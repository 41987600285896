import BoardListTable from "@components/shere-board-content/board-list-table";

export default function InviteUserTable() {
  return (
    <div>
      <BoardListTable />
    </div>
  );
}


