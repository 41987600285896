import { QuickLaunch } from '@models/quick-launch';
import { Box } from '@mui/material';
import { btQuickPanelActions } from '@reducers/BtQuickPanelReducer';
import { DBBoardsThunks, DBQuickLaunchThunks, quickLaunchSelectByQuery } from '@reducers/DBServiceReducers';
import { selectUserObject } from '@reducers/UserSelectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import BtQuickPanelMainMenuButton from './bt-quick-panel-main-menu-button';
import classes from './bt-quick-panel.module.scss';
import PanelMenuPopper from './panel-menu-popper';
import LinkPopper from './quick-link-overlay/link-popper';
import QuickLinkOverlayButton from './quick-link-overlay/quick-link-overlay-button';

export default function BtQuickPanel() {
    const dispatch = useAppDispatch();
    const isDragging = useAppSelector((state) => state.BtQuickPanelReducer.isDragging);
    const authUser = useAppSelector(selectUserObject);

    const loadBoards = useCallback(async () => {
        if (!authUser?._id)
            dispatch(btQuickPanelActions.setIsLoadingBoards(true))
        const response = await dispatch(DBBoardsThunks.find({}))
        if (response.payload) {
            dispatch(btQuickPanelActions.setIsLoadingBoards(false))
        }
    }, [dispatch, authUser])

    useEffect(() => {
        loadBoards()
        dispatch(DBQuickLaunchThunks.find({}))
    }, [dispatch, loadBoards])

    let startPosX = 0, startPosY = 0, mouseDownTime = 0;
    const draggableRef = useRef(null);
    const snapPosition = useRef({snapX: false, snapY: false});

    function setPosition(newPosition, size, snapPos, windowSize) {
        if (!snapPosition.current[snapPos] && Date.now() - mouseDownTime >= 1000) {
            if (newPosition <= 15) {
                snapPosition.current[snapPos] = true;
                return '0px';
            } else if (windowSize - (newPosition + size) <= 15) {
                snapPosition.current[snapPos] = true;
                return `${windowSize - size}px`;
            }
        }
        return snapPosition.current[snapPos] ? draggableRef.current.style[snapPos === 'snapX' ? 'left' : 'top'] : `${newPosition}px`;
    }

    function MouseDown(e) {
        e.preventDefault();
        dispatch(btQuickPanelActions.setIsDragging(true))

        startPosX = e.clientX;
        startPosY = e.clientY;
        mouseDownTime = Date.now();

        snapPosition.current = {snapX: false, snapY: false};

        document.addEventListener('mousemove', mouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    function handleMouseUp(e) {
        e.preventDefault();
        document.removeEventListener('mousemove', mouseMove);
        dispatch(btQuickPanelActions.setIsDragging(false))
    }

    function mouseMove(e) {
        if (!draggableRef.current) return;

        const element = draggableRef.current;
        const newPosX = startPosX - e.clientX;
        const newPosY = startPosY - e.clientY;

        startPosX = e.clientX;
        startPosY = e.clientY;

        const newClientX = element.offsetLeft - newPosX;
        const newClientY = element.offsetTop - newPosY;

        element.style.left = setPosition(newClientX, element.offsetWidth, 'snapX', window.innerWidth);
        element.style.top = setPosition(newClientY, element.offsetHeight, 'snapY', window.innerHeight);
    }

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', mouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    const quickLinks: QuickLaunch[] = useAppSelector((state) => quickLaunchSelectByQuery(state.DBQuickLaunchReducer, {
        authorId: authUser?._id ?? '',
        $or: [{ deleted: { $exists: false } }, { deleted: false }]
    }));

    return (
        <Box className={classes.OverlayContainer}
            sx={{ cursor: isDragging ? 'move' : 'pointer' }}
            ref={draggableRef}
            id='draggableItem'
            onMouseDown={MouseDown}
            onMouseUp={handleMouseUp}
        >
            <BtQuickPanelMainMenuButton />
            {!isDragging && (<Box>
                {quickLinks.map((quickLink, index) => (
                    <QuickLinkOverlayButton quickLink={quickLink} key={index} />
                ))}
            </Box>)}
            <PanelMenuPopper />
            <LinkPopper />
        </Box>
    )
}

