import { useAppSelector } from "@store/hooks";
import React, {
  useCallback,
  useMemo,
} from "react";

import type { BarMoveAction } from "../../../types/gantt-task-actions";
import { TaskItemProps } from "../task-item";
import styles from "./project.module.css";
import { offset } from "@floating-ui/react";
import { getBattaryProgressGanttGroupBar } from "@reducers/TaskSelectors";

export const Project: React.FC<TaskItemProps & { onTaskEventStart: (action: BarMoveAction, clientX: number) => void; }> = ({
  distances: {
    barCornerRadius,
  },
  taskHalfHeight,
  taskHeight,
  isSelected,
  isCritical,
  colorStyles,
  task,
  progressWidth,
  progressX,
  taskYOffset,
  width,
  x1,
  x2,
  onTaskEventStart,
}) => {

  const barColor = useMemo(() => {
    if (isCritical) {
      if (isSelected) {
        return colorStyles.projectBackgroundSelectedCriticalColor;
      }

      return colorStyles.projectBackgroundCriticalColor;
    }

    if (isSelected) {
      return colorStyles.projectBackgroundSelectedColor;
    }

    return colorStyles.projectBackgroundColor;
  }, [isSelected, isCritical, colorStyles]);

  const processColor = useMemo(() => {
    if (isCritical) {
      if (isSelected) {
        return colorStyles.projectProgressSelectedCriticalColor;
      }

      return colorStyles.projectProgressCriticalColor;
    }

    if (isSelected) {
      return colorStyles.projectProgressSelectedColor;
    }

    return colorStyles.projectProgressColor;
  }, [isSelected, isCritical, colorStyles]);


  const groupId = task.id;

  const batteryGradient = useAppSelector(state => getBattaryProgressGanttGroupBar(state, groupId))
  const batteryArray = batteryGradient.split('90deg,')
  const completeBatteryList = []
  const batterySvgEl: any[] = [];
  let sum = 0

  batteryArray.map(task => {
    const item = {}
    if (task !== 'linear-gradient(') {
      const taskValues = task.split(',')
      completeBatteryList.push({ color: taskValues[0], per: taskValues[1] })

    }
  })

  for (let i = 0; i < completeBatteryList.length; i++) {
    const el = completeBatteryList[i];
    let svgEl, svgElStart;
    const percentage = parseFloat(el.per)
    if (i === 0) {
      sum += percentage;
      svgElStart = <stop offset={`0`} stopColor={el.color} />
    } else if (i === completeBatteryList.length - 1) {
      svgElStart = <stop offset={`${sum}`} stopColor={el.color} />
      sum = 100;
    } else {
      svgElStart = <stop offset={`${sum}`} stopColor={el.color} />
      sum += percentage;
    }
    svgEl = <stop offset={`${sum}`} stopColor={el.color} />

    batterySvgEl.push(svgElStart, svgEl)
  }

  const projectLeftTriangle = [
    x1,
    taskYOffset + taskHeight / 2 - 1,
    x1,
    taskYOffset + taskHeight,
    x1 + 15,
    taskYOffset + taskHeight / 2 - 1,
  ].join(",");
  const projectRightTriangle = [
    x2,
    taskYOffset + taskHeight / 2 - 1,
    x2,
    taskYOffset + taskHeight,
    x2 - 15,
    taskYOffset + taskHeight / 2 - 1,
  ].join(",");

  const startMoveFullTask = useCallback((clientX: number) => {
    onTaskEventStart("move", clientX);
  }, [onTaskEventStart]);

  return (
    <g
      onMouseDown={(e) => {
        startMoveFullTask(e.clientX);
      }}
      onTouchStart={(e) => {
        const firstTouch = e.touches[0];

        if (firstTouch) {
          startMoveFullTask(firstTouch.clientX);
        }
      }}
      tabIndex={0}
      className={styles.projectWrapper}>
      <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
        {batterySvgEl.map((el, key) => {
          return (

            <stop key={key} offset={`${el.props.offset}%`} stopColor={el.props.stopColor} />
            )
         
        })}

      </linearGradient>
      <rect
        fill={barColor}
        x={x1}
        width={width}
        y={taskYOffset}
        height={taskHeight}
        rx={barCornerRadius}
        ry={barCornerRadius}
        className={styles.projectBackground}
      />
      <rect
        x={progressX}
        width={progressWidth}
        y={taskYOffset}
        height={taskHeight}
        ry={barCornerRadius}
        rx={barCornerRadius}
        fill='url(#grad)'
      />
      <rect
        fill={barColor}
        x={x1}
        width={width}
        y={taskYOffset}
        height={taskHalfHeight + 10}
        rx={barCornerRadius}
        ry={barCornerRadius}
        className={styles.projectTop}
      />
      {/* <polygon
        className={styles.projectTop}
        points={projectLeftTriangle}
        fill={barColor}
      /> */}
      <polygon
        className={styles.projectTop}
        points={projectRightTriangle}
        fill={barColor}
      />
    </g>
  );
};
