import { Board } from '@models/board'
import { Box, styled } from '@mui/material'
import { boardsSelectors } from '@reducers/DBServiceReducers'
import { useAppSelector } from '@store/hooks'



const SelectWrap = styled(Box)(({ theme }) => ({
    margin: '0 5px',
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #d8e9ed',
    backgroundColor: '#fff',
    borderRadius: 8
}))


const Select = styled('select')(({ theme }) => ({
    width: 119,
    height: 32,
    padding: 0,
    border: '0px solid transparent',
    outline: 'none',
    borderRadius: 8,
    color: '#626262',
    appearance: 'auto'
}))



type Props = {
    handleSelectOnChange: (event:any) => void
}

export default function BoardsSelect({handleSelectOnChange }: Props) {
    const boards: Board[] = useAppSelector(boardsSelectors.selectAll);
    const isLoadingBoards = useAppSelector((state)=>state.BtQuickPanelReducer.isLoadingBoards)
    const isErrorInput = useAppSelector((state)=>state.BtQuickPanelReducer.isErrorInput)
    const selectedData = useAppSelector((state)=>state.BtQuickPanelReducer.selectedData)


    return (
        <SelectWrap sx={{ borderColor: isErrorInput && !selectedData.boardId ? 'red' : '' }}>
            <Select value={selectedData.boardId}
                name="boardId"
                onChange={handleSelectOnChange}
            >
                <option value="none">Choose Board</option>
                {!isLoadingBoards && boards.length ? (<>
                    {boards.map((board) => (
                        <option key={board._id} value={board._id}>
                            {`
                    ${board?.titleIcon !== undefined ? board?.titleIcon : ''} ${board.name}`}
                        </option>
                    ))}
                </>) : (
                    <option style={{ fontWeight: 600 }} value='none'>
                        {isLoadingBoards ? 'Loading boards...' : '--No boards--'}
                    </option>
                )}
            </Select>
        </SelectWrap>
    )
}