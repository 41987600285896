import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { logsSelectOneObjectByQuery, tasksSelectOneFieldById } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { CSSProperties } from 'react';
import { LogStack } from '../../ExtraDataPanel';


type Props = {
    logId: string;
    isBoardLog?: boolean;
}

export default function DropdownColumnLog({
    logId,
    isBoardLog = false,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))
    const taskName = useAppSelector(state => tasksSelectOneFieldById(state.DBTasksReducer, log?.taskId, 'text'))

    const cellWrapperStyle: CSSProperties = { pointerEvents: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }

    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === log.logUpdateType
    );

    let status
    let oldValue
    let newValue
    if (!log.oldValue && log.newValue) {
        newValue = [{
            label: log.newValue
        }]
        status = 'add data'
    }
    if (log.oldValue && !log.newValue) {
        oldValue = [{
            label: log.oldValue
        }]
        status = 'remove data'
    }
    return (
        <LogStack>
            {status === 'add data' &&
                <>
                    <span>{log.actionType} </span>
                    <b style={cellWrapperStyle}><CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></b>
                    <span>to&nbsp;</span>
                </>
            }
            {status === 'remove data' &&
                <>
                    <span>{log.actionType} </span>
                    <b style={cellWrapperStyle}><CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /> </b>
                    <span>from&nbsp;</span>
                </>
            }
            {isBoardLog && <span style={{ fontWeight: 'bold' }}>{taskName}-</span>}
            <span style={{ fontWeight: 'bold' }}>{log.actionOnEntityData}</span>
        </LogStack>
    )
}


