import { TaskColumn } from '@models/task-column';
import { TasksGroup } from '@models/task-group'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
    importFileName: string,
    workSheetName: string,
    importJsonData: any[],
    selectedColumn: string,
    step: number,
    newGroupName: string;
    selectedImportType: string
    selectedGroup: TasksGroup,
    importDataMapping: Record<string, string>,
    file?: File;
    importInProgress?: boolean;
    newColumnList?: TaskColumn[]
    newAudienceName?: string;
}
const initialState: State = {
    importFileName: "",
    workSheetName: "",
    importJsonData: [],
    selectedColumn: "",
    step: 0,
    newGroupName: "",
    selectedImportType: 'default-create-new-group',
    selectedGroup: {},
    importDataMapping: {},
    newColumnList: [],
    newAudienceName: null,
}


const importItemsSlice = createSlice({
    name: 'importItemSlice',
    initialState,
    reducers: {
        setImportProps(state, { payload }: PayloadAction<{ file: File, importFileName: string, workSheetName: string, importJsonData: any[] }>) {
            state.importFileName = payload.importFileName
            state.workSheetName = payload.workSheetName
            state.importJsonData = payload.importJsonData
            state.file = payload.file;
        },
        setGoogleSheetsImportProps(state, { payload }: PayloadAction<{ importFileName: string, workSheetName: string, importJsonData: any[] }>) {
            state.importFileName = payload.importFileName
            state.workSheetName = payload.workSheetName
            state.importJsonData = payload.importJsonData
        },
        setImportJsonData(state, { payload }: PayloadAction<any[]>) {
            state.importJsonData = payload
        },
        setSelectedColumn(state, { payload }: PayloadAction<string>) {
            state.selectedColumn = payload
        },
        setStep(state, { payload }: PayloadAction<number>) {
            state.step = payload
        },
        setNewGroupName(state, { payload }: PayloadAction<string>) {
            state.newGroupName = payload
        },
        setImportType(state, { payload }: PayloadAction<string>) {
            state.selectedImportType = payload
        },
        selectGroup(state, { payload }: PayloadAction<TasksGroup>) {
            state.selectedGroup = payload
        },
        setImportDataMapping(state, { payload }: PayloadAction<Record<string, string>>) {
            state.importDataMapping = payload
        },
        setImportInProgress(state, { payload }: PayloadAction<boolean>) {
            state.importInProgress = payload
        },
        resetImport(state) {
            state.step = 0
            state.importFileName = ""
            state.selectedColumn = ""
            state.workSheetName = ""
            state.newGroupName = ""
            state.selectedGroup = {}
            state.selectedImportType = 'default-create-new-group'
            state.importJsonData = []
            state.newColumnList = []
            delete state.importInProgress;
        },
        setNewColumnList(state, { payload }: PayloadAction<TaskColumn[]>) {
            state.newColumnList = payload
        },
        setNewAudienceName(state, { payload }: PayloadAction<string | null>) {
            state.newAudienceName = payload
        },
    }
})


export const importItemsActions = importItemsSlice.actions;
export default importItemsSlice.reducer
