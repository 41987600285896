import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { NotificationStack } from './notification-row';

type Props = {
    notificationId: string;
}

export default function PersonColumnNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))


    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === notification.notificationUpdateType
    );




    return (
        <>
            {notification.actionType === 'changed' &&
                <NotificationStack direction='row'>
                    <span style={{ verticalAlign: 'middle' }}>{notification.actionType}</span>&nbsp;
                    <div style={{ maxWidth: '30px' }}>
                        <CellWrapper
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={[notification?.oldUser]}
                            readOnly={true}
                            cellComponent={cell.cellComponent}
                        />
                    </div>
                    &nbsp;
                    <span style={{ verticalAlign: 'middle' }}>to</span>&nbsp;
                    <div style={{ maxWidth: '30px' }}>
                        <CellWrapper
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={[notification?.newUser]}
                            readOnly={true}
                            cellComponent={cell.cellComponent}
                        />
                    </div>
                    &nbsp;
                    <span style={{ verticalAlign: 'middle' }}>in</span>&nbsp;
                    <span style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>{notification.actionOnEntityData}</span>&nbsp;
                </NotificationStack>
            }
            {notification.actionType !== 'changed' &&
                <NotificationStack direction='row'>
                    <span style={{ verticalAlign: 'middle' }}>{notification.actionType}</span>&nbsp;
                    <div style={{ maxWidth: '30px' }}>
                        <CellWrapper
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={[notification?.oldUser ?? notification?.newUser]}
                            readOnly={true}
                            cellComponent={cell.cellComponent}
                        />
                    </div>
                    &nbsp;
                    <span style={{ verticalAlign: 'middle' }}>in</span>&nbsp;
                    <span style={{ fontWeight: 'bold' }}>{notification.taskName}-</span>
                    <span style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>{notification.actionOnEntityData}</span>&nbsp;
                </NotificationStack>
            }
        </>
    )
}