import BTButton from "@components/bt-button/bt-button";
import SelectUserRole from "@components/select-user-role";
import PersonAvatar from "@components/tasks/person-avatar/person-avatar";
import { BlueticksBoardUser, boardUserDisplayName } from "@models/board-user";
import {
  BlueticksWorkspaceUser,
  UserRole,
  UserRoleOrderMap,
} from "@models/workspace-user";

import BTInputField from "@components/bt-input-field/bt-input-field";
import ShareBoardMockUserRow from "@components/invite/share-board-mock-user-row";
import {
  Alert,
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import {
  AugmentedBlueticksBoardUser,
  getActiveWorkspaceUserListNotInCurrentBoard,
  getCurrentBoardId,
  getMockPersonList,
  getSelectedBoardUserList,
  isCurrentBoardOwnerSelector,
  isCurrentUserMemberOrOwner,
  isCurrentWorkspaceOwnerSelector,
} from "@reducers/BoardsSelectors";
import {
  addUserToBoardAsMemberOrGuestThunk,
  addUserToBoardThunk,
} from "@reducers/BoardsThunks";
import {
  DBBoardUserThunks,
  DBWorkspaceUserThunks,
  isTempId
} from "@reducers/DBServiceReducers";
import { createMockPersonThunk } from "@reducers/TaskThunks";
import { taskActions } from "@reducers/TasksReducer";
import { UndoRedoAction } from "@reducers/UndoRedoReducer";
import { doActionThunk } from "@reducers/UndoRedoThunks";
import { useRouter } from "@reducers/hooks/use-router";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import * as EmailValidator from "email-validator";
import { useCallback, useMemo, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { userDisplayName } from "@models/user";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { appConfig } from "config/app.config";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";
import { resendBoardInvitation } from "@reducers/backend-api/backend-api";

export type TableUser = BlueticksBoardUser | AugmentedBlueticksBoardUser;

export enum AddMemberTypes {
  user = "user",
  mockUser = "mockUser",
  workspaceUser = "workspaceUser",
}

export default function BoardListTable() {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch();

  const currentBoardId = useAppSelector(getCurrentBoardId);

  const completeUserList = useAppSelector(getSelectedBoardUserList);
  const isCurrentBoardOwner = useAppSelector(isCurrentBoardOwnerSelector);
  const isCurrentWorkspaceOwner = useAppSelector(isCurrentWorkspaceOwnerSelector);
  const isMemberOrOwner = useAppSelector(isCurrentUserMemberOrOwner);
  //const isMemberOrOwner = isCurrentBoardOwner || isCurrentWorkspaceOwner;
  const activeWorkspaceUserListNotInCurrentBoard = useAppSelector(
    getActiveWorkspaceUserListNotInCurrentBoard
  );
  const currentUserId = useAppSelector((state) => state.UserReducer.userId);

  const mockUserList = useAppSelector((state) => getMockPersonList(state));

  const allTableUsers: TableUser[] = useMemo(() => {
    const sorted = completeUserList.sort(
      (userA, userB) =>
        // UserRoleOrderMap[userA.role] - UserRoleOrderMap[userB.role]
        UserRoleOrderMap[userA.workspaceUser?.role] -
        UserRoleOrderMap[userB.workspaceUser?.role]
    );
    return [...sorted, ...mockUserList];
  }, [completeUserList, mockUserList]);
  const [newPersonActiveType, setNewPersonActiveType] =
    useState<AddMemberTypes>(AddMemberTypes.user);

  const [newUserEmail, setNewUserEmail] = useState("");
  const [newMockUserName, setNewMockUserName] = useState("");
  // const [workspaceUserEmail, setWorkspaceUserEmail] = useState("");
  const [workspaceUserId, setWorkspaceUserId] = useState("");
  const [newPersonError, setNewPersonError] = useState("");
  const [newPersonInvalidEmailError, setNewPersonInvalidEmailError] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const direction = useAppSelector((state) => state.UserReducer.direction);
  console.log(completeUserList)
  const resendInvite = useCallback(
    async (boardUser: BlueticksBoardUser) => {
      setIsLoading(true);
      let res
      try {
        res = await dispatch(resendBoardInvitation.initiate({
          boardId: currentBoardId,
          boardUserId: boardUser._id, email: boardUser.email
        })).unwrap();
      } catch (error) {

        return { success: false, error: error.data.message || "An unexpected error occurred." };
      }
      if (res.status === 200) {
        dispatch(taskActions.setUpgradeSnackbarMessage(`Invite resent to ${boardUser.email}`));

      } else {
        dispatch(taskActions.setUpgradeSnackbarMessage(res.error));

      }

      setIsLoading(false);
    },
    [dispatch]
  );




  const handleCopyToClipboard = (invite) => {

    const textToCopy = `${appConfig.APP_ENDPOINT}/join-board/${invite?.boardId}?inviteId=${invite?._id?.toString()}&email=${invite.email}`
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log("Copied to clipboard successfully!");
      })
      .catch(err => {
        console.error("Failed to copy: ", err);
      });
  }
  const removeUser = useCallback(
    (boardUser: BlueticksBoardUser) => {
      const action: UndoRedoAction<string[], string> = {
        type: "delete-board-user-list",
        data: [boardUser._id],
        deletedOrArchived: "deleted"
      };
      dispatch(doActionThunk({ action }));
    },
    [dispatch]
  );

  const updateUserRole = useCallback(
    async (workspaceUser: BlueticksWorkspaceUser, role: UserRole) => {
      setIsLoading(true);

      const res: any = await dispatch(
        DBWorkspaceUserThunks.patch({
          entity: {
            // ...workspaceUser,
            _id: workspaceUser._id,
            role,
          },
        })
      );

      if (res?.payload?.message) {
        dispatch(taskActions.setUpgradeSnackbarMessage(res?.payload?.message));
      }

      setIsLoading(false);
    },
    [dispatch]
  );

  const addNewUser = useCallback(
    async (inviteAsMember?: boolean) => {

      setNewPersonError('');

      if (!EmailValidator.validate(newUserEmail)) {
        setNewPersonInvalidEmailError(true);
        return;
      }

      setIsLoading(true);

      const role = inviteAsMember ? "member" : "guest";

      const result = await dispatch(addUserToBoardAsMemberOrGuestThunk({ role, email: newUserEmail })).unwrap();

      setNewUserEmail("");
      await dispatch(DBWorkspaceUserThunks.find({ email: newUserEmail }));
      setIsLoading(false);

      if (!result.success) {
        setNewPersonError(result.error);
        dispatch(taskActions.setUpgradeSnackbarMessage(result.error));
        setIsLoading(false);
        return;
      }


    },
    [newUserEmail, isCurrentWorkspaceOwner, dispatch]
  );


  const addNewMockUser = useCallback(async () => {
    setIsLoading(true);

    dispatch(
      createMockPersonThunk({
        columnId: undefined,
        newPersonName: newMockUserName,
        imgUrl: "",
        imgColor: "",
      })
    );

    setNewMockUserName("");

    setIsLoading(false);
  }, [newMockUserName, dispatch]);

  const addWorkspaceUser = useCallback(async () => {
    setIsLoading(true);

    const stateEntity = await dispatch(
      DBBoardUserThunks.createOptimisticUpdate({
        userId: workspaceUserId,
        boardId: currentBoardId,
      })
    ).unwrap();
    const dirtySessionId = stateEntity.dirtySessionId;

    const dbEntityResult = await dispatch(
      addUserToBoardThunk(workspaceUserId)
    ).unwrap();
    if (dbEntityResult?.error?.data?.message) {
      dispatch(DBBoardUserThunks.undoOptimisticUpdate(dirtySessionId));
      setNewPersonError(dbEntityResult?.error?.data?.message ?? "");
    } else {
      dispatch(
        DBBoardUserThunks.syncOptimisticUpdate({
          ...dbEntityResult.data,
          dirtySessionId,
        })
      );
      setWorkspaceUserId("");
    }

    setIsLoading(false);
  }, [dispatch, workspaceUserId, currentBoardId]);

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <div style={{ position: "relative" }}>
          <div
            style={{
              zIndex: "2",
              minHeight: "100px",
              padding: "16px",
              gap: "8px",
              display: "flex",
              flexDirection: "column",
              background: "var(--boards-panel-background)",
              borderRadius: "12px",
            }}
          >
            <div style={{ fontSize: "14px" }}>
              <b>{t('boardListTable.addNewUserToBoard')}</b>
            </div>
            <RadioGroup
              row
              value={newPersonActiveType}
              onChange={(_, memberType: AddMemberTypes) => {
                setNewPersonActiveType(memberType);
              }}
            >
              <FormControlLabel
                value={AddMemberTypes.user}
                control={<Radio sx={{ color: "var(--primary)" }} />}
                label={<Typography sx={{ fontSize: "12px" }}>{t('boardListTable.userLabel')}</Typography>}
              />

              <FormControlLabel
                value={AddMemberTypes.workspaceUser}
                control={<Radio sx={{ color: "var(--primary)" }} />}
                label={
                  <Typography sx={{ fontSize: "12px" }}>
                    {t('boardListTable.workspaceUserLabel')}
                  </Typography>
                }
              />

              <FormControlLabel
                value={AddMemberTypes.mockUser}
                control={<Radio sx={{ color: "var(--primary)" }} />}
                label={
                  <Typography sx={{ fontSize: "12px" }}>{t('boardListTable.virtualMemberLabel')}</Typography>
                }
              />
            </RadioGroup>

            {newPersonActiveType === AddMemberTypes.user ? (
              <Stack spacing={1}>
                <BTInputField
                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                  style={{ width: "100%", backgroundColor: 'var(--search-input-background)', }}
                  placeholder={t('boardListTable.emailPlaceholder')}
                />

                <Stack direction="row" spacing={1}>
                  {isMemberOrOwner ? (
                    <BTButton
                      onClick={() => addNewUser(true)}
                      style={{
                        background:
                          "linear-gradient(66.67deg, #FFA3CE 0%, #F656A1 100%)",
                        width: "100%",
                        opacity: isLoading || !newUserEmail ? 0.5 : 1,
                      }}
                      disabled={isLoading || !newUserEmail}
                    >
                      <FaPlus
                        style={{
                          marginInlineEnd: "5px",
                          height: "14px",
                          width: "14px",
                          flexShrink: "0",
                        }}
                      />
                      {t('boardListTable.inviteAsMember')}
                    </BTButton>
                  ) : null}
                  <BTButton
                    onClick={() => addNewUser()}
                    style={{
                      width: "100%",
                      opacity: isLoading || !newUserEmail ? 0.8 : 1,
                    }}
                    sx={{
                      "& .MuiLoadingButton-loadingIndicator": {
                        height: "14px",
                        width: "14px",
                      }
                    }}
                    startIcon={!isLoading ? <FaPlus
                      style={{
                        height: "14px",
                        width: "14px",
                        flexShrink: "0",
                      }}
                    />
                      : <CircularProgress style={{
                        height: "14px",
                        width: "14px",
                        flexShrink: "0",
                      }} />}
                    disabled={isLoading || !newUserEmail}
                  >

                    {t('boardListTable.inviteAsGuest')}
                  </BTButton>
                </Stack>
              </Stack>
            ) : null}

            {newPersonActiveType === AddMemberTypes.mockUser ? (
              <Stack spacing={1}>
                <BTInputField
                  value={newMockUserName}
                  onChange={(e) => setNewMockUserName(e.target.value)}
                  style={{ width: "100%" }}
                  placeholder={t('boardListTable.namePlaceholder')}
                />

                <Stack direction="row" spacing={1}>
                  <BTButton
                    onClick={() => addNewMockUser()}
                    style={{
                      width: "100%",
                      opacity: isLoading || !newMockUserName ? 0.5 : 1,
                    }}
                    disabled={isLoading || !newMockUserName}
                  >
                    <FaPlus
                      style={{
                        marginInlineEnd: "5px",
                        height: "14px",
                        width: "14px",
                        flexShrink: "0",
                      }}
                    />
                    {t('boardListTable.addButton')}
                  </BTButton>
                </Stack>
              </Stack>
            ) : null}

            {newPersonActiveType === AddMemberTypes.workspaceUser ? (
              <Stack spacing={1}>
                <Select
                  displayEmpty
                  renderValue={(value) => {
                    if (!value) {
                      return (
                        <span
                          style={{
                            color: "var(--placeholder-text)",
                            fontSize: "14px",
                            paddingInlineStart: "8px",
                          }}
                        >
                          {t('boardListTable.chooseTeamMember')}
                        </span>
                      );
                    }
                    // return value;
                    const workspaceUser =
                      activeWorkspaceUserListNotInCurrentBoard.find(
                        (workspaceUser) =>
                          workspaceUser?.userId &&
                          workspaceUser?.userId === value
                      );

                    return value ? <PersonAvatar
                      email={workspaceUser?.profile?.email}
                      profileImg={workspaceUser?.profile?.profileImg}
                      lastName={workspaceUser?.profile?.lastName}
                      firstName={workspaceUser?.profile?.firstName}
                      isName={true}
                      style={{ justifyContent: 'flex-start', alignItems: 'center' }}
                      textWidth={"100%"}
                    /> : null

                  }}
                  sx={{
                    height: "36px",
                    background: "var(--boards-panel-background)",
                    borderRadius: "8px",
                    border: "none!important",
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "1px solid var(--search-input-border)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid var(--search-input-border-hover)",
                      boxShadow: "0 5px 12px rgb(0 0 0 / 15%)",
                    },
                  }}
                  value={workspaceUserId}
                  onChange={(e) => setWorkspaceUserId(e.target.value)}
                >
                  {activeWorkspaceUserListNotInCurrentBoard.length ? (
                    <MenuItem value="">{t('boardListTable.selectTeamMember')}</MenuItem>
                  ) : (
                    <MenuItem hidden disabled>
                      {t('boardListTable.noMembersToAdd')}
                    </MenuItem>
                  )}
                  {activeWorkspaceUserListNotInCurrentBoard.map(
                    (workspaceUser) => (
                      <MenuItem
                        key={workspaceUser?.userId}
                        value={workspaceUser?.userId}
                      >
                        <PersonAvatar
                          email={workspaceUser.profile?.email}
                          profileImg={workspaceUser?.profile?.profileImg}
                          lastName={workspaceUser?.profile?.lastName}
                          firstName={workspaceUser?.profile?.firstName}
                          isName={true}
                          textWidth={"100%"}


                        />

                      </MenuItem>
                    )
                  )}
                </Select>

                <Stack direction="row" spacing={1}>
                  <BTButton
                    onClick={() => addWorkspaceUser()}
                    style={{
                      width: "100%",
                      opacity: isLoading || !workspaceUserId ? 0.5 : 1,
                    }}
                    disabled={isLoading || !workspaceUserId}
                  >
                    <FaPlus
                      style={{
                        marginInlineEnd: "5px",
                        height: "14px",
                        width: "14px",
                        flexShrink: "0",
                      }}
                    />
                    {t('boardListTable.addButton')}
                  </BTButton>
                </Stack>
              </Stack>
            ) : null}

            {/* {!!newPersonError && (
              <Alert severity="error">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {newPersonError}

                  {newPersonError === "Seat limit exceeded" && (
                    <BTButton
                      sx={{
                        ml: "4px",
                        height: 20,
                      }}
                      onClick={() => {
                        router.navigate("/account-subscription");
                      }}
                    >
                      {t('boardListTable.addMoreSeats')}
                    </BTButton>
                  )}
                </Stack>
              </Alert>
            )} */}
          </div>
        </div>
        {!isMemberOrOwner && <Stack direction="row" justifyContent="center" alignItems="center"
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            background: "var(--task-background-color)",
            height: "100%",
            width: "100%",
            borderRadius: "12px",
            zIndex: "1",
            opacity: 0.8
          }}
        >
          <span>{t('boardListTable.notAllowedToShareBoard')}</span>
        </Stack>}
      </Box>

      <TableContainer
        sx={{
          background: "unset",
          overflow: "hidden",
          boxShadow: "none",
          marginTop: "16px",
        }}
        component={Paper}
      >
        <Table sx={{ width: "100%" }}>
          <TableHead
            sx={{
              "& .MuiTableRow-root th": {
                border: 0,
              },
              background: "unset",
            }}
          >
            <TableRow>
              <TableCell
                align={direction === "ltr" ? "left" : "right"}
                key={2}
                sx={{
                  fontFamily: '"Inter"',
                  paddingInlineEnd: "4px",
                  color: "#809BA8",
                  fontSize: "12px",
                  minWidth: "157px",
                }}
              >
                {t('boardListTable.nameEmail')}
              </TableCell>
              <TableCell
                align={direction === "ltr" ? "left" : "right"}
                key={3}
                sx={{
                  fontFamily: '"Inter"',
                  paddingInlineEnd: "16px",
                  color: "#809BA8",
                  fontSize: "12px",
                }}
              >
                {t('boardListTable.permissions')}
              </TableCell>
              {isCurrentWorkspaceOwner || isCurrentBoardOwner ? (
                <TableCell key={4} />
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              background: "var(--task-background-color)",
              borderRadius: "8px",
              "& .MuiTableRow-root:last-child td": {
                border: 0,
              },
              "& .MuiTableRow-root:first-of-type td:first-of-type": {
                borderTopLeftRadius: direction === "ltr" ? "12px" : undefined,
                borderTopRightRadius: direction === "rtl" ? "12px" : undefined,
              },
              "& .MuiTableRow-root:first-of-type td:last-child": {
                borderTopRightRadius: direction === "ltr" ? "12px" : undefined,
                borderTopLeftRadius: direction === "rtl" ? "12px" : undefined,

              },
              "& .MuiTableRow-root:last-child td:first-of-type": {
                borderBottomLeftRadius: direction === "ltr" ? "12px" : undefined,
                borderBottomRightRadius: direction === "rtl" ? "12px" : undefined,

              },
              "& .MuiTableRow-root:last-child td:last-child": {
                borderBottomRightRadius: direction === "ltr" ? "12px" : undefined,
                borderBottomLeftRadius: direction === "rtl" ? "12px" : undefined,
              },
            }}
          >
            {allTableUsers.map((boardUser, index) => {
              const isUserMock = !("boardId" in boardUser);
              if (isUserMock) {
                return (
                  <ShareBoardMockUserRow
                    key={boardUser._id}
                    person={boardUser as AugmentedBlueticksBoardUser}
                    index={index}
                  />
                );
              } else if (!isTempId(boardUser._id)) {

                return (

                  <TableRow key={index} >
                    <TableCell sx={{ padding: "16px" }} align={direction === "ltr" ? "left" : "right"} key={2} >
                      <Stack direction="row" alignItems={"center"} gap="10px">
                        <PersonAvatar
                          size={32}
                          email={boardUser.email}
                          profileImg={boardUser?.workspaceUser?.profile?.profileImg}
                          lastName={boardUser?.workspaceUser?.profile?.lastName}
                          firstName={boardUser?.workspaceUser?.profile?.firstName}
                          toolTip={false}
                        />
                        <Stack direction="column" alignItems={"flex-start"}>
                          <span
                            style={{
                              fontFamily: '"Inter"',
                              color: "#53bdeb",
                              fontSize: 14,
                              fontWeight: 600,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              width: "170px",
                            }}
                          >
                            {boardUserDisplayName(boardUser)}
                          </span>
                          {boardUser.status !== "active" && (
                            <Stack
                              style={{ height: "16px", marginTop: "2px" }}
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Box
                                sx={{
                                  color: "var(--gray-to-lighter-dark)",
                                  fontSize: 11,
                                }}
                              >
                                {t('boardListTable.pending')}
                              </Box>
                              <BTButton
                                btvariant="text"
                                disabled={isLoading}
                                onClick={() =>
                                  resendInvite(boardUser as BlueticksBoardUser)
                                }
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <AiOutlineMail />
                                  <span
                                    style={{
                                      fontSize: 11,
                                      marginLeft: 5,
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t('boardListTable.resend')}
                                  </span>
                                </Stack>
                              </BTButton>
                              <Tooltip title={t('boardListTable.copyInviteLink')} placement="top" arrow>

                                <IconButton
                                  onClick={() => handleCopyToClipboard(boardUser)}
                                  sx={{ fontSize: "12px" }}>
                                  <ContentCopyIcon sx={{ fontSize: "12px" }} />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          )}
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell key={3} sx={{ padding: "16px 0" }}>

                      <SelectUserRole
                        disabled={
                          !isMemberOrOwner
                        }
                        onChange={(role) => {
                          updateUserRole(boardUser?.workspaceUser, role);
                        }}
                        user={boardUser?.workspaceUser}
                      />
                    </TableCell>
                    <TableCell key={4}>
                      {isMemberOrOwner ? (
                        <>
                          {
                            boardUser.userId !== currentUserId && (
                              <Tooltip title={boardUser?.workspaceUser?.role === "owner" ? t('boardListTable.cantRemoveOwner') : t('boardListTable.removeUserFromBoard')} arrow placement="top">

                                <span>
                                  <IconButton
                                    disabled={boardUser?.workspaceUser?.role === 'owner'}
                                    onClick={() => {
                                      removeUser(boardUser as BlueticksBoardUser);
                                    }}
                                  >
                                    <RiDeleteBinLine
                                      style={{ height: "16px", width: "16px", color: 'var(--secondary-text-color)' }}
                                    />
                                  </IconButton>
                                </span>

                              </Tooltip>
                            )

                          }
                        </>
                      ) : null}

                    </TableCell>

                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={!!newPersonInvalidEmailError}
        autoHideDuration={2000}
        onClose={() => setNewPersonInvalidEmailError(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={() => setNewPersonInvalidEmailError(false)}
          severity="error"
          variant="filled"
          sx={{
            "& .MuiButtonBase-root": {
              color: "#fff;",
            },
          }}
        >
          {t('boardListTable.emailInvalidAlert')}
        </Alert>
      </Snackbar>
    </>
  );
}