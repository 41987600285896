import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, Stack, TextField } from "@mui/material";
import { taskActions } from "@reducers/TasksReducer";
import { useRouter } from "@reducers/hooks/use-router";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCallback, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { BiBriefcase } from "react-icons/bi";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { getCurrentWorkspaceUserList, userRoleCountSelector } from "@reducers/BoardsSelectors";
import BTButton from "@components/bt-button/bt-button";
import BTInputField from "@components/bt-input-field/bt-input-field";
import { UserManagementFilter } from "./user-management-filter";
import { MdAdd } from "react-icons/md";
import { workspaceActions } from "@reducers/WorkspaceReducer";
import { selectCurrentWorkspace } from "@reducers/UserSelectors";
import { DBWorkspaceUserThunks } from "@reducers/DBServiceReducers";
import { UserManagementExport } from "./user-management-export";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";

export const UserManagementHeader = ({readOnly}) => {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const router = useRouter();
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const numOfGuests = useAppSelector((state) => userRoleCountSelector(state, 'guest'));
    const numOfMembers = useAppSelector((state) => userRoleCountSelector(state, 'member'));
    const currentWorkspace = useAppSelector(selectCurrentWorkspace);
    const workspaceUserList = useAppSelector(getCurrentWorkspaceUserList);

    const snackbarMessage = useAppSelector((state) => state.TasksReducer.taskSnackbarMessage);
    const handleClose = useCallback(() => {
        dispatch(taskActions.setTaskSnackbarMessage(''));
    }, []);


    const onSearchChange = (query: string) => {
        dispatch(workspaceActions.setWorkSpaceUsersFilter(query))
    }

    function filterWorkspaceUser(query: string) {
        setEmail(query)
    }

    const handleCloseInviteDialog = useCallback(() => {
        setInviteModalOpen(false);
    }, []);

    const inviteUser = useCallback(async () => {
        setIsLoading(true);
        const res = await dispatch(
            DBWorkspaceUserThunks.create({
                email,
                role: 'member',
                status: 'pending',
                workspaceId: currentWorkspace._id
            })
        ).unwrap();
        if ((res as any).status === 500) {
            const error = (res as any).data;
            dispatch(taskActions.setTaskSnackbarMessage(error.message));
        }

        setEmail('');
        setIsLoading(false);
        setInviteModalOpen(false);

    }, [currentWorkspace?._id, dispatch, email]);

    const emailExists = useMemo(
        () =>
            workspaceUserList.find((user) => user?.email === email),
        [workspaceUserList, email]
    );



    const isValidEmail = useMemo(() => {
        const regex = /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/;
        return email.match(regex);
    }, [email]);

    const canInvite = useMemo(
        () => isValidEmail && !isLoading && !emailExists,
        [emailExists, isLoading, isValidEmail]
    );

    return (
        <>
            <div style={{ display: 'flex', gap: '32px' }}>
                <div style={{
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '21px',

                }}>
                    <PeopleAltOutlinedIcon sx={{ height: '15px', marginInlineEnd: "-4px" }} />
                    <div>
                        <span style={{
                            fontWeight: '700'
                        }}>{numOfMembers} </span>
                        <span> {t('userManagementHeader.members')}</span>
                    </div>
                    <BTButton onClick={() => {
                        router.navigate('/account-subscription')
                    }} style={{
                        color: '#FF46A0',
                        position: "relative",
                        top: "1.3px"
                    }} btvariant="text">
                        {t('userManagementHeader.upgrade')}
                    </BTButton>

                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '21px',
                }}>
                    <BiBriefcase />
                    <div>
                        <span style={{
                            fontWeight: '700'
                        }}>{numOfGuests} </span>
                        <span> {t('userManagementHeader.guestsFree')}</span>

                    </div>

                </div>
            </div>

            <Stack alignItems={"center"} direction="row" my={2}  >
                <Stack direction="row" spacing={4} alignItems={"center"} >

                    <BTInputField
                        style={{
                            width: "300px",
                        }}
                        onChange={(e) => onSearchChange(e.target.value)}
                        placeholder={t('userManagementHeader.searchMembersPlaceholder')}
                        sx={{
                            backgroundColor: 'var(--background-default)',
                        }}
                    />

                    <UserManagementFilter />
                    <UserManagementExport/>
           
                </Stack>


                <BTButton
                disabled={readOnly}
                    sx={{
                        width: '200px',
                        ml: 'auto',
                        ".MuiButton-startIcon": { marginInlineStart: '-4px', marginInlineEnd: '8px' }
                    }}
                    onClick={() => setInviteModalOpen(true)}
                    startIcon={<MdAdd />}
                >
                    {t('userManagementHeader.invite')}
                </BTButton>





            </Stack>

            <Dialog
                open={inviteModalOpen}
                onClose={() => setInviteModalOpen(false)}
            >
                <DialogTitle>{t('userManagementHeader.inviteDialogTitle')} <b>{currentWorkspace?.name ?? t('userManagementHeader.workspace')}</b></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('userManagementHeader.inviteDialogDescription')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('userManagementHeader.emailAddressLabel')}
                        type="email"
                        fullWidth
                        variant="standard"
                        value={email}
                        onChange={({ target }: any) => filterWorkspaceUser(target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <BTButton onClick={handleCloseInviteDialog} btvariant="text">{t('userManagementHeader.cancelButton')}</BTButton>
                    <BTButton disabled={!canInvite} onClick={inviteUser}>{t('userManagementHeader.inviteButton')}</BTButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={!!snackbarMessage}
                autoHideDuration={8000}
                onClose={handleClose}
                sx={{
                    "& .MuiPaper-root": {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }

                }}
            >
                <Alert severity="info" >
                    <Stack direction="row" alignItems={"center"}>

                        <Box>{snackbarMessage}</Box>
                        {snackbarMessage === t('userManagementHeader.seatLimitExceeded') && <Button sx={{ mr: "10px", textTransform: "capitalize", color: "inherit" }} variant="text" onClick={() => router.navigate('/account-subscription')}><span style={{ borderBottom: "1px solid #000", fontWeight: "bold" }}>{t('userManagementHeader.snackbarAddMoreSeats')}</span></Button>}


                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </Alert>
            </Snackbar>

        </>
    );
};
