import { useSendResetPasswordMutation } from "@reducers/backend-api/backend-api";
import React, { useEffect, useState } from "react";
import classes from "./forgot-password.form.module.scss";
import accessFormclasses from "../access-form.module.scss";
import BTButton from "@components/bt-button/bt-button";
import FormPatial from "../form-patial";
import FormHeader from "../form-header/header";
import Input from "../form-input/form-input";
import { Box } from "@mui/system";
import { mainSliceActions } from "@reducers/MainReducer";
import { useAppDispatch } from "@store/hooks";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@services/analytics-service";
import { useTranslation } from "react-i18next";
import { namespace } from "config/constants";


export interface ForgotPasswordFormProps { }

export interface ForgotPasswordFormState {
  email: string;
  loading: boolean;
  status: string;
}

export const ForgotPasswordForm = () => {
  const { t } = useTranslation(namespace);
  const analyticsService: AnalyticsService = useAnalyticsService();

  const dispatch = useAppDispatch()
  const [sendForgotPasswordLink] = useSendResetPasswordMutation();
  const [isErr, setIsErr] = useState<boolean>(false)
  const [state, setState] = useState<ForgotPasswordFormState>({
    email: "",
    loading: false,
    status: "",
  });

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      email: event.target.value,
    });
  };

  const sendResetLink = async () => {

    if (!state.email) {
      setIsErr(true)
      return
    } else {
      setIsErr(false)
    }

    setState({
      ...state,
      loading: true,
    });

    let res;
    try {
      res = await sendForgotPasswordLink(state.email).unwrap();
    } catch (e) {
      console.log('Problem sending reset passwordd')
    }

    setState({
      ...state,
      loading: false,
      status: res?.state,
    });
  };

  function handleFormState(target: string) {
    dispatch(mainSliceActions.setFormState(target));
  }



  const imagePath = state.status !== "" ?
    "/img/email_sent_illustrator.png" :
    "/img/forgot_password_illustrator.png"
    const screenTitle = state.status !== "" 
    ? t('forgotPassword.emailSent') 
    : t('forgotPassword.forgotPasswordTitle');
  const text = state.status !== "" 
    ? t('forgotPassword.followInstructions') 
    : t('forgotPassword.insertEmail');


  useEffect(() => {
    document.title = t('forgotPassword.title');
  }, [t]);

  return (
    <FormPatial imagePath={imagePath}>
      <div className={accessFormclasses.FormContainer}>
        <FormHeader text={text} />
        <form className={classes.form}>
          {state.status !== "" ? (
            <></>
          ) : (
            <Input
              value={state.email}
              onChange={onEmailChange}
              label={t('forgotPassword.accountEmail')}
              style={{ borderColor: isErr && !state.email ? '#f72371' : '' }}
              placeholder={t('forgotPassword.yourEmail')}
              errorMessage={isErr && !state.email ? t('forgotPassword.emailIsRequired') : ''}
            />
          )}

          <Box className={classes.FormFooter}>
            <BTButton
              disabled={state.loading}
              loading={state.loading}
              sx={{ width: 1, padding: '15px 0' }}
              onClick={() => {
                analyticsService.event("send_email_password_link", {
                  category: "access",
                  action: "send email password link",
                });
                sendResetLink();
              }}
            >
              {state.status !== "" ? t('forgotPassword.resendEmail') : t('forgotPassword.sendEmail')}
            </BTButton>
            <Box
              className={classes.FormLabelText}
              style={{
                marginTop: 16,
                cursor: 'pointer',
              }}
            >
              <span
                onClick={() => handleFormState('')}>
                {t('forgotPassword.backTo')} <span className={classes.ButtonLink}>{t('forgotPassword.login')}</span> {t('forgotPassword.page')}
              </span>
            </Box>
          </Box>
        </form>
      </div>
    </FormPatial>
  );
};

export default ForgotPasswordForm;
