import BTTextField from "@components/atoms/text-field/bt-text-field";
import BTThemeProvider from '@components/bt-theme-provider';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, Menu, MenuProps, Tooltip } from "@mui/material";
import { useAppDispatch } from '@store/hooks';
import React, { ReactElement, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Label } from "../../models/label";
import {
  chatFilterListLabelAddOrRemove,
  LabelsState
} from "../../reducers/LabelsReducer";
import {
  filterLabelsByTitle,
  POP_DIRECTIONAL_FORTMATTING_UNICODE
} from "../../reusable/common";
import { RootState } from "../../store";
import { theme } from "../../theme";
import { LabelItemsList } from "../label-items-list/label-items-list";
import classes from "./filter-labels-button.module.scss";


export interface FilterLabelsButtonProps { }
export interface FilterLabelsButtonState { }

const StyledMenu = (props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    transformOrigin={{ vertical: "top", horizontal: "center" }}
    {...props}
  />
);

export default function FilterLabelsButton(): ReactElement {
  const dispatch = useAppDispatch()
  const { globalLabelList, chatFilterLabelsIdList } = useSelector<
    RootState,
    LabelsState
  >((state) => state.LabelsReducer);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const filteredLabels: Array<Label> | undefined = useMemo(
    () => filterLabelsByTitle(globalLabelList, searchText),
    [globalLabelList, searchText]
  );

  function openDialog(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
    setSearchText("");
  }

  function onMouseDownHandle(e: any) {
    e.stopPropagation();
  }

  function handleClose() {
    setAnchorEl(null);
    setIsMenuOpen(false);
  }

  function onLabelItemClick(labelItem: Label) {
    dispatch(chatFilterListLabelAddOrRemove(labelItem));
  }

  function onTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newSearchValue: string = event.target.value.trim().toLowerCase();
    setSearchText(newSearchValue);
  }

  return (
    <BTThemeProvider>
      <Tooltip title="Open filter labels menu">
        <div onClick={openDialog} onMouseDown={onMouseDownHandle} style={{}}>
          <FilterListIcon fontSize="small" />
        </div>
      </Tooltip>
      <StyledMenu
        id="filter-labels-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
      >
        <div
          style={{
            padding: `${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
          }}
        >
          <p>{POP_DIRECTIONAL_FORTMATTING_UNICODE}Filter as:</p>
          <BTTextField
            className={classes.margin}
            id="filter-input-with-icon-textfield"
            onChange={onTextChange}
            value={searchText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {filteredLabels === undefined || filteredLabels.length === 0 ? (
          <p>No labels</p>
        ) : (
          <LabelItemsList
            initialLabelItems={filteredLabels.map(
              (label: Label, index: number) => ({
                label: label,
                selected:
                  chatFilterLabelsIdList?.indexOf(label?._id as string) !== -1,
              })
            )}
            onLabelItemClick={onLabelItemClick}
          />
        )}
      </StyledMenu>
    </BTThemeProvider>
  );
}
