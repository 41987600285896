import { Close } from "@mui/icons-material";
import { IconButton, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from 'react';

import BTDrawer from '@components/bt-drawer/bt-drawer';
import InviteUserModal from '@components/invite/InviteUserModal';
import { ExtraDataPanel } from '@components/tasks/extraData/ExtraDataPanel';
import ActivityLog from '@components/tasks/extraData/logs/activity-log';
import NotificationsDrawer from '@components/tasks/extraData/notifications/notifications-drawers';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { getCurrentBoard, isBoardPanelDrawerOpen } from '@reducers/BoardsSelectors';
import { DBBoardsThunks } from '@reducers/DBServiceReducers';
import { setExtraDataOfTaskId } from '@reducers/ExtraDataReducer';
import { taskActions } from '@reducers/TasksReducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { theme } from 'theme';
import classes from "./tasks-panel.module.scss";
import { updateAllUnseenNotificationsToSeenThunk } from "@reducers/NotificationsSelectors";
import { resetDisplayedNewNotifications } from "@reducers/MainReducer";

const ResizableDrawer = () => {
    const dispatch = useAppDispatch()

    const isDesktop = useMediaQuery(theme.breakpoints.up(786))
    const direction = useAppSelector((state) => state.UserReducer.direction);
    const boardPanelDrawerOpen = useAppSelector((state) => isBoardPanelDrawerOpen(state))
    const board = useAppSelector(getCurrentBoard);

    const taskViewSideDrawerOpen = useAppSelector(
        (state) => state.TasksReducer.taskViewSideDrawerOpen
    );
    const [drawerWidth, setDrawerWidth] = useState<number>();
    const drawerWidthRef = useRef<number>();
    const startResizing = (e) => {
        e.preventDefault();
        document.addEventListener('mousemove', resizeDrawer);
        document.addEventListener('mouseup', stopResizing);
    };

    const resizeDrawer = (e) => {
        const windowWidth = window.innerWidth;
        const currentMax = boardPanelDrawerOpen ? 310 : 90
        const maxWidth = windowWidth - currentMax
        let newWidth = windowWidth - e.clientX;

        newWidth = Math.max(newWidth, 500);
        newWidth = Math.min(newWidth, maxWidth);

        setDrawerWidth(newWidth);
        drawerWidthRef.current = newWidth;
    };
    const stopResizing = () => {
        dispatch(DBBoardsThunks.patch({
            entity: {
                _id: board._id,
                drawerWidth: drawerWidthRef.current
            }
        }));
        document.removeEventListener('mousemove', resizeDrawer);
        document.removeEventListener('mouseup', stopResizing);
    };

    function closeTaskViewSideDrawer(taskViewSideDrawerOpen) {
        dispatch(taskActions.setTaskViewSideDrawerOpenWith(""));
        if (taskViewSideDrawerOpen === 'ExtraDataPanel') {
            let url = new URL(window.location.href);
            url.searchParams.delete('taskId');
            url.searchParams.delete('updateId');
            window.history.pushState({ path: url.toString() }, '', url.toString());
            dispatch(setExtraDataOfTaskId(null))
        }
        if (taskViewSideDrawerOpen === 'notificationsDrawer') {
            dispatch(updateAllUnseenNotificationsToSeenThunk())
            dispatch(resetDisplayedNewNotifications())
          }
    }

    function handleEscapeKeyClose(e) {
        if (e.key === "Escape") {
            closeTaskViewSideDrawer(taskViewSideDrawerOpen);
        }
    }

    useEffect(() => {
        const initialWidth = board?.drawerWidth ? board.drawerWidth : 500;
        setDrawerWidth(initialWidth);
        drawerWidthRef.current = initialWidth;
        document.addEventListener("keydown", handleEscapeKeyClose);
        return () => {
            document.removeEventListener("keydown", handleEscapeKeyClose);
        };
    }, [board?.drawerWidth]);

    return (
        <BTDrawer variant={isDesktop ? "persistent" : 'temporary'} anchor={direction === "ltr" ? 'right' : 'left'} open={taskViewSideDrawerOpen !== ""}>
            <div className={classes.extraDataPanel} style={{
                background: taskViewSideDrawerOpen === "ExtraDataPanel" || taskViewSideDrawerOpen === "Help" ? "var(--extradata-background-color)" : "var(--secondary-background-color)",
                padding: taskViewSideDrawerOpen === "ExtraDataPanel" || taskViewSideDrawerOpen === "Help" ? "0" : "0 20px",
                overflow: "auto",
                height: "100%",
                width: drawerWidth,
                position: "relative"
            }}>

                <div className={`${classes.resizeHandle}`} onMouseDown={startResizing} > <DragIndicatorIcon sx={{ fontSize: "16px", color: "#000" }} /></div>

                <div style={{ background: taskViewSideDrawerOpen === "ExtraDataPanel" || taskViewSideDrawerOpen === "Help" ? "var(--default-background)" : "var(--secondary-background-color)" }}
                    className={classes.header}>
                    <IconButton sx={{ left: taskViewSideDrawerOpen === "ExtraDataPanel" ? "25px" : 0 }} onClick={() => closeTaskViewSideDrawer(taskViewSideDrawerOpen)}>
                        <Close />
                    </IconButton>
                </div>
                {taskViewSideDrawerOpen === "ExtraDataPanel" && <ExtraDataPanel />}
                {/* {taskViewSideDrawerOpen === "Help" && <HelpAndSupport />} */}

                {taskViewSideDrawerOpen === "InviteUserModal" && <InviteUserModal />}

                {taskViewSideDrawerOpen === "ActivityLog" && <ActivityLog />}
                
                {taskViewSideDrawerOpen === 'notificationsDrawer' && <NotificationsDrawer />}
            </div>
        </BTDrawer>
    );
};

export default ResizableDrawer;
