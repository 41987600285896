import { useState } from 'react';
import InputMask from 'react-input-mask';
import { useStopwatch, useTimer } from 'react-timer-hook';


// Shared UI Component
export function ClockUI({ days, hours, minutes, seconds, isRunning, onStart, onPause, onReset }) {
    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '100px' }}>
                <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
            </div>
            <p>{isRunning ? 'Running' : 'Not running'}</p>
            <button onClick={onStart}>Start</button>
            <button onClick={onPause}>Pause</button>
            <button onClick={onReset}>Reset</button>
        </div>
    );
}

// Timer Component
export function Timer({ expiryTimestamp, onTimerChange }) {
    const timer = useTimer({ expiryTimestamp, onExpire: () => console.warn('Timer expired') });

    return (
        <ClockUI
            days={timer.days}
            hours={timer.hours}
            minutes={timer.minutes}
            seconds={timer.seconds}
            isRunning={timer.isRunning}
            onStart={timer.start}
            onPause={timer.pause}
            onReset={() => {
                const time = new Date();
                time.setSeconds(time.getSeconds() + 300); // 5 minutes
                timer.restart(time);
                onTimerChange(time);
            }}
        />
    );
}

// Stopwatch Component
export function Stopwatch() {
    const stopwatch = useStopwatch({ autoStart: true });

    return (
        <ClockUI
            days={stopwatch.days}
            hours={stopwatch.hours}
            minutes={stopwatch.minutes}
            seconds={stopwatch.seconds}
            isRunning={stopwatch.isRunning}
            onStart={stopwatch.start}
            onPause={stopwatch.pause}
            onReset={stopwatch.reset}
        />
    );
}

// ClockUI Input Component
export function ClockUIInput({ onTimeChange }) {
    const [value, setValue] = useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
        onTimeChange(event.target.value); // Propagate the change up
    };

    return (
        <InputMask
            mask="99:99:99"
            value={value}
            onChange={handleChange}
            placeholder="HH:MM:SS"
        />
    );
}

export default function NewTabView2() {
    const [showTimer, setShowTimer] = useState(true);
    const [time, setTime] = useState(() => {
        let initialTime = new Date();
        initialTime.setSeconds(initialTime.getSeconds() + 600); // Initialize to 10 minutes
        return initialTime;
    });

    const handleTimeChange = (inputTime) => {
        // Convert HH:MM:SS format to a Date object
        const [hours, minutes, seconds] = inputTime.split(':').map(Number);
        let newTime = new Date();
        newTime.setHours(hours, minutes, seconds, 0);
        setTime(newTime);
    };

    return (
        <div>
            <h1>New Tab View 2</h1>
            <ClockUIInput onTimeChange={handleTimeChange} />
            {showTimer ? (
                <Timer expiryTimestamp={time} onTimerChange={setTime} />
            ) : (
                <Stopwatch />
            )}
            <button onClick={() => setShowTimer(!showTimer)}>
                {showTimer ? 'Switch to Stopwatch' : 'Switch to Timer'}
            </button>
        </div>
    );
}
