import { TasksGroup } from "@models/task-group";
import { Stack } from "@mui/system";
import { getCurrentBoard, doesGroupBelongToAudienceBoard } from "@reducers/BoardsSelectors";
import { taskgroupsSelectors } from "@reducers/DBServiceReducers";
import { useAppDispatch } from "@store/hooks";
import { ReactElement, memo, useCallback, useState } from 'react';
import { DragDropContext, DraggableProvided, DropResult, Droppable } from "react-beautiful-dnd";
import { useAppSelector } from "../../../store/hooks";
import GroupColumnItem from "./group-column-item";
import classes from "./group_header.module.scss";
import { columnListForDisplaySelector } from "@reducers/TaskSelectors";
import { updateColumnOrderAfterTaskDragEnd } from "@reducers/TaskThunks";
interface Props {
  groupId: string;
  provided: DraggableProvided;
}

function GroupHeader({ groupId, provided: parentProvided }: Props): ReactElement {
  const dispatch = useAppDispatch()
  const isAudienceBoard = useAppSelector((state) => doesGroupBelongToAudienceBoard(state, groupId));
  const isDisplayChat = !isAudienceBoard;

  const isGroupDragging = useAppSelector(state => state.TasksReducer.isGroupDragging)
  const [draggingOver, setDraggingOver] = useState<string | null>(null);

  const board = useAppSelector(getCurrentBoard)

  const tasksGroup: TasksGroup = useAppSelector((state) => {
    return taskgroupsSelectors.selectById(state, groupId)
  });


  // const collapsed = tasksGroup?.isCollapsed || isGroupDragging;
  //const activeColumn = useAppSelector((state) => state.TasksReducer.activeColumn)
  //const isSortPendingSave = useAppSelector((state) => state.TasksReducer.isSortPendingSave)

  let columnList = useAppSelector(state => columnListForDisplaySelector(state, tasksGroup?.boardId, groupId))
    .filter(col => (col.type === 'chat' && isDisplayChat) || (col.type !== 'chat'));

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination?.index === undefined) {
        return;
      }


      if (result.source.index === result.destination.index) {
        return;
      }


      const sourceEl = columnList[result.source?.index];
      const destinationEl = columnList[result.destination.index];

      if (destinationEl.order >= 1000) {
        return;
      }
      dispatch(updateColumnOrderAfterTaskDragEnd({
        sourceColumnId: sourceEl._id,
        destinationBoardId: destinationEl.boardId,
        destinationOrder: destinationEl.order,
        dragDirection: result.destination.index - result.source?.index
      }))
    }, [columnList, dispatch]);


  return (
    <DragDropContext
      onDragStart={(start: DropResult) => {
      }}
      onDragUpdate={(update: DropResult) => {
        setDraggingOver(update.destination?.droppableId || null);
      }}
      onDragEnd={onDragEnd}>
      <Droppable direction="horizontal" droppableId={`column_droppable_${tasksGroup?._id}`}  >
        {
          (droppableProvided, droppableSnapshot) => {
            return <Stack
              data-testid='column-header-row'
              direction="row"
              ref={droppableProvided.innerRef}
              className={classes.todoRow}
              {...droppableProvided.droppableProps}
            >

              {columnList.map((column, index) => {
                const isDraggingOver = draggingOver === `column_${tasksGroup?._id}_${column?._id}`;
                const columnKey = `column_${tasksGroup?._id}_${column._id}_${isDraggingOver}`
                return <GroupColumnItem
                  key={columnKey}
                  column={column}
                  index={index}
                  tasksGroup={tasksGroup}
                  board={board}
                  isGroupDragging={isGroupDragging}
                  columnListLength={columnList.length}
                  isDraggingOver={isDraggingOver}
                  parentProvided={parentProvided} />

              })}
              {droppableProvided.placeholder}
            </Stack>
          }
        }
      </Droppable>
    </DragDropContext>
  );
}

export default memo(GroupHeader);