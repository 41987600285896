import { Board } from "@models/board"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "@store/index"
import { bulkActions, getBulkTaskList } from "./BulkActionsReducer"
import { mainSliceActions } from "./MainReducer"
import { UndoRedoAction } from "./UndoRedoReducer"
import { doActionThunk } from "./UndoRedoThunks"

export const moveGroupToBoardThunk = createAsyncThunk<void, Board, { state: RootState }>
    ('bulkActions/moveGroupToBoardThunk', async (board, thunkAPI) => {
    const dispatch = thunkAPI.dispatch

    dispatch(bulkActions.setIsBulkActionsDialogOpen(false))
    
    dispatch(bulkActions.setMovingTo(""))
    dispatch(bulkActions.setGroupIsMoving(false))
    dispatch(mainSliceActions.toggleGroupMenu(''))

})

type DeleteTasksParams = {
    deletedOrArchived: string;
  };

  export const deleteOrArchiveSelectedTasks = createAsyncThunk<
  void, 
  DeleteTasksParams, 
  { state: RootState }
>
('bulkActions/deleteOrArchiveSelectedTasks', async (params, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();

    const bulkTaskList = getBulkTaskList(state);
    
    const action: UndoRedoAction<string[], string> = {
        type: 'delete-task-list',
        data: bulkTaskList.map(task => task._id),
        deletedOrArchived: params.deletedOrArchived  
    };

    dispatch(doActionThunk({ action }));
    dispatch(bulkActions.setBulkTasksMap({}));
})
