
import { ThemeProvider, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import classes from "./access-form.module.scss";
import ForgotPasswordForm from "./forgot-password-form/forgot-password-form";
import { LoginForm } from "./login-form/login-form";
import { SignupForm } from "./signup-form/signup-form";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@services/analytics-service";
import { useEffect } from "react";
import { mainSliceActions } from "@reducers/MainReducer";

export enum FormState {
  NULL,
  Login,
  Signup,
  ForgotPassword,
}

type AccessFormProps = {
  initialState?: FormState;
  headerTitle?: string;
  headerSubtitle?: string;
};

export default function AccessForm(props: AccessFormProps) {
  const dispatch = useAppDispatch();
  const analyticsService: AnalyticsService = useAnalyticsService();

  const formState = useAppSelector((state) => state.MainReducer.formState);
  const theme = useTheme();

  useEffect(() => {
    analyticsService.event("popup_access_form", {
      category: "popupAccess",
      action: "open popup access form",
    });
    let initState = "login"
    switch (props.initialState) {
      case FormState.Signup:
        initState = "SIGNUP"
        break;
      case FormState.ForgotPassword:
        initState = "RESET_PASSWORD"
        break;
      default:
    }

      dispatch(mainSliceActions.setFormState(initState))
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.accessFormContainer}>

      {formState === "SIGNUP" ? (
          <SignupForm />
        ) : formState === "RESET_PASSWORD" ? (
          <ForgotPasswordForm />
        ) : (
          <LoginForm />
        )}
      </div>
    </ThemeProvider>
  );
}




