import { Skeleton } from '@mui/material';
import { isExtensionContext } from '@services/detect-context';
import React, { useState, useEffect } from 'react';
import { FlagImage } from 'react-international-phone';

const incrementCodepoint = (codePoint: string, incrementBy: number): string => {
    const decimal = parseInt(codePoint, 16);
    return Number(decimal + incrementBy).toString(16);
};

const alphabet = 'abcdefghijklmnopqrstuvwxyz';

const A_LETTER_CODEPOINT = '1f1e6';
const codepoints: Record<string, string> = alphabet
    .split('')
    .reduce((obj, currentLetter, index) => {
        return {
            ...obj,
            // construct alphabet by incrementing A letter codepoint value
            [currentLetter]: incrementCodepoint(A_LETTER_CODEPOINT, index),
        };
    }, {});

/**
 * Flag emoji value contains 2 emojis which represents iso2 code
 * For example: '🇺🇦' -> U+1F1FA (U letter), U+1F1E6 (A letter)
 * For Twemoji we need to pass codepoints in format `1f1fa-1f1e6`
 */
const getFlagCodepointByIso2 = (iso2: string) => {
    return [codepoints[iso2[0]], codepoints[iso2[1]]].join('-');
};

interface FlagImageProps {
    iso2?: string;
    size?: string;
    className?: string;
    style?: React.CSSProperties;
}


export const ExtensionFlagImage = ({ iso2, size, className, style, ...restProps }: FlagImageProps) => {
    const [src, setSrc] = useState('');

    useEffect(() => {
        if (iso2) {
            if (isExtensionContext()) {

                const flagCodepoint = getFlagCodepointByIso2(iso2);
                const svgUrl = `https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/${flagCodepoint}.svg`;

                // Send a message to the background script to fetch the SVG
                chrome.runtime.sendMessage({ action: "fetchSVG", url: svgUrl }, (response) => {
                    if (response && response.data) {
                        // Encode SVG content as a data URL
                        const svgDataUrl = `data:image/svg+xml;utf8,${encodeURIComponent(response.data)}`;
                        setSrc(svgDataUrl);
                    }
                });
            } else {
                // Fetch SVG using the Fetch API
                const flagCodepoint = getFlagCodepointByIso2(iso2);
                const svgUrl = `https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/${flagCodepoint}.svg`;

                fetch(svgUrl)
                    .then(response => response.text())
                    .then(data => {
                        // Encode SVG content as a data URL
                        const svgDataUrl = `data:image/svg+xml;utf8,${encodeURIComponent(data)}`;
                        setSrc(svgDataUrl);
                    })
                    .catch(error => console.error('Error fetching SVG:', error));
            }
        }
    }, [iso2]);

    // Use the original FlagImage component with the fetched and encoded SVG as src
    return (
        <>
            {src ? (
                <FlagImage
                    iso2={iso2}
                    src={src}
                    size={size}
                    className={className}
                    style={style}
                    {...restProps}
                />
            ) : (
                <Skeleton style={{
                    ...style,
                    padding: '2px',
                    boxSizing: 'border-box'
                }} variant='rectangular' />
            )}
        </>
    );
}

export default ExtensionFlagImage;
