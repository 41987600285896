import BTBulkDashboard from "@components/bt-bulk-dashboard/bt-bulk-dashboard";
import { getMockChats, getMockContacts } from "@services/mock-contacts";
import { Chat, WhatsappContact } from "@services/whatsapp";
import { ReactElement, useCallback, useEffect } from "react";



export default function CampaignsViewWithContacts(props: any): ReactElement {

  const getMockData = useCallback(async () => {
    const contactList: Partial<WhatsappContact>[] = await getMockContacts();
    const chatList: Partial<Chat>[] = await getMockChats();

    window.postMessage(
      {
        cmd: "contactList",
        contactList,
        chatList
      },
      "*"
    );
  }, []);

  useEffect(() => {
    getMockData();

    localStorage.setItem("schedulerReady", "true");
  }, [getMockData]);


  return (
    <div >
      <BTBulkDashboard />
    </div>
  );
}

