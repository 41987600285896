import { AccessFormPopup } from '@components/access-form-popup/access-form-popup';
import { Board } from '@models/board';
import { Box, CircularProgress, Stack } from '@mui/material';
import { accessFormPopupActions } from '@reducers/AccessFormPopupReducer';
import { getCurrentBoards, getSelectedBoardId } from '@reducers/BoardsSelectors';
import { getBoardDataThunk, setSelectedBoardId } from '@reducers/BoardsThunks';
import { DBBoardsThunks } from '@reducers/DBServiceReducers';
import { selectNewTabFocusByOwnerId } from '@reducers/NewTabFocusThunks';
import { taskActions } from '@reducers/TasksReducer';
import { selectUser } from '@reducers/UserSelectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import TaskView from '@views/task-view/task-view';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FocusSearchInput from './focus-search-input';
import FocusBoardHeader from './focus-task-header';
import FocusTaskInput from './focus-task-input';
import TimerOrStopWatch from './focus-timer-or-stopwatch';

export default function NewTabView() {
    const dispatch = useAppDispatch();
    const [isTasksDataReady, setIsTasksDataReady] = useState<boolean>(false)
    const boards: Board[] = useSelector(getCurrentBoards, isEqual);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const user = useAppSelector(selectUser);
    const tabData = useAppSelector(selectNewTabFocusByOwnerId);


    useEffect(() => {
        async function fetchData() {
            dispatch(taskActions.setViewMode('tasks'));
            dispatch(taskActions.setCurrentBoardView({
                type: 'board',
                id: boardId
            }));
            dispatch(taskActions.setPersonFilter(""));
            await dispatch(getBoardDataThunk({ boardId }));
            setIsTasksDataReady(true)
            dispatch(taskActions.setIsNewTab(true))
        }
        if (boardId !== null && boardId !== undefined && boardId !== '') {
            fetchData()
        }
    }, [boardId,dispatch]);

    useEffect(() => {
        async function fetchBoards() {
            await dispatch(DBBoardsThunks.find({
                $paginate: false,
                deleted: false,
            }));

            if (boards.length > 0 && boardId === "") {
                dispatch(setSelectedBoardId({ boardId: boards[0]?._id }));
            }
        }
        fetchBoards()

    }, [boards,user, dispatch])





    return <Stack sx={{
        fontFamily: 'Poppins', paddingTop: "75px",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        background: "linear-gradient(66.67deg, #259AE9 0%, #6DD1F1 100%)",
        textAlign: "center",
        boxSizing: "border-box",
    }}>


        <>
            <FocusSearchInput />

            {tabData?.timerShown &&
                <Box sx={{
                    position: "absolute", left: "50%", transform: "translate(-50%,0)",
                    top: "12%", zIndex: "9"
                }} >

                    <TimerOrStopWatch />

                </Box>
            }

            <FocusTaskInput />

            {isTasksDataReady && <FocusBoardHeader />}

        </>




        {isTasksDataReady && boardId && tabData?.expanded &&
            <Box sx={{ height: "350px", width: '100%', maxWidth: '1200px', margin: '0 auto', borderRadius: "15px" }}>
                <TaskView
                    isDisplayTasksHeader={false}
                    isDisplaySharedUsersAvatar={false}
                    isDisplayShareButton={false}
                    isDisplayMenuButton={false}
                    isDisplayDarkBrightModeButton={false}
                    isDisplayTabs={false}
                    isDisplayTasksPanelHeader={false}
                    isDisplayNewGroupButton={false}
                    isDisplaySearchField={true}
                    isDisplayPersonButton={false}
                    isDisplayColumnsButton={true}
                    isDisplayImportButton={false}
                    isDisplayExportButton={false}
                    isDisplayBoardPanel={false}
                    isFromCampaign={true}
                    height={500}
                />
            </Box>



        }
            <AccessFormPopup />

    </Stack>


}











