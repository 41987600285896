import { getSelectedBoardId } from '@reducers/BoardsSelectors'
import { boardsSelectors } from '@reducers/DBServiceReducers'
import { useRouter } from '@reducers/hooks/use-router'
import { useAppSelector } from '@store/hooks'
import React from 'react'

import classes from './page-not-found.module.scss'

export default function PageNotFound() {
  const router = useRouter()
  const selectedBoardId = useAppSelector(getSelectedBoardId)
  const boards = useAppSelector((state) => boardsSelectors.selectAll(state))

  function handleNavigate() {
    if (router.query.boardID && !selectedBoardId && boards.length) {
      router.navigate(`/tasks/${boards[0]._id}`)
    } else {
      router.navigate('/tasks')
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <h1>Oops!</h1>
        <h3>404 - PAGE NOT FOUND</h3>
        <p>
          The page youre looking for might have been removed,
          had it's name changed or temporarily unavailable
        </p>
        <div className={classes.BackBtnWrapper}>
          <button onClick={handleNavigate} className={classes.GoBackBtn}>Go back to tasks page</button>
        </div>
      </div>
    </div>
  )
}

