import * as types from "../reusable/actions";
import store from "../store";
const init = {
  isEditing: false,
};

export default function InlineEditReducer(
  state = init,
  { type, payload }: any
) {
  switch (type) {
    default:
      return state;
  }
}
