// eslint-disable-next-line import/no-webpack-loader-syntax
import "!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import "!style-loader!css-loader!react-big-calendar/lib/addons/dragAndDrop/styles.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import "!style-loader!css-loader!./calendar-custom.css";

import {
  Calendar,
  EventProps,
  momentLocalizer,
  NavigateAction,
  stringOrDate,
  ToolbarProps,
  View
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import moment from "moment";

import {
  AppBar, Dialog, DialogContent, IconButton, Toolbar, Tooltip, Typography
} from "@mui/material";
import { useAppSelector, useAppThunkDispatch } from "@store/hooks";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { MessageConfirmationDialog } from "@components/add-scheduled-message-button/message-confirmation-dialog";
import BTThemeProvider from "@components/bt-theme-provider";
import {
  CherryPickOptions,
  getByWeekDay,
  getRuleValue,
  getWeekDay,
  parseOldUnits,
  patchRuleSet,
  toRuleSet
} from "@components/schedule-picker/recurrence-utils";
import { CalendarColors, UserMessage } from "@models/user-message";
import { Close } from "@mui/icons-material";
import { accessFormPopupActions } from "@reducers/AccessFormPopupReducer";
import {
  addScheduledMessageButtonActions
} from "@reducers/AddScheduledMessageButtonReducer";

import { selectUserObject } from "@reducers/UserSelectors";
import { MaybeUser } from "interfaces";
import SelectedEvent from "./selected-event";

import AddScheduledMessage from "@components/add-scheduled-message-button/add-scheduled-message";
import { DBUserMessageThunks, userMessagesSelectByQuery } from "@reducers/DBServiceReducers";
import { scheduleMessage } from "@reducers/WhatsappThunks";
import { Frequency, RRule, RRuleSet } from "rrule";
import { CustomDayBackground, CustomMonthDayBackground } from "./custom-day-background";
import { CustomDayCell } from "./custom-day-cell";
import { CustomEvent, CustomMonthEvent } from "./custom-message-event";
import CustomToolbar from "./custom-toolbar";
import MessageColor, { Color, defaultEventcolor } from "./message-color";


const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop<UserMessage>(Calendar);

interface CalendarEvent extends UserMessage {
  dropDate?: moment.Moment;
}
export type EventPL = UserMessage & Partial<DOMRect>;

export type confirmationDialogOption =
  | "All messages"
  | "This message"
  | "This and following messages";

export const confirmationDialogOptions: confirmationDialogOption[] = [
  "This message",
  "This and following messages",
  "All messages",
];

interface P {
  onClose: () => void;
}

function MessageCalendar(props: P) {
  const hoveredDate = useAppSelector((state) => state.WhatsAppReducer.hoveredDate);

  let messages: UserMessage[] = useAppSelector((state) =>
    userMessagesSelectByQuery(state.DBUserMessageReducer, {
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    })
  );

  const currentChat = useAppSelector(
    (state) => state.WhatsAppReducer.currentChat
  );
  const user: MaybeUser = useSelector(selectUserObject);

  //
  const [eventPL, setEventPL] = useState<EventPL>(null);
  const [showEventDialog, setShowEventDialog] = useState<boolean>(false);
  const [dndEvent, setDndEvent] = useState<CalendarEvent>(null);
  const [view, setView] = useState<View>("month");
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState<boolean>(false);
  const [showDndConfirmationDialog, setShowDndConfirmationDialog] =
    useState<boolean>(false);

  const [showColorsDialog, setShowColorsDialog] = useState<boolean>(false);
  const [showColorsConfirmationDialog, setShowColorsConfirmationDialog] =
    useState<boolean>(false);
  const [eventColor, setEventColor] = useState<string>();

  const [viewMonthStart, setViewMonthStart] = useState(
    moment().startOf("month")
  );

  const viewMonthStartStr = useMemo(
    () => viewMonthStart.toISOString(),
    [viewMonthStart]
  );

  const onNavigate = useCallback(
    (newDate: Date, view: View, action?: NavigateAction): void => {
      const start = moment(newDate).startOf("month");
      if (start.month() !== viewMonthStart.month()) {
        setViewMonthStart(start);
      }
    },
    [viewMonthStart]
  );

  const events = useMemo(() => {
    const rangeStart = moment
      .utc(moment(viewMonthStartStr).subtract(1, "week"))
      .toDate();
    const rangeEnd = moment
      .utc(moment(viewMonthStartStr).endOf("month").add(1, "week"))
      .toDate();

    let nonRecurring = messages.filter(
      (message) =>
        !message.isRecurring &&
        moment(message.dueDate).isBetween(rangeStart, rangeEnd)
    );

    let recurring: UserMessage[] = [];

    messages
      .filter((message) => message.isRecurring)
      .forEach((m) => {
        let originalRuleset: RRuleSet = m.rruleset
          ? toRuleSet(m.rruleset) // Make sure toRuleSet parses the string into an RRuleSet
          : parseOldUnits(m.recurenceUnit, m.recurrenceQuantity); // Assume this returns an RRuleSet

        // Use moment.js to parse m.dueDate and convert it to a Date object, ensuring UTC
        let correctDtStart = moment.utc(m.dueDate).toDate();

        // Assuming you have a way to reconstruct or access the rules (and exdates if any) from the original RRuleSet:
        // Create a new RRuleSet
        let ruleset = new RRuleSet();

        // For each original rule, re-create it with the correct dtstart
        originalRuleset.rrules().forEach(rule => {
          let newRuleOptions = rule.origOptions;
          newRuleOptions.dtstart = correctDtStart;

          // Create a new rule with corrected options and add to the new ruleset
          ruleset.rrule(new RRule(newRuleOptions));
        });

        // If there are exclusion dates, add them to the new ruleset as well
        originalRuleset.exdates().forEach(exdate => {
          ruleset.exdate(exdate);
        });

        // Get excluded dates from the ruleset
        const excludedDates = ruleset.exdates().map(ed => moment(ed).startOf('minute').valueOf());
        const _excludedDates = ruleset.exdates()
        // Get in-range dates that are not excluded
        const inRangeDates = ruleset.between(rangeStart, rangeEnd)
          .filter((date) => {
            // Compare dates down to the minute for exclusion
            console.log(_excludedDates)
            const a = ruleset.between(rangeStart, rangeEnd)
            const dateValue = moment(date).startOf('minute').valueOf();
            return !excludedDates.includes(dateValue);
          });

        // Create message objects for each in-range date that is not excluded
        const rec = inRangeDates.map((d) => {
          const due = moment(d).startOf("minute").toISOString();
          return { ...m, dueDate: due } as UserMessage;
        });

        // Add the non-excluded recurring messages to the array
        recurring = [...recurring, ...rec];
      });

    // colors
    nonRecurring = nonRecurring.map((v) => ({
      ...v,
      messageColor: v.messageColor || defaultEventcolor,
    }));

    recurring = recurring.map((v) => {
      if (v.calendarColors) {
        const { defaultColor, ranges, specific } = v.calendarColors;

        const specificColor = specific.find((s) => s.messageTime === v.dueDate);
        if (specificColor) return { ...v, messageColor: specificColor.color };

        const sorted = [...ranges]?.sort((a, b) =>
          moment(a.fromTime).isBefore(moment(b.fromTime)) ? 1 : -1
        );
        const rangeColor = sorted?.find((s) =>
          moment(s.fromTime).isSameOrBefore(moment(v.dueDate))
        );
        if (rangeColor) return { ...v, messageColor: rangeColor.color };

        if (defaultColor) return { ...v, messageColor: defaultColor };
      }
      return { ...v, messageColor: defaultEventcolor };
    });

    const events = [...nonRecurring, ...recurring];

    return events;
  }, [messages, viewMonthStartStr]);

  const onSelectEvent = (
    event: UserMessage,
    e: SyntheticEvent<HTMLElement, Event>
  ): void => {
    const { left, top, bottom, right } =
      e.currentTarget.getBoundingClientRect();
    setEventPL({ ...event, calendarMessage: true, left, top, bottom, right });
    setShowEventDialog(true);
  };

  const dispatch = useAppThunkDispatch();

  // existing event
  const editEvent = () => {
    dispatch(addScheduledMessageButtonActions.editCalendarMessage(eventPL));
    setShowEventDialog(false);
  };

  const deleteEvent = () => {
    eventPL.isRecurring
      ? setShowDeleteConfirmationDialog(true)
      : dispatch(DBUserMessageThunks.delete({ entity: eventPL }));

    setShowEventDialog(false);
    setShowColorsDialog(false);
  };

  const closeEvent = () => {
    setShowEventDialog(false);
  };
  const onEventDrop = ({
    event,
    start,
  }: {
    event: UserMessage;
    start: stringOrDate;
  }) => {
    const dropDate = moment.utc(start).startOf("minute");
    if (dropDate.isSame(event.dueDate)) return;

    setDndEvent({ ...event, dropDate });

    event.isRecurring
      ? setShowDndConfirmationDialog(true)
      : dispatch(
        scheduleMessage({ ...event, dueDate: dropDate.toISOString() })
      );
  };

  const onDndRecurringDialogClose = (value?: confirmationDialogOption) => {
    if (value) {
      const { _id, dueDate, dropDate } = dndEvent;
      let original = messages.find((m) => m._id === _id);
      const originalRuleset = toRuleSet(original?.rruleset);

      if (value === "This message") {
        originalRuleset.exdate(moment.utc(dueDate).startOf("minute").toDate());
        original = { ...original, rruleset: originalRuleset.toString() };
        delete dndEvent._id;
        dndEvent.isRecurring = false;
        delete dndEvent.rruleset;
        delete dndEvent.recurenceUnit;
        delete dndEvent.recurrenceQuantity;
        delete dndEvent.dropTime;
        delete dndEvent.dueTime;
        dndEvent.dueDate = dropDate.toISOString();

        dispatch(scheduleMessage(original));
        dispatch(scheduleMessage(dndEvent));
      } else if (value === "This and following messages") {
        original = {
          ...original,
          rruleset: patchRuleSet(originalRuleset, {
            until: moment.utc(dueDate).subtract(1, "minute").toDate(),
          }).toString(),
        };

        let patchParams: Partial<CherryPickOptions> = {
          dtstart: dropDate.toDate(),
        };

        const freq = getRuleValue(originalRuleset, "freq");
        if (freq === Frequency.WEEKLY) {
          patchParams = { ...patchParams, byweekday: getWeekDay(dropDate) };
        } else if (freq === Frequency.MONTHLY) {
          if (getRuleValue(originalRuleset, "bymonthday")) {
            patchParams = { ...patchParams, bymonthday: dropDate.date() };
            delete patchParams.byweekday;
          } else {
            patchParams = { ...patchParams, byweekday: getByWeekDay(dropDate) };
            delete patchParams.bymonthday;
          }
        }

        let newEvent = {
          ...dndEvent,
          rruleset: patchRuleSet(originalRuleset, patchParams).toString(),
        };
        delete newEvent._id;
        delete newEvent.dropDate;

        // handle range colors
        const { ranges } = original.calendarColors;
        const rangeColorIdx = ranges.findIndex((r) => r.fromTime === dueDate);
        if (rangeColorIdx !== -1) {
          const originalUpdatedRanges = [...ranges];
          originalUpdatedRanges.splice(rangeColorIdx, 1);
          original = {
            ...original,
            calendarColors: {
              ...original.calendarColors,
              ranges: originalUpdatedRanges,
            },
          };

          newEvent = {
            ...newEvent,
            calendarColors: {
              ...original.calendarColors,
              ranges: [
                ...original.calendarColors.ranges,
                {
                  fromTime: dropDate.toISOString(),
                  color: ranges[rangeColorIdx].color,
                },
              ],
            },
          };
        }

        dispatch(scheduleMessage(original));
        dispatch(scheduleMessage(newEvent));
      } else {
        original = {
          ...original,
          rruleset: patchRuleSet(originalRuleset, {
            until: moment.utc(dueDate).subtract(1, "minute").toDate(),
          }).toString(),
        };

        const { ranges } = original.calendarColors;
        const rangeColorIdx = ranges.findIndex((r) => r.fromTime === dueDate);
        if (rangeColorIdx !== -1) {
          const originalUpdatedRanges = [...ranges];
          originalUpdatedRanges.splice(rangeColorIdx, 1);
          original = {
            ...original,
            calendarColors: {
              ...original.calendarColors,
              ranges: originalUpdatedRanges,
            },
          };
        }

        dispatch(scheduleMessage(original));
      }
    }
    setShowDndConfirmationDialog(false);
  };

  const onDeleteRecurringDialogClose = (value?: confirmationDialogOption) => {
    if (value) {
      const { _id, dueDate } = eventPL;
      let original = messages.find((m) => m._id === _id);
      const originalRuleset = toRuleSet(original.rruleset);

      if (value === "This message") {
        originalRuleset.exdate(moment.utc(dueDate).startOf("minute").toDate());
        original = { ...original, rruleset: originalRuleset.toString() };
        dispatch(scheduleMessage(original));
      } else if (value === "This and following messages") {
        const patched = patchRuleSet(originalRuleset, {
          until: moment.utc(dueDate).subtract(1, "minute").toDate(),
        });
        original = { ...original, rruleset: patched.toString() };
        dispatch(scheduleMessage(original));
      } else {
        dispatch(DBUserMessageThunks.delete({ entity: original }));
      }
    }

    setShowDeleteConfirmationDialog(false);
  };

  const onColorsRecurringDialogClose = (value?: confirmationDialogOption) => {
    if (value) {
      const { _id, dueDate } = eventPL;
      let original = messages.find((m) => m._id === _id);
      let colors =
        original.calendarColors ??
        ({ specific: [], ranges: [] } as CalendarColors);

      if (value === "This message") {
        original = {
          ...original,
          calendarColors: {
            ...colors,
            specific: [
              ...colors.specific,
              {
                messageTime: dueDate,
                color: eventColor,
              },
            ],
          },
        };
      } else if (value === "This and following messages") {
        original = {
          ...original,
          calendarColors: {
            ...colors,
            ranges: [
              ...colors.ranges,
              {
                fromTime: dueDate,
                color: eventColor,
              },
            ],
          },
        };
      } else {
        original = {
          ...original,
          calendarColors: {
            defaultColor: eventColor,
            specific: [],
            ranges: [],
          },
        };
      }
      dispatch(scheduleMessage(original));
    }
    setShowColorsConfirmationDialog(false);
  };

  // new event
  const handleNewMessage = useCallback(
    (dueDate = moment()) => {
      if (!user) {
        dispatch(accessFormPopupActions.setStatus("Signup"));
        return;
      }

      dispatch(
        addScheduledMessageButtonActions.editMessage({
          dueDate: dueDate.startOf("minute").toISOString(),
          message: "",
          isRecurring: false,
          cancelIfReceived: false,
          contactList: [
            {
              ...currentChat,
              id: currentChat?.id?._serialized,
              name: currentChat?.name ?? currentChat?.displayName,
            },
          ],
          calendarMessage: true,
        } as UserMessage)
      );
    },
    [currentChat, dispatch, user]
  );

  const onSelectSlot = (slotInfo) => {
    let slotStart = moment(hoveredDate ?? slotInfo.start);
    slotStart.hour(12).minute(0).second(0);
    handleNewMessage(slotStart);
  };

  const onView = (view: View) => {
    setView(view);
  };

  const onRightClick = (pl: EventPL) => {
    setEventPL(pl);
    setShowColorsDialog(true);
  };

  const onCloseColorDialog = () => {
    setShowColorsDialog(false);
  };

  const components = useMemo(
    () => ({
      toolbar: (v: ToolbarProps<UserMessage, any>) => (
        <CustomToolbar toolbarProps={v} />
      ),
      month: {
        event: (v: EventProps<UserMessage>) => (
          <CustomMonthEvent eventProps={v} onRightClick={onRightClick} />
        ),
        dateHeader: ({ date, label }) => (
          <CustomDayCell date={date} label={label} />
        ),
        dateCellWrapper: ({ value }) => (
          <CustomMonthDayBackground date={value} />
        ),
      },
      week: {
        event: (v: EventProps<UserMessage>) => (
          <CustomEvent eventProps={v} onRightClick={onRightClick} />
        ),
        timeSlotWrapper: ({ children, value }) => (
          <CustomDayBackground date={value}  >{children}</CustomDayBackground>
        ),
      },
      day: {
        event: (v: EventProps<UserMessage>) => (
          <CustomEvent eventProps={v} onRightClick={onRightClick} />
        ),
        timeSlotWrapper: ({ children, value }) => (
          <CustomDayBackground date={value}  >{children}</CustomDayBackground>
        ),
      },
    }),
    []
  );

  const onColorChange = (c: Color) => {
    setEventColor(c);
    if (eventPL.isRecurring) {
      setShowColorsConfirmationDialog(true);
    } else {
      dispatch(scheduleMessage({ ...eventPL, messageColor: c }));
    }

    setShowColorsDialog(false);
  };

  return (
    <BTThemeProvider>
      <Dialog open={true} fullScreen sx={{ direction: 'ltr' }}>
        <AppBar position="static">
          <Toolbar sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              sx={{ margin: "auto", color: "var(--calendar-title-color)" }}
            >
              Messages Calendar
            </Typography>
            <Tooltip title={"Close"}>
              <IconButton
                size="large"
                sx={{
                  mr: 2,
                  position: "absolute",
                  right: 0,
                  color: "var(--calendar-title-color)",
                }}
                onClick={props.onClose}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <DnDCalendar
            key={view}
            localizer={localizer}
            events={events}
            titleAccessor="message"
            startAccessor={(m) => moment(m.dueDate).toDate()}
            endAccessor={(m) => moment(m.dueDate).add(30, "minute").toDate()}
            onNavigate={onNavigate}
            onSelectEvent={onSelectEvent}
            view={view}
            onView={onView}
            resizable={false}
            selectable={true}
            onSelectSlot={onSelectSlot}
            onEventDrop={onEventDrop}
            components={components}
          // showAllEvents={true}
          />

          {showEventDialog && (
            <SelectedEvent
              event={eventPL}
              onClose={closeEvent}
              onEdit={editEvent}
              onDelete={deleteEvent}
            />
          )}
        </DialogContent>
      </Dialog>
      {showDeleteConfirmationDialog && (
        <MessageConfirmationDialog
          title="Delete recurring message"
          options={confirmationDialogOptions}
          onClose={onDeleteRecurringDialogClose}
        />
      )}
      {showDndConfirmationDialog && (
        <MessageConfirmationDialog
          title="Edit recurring message"
          options={confirmationDialogOptions.slice(0, 2)}
          onClose={onDndRecurringDialogClose}
        />
      )}
      {showColorsDialog && (
        <MessageColor
          event={eventPL}
          onClose={onCloseColorDialog}
          onDelete={deleteEvent}
          onChange={onColorChange}
        />
      )}
      {showColorsConfirmationDialog && (
        <MessageConfirmationDialog
          title="Edit recurring message color"
          options={confirmationDialogOptions}
          onClose={onColorsRecurringDialogClose}
        />
      )}

      <AddScheduledMessage />
    </BTThemeProvider>
  );
}

export default MessageCalendar;
