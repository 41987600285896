import RowStack from "@components/common/row-stack/RowStack"
import { Box, Chip, LinearProgress, Stack } from "@mui/material"
import { setActiveBulkView, setActiveTab, toggleBulkDashboardDisplay } from "@reducers/CampaignReducer"
import { selectCampaignNumberOfContacts, selectIsCampaignRunning, selectRunningCampaignSentMessageCount } from "@reducers/CampaignSelectors"
import { taskActions } from "@reducers/TasksReducer"
import { useAppDispatch, useAppSelector } from "@store/hooks"

export const RunningCampaignIndicator = () => {
    const dispatch = useAppDispatch()

    const isRunningCampaign = useAppSelector(state => selectIsCampaignRunning(state))
    const numberOfContacts = useAppSelector(state => selectCampaignNumberOfContacts(state))
    const numberOfSentMessages = useAppSelector(state => selectRunningCampaignSentMessageCount(state))

    const manageCampaignEntities = () => {
        dispatch(taskActions.setIsTasksPanelOpen(false));
        dispatch(setActiveTab("manageCampaigns"))
        dispatch(setActiveBulkView('campaignManagementView'))
        dispatch(toggleBulkDashboardDisplay());
    }

    return (
        <>
            {isRunningCampaign && <Stack spacing={0.5}
                onClick={manageCampaignEntities}
                sx={{
                    cursor: 'pointer',
                }}>
                <RowStack spacing={1}>
                    <Box>Campaign</Box>
                    {<Chip sx={{ cursor: 'pointer' }} label={`${numberOfSentMessages}/${numberOfContacts} sent`} />}
                </RowStack>
                <LinearProgress />
            </Stack>}
        </>
    )
}

export default RunningCampaignIndicator