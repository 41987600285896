import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { taskActions } from '@reducers/TasksReducer';
import { handleUpload } from '@services/upload';
import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react'
import { CirclePicker } from 'react-color';
import { BiImageAdd } from 'react-icons/bi';
import { groupcolors } from 'theme';
import classes from "@components/tasks-panel/form/bt-color-picker.module.scss";
import { useAppDispatch } from '@store/hooks';
import { namespace } from 'config/constants';
import { useTranslation } from 'react-i18next';


type Props = {
    handleColorChange: ({ hex }) => void;
    handlePhotoChange: (url: string) => void;
}

export default function PhotoOrColorSelect({
    handlePhotoChange,
    handleColorChange,
}: Props) {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const fileRef: any = useRef();
    const [loading, showLoader] = useState(false);

    function addFiles(e) {
        e.stopPropagation();
        fileRef.current.files = null;
        fileRef.current.click();
    }

    const uploadFiles = useCallback(async (files: any) => {
        if (files) {
            const filesArray = [...files];
            const [file] = filesArray;
            if (file.size >= 4000000) {
                dispatch(taskActions.setTaskSnackbarMessage(t('photoOrColorSelect.cannotUploadBigFiles')))
            }
            const fileType = file.type
            if (!fileType.includes('image')) {
                dispatch(taskActions.setTaskSnackbarMessage(t('photoOrColorSelect.onlyImages')))
            }
            else {
                const promises = filesArray.map(async (file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        const mimeType: string = file.type;
                        const filename: string = file.name;
                        const fileDetails = {};
                        reader.addEventListener("load", async () => {
                            const base64file: any = reader.result;
                            const urlLink = await uploadFile(base64file, mimeType);
                            fileDetails["url"] = urlLink;
                            fileDetails["type"] = mimeType;
                            fileDetails["filename"] = filename;
                            fileDetails["updatedAt"] = moment().format('DD/MM/YYYY HH:mm:ss');
                            fileDetails["version"] = 1;
                            fileDetails["versions"] = [{ url: urlLink, type: mimeType, filename, updatedAt: moment().format('DD/MM/YYYY HH:mm:ss'), version: 1 }]
                            resolve(fileDetails);
                        });
                        reader.onerror = reject;
                        reader.readAsDataURL(file);
                    });
                });
                let currentFilesUploaded: any = await Promise.all(promises);
                return currentFilesUploaded;
            }
        }
    }, [dispatch, t])

    const onNewFilesAdded = useCallback(async (newFiles) => {
        showLoader(true);
        const [fileListUploaded] = await uploadFiles(newFiles);
        const uploadedFileUrl = JSON.stringify(fileListUploaded.url).split(`"`)[1]
        if (fileListUploaded) {
            handlePhotoChange(uploadedFileUrl)
        }
        showLoader(false);
    }, [handlePhotoChange, uploadFiles])

    const uploadFile = async (base64file: string, mimeType: string) => {
        try {
            const response: any = await handleUpload(base64file, mimeType);
            if (response.uploadResponse.url) {
                return response.image_url;
            }
        } catch (err) {
            console.log(err);
        }
    };



    return (
        <Stack direction='column' sx={{
            p: 3,
        }}
        >
            <div style={{
                marginBottom: '14px',
                paddingBottom: '10px',
                borderBottom: '1px solid var(--border-color)'
            }}>
                <Button
                    onClick={(e) => {
                        addFiles(e);
                    }}
                    startIcon={<BiImageAdd />}
                    style={{
                        cursor: 'pointer',
                        textTransform: 'none',
                        border: '1px dashed var(--search-input-border)',
                        borderRadius: 8,
                        height: 56,
                        color: 'var(--text-color)',
                        paddingInline: '20px',
                        width: 200,
                        maxWidth: 200,
                        fontFamily: 'inherit',
                        fontWeight: 600,
                    }}
                    value="upload image">
                    {t('photoOrColorSelect.uploadImage')}
                </Button>


                <p style={{
                    marginTop: 6,
                    textAlign: 'center',
                    fontSize: 11,
                    color: 'var(--text-color)',
                }}>
                </p>
                <input
                    onChange={(e) => {
                        onNewFilesAdded(e.target.files);
                    }}
                    ref={fileRef}
                    type="file"
                    multiple={false}
                    hidden
                />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <CirclePicker
                    onChange={handleColorChange}
                    width="180px"
                    circleSize={20}
                    circleSpacing={10}
                    colors={groupcolors}
                />
            </div>
        </Stack>
    )
}