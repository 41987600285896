import { Dialog, DialogContent, Stack } from '@mui/material';
import { paymentFormActions } from '@reducers/PaymentFormReducer';
import { closePaymentDialogThunk } from '@reducers/PaymentFormThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import ThankYouView from '@views/thank-you-view/thank-you-view';
import { namespace } from 'config/constants';
import { useTranslation } from 'react-i18next';

export default function PaymentThankYouPopup() {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch();
    const purchaseSuccess = useAppSelector((state) => state.PaymentFormReducer.paymentSuccess);
    const closeDialog = () => {
        dispatch(closePaymentDialogThunk())
        dispatch(paymentFormActions.setPaymentSuccess(false))
    }

    return (
        <Dialog fullScreen sx={{
            p: 3
        }} open={purchaseSuccess} onClose={closeDialog}>
            <DialogContent sx={{ background: 'linear-gradient(180deg, var(--default-background) 0%, #E8F9FD 100%)', display: 'flex' }}>
                <Stack sx={{ width: 1 }} justifyContent={"center"}>
                    <ThankYouView />
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

