import BTTextField from "@components/atoms/text-field/bt-text-field";
import { Delete } from "@mui/icons-material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputIcon from '@mui/icons-material/Input';
import { Avatar, Box, Button, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tab, Tabs, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { appConfig } from "../../../config/app.config";
import * as mimeDb from 'mime-db';

export function getMimeType(fileExtension: string): string | undefined {
  for (const [mimeType, info] of Object.entries(mimeDb)) {
    if ((info as any).extensions?.includes(fileExtension.slice(1))) {
      return mimeType;
    }
  }
  return undefined;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function ApiPlaygroundView() {
  const accessToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJzdWJqZWN0IjoiNWY0MjdiNDE5NmFhYjAwMDE3NTViYThkIiwiaWF0IjoxNzEyNzUxMTYxLCJleHAiOjQ4Njg1MTExNjEsImF1ZCI6Imh0dHBzOi8vYmx1ZXRpY2tzLmNvIiwiaXNzIjoiZmVhdGhlcnMiLCJzdWIiOiI1ZjQyN2I0MTk2YWFiMDAwMTc1NWJhOGQiLCJqdGkiOiI1YTBlODc1YS0zYmFjLTRjNWItYmY0OS0yOGJlNGMxYjVhODgifQ.S-54Js9aVjWv2NbLSMsjdUqwDmrDPdBLizn7SJzRWjI";
  const apiKey = "sb03aY86FU";
  const headers = { Authorization: `Bearer ${accessToken}` };
  const [tabValue, setTab] = useState(0);
  const [message, setMessage] = useState("");
  const [targetNumber, setTargetNumber] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [attachmentMimeType, setAttachmentMimeType] = useState("");
  const [webhookList, setWebhookList] = useState([]);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [callbackUrl, setCallbackUrl] = useState("");

  const getWebHooks = async () => {
    const response = await axios.get(`${appConfig.API_ENDPOINT}/webhooks`, {
      params: { $limit: 100 },
      headers,
    });
    console.log(response.data);
    setWebhookList(response?.data?.data ?? []);
  };

  useEffect(() => {
    getWebHooks();
  }, []);

  const tabHandleChange = (
    event: React.ChangeEvent<{}>,
    tabNewValue: number
  ) => {
    setTab(tabNewValue);
  };

  const sendMessage = () => {
    console.log("sending message");

    axios.post(`${appConfig.API_ENDPOINT}/messages`, {
      apiKey,
      to: targetNumber,
      message,
      asset: {
        url: attachmentUrl,
        type: attachmentMimeType,
      },
      callbackUrl,
    });
  };

  const addHook = async () => {
    await axios.post(
      `${appConfig.API_ENDPOINT}/webhooks`,
      {
        url: webhookUrl,
      },
      {
        headers,
      }
    );
    getWebHooks();
  };

  const deleteHook = async (webhook: any) => {
    await axios.delete(`${appConfig.API_ENDPOINT}/webhooks/${webhook._id}`, {
      headers,
    });
    getWebHooks();
  };

  const formattedRequest = () =>
    `fetch("${appConfig.API_ENDPOINT}/messages", 
      {
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          apiKey: "sb03aY86FU",
          to: "${targetNumber}",
          message: "${message}",${attachmentUrl
      ? `asset: {
            url: "${attachmentUrl}",
            type: "${attachmentMimeType}"
          },`
      : ""
    }
        }),
        method: "POST"
      }
    )`;

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          marginBottom: "24px",
        }}
      >
        <div>
          <img alt="logo" src="img/logo.png"></img>

          <h1 style={{ margin: 0 }}>Try our API</h1>
        </div>

        <h2 style={{ margin: "0 0 15px 0" }}>
          ⚙️ Build amazing apps, faster 🚀
        </h2>

        <a
          href="https://documenter.getpostman.com/view/800229/TVsrG9VS"
          target="_blank"
          rel="noreferrer"
        >
          Click here for full Documentation
        </a>
      </div>

      <Box
        p={3}
        style={{
          paddingBottom: "0",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={tabHandleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Send Messages" />
          <Tab label="Read messages" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <BTTextField
          label="Insert full phone number (with country prefix)"
          value={targetNumber}
          onChange={(event) => setTargetNumber(event.target.value)}
          variant="outlined"
          inputProps={{ style: { padding: "18.5px 14px" } }}
          style={{
            width: "100%",
          }}
        />
        <Divider
          style={{
            marginTop: "12px",
            marginBottom: "12px",
          }}
        />
        <BTTextField
          id="outlined-multiline-static"
          label="Type a message"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          multiline
          rows={4}
          variant="outlined"
          inputProps={{
            dir: "auto",
          }}
          style={{
            width: "100%",
          }}
        />

        <BTTextField
          label="Insert file URL"
          value={attachmentUrl}
          onChange={(event) => {
            setAttachmentMimeType(getMimeType(event.target.value) || "");
            setAttachmentUrl(event.target.value);
          }}
          variant="outlined"
          inputProps={{ style: { padding: "18.5px 14px" } }}
          style={{
            width: "100%",
            marginTop: "12px",
          }}
        />

        <BTTextField
          label="MIME type"
          value={attachmentMimeType}
          onChange={(event) => setAttachmentMimeType(event.target.value)}
          variant="outlined"
          inputProps={{ style: { padding: "18.5px 14px" } }}
          style={{
            width: "100%",
            marginTop: "12px",
          }}
        />

        <BTTextField
          label="Callback URL (Optional)"
          value={callbackUrl}
          onChange={(event) => setCallbackUrl(event.target.value)}
          variant="outlined"
          inputProps={{ style: { padding: "18.5px 14px" } }}
          style={{
            width: "100%",
            marginTop: "12px",
          }}
        />

        <div></div>
        <Button
          onClick={sendMessage}
          variant="contained"
          color="primary"
          style={{ marginTop: "12px" }}
        >
          Send Message
        </Button>

        <SyntaxHighlighter language="javascript" style={docco}>
          {formattedRequest()}
        </SyntaxHighlighter>
        <Button
          variant="outlined"
          onClick={() => navigator.clipboard.writeText(formattedRequest())}
        >
          Copy to clipboard
        </Button>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div>
          <BTTextField
            label="Type your hook address"
            value={webhookUrl}
            onChange={(event) => setWebhookUrl(event.target.value)}
            variant="outlined"
            inputProps={{ style: { padding: "18.5px 14px" } }}
            style={{
              width: "100%",
            }}
          />
          <a
            target="_blank"
            href="/webbook-guide"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              color: "#686868",
            }}
          >
            <h4 style={{ margin: "5px 0 10px 0" }}>
              For explantion how to create quick webhook - press here
            </h4>
            <IconButton edge="end" aria-label="delete">
              <HelpOutlineIcon />
            </IconButton>
          </a>

          <Button onClick={addHook} variant="contained" color="primary">
            Add Hook
          </Button>
          <List>
            {webhookList.map((webhook: any) => (
              <ListItem button key={webhook.url}>
                <ListItemAvatar>
                  <Avatar>
                    <InputIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={webhook.url} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => deleteHook(webhook)}
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </TabPanel>
      <div
        style={{
          textAlign: "center",
          backgroundImage: `url(img/api-bg.svg)`,
          backgroundSize: "contain",
          marginBottom: "24px",
          minHeight: "150px",
        }}
      ></div>
    </div>
  );
}
