import BTButton from '@components/bt-button/bt-button';
import BTThemeProvider from '@components/bt-theme-provider';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Button, CircularProgress, Dialog, DialogContent, IconButton, Slide, Stack, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Box } from '@mui/system';
import { toggleHowToScanQrModal, toggleSocketModal } from '@reducers/MainReducer';
import { activeSubscriptionSelector, selectCurrentSessionId, selectCurrentSessionQrCode, selectCurrentSessionStatus } from "@reducers/UserSelectors";
import { createNewSessionThunk } from '@reducers/UserThunks';
import { getRuntimeUrl } from "@services/storage";
import { openPaymentSite } from '@services/utils';
import { useAppDispatch, useAppSelector } from "@store/hooks";
import React, { useCallback, useEffect } from 'react';
import { TbArrowBigUp } from 'react-icons/tb';
import QRCode from "react-qr-code";
import { CloseIcon } from "./qr-icons";
import classes from "./qr.module.scss";


const closeLaptopVideo = getRuntimeUrl("img/close_laptop.mp4");
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const QrModal = () => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector((state) => state.UserReducer.accessToken);
  let sessionStatus = useAppSelector(state => selectCurrentSessionStatus(state));
  const sessionId = useAppSelector(state => selectCurrentSessionId(state));
  const qrCode = useAppSelector((state) => selectCurrentSessionQrCode(state));
  const isSocketModalOpen = useAppSelector((state) => state.MainReducer.isSocketModalOpen);
  const activeSubscription = useAppSelector(activeSubscriptionSelector);
  const allowToScanSocket = activeSubscription?.hasSocket();

  const isLoading = !sessionId

  console.log(`QrModal: sessionStatus: ${sessionStatus}, sessionId: ${sessionId}, isLoading: ${isLoading}`)

  const createNewSession = useCallback(async () => {
    console.log(`Requesting session`);
    dispatch(createNewSessionThunk())
  }, [dispatch])


  useEffect(() => {
    if (isSocketModalOpen && allowToScanSocket) {
      createNewSession();
    }
  }, [allowToScanSocket, createNewSession, isSocketModalOpen]);

  //sessionStatus = 'connecting'

  const qrContainerContent = (size?: number) => {
    switch (sessionStatus) {
      case 'connected':
        return <span>Connected!</span>;
      case 'qrcode':
        if (qrCode.indexOf('data:image') === -1) {
          return <QRCode value={qrCode} size={size} />;
        } else {
          return <img src={qrCode} alt="QR Code" style={{ width: size, height: size }} />;
        }
      case 'connecting':
        return <span className={classes.loading}>Connecting...</span>;
      case 'scan-qr-again':
        return <div>
          <Typography
            sx={{ fontWeight: "bold", fontSize: "22px", lineHeight: 1.5, color: 'var(--primary)' }}>
            Oops, the QR scan <u>failed.</u><br />
            Please wait while we<br />
            <span className={classes.loadingClean}>generate a new QR code...</span>
          </Typography>
        </div>;
      case 'disconnected':
        return <CircularProgress />;
      default:
        return <Stack spacing={1} alignItems="center" justifyContent="center">
          <IconButton onClick={createNewSession}>
            <RestartAltIcon sx={{ width: '5rem', height: '5rem' }} />
          </IconButton>
          <Button variant="text" onClick={createNewSession}>
            Click to regenerate the QR
          </Button>
        </Stack>;
    }
  }

  const disconnectedState = () => <Stack justifyContent={"center"} alignItems={"center"} style={{ width: "600px" }} >
    <div className={classes.close_button_container}>
      <span onClick={() => { dispatch(toggleSocketModal(false)) }}>
        {CloseIcon}
      </span>
    </div>
    <div className={classes.content} style={{ maxWidth: "500px" }}>

      <Box sx={{ mb: !allowToScanSocket ? 3 : 1 }}>
        <Typography
          sx={{ fontWeight: "bold", fontSize: "22px", lineHeight: 1.2, color: 'var(--primary)' }}>
          Don't want to leave your computer open?
        </Typography>
        <Typography sx={{ marginTop: "10px", color: 'var(--primary)' }}>
          Scan this QR code with your phone WhatsApp, and stop worrying about leaving your computer open
        </Typography>
      </Box>

      <Stack alignItems={"center"}>
        {
          allowToScanSocket &&
          <div>
            <div className={classes.qrContainer} style={{ margin: "40px 0" }}>
              {isLoading ? <CircularProgress /> : qrContainerContent(264)}
            </div>
            <p style={{ marginBottom: "8px" }}
              onClick={() => { dispatch(toggleHowToScanQrModal(true)) }}
              className={classes.howto_scan}>How to scan this QR code?
            </p>
          </div>
        }
        {
          !allowToScanSocket &&
          <Stack justifyContent={"center"} alignItems={"center"}
            sx={{ maxWidth: '400px', padding: "10px 7px 15px 7px", mb: "28px", backgroundColor: "var(--qr-modal-upgrade-bg)", borderRadius: "16px" }}>

            <BTButton onClick={() => openPaymentSite(accessToken)}
              startIcon={<TbArrowBigUp size={"16"} />}
              sx={{ fontSize: "16px", padding: "6px 20px", fontWeight: 500, color: 'var(--background-default)', }} btvariant="pink"  >
              UPGRADE
            </BTButton>

            <Typography sx={{ fontSize: "16px", marginTop: "6px", color: 'var(--primary)' }}>
              Upgrade to <b>Campaigner</b> plan to be able to scan QR code and schedule messages offline.
            </Typography>

            <Typography sx={{ fontSize: "16px", marginTop: "6px", color: 'var(--primary)' }}>
              * Once you've made the purchase, don't forget to refresh the page to activate the feature.
            </Typography>
          </Stack>
        }

      </Stack>
      <div>
        <video autoPlay loop playsInline muted src={closeLaptopVideo}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: "15px",
            maxWidth: '302px',
          }} />
      </div>
      <button style={{ color: 'var(--primary)' }} onClick={() => { dispatch(toggleSocketModal(false)) }} className={classes.dismiss_button} >
        No thanks, I'll leave my computer open
      </button>
    </div>
  </Stack>

  const connectedState = () => <Stack justifyContent={"center"} alignItems={"center"} style={{ width: "600px" }} >
    <div className={classes.close_button_container}>
      <span onClick={() => { dispatch(toggleSocketModal(false)) }}>
        {CloseIcon}
      </span>
    </div>
    <div className={classes.content} >
      <Box sx={{ mb: "10px" }}>
        <Typography
          sx={{ fontWeight: "bold", fontSize: "36px", lineHeight: 1.2, color: '#49B26B' }}>
          You are connected!🥳
        </Typography>
        <Typography sx={{ marginTop: "10px", color: 'var(--primary)' }}>
          You can schedule messages and <b>turn off the computer.</b>
        </Typography>
      </Box>

      <div>
        <video autoPlay loop playsInline muted src={closeLaptopVideo}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: "15px",
            maxWidth: '302px',
          }} />
      </div>

      <Stack justifyContent={"center"} alignItems={"center"} style={{ marginTop: "10px" }}>
        <Button onClick={() => { dispatch(toggleSocketModal(false)) }}
          variant='outlined'
          color="success"
          style={{
            padding: "10px 20px",
          }}>
          Got it!
        </Button>
      </Stack>
      <Typography sx={{ marginTop: "12px", color: 'var(--primary)', fontSize: "14px" }}>
        * Always remember that you can disconnect Blueticks from your WhatsApp app.
        <br />
        Please refer to the WhatsApp tutorial&nbsp;
        <a href="https://faq.whatsapp.com/834124628020911/?cms_platform=web" target="_blank" rel="noopener noreferrer">"How to Log Out"</a>&nbsp;
        for instructions.
      </Typography>
    </div>
  </Stack>

  return (
    <BTThemeProvider>
      <Dialog
        dir="ltr"
        TransitionComponent={Transition}
        maxWidth='lg'
        open={isSocketModalOpen}
        onClose={() => { dispatch(toggleSocketModal(false)) }}
        PaperProps={{
          sx: {
            backgroundColor: 'var(--background-default)',
            color: '#000000'
          }
        }}
      >
        <DialogContent>
          {sessionStatus === 'connected' ? connectedState() : disconnectedState()}
        </DialogContent>
      </Dialog >
    </BTThemeProvider>
  );
}
export default QrModal;