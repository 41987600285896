
import ToolbarButton from "@components/toolbar-button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getTaskExtradataArrayForMessageThread } from "@reducers/BoardsSelectors";
import { selectUserId } from "@reducers/UserSelectors";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { ReactElement, useEffect, useState } from "react";
import classes from "./editor/thread.module.scss";
import ExtraDataItemComponent from "./extra-data-item-component";
import { isExtensionContext } from "@services/detect-context";


type Props = {
  parentItemId?: string;
}


export default function MessageThread({ parentItemId = undefined }: Props): ReactElement {
  const displayExtraDataOfTaskById = useAppSelector(
    (state) => state.ExtraDataReducer.displayExtraDataOfTaskById
  );


  const taskExtradataArray = useAppSelector((state) => getTaskExtradataArrayForMessageThread(state, parentItemId, displayExtraDataOfTaskById))

  const [filterByUpdateId, setFilterByUpdateId] = useState(null);
  const [showAll, setShowAll] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const updateId = queryParams.get('updateId');
    if (updateId) {
      setFilterByUpdateId(updateId);
      setShowAll(false);
    }
  }, []);

  const filteredData = filterByUpdateId
    ? taskExtradataArray.filter(item => item._id === filterByUpdateId)
    : taskExtradataArray;

  const seeAllUpdates = () => {
    setShowAll(!showAll);
    let url = new URL(window.location.href);
    url.searchParams.delete('updateId');
    if (!isExtensionContext()) {
      window.history.pushState({ path: url.toString() }, '', url.toString());
    }
  }


  return (
    <div className={classes.container}>

      {!showAll && taskExtradataArray.length > 1 &&
        <ToolbarButton
          onClickAction={() => seeAllUpdates()}
          startIconSVG={ArrowBackIcon}
          title="See All Updates"
          buttonText={'See All Updates'}
          toolTipTitle={''}
          additionalStyles={{ mb: 2 }}

        />

      }

      {taskExtradataArray.length > 0 && showAll
        ? taskExtradataArray.map((item, index) => <ExtraDataItemComponent item={item} key={index} />)
        : filteredData.map((item, index) => <ExtraDataItemComponent item={item} key={index} />)
      }


    </div>
  );
}
