
import FormHeader from "@components/access-form/form-header/header";
import Input from "@components/access-form/form-input/form-input";
import FormPatial from "@components/access-form/form-patial";
import BTButton from "@components/bt-button/bt-button";
import BTThemeProvider from "@components/bt-theme-provider";

import { Box } from "@mui/system";
import { useResetPasswordMutation } from "@reducers/backend-api/backend-api";
import querystring from 'query-string';
import React, { useMemo, useState } from "react";
import { theme } from '../../../theme';
import classes from './reset-password-view.module.scss';

export interface ResetPasswordViewProps {

}

export interface ResetPasswordViewState {
    password: string;
    verifyPassword: string;
    loading: boolean;
    status: string;
}

export const ResetPasswordView = () => {

    const [state, setState] = useState({
        password: '',
        verifyPassword: '',
        loading: false,
        status: ''
    });
    const qs = useMemo(() => querystring.parse(window.location.search), []);
    const [resetPassword] = useResetPasswordMutation();
    const [isErr, setIsErr] = useState<boolean>(false)

    const handleOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [target.name]: target.value
        });
    }


    const triggerResetPassword = async (event?: React.FormEvent<HTMLFormElement>) => {
        console.log('clicked')
        event?.preventDefault();
        if (!state.password || !state.verifyPassword || state.password !== state.verifyPassword) {
            setIsErr(true)
            return
        } else {
            setIsErr(false)
        }
        setState({
            ...state,
            loading: true
        });

        let newStatus = 'Invalid token!';
        try {
            await resetPassword({
                token: qs.resetToken as string,
                password: state.password
            }).unwrap();

            newStatus = 'ok'
        } catch (e) {
            newStatus = 'Invalid token!';
        }

        setState({
            ...state,
            loading: false,
            status: newStatus
        });
    }



    function gotoLogin() {
        window.location.href = '/login';
    }

    const imagePath = state.status === 'ok' ?
        "/img/success_reset_pass_illustrator.png" :
        "/img/reset_password_illustrator.png"

    const errorFormColor = '#f72371';
    const screenTitle = state.status === 'ok' ? 'Reset Successfully' : ''
    const text = state.status === 'ok' ? 'Your password has been successfully reseted' : 'Enter then new password'

    return (<BTThemeProvider>
        <FormPatial imagePath={imagePath}>

            <Box className={classes.FormContainer}>

                <FormHeader screenTitle={screenTitle} text={text} />
                {state.status === 'ok' ? (
                    <>

                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: theme.spacing(2) }}>
                            <BTButton
                                sx={{ width: 1, padding: '15px 0' }}
                                onClick={gotoLogin}
                            >
                                BACK TO LOGIN PAGE
                            </BTButton>
                        </Box>
                    </>)
                    : (

                        <form onSubmit={triggerResetPassword}>

                            <Input
                                type="password"
                                name="password"
                                label="password"
                                onChange={handleOnChange}
                                placeholder="Enter new password"
                                style={{ borderColor: isErr && !state.password ? errorFormColor : '' }}
                                errorMessage={isErr && !state.password ? 'New password is required!' : ''}
                            />
                            <Input
                                type="password"
                                name="verifyPassword"
                                label="Verify password"
                                onChange={handleOnChange}
                                placeholder="Repeat new password!"
                                style={{ borderColor: isErr && !state.verifyPassword ? errorFormColor : '' }}
                                errorMessage={isErr && !state.verifyPassword ?
                                    'New password confirmation is required!' : state.verifyPassword &&
                                        state.password !== state.verifyPassword ? "Your new password didn't match!" : ''}
                            />
                            <Box className={classes.FormFooter}> 
                                <BTButton
                                    type="submit"
                                    loading={state.loading}
                                    sx={{ width: 1, padding: '15px 0' }}
                                >
                                    RESET PASSWORD
                                </BTButton>

                                <div
                                    className={classes.FormLabelText}
                                    style={{ marginTop: 16, cursor: "pointer" }}
                                >
                                    <span
                                        onClick={gotoLogin}>
                                        Back to the <span className={classes.ButtonLink}>Log In</span> page
                                    </span>
                                </div>


                            </Box>

                        </form>
                    )}
            </Box>

        </FormPatial>
    </BTThemeProvider>);
}

export default ResetPasswordView;