import UserControlPanel from "@components/user-control-panel/user-control-panel";

import BTButton from "@components/bt-button/bt-button";
import { AttachMoney, ExitToAppOutlined, FactCheckOutlined, GppGoodOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Divider } from "@mui/material";
import { isCurrentUserMemberOrOwner } from "@reducers/BoardsSelectors";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { isUserWorkSpaceOwner, selectUserObject } from "@reducers/UserSelectors";
import { logoutWrapperThunk } from "@reducers/UserThunks";
import { AnalyticsService } from "@services/analytics-service";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { appConfig } from "config/app.config";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { User } from "../../models/user";
import classes from "./sidebar.module.scss";



export enum SideBarItem {
  AccountSettings,
  Language,
  Notifications,
  Security,
  Workspace,
  AdminWorkspace,
  AccountUsers,
  AdminBilling,
  UserManagement,
  AdminAPI,
  Subscriptions,
  Invoices,
  Devices
}

export interface SideBarProps {
  active?: SideBarItem;
  readOnly?: boolean;
}

export interface SideBarState {
  user?: User | null | undefined;
}


const SideBar = ({ active, readOnly }: SideBarProps) => {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch()
  const analyticsService: AnalyticsService = useAnalyticsService();
  const accessToken = useAppSelector((state) => state.UserReducer.accessToken);
  const isMemberOrOwner = useAppSelector(isCurrentUserMemberOrOwner);
  const isWorkSpaceOwner = useAppSelector(isUserWorkSpaceOwner);
  const user = useAppSelector(selectUserObject);
  const direction = useAppSelector((state) => state.UserReducer.direction);
  const location = useLocation();
  return (
    <div className={classes.sidebar} style={{ position: "relative" }}>
      <div className={classes.sidebarHeader}>
        <span>{t('sideBar.myAccount')}</span>
      </div>
      {readOnly &&
        <span style={{ fontSize: "14px", padding: "10px" }}>
          Support mode <br />
          Name:{user?.firstName} {user?.lastName}
          <br />
          email: {user.email}

        </span>}
      <Divider sx={{ borderColor: "var(--separator-line)", }}></Divider>

      <div className={classes.menuContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          {!readOnly &&
            <Link to="/tasks" style={{
              width: 'calc(100% - 32px)',
              margin: "auto",
              borderRadius: "8px",
            }} >
              <BTButton

                sx={{
                  width: '100%',
                  textTransform: 'none',
                  ".MuiButton-startIcon": { marginInlineStart: '-4px', marginInlineEnd: '8px' }
                }}

                startIcon={<ArrowBackIcon sx={{ rotate: direction === "rtl" ? '180deg' : undefined }} />}  >
                {t('sideBar.backToTasks')}</BTButton>
            </Link>

          }
        </div>
        <Link to={`/account${location.search}`} style={{
          width: 'calc(100% - 32px)',
          margin: "auto",
          borderRadius: "8px",
        }}
          onClick={() => {
            analyticsService.event('switch_to_personal_information', {
              category: "myAccountSidebar",
              action: 'switch to personal information',
            })
          }}>
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.AccountSettings ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <PersonOutlineOutlined />
              <span className={classes.menuTitle}>{t('sideBar.personalInformation')}</span>
            </div>
          </div>
        </Link>
        {/* <Link to="/account-language">
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.Language ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <img src={worldIcon} />
              <span className={classes.menuTitle}>Language & region</span>
            </div>
          </div>
        </Link>
        <Link to="/account-notification">
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.Notifications ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <img src={bellIcon} />
              <span className={classes.menuTitle}>Notifications</span>
            </div>
          </div>
        </Link>
          */}
        {!readOnly &&
          <Link to="/account-security" style={{
            width: 'calc(100% - 32px)',
            margin: "auto",
            borderRadius: "8px",
          }}
            onClick={() => {
              analyticsService.event('switch_to_account_security', {
                category: "myAccountSidebar",
                action: 'switch to account security',
              })
            }}>
            <div
              className={[
                classes.menuItem,
                active === SideBarItem.Security ? classes.active : "",
              ].join(" ")}
            >
              <div className={[classes.row, classes.alignCenter].join(" ")}>
                <GppGoodOutlined />
                <span className={classes.menuTitle}>{t('sideBar.security')}</span>
              </div>
            </div>
          </Link>
        }


        {/* <Link to="/devices" style={{
          width: 'calc(100% - 32px)',
          margin: "auto",
          borderRadius: "8px",
        }}
          onClick={() => {
            analyticsService.event('switch_to_devices', {
              category: "myAccountSidebar",
              action: 'switch to devices',
            })
          }}>
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.Devices ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
            <DevicesIcon />
              <span className={classes.menuTitle}>Devices</span>
            </div>
          </div>
        </Link> */}

        <Link to={`/account-workspace${location.search}`} style={{
          width: 'calc(100% - 32px)',
          margin: "auto",
          borderRadius: "8px",
        }}
          onClick={() => {
            analyticsService.event('switch_to_account_workspace', {
              category: "myAccountSidebar",
              action: 'switch to account workspace',
            })
          }}>
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.Workspace ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <FactCheckOutlined />
              <span className={classes.menuTitle}>{t('sideBar.workspaces')}</span>
            </div>
          </div>
        </Link>
        {/* <Link to="/admin-workspace">
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.AdminWorkspace ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <img src={workspaceIcon} />
              <span className={classes.menuTitle}>Admin Workspace</span>
            </div>
          </div>
        </Link> */}

        {isMemberOrOwner && <Link to={`/user-management${location.search}`} style={{
          width: 'calc(100% - 32px)',
          margin: "auto",
          borderRadius: "8px",
        }}
          onClick={() => {
            analyticsService.event('switch_to_user_management', {
              category: "myAccountSidebar",
              action: 'switch to user management',
            })
          }}>
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.UserManagement ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <FactCheckOutlined />
              <span className={classes.menuTitle}>{t('sideBar.userManagement')}</span>
            </div>
          </div>
        </Link>}
        {appConfig.asset_key === 'blueticks' && <Link to="/admin-api" style={{
          width: 'calc(100% - 32px)',
          margin: "auto",
          borderRadius: "8px",
        }} data-testid='admin-api-view-button'
          onClick={() => {
            analyticsService.event('switch_to_admin_api', {
              category: "myAccountSidebar",
              action: 'switch to admin api',
            })
          }}>
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.AdminAPI ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <FactCheckOutlined />
              <span className={classes.menuTitle}>{t('sideBar.api')}</span>
            </div>
          </div>
        </Link>}
        {(isWorkSpaceOwner || user?.role === "support" || user?.role === "admin") &&
          <><Link to={`/admin-billing${location.search}`} style={{
            width: 'calc(100% - 32px)',
            margin: "auto",
            borderRadius: "8px",
          }} data-testid='admin-billing-view-button'
            onClick={() => {
              analyticsService.event('switch_to_admin_billing', {
                category: "myAccountSidebar",
                action: 'switch to admin billing',
              })
            }}>
            <div
              className={[
                classes.menuItem,
                active === SideBarItem.AdminBilling ? classes.active : "",
              ].join(" ")}
            >
              <div className={[classes.row, classes.alignCenter].join(" ")}>
                <FactCheckOutlined />
                <span className={classes.menuTitle}>{t('sideBar.adminBilling')}</span>
              </div>
            </div>
          </Link>
          </>
        }
        {isWorkSpaceOwner && !readOnly && <Link to="/account-subscription" style={{
          width: 'calc(100% - 32px)',
          margin: "auto",
          borderRadius: "8px",
        }} data-testid='buy-subscription-view-button'
          onClick={() => {
            analyticsService.event('switch_to_account_subscription', {
              category: "myAccountSidebar",
              action: 'switch to account subscription',
            })
          }}>
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.Subscriptions ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <AttachMoney />
              <span className={classes.menuTitle}>{t('sideBar.buySubscription')}</span>
            </div>
          </div>
        </Link>}
        {/* <Link to="/account-invoices">
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.Invoices ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <AttachMoney />
              <span className={classes.menuTitle}>Invoices</span>
            </div>
          </div>
        </Link> */}
        {
          !readOnly &&
          <div style={{
            width: "calc(100% - 32px)",
            margin: "auto",
            borderRadius: "8px",
          }}
            className={classes.menuItem}
            onClick={() => {
              dispatch(logoutWrapperThunk({ accessToken }))
            }}
          >
            <ExitToAppOutlined />
            <span className={classes.menuTitle}>{t('sideBar.logout')}</span>
          </div>
        }
      </div >

      <div style={{ marginTop: "auto" }} className={classes.menuContainer}>
        {/* <Link to={`${appConfig.APP_ENDPOINT}/privacy-policy`}>
          <div className={classes.menuItem}>
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <VerifiedUser></VerifiedUser>
              <span className={classes.menuTitle}>Privacy Policy</span>
            </div>
          </div>
        </Link> */}
        {/* <Link component="a" href="/faq.html">
                <div className={classes.menuItem}>
                    <div className={[classes.row, classes.alignCenter].join(' ')}>
                        <HelpOutline></HelpOutline>
                        <span className={classes.menuTitle}>FAQ</span>
                    </div>
                </div>
            </Link> */}
        {/* <div
            style={{ fontSize: 12 }}
            className={[classes.menuItem, classes.textOnly].join(" ")}
            >
            <FontAwesomeIcon icon={faCopyright as IconProp} />
            <span className={classes.menuTitle}>
                Copyrights 2020, All Rights Resereved
            </span>
            </div> */}
      </div>
      <UserControlPanel isSideMenuOpen={true} readOnly={readOnly} />
    </div >
  );
};

export default SideBar;
