import BTInputField from "@components/bt-input-field/bt-input-field";
import { focusContactThunk } from "@reducers/WhatsappThunks";
import { getWhatsappContactsFilterd, setContactFilterName } from "@reducers/WhatsAppViewReducer";
import { WhatsappContact } from "@services/whatsapp";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { Virtuoso } from "react-virtuoso";
import WhatsappContactBox from "./whatsapp-contact-box";

const WhatsappContactList = () => {
  const dispatch = useAppDispatch();

  const whatsappContactsFilterd = useAppSelector(getWhatsappContactsFilterd);

  function filterBoards({ target }: any) {
    dispatch(setContactFilterName(target.value));
  }

  const focusWAContact = (user: WhatsappContact) => {
    dispatch(focusContactThunk(user.id._serialized))
  };

  return (
    <div>
      <BTInputField
        type="text"
        onChange={filterBoards}
        placeholder={"Search contacts..."}
      />

      <Virtuoso
        style={{ height: '400px' }}
        totalCount={whatsappContactsFilterd.length}
        itemContent={index => {
          const whatsappUser = whatsappContactsFilterd[index]

          return (
            <div key={`conatct${index}`} onClick={(e) => focusWAContact(whatsappUser)}
              style={{ marginBottom: "10px" }} >
              <WhatsappContactBox
                contactName={whatsappUser.displayName ?? whatsappUser.name ?? whatsappUser.notifyName}
              />
            </div >
          )
        }

        }
      />


    </div>
  );
};

export default WhatsappContactList;
