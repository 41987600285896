import { appConfig } from "config/app.config";
import { EventEmitter } from "events";
import { getContextName, isExtensionContextOrWhatsappView } from "./detect-context";

export interface Message<T = any, R = any> {
  type: string;
  data: T;
  sendResponse?: (respone?: R) => void;
}
const extensionContext = isExtensionContextOrWhatsappView();

let _instance: MessageService;

export class MessageService {
  eventEmitter: EventEmitter = new EventEmitter();
  constructor() {
    if (!extensionContext) return;
    if (!_instance) {
      _instance = this;
      if (chrome.runtime && chrome.runtime.onMessageExternal) {
        chrome.runtime.onMessageExternal.addListener(this.messageHandler);
      }
      if (chrome.runtime && chrome.runtime.onMessage) {
        chrome.runtime.onMessage.addListener(this.messageHandler);
      }
      if (typeof window !== "undefined") {
        window.addEventListener("message", async (event: MessageEvent) => {
          if (event.data && event.data.type) {
            // console.log(
            //   `[Message Service - ${getContextName()}] message received: ${
            //     event.data.type
            //   }`
            // );
            this.messageHandler(event.data);
          }
        });
      }
    }
    return _instance;
  }

  messageHandler = (message: Message, sender?, _sendResponse?) => {
    if (!extensionContext) return;
    if (!_instance) return;

    const context = getContextName();
    //console.log(`[${context}] ${JSON.stringify(message)}`);
    this.eventEmitter.emit(message.type, {
      type: message.type,
      data: message.data,
      sendResponse: _sendResponse
        ? (response?: any) => {
            console.log(`Sending response to message sender: ${response}`)
            _sendResponse(response);
          }
        : undefined,
    });

    return true;
  };

  async _sendMessage<T>(type: string, data: any): Promise<T> {
    if (!extensionContext) return;
    if (!_instance) return;

    console.log(`[Message Service] Send message ${type}`)
    return new Promise((resolve, reject) => {
      const message: Message = {
        type,
        data,
      };
      if (typeof window !== "undefined") {
        window.postMessage(message, "*");
      }
      const context = getContextName();
      console.log(`Message service sendMessage [context: ${context}, extension: ${appConfig.EXTENTION_ID}]`);
      console.log("Sending message...");
      try {
        if (context !== "web") {
          console.log('Context is not web, sending message without extension ID')
          chrome.runtime?.sendMessage(message, (response: any) => {
            console.log(`Recieved response: ${JSON.stringify(response)}`);
            resolve(response);
          });
        } else if (chrome.runtime?.sendMessage) {
          console.log(`Context is not web, sending message to extension with ID ${appConfig.EXTENTION_ID}`)
          chrome.runtime?.sendMessage(
            appConfig.EXTENTION_ID,
            message,
            (response: any) => {
              console.log(`Recieved response: ${JSON.stringify(response)}`);
              resolve(response);
            }
          );
        } else {
          resolve(null);
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  async sendMessage<T>(type: string, data: any): Promise<T | undefined> {
    if (!extensionContext) return;
    if (!_instance) return;

    let timer;
    return Promise.race([
      this._sendMessage<T>(type, data).then((res) => {
        console.log(`[Message Service] Response received: ${JSON.stringify(res)}`)
        return res;
      }),
      new Promise<undefined>((_r, reject) => {
        timer = setTimeout(() => {
          console.log(`Timeout waiting for response, type ${type}`)
          reject(null);
        }, 3000);
      }).finally(() => clearTimeout(timer)),
    ]);
  }

  sendPageMessage(type: string, data: any) {
    if (!extensionContext) return;
    if (!_instance) return;

    console.log("message from background!");
    if (typeof window !== "undefined") {
      window.postMessage(
        Object.assign({
          type,
          data,
        }),
        "*"
      );
    }
  }

  async sendMessageToTab(type: string, data: any) {
    if (!extensionContext) return;
    if (!_instance) return;

    console.log(`[Message Service] Send message to tab ${type}`)
    if (chrome.tabs) {
      [
        "https://web.whatsapp.com/*",
        "http://localhost/*",
        "https://blueticks-qa.netlify.app/*",
        "https://whatsapp-scheduler.com/*",
        "https://blueticks.co/*",
      ].forEach((url) => {
        chrome.tabs.query({ url }, (tabs) => {
          (tabs || []).forEach((tab) => {
            if (tab.id) {
              chrome.tabs.sendMessage(
                tab.id,
                { type, data },
                function (response) {
                  console.log(response);
                }
              );
            }
          });
        });
      });
    }
  }
}

export const messageService = new MessageService();
