import BTDropDown from "@components/atoms/drop-down/bt-drop-down";
import { UserMessage } from "@models/user-message";
import { Box, MenuItem, SelectChangeEvent, Tooltip } from "@mui/material";

import { NavigateAction, ToolbarProps, View } from "react-big-calendar";
import { NavigateNext, NavigateBefore } from "@mui/icons-material";

// eslint-disable-next-line import/no-webpack-loader-syntax
import "!style-loader!css-loader!./custom-toolbar.css";
import moment from "moment";
import { CSSSelectorObjectOrCssVariables } from "@mui/system";

interface P {
  toolbarProps: ToolbarProps<UserMessage, any>;
}

function CustomToolbar(props: P) {
  const { view } = props.toolbarProps;

  const handleChange = (event: SelectChangeEvent<View>) => {
    props.toolbarProps.onView(event.target.value as View);
  };

  const navigate = (action: NavigateAction) => {
    props.toolbarProps.onNavigate(action);
  };

  const hoverSX = {
    cursor: "pointer",
    backgroundColor: "var(--calendar-toolbar-hover-bg)",
    color: "var(--calendar-toolbar-hover-color)",
  } as CSSSelectorObjectOrCssVariables<{}>;

  return (
    <Box className="cal-toolbar">
      <Box className="cal-toolbar-navigate">
        <Tooltip title={moment().format("dddd, DD MMMM")}>
          <Box onClick={() => navigate("TODAY")} className="cal-toolbar-today">
            Today
          </Box>
        </Tooltip>
        <Tooltip title={"Previous " + view}>
          <Box
            sx={{ "&:hover": hoverSX, display: "flex" }}
            onClick={() => navigate("PREV")}
          >
            <NavigateBefore />
          </Box>
        </Tooltip>
        <Tooltip title={"Next " + view}>
          <Box
            onClick={() => navigate("NEXT")}
            sx={{ mr: 3, "&:hover": hoverSX, display: "flex" }}
          >
            <NavigateNext />
          </Box>
        </Tooltip>

        <Box sx={{ fontSize: "22px" }}>{props.toolbarProps.label}</Box>
      </Box>
      <Box sx={{ maxWidth: "fit-content" }}>
        <BTDropDown
          value={view}
          onChange={handleChange}
          autoWidth
          className="cal-toolbar-select"
          sx={{
            fontWeight: 600,
            fontSize: "14px",

            ".MuiOutlinedInput-input": {
              padding: 0,
              pl: 1,

              display: "flex",
              alignItems: "center",
            },
            ".MuiSelect-select": {
              color: "var(--calendar-toolbar-text-color)",
              height: "36px",
            },
            ".MuiSvgIcon-root": {
              color: "var(--calendar-toolbar-text-color)",
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent !important",
            },
          }}
        >
          <MenuItem value={"month"}>Month</MenuItem>
          <MenuItem value={"week"}>Week</MenuItem>
          <MenuItem value={"day"}>Day</MenuItem>
          <MenuItem value={"agenda"}>Agenda</MenuItem>
        </BTDropDown>
      </Box>
    </Box>
  );
}

export default CustomToolbar;
