import BTThemeProvider from "@components/bt-theme-provider";
import {
    Button,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import { getIsChatHasBoard } from "@reducers/BoardsSelectors";
import { selectUserObject } from "@reducers/UserSelectors";
import { logoutWrapperThunk } from "@reducers/UserThunks";
import { setShowWizard } from "@reducers/WhatsAppReducer";
import { createWAContactBoardThunk, toggleTasksThunk } from "@reducers/WhatsappThunks";
import { getRuntimeUrl } from "@services/storage";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { MaybeUser } from "interfaces";
import { useEffect, useState } from "react";
import { BiWalk } from "react-icons/bi";
import { useSelector } from "react-redux";
import { User } from "../../models/user";
import { accessFormPopupActions } from "../../reducers/AccessFormPopupReducer";
import { LogoutIcon } from "../../svgs/svgs";
import { theme } from "../../theme";
import classes from "./open-menu-button.module.scss";

export interface OpenMenuButtonProps {
    handleClose: () => void
}

export interface OpenMenuButtonState {
    dialogOpen: boolean;
    setAnchorEl: null | HTMLElement;
    user?: User | null | undefined;
    qrPopupOpen: boolean;
    qr?: string;
}


type Props = {
    handleClose: () => void;
}

export default function ManageExtensionMenu({
    handleClose,
}: Props) {
    const dispatch = useAppDispatch()
    const user: MaybeUser = useSelector(selectUserObject);
    const userIsLoggedIn = !!user?._id ?? false;
    const accessToken = useAppSelector((state) => state.UserReducer.accessToken);
    // const [showWizard, setShowWizard] = useState(false);
    const currentExtensionVersion = useAppSelector((state) => state.WhatsAppReducer.currentExtensionVersion);
    const currentChat = useAppSelector((state) => state.WhatsAppReducer.currentChat)


    const openSignup = () => {
        dispatch(accessFormPopupActions.setStatus("Signup"));
    };

    const openLogin = () => {
        dispatch(accessFormPopupActions.setStatus("Login"));
    };


    const handleClickShowWizard = async () => {
        const LOCALSTORAGE_KEY = "wasOnboardingWizardShown"
        localStorage.removeItem(LOCALSTORAGE_KEY)
        handleClose()
        dispatch(setShowWizard(true))
    }

    const isTasksPanelOpen = useAppSelector((state) => state.TasksReducer.isTasksPanelOpen);

    const isHaveBoard = useAppSelector((state) => getIsChatHasBoard(state))

    const [boardButtonString, setBoardButtonString] = useState<string>('Open board')




    const newTaskSVG = getRuntimeUrl("/img/newTaskSVG.svg")
    const newBoardSVG = getRuntimeUrl("/img/newBoardSVG.svg")




    function toggleTasks() {
        if (currentChat) {
            if (!isHaveBoard) {
                dispatch(createWAContactBoardThunk({}))
            }
            dispatch(toggleTasksThunk({}))
        }
        handleClose()
    }

    useEffect(() => {
        if (isTasksPanelOpen && isHaveBoard) {
            setBoardButtonString('Close board')
        }
        if ((!isTasksPanelOpen && isHaveBoard) || (!currentChat)) {
            setBoardButtonString('Open board')
        }
        if (currentChat && !isHaveBoard) {
            setBoardButtonString('New board')
        }
    }, [currentChat, isHaveBoard, isTasksPanelOpen])

    return (
        <BTThemeProvider>
            <Stack sx={{
                width: '250px',
                borderRadius: '6px',
                backgroundColor: 'var(--default-background)',
                direction: 'ltr',
            }}>


                {false && <>
                    <Typography style={{
                        fontWeight: 600,
                        height: '24px',
                        backgroundColor: 'var(--manage-extension-menu-headers-bg)',
                        fontSize: '11px',
                        color: 'var(--primary)',
                        lineHeight: '23px',
                        paddingInlineStart: '19px',
                    }}>TASKS</Typography>
                    <Stack direction='row' sx={{
                        height: '120px',
                        justifyContent: 'space-evenly',
                    }}>
                        <IconContainer onClick={() => { handleClose() }} svg={newTaskSVG} label='New task' filter='grayscale(100%)' width="42px" height="42px" />
                        {currentChat &&
                            <IconContainer onClick={() => { toggleTasks() }} svg={newBoardSVG}
                                label={boardButtonString} />
                        }
                        {!currentChat &&
                            <Tooltip title='you need to select a chat in order to use this'>
                                <div style={{ width: '100%', }}>
                                    <IconContainer onClick={() => { toggleTasks() }} svg={newBoardSVG}
                                        label={boardButtonString} filter={'grayscale(100%)'} width="42px" height="42px" />
                                </div>
                            </Tooltip>
                        }
                    </Stack>
                </>}


                {/* <MenuItem onClick={handleClickShowWizard} sx={{ height: "54px" }}>
                    <ListItemIcon className={classes.menuIcon}>
                        <BiWalk size={20} />
                    </ListItemIcon>
                    <ListItemText primary={"Show me around"} />
                     <BiHelpCircle size={24} /> 
                </MenuItem> */}

                {userIsLoggedIn &&
                    <MenuItem sx={{ height: "54px" }} onClick={() => {
                        dispatch(logoutWrapperThunk({ accessToken }))
                        handleClose()
                    }}>
                        <ListItemIcon className={classes.menuIcon}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </MenuItem>
                }

                {!userIsLoggedIn &&
                    <MenuItem onClick={() => {
                        dispatch(accessFormPopupActions.setStatus('Login'))
                        handleClose()
                    }}>
                        <ListItemIcon className={classes.menuIcon}>
                            <LogoutIcon></LogoutIcon>
                        </ListItemIcon>
                        <ListItemText primary="Login" />
                    </MenuItem>
                }
                <MenuItem disabled>
                    <div style={{ textAlign: "center" }}>{user?.displayEmail}</div>


                </MenuItem>

                <MenuItem disabled>
                    <div style={{ textAlign: "center" }}>version {currentExtensionVersion}</div>
                </MenuItem>

                {user?.isGuest && (
                    <div className={classes.openMenuOptionGuestSection}>
                        <p
                            style={{
                                marginTop: theme.spacing(3.5),
                                marginBottom: theme.spacing(1.5),
                            }}
                        >
                            You are currently logged in as <span>a guest.</span>
                        </p>
                        <p
                            style={{
                                marginBottom: theme.spacing(1.5),
                            }}
                        >
                            <span onClick={openSignup}>Sign up</span> to prevent losing your
                            data.
                        </p>
                        <p
                            style={{
                                marginBottom: theme.spacing(3),
                            }}
                        >
                            <span onClick={openLogin}>Login</span> to switch to an existing
                            account.
                        </p>
                        <div
                            className={classes.openMenuOptionGuestSectionButtons}
                            style={{
                                marginTop: theme.spacing(1.5),
                                marginBottom: theme.spacing(3.5),
                            }}
                        >
                            <span></span>
                            <Button
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                variant="contained"
                                color="primary"
                                onClick={openSignup}
                            >
                                Sign up
                            </Button>
                            <span></span>
                            <Button
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                variant="outlined"
                                color="primary"
                                onClick={openLogin}
                            >
                                Login
                            </Button>
                            <span></span>
                        </div>
                    </div>
                )}


            </Stack>
        </BTThemeProvider>
    )
}


const IconContainer = ({ svg, label, onClick, filter = 'grayscale(0%)', width = '28px', height = '32px' }: { svg: string; label: string; onClick: () => void; filter?: string, width?: string, height?: string }) => {
    return (
        <Button onClick={onClick} sx={{
            width: '100%', height: '100%',
            '&:hover': {
                backgroundColor: 'rgba(13, 122, 169, 0.07)', // Correct syntax for RGBA in JSX
            },
        }} >
            <Stack sx={{
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <img style={{ width, height, filter: filter }} src={svg} alt={`${svg}`} />
                <p style={{
                    marginTop: '5px',
                    marginBottom: '0px',
                    fontSize: '13px',
                    lineHeight: '16px',
                    textTransform: 'none',
                    color: 'var(--primary)',
                }}>{label}</p>
            </Stack>
        </Button>
    )
}