import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { setHoveredDate } from '@reducers/WhatsAppReducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import classes from "./message-calendar.module.scss";
import moment from 'moment';

export const CustomDayCell = ({ date, label }) => {
    const dispatch = useAppDispatch()
    const hoveredDate = useAppSelector((state) => state.WhatsAppReducer.hoveredDate);

    const isHovered = moment(hoveredDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')

    const handleMouseEnter = () => {
        dispatch(setHoveredDate(date))
    };
    const handleMouseLeave = () => {
        dispatch(setHoveredDate(undefined))
    };


    return (
        <div className={classes.customDayCell} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <span className={classes.dayLabel}>{label}</span>
            <IconButton size="small" className={classes.addButton} sx={{ opacity: isHovered ? 1 : 0, transition: 'opacity 0.3s ease-out' }}>
                <AddIcon fontSize="small" />
            </IconButton>
        </div >
    );
};
