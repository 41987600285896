
export function ClockFill() {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
        </svg>
    )
}

export function LogoutIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M18.3591 6.64062C19.6175 7.89941 20.4744 9.50307 20.8214 11.2488C21.1685 12.9946 20.9901 14.804 20.3088 16.4484C19.6275 18.0927 18.474 19.4982 16.994 20.487C15.514 21.4758 13.7741 22.0035 11.9941 22.0035C10.2142 22.0035 8.4743 21.4758 6.99432 20.487C5.51434 19.4982 4.36079 18.0927 3.67951 16.4484C2.99823 14.804 2.81983 12.9946 3.16686 11.2488C3.51389 9.50307 4.37077 7.89941 5.62914 6.64062" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 2V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export function BlueticksLogo() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="https://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="11" fill="#546EFF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.666016 11.8801C1.05954 16.5588 4.55895 20.3537 9.09541 21.196L8.73788 20.8009C4.67666 19.8674 1.55453 16.4748 1.02296 12.2746L0.666016 11.8801ZM20.9737 9.69767C20.4337 5.51827 17.3281 2.14319 13.2877 1.20333L12.9288 0.806641C17.4465 1.65604 20.9306 5.4348 21.3331 10.0949L20.9737 9.69767Z" fill="white" />
            <g clipPath="url(#clip0)">
                <path d="M4.77756 11.6384L7.85645 14.8105L8.63649 14.0069L5.55761 10.8347L4.77756 11.6384Z" fill="white" />
                <path d="M14.4715 7.99512L13.6914 7.19141L10.1963 10.7953L10.9763 11.599L14.4715 7.99512Z" fill="white" />
                <path d="M16.8115 7.19141L10.9734 13.2035L8.6746 10.835L7.89453 11.6387L10.9734 14.811L17.5888 7.99512L16.8115 7.19141Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="12.8118" height="13.2" fill="white" transform="translate(4.77734 4.40039)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export function BlueticksMenuLogoOption() {
    return (

        <svg width="27" height="27" viewBox="0 0 27 27" fill="currentColor" xmlns="https://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13.5" r="12.5" fill="white" stroke="white" strokeWidth="2" />
            <path d="M4.00043 14.6245L8.51074 19.3496L9.65345 18.1525L5.14314 13.4274L4.00043 14.6245Z" fill="currentColor" />
            <path d="M18.2013 9.19716L17.0585 8L11.9385 13.3681L13.0812 14.5653L18.2013 9.19716Z" fill="currentColor" />
            <path d="M21.6291 8L13.0768 16.9553L9.70915 13.4273L8.56641 14.6245L13.0768 19.3496L22.7678 9.19716L21.6291 8Z" fill="currentColor" />
        </svg>
        
    )
}

export function BlueticksMenuOptionUserPlus() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.5 11C10.7091 11 12.5 9.20914 12.5 7C12.5 4.79086 10.7091 3 8.5 3C6.29086 3 4.5 4.79086 4.5 7C4.5 9.20914 6.29086 11 8.5 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 8V14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23 11H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export function BlueticksMenuOptionBroadcast() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M20.0645 3.93602C19.7045 3.57602 19.1525 3.57602 18.7925 3.93602C18.4325 4.29602 18.4325 4.84802 18.7925 5.20802C22.5365 8.95202 22.5365 15.048 18.7925 18.792C18.4325 19.152 18.4325 19.704 18.7925 20.064C18.9605 20.232 19.2005 20.328 19.4405 20.328C19.6805 20.328 19.8965 20.232 20.0645 20.064C24.5045 15.624 24.5045 8.37602 20.0645 3.93602Z" fill="currentColor" />
            <path d="M17.0889 6.88719C16.7289 6.52719 16.1769 6.52719 15.8169 6.88719C15.4569 7.24719 15.4569 7.79919 15.8169 8.15919C16.8489 9.19119 17.4009 10.5352 17.4009 11.9992C17.4009 13.4392 16.8489 14.8072 15.8169 15.8152C15.4569 16.1752 15.4569 16.7272 15.8169 17.0872C15.9849 17.2552 16.2249 17.3512 16.4649 17.3512C16.7049 17.3512 16.9209 17.2552 17.0889 17.0872C18.4569 15.7432 19.2249 13.9192 19.2009 11.9992C19.2009 10.0792 18.4569 8.25519 17.0889 6.88719Z" fill="currentColor" />
            <path d="M5.20845 5.20802C5.56845 4.84802 5.56845 4.29602 5.20845 3.93602C4.84845 3.57602 4.29645 3.57602 3.93645 3.93602C-0.503555 8.37602 -0.503555 15.624 3.93645 20.064C4.10445 20.232 4.34445 20.328 4.56045 20.328C4.80045 20.328 5.01645 20.232 5.20845 20.064C5.56845 19.704 5.56845 19.152 5.20845 18.792C1.46445 15.048 1.46445 8.95202 5.20845 5.20802Z" fill="currentColor" />
            <path d="M8.16016 8.18458C8.52016 7.82458 8.52016 7.27258 8.16016 6.91258C7.80016 6.55258 7.24816 6.55258 6.88816 6.91258C4.08017 9.72058 4.08017 14.2806 6.91216 17.1126C7.08016 17.2806 7.32016 17.3766 7.56016 17.3766C7.80016 17.3766 8.01616 17.2806 8.18416 17.1126C8.54416 16.7526 8.54416 16.2006 8.18416 15.8406C6.07216 13.7286 6.04817 10.2966 8.16016 8.18458Z" fill="currentColor" />
            <path d="M12.0004 14.9754C13.644 14.9754 14.9764 13.643 14.9764 11.9994C14.9764 10.3558 13.644 9.02344 12.0004 9.02344C10.3568 9.02344 9.02441 10.3558 9.02441 11.9994C9.02441 13.643 10.3568 14.9754 12.0004 14.9754Z" fill="currentColor" />
        </svg>

    )
}

export function BlueticksMenuOptionCalendar() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 2V6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 2V6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 10H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}


