import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { getFilterConfigItemByColumnTypeAndSelectedOperator, getFilterOperatorListByColumnType } from "@reducers/TasksFilterPanelThunks";
import { useAppSelector } from "@store/hooks";

interface FilterRowOperatorFieldProps {
    operatorField: string;
    onOperatorChange: (value: string) => void;
    selectedColumnType: string;
}

const FilterRowOperatorField: React.FC<FilterRowOperatorFieldProps> = ({
    operatorField,
    onOperatorChange,
    selectedColumnType,
}) => {
    const operatorsList = useAppSelector((state) => getFilterOperatorListByColumnType(state, selectedColumnType));
    const selectedFilterItemOperator = useAppSelector((state) => getFilterConfigItemByColumnTypeAndSelectedOperator(state, selectedColumnType, operatorField));
    const handleChange = (event: SelectChangeEvent<string>) => {
        onOperatorChange(event.target.value);
    };


    return (
        <Select
            sx={{ width: '100%', height: '47px' }}
            id=""
            name="condition"
            value={operatorField ?? selectedFilterItemOperator?.value}
            label=""
            placeholder="Condition"
            onChange={handleChange}
        >
            {operatorsList &&
                operatorsList.map((operator) =>
                    <MenuItem key={operator} value={operator}>
                        <span style={{ fontSize: "14px" }}>
                            {operator}
                        </span>
                    </MenuItem>
                )
            }
        </Select>
    );
};

export default FilterRowOperatorField;
