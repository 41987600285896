import PromptDeleteAction from '@components/prompt-delete-action/prompt-delete-action'
import BtEditableBox from '@components/tasks/bt-editable-box/bt-editable-box'
import SortButton from '@components/tasks/group-header/sort-button/sort-button'
import { Stack } from '@mui/material'
import { Box } from '@mui/system'
import { isTempId, taskGroupsSelectOneFieldById } from '@reducers/DBServiceReducers'
import { getGroupTaskListLength } from '@reducers/TaskSelectors'
import { toggleCollapseGroup, updateGroupNameThunk } from '@reducers/TaskThunks'
import { taskActions } from '@reducers/TasksReducer'
import { UndoRedoAction } from '@reducers/UndoRedoReducer'
import { doActionThunk } from '@reducers/UndoRedoThunks'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useCallback, useMemo, useState } from 'react'
import { BiPencil } from 'react-icons/bi'
import { FiTrash2 } from 'react-icons/fi'
import { TfiAngleDown, TfiAngleUp } from 'react-icons/tfi'
import { DEFAULT_ACCENT_COLOR } from 'theme/colors'
import classes from "../../group-header/group_header.module.scss"
import { CellProps } from '../cell-interface'

const inputTheme = {
    width: '100%',
    padding: "0.5px 5px",
    color: "var(--primary)",
    fontSize: '15px',
    border: '1px solid transparent',
    borderRadius: '4px',
    fontWeight: '600',
    background: 'var(--boards-panel-background)',
    "& .MuiInputBase-input": {
        padding: "0"
    }
}
const textTheme = {
    // minWidth: 100,
    cursor: 'text',
    color: "white",
    padding: "0 5px",
    fontSize: '15px',
    border: '1px solid transparent',
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontWeight: '600',
    maxWidth: '260px',
    borderRadius: '4px',
    "&:hover": {
        border: " 1px dashed var(--default-background);"
    },
}

export default function RowPrefixHeader({
    groupId,
    columnId
}: CellProps) {
    const dispatch = useAppDispatch()
    const groupTaskListLength: any = useAppSelector((state) => getGroupTaskListLength(state, groupId))
    const colorAccent = useAppSelector((state) => taskGroupsSelectOneFieldById(state.DBTaskgroupsReducer, groupId, "colorAccent"))
    const isCollapsed = useAppSelector((state) => taskGroupsSelectOneFieldById(state.DBTaskgroupsReducer, groupId, "isCollapsed"))
    const groupName = useAppSelector((state) => taskGroupsSelectOneFieldById(state.DBTaskgroupsReducer, groupId, "name"))
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const forceEditModeToGroupNameByGroupId = useAppSelector((state) => state.TasksReducer.forceEditModeToGroupNameByGroupId)

    const isTemp = useMemo(() => isTempId(groupId), [groupId]);

    const handleColapse = useCallback(() => {
        dispatch(toggleCollapseGroup({groupId}))
    }, [dispatch, groupId])

    const clearEditMode = useCallback(() => {
        if (forceEditModeToGroupNameByGroupId !== "") {
            dispatch(taskActions.setForceEditModeToGroupNameByGroupId(""))
        }
    }, [dispatch, forceEditModeToGroupNameByGroupId])

    const updateGroupName = useCallback((groupName) => {
        clearEditMode()
        if (!groupName) return
        dispatch(updateGroupNameThunk({ groupId, groupName }))
    }, [clearEditMode, dispatch, groupId])

    const deleteGroup = useCallback(() => {
        setConfirmDialogOpen(true);
    }, [])

    const forceGroupEditMode = useCallback(() => {
        dispatch(taskActions.setForceEditModeToGroupNameByGroupId(groupId))
    }, [dispatch, groupId])


    const setEditMode = useCallback((value) => {
        setIsEditing(value)
        clearEditMode()
    }, [clearEditMode])

    const handleDeleteConfirmationClosed = useCallback((shouldDelete?: boolean) => {
        if (shouldDelete === true) {
            const action: UndoRedoAction<string[], string> = {
                type: 'delete-group-list',
                data: [groupId],
                deletedOrArchived: "deleted"
            }
            dispatch(doActionThunk({ action }))
        }
        setConfirmDialogOpen(false);
    }, [dispatch, groupId])


    return (
        <>
            <PromptDeleteAction title={`Delete group?`} handleClose={handleDeleteConfirmationClosed} open={confirmDialogOpen} />
            <Box id={`group_color_picker_${groupId}`}
                className={classes.group_name_container} sx={{
                    // borderTopLeftRadius: (tasksGroup?.isCollapsed || groupTaskListLength === 0) ? 10 : groupTaskListLength === 0 && 10,
                    pointerEvents: isTemp ? 'none' : 'all'
                }}>
                <div
                    id={`group_header_${groupId}`}
                    className={classes.group_name_wrapper}
                    style={{
                        background: colorAccent ?? DEFAULT_ACCENT_COLOR,
                        marginBottom: groupTaskListLength === 0 ? 2 : 0,
                        insetInlineStart: "-6px",
                        flex: isEditing || groupId === forceEditModeToGroupNameByGroupId ? 'auto' : '',
                        borderRadius: (isCollapsed || groupTaskListLength === 0) && "12px",
                    }}>

                    {(!isEditing && forceEditModeToGroupNameByGroupId === "" && !isTemp && !isCollapsed) &&
                        <SortButton columnId={columnId} />
                    }
                    {/* <span style={{ color: "white" }}><b>{tasksGroup?.order}    ---  {tasksGroup?._id}   </b></span> */}
                    <BtEditableBox
                        data-testid='group-name-bteditablebox'
                        forceEditMode={groupId === forceEditModeToGroupNameByGroupId}
                        inputSx={inputTheme}
                        boxSx={textTheme}
                        text={groupName}
                        onValueUpdate={(value) => updateGroupName(value)}
                        onEditModeChange={(value) => setEditMode(value)}
                    />

                    {(!isEditing && forceEditModeToGroupNameByGroupId === "") &&
                        <button onClick={handleColapse} className={classes.collapse_button}>
                            {!isCollapsed ? <TfiAngleUp /> : <TfiAngleDown />}
                        </button>

                    }
                </div>

                {(!isEditing && forceEditModeToGroupNameByGroupId === "") &&
                    <Stack direction="row">
                        <button onClick={forceGroupEditMode} className={classes.edit_group_name_button}>
                            <BiPencil />
                        </button>
                        <button data-testid='group-header-delete-button' onClick={deleteGroup} className={classes.delete_group_button}>
                            <FiTrash2 />
                        </button>
                    </Stack>
                }

                {isCollapsed && (
                    <div style={{ marginLeft: 10, color: colorAccent ?? 'var(--text-color)' }}>
                        {groupTaskListLength === 0 ? "No items" :
                            (<>
                                {groupTaskListLength} Item{(groupTaskListLength as number > 1) ? "s" : ""}
                            </>
                            )}
                    </div>
                )}
            </Box>
        </>
    )
}