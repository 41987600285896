import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import SyncIcon from '@mui/icons-material/Sync';
import { Box, IconButton, Skeleton, Stack, Tooltip } from "@mui/material";
import { DBUserMessageThunks, isTempId } from "@reducers/DBServiceReducers";
import { sendMessageThunk } from "@reducers/WhatsappThunks";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { blobMap } from "@services/blob-service";
import moment from "moment";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { AiOutlineSync } from "react-icons/ai";
import { UserMessage } from "../models/user-message";
import { addScheduledMessageButtonActions } from "../reducers/AddScheduledMessageButtonReducer";
import { AnalyticsService } from "../services/analytics-service";
import { MessageOvedueLimit } from "../services/schedule-service";
import { useAppThunkDispatch } from "../store/hooks";
import { theme } from "../theme";
import AttachmentPreview from "./attachment-preview/attachment-preview";
import MessageProblem from "./message-problem";
import classes from "./whatsapp-message.module.scss";
import ContactAvatar from './bulk-dashboard/contact-table/contact-avatar';
import { isExtensionContext } from '@services/detect-context';
import RowStack from './common/row-stack/RowStack';
type WhatsappMessageProps = {
  hideActions?: boolean;
  scheduledMessage: UserMessage;
  isDisplayContactAvatar?: boolean;
  onClose?: () => void;
  onDelete?: () => void;
};

const singleLineEllipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
}

export function WhatsappMessage(props: WhatsappMessageProps): ReactElement {
  const analyticsService: AnalyticsService = useAnalyticsService();

  const dispatch = useAppThunkDispatch();
  const [failedToSend, setFailedToSend] = useState(false);
  const [pending, setPending] = useState(false);
  const [isOverdue, setIsOverdue] = useState(false);


  // Function to update UI state
  const updateUIState = useCallback(() => {
    const isMessageOverdue = checkIfOverdue(props.scheduledMessage.dueDate);
    setIsOverdue(isMessageOverdue);

    const hasFailedToSend = checkIfFailedToSend(props.scheduledMessage.updatedAt);
    setFailedToSend(props.scheduledMessage.status === 'sent' && hasFailedToSend);
    setPending(props.scheduledMessage.status === 'sent' && !hasFailedToSend);
  }, [props.scheduledMessage.dueDate, props.scheduledMessage.status, props.scheduledMessage.updatedAt]);

  // Function to check if the message is overdue
  const checkIfOverdue = (dueDate) => {
    return moment(dueDate).isBefore(moment().subtract(MessageOvedueLimit, "minutes"));
  };

  // Function to check if the message has failed to send
  const checkIfFailedToSend = (updatedAt) => {
    const lastUpdate = moment(updatedAt);
    const now = moment();
    return now.diff(lastUpdate, 'minutes') > 5;
  };

  // Update UI state based on prop changes
  useEffect(() => {
    updateUIState();
  }, [props.scheduledMessage.status, props.scheduledMessage.updatedAt, props.scheduledMessage.dueDate, updateUIState]);

  // Interval to update UI when message status changes
  useEffect(() => {
    if (props.scheduledMessage.status === 'sent') {
      const interval = setInterval(() => {

        updateUIState();
      }, 5000); // Adjust interval time as needed

      return () => clearInterval(interval);
    }
  }, [props.scheduledMessage.status, props.scheduledMessage.updatedAt, props.scheduledMessage.dueDate, updateUIState]);



  const linkPreviewImage = `data:image/jpeg;base64,${props.scheduledMessage.linkPreview?.thumbnail}`;

  const editMessage = () => {
    dispatch(
      addScheduledMessageButtonActions.editMessage(props.scheduledMessage)
    );
    analyticsService.event("edit_message", {
      category: "messages",
      action: "edit",
      label: props.scheduledMessage.isRecurring ? "recurring" : "regular",
    });
  };

  const sendMessage = (event: React.MouseEvent) => {
    console.log("sendMessage")
    event.stopPropagation();

    dispatch(sendMessageThunk(props.scheduledMessage))
  }

  const deleteMessage = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (props.onDelete) { props.onDelete() }
    else {
      dispatch(
        DBUserMessageThunks.delete({
          entity: props.scheduledMessage,
          options: { inCaseOfErrorRollbackOptimisticUpdateDisabled: true },
        })
      );
    }

    analyticsService.event("remove_message", {
      category: "messages",
      action: "remove",
      label: props.scheduledMessage.isRecurring ? "recurring" : "regular",
    });
    if (props.onClose) { props.onClose() }
  };

  const imageBlob = blobMap.get(props.scheduledMessage?.imageId);
  const isExtension = isExtensionContext()


  const buildActionContainer = () => <div className={classes.messageActionContainer}>
    <RowStack>
      <Tooltip title="Edit message" arrow>
        <IconButton sx={{ marginRight: "5px" }} onClick={editMessage} className={classes.message_icon}>
          <EditIcon style={{ fontSize: "15px", margin: !isExtension && '0px 0px 1px 1px', }} />
        </IconButton>
      </Tooltip>

      {/* {
      props.scheduledMessage.cancelIfReceived &&
      <Tooltip PopperProps={{ style: { textAlign: "left" } }} arrow
        style={{ direction: "ltr" }} title="This message will be canceld if message is received">
          <Box className={classes.message_icon}>
        <BsSendX style={{fontSize:"15px"}}  />
        </Box>
      </Tooltip>
    } */}
      <Tooltip
        PopperProps={{ style: { textAlign: "left" } }}
        arrow
        style={{ direction: "ltr" }}
        title="Send the message now"
      >
        <IconButton sx={{ marginRight: "5px", fontSize: "15px" }} className={classes.message_icon} onClick={sendMessage}>


          <SendIcon style={{ fontSize: "15px", margin: !isExtension && '0px 0px 1px 3px', }} />

        </IconButton>
      </Tooltip>
      {
        props.scheduledMessage.isRecurring && (
          <Tooltip
            PopperProps={{ style: { textAlign: "left" } }}
            arrow
            style={{ direction: "ltr" }}
            title="Recurring message"
          >
            <button className={classes.message_icon}
              style={{
                boxShadow: 'unset',
                background: '#a3a3a3',
                cursor: 'unset',
              }}

            >
              <AiOutlineSync />
            </button>
          </Tooltip>
        )}
    </RowStack>

    <RowStack>


      {pending && (
        <Tooltip
          PopperProps={{ style: { textAlign: "left" } }}
          style={{ direction: "ltr" }}
          arrow
          title="Message is pending"
        >
          <Box className={classes.message_icon} sx={{
            boxShadow: 'unset',
            background: '#a3a3a3',
            cursor: 'unset',
            // background: "#fff",
            // '& > svg': {
            //   color: 'orange'
            // }
          }}>
            <SyncIcon sx={{ fontSize: "15px" }} />
          </Box>
        </Tooltip>
      )}
      {failedToSend && (
        <Tooltip
          PopperProps={{ style: { textAlign: "left" } }}
          style={{ direction: "ltr" }}
          title="Message failed to send"
        >
          <Box className={classes.message_icon} sx={{
            boxShadow: 'unset',
            background: '#a3a3a3',
            cursor: 'unset',
            marginLeft: "5px",
            // '& > svg': {
            //   color: 'red'
            // }
          }}>
            <BlockIcon fontSize="small" />
          </Box>
        </Tooltip>
      )}
      {isOverdue && (

        <button className={classes.message_icon}
          style={{
            boxShadow: 'unset',
            background: '#a3a3a3',
            cursor: 'unset',
            marginLeft: "5px"
          }}>
          <MessageProblem />
        </button>

      )}
      <div className={classes.flexRow} style={{ marginLeft: "5px" }}>
        <Tooltip title="Delete message" arrow>
          <IconButton
            onClick={deleteMessage}
            className={classes.message_icon}
          >
            <CloseIcon style={{ fontSize: "16px" }} />
          </IconButton>
        </Tooltip>
      </div>
    </RowStack>
  </div>

  const linkPreviewBox = () => <Stack direction='row' sx={{
    overflow: 'hidden',
    borderRadius: '5px',
    background: 'var(--link-preview-background);',
    direction: 'ltr',
    height: 90,

  }}>
    <Stack sx={{
      p: '5px'
    }}>
      <Box sx={{
        ...singleLineEllipsis,
        mb: '2px',
        color: 'var(--link-preview);'
      }}>{scheduledMessage.linkPreview?.title?.slice(0, 26)}</Box>
      <Box sx={{
        ...singleLineEllipsis,
        mb: '2px',
        color: 'var(--link-preview-lighter);'
      }}>{scheduledMessage.linkPreview?.description?.slice(0, 26)}</Box>
      <Box sx={{
        ...singleLineEllipsis,
        mb: '2px',
        color: 'var(--link-preview-light);'
      }}>{scheduledMessage.linkPreview?.siteName}</Box>
    </Stack>
    <Box>
      <img style={{ height: '100%' }} alt='' src={linkPreviewImage} />
    </Box>
  </Stack>

  const buildMessage = () => {
    const { scheduledMessage } = props;
    return (
      <>
        {!props.hideActions && buildActionContainer()}
        {scheduledMessage.quotedMessage?.body &&
          !scheduledMessage.quotedMessage?.blob ? (
          <div
            style={{
              backgroundColor: theme.palette.primary.main,
              padding: "5px 8px 7px 12px",
              borderRadius: 5,
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          >
            {scheduledMessage.quotedMessage?.body}
          </div>
        ) : (
          <></>
        )}
        <Box sx={{ mb: 1 }}>
          {scheduledMessage.sendLinkPreview && linkPreviewBox()}
          {!scheduledMessage.sendLinkPreview && <AttachmentPreview
            attachment={imageBlob}
            attachmentUrl={scheduledMessage.asset?.url}
            attachmentType={scheduledMessage.asset?.type}
            attachmentName={scheduledMessage.asset?.name}
          />}
        </Box>
        {props.isDisplayContactAvatar &&
          <ContactAvatar
            contactId={props.scheduledMessage?.contactList[0]?.id}
            size={30}
            sx={{
              justifyContent: 'flex-start',
              m: '-7px 0px 8px 9px',
            }}
          />
        }
        <div className={classes.messageContainer}>
          {scheduledMessage.message}
        </div>
        <div dir="auto" className={classes.timestamp}>
          <Tooltip title="Scheduled Send Time" arrow>
            <div>
              {moment(scheduledMessage.dueDate).format("HH:mm ")}</div>
          </Tooltip>
          <Tooltip title="Scheduled Send Date" arrow>
            <div>{moment(scheduledMessage.dueDate).format("DD/MM/yyyy")}</div>

          </Tooltip>
        </div>
      </>
    );
  };

  const buildPlaceholder = () => {
    return (
      <div className={classes.placeholderWrap}>
        <Skeleton variant="circular" width={25} height={25} />
        <Skeleton variant="text" height={16} width={100} />
        <Skeleton variant="text" height={16} width={60} />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "38% 60%",
            justifyContent: "space-between",
          }}
        >
          <Skeleton height={12} variant="text" />
          <Skeleton height={12} variant="text" />
        </div>
      </div>
    );
  };

  const { scheduledMessage } = props;
  return (
    <Stack className={classes.messageBoxContainer}>
      {isTempId(scheduledMessage._id)
        ? buildPlaceholder()
        : buildMessage()}
    </Stack>
  );
}
