import { AccountPage } from "@components/account-page/account-page";
import BTThemeProvider from "@components/bt-theme-provider";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { selectUserObject } from "@reducers/UserSelectors";
import { useState } from "react";
import { useSelector } from "react-redux";
import { SideBarItem } from "../../../components/sidebar/sidebar";
import { User } from "../../../models/user";
import classes from "../account-view/account-view.module.scss";
// import TimezoneSelect, { ITimezone } from "react-timezone-select";
import BTButton from "@components/bt-button/bt-button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
export interface AccountLangViewProps { }

export interface AccountLangViewState {
  user?: User | null | undefined;
  loading: boolean;
}

const AccountLangView = () => {
  const user = useSelector(selectUserObject);
  const [lang, setLang] = useState("");
  const [timezone, setTimezone] = useState("");
  const [time, setTime] = useState(new Date());
  const [date, setDate] = useState(new Date());
  // const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>();

  const langChange = (event: SelectChangeEvent) => {
    setLang(event.target.value as string);
  };
  const timezoneChange = (event: SelectChangeEvent) => {
    setTimezone(event.target.value as string);
  };

  const accountLangView = () => {
    return (
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <h4 className={classes.pageTitle}>Language & region</h4>
        </div>

        <div className={classes.accountSettingBody} style={{
          overflow: 'auto',
        }}>
          <div className={classes.editableField}>
            <span className={classes.fieldName}>Language</span>
            <Select
              style={{ padding: 0, background: "var(--field-background-color)" }}
              className={classes.inputField}
              value={lang}
              onChange={langChange}
            >
              <MenuItem selected value={"English"}>
                English
              </MenuItem>
              <MenuItem value={"Português"}>Português</MenuItem>
            </Select>
          </div>{" "}
          <div className={classes.editableField}>
            <span className={classes.fieldName}>Timezone</span>
            <div style={{ padding: 0 }} className={classes.inputField}>
              <Select
                style={{ padding: 0, background: "var(--field-background-color)" }}
                className={classes.inputField}
                value={timezone}
                onChange={timezoneChange}
              >
                <MenuItem value={"English"}>Jerusalem (GMT +02:00)</MenuItem>
                <MenuItem value={"Português"}>Português</MenuItem>
              </Select>
            </div>
          </div>
          <div className={classes.editableField}>
            <span className={classes.fieldName}>Time format</span>
            <RadioGroup>
              <div
                style={{
                  padding: "8px 16px",
                  background: "var(--field-background-color)",
                  display: "flex",
                  alignContent: "center",
                }}
                className={classes.inputField}
              >
                <FormControlLabel
                  value="12-hours"
                  control={<Radio />}
                  label="12 Hours"
                />
                <span style={{ marginLeft: "auto", alignSelf: "center" }}>
                  {time.toLocaleString("en-US", {
                    minute: "2-digit",
                    hour: "2-digit",
                    hour12: true,
                  })}
                </span>
              </div>
              <div
                style={{
                  padding: " 8px 16px",
                  display: "flex",
                  background: "var(--field-background-color)",

                  alignContent: "center",
                }}
                className={classes.inputField}
              >
                <FormControlLabel
                  value="24-hours"
                  control={<Radio />}
                  label="24 Hours"
                />
                <span style={{ marginLeft: "auto", alignSelf: "center" }}>
                  {time.toLocaleString("en-US", {
                    minute: "2-digit",
                    hour: "2-digit",
                    hour12: false,
                  })}
                </span>
              </div>
            </RadioGroup>
          </div>
          <div className={classes.editableField}>
            <span className={classes.fieldName}>Date format</span>
            <RadioGroup>
              <div
                style={{
                  background: "var(--field-background-color)",
                  display: "flex",
                  alignContent: "center",
                }}
                className={classes.inputField}
              >
                <FormControlLabel
                  value="12-hours"
                  control={<Radio />}
                  label={date.toLocaleString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                />
                <span
                  style={{ marginLeft: "auto", alignSelf: "center" }}
                ></span>
              </div>
              <div
                style={{
                  padding: "0 16",
                  background: "var(--field-background-color)",
                  display: "flex",
                  alignContent: "center",
                }}
                className={classes.inputField}
              >
                <FormControlLabel
                  value="date2"
                  control={<Radio />}
                  label={date.toLocaleString("en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                />
              </div>
            </RadioGroup>
          </div>
          <div
            style={{ alignItems: "flex-end" }}
            className={classes.editableField}
          >
            <BTButton >
            Save changes
            </BTButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <BTThemeProvider>
      <AccountPage
        page={accountLangView()}
        active={SideBarItem.Language}
      ></AccountPage>
    </BTThemeProvider>
  );
};

export default AccountLangView;
