import { useCallback, useEffect } from 'react'
import NewTaskAndExtradata from '../new-task-and-extradata/new-task-andextradata'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { Box, ClickAwayListener, Popper } from '@mui/material'
import { QUICK_PANEL } from 'reusable/interfaces'
import { DBTaskColumnThunks } from '@reducers/DBServiceReducers'
import { CustomColumnData } from '@models/task'
import { btQuickPanelActions } from '@reducers/BtQuickPanelReducer'
import { columnListSelector } from '@reducers/TaskSelectors'


export default function LinkPopper() {
    const dispatch = useAppDispatch()
    const activeLink = useAppSelector((state) => state.BtQuickPanelReducer.activeLink)
    const popperId = useAppSelector((state) => state.BtQuickPanelReducer.popperId)
    const columnList = useAppSelector(state => columnListSelector(state, activeLink.boardId));
    const [firstPersonColumn] = columnList.filter(column => column.type === 'person-picker');
    const columnId = firstPersonColumn?._id;


    const loadColumns = useCallback(async () => {
        dispatch(
            DBTaskColumnThunks.find({
                boardId: activeLink.boardId,
                deleted: false,
                $paginate: false,
            })
        )

    }, [dispatch, activeLink.boardId])


    useEffect(() => {
        loadColumns()
    }, [dispatch, loadColumns])


    const customData: Record<string, CustomColumnData> = {
        [columnId]: {
            value: [{ id: activeLink.personId, type: 'user' }],
        }
    }

    let anchorEl

    const _popperId = `${activeLink._id}-${QUICK_PANEL.QUICK_LINK_PANEL_ID}`
    if (popperId === _popperId) {
        anchorEl = document.getElementById(_popperId)
    }

    if (popperId !== _popperId) return

    return (
        <ClickAwayListener
            onClickAway={() => dispatch(btQuickPanelActions.setPopperId(''))}
        >
            <Popper
                id={popperId}
                anchorEl={anchorEl}
                open={popperId === _popperId}
                placement='right-end'
                sx={{ zIndex: 1390 }}
            >
                <Box sx={{ mx: 1 }}>
                    <NewTaskAndExtradata
                        customData={activeLink.personId ? customData : undefined}
                        boardId={activeLink.boardId}
                        groupId={activeLink.groupId}
                        colorAccent={activeLink.colorAccent}
                    />
                </Box>
            </Popper>
        </ClickAwayListener>
    )
}