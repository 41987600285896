import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { labelActions } from '@reducers/labels-reducer';
import { ButtonBase, CircularProgress, colors } from '@mui/material';
import { Close } from '@mui/icons-material';
import LabelForm from '../label-input-with-emoji-picker';
import TalkToUsFooter from './talktous-footer';

import mainClasses from '../labels-feature.module.scss'
import classes from './modal.module.scss'
import { updateLabelThunk } from '@reducers/labels-reducer/labels-thunks/crud';



export default function EditLabelModalModal() {
    const dispatch = useAppDispatch()
    const label = useAppSelector((state) => state.LabelReducer.label)
    const isLabelEditting = useAppSelector((state) => state.LabelReducer.isLabelEditting)
    const labelNetWorkStatus = useAppSelector((state) => state.LabelReducer.labelNetWorkStatus)

    return (
        <div>

            <Modal
                open={isLabelEditting}
                onClose={() => {
                    dispatch(labelActions.toggleEditLabel(false))
                    dispatch(labelActions.setLabel({}))
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.container}>
                    <Box className={classes.modalHead}>
                        <Typography id="modal-modal-title"
                            sx={{ flex: 1, fontWeight: 600 }} variant="h6" component="h2">
                            Edit Custom Label
                        </Typography>

                        <ButtonBase
                           className={classes.closeModalButton}
                            onClick={() => {
                                dispatch(labelActions.toggleEditLabel(false))
                                dispatch(labelActions.setLabel({}))
                            }}
                        >
                            <Close sx={{ fontSize: '25px' }} />
                        </ButtonBase>
                    </Box>
                    <LabelForm />
                    <Box className={classes.suggestedLabels}>
                        <Box className={classes.createWrap}>
                            <ButtonBase
                            className={mainClasses.labelButtonBase}
                                onClick={() => dispatch(updateLabelThunk())}
                                sx={{
                                    flexBasis: '40%',
                                    bgcolor: label.name ? '#546eff!important' : '',
                                    color: label.name ? '#fff!important' : '',
                                    '&:hover': {
                                        color: labelNetWorkStatus === 'update-label' ? '' : '#fff',
                                        backgroundColor: labelNetWorkStatus === 'update-label' ? colors.grey[200] : '#546eff',
                                    }
                                }}>
                                {labelNetWorkStatus === 'update-label' ? 'Updating..' : 'Save'}
                                {labelNetWorkStatus === 'update-label' && <CircularProgress size={16} sx={{
                                    ml: 1,
                                }} />}

                            </ButtonBase>
                        </Box>
                    </Box>

                    <TalkToUsFooter />
                </Box>
            </Modal>
        </div >
    );
}