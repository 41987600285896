import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import { Box, ButtonBase, Popover } from '@mui/material';
import { useAppDispatch } from '@store/hooks';
import classes from '../labels-feature.module.scss'
import MoodIcon from '@mui/icons-material/Mood';
import EmojiPicker from 'emoji-picker-react';
import { _Label } from '@models/label';
import { labelActions } from '@reducers/labels-reducer';
import popperClasses from '../poppers/poppers.module.scss'
type Props = {
    label: _Label
    disabled?: boolean
}

export default function EmojiPickerPopper({ label, disabled }: Props) {
    const dispatch = useAppDispatch()
    return (
        <PopupState variant='popper'>
            {((popupState) => (
                <Box sx={(theme) => ({
                    flexBasis: '20%',
                    marginTop: '2px',
                    [theme.breakpoints.down("sm")]: {
                        flexBasis: '20%',
                        marginTop: 0,
                        marginLeft: '10px',
                    }
                })}>
                    <ButtonBase
                        className={popperClasses.emojiPickerButton}
                        disabled={disabled}
                        {...bindTrigger(popupState)}
                    >
                        {label.icon || <MoodIcon />}
                    </ButtonBase>

                    <Popover
                        {...bindPopover(popupState)}
                        classes={{
                            root: classes.PopperContainer,
                            paper: classes.CustomPaper
                        }}

                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}

                        elevation={0}
                    >
                        <Box>
                            <EmojiPicker
                                onEmojiClick={({ emoji }: any) => {
                                    dispatch(labelActions.setLabel({ ...label, icon: emoji }))
                                    popupState.close()
                                }}
                                emojiVersion='4.0'
                            />
                        </Box>
                    </Popover>
                </Box>
            ))}
        </PopupState>
    )
}