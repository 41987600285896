import { previewPhoto } from "@reducers/ExtraDataReducer";
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { MdClose } from 'react-icons/md';
import classes from './image-previewer.module.scss';

export default function ImagePreviewer() {
  const dispatch = useAppDispatch()
  const imagePreview = useAppSelector((state) => state.ExtraDataReducer.imagePreview);


  function handleClose() {
    dispatch(previewPhoto({ isActive: false, imageURL: "" }))
  }


  return (
    <div className={classes.container}>
      <div className={classes.image_wrapper}>
        <button className={classes.close_button} onClick={handleClose}>
          <MdClose />
        </button>
        <img style={{height:"90vh",maxWidth:"100%"}} src={imagePreview.imageURL} />
      </div>
    </div>
  );
}
