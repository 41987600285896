import React, { CSSProperties } from 'react'

type Props = {
    label: string
}

export default function CampaignStatusLabel({ label }: Props) {
    const style: CSSProperties = {
        borderRadius: '6px',
        fontSize: '13px',
        fontWeight: 600,
        display: 'flex', // Ensuring the content is aligned properly
        alignItems: 'center', // Center align items vertically
        justifyContent: 'center', // Center align items horizontally
        padding: '2px 7px 5px 7px'
    }


    const completed = () => <div style={{
        color: 'var(--campaign-status-completed-text)',
        backgroundColor: 'var(--campaign-status-completed-bg)',
        ...style,
    }}>
        Completed
    </div>

    const pending = () => <div style={{
        color: 'var(--campaign-status-pending-text)',
        backgroundColor: 'var(--campaign-status-pending-bg)',
        width: '66px',
        height: '24px',
        ...style,
    }}>
        Pending
    </div>

    const running = () => <div style={{
        color: 'var(--campaign-status-running-text)',
        backgroundColor: 'var(--campaign-status-running-bg)',
        width: '70px',
        height: '24px',
        ...style,
    }}>
        Running
    </div>

    const aborted = () => <div style={{
        color: 'var(--campaign-status-aborted-text)',
        backgroundColor: 'var(--campaign-status-aborted-bg)',
        width: '70px',
        height: '24px',
        ...style,
    }}>
        Aborted
    </div>

    const paused = () => <div style={{
        color: 'var(--campaign-status-paused-text)',
        backgroundColor: 'var(--campaign-status-paused-bg)',
        width: '60px',
        height: '24px',
        ...style,
    }}>
        Paused
    </div>

    return (
        <>
            {label === 'completed' &&
                <> {completed()} </>
            }
            {label === 'pending' &&
                <>  {pending()} </>
            }
            {label === 'running' &&
                <> {running()}</>
            }
            {label === 'aborted' &&
                <> {aborted()} </>
            }
            {label === 'paused' &&
                <>{paused()}  </>
            }
        </>
    )
}