import { Box, Button } from "@mui/material";
import classes from "./message-editor.module.scss";

interface P {
  confirmBtnTxt: string;
  confirmDisabled?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

function MessageEditorFooter(props: P) {
  return (
    <Box className={classes.actions}>
      <Button
        onClick={props.onCancel}
        className={classes.cancelBtn}
        variant="outlined"
        style={{
          color: "var(--snippet-btn-color)",
          borderColor: "var(--snippet-btn-color)",
        }}
      >
        CANCEL
      </Button>
      <Button
        variant="contained"
        onClick={() => props.onConfirm()}
        className={classes.saveBtn}
        color="primary"
        sx={{ ml: 2 }}
        disabled={props.confirmDisabled}
      >
        {props.confirmBtnTxt}
      </Button>
    </Box>
  );
}

export default MessageEditorFooter;
