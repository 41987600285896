import { TasksGroup } from "@models/task-group";
import { Stack } from "@mui/material";
import { getCurrentBoard } from "@reducers/BoardsSelectors";
import { taskgroupsSelectors } from "@reducers/DBServiceReducers";
import { columnListForDisplaySelector } from "@reducers/TaskSelectors";
import { useAppSelector } from "@store/hooks";
import { memo, useMemo } from "react";
import ColumnFooterCell from "../columns-cells/column-footer-cell/column-footer-cell";

interface Props {
  groupId: string;
}


function FooterRow({ groupId }: Props) {
  const tasksGroup: TasksGroup = useAppSelector((state) => {
    return taskgroupsSelectors.selectById(state, groupId)
  });

  let columnList = useAppSelector(state => columnListForDisplaySelector(state, tasksGroup?.boardId, groupId));
  const columnsNotStatic = useMemo(() => columnList.filter(column => !column.static), [columnList])
  const board = useAppSelector(getCurrentBoard)
  let offset = 0;
  if (board.type === 'audience') {
    offset = 80; // No extra-data cell in audience
  }

  const styles = {
    position: "absolute",
    marginTop: '8px',
    transition: '0.05s',
    insetInlineStart: (board?.textColumnWidth ? board?.textColumnWidth + 125 : 526) - offset,
  };
  return (
    <Stack sx={{ ...styles }} className={"column-row-prefix-footer"} direction="row">
      {columnsNotStatic.map((column, index) => (
        <ColumnFooterCell key={index} column={column} index={index} columnId={column._id} groupId={groupId} boardId={tasksGroup?.boardId} columnType={column.type} />
      ))}
    </Stack>

  );
}

export default memo(FooterRow);
