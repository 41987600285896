import BTThemeProvider from "@components/bt-theme-provider";
import { WhatsappMessage } from "@components/whatsapp-message";
import {
  Box,
  ClickAwayListener,
  Dialog
} from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { EventPL } from "./message-calendar";

interface P {
  event: EventPL;
  onClose?: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export const eventDialogColor = "#5f6368";

function SelectedEvent(props: P) {
  const boxRef = createRef<HTMLDivElement>();
  const [paperLeft, setPaperLeft] = useState(0);
  const [paperTop, setPaperTop] = useState(0);


  // postion dialog next to calendar message
  useEffect(() => {
    const {
      left: dialogLeft,
      right: dialogRight,
      top: dialogTop,
      bottom: dialogBottom,
    } = boxRef.current.getBoundingClientRect();
    const { left: eventLeft, top: eventTop, right: eventRight } = props.event;

    const dialogWidth = dialogRight - dialogLeft;
    const eventWidth = eventRight - eventLeft;
    const hasPlaceToRight =
      window.innerWidth > eventLeft + eventWidth + dialogWidth;
    const hasPlaceToLeft = eventLeft > dialogWidth;

    const left = hasPlaceToLeft
      ? eventLeft - dialogWidth
      : hasPlaceToRight
        ? eventLeft + eventWidth
        : window.innerWidth / 2 - dialogWidth / 2;

    setPaperLeft(left);

    const dialogHeight = dialogBottom - dialogTop;
    const hasPlaceBelow = window.innerHeight - eventTop - dialogHeight > 0;
    const top = hasPlaceBelow ? eventTop : eventTop - dialogHeight;

    setPaperTop(top);
  }, [boxRef, props.event]);

  return (
    <BTThemeProvider>
      <Dialog
        disablePortal
        open={true}
        sx={{
          ".MuiBackdrop-root": {
            backgroundColor: "unset",
          },
        }}
        PaperProps={{
          sx: {
            position: "absolute",
            margin: 0,
            left: paperLeft,
            top: paperTop,
            color: eventDialogColor,
            // minWidth: "400px",
            // minHeight: "200px",
          },
        }}
      >
        <ClickAwayListener onClickAway={props.onClose}>
          <Box ref={boxRef}>
            <WhatsappMessage
              scheduledMessage={props.event}
              isDisplayContactAvatar={true}
              onClose={props.onClose}
              onDelete={props.onDelete}
            />
          </Box>
        </ClickAwayListener>
      </Dialog>
    </BTThemeProvider>
  );
}

export default SelectedEvent;
