import { Template } from '@models/template';
import { ArrowDropDown } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import { Stack } from '@mui/system';
import { getTemplatePlaceholderListForCampaign } from '@reducers/CampaignSelectors';
import { DBTaskColumnThunks } from '@reducers/DBServiceReducers';
import { insertTextAtCursor } from '@services/utils';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import { theme } from 'theme';

type Props = {
    value?: Template;
    setTemplateLocalState: React.Dispatch<React.SetStateAction<Partial<Template>>>;
    templateLocalState: Partial<Template>;
    templateMessageArea: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
    showTemplateButtons: boolean;
}

export default function TemplatePlaceholderBar({
    value,
    setTemplateLocalState,
    templateLocalState,
    templateMessageArea,
    showTemplateButtons,
}: Props) {
    const dispatch = useAppDispatch()
    const newCampaign = useAppSelector((state) => state.CampaignReducer.newCampaign)
    const templatePlaceholderList: string[] = useAppSelector((state) => getTemplatePlaceholderListForCampaign(state, newCampaign.boardIDArray))
    const activeBulkView = useAppSelector((state) => state.CampaignReducer.activeBulkView);


    const [placeholderDropdownAnchorEl, setPlaceholderDropdownAnchorEl] = useState(null)
    const [isEditMode, setEditMode] = useState<boolean>(activeBulkView === "campaignNewTemplateViewFromManagement"
        || activeBulkView === "campaignNewTemplateViewFromNewCampaignView"
        || activeBulkView === "campaignNewTemplateViewFromWhatsappView");




    const handlePlaceholderDropdownClick = (event) => {
        setPlaceholderDropdownAnchorEl(event.currentTarget);
    };

    const handlePlaceholderDropdownClose = () => {
        setPlaceholderDropdownAnchorEl(null);
    };



    useEffect(() => {
        for (let boardId of newCampaign.boardIDArray ?? []) {
            dispatch(DBTaskColumnThunks.find({
                boardId,
                deleted: false,
                $paginate: false,
            }))
        }
    }, [])

    return (
        <Stack direction="row" alignItems="center"
            style={{
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
                gap: '10px'
            }}>
            {isEditMode &&
                ['First Name', 'Last Name', 'Phone'].map((val, index) =>
                    <Button key={index}
                        sx={{
                            border: '1px solid rgba(0,0,0,0.05)',
                            borderRadius: '16px',
                            height: '27px',
                            width: 'auto',
                            textTransform: 'none',
                            color: 'var(--primary)',
                        }}
                        onClick={() =>
                            setTemplateLocalState({
                                ...templateLocalState,
                                message: insertTextAtCursor(templateMessageArea, `{${val}}`)
                            })
                        }
                    >
                        {val}
                    </Button>)
            }
            {!isEditMode && showTemplateButtons &&
                templatePlaceholderList.slice(0, 4).map((val, index) =>
                    <Button key={index}
                        sx={{
                            border: '1px solid rgba(0,0,0,0.05)',
                            borderRadius: '16px',
                            height: '27px',
                            width: 'auto',
                            textTransform: 'none',
                            color: 'var(--primary)',
                        }}
                        onClick={() =>
                            setTemplateLocalState({
                                ...templateLocalState,
                                message: insertTextAtCursor(templateMessageArea, `{${val}}`)
                            })
                        }
                    >
                        {val}
                    </Button>)}
            {!isEditMode && templatePlaceholderList.length > 4 &&
                <div>
                    <IconButton
                        aria-controls="menu"
                        aria-haspopup="true"
                        onClick={handlePlaceholderDropdownClick}
                        color="inherit"
                        edge="end"
                    >
                        <ArrowDropDown />
                    </IconButton>
                    <Menu
                        id="menu"
                        anchorEl={placeholderDropdownAnchorEl}
                        keepMounted
                        open={Boolean(placeholderDropdownAnchorEl)}
                        onClose={handlePlaceholderDropdownClose}
                    >
                        {templatePlaceholderList.slice(4).map((val, index) => (
                            <MenuItem key={index} onClick={handlePlaceholderDropdownClose}>
                                <Button
                                    sx={{
                                        border: '1px solid rgba(0,0,0,0.05)',
                                        borderRadius: '16px',
                                        height: '27px',
                                        width: 'auto',
                                        textTransform: 'none',
                                        color: 'var(--primary)',
                                    }}
                                    onClick={() =>
                                        setTemplateLocalState({
                                            ...templateLocalState,
                                            message: insertTextAtCursor(templateMessageArea, `{${val}}`)
                                        })
                                    }
                                >
                                    {val}
                                </Button>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            }
        </Stack >
    )
}