import { Stack } from '@mui/system'
import React, { useState } from 'react'
import PersonAvatar from '../person-avatar/person-avatar'
import { cancelSubscriptionThunk, createSubscriptionThunk, getIsUserSubscribed } from '@reducers/ExtraDataThunks'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { IconButton, Snackbar, SnackbarContent } from '@mui/material'
import { Cancel } from '@mui/icons-material'

type Props = {
    user: any
    index: number
}

export default function SubscriptionManagementEntityRow({
    user,
    index,
}: Props) {
    const dispatch = useAppDispatch()
    const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>(undefined)

    const [isSubscribed, isSubscribedToBoard] = useAppSelector((state) => getIsUserSubscribed(state, user?.userId))

    const getUserDisplayString = (user) => {
        if (user?.profile?.firstName && user?.profile?.lastName) {
            return `${user.profile.firstName} ${user.profile.lastName}`
        } else if (user?.profile?.firstName) {
            return `${user.profile.firstName}`
        } else if (user?.profile?.lastName) {
            return `${user.profile.lastName}`
        } else {
            return `${user?.email}`
        }
    }

    const createSubscription = (user) => {
        dispatch(createSubscriptionThunk({ userId: user?.userId }))
    }

    const cancelSubscription = () => {
        if (isSubscribedToBoard) {
            setSnackbarMessage('You cannot unsubscribe a user that is subscribed to the entire board')
        }
        else {
            dispatch(cancelSubscriptionThunk({
                userId: user?.userId,
            }))
        }
    }

    const userDisplayString = getUserDisplayString(user)
    return (
        <Stack direction='row' key={index} onClick={(event) => { createSubscription(user) }} sx={{
            cursor: 'pointer',
            justifyContent: 'space-between',
        }}>
            <Stack direction='row'>
                <PersonAvatar
                    size={32}
                    email={user.email}
                    profileImg={user?.profile?.profileImg}
                    lastName={(user?.profile?.lastName === 'undefined' || user?.profile?.lastName === undefined) ? ' ' : user?.profile.lastName}
                    firstName={user?.profile?.firstName}
                    toolTip={true}
                />
                <p style={{ marginInlineStart: '10px', }}>{userDisplayString}</p>
            </Stack>
            {isSubscribed &&
                <IconButton onClick={cancelSubscription}>
                    <Cancel />
                </IconButton>
            }

            <Snackbar
                open={snackbarMessage !== undefined}
                onClose={() => setSnackbarMessage(undefined)}
                autoHideDuration={3000}
                key="imported-contacts"
            >
                <SnackbarContent style={{ display: 'flex', justifyContent: 'center' }} message={snackbarMessage} />
            </Snackbar>
        </Stack>
    )
}