import { Invoice } from '@models/invoice';
import { SubscriptionObject } from '@models/subscription';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, CircularProgress, Collapse, IconButton, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useAuthenticateMutation, useCancelSubscriptionMutation, useOrderDetailsMutation } from '@reducers/backend-api/backend-api';
import { InvoiceReducerActions, invoiceSelectByQuery } from '@reducers/DBServiceReducers';
import { subscriptionObjectListSelector } from '@reducers/UserSelectors';
import { useAppSelector } from '@store/hooks';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '@store/hooks';

export default function SubscriptionTable() {
    const dispatch = useAppDispatch()
    const [orderDetails] = useOrderDetailsMutation();
    const invoiceList = useAppSelector(state => invoiceSelectByQuery(state.DBInvoiceReducer, {}))
    const [subscriptionFetchMap, setSubscriptionFetchMap] = useState<Record<string, boolean>>({})
    const subscriptionList = useAppSelector(subscriptionObjectListSelector);

    const subscriptionInvoiceMap: Record<string, Invoice[]> = useMemo(() => invoiceList.reduce((_subscriptionInvoiceMap, invoice) => ({
        ..._subscriptionInvoiceMap,
        [invoice.subscriptionId]: [
            ...(_subscriptionInvoiceMap[invoice.subscriptionId] ?? []),
            invoice
        ]
    }), {}), [invoiceList])

    const getOrderDetails = useCallback(async (orderId: string) => {
        if (!subscriptionFetchMap[orderId]) {
            setSubscriptionFetchMap({
                ...subscriptionFetchMap,
                [orderId]: true
            })
            const invoiceList = await orderDetails(orderId).unwrap()
            dispatch(InvoiceReducerActions.addMany(invoiceList))
        }
    }, [dispatch, orderDetails, subscriptionFetchMap])

    useEffect(() => {
        subscriptionList.forEach((subscription) => {
            getOrderDetails(subscription._id)
        })
    }, [getOrderDetails, subscriptionList])

    const buildTable = () => <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell>Workspace</TableCell>
                    <TableCell>Plan</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Expiry Date</TableCell>
                    <TableCell>Renewal Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(subscriptionList || []).map((subscription: SubscriptionObject, index) => (
                    <Row subscription={subscription} invoiceList={subscriptionInvoiceMap[subscription._id ?? '']} key={index} />
                ))}
            </TableBody>
        </Table>
    </TableContainer>;

    return buildTable();
}

interface RowProps {
    subscription: SubscriptionObject;
    invoiceList?: Invoice[];
    initialyOpen?: boolean;
}

const Row = ({ subscription, invoiceList, initialyOpen }: RowProps) => {
    const accessToken = useAppSelector((state) => state.UserReducer.accessToken);
    const [triggerCancelSubscription, { isLoading: isCancellingSubscription }] = useCancelSubscriptionMutation();
    const [authenticate, { isLoading: isAuthenticating }] = useAuthenticateMutation();
    const [open, setOpen] = useState(initialyOpen ?? false);

    const loading = useMemo(() => isAuthenticating || isCancellingSubscription, [isAuthenticating, isCancellingSubscription]);

    const cancelSubscription = async (subscriptionID?: string) => {
        if (subscriptionID) {
            await triggerCancelSubscription({
                subscriptionID,
                reason: 'Cancelled on account subscription page'
            });
            await authenticate({
                strategy: 'jwt',
                accessToken
            })
        }
    }

    const buildLoader = () => <TableRow>
        <TableCell colSpan={5}>
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ p: 1 }}>
                <CircularProgress></CircularProgress>
            </Stack>
        </TableCell>
    </TableRow>

    const buildRow = () => <>
        <TableRow>
            <TableCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell>{subscription.workspace?.name}</TableCell>
            <TableCell>{subscription.planName}</TableCell>
            <TableCell component="th" scope="row">{subscription.status}</TableCell>
            <TableCell>{moment(subscription.renewalTime).format('DD/MM/YYYY')}</TableCell>
            <TableCell component="th" scope="row">{subscription.origStatus}</TableCell>
            <TableCell>
                {
                    subscription.origStatus === 'ACTIVE' ?
                        <Button variant='outlined' size='small' color='primary'
                            onClick={() => cancelSubscription(subscription._id)}>Cancel</Button> :
                        <></>
                }
            </TableCell>
        </TableRow>
        {invoiceList?.length > 0 && <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                            Invoices
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Invoice</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(invoiceList ?? []).map((invoice, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{moment(invoice.document.creationDate * 1000).format('DD/MM/yyyy')}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {invoice.document.amount}
                                            {invoice.document.currency}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton component={Link} target="_blank" href={invoice.url.en}>
                                                <DescriptionIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>}
    </>

    return (loading ? buildLoader() : buildRow())
}