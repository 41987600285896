import { NotificationsActiveRounded } from '@mui/icons-material';
import { getNumberOfUnseenNotifications } from '@reducers/NotificationsSelectors';
import { taskActions } from '@reducers/TasksReducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import classes from "./tasks-header.module.scss";

type Props = {}

export default function NotificationsButton({ }: Props) {
    const dispatch = useAppDispatch();

    const numberOfUnseenNotifications = useAppSelector((state) => getNumberOfUnseenNotifications(state))


    const openNotificationsDrawer = () => {
        dispatch(taskActions.setTaskViewSideDrawerOpenWith("notificationsDrawer"))
    }


    return (
        <div style={{ position: 'relative' }}>
            {numberOfUnseenNotifications > 0 && (
                <div className={classes.redCircle}>
                    {numberOfUnseenNotifications}
                </div>
            )}
            <button className={classes.button + " " + classes.with_shadow_on_hover} onClick={openNotificationsDrawer}>
                <NotificationsActiveRounded />
            </button>
        </div>
    )
}