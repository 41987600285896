import { TaskColumnOption } from "@models/task-column-option";
import { ModeEdit } from "@mui/icons-material";
import { Button } from "@mui/material";
import { BiPencil } from "react-icons/bi";
import classes from "./picker-dialog.module.scss";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";

type Props = {
  options: TaskColumnOption[];
  handleValueSelected: (option: any) => void;
  onEdit: () => void;
  canEdit?: boolean;
  isComplexity?: boolean;
};

export default function MappedOptions({
  options,
  handleValueSelected,
  onEdit,
  canEdit,
  isComplexity
}: Props) {
  const { t } = useTranslation(namespace);
  function changeToEditMode() {
    onEdit();
  }

  return (<>
    <div className={classes.label_container}>
      {/* Render custom labels */}
      {(options ?? [])
        .sort((a, b) => a.order - b.order)
        .map((option: TaskColumnOption, index: number) => (
          <div key={index}  >

            {!isComplexity &&
              <button
                onClick={() => handleValueSelected(option)}
                className={classes.pickerBtn}
                style={{
                  backgroundColor: option?.bgcolor,
                  color: "#fff",
                  textTransform: "capitalize",
                  marginTop: 5,
                  boxShadow: "none",
                  fontSize: 12,
                  borderRadius: 5,
                  fontWeight: "600",
                  fontFamily: "'Inter', 'Assistant', sans-serif",
                  height: 28,
                  // marginLeft: 25,
                  // marginRight: 25,
                  width: '180px',
                }}
              >
                {option?.label}
              </button>
            }
            {isComplexity &&
              <button
                onClick={() => handleValueSelected(option)}
                className={classes.pickerBtn}
                style={{
                  backgroundColor: option?.bgcolor,
                  color: "#fff",
                  textTransform: "capitalize",
                  marginTop: 5,
                  boxShadow: "none",
                  fontSize: 12,
                  borderRadius: 5,
                  fontWeight: "600",
                  fontFamily: "'Inter', 'Assistant', sans-serif",
                  height: 28,
                  // marginLeft: 25,
                  // marginRight: 25,
                  width: '180px',
                }}
              >
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: '5px',
                  paddingLeft: '5px'
                }}>
                  <div>{option?.label ?? ''}</div>
                  <div style={{ border: '2px solid var(--border-color-two)', borderRadius: '5px', padding: '1px', minWidth: '15px' }}>{option?.complexity ?? ''}</div>
                </div>
              </button>
            }
          </div>
        ))}
    </div>

    <div className={classes.edit_labels_button_wrap}>
      <Button
        onClick={changeToEditMode}
        disabled={!canEdit}
        className={classes.edit_labels_button}
        style={{
          textTransform: "capitalize",
          marginTop: 5,
          boxShadow: "none",
          fontSize: 12,
          borderRadius: 0,
          color: 'var(--option-picker-button)',
        }}
      >
        <BiPencil size={16} />
        <p style={{
          margin: '0px 5px',
          fontFamily: "'Inter', sans-serif",
          fontWeight: "600",
          fontSize: 14,
        }}>
          {t('mappedOptions.editLabel')}
        </p>
      </Button>
    </div>
  </>

  );
}









