import { Box, Stack } from "@mui/system";
import { automation } from "@reducers/AutomationReducer";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import AddAndRemove from "../add-and-remove";
import { StyledTextField, errorStyle,setCurrentAction,buttonStyle } from "../automations-helper";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import _ from "lodash";


const CreateGroupAction = ({ index }) => {
    const dispatch = useAppDispatch()
    const [groupName, setGroupName] = useState('Group name');
    const prefix = index === 0 ? 'Then' : 'and then'
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    const actions = useAppSelector((state) => state.AutomationReducer.actions);

    // const handleGroupNameChange = ({ target }: any) => {
    //     dispatch(automation.setActions(setCurrentAction(actions, index, { groupName: target.value })));

    // }

    
    const debouncedDispatch = useMemo(() =>
        _.debounce((value) => {
            dispatch(automation.setActions(setCurrentAction(actions, index, { groupName: value })));

        }, 300), [dispatch, actions, index]);

    const handleGroupNameChange = useCallback(({ target }: any) => {
        setGroupName(target.value)
        debouncedDispatch(target.value);
    }, [debouncedDispatch]);



    useEffect(() => {
        if (actions) {
            setGroupName(actions[index].groupName);
            dispatch(automation.fieldValidityChanged({ component: 'CreateGroupAction', index, field: 'groupName', isValid: !!actions[index].groupName }));
        }
    }, [actions, dispatch, index]);

    return (
        <Stack sx={{ fontSize: '24px' }} direction="row" justifyContent={"space-between"}>
            <Box>
                {prefix} create a
               
                    <StyledTextField
                        onChange={handleGroupNameChange}
                        value={groupName ?? ""}
                        variant={"standard"}
                        placeholder="Group"
                        sx={{
                            ...buttonStyle,
                            ...(attemptedSubmission && validationStates[`CreateGroupAction_${index}`]?.groupName === false ? errorStyle : {}),
                        }}
                    />
            
            </Box>
            <AddAndRemove elements={actions} index={index} componentName="CreateGroupAction" type={"actions"} />

        </Stack>
    );
};

export default CreateGroupAction;
