import BTButton from '@components/bt-button/bt-button'
import PersonAvatar from '@components/tasks/person-avatar/person-avatar'
import { Board } from '@models/board'
import { BlueticksBoardUser } from '@models/board-user'
import { QuickLaunch } from '@models/quick-launch'
import { TasksGroup } from '@models/task-group'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { colors, styled } from '@mui/material'
import { Box } from '@mui/system'
import { DBBoardUserThunks, DBQuickLaunchThunks, DBTaskgroupsThunks, boardUserSelectByQuery, boardsSelectors, quickLaunchSelectByQuery, taskGroupsSelectByQuery } from '@reducers/DBServiceReducers'
import { btQuickPanelActions } from '@reducers/BtQuickPanelReducer'
import { selectUserObject } from '@reducers/UserSelectors'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import randomEmoji from 'get-random-emoji'
import { useCallback, useEffect, useState } from 'react'
import { BsRecordCircle } from 'react-icons/bs'
import { DEFAULT_ACCENT_COLOR } from 'theme/colors'
import BoardsSelect from './boards-select/boards-select'
import classes from './quick-task-panel.module.scss'



const SelectWrap = styled(Box)(({ theme }) => ({
    margin: '0 5px',
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #d8e9ed',
    backgroundColor: '#fff',
    borderRadius: 8
}))

const Select = styled('select')(({ theme }) => ({
    width: 119,
    height: 32,
    padding: 0,
    border: '0px solid transparent',
    outline: 'none',
    borderRadius: 8,
    color: '#626262',
    appearance: 'auto'
}))

const PanelFooter = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 10,
    alignSelf: 'self-end'
}))




function QuickTask() {
    const dispatch = useAppDispatch()
    const boards: Board[] = useAppSelector(boardsSelectors.selectAll);
    const selectedData = useAppSelector((state) => state.BtQuickPanelReducer.selectedData)
    const isErrorInput = useAppSelector((state) => state.BtQuickPanelReducer.isErrorInput)
    const [selectedPerson, setSelectedPerson] = useState<BlueticksBoardUser | undefined>()
    const authUser = useAppSelector(selectUserObject);
    const [isFetchingGroups, setIsFetchingGroups] = useState<boolean>(false)
    const [isFetchingBoardUsers, setIsFetchingBoardUsers] = useState<boolean>(false)
    const boardUsersList = useAppSelector((state) => boardUserSelectByQuery(
        state.DBBoardUserReducer,
        { boardId: selectedData.boardId }
    ))

    const groups = useAppSelector((state) => taskGroupsSelectByQuery(state.DBTaskgroupsReducer, {
        boardId: selectedData.boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }]
    }))
    const quickLinks: QuickLaunch[] = useAppSelector((state) => quickLaunchSelectByQuery(state.DBQuickLaunchReducer, {
        authorId: authUser?._id ?? '',
        $or: [{ deleted: { $exists: false } }, { deleted: false }]
    }))




    const loadGroupsAndBoardUsers = useCallback(async () => {
        setSelectedPerson(undefined)
        setIsFetchingGroups(true)
        const response = await dispatch(DBTaskgroupsThunks.find({
            boardId: selectedData.boardId,
            deleted: false,
            $paginate: false
        }))
        if (response.payload) {
            setIsFetchingGroups(false)
        }
        setIsFetchingBoardUsers(true)
        const boardUsersRes = await dispatch(
            DBBoardUserThunks.find({
                boardId: selectedData.boardId,
                deleted: false,
                $paginate: false,
            })
        )
        if (boardUsersRes.payload) {
            setIsFetchingBoardUsers(false)
        }

    }, [dispatch, selectedData.boardId])



    useEffect(() => {
        loadGroupsAndBoardUsers()
    }, [dispatch, loadGroupsAndBoardUsers, selectedData.boardId])

    useEffect(() => {
        // run cleanups
        return () => {
            dispatch(btQuickPanelActions.setAlertPopper({
                type: '',
                message: '',
                popperTargetId: ''
            }))
            dispatch(btQuickPanelActions.setSelectedData({
                groupId: '',
                boardId: '',
                groupName: '',
                colorAccent: '',
                boardTitleIcon: '',
                firstName: '',
                lastName: ''
            }))
            dispatch(btQuickPanelActions.setIsErrorInput(false))
            dispatch(btQuickPanelActions.setNewTaskId(''))
            dispatch(btQuickPanelActions.setTargetComponent(""))
        }
    }, [dispatch])


    const emoji = randomEmoji()
    function handleSelectOnChange({ target }) {
        console.log(target.value)
        if (target.name === 'boardId') {
            const board: Board = boards.find((group) => group._id === target.value);
            dispatch(btQuickPanelActions.setSelectedData({
                ...selectedData,
                boardId: board._id,
                boardTitleIcon: board?.titleIcon ?? emoji

            }))

            // console.log(board.boardUserList)
        } else if (target.name === 'groupId') {
            const group: TasksGroup = groups.find((group) => group._id === target.value);
            dispatch(btQuickPanelActions.setSelectedData({
                ...selectedData,
                groupId: group._id,
                groupName: group.name,
                colorAccent: group?.colorAccent ?? DEFAULT_ACCENT_COLOR
            }))
        } else {
            const person = boardUsersList.find((_person) => _person._id === target.value);
            setSelectedPerson(person)
        }

    }

    //check if quicklinks alredy exists
    const isQuickLinkExists = (function () {
        let bool = false
        const filtered = quickLinks.filter((link) => link.boardId && selectedData.boardId &&
            link.groupId === selectedData.groupId && link.personId === selectedPerson?._id)
        if (filtered.length) {
            bool = true
        }
        return bool
    })()


    async function addNewQuickLink() {
        if (selectedData.boardId && selectedData.groupId) {
            if (isQuickLinkExists) return
            const res: any = await dispatch(DBQuickLaunchThunks.create({
                ...selectedData,
                authorId: authUser._id,
                personId: selectedPerson?._id ?? '',
                firstName: selectedPerson.firstName ?? '',
                lastName: selectedPerson.lastName ?? '',
                email: selectedPerson.email ?? '',
                personPhotoURL: selectedPerson?.profile?.profileImg
            }))
            dispatch(btQuickPanelActions.setNewQuickLinkId(res.payload?._id))
            dispatch(btQuickPanelActions.setTargetComponent(""))
        } else {
            dispatch(btQuickPanelActions.setIsErrorInput(true))
        }
    }

    return (
        <Box className={classes.Container}>
            <Box className={classes.Header}>
                <BoardsSelect
                    handleSelectOnChange={handleSelectOnChange}
                />

                <SelectWrap sx={{ borderColor: isErrorInput && !selectedData.groupId ? 'red' : '' }}>
                    <Select value={selectedData.groupId}
                        name="groupId"
                        onChange={handleSelectOnChange}

                    >
                        <option value='none'>Choose Group</option>
                        {!isFetchingGroups && selectedData.boardId && groups.length ? (
                            <>
                                {groups.map((group) => (
                                    <option key={group._id} style={{ padding: 10 }} value={group._id}>
                                        {group.name}
                                    </option>
                                ))}</>
                        ) : selectedData.boardId && !groups.length ? (
                            <option style={{ fontWeight: 600 }} value='none'>
                                {isFetchingGroups ? 'Loading groups...' : '--No groups--'}
                            </option>
                        ) : <></>}
                    </Select>
                </SelectWrap>



                <SelectWrap sx={{ borderColor: !selectedPerson?.profile?.profileImg && isErrorInput && !selectedData.boardId && !selectedData.groupId ? 'red' : '' }}>
                    {selectedPerson?.profile?.profileImg ? (
                        <PersonAvatar
                            email=""
                            firstName={selectedPerson.firstName ?? ''}
                            lastName={selectedPerson.lastName ?? ''}
                            profileImg={selectedPerson?.profile?.profileImg}
                            size={20}
                            textSize={13}

                        />
                    ) : (
                        <AccountCircleIcon sx={{ color: colors.grey[300] }} />
                    )}
                    <Select
                        name="person"
                        onChange={handleSelectOnChange}
                    >
                        <option value='none'>Choose Person</option>
                        {!isFetchingBoardUsers && selectedData.boardId && boardUsersList.length ? (<>
                            {boardUsersList.map((person, index) => (
                                <option key={index} value={person._id}>
                                    {`${person.profile?.firstName} ${person?.profile?.lastName}`}
                                </option>
                            ))}

                        </>) : selectedData.boardId && !boardUsersList.length ? (
                            <option style={{ fontWeight: 600 }} value='none'>
                                {isFetchingBoardUsers ? 'Loading users...' : '--No users--'}
                            </option>
                        ) : <></>}
                    </Select>
                </SelectWrap>

            </Box>

            <PanelFooter >
                <BTButton startIcon={<BsRecordCircle size={20} />}
                    sx={{
                        background: isQuickLinkExists ? `${colors.red[400]}` : 'linear-gradient(66.67deg, #259ae9 0%, #6dd1f1 100%)',
                        '&:hover': {
                            background: isQuickLinkExists ? `${colors.red[400]}` : 'linear-gradient(66.67deg, #259ae9 0%, #6dd1f1 100%)',
                        }
                    }}
                    onClick={addNewQuickLink}>
                    {isQuickLinkExists ? 'Quick link alredy exists' : 'Add to quick links'}
                </BTButton>
            </PanelFooter>
        </Box >
    )
}

export default QuickTask