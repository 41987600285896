import BTButton from '@components/bt-button/bt-button';
import EastIcon from '@mui/icons-material/East';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { CircularProgress, Divider, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { currencySymbolSelector, getIsVATIDValid, isEnabledSelector, selectPaymentBoxData, showLoaderSelector, tooltipListSelector } from '@reducers/PaymentFormSelectors';
import { modifyPlanName } from '@services/utils';
import { useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import { appConfig } from 'config/app.config';
import { namespace } from 'config/constants';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TwoTicks } from '../../../assets/two-ticks.svg';
import { ReactComponent as Rocket } from '../rocket.svg';
import classes from "./payment-pricing-box.module.scss";

export interface PaymentPricingBoxProps {
    makePayment: () => void;
}

export default function PaymentPricingBox({
    makePayment
}) {
    const { t } = useTranslation(namespace);

    const paymentIntent = useAppSelector(state => state.PaymentFormReducer.paymentIntent)
    const estimate = useAppSelector(state => state.PaymentFormReducer.estimate)
    const [paypalButtonReady, setPaypalButtonReady] = useState(false);


    const {
        basePrice,
        amount_due,
        totalCredit,
        yearlyDiscountAmount,
        yearlyDiscountPercentages,
        seatDiscountAmount,
        seatDiscountPercentages,
        seatCount
    } = useAppSelector((state: RootState) => selectPaymentBoxData(state));

    const calculateCartIsLoading = useAppSelector(state => state.PaymentFormReducer.calculateCartIsLoading)
    const currencySymbol = useAppSelector(currencySymbolSelector)
    const isEnabled = useAppSelector(isEnabledSelector);
    const tooltipList = useAppSelector(tooltipListSelector);
    const processingPayment = useAppSelector((state) => state.PaymentFormReducer.processingPayment);
    const showLoader = useAppSelector(showLoaderSelector);
    const planId = useAppSelector((state) => state.PaymentFormReducer.planId);
    const paymentMethod = useAppSelector((state) => state.PaymentFormReducer.paymentMethod ?? 'CreditCard');
    const isVATIDValid = useAppSelector(state => getIsVATIDValid(state))

    const extensionPricing = useMemo(() => {
        return t('planSelection.extensionPricing', { returnObjects: true });
    }, [t]);

    const taskPricing = useMemo(() => {
        return t('planSelection.taskPricing', { returnObjects: true });
    }, [t]);

    const planDescription: any = appConfig.pricingOptions === 'extension' ? extensionPricing : taskPricing;

    const [lineItem] = estimate?.invoice_estimate?.line_items ?? [];
    const planDescriptor = useMemo(() => planDescription.find(planDescriptor => planDescriptor.key === lineItem?.entity_id), [lineItem?.entity_id, planDescription])


    const canCheckoutOrUpdate = useMemo(() => {
        const isNotReady = planId ? !(estimate && isEnabled) : !isEnabled;

        console.log('canCheckoutOrUpdate', !isNotReady && !!paymentIntent)
        console.log(`planId: ${planId}, estimate: ${estimate}, isEnabled: ${isEnabled}`)
        console.log(`isNotReady: ${isNotReady}, !!paymentIntent: ${!!paymentIntent}`)

        return !isNotReady && !!paymentIntent;
    }, [estimate, isEnabled, paymentIntent, planId]);

    return <Stack
        sx={{ boxShadow: '0px 0px 32px rgba(48, 162, 234, 0.15)', borderRadius: '12px', position: 'relative' }} >
        <Stack
            spacing={1}
            sx={{
                padding: '5% 10%', background: 'var(--default-background)', zIndex: '5',
                borderRadius: '0 0 12px 12px'
            }}>
            <Box
                sx={{
                    background: 'var(--secondary-background-color)',
                    display: 'flex', justifyContent: 'center',
                    alignItems: 'center', height: '150px', position: 'absolute',
                    top: '-150px', right: 0, left: 0,
                    borderRadius: '12px 12px 0px 0px'
                }}>
                <div className={classes.rocket}>
                    <Rocket />
                </div>
            </Box>
            <h3 style={{
                fontSize: '18px',
                fontWeight: 700,
                color: 'var(--secondary-text-color)',
                margin: '15px 0',
                textAlign: "center"
            }}>
                {modifyPlanName(lineItem?.entity_id)}
            </h3>
            {planId &&
                <Stack sx={{ padding: '0 15px' }} spacing={2}>
                    {([
                        ...(planDescriptor?.benefits ?? []),
                        ...(planDescriptor?.common ?? [])
                    ]).map((benefit, index) => <span key={index} className={classes.featuresRecived}>
                        <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-start">
                            <TwoTicks style={{ marginRight: "5px", flexShrink: 0 }} />

                            <div
                                style={{ fontSize: "14px" }}
                                dangerouslySetInnerHTML={{ __html: benefit }}>
                            </div>
                        </Stack>
                    </span>)}

                    <Divider variant="middle" sx={{ margin: '20px 0' }} />
                    <Box sx={{ minHeight: 120 }} >
                        {estimate && (!calculateCartIsLoading) && <>
                            {
                                <Stack direction='row' justifyContent='space-between' sx={{ marginBottom: "8px" }}>
                                    <span className={classes.planText}>Price</span>
                                    <span className={classes.planText}>{currencySymbol}{basePrice}</span>
                                </Stack>
                            }

                            {
                                yearlyDiscountAmount > 0 &&
                                <Stack direction='row' justifyContent='space-between' sx={{ marginBottom: "8px" }}>
                                    <span className={classes.planText}>Yearly plan (Save {yearlyDiscountPercentages}%) </span>
                                    <span className={classes.planText}>{`-${currencySymbol}${yearlyDiscountAmount}`}</span>
                                </Stack>
                            }
                            {
                                seatDiscountAmount > 0 &&
                                <Stack direction='row' justifyContent='space-between' sx={{ marginBottom: "8px" }}>
                                    <span className={classes.planText}>
                                        {seatCount} members
                                        (Save {seatDiscountPercentages}%)
                                    </span>
                                    <span className={classes.planText}>{`-${currencySymbol}${seatDiscountAmount}`}</span>
                                </Stack>
                            }
                            {
                                estimate.invoice_estimate.credits_applied > 0 &&
                                <Stack direction='row' justifyContent='space-between' sx={{ marginBottom: "8px" }}>
                                    <span className={classes.planText}>Applied Credit</span>
                                    <span className={classes.planText}>
                                        {estimate.invoice_estimate.credits_applied && `-${currencySymbol}${estimate.invoice_estimate.credits_applied / 100}`}
                                    </span>
                                </Stack>
                            }
                            {estimate.invoice_estimate?.discounts?.map((discount, index) => (
                                <Stack direction='row' justifyContent='space-between'
                                    sx={{ marginBottom: "8px" }}>
                                    <span className={classes.planText}>
                                        Save {discount.discount_percentage}%
                                    </span>
                                    <span className={classes.planText}>-{currencySymbol}{discount.amount / 100}</span>
                                </Stack>
                            ))}
                            {estimate &&
                                <Stack direction='row' justifyContent='space-between'
                                    sx={{
                                        fontSize: "20px",
                                        marginTop: "10px",
                                    }}

                                >
                                    <span><b>Total</b></span>
                                    <span><b>{currencySymbol}{amount_due / 100}</b></span>
                                </Stack>
                            }
                            {estimate && (totalCredit > 0) &&
                                <Box sx={{
                                    fontSize: 10,
                                    mt: 1
                                }}>
                                    {`* Remaining ${currencySymbol}${totalCredit} will be credited to future payments.`}
                                </Box>
                            }
                        </>}
                    </Box>

                </Stack>
            }
            <Stack spacing={1} direction='row' alignItems='center' justifyContent='center'
                sx={{ fontWeight: 600, fontSize: '14px', color: "#00C875" }}>
                <GppGoodIcon />
                {planId ? 'Safe & secure payment' : 'Safe & secure update'}
            </Stack>
            <Tooltip title={tooltipList.map((tip, index) => <Box key={index}>{tip}</Box>)}
                disableHoverListener={tooltipList.length === 0}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    {paymentMethod === 'Paypal' && <div id='paypal-button'></div>}
                    {paymentMethod === 'Paypal' && (!paypalButtonReady) && <CircularProgress />}
                    {paymentMethod === 'CreditCard' && <BTButton
                        data-testid={processingPayment || showLoader ? 'cant-checkout' : 'credit-card-checkout-button'}
                        disabled={!canCheckoutOrUpdate}
                        loading={processingPayment || showLoader}
                        sx={{ width: 1, boxShadow: '2px 6px 24px rgba(37, 154, 233, 0.5)', padding: '15px 0' }}
                        onClick={() => {
                            makePayment()
                        }}
                        endIcon={<EastIcon />}>
                        {planId ? 'Checkout' : 'Update Payment Info'}
                    </BTButton>
                    }
                </Stack>
            </Tooltip>
        </Stack>
    </Stack >
}

