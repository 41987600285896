import { Box } from '@mui/system';
import { isExtensionContextOrWhatsappView, isInPopup } from "@services/detect-context";
import { useElementSize } from 'usehooks-ts';
import classes from "./form-patial.module.scss";
import { useMediaQuery } from '@mui/material';
import { theme } from 'theme';

type Props = {
    imagePath: string,
    children: any
}
export default function FormPatial({ imagePath, children }: Props) {
    const [squareRef, { width, height }] = useElementSize()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box className={classes.FlexContainer} ref={squareRef}>

            {!isExtensionContextOrWhatsappView() && !isMobile &&
                <Box
                    className={classes.col}
                    style={{
                        display: width > 900 ? "flex" : "none",
                    }}>
                    <img src={imagePath} alt="" />
                </Box>
            }

            <Box className={classes.col}
                style={{
                    padding: isInPopup() || isExtensionContextOrWhatsappView() || isMobile ? "40px" : "0",
                    height: isExtensionContextOrWhatsappView() ? "unset" : isMobile ? "calc(100vh - 80px)" : "100vh",
                    flexBasis: width > 900 ? "50%" : '100%',
                    alignItems: "center",
                    display: "flex",

                }}>
                {children}
            </Box>
        </Box >
    )
}