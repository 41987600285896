// GoogleLoginWrapper.tsx
import React from 'react';
import { useState } from "react";
import { useAppDispatch } from "@store/hooks";
import { googleLoginThunk } from "@reducers/UserReducer";
import { isExtensionContext } from "@services/detect-context";
import GoogleLoginHandler from './google-login-handler';
import { AnalyticsService } from '@services/analytics-service';

const GoogleLoginWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const [googleLoading, setGoogleLoading] = useState(false);
  const isExtension = isExtensionContext();
  const analyticsService: AnalyticsService = new AnalyticsService();

  const handleLoginSuccess = async (credential: string) => {
    setGoogleLoading(true);
    try {
     const res:any = await dispatch(googleLoginThunk({ accessToken: credential }));
      if (res.payload.data) {
        const {loginOrSignup} = res.payload.data
        analyticsService.event(loginOrSignup, {
          category: "access",
            action: loginOrSignup === "google_login_success" ? "google-login-success" : "google register new user",
            email: res.payload?.email
        });
     
      }
    } catch (error) {
      console.error("Error during Google login:", error);
    } finally {
      setGoogleLoading(false);
    }
  };

  const handleLoginError = () => {
    console.log('Login Failed');
  };

  return (
    <GoogleLoginHandler
      onLoginSuccess={handleLoginSuccess}
      onLoginError={handleLoginError}
      isExtension={isExtension}
      googleLoading={googleLoading}
    />
  );
};

export default GoogleLoginWrapper;
