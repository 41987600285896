import { Subscription } from "@models/subscription";
import { UserInterface } from "@models/user";
import {
  DBBoardsThunks,
  DBCampaignThunks,
  DBSessionThunks,
  DBSubscriptionThunks,
  DBUserMessageThunks,
  DBUserThunks,
  UserReducerActions,
} from "@reducers/DBServiceReducers";
import { MODAL, modalActions } from "@reducers/ModalReducer";
import { initializeNotifierConnectionThunk } from "@reducers/NotifierThunks";
import {
  clearStateThunk,
  setAuthenticating,
  userSlice,
} from "@reducers/UserReducer";
import { validateSubscriptionsThunk } from "@reducers/UserThunks";
import { getWorkspaceDataThunk } from "@reducers/WorkspaceThunks";
import {
  rebuildContactNodesThunk,
} from "@reducers/labels-reducer/labels-thunks";
import { loadLabelsAndContactLabelsThunk } from "@reducers/labels-reducer/labels-thunks/crud";
import { isExtensionContextOrWhatsappView } from "@services/detect-context";

export const authenticateOnQueryStarted = async (
  id,
  { dispatch, queryFulfilled }
) => {
  const result = await queryFulfilled;
  const user: UserInterface = result.data.user;
  if (window?.clarity) {
    window.clarity("identify", user.email);
  }



  dispatch(clearStateThunk());
  dispatch(
    UserReducerActions.upsertOne({
      ...result.data.user,
      impersonating: result.data?.authentication?.payload?.impersonating,
    })
  );
  document.querySelector("body").className = result.data.user.isDarkMode
    ? "dark"
    : "";
  dispatch(
    userSlice.actions.setUserDirection(
      result.data.user.direction !== undefined
        ? result.data.user.direction
        : "ltr"
    )
  );

  if (!window.location.href.includes("web.whatsapp.com")) {
    document.body.setAttribute(
      "dir",
      result.data.user.direction !== undefined
        ? result.data.user.direction
        : "ltr"
    );
  }

  // if extionsion context and domain is not whatsapp.com then dont initialize notifier
  if (
    !isExtensionContextOrWhatsappView() ||
    window.location.hostname.includes("whatsapp.com") ||
    (window.location.hostname.includes("localhost") && window.location.pathname === '/whatsapp-view')
  ) {
    dispatch(initializeNotifierConnectionThunk());
  }

  const emailConfirmationToken = new URL(
    (window as any).location.href
  ).searchParams.get("emailConfirm");

  if (
    emailConfirmationToken &&
    emailConfirmationToken === user?.emailConfirmationToken
  ) {
    dispatch(
      DBUserThunks.patch({
        entity: { _id: user._id, isEmailConfirmed: true, emailConfirmationToken: null },
      })
    );
    dispatch(modalActions.setComponentToRender(MODAL.EMAIL_CONFIRMATION_THANKS));


  }

  dispatch(
    DBUserMessageThunks.find({
      deleted: { $ne: true },
      status: "pending",
      $sort: {
        dueDate: 1,
      },
      $limit: -1,
      "contactList.0._id": { $exists: true },
    })
  );
  dispatch(
    DBCampaignThunks.find({
      deleted: { $ne: true },
      $sort: {
        dueDate: 1,
      },
      $limit: 20,
    })
  );

  // Essential for the app to work, must await before continuing
  const subscriptionListRes = await dispatch(DBSubscriptionThunks.find({}));
  const subscriptionList: Subscription[] = subscriptionListRes.payload;

  dispatch(validateSubscriptionsThunk(subscriptionList));

  await dispatch(
    getWorkspaceDataThunk({
      workspaceId: user?.currentWorkspace?.toString(),
    })
  );

  dispatch(DBSessionThunks.find({}));
  dispatch(setAuthenticating(false));

  if (isExtensionContextOrWhatsappView()) {
    dispatch(loadLabelsAndContactLabelsThunk())

    //loadWAStore()
    dispatch(rebuildContactNodesThunk());
    dispatch(DBBoardsThunks.find({ isWABoard: true }));
  }
};
