import { useAppDispatch, useAppSelector } from '@store/hooks';
import classes from './import-excel.module.scss';

import { setActiveTab, setIsImportModalOpen } from '@reducers/CampaignReducer';
import { createNewColumnsForImportExcelThunk, getBoardEntitiesForImportModalThunk, saveNewContactsFromWhatsappForExistingCampaignAudienceThunk, saveNewContactsFromWhatsappForExistingCampaignAudienceWithDataMappingThunk, saveNewContactsFromWhatsappForNewCampaignAudienceThunk } from '@reducers/CampaignThunks';
import { DBBoardsThunks, DBTaskgroupsThunks, DBTasksThunks } from '@reducers/DBServiceReducers';
import { importItemsActions } from '@reducers/ImportItemsReducer';
import { modalActions } from '@reducers/ModalReducer';
import { dynamicColumnList } from '@reducers/TaskThunks';
import { useImportExcelToBoardMutation } from '@reducers/backend-api/backend-api';
import { namespace } from 'config/constants';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ImportButton from './import-button';
import { generateNewAudienceName } from '@services/utils';

type Props = {
    selectedBoardId: any;
    importJsonData: any[];
    handleFinishImporting?: () => void;
    goNext: (isFromSheets?: boolean) => void;
}

export default function ImportNavButtons({
    selectedBoardId,
    importJsonData,
    handleFinishImporting,
    goNext,
}: Props) {
    const { t } = useTranslation(namespace);

    const dispatch = useAppDispatch()

    const step = useAppSelector((state) => state.ImportItemsReducer.step);
    const openedby = useAppSelector((state) => state.ModalReducer.openedby)
    const importType = useAppSelector((state) => state.ModalReducer.importType)
    const importFileName = useAppSelector((state) => state.ImportItemsReducer.importFileName);
    const selectedColumn = useAppSelector((state) => state.ImportItemsReducer.selectedColumn);
    const importDataMapping = useAppSelector((state) => state.ImportItemsReducer.importDataMapping);
    const sheetName = useAppSelector((state) => state.ImportItemsReducer.workSheetName);
    const file = useAppSelector((state) => state.ImportItemsReducer.file)
    const importedContactsArray = useAppSelector(state => state.WhatsAppReducer.importedContactsArray)
    const columnsList = useAppSelector((state) => dynamicColumnList(state, selectedBoardId))
    const newAudienceName = useAppSelector((state) => state.ImportItemsReducer.newAudienceName)




    const nextButtonString = () => {
        if ((openedby === 'campaign' && step === 2) || importType === 'work') {
            return t('importExcelToBlueticks.import')
        }
        else {
            return t('importExcelToBlueticks.next')
        }
    }


    const [importExcelToBoard] = useImportExcelToBoardMutation();

    function jsonToCsvFile(jsonObj) {
        let csv = '';

        if (!Array.isArray(jsonObj) || jsonObj.length === 0) {
            return null;
        }

        // Extract header (column names)
        let header = Object.keys(jsonObj[0]);
        csv += header.join(',') + '\n';

        // Extract content
        for (let row of jsonObj) {
            let csvRow = header.map(fieldName => {
                let value = row[fieldName];

                // Escape double quotes and surround with quotes
                if (typeof value === 'string') {
                    value = '"' + value.replace(/"/g, '""') + '"';
                }
                return value;
            });
            csv += csvRow.join(',') + '\n';
        }

        // Convert to File
        return new File([csv], 'data.csv', { type: 'text/csv', lastModified: new Date().getTime() });
    }



    const isMappingRequired = useCallback(() => {
        const isFirstNameColumnExist = (columnsList ?? []).filter(column => !column.deleted && column.type === 'text-cell'
            && column?.title?.toLowerCase().includes('first'))?.length > 0
        const isLastNameColumnExist = (columnsList ?? []).filter(column => !column.deleted && column.type === 'text-cell'
            && column?.title?.toLowerCase().includes('last'))?.length > 0

        return (!isFirstNameColumnExist || !isLastNameColumnExist) && importType === 'whatsapp' && selectedBoardId
    }, [columnsList, importType, selectedBoardId])



    const startImport = async () => {
        dispatch(importItemsActions.setImportInProgress(true));
        let boardId
        if (importType === 'whatsapp') {

            if (selectedBoardId) {
                boardId = await dispatch(saveNewContactsFromWhatsappForExistingCampaignAudienceThunk({
                    waContactList: importedContactsArray,
                })).unwrap()
            }
            else {
                boardId = await dispatch(saveNewContactsFromWhatsappForNewCampaignAudienceThunk({
                    displayName: newAudienceName,
                    waContactList: importedContactsArray,
                })).unwrap()
            }


        }
        if (importType === 'whatsappWithMapping') {

            boardId = await dispatch(saveNewContactsFromWhatsappForExistingCampaignAudienceWithDataMappingThunk({
                waContactList: importedContactsArray,
                importDataMapping,
            })).unwrap()

        }
        if (importType !== 'whatsapp' && importType !== 'whatsappWithMapping') {
            let _selectedColumn
            let _importDataMapping = {}
            if (!selectedBoardId) {
                const [_boardId, firstNameColumnId, lastNameColumnId, contactColumnId] = await dispatch(getBoardEntitiesForImportModalThunk({
                    displayName: newAudienceName,
                })).unwrap()

                boardId = _boardId;

                const mappingKeys = Object.keys(importDataMapping)
                for (let key of mappingKeys) {
                    let value = undefined
                    if (importDataMapping[key] === 'fakeFirstNameColumnId012') {
                        value = firstNameColumnId
                    }
                    if (importDataMapping[key] === 'fakeLastNameColumnId0123') {
                        value = lastNameColumnId
                    }
                    if (importDataMapping[key] === 'fakeContactColumnId01234') {
                        value = contactColumnId
                    }
                    if (value) {
                        _importDataMapping = {
                            ..._importDataMapping,
                            [key]: value,
                        }
                    }
                }

                await dispatch(importItemsActions.setImportDataMapping(_importDataMapping))
            }
            else {
                boardId = selectedBoardId
                let mappingKeys = Object.keys(importDataMapping) ?? []
                mappingKeys.forEach(async key => {
                    if (importDataMapping[key] !== 'taskName') {
                        _importDataMapping[key] = importDataMapping[key];
                    }
                    else {
                        _selectedColumn = key
                    }
                });
                await dispatch(importItemsActions.setImportDataMapping(_importDataMapping))
            }
            const newColumnsMapping = await (await dispatch(createNewColumnsForImportExcelThunk({ boardId })))?.payload
            const mappingKeys = Object.keys(importDataMapping);
            for (let key of mappingKeys) {
                if (importDataMapping[key].includes('NEWCOLUMN')) {
                    _importDataMapping = {
                        ..._importDataMapping,
                        [newColumnsMapping[importDataMapping[key]]?.excelColumn]: newColumnsMapping[importDataMapping[key]]?._id,
                    };
                }
            }
            await dispatch(importItemsActions.setImportDataMapping(_importDataMapping));

            const _file = () => {
                if (importType === 'csv') {
                    return file
                }
                if (importType === 'google') {
                    const googleSheetFile = jsonToCsvFile(importJsonData)
                    return googleSheetFile
                }
            }
            const __file = await _file()


            await importExcelToBoard({
                importDataMapping: _importDataMapping,
                selectedColumn: _selectedColumn ?? selectedColumn ?? '',
                boardId,
                sheetName,
                importFileName,
                file: __file,
            });
        }




        if (!selectedBoardId) {
            handleFinishImporting()
        }

        dispatch(modalActions.setComponentToRender(''))

        dispatch(modalActions.setImportType());
        dispatch(importItemsActions.resetImport())
        dispatch(importItemsActions.setNewAudienceName(null));
        if (openedby === 'campaign') {
            dispatch(setActiveTab('audiences'))
        }

        await dispatch(DBBoardsThunks.find({
            _id: boardId,
            deleted: false,
            type: 'audience',
            $paginate: false,
        }))
        await dispatch(DBTaskgroupsThunks.find({
            boardId,
            deleted: false,
            archived: { $ne: true },
            $paginate: false,
        }))
        await dispatch(DBTasksThunks.find({
            boardId,
            deleted: false,
            archived: { $ne: true },
            $paginate: false,
        }))
    };


    const isCSVOrSheetsNotReadyToImport = useMemo(() => {
        const mappedColumnIdsArray = Object.values(importDataMapping).filter(value => value && value !== '')
        const isSelectedColumnExists = !!selectedColumn && selectedColumn !== ''
        return (!(['whatsapp', 'whatsappWithMapping'].includes(importType)) && (!isSelectedColumnExists && mappedColumnIdsArray.length === 0))
    }, [importDataMapping, importType, selectedColumn])

    const isWhatsappNotReadyToImport = useMemo(() => {
        return (['whatsapp', 'whatsappWithMapping'].includes(importType)) && (!importedContactsArray || importedContactsArray.length === 0)
    }, [importType, importedContactsArray])

    const isShowNextButton = useMemo(() => {
        if (step !== 2) {
            if (importType === 'whatsapp' && isMappingRequired()) {
                return true
            }
            if (!(['whatsapp', 'whatsappWithMapping'].includes(importType))) { // csv or google sheets
                if (importType && importType !== '') { // only after selecting import method
                    if (importFileName && importFileName !== "") { // only after selecting a file
                        return true
                    }
                }
            }
        }
        return false
    }, [importFileName, importType, isMappingRequired, step])

    return (
        <div className={classes.nextBtnWrap} style={{ margin: importType === 'whatsapp' && 0, }}>
            {/* {importFileName && step !== 0 && */}
            {/* // ( */}
            {((step !== 2 && importType && importType !== '') ||
                (step === 2)) &&
                <button className={classes.backBtn} onClick={() => {
                    if (importType === 'whatsapp') {
                        dispatch(modalActions.setImportType())
                    }
                    if (importType === 'whatsappWithMapping') {
                        dispatch(modalActions.setImportType('whatsapp'))
                    }
                    if (step > 0) {
                        if (step === 2) {
                            if (openedby !== 'campaign') {
                                dispatch(importItemsActions.setSelectedColumn(''))
                            }
                            dispatch(importItemsActions.setStep(step - 2))
                        }
                        else {
                            dispatch(importItemsActions.setStep(step - 1))
                        }

                    }
                    if (step === 0 && importType) {
                        dispatch(modalActions.setImportType());
                        dispatch(importItemsActions.resetImport())
                    }
                    if (step === 0 && !importType) {
                        dispatch(importItemsActions.setStep(0))
                        dispatch(modalActions.setComponentToRender(''))
                        dispatch(setIsImportModalOpen(false))
                    }
                }}>{t('importExcelToBlueticks.back')}</button>
            }

            {isShowNextButton &&
                < button disabled={(step === 0 && importFileName === "" && importType !== 'whatsapp') || (step === 2 && selectedColumn === "")} className={classes.nextBtn} onClick={() => goNext()}>{nextButtonString()}</button>
            }
            {(step === 2 || (importType === 'whatsapp' && !isMappingRequired()) || importType === 'whatsappWithMapping') &&
                <ImportButton
                    startImport={() => startImport()}
                    isDisabled={(isWhatsappNotReadyToImport || isCSVOrSheetsNotReadyToImport)} />
            }
        </div>
    )
}