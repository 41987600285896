import { Box, Stack, Tooltip } from '@mui/material';
import { getCurrentBoardId, getFeatureListForTasksHeaderSelector } from '@reducers/BoardsSelectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useRef } from 'react';
import { useElementSize } from 'usehooks-ts';
import AddFeatureMenuButton from './add-feature-menu-button';
import TabsMoreButton from './tabs-more-button';
import TaskHeaderTab from './task-header-tab';
import { taskActions } from '@reducers/TasksReducer';
import { namespace } from 'config/constants';
import { useTranslation } from 'react-i18next';

export default function BoardTabList() {
    const { t } = useTranslation(namespace);
    // const tabParentRef = useRef<HTMLDivElement>(null)
    const [tabParentRef, { width }] = useElementSize()
    const tabsRef = useRef<Array<HTMLDivElement | null>>([]);
    const dispatch = useAppDispatch()
    const currentBoardId = useAppSelector(getCurrentBoardId)
    const fakeFeatureListForMeasuringTheWidthOfEachTab = useAppSelector((state) => getFeatureListForTasksHeaderSelector(state, -1, width, tabsRef));
    useEffect(() => {
        tabsRef.current = tabsRef.current.slice(0, fakeFeatureListForMeasuringTheWidthOfEachTab?.length);

    }, [fakeFeatureListForMeasuringTheWidthOfEachTab]);

    useEffect(() => {
        dispatch(taskActions.setCurrentBoardView({
            type: 'board',
            id: currentBoardId
        }))
    },[])

    const featureList = useAppSelector((state) => getFeatureListForTasksHeaderSelector(state, 0, width, tabsRef));
    return (

        <Stack direction='column'>
            <Stack id='board-tab-list' direction='row'  alignItems="center" sx={{ px: '40px', height: '69px' }} ref={tabParentRef}>

                {featureList.map((feature, index) => (
                    <Box
                        key={`real_tab_button_${index}`}>
                        <TaskHeaderTab
                            featureId={feature?._id.toString()}
                            featureName={feature?.name}
                            type={feature?.type}
                            index={index} />
                    </Box>
                ))}
                {featureList?.length !== fakeFeatureListForMeasuringTheWidthOfEachTab?.length &&
                    <TabsMoreButton
                        width={width}
                        tabsRef={tabsRef}
                    />
                }
                <Tooltip title={t('boardTabList.addView')} arrow placement="right">
                    <Box sx={{ marginLeft: '4px' }}>

                        <AddFeatureMenuButton />
                    </Box>
                </Tooltip>

            </Stack>
            <Stack direction='row'>
                {
                    fakeFeatureListForMeasuringTheWidthOfEachTab.map((feature, index) => (
                        <Box
                            ref={(el: HTMLDivElement) => tabsRef.current[index] = el}
                            key={`fake_tab_button_${index}`}
                            style={{
                                visibility: 'hidden',
                                pointerEvents: 'none',
                                height: 0,
                            }}>
                            <TaskHeaderTab
                                featureId={feature?._id.toString()}
                                featureName={feature?.name}
                                type={feature?.type}
                                index={index} />
                        </Box>
                    ))
                }
            </Stack>
        </Stack>
    )
}
