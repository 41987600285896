import ImportExcelToBlueticks from '@components/tasks-panel/import-tasks-from-excel/import-excel';
import { Modal } from '@mui/material';
import { bulkActions } from '@reducers/BulkActionsReducer';
import { setIsImportModalOpen } from '@reducers/CampaignReducer';
import { handleActiveBulkViewNavigationThunk } from '@reducers/CampaignThunks';
import { importItemsActions } from '@reducers/ImportItemsReducer';
import { modalActions } from '@reducers/ModalReducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import React from 'react';



export default function ImportCSVOrGoogleSheets() {
    const dispatch = useAppDispatch()

    const isImportModalOpen = useAppSelector(state => state.CampaignReducer.isImportModalOpen)

    const handleExitNewAudienceView = () => {
        dispatch(handleActiveBulkViewNavigationThunk({ navigate: "fromNewAudience" }))

    }

    const handleFinishImporting = () => {
        dispatch(setIsImportModalOpen(false))
        handleExitNewAudienceView()
    }


    return (
        <Modal
            open={isImportModalOpen}
            onClose={() => {
                dispatch(setIsImportModalOpen(false))
                dispatch(importItemsActions.setStep(0))
                dispatch(importItemsActions.resetImport())
                dispatch(modalActions.setImportType());
                dispatch(modalActions.setComponentToRender(''))
                dispatch(bulkActions.setBulkTasksMap({}))
                dispatch(modalActions.setOpenedby(''))
                dispatch(importItemsActions.setNewAudienceName(null));
            }}
            sx={{
                "maxWidth": "1000px",
                "minWidth": "800px",
                "margin": "auto",
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
                "zIndex": '999',
            }}
        >
            <ImportExcelToBlueticks handleFinishImporting={handleFinishImporting} />
        </Modal>
    )
}