import { appConfig } from "config/app.config";

export interface VznLyticsEvent {
  event_name?: string;
  label?: string;
  triggered_by?: "click" | "view" | "scroll";
  category?: string;
  sub_category?: string;
  value?: any;
  entity_id?: string;
  entity_display_name?: string;
  entity_type?: string;
  data?: any;
  email?: string;
}

export class VznLytics {
  static Endpoint = appConfig.VZNLYTICS_ENDPOINT;
  event(event: VznLyticsEvent) {
    try {
      const urlParams = new URLSearchParams(window.location.search).toString();
      fetch(`${VznLytics.Endpoint}/events?${urlParams}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(event)
      });

    } catch (e) {
      console.log(e);
    }
  }
}
