import { Dialog, Slide, Tooltip } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import React, { useEffect, useMemo, useRef } from "react";
import { BsFillClockFill } from "react-icons/bs";
import { User } from "../../models/user";
import { addScheduledMessageButtonActions } from "../../reducers/AddScheduledMessageButtonReducer";

import BTThemeProvider from "@components/bt-theme-provider";
import { UserMessage } from "@models/user-message";
import { isOverLimitOfScheduledMessages } from "@reducers/UserMessageReducer";
import { checkWaSocketsLimitThunk, handleClickScheduleMessageThunk } from "@reducers/WhatsappThunks";
import { Chat } from "../../services/whatsapp";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Popup } from "../popup/popup";
import AddScheduledMessage from "./add-scheduled-message";
import styles from "./add-scheduled-message-button.module.scss";

type AddScheduledMessageButtonProps = {
  isDisabled?: boolean;
  classes?: any;
};

export type AddScheduledMessageButtonState = {
  open: boolean;
  message?: UserMessage;
  user?: User | null | undefined;
  showErrorDialog: boolean;
  currentChat?: Chat;
  isEmojiPicker: boolean;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AddScheduledMessageButton = (props: AddScheduledMessageButtonProps) => {
  const dispatch = useAppDispatch()


  const analyticsService = useAnalyticsService();

  const addButtonRef = useRef<HTMLDivElement>(null);

  const _currentChat = useAppSelector((state) => state.WhatsAppReducer.currentChat);
  const errorState = useAppSelector((state) => state.WhatsAppReducer.errorState);
  const state: AddScheduledMessageButtonState = useAppSelector((state) => state.AddScheduledMessageButtonReducer);

  const currentChat = useMemo(() => ({
    ..._currentChat,
    participants: undefined,
  }), [_currentChat])

  useEffect(() => {
    if (state.open) {
      window.postMessage(
        {
          cmd: "clearChatInput",
          contact: currentChat?.id?._serialized,
        },
        "*"
      );
    }
  }, [currentChat?.id?._serialized, state.open]);

  useEffect(() => {
    dispatch(checkWaSocketsLimitThunk());
  }, []);

  //remove css conflict from Li' emoji className
  //by adding the width and the height properties
  const reomoveClassNameConflict = () => {
    const styleTag = document.createElement("style");
    styleTag.innerText = `
    .emoji-picker-react .emoji {
      position: relative;
      width:auto;
      height:auto;
    } `;

    document.head.appendChild(styleTag);
  };

  useEffect(() => {
    reomoveClassNameConflict();
  }, [])

  const isDisabled = useMemo(() => localStorage.getItem("schedulerReady") !== "true" || errorState, [errorState]);

  const handleClickOpen = async () => {
    dispatch(handleClickScheduleMessageThunk())
  };

  const handleClose = () => {
    analyticsService.event("close_schedule_dialog", {
      category: "Schedule",
      action: "close dialog",
    });

    dispatch(addScheduledMessageButtonActions.closeDialog());
  };

  const isOverLimit = useAppSelector(isOverLimitOfScheduledMessages);

  const limitReachedDialog = () => {
    return (
      <BTThemeProvider>
        <Dialog
          dir="ltr"
          TransitionComponent={Transition}
          open={state.open && isOverLimit}
          onClose={handleClose}
        >
          <Popup
            headerTitle={"Only 1 message at a time"}
            headerSubtitle={"Go premium to unlock unlimited messages"}
            onClose={handleClose}
          />
        </Dialog>
      </BTThemeProvider>
    );
  }



  const { classes } = props;
  const buttonClasses = [styles?.alarmButton, classes?.alarmButton];
  if (isDisabled) {
    buttonClasses.push(classes?.disabled);
  }

  return (
    <>
      <div ref={addButtonRef} className={classes?.addScheduleContainer}>
        <Tooltip title="Schedule a message" arrow placement="top">

          <button
            className={buttonClasses.join(" ")}
            onClick={handleClickOpen}
          >
            <BsFillClockFill />
          </button>
        </Tooltip>
        <BTThemeProvider>
          {limitReachedDialog()}
          <AddScheduledMessage />
        </BTThemeProvider>
      </div>
    </>
  );
}
