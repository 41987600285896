import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import BTButton from "@components/bt-button/bt-button";
import BTtextFieldWithLabel from "@components/bt-input-field-with-label/bt-input-field-with-label";
import PersonAvatar from '@components/tasks/person-avatar/person-avatar';
import { Alert, Snackbar } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DBUserThunks } from '@reducers/DBServiceReducers';
import { selectUserObject } from "@reducers/UserSelectors";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import replaceImg from "../../../assets/icons/replaceImg.png";
import { AccountPage } from "../../../components/account-page/account-page";
import { SideBarItem } from "../../../components/sidebar/sidebar";
import { User, UserInterface } from "../../../models/user";
import { handleUpload } from "../../../services/upload";
import classes from "./account-view.module.scss";
import LanguageSwitcher from '@components/language-switcher/language-switcher';
import { useTranslation } from 'react-i18next';
import { closePaymentDialogThunk } from '@reducers/PaymentFormThunks';
import { namespace } from 'config/constants';
import { MODAL, modalActions } from '@reducers/ModalReducer';
import ReusableModal from '@components/modals/reusable-modal';

export interface AccountViewProps {
  readOnly?: boolean;
}
export interface AccountViewState {
  user?: User | null | undefined;
}

export const AccountView = ({ readOnly }: AccountViewProps) => {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch()
  const user: UserInterface = useAppSelector(selectUserObject);


  const [isLoading, setIsLoading] = useState(false);

  const [inputValues, setInputValues] = useState({
    email: user?.email || '',
    phone: user?.phone || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
  });

  const [profileImgDataUrl, setProfileImgDataUrl] = useState<string | undefined>();
  const [profileImgType, setProfileImgType] = useState<string | undefined>();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState<any>();
  const [snackbarMessage, setSnackbarMessage] = useState<string>();

  const onFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    if (file && file.type.indexOf('image') > -1) {
      const reader = new FileReader();
      reader.onloadend = () => {
        inputFilesRef.current.value = ''
        setProfileImgDataUrl(reader.result as string);
        setProfileImgType(file.type);
      };
      reader.readAsDataURL(file);
    } else {
      setProfileImgDataUrl('');
      setProfileImgType('');
    }
  }, []);

  const saveUser = useCallback(async () => {
    console.log('saveUser');
    setIsLoading(true);

    const fieldsToPatch: Partial<User> = { ...inputValues };
    if (profileImgDataUrl) {
      let urlLink = '';
      try {
        const response = await handleUpload(profileImgDataUrl, profileImgType);
        urlLink = response?.image_url;
      } catch (error) {
        console.log('upload error', error);
      }
      console.log('uploaded', urlLink);
      if (urlLink) {
        fieldsToPatch.profileImg = urlLink;
      }
    }


    const res: any = await dispatch(DBUserThunks.patch({
      entity: {
        _id: user?._id,
        ...fieldsToPatch,
      }
    }))
    if (res?.error) {
      setSnackBarSeverity('error');
      setSnackbarMessage(t('accountView.snackbarErrorMessage'));
      setOpenSnackbar(true);
      setIsLoading(false);
    } else {
      setSnackbarMessage(t('accountView.snackbarSuccessMessage'));
      setSnackBarSeverity('success');
      setProfileImgDataUrl(undefined);
      setProfileImgType('');
      setOpenSnackbar(true);
    }
    setIsLoading(false);
  }, [dispatch, inputValues, profileImgDataUrl, profileImgType, user?._id, t]);

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  }, [inputValues]);

  const inputFilesRef = useRef<HTMLInputElement>(null);


  useEffect(() => {
    document.title = t('accountView.pageTitle');

    dispatch(closePaymentDialogThunk());

  }, []);

  const accountView = () => {
    return (
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <h4 className={classes.pageTitle}>{t('accountView.pageTitle')}</h4>
        </div>
        <div className={classes.accountSettingBody}>
          <div className={classes.inputsContainer}>
            <div className={classes.editableField}>
              <span className={classes.fieldName}>{t('accountView.yourImageLabel')}</span>
              <div style={{ position: "relative", maxWidth: "150px" }}>
                <PersonAvatar size={150} email={user?.email}
                  profileImg={profileImgDataUrl ?? user?.profileImg} lastName={user?.lastName}
                  firstName={user?.firstName} textSize={60} />

                <label htmlFor="profileImg">
                  <img
                    style={{ cursor: "pointer" }}
                    className={classes.replaceImg}
                    src={replaceImg}
                    width={32}
                    height={32}
                    alt=""
                  />
                </label>

                <input
                  disabled={isLoading || readOnly}
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={inputFilesRef}
                  type="file"
                  id="profileImg"
                  onChange={onFileChange}
                />
              </div>
            </div>

            <Stack spacing={2}>
              {/* <div>
                <LanguageSwitcher />          
              </div>    */}
              <BTtextFieldWithLabel
                // --background-default
                label={t('accountView.firstNameLabel')}
                placeholder={t('accountView.firstNamePlaceholder')}
                disabled={readOnly}
                onChange={handleChangeInput}
                name="firstName"
                value={inputValues.firstName}
                sx={{
                  backgroundColor: 'var(--background-default)',
                }}
              />
              <BTtextFieldWithLabel
                // --background-default
                label={t('accountView.lastNameLabel')}
                placeholder={t('accountView.lastNamePlaceholder')}
                disabled={readOnly}
                onChange={handleChangeInput}
                name="lastName"
                value={inputValues.lastName}
                sx={{
                  backgroundColor: 'var(--background-default)',
                }}
              />

              <BTtextFieldWithLabel
                label={t('accountView.emailLabel')}
                disabled
                placeholder={t('accountView.emailPlaceholder')}
                onChange={handleChangeInput}
                name="email"
                value={inputValues.email}
                sx={{
                  backgroundColor: 'var(--background-default)',
                  cursor: 'default'
                }}
              />
              <BTtextFieldWithLabel
                label={t('accountView.phoneLabel')}
                placeholder={t('accountView.phonePlaceholder')}
                disabled={readOnly}
                onChange={handleChangeInput}
                name="phone"
                value={inputValues.phone}
                sx={{
                  backgroundColor: 'var(--background-default)',
                }}
              />
              <div
                style={{ paddingBottom: '0', alignItems: "flex-end" }}
                className={classes.editableField}
              >
                {/* <LoadingButton
                sx={(theme) => (
                  {
                    "&:disabled": {
                      opacity: '0.5',
                      background: 'unset !important'
                    },
                    transition: '0.2s',
                    // width: width,
                    color: "#ffffff",
                    fontWeight: "700",
                    lineHeight: "21px",
                    borderRadius: "10px",
                    padding: "7.5px 16px",
                    background: "linear-gradient(66.67deg, #259ae9 0%, #6dd1f1 100%)",
                    whiteSpace: 'nowrap',
                    textTransform: 'capitalize',
                  }
                )} 
                onClick={saveUser}
                loadingPosition="center"
                loading={!isLoading}>
             {  !isLoading ??  'Save Changes'}
              </LoadingButton> */}
                {!readOnly &&
                  <BTButton onClick={saveUser} loading={isLoading} >
                    {t('accountView.saveChangesButton')}
                  </BTButton>
                }
              </div>
            </Stack>

            <Stack mt={10}>

              <Box sx={{cursor:"pointer",fontSize:"14px"}} onClick={() => dispatch(modalActions.setComponentToRender(MODAL.DELETE_ACCOUNT))}>Delete account</Box>
            </Stack>
            <ReusableModal />

            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={() => setOpenSnackbar(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Alert onClose={() => setOpenSnackbar(false)} severity={snackBarSeverity} variant="filled" sx={{
                "& .MuiButtonBase-root": {
                  color: '#fff;'
                }
              }}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
    );
  };

  return (
    <AccountPage
      page={accountView()}
      active={SideBarItem.AccountSettings}
      readOnly={readOnly}
    ></AccountPage>
  );
};

export default AccountView;
