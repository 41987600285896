import { Snackbar } from '@mui/material'
import { importItemsActions } from '@reducers/ImportItemsReducer'
import { useAppDispatch } from '@store/hooks'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import * as Xlsx from 'xlsx'
import classes from './dropzone.module.scss'

function Dropzone() {
    const [error, setError] = useState<string | undefined>(undefined);
    const dispatch = useAppDispatch()

    const acceptedExtensionList = [
        '.csv',
        '.xlsx',
        '.xls'
    ];

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0]

        const [extension] = file.name.split('.').reverse()

        if (!acceptedExtensionList.includes(`.${extension.toLowerCase()}`)) {
            setError(`Invalid file extension (${extension}). Only ${acceptedExtensionList.join(', ')} are allowed.`)
            return
        }

        console.log(acceptedFiles)
        const fileReader = new FileReader()
        fileReader.onload = () => {
            try {
                const data = fileReader.result
                const workBook = Xlsx.read(data, { sheets: 0 })
                const sheetName: string = workBook.SheetNames[0]
                const _jsonData: any = Xlsx.utils.sheet_to_json(workBook.Sheets[sheetName])
                console.log(_jsonData)
                dispatch(importItemsActions.setImportProps({
                    importFileName: file.name,
                    workSheetName: sheetName,
                    importJsonData: _jsonData,
                    file
                }))
            } catch (error) {
                console.error(error)
                setError("Something went wrong!")
            }
        }

        fileReader.readAsArrayBuffer(file)

    }, [dispatch])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(undefined);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    return (
        <div  {...getRootProps()} className={classes.dropzoneContainer} style={{ border: '2px dashed #ddd' }} >
            <input {...getInputProps()} accept="text/csv application/vnd.openxmlformats-officedocument.spreadsheetml.sheet application/vnd.ms-excel .csv .xlsx .xls" />
            <div className={classes.textWrap}>
                <p style={{fontSize: 20, textAlign: "center"}}>
                    Drop files here or&nbsp;
                    <span style={{ color: '#53bdeb' }}>
                        browse your device
                        </span>
                </p>
                <p style={{ textAlign: "center" }}>{acceptedExtensionList.join(', ')}</p>
            </div>
            <Snackbar open={error !== undefined} autoHideDuration={6000} onClose={handleClose} message={error} />
        </div>
    )
}

export default Dropzone
