import AvatarGenerator from '@components/tasks/extraData/avatar-generator'
import { Stack } from '@mui/system'
import { getSelectedBoardId } from '@reducers/BoardsSelectors'
import { DBLogsThunks, logsSelectByQuery } from '@reducers/DBServiceReducers'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useEffect } from 'react'
import CheckboxColumnLog from './task-logs-components/checkbox-column-log'
import DateColumnLog from './task-logs-components/date-column-log'
import DropdownColumnLog from './task-logs-components/dropdown-column-log'
import FilesColumnLog from './task-logs-components/files-column-column-log'
import InviteAcceptRemoveRestoreBoardUserLog from './task-logs-components/inviteAcceptRemoveRestoreBoardUserLog'
import LogCreation from './task-logs-components/log-creation'
import NewDeleteRestoreTaskLog from './task-logs-components/new-delete-task-log'
import NumberLinkTextPhoneColumnLog from './task-logs-components/number-link-text-phone-column-log'
import OptionStatusComplexityColumnLog from './task-logs-components/option-status-complexity-column-log'
import PersonColumnLog from './task-logs-components/person-column-log'
import RatingColumnLog from './task-logs-components/rating-column-log'
import RenameLog from './task-logs-components/rename-log'
import TimelineColumnLog from './task-logs-components/timeline-column-log'
import TaskDragToNewGroupLog from './task-logs-components/task-drag-to-new-group-log'

type Props = {

}

export default function ActivityLog({

}: Props) {
    const dispatch = useAppDispatch()
    const boardId = useAppSelector(state => getSelectedBoardId(state))



    useEffect(() => {
        console.log("get all board logs");
        dispatch(
            DBLogsThunks.find({
                boardId,
                deleted: false,
                $paginate: false,
                $sort: {
                    createdAt: -1
                },
            }))
    }, [])

    const boardLogs = useAppSelector((state) => logsSelectByQuery(state.DBLogReducer, { boardId }))
    const createBoard = boardLogs.find(log => (log?.logUpdateType as any) === 'new board')
    const _boardLogs = [createBoard, ...boardLogs.filter(log => log?._id !== createBoard?._id)]
        .sort((a, b) => {
            const aDate = new Date(a.createdAt)
            const bDate = new Date(b.createdAt)

            const aSeconds = Math.floor(aDate.getTime())
            const bSeconds = Math.floor(bDate.getTime())
            return bSeconds - aSeconds
        })
    //    ******** ALSO, ADD "CHANGE TASK'S GROUP" **********



    const TaskLogData = (log) => {
        if (
            [
                'task group change'
            ]
                .includes(log?.logUpdateType) === true) {
            return (<TaskDragToNewGroupLog logId={log._id} />)
        }
        if (
            [
                'new task', 'delete task', 'restore task',
                'new board', 'restore board', 'delete board',
                'new group', 'restore group', 'delete group',
                'new column', 'restore column', 'delete column'
            ]
                .includes(log?.logUpdateType) === true) {
            return (<NewDeleteRestoreTaskLog logId={log._id} />)
        }
        if (
            [
                'board invite', 'accept board user invite', 'remove board user', 'restore board user'
            ]
                .includes(log?.logUpdateType) === true) {
            return (<InviteAcceptRemoveRestoreBoardUserLog logId={log._id} />)
        }
        if (
            ['task name', 'board name', 'group name', 'column name']
                .includes(log?.logUpdateType) === true) {
            return (<RenameLog logId={log._id} />)
        }
        if (
            ['option-picker', 'status-option-picker', 'complexity-picker']
                .includes(log?.logUpdateType) === true) {
            return (<OptionStatusComplexityColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['person-picker']
                .includes(log?.logUpdateType) === true) {
            return (<PersonColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['datepicker']
                .includes(log?.logUpdateType) === true) {
            return (<DateColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['number', 'link', 'text-cell', 'phone-cell']
                .includes(log?.logUpdateType) === true) {
            return (<NumberLinkTextPhoneColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['files']
                .includes(log?.logUpdateType) === true) {
            return (<FilesColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['checkbox']
                .includes(log?.logUpdateType) === true) {
            return (<CheckboxColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['rating']
                .includes(log?.logUpdateType) === true) {
            return (<RatingColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['drop-down']
                .includes(log?.logUpdateType) === true) {
            return (<DropdownColumnLog logId={log._id} isBoardLog={true} />)
        }
        if (
            ['timeline-picker']
                .includes(log?.logUpdateType) === true) {
            return (<TimelineColumnLog logId={log._id} isBoardLog={true} />)
        }
    }

    const LogROw = (index) => {
        const log = _boardLogs[index];
        return (
            <Stack direction='row' key={`${index}_logs_${boardId}`} gap={1} sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                background: "white",
                padding: "0 24px 0 16px",
                minHeight: "80px",
                borderBottom: index === _boardLogs.length - 1 ? 'none' : '1px solid #DEEEF2',
            }}>
                <AvatarGenerator itemId={log?._id} size={32} isUser={true} />
                {TaskLogData(log)}
                <div style={{ marginLeft: 'auto' }}><LogCreation logId={log?._id} /></div>
            </Stack>
        )
    }

    return (
        <Stack
            sx={{
                borderBottom: '1px solid #DEEEF2',
                borderLeft: '1px solid #DEEEF2',
                borderRadius: "8px",
                overflow: "hidden",
            }}>

            {
                _boardLogs.map((log, index) => {
                    return (LogROw(index))
                })
            }
        </Stack>
    )
}


