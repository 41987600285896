import LabelToast from './label-toast'
import AddNewLabelModalModal from './modals/add-new-label'
import CustomSnoozeModal from './modals/custom-snooze'
import DeleteLabelModalModal from './modals/delete-label'
import EditLabelModalModal from './modals/edit-label'
import SortChatsToNewLabel from './modals/sort-chats-to-new-label'


const LabelsModals = () => (
    <>
        <LabelToast />
        <AddNewLabelModalModal />
        <SortChatsToNewLabel />
        <DeleteLabelModalModal />
        <EditLabelModalModal />
        <CustomSnoozeModal />
    </>
)


export default LabelsModals