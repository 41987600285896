import { taskActions } from '@reducers/TasksReducer';
import { useEffect } from 'react';
import { useFps } from 'react-fps';
import { useDispatch } from 'react-redux';

const FpsTracker = () => {
  const { avgFps } = useFps(10);
  const dispatch = useDispatch();

  //console.log('FpsTracker render');

  useEffect(() => {
    //console.log('FpsTracker -> avgFps', avgFps);
    const interval = setInterval(() => {
      dispatch(taskActions.setCurrentFPS(Number(avgFps)));
    }, 500);

    return () => clearInterval(interval);
  }, [avgFps, dispatch]);

  return (
    <>
      {/* <div>
      <p>Average FPS: {avgFps}</p>
    </div> */}
    </>

  );
};

export default FpsTracker;
