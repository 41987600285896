import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import axios from "axios";
import { WhatsappId } from "../services/whatsapp";
import { backendApi } from "./backend-api/backend-api";

export interface ProfilePicThumbEvent {
  id: WhatsappId;
  profilePicThumb: Picture;
}

export interface Picture {
  imgFull?: string;
  img?: string;
  id?: WhatsappId;
  profilePicUrl?: string;
}

export interface ProfilePicState {
  profilePicMap: Record<string, Picture>;
}

const initialState: ProfilePicState = {
  profilePicMap: {},
};

export interface GetProfilePicResponse {
  waId: string;
  profilePic: string;
}

export const getProfilePicThunk = createAsyncThunk<
  GetProfilePicResponse,
  string,
  { state: RootState }
>("getProfilePicThunk", async (whatsappId: string, thunkApi) => {
  const state = thunkApi.getState();

  let publicUrl;
  let uploadUrl;
  let profilePicThumb: Picture;
  if (!state.ProfilePicReducer.profilePicMap[whatsappId]) {
    thunkApi.dispatch(
      updateProfilePic({
        id: { _serialized: whatsappId },
        profilePicThumb: {},
      })
    );
    console.log(`getProfilePicThunk ${whatsappId} - started`);
    profilePicThumb = await getProfilePicThumb(whatsappId);
    console.log(`getProfilePicThunk ${whatsappId} - finished`);

    thunkApi.dispatch(
      updateProfilePic({
        id: { _serialized: whatsappId },
        profilePicThumb,
      })
    );

    if (profilePicThumb?.img) {
      const uploadUrlRes = await thunkApi.dispatch(backendApi.endpoints.getSignedUploadUrl.initiate(
        'image/jpeg'
      ));
      console.log(uploadUrlRes);
      if (!('error' in uploadUrlRes)) {
        const data = uploadUrlRes.data;
        uploadUrl = data.url;
        publicUrl = data.publicUrl;
      }
      if (uploadUrl) {
        const res = await fetch(profilePicThumb?.img);
        const blob = await res.blob();

        await axios({
          method: "PUT",
          url: uploadUrl,
          data: blob,
          headers: {
            "Content-Type": 'image/jpeg',
          },
        });
      }
    }
  } else {
    profilePicThumb = state.ProfilePicReducer.profilePicMap[whatsappId];
  }
  const response = {
    profilePic: publicUrl ?? profilePicThumb?.profilePicUrl ?? profilePicThumb?.img,
    waId: whatsappId,
  };
  console.log(`getProfilePicThunk finisehd for user: ${whatsappId}`);
  return response;
});

export const sliceName = createSlice({
  name: "ProfilePicReducer",
  initialState,
  reducers: {
    updateProfilePic: (state, action: PayloadAction<ProfilePicThumbEvent>) => {
      if (action.payload.id?._serialized && action.payload.profilePicThumb) {
        state.profilePicMap[action.payload.id?._serialized] =
          action.payload.profilePicThumb;
      }
    },
  },
});
export interface ProfilePicThumbResponse {
  profilePicThumb: Picture;
}


export function getProfilePicThumb(id: string): Promise<Picture> {
  return new Promise((resolve, reject) => {
    const channel = new MessageChannel();

    channel.port1.onmessage = (event: MessageEvent<ProfilePicThumbResponse>) => {
      const { profilePicThumb } = event.data;
      console.log(event.data);
      resolve(profilePicThumb);
    }

    window.postMessage(
      {
        cmd: "getProfilePicThumb",
        id,
      },
      "*",
      [channel.port2]
    );
  })
}
export const { updateProfilePic } = sliceName.actions;

export default sliceName.reducer;
