import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import {
  BoardUserReducerActions,
  TaskColumnOptionReducerActions,
  TaskColumnReducerActions,
  boardUserSelectByQuery,
  boardsSelectByQuery,
  myWorksSelectOneFieldByQuery,
  myWorksSelectOneObjectByQuery,
  taskColumnSelectByQuery,
  taskColumnSelectOneFieldByQuery,
  taskColumnSelectOneObjectByQuery,
  taskgroupsReducerActions,
  tasksReducerActions,
  tasksSelectByQuery,
} from "./DBServiceReducers";
import { ThunkAppDispatch } from "@store/hooks";
import { myWorkActions } from "./MyWorkReducer";
import { getMyWork } from "./backend-api/backend-api";
import { mergeBoardsOrDatesAndTasks } from "@components/tasks/helpers/elements-factory/elementsFactory";

const getMyWorkTaskList = createSelector(
  [
    (state: RootState) => state,
    (state: RootState) => state.MyWorkReducer?.myWorkPreferences,
  ],
  (state, myWorkPreferences) => {
    if (Object.keys(myWorkPreferences).length > 0) {
      const allTasks = [];

      const boardUsers = boardUserSelectByQuery(state.DBBoardUserReducer, {
        userId: { $in: myWorkPreferences?.people },
      });

      const boardUserIds = boardUsers.map((boardUser) =>
        boardUser._id?.toString()
      );

      const personPickerColumns = [];
      const myWorkBoards = getMyWorkBoards(state);

      myWorkBoards.forEach((board) => {
        const boardPersonPickerColumns = taskColumnSelectByQuery(
          state.DBTaskColumnReducer,
          {
            boardId: board._id,
            type: "person-picker",
          }
        );

        if (boardPersonPickerColumns && boardPersonPickerColumns?.length) {
          personPickerColumns.push(...boardPersonPickerColumns);
        }
      });

      personPickerColumns.forEach((column) => {
        const tasks = tasksSelectByQuery(state.DBTasksReducer, {
          [`customData.${column._id}.value.id`]: {
            $in: boardUserIds,
          },
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
        });

        allTasks.push(...tasks);
      });

      const updatedAllTasks = allTasks.map((task) => {
        const datePickerColumnId = taskColumnSelectOneFieldByQuery(
          state.DBTaskColumnReducer,
          {
            boardId: task.boardId,
            type: "datepicker",
          },
          "_id",
          { order: 1 }
        );

        return { ...task, datePickerColumnId };
      });

      return updatedAllTasks.filter(
        (task) =>
          !state.MyWorkReducer?.myWorkPreferences.hiddenBoards?.includes(
            task.boardId
          )
      );
    } else {
      return [];
    }
  }
);

export const getMyWorkBoards = createSelector(
  [(state) => state, (state) => state.MyWorkReducer?.myWorkBoardsIds],
  (state, boardsIds) => {
    return boardsSelectByQuery(state.DBBoardsReducer, {
      _id: { $in: boardsIds },
    });
  }
);

//write a selector that get myworkprefernces currentview by id

export const getMyWorkPreferencesCurrentView = createSelector(
  [
    (state) => state,
  ],
  (state) => {
    const myWorkPreferencesId = state.MyWorkReducer.myWorkPreferences._id;
    return myWorksSelectOneFieldByQuery(
      state.DBMyWorksReducer,
      {
        _id: myWorkPreferencesId,
      },
      "currentView"
    );
  }
);

export const fetchMyWorkThunk = createAsyncThunk(
  "myWork/fetchWork",
  async (_, thunkAPI) => {
    const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;
    dispatch(myWorkActions.setMyWorkLoading(true));

    let res = await dispatch(getMyWork.initiate({})).unwrap();

    let tasks = [...res.tasks, ...res.subTasks, ...res.parentTasks];
    dispatch(tasksReducerActions.addMany(tasks));

    dispatch(
      tasksReducerActions.updateMany(
        tasks.map((task) => ({
          id: task._id,
          changes: task,
        }))
      )
    );

    dispatch(TaskColumnReducerActions.addMany(res.columns));
    dispatch(
      TaskColumnReducerActions.updateMany(
        res.columns.map((column) => ({
          id: column._id,
          changes: column,
        }))
      )
    );

    dispatch(BoardUserReducerActions.addMany(res.boardUsers));
    dispatch(
      BoardUserReducerActions.updateMany(
        res.boardUsers.map((boardUser) => ({
          id: boardUser._id,
          changes: boardUser,
        }))
      )
    );

    dispatch(taskgroupsReducerActions.addMany(res.groups));
    dispatch(
      taskgroupsReducerActions.updateMany(
        res.groups.map((group) => ({
          id: group._id,
          changes: group,
        }))
      )
    );
    dispatch(TaskColumnOptionReducerActions.addMany(res.taskColumnOptions));
    dispatch(
      TaskColumnOptionReducerActions.updateMany(
        res.taskColumnOptions.map((columnOption) => ({
          id: columnOption._id,
          changes: columnOption,
        }))
      )
    );
    // dispatch(myWorkActions.setMyWorkTasks(visibleTasks));
    dispatch(myWorkActions.setMyWorkBoardsIds(res.boardsIds));

    dispatch(myWorkActions.setMyWorkPrefernces(res.myWorkPreferences));

    dispatch(myWorkActions.setMyWorkLoading(false));
    dispatch(
      myWorkActions.setMyWorkViewMode(res.myWorkPreferences.currentView)
    );

    return res;
  }
);

export const selectMyWorkElementList = createSelector(
  (state: RootState) => state,
  (state: RootState) => state?.MyWorkReducer?.myWorkPreferences,
  (state: RootState) => getMyWorkBoards(state),
  (state: RootState) => state?.MyWorkReducer?.myWorkViewMode,
  (state: RootState) => state?.MyWorkReducer?.myWorkTasksFilter,
  (state, myWorkPreferences, boards, viewMode, tasksFilterText) => {
    let tasks = getMyWorkTaskList(state);
    let elementsList = [];
    let hideEmptyGroups = false;
    if (tasksFilterText) {
      tasks = tasks?.filter((task) => tasksFilterText?.test(task.text));
      hideEmptyGroups = true;
    }

    const filteredBoards = boards?.filter(
      (board) => !myWorkPreferences.hiddenBoards.includes(board._id)
    );

    elementsList = mergeBoardsOrDatesAndTasks(
      filteredBoards,
      tasks,
      viewMode,
      hideEmptyGroups,
      myWorkPreferences
    );

    return elementsList;
  }
);

export const getFirstColumnByBoardIdAndType = createSelector(
  [
    (state, boardId, type) => state,
    (state, boardId, type) => boardId,
    (state, boardId, type) => type,
  ],
  (state, boardId, type) => {
    const firstColumn = taskColumnSelectOneObjectByQuery(
      state.DBTaskColumnReducer,
      {
        boardId: boardId,
        type: type,
      },
      { order: -1 }
    );

    return firstColumn;
  }
);
