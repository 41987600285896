

const WhatsappFooter = () => {

  return (
    <div>
      <footer >
        write a message...
        <div contentEditable={true} suppressContentEditableWarning={true} style={{ border: "1px solid gray" }} >
          <p style={{
            padding: 0,
            margin: 0,
            height: "20px",
          }}>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default WhatsappFooter;
