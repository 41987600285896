import { Menu, MenuItem, TextField } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { automation } from "@reducers/AutomationReducer";
import { selectNumberColumns } from "@reducers/AutomationsSelectors";
import { getSelectedBoardId } from "@reducers/BoardsSelectors";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import AddAndRemove from '../add-and-remove';
import PickerColumn from "../dropdowns/column-dropdown";
import { StyledTextField, errorStyle, setCurrentCondition,buttonStyle } from "../automations-helper";


import { styled } from "@mui/system";
import AutomationButton from "../automation-button";


const NumberMeetsCondition = ({ index }) => {
    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [columnName, setColumnName] = useState<string>('number');
    const [optionName, setMathOption] = useState<string>('greater than');
    const [amount, setAmount] = useState<string>('some amount');
    const [dropdown, setDropdown] = useState<string>('');

    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const filteredColumns = useAppSelector((state) => selectNumberColumns(state, boardId));
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, dropdownOption) => {
        setAnchorEl(event.currentTarget);
        setDropdown(dropdownOption);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleColumnChange = (id, name) => {
        dispatch(automation.setConditions(setCurrentCondition(conditions, index, { conditionType: conditions[index].conditionType, columnId: id })));
        setColumnName(name);
        handleClose();

    };

    const clickedOption = (option) => {
        dispatch(automation.setConditions(setCurrentCondition(conditions, index, { conditionType: conditions[index].conditionType, mathOption: option})));
        setMathOption(option)
        handleClose();
    };

    const handleNumberChange = ({ target }: any) => {
        dispatch(automation.setConditions(setCurrentCondition(conditions, index, { conditionType: conditions[index].conditionType,  numberAmount: Number(target.value?.replaceAll(',', ''))})));
    }


    
    useEffect(() => {
        if (conditions) {
            const selectedColumnId = conditions[index].columnId;
            const column = filteredColumns.find(item => item.id === selectedColumnId);
            setColumnName(column?.name ?? "number");
            setMathOption(conditions[index]?.mathOption?.toString() ?? "greater than");
            setAmount(conditions[index]?.numberAmount?.toString() ?? "")
            dispatch(automation.fieldValidityChanged({ component: 'NumberMeetsCondition', index, field: 'columnId', isValid: !!selectedColumnId }));
            dispatch(automation.fieldValidityChanged({ component: 'NumberMeetsCondition', index, field: 'mathOption', isValid: !!conditions[index]?.mathOption }));
            dispatch(automation.fieldValidityChanged({ component: 'NumberMeetsCondition', index, field: 'numberAmount', isValid: !!conditions[index]?.numberAmount }));
        }
    }, [conditions, dispatch, filteredColumns, index]);

    return (
        <Stack>
            <Stack key={index} sx={{ fontSize: "24px" }} direction="row" alignItems={"flex-start"} justifyContent={"space-between"}>
                <Box>
                    and only if
                    <AutomationButton
                        componentName='NumberMeetsCondition'
                        index={index}
                        option='columnId'
                        label={columnName}
                        handleClick={(e) => handleClick(e, 'pickerColumn')}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    /> is
                    <AutomationButton
                        componentName='NumberMeetsCondition'
                        index={index}
                        option='mathOption'
                        label={optionName}
                        handleClick={(e) => handleClick(e, 'columnOptions')}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    />
                    <NumericFormat
                        value={amount ?? ""}
                        onBlur={handleNumberChange}
                        allowNegative
                        decimalSeparator="."
                        allowLeadingZeros
                        customInput={StyledTextField}
                        thousandSeparator=","
                        label="Some amount"
                        variant="standard"
                        sx={{
                            marginTop: '8px',
                            ...buttonStyle,
                            ...(attemptedSubmission && validationStates[`NumberMeetsCondition_${index}`]?.numberAmount === false ? errorStyle : {}),
                        }}
                    />
                </Box>
                <AddAndRemove elements={conditions} index={index} type={"conditions"} componentName={"NumberMeetsCondition"} />
            </Stack>

            {dropdown === 'pickerColumn' && (
                <PickerColumn
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    filteredColumns={filteredColumns}
                    handleColumnChange={handleColumnChange}
                />)}

            {dropdown === 'columnOptions' && (
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => clickedOption("Greater than")}>Greater than</MenuItem>
                    <MenuItem onClick={() => clickedOption("Less than")}>Less than</MenuItem>
                    <MenuItem onClick={() => clickedOption("Equal to")}>Equal to</MenuItem>
                </Menu>)}

        </Stack>


    )
}
export default NumberMeetsCondition