import { ExtraDataItem } from '@models/ExtraDataItem'
import { ClickAwayListener, IconButton, Menu, Tooltip } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { DBTasksExtraDataThunks, tasksSelectors } from '@reducers/DBServiceReducers'
import { extraDataActions, setEditModeByExtraDataId, setIsNewSubItemDialog } from '@reducers/ExtraDataReducer'
import { getDisplayNameFromFirstLastAndEmail } from '@services/utils'
import { useAppSelector } from '@store/hooks'
import moment from 'moment'
import { useRef, useState } from 'react'
import { BiPin } from 'react-icons/bi'
import { BsThreeDots } from 'react-icons/bs'
import { useAppDispatch } from '@store/hooks';
import PersonAvatar from '../person-avatar/person-avatar'
import ExtraDataMenu from '../picker-dialog/pickers/extraDataMenu'
import RenderUpdate from './editor/RenderUpdate'
import SlateEditor from './editor/SlateEditor'
import classes from "./editor/thread.module.scss";
import { ReplyOutlined, ThumbUpOutlined, VisibilityOutlined } from '@mui/icons-material'
import { selectUserId } from '@reducers/UserSelectors'
import MessageThread from './MessageThread'
import ExtraDataCreatedAtTag from './extra-data-created-at-tag'
import SeenByTooltipComponent from './seen-by-tooltip-component'
import { CreateExtraDataAndEntitySuscribersThunk } from '@reducers/ExtraDataThunks'
import { userDisplayName } from '@models/user'

type Props = {
    item: ExtraDataItem;
}

export default function ExtraDataItemComponent({
    item
}: Props) {
    const dispatch = useAppDispatch()
    const userId = useAppSelector(selectUserId)
    const editModeByExtraDataId = useAppSelector(
        (state) => state.ExtraDataReducer.editModeByExtraDataId
    );
    const activeMenuId = useAppSelector((state) => state.ExtraDataReducer.activeMenuId)
    const displayExtraDataOfTaskById = useAppSelector(
        (state) => state.ExtraDataReducer.displayExtraDataOfTaskById)

    const task = useAppSelector((state) =>
        tasksSelectors.selectById(state, displayExtraDataOfTaskById)
    );

    const isNewSubItemDialog = useAppSelector((state) => state.ExtraDataReducer.isNewSubItemDialog)

    const menuRef = useRef(null);


    // const author = {
    //     displayName: getDisplayNameFromFirstLastAndEmail(item.ownerObj?.firstName, item.ownerObj?.lastName, item.ownerObj?.email),
    //     waId: "xxxx",
    // };

    const updateConversation = (editorValue, isDraft) => {
        if (isDraft) return;
        const _extraDataItem: ExtraDataItem = {
            _id: item._id,
            extraData: editorValue.editorValue,
        };

        dispatch(DBTasksExtraDataThunks.patch({
            entity: _extraDataItem
        }));
        dispatch(setEditModeByExtraDataId(""))
    }

    const cancelUpdate = () => {
        dispatch(setEditModeByExtraDataId(""))
    }

    const closeMenu = () => {
        dispatch(extraDataActions.setActiveMenuId(""))
    }

    const likeExtraDataItem = () => {
        let newIsLiked = true
        if (item?.isLiked?.[userId] === true) {
            newIsLiked = false
        }
        dispatch(DBTasksExtraDataThunks.patch({
            entity: {
                ...item,
                isLiked: {
                    ...item?.isLiked,
                    [userId]: newIsLiked,
                }
            }
        }))
    }

    const likesCounter = () => {
        let numberOfLikes = 0;

        for (const key in item?.isLiked ?? {}) {
            if (item?.isLiked[key] === true) {
                numberOfLikes++;
            }
        }

        return (
            <>
                {numberOfLikes > 0 &&
                    <Stack direction='row'>
                        <IconButton>
                            <ThumbUpOutlined />
                        </IconButton>
                        <span style={{ lineHeight: '37px', }}>{numberOfLikes}</span>
                    </Stack>
                }
            </>
        )
    }

    const seenCounter = () => {


        return (
            <Tooltip title={<SeenByTooltipComponent seenByIdArray={item?.seenByIdArray ?? []} />}>
                <Stack direction='row'>
                    <VisibilityOutlined />
                    <span>&nbsp; {(item?.seenByIdArray ?? []).length}</span>
                    <span>&nbsp; seen</span>
                </Stack>
            </Tooltip>
        )
    }

    function createNewSubExtraData(editorValue) {

        dispatch(CreateExtraDataAndEntitySuscribersThunk({
            editorValue,
            taskId: displayExtraDataOfTaskById,
        }))

        dispatch(setIsNewSubItemDialog(''));

    }

    return (
        <div
            style={{
                borderTopRightRadius: item.isPinedToTop ? 0 : "15px",
                marginBottom: "20px",
                backgroundColor: 'var(--thread-bg-color)',
                boxShadow: "0 2px 7px -2px rgb(0 0 0 / 15%)",
                fontFamily: "Inter",
                borderRadius: "14px",
                position: "relative",
                background: "var(--thread-bg-color)"

            }}
        >
            {item.isPinedToTop && (
                <div className={classes.pinned_badge}>

                    <BiPin />

                </div>
            )}

            <Box className={classes.thread_header}>
                <div className={classes.author_profile}>
                    <Box sx={{ marginInlineEnd: 1 }}>
                        <PersonAvatar
                            size={24}
                            email={item.profile?.email}
                            profileImg={item.profile?.profileImg}
                            lastName={item.profile?.lastName}
                            firstName={item.profile?.firstName}
                            toolTip={true}
                            isName={false}

                        />
                    </Box>
                    <Stack spacing={0.2} sx={{ fontFamily: "Inter" }}>
                        <div style={{ color: "var(--primary)", fontSize: '12px', fontWeight: "600" }} >
                            {userDisplayName(item.profile)}
                        </div>

                        <div style={{ color: "var(--primary)", fontSize: '11px', opacity: 0.3 }} >
                            {item?.updatedAt ? moment(item.updatedAt).format("DD/MM/yyyy") : moment(new Date()).format("DD/MM/yyyy")}
                        </div>

                    </Stack>
                    {/* <p className={classes.active_status}></p> */}
                </div>
                <Stack direction="row" spacing={1} className={classes.aside_right} >
                    <ExtraDataCreatedAtTag createdAt={item?.createdAt} />
                    <button
                        ref={menuRef}
                        id={`extraData_${item._id}`} key={`extraData_${item._id}`}
                        className={classes.drop_down_btn}
                        onClick={() => {
                            if (activeMenuId !== item._id) {
                                dispatch(extraDataActions.setActiveMenuId(item._id))
                            } else {
                                dispatch(extraDataActions.setActiveMenuId(""))
                            }
                        }}
                    >
                        <BsThreeDots />
                    </button>
                </Stack>
                <Menu
                    id="basic-menu"
                    anchorEl={menuRef.current}
                    open={activeMenuId === item._id}
                    onClose={closeMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.32))',
                            mt: 1,
                            "& .MuiList-root": {
                                padding: 0
                            },
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 40,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },

                        },

                    }}
                    sx={{ padding: 0 }}
                >
                    <ExtraDataMenu extraDataId={item._id} />
                </Menu>
            </Box>

            {editModeByExtraDataId === item._id ? (
                <SlateEditor value={item.extraData} onValueUpdate={updateConversation} addBorder={false} onCancel={cancelUpdate} />
            ) : (
                <Box className={classes.thread_main_component} sx={{ fontFamily: "'Inter', 'Assistant'"}}>
                    <RenderUpdate update={item.extraData} />
                    <Stack direction='row' sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        height: '40px',
                        
                    }}>
                        {/* <div>{likesCounter()}</div> */}
                        {/* <div>{seenCounter()}</div> */}
                    </Stack>
                    {isNewSubItemDialog === item?._id &&
                        <SlateEditor
                            value={null}
                            onValueUpdate={createNewSubExtraData}
                            addBorder={true}
                            parentItemId={item?._id} />
                    }
                    {/* <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between', marginTop: '15px' }}>
                        <IconButton onClick={() => { likeExtraDataItem() }}
                            sx={{
                                width: '49%',
                                boxSizing: 'border-box',
                                border: '1px solid var(--border-color)',
                                borderRadius: '8px',
                                backgroundColor: item?.isLiked?.[userId] ? '#e8f9fd' : 'white',
                            }}>
                            <ThumbUpOutlined />
                        </IconButton>
                        <IconButton onClick={() => { dispatch(setIsNewSubItemDialog(item?._id)) }}
                            sx={{
                                width: '49%',
                                boxSizing: 'border-box',
                                border: '1px solid var(--border-color)',
                                borderRadius: '8px'
                            }}>
                            <ReplyOutlined />
                        </IconButton>
                    </Stack> */}
                    <MessageThread parentItemId={item?._id} />
                </Box>
            )}
        </div>
    )
}