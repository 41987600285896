import CloseIcon from '@mui/icons-material/Close';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { DBNewTabFocusThunks } from '@reducers/DBServiceReducers';
import { selectNewTabFocusByOwnerId, toggleTimerThunk } from '@reducers/NewTabFocusThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { CSSProperties, useEffect, useState } from 'react';
import { Timer } from './focus-timer';
import { Stopwatch } from './focus-stopwatch';

export function ClockUI({
  days,
  hours,
  minutes,
  seconds,
  isRunning,
  onStart,
  onPause,
  onReset,
  onTimeEdit,
}) {
  const formatTime = time => String(time).padStart(2, '0');

  const [editableDays, setEditableDays] = useState(formatTime(days));
  const [editableHours, setEditableHours] = useState(formatTime(hours));
  const [editableMinutes, setEditableMinutes] = useState(formatTime(minutes));
  const [editableSeconds, setEditableSeconds] = useState(formatTime(seconds));

  const [isDaysInputFocused, setIsDaysInputFocused] = useState(false);
  const [isHoursInputFocused, setIsHoursInputFocused] = useState(false);
  const [isMinutesInputFocused, setIsMinutesInputFocused] = useState(false);
  const [isSecondsInputFocused, setIsSecondsInputFocused] = useState(false);

  const editableStyle:CSSProperties = {
    border: "2px dashed transparent",
    outline: "none",
    borderRadius: "4px",
    background: "transparent",
    color: "#fff",
    width: "40px",
    textAlign: "center",
    fontSize: "32px",
    padding: 0,
    transition: "border 0.3s",
  };

  const focusedStyle = {
    border: "2px dashed white",
  };

  useEffect(() => {
    setEditableDays(formatTime(days));
    setEditableHours(formatTime(hours));
    setEditableMinutes(formatTime(minutes));
    setEditableSeconds(formatTime(seconds));
  }, [days, hours, minutes, seconds]);

  const handleTimeChange = (value, setTime, timeUnit) => {
    const numericValue = parseInt(value, 10);

    if (!isNaN(numericValue) && numericValue >= 0) {
      const formattedValue = formatTime(numericValue);
      setTime(formattedValue);
      onTimeEdit({
        days: timeUnit === "days" ? formattedValue : editableDays,
        hours: timeUnit === "hours" ? formattedValue : editableHours,
        minutes: timeUnit === "minutes" ? formattedValue : editableMinutes,
        seconds: timeUnit === "seconds" ? formattedValue : editableSeconds,
      });
    }
  };

  const handleInputChange = (e, setTime, timeUnit) => {
    let value = e.target.value.slice(-2); // Keeps the input to 2 characters
    handleTimeChange(value, setTime, timeUnit);
  };

  const handleInputClick = (e) => {
    e.target.select();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        fontSize: "30px", margin: "10px 0",
      }}
    >
      {isRunning ? (
        <Tooltip title="Pause" arrow>
          <IconButton className="hover-icon"
            sx={{
              color: "#fff", background: "rgba(232, 249, 253, 0.2)",
              opacity: 0,
              visibility: 'hidden'
            }}
            onClick={onPause}
          >
            <PauseIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Start" arrow>
          <IconButton className="hover-icon"
            sx={{
              color: "#fff", background: "rgba(232, 249, 253, 0.2)", opacity: 0,
              visibility: 'hidden'
            }}
            onClick={onStart}
          >
            <PlayArrowIcon />
          </IconButton>
        </Tooltip>
      )}
      <Stack spacing={1}
        direction={"row"}
        justifyContent={"center"}
        style={{
          color: "#fff",
          background: "rgba(232, 249, 253, 0.2)",
          padding: "10px",
          borderRadius: "8px",
          minWidth: "250px",
        }}
      >
        <Tooltip title="Days" arrow>
          <input
            type="text"
            value={editableDays}
            style={{
              ...editableStyle,
              ...(isDaysInputFocused ? focusedStyle : {}),
            }}
            onFocus={() => setIsDaysInputFocused(true)}
            onBlur={() => setIsDaysInputFocused(false)}
            onChange={(e) => handleInputChange(e, setEditableDays, "days")}
            onClick={handleInputClick}
          />
        </Tooltip>
        <div>:</div>
        <Tooltip title="Hours" arrow>
          <input
            type="text"
            value={editableHours}
            style={{
              ...editableStyle,
              ...(isHoursInputFocused ? focusedStyle : {}),
            }}
            onFocus={() => setIsHoursInputFocused(true)}
            onBlur={() => setIsHoursInputFocused(false)}
            onChange={(e) => handleInputChange(e, setEditableHours, "hours")}
            onClick={handleInputClick}
          />
        </Tooltip>
        <div>:</div>
        <Tooltip title="Minutes" arrow>
          <input
            type="text"
            value={editableMinutes}
            style={{
              ...editableStyle,
              ...(isMinutesInputFocused ? focusedStyle : {}),
            }}
            onFocus={() => setIsMinutesInputFocused(true)}
            onBlur={() => setIsMinutesInputFocused(false)}
            onChange={(e) => handleInputChange(e, setEditableMinutes, "minutes")}
            onClick={handleInputClick}
          />
        </Tooltip>
        <div>:</div>
        <Tooltip title="Seconds" arrow>
          <input
            type="text"
            value={editableSeconds}
            style={{
              ...editableStyle,
              ...(isSecondsInputFocused ? focusedStyle : {}),
            }}
            onFocus={() => setIsSecondsInputFocused(true)}
            onBlur={() => setIsSecondsInputFocused(false)}
            onChange={(e) => handleInputChange(e, setEditableSeconds, "seconds")}
            onClick={handleInputClick}
          />
        </Tooltip>
      </Stack>
      <Tooltip title="Reset" arrow>
        <IconButton className="hover-icon"
          onClick={onReset}
          sx={{ color: "#fff", background: "rgba(232, 249, 253, 0.2)", opacity: 0, visibility: 'hidden' }}
        >
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}



export default function TimerOrStopWatch() {
  const dispatch = useAppDispatch()
  const tabData = useAppSelector(selectNewTabFocusByOwnerId);
  const showTimerOrStopWatch = (stopWatchOrTimer) => {
    dispatch(
      DBNewTabFocusThunks.patch({
        entity: {
          _id: tabData._id,
          stopWatchOrTimer: stopWatchOrTimer
        },
      })
    );
  }

  const hideTimer = () => {
    dispatch(toggleTimerThunk())
  }
  const showTimer = tabData.stopWatchOrTimer === 'timer' ? true : false;


  return (
    <Stack sx={{
      margin: "0 auto", '&:hover .hover-icon': {
        opacity: 1,
        visibility: 'visible',
        
      }
    }}>

      <Box my={2}>
        <IconButton className='hover-icon' size={"small"} sx={{

          transition: 'opacity 0.3s',
          background: "rgba(255,255,255,0.2)",
          opacity: 0,
          visibility: "hidden"
        }} onClick={hideTimer}>
          <CloseIcon fontSize="small" sx={{ color: "#fff" }} />
        </IconButton>
      </Box>


      <Stack className='hover-icon' sx={{ margin: "0 auto", color: "#fff",opacity:0,visibility:"hidden" }} direction="row" spacing={2} fontSize={"11px"} >


        <Box sx={{ cursor: "pointer", padding: "2px 5px", background: !showTimer ? "rgba(232, 249, 253, 0.2)" : "", borderRadius: "4px", fontWeight: !showTimer ? "bold" : "" }} onClick={() => showTimerOrStopWatch('stopwatch')} >
          Stopwatch
        </Box>
        <Box sx={{ cursor: "pointer", padding: "2px 5px", background: showTimer ? "rgba(232, 249, 253, 0.2)" : "", borderRadius: "4px", fontWeight: showTimer ? "bold" : "" }} onClick={() => showTimerOrStopWatch('timer')} >
          Timer
        </Box>

      </Stack>
      {showTimer ? (
        <Timer />
      ) : (
        <Stopwatch />
      )}

    </Stack>
  );
}
