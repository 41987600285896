import { Box } from '@mui/system'
import { btQuickPanelActions } from '@reducers/BtQuickPanelReducer'
import { useAppDispatch } from '@store/hooks'
import React, { useEffect } from 'react'



function StartChat() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(btQuickPanelActions.setTargetComponent(""))
    }
  },[dispatch])

  
  return (
    <Box>StartChat</Box>
  )
}

export default StartChat