import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { CSSProperties } from 'react';
import { NotificationStack } from './notification-row';


type Props = {
    notificationId: string;
}

export default function DropdownColumnNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))
    
    const cellWrapperStyle: CSSProperties = { pointerEvents: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBlockStart: '4px' }

    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === notification.notificationUpdateType
    );

    let status
    let oldValue
    let newValue
    if (!notification.oldValue && notification.newValue) {
        newValue = [{
            label: notification.newValue
        }]
        status = 'add data'
    }
    if (notification.oldValue && !notification.newValue) {
        oldValue = [{
            label: notification.oldValue
        }]
        status = 'remove data'
    }
    return (
        <NotificationStack>
            {status === 'add data' &&
                <>
                    <span>{notification.actionType} </span>
                    <b style={cellWrapperStyle}><CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></b>
                    <span>to&nbsp;</span>
                </>
            }
            {status === 'remove data' &&
                <>
                    <span>{notification.actionType} </span>
                    <b style={cellWrapperStyle}><CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /> </b>
                    <span>from&nbsp;</span>
                </>
            }
            <span style={{ fontWeight: 'bold' }}>{notification.taskName}-</span>
            <span style={{ fontWeight: 'bold' }}>{notification.actionOnEntityData}</span>
        </NotificationStack>
    )
}


