import { BulkView, setActiveBulkView } from "@reducers/CampaignReducer";
import { getMockChats, getMockContacts } from "@services/mock-contacts";
import { Chat, WhatsappContact } from "@services/whatsapp";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCallback, useEffect } from "react";
import BTBulkDashboard from "./bt-bulk-dashboard";
import BTBulkManagement from "./bt-bulk-management";
import BulkLogsDialog from "./bulk-logs-dialog";
import BulkTaskViewContainer from "./bulk-task-view-container";
import NewTemplateView from "./new-template-view";

export interface CampaignsViewProps {
    isWebsite?: boolean;
}

export default function CampaignsView({ isWebsite = false }: CampaignsViewProps) {
    const dispatch = useAppDispatch()
    const location = window.location.href

    const activeBulkView = useAppSelector((state) => state.CampaignReducer.activeBulkView);

    const getMockData = useCallback(async () => {
        const contactList: Partial<WhatsappContact>[] = await getMockContacts();
        const chatList: Partial<Chat>[] = await getMockChats();

        window.postMessage(
            {
                cmd: "contactList",
                contactList,
                chatList
            },
            "*"
        );
    }, []);


    useEffect(() => {
        if (location.includes('supportedUserId')) {
            dispatch(setActiveBulkView('campaignManagementView' as BulkView))
        }
    }, [location]);

    useEffect(() => {
        if (isWebsite) {
            getMockData();

            localStorage.setItem("schedulerReady", "true");
        }
    }, [getMockData, isWebsite]);


    return (
        <div style={{
            height: '100vh',
            direction: 'ltr',
            backgroundColor: 'var(--background-default)',
        }}>

            {
                activeBulkView === "newCampaignView" &&
                <BTBulkDashboard />
            }

            {
                activeBulkView === "campaignManagementView" &&
                <BTBulkManagement />
            }

            {
                (activeBulkView === "campaignBoardFromManagementView"
                    || activeBulkView === "campaignBoardFromNewCampaignView") &&
                <BulkTaskViewContainer />
            }

            {
                (activeBulkView === "campaignNewTemplateViewFromManagement"
                    || activeBulkView === "campaignNewTemplateViewFromNewCampaignView"
                    || activeBulkView === 'campaignNewTemplateViewFromWhatsappView') &&
                <NewTemplateView />
            }

            {
                (activeBulkView === "campaignLogsView") &&
                <BulkLogsDialog />
            }


        </div>
    )
}