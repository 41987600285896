import React from "react";
import { ViewMode } from "@components/reactgantt/types/public-types";
type ViewSwitcherProps = {
    isChecked: boolean;
    onViewListChange: (isChecked: boolean) => void;
    onViewModeChange: (viewMode: ViewMode) => void;
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
    onViewModeChange,
    onViewListChange,
    isChecked
}) => {
    return (
        <div className="ViewContainer">
            <button
                className="Button"
                onClick={() => onViewModeChange(ViewMode.QuarterDay)}
            >
                Quarter of Day
            </button>
            <button
                className="Button"
                onClick={() => onViewModeChange(ViewMode.HalfDay)}
            >
                Half of Day
            </button>
            <button className="Button" onClick={() => onViewModeChange(ViewMode.Day)}>
                Day
            </button>
            <button
                className="Button"
                onClick={() => onViewModeChange(ViewMode.Week)}
            >
                Week
            </button>
            <button
                className="Button"
                onClick={() => onViewModeChange(ViewMode.Month)}
            >
                Month
            </button>

            <div className="Switch">
                <label className="Switch_Toggle">
                    <input
                        type="checkbox"
                        defaultChecked={isChecked}
                        onClick={() => onViewListChange(!isChecked)}
                    />
                    <span className="Slider" />
                </label>
                Show Task List
            </div>
        </div>
    );
};
