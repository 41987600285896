import classes from "./files-menu.module.scss";
import { IconButton } from "@mui/material";
import { BiDownload, BiTrash } from "react-icons/bi";
import { Tooltip } from "@mui/material";
import FilesIcons from "@components/files-icons/filesIcons";
import { PickerProps } from "../../columns-cells/cell-interface";
import { updateCellValueThunk } from "@reducers/TaskThunks";
import { useAppDispatch } from "@store/hooks";
import { mediaManager } from "@reducers/MediaManagerReducer";
import { useAppSelector } from "@store/hooks";
import { pickerDialogActions } from "@reducers/PickerDialogReducer";
import JSZip from 'jszip';
import JSZipUtils from "jszip-utils";
import FileSaver from 'file-saver';
import { AiFillFileZip } from "react-icons/ai";
import { cellValueSelector } from "@reducers/TaskSelectors";

export default function FilesMenu({ columnId,
  taskId }: PickerProps) {
  const dispatch = useAppDispatch()
  const cellValue = useAppSelector((state) => cellValueSelector(state, taskId, columnId))
  const allFiles = useAppSelector((state) => state.PickerDialogReducer.pickerProps.cellValue);
  const isFilesSumCell = useAppSelector((state) => state.PickerDialogReducer.pickerAnchorId);
  function clearFile(i) {
    const arr = cellValue.filter((_: number, index: number) => index !== i);
    onValueUpdate(arr.length >= 1 ? arr : undefined);
    dispatch(pickerDialogActions.closePickerDialog());
  }

  function setSelectedCellFilesAndCurrentindex(i) {
    const filesStateUpdate = {}
    filesStateUpdate["index"] = i;
    filesStateUpdate["shown"] = true;
    filesStateUpdate["taskId"] = taskId;
    filesStateUpdate["columnId"] = columnId;
    filesStateUpdate["filesTotal"] = isFilesSumCell ? allFiles : [];
    filesStateUpdate["isViewOnly"] = isFilesSumCell === 'filesSum';
    if (isFilesSumCell === 'filesSum') {
      filesStateUpdate["taskId"] = allFiles[i]._id;
    }
    dispatch(
      mediaManager.setSelectedCellFilesAndCurrentindex(filesStateUpdate)
    );
    dispatch(pickerDialogActions.closePickerDialog());
  }

  const onValueUpdate = (value: any) => {
    dispatch(updateCellValueThunk({
      taskId,
      columnId,
      data: {
        value
      }
    }));
  }

  function downloadFile(file) {
    FileSaver.saveAs(file.url, file.filename);
  }
  function downloadZip(allFiles) {
    const zip = new JSZip();
    let count = 0;
    allFiles.forEach(function (file) {
      var filename = file.filename;
      JSZipUtils.getBinaryContent(file.url, function (err, data) {
        if (err) {
          throw err;
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count === allFiles.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            FileSaver.saveAs(content, 'download.zip');
          });
        }
      });
    });
  }

  return (
    <div className={classes.filesMenu}>
      {isFilesSumCell === 'filesSum' && (
        <div style={{ cursor: "pointer" }} onClick={() => downloadZip(allFiles)} className={classes.fileContainer}>
          <b>Download all files as zip</b>
          <Tooltip title={'Download ' + allFiles.length + ' files as zip'} arrow placement="top" disableInteractive>
            <IconButton size="small">
              <AiFillFileZip />
            </IconButton>
          </Tooltip>

        </div>
      )}
      {allFiles?.map((file, i) => {
        return (
          <span key={i}>
            <div className={classes.fileContainer}>
              <div onClick={(e) => {
                setSelectedCellFilesAndCurrentindex(i);
              }} className={classes.fileName}>
                <FilesIcons file={file} />
                <Tooltip
                  title={file.filename}
                  arrow
                  placement="top"
                  disableInteractive
                >
                  <span> {file.filename}</span>
                </Tooltip>
              </div>
              <div className={classes.actions}>
                <IconButton size="small" onClick={() => downloadFile(file)}>
                  <BiDownload />
                </IconButton>
                {isFilesSumCell !== 'filesSum' ? (<IconButton size="small" onClick={() => clearFile(i)}>
                  <BiTrash />
                </IconButton>) : null}
              </div>
            </div>
          </span>
        );
      })}
    </div>
  );
}
