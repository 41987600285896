import Checkmark from '@assets/lottie/checkmark.json';
import { Board } from '@models/board';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import { getCurrentBoard, getCurrentBoards } from '@reducers/BoardsSelectors';
import { setSelectedBoardId } from '@reducers/BoardsThunks';
import { DBNewTabFocusThunks } from '@reducers/DBServiceReducers';
import { toggleNewTabBoardWindow } from '@reducers/MainReducer';
import { getColumnOptionByValueDone, getCountOfTasksLeftToDo, selectNewTabFocusByOwnerId } from '@reducers/NewTabFocusThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import { appConfig } from 'config/app.config';
import { isEqual } from 'lodash';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { BiLinkExternal } from "react-icons/bi";
import { useSelector } from 'react-redux';

const fadeInFromBelowAnimation = {
    '@keyframes fadeInFromBelow': {
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
    },
    animation: 'fadeInFromBelow 0.5s',
};
const FocusBoardHeader = () => {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = useState(null);

    const boards: Board[] = useSelector(getCurrentBoards, isEqual);
    const currentBoard = useAppSelector(getCurrentBoard);
    const tabData = useAppSelector(selectNewTabFocusByOwnerId);



    const countOfTasksLeft = useAppSelector((state) =>getCountOfTasksLeftToDo(state));


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setCurrentBoard = (boardId) => {
        dispatch(setSelectedBoardId({ boardId }));
        handleClose();

        dispatch(DBNewTabFocusThunks.patch({
            entity: {
                _id: tabData?._id,
                boardId: boardId,
            },
        }));
    }

    const openBoardExternalLink = () => {
        const boardURL = `${appConfig.APP_ENDPOINT}/tasks/?boardID=${currentBoard._id}`;
        window.open(boardURL, '_blank')
    }

    const toggleExpandOrMinimize = () => {

        dispatch(DBNewTabFocusThunks.patch({
            entity: {
                _id: tabData?._id,
                expanded: !tabData?.expanded,
            },
        }));

      //  dispatch(toggleNewTabBoardWindow(!isBoardExpanded));
    }

    useEffect(() => {
        if (tabData) {

            if (tabData?.expanded !== undefined) {
                dispatch(toggleNewTabBoardWindow(tabData?.expanded ));
            }

            if (tabData?.boardId && boards.length > 0) {
                const currentBoard = boards.find(board => board._id === tabData.boardId);
                if (currentBoard) {
                    dispatch(setSelectedBoardId({ boardId: currentBoard._id }));
                }
            }
        }
    }, [tabData, boards, dispatch]);

    return (
        <Stack px={1} py={2} direction="row" justifyContent="space-between" alignItems={"center"} sx={{ width: '100%', maxWidth: '1200px', margin: '0 auto', color: "#fff", fontWeight: "600", background: tabData?.expanded ? "" : "rgba(232, 249, 253, 0.15)", borderRadius: "12px" }} position="static">
            <Box>
                <Stack direction="row" style={{ cursor: 'pointer',fontSize:"15px", }} onClick={handleClick}>
                    {currentBoard?.titleIcon ? currentBoard.titleIcon : "🚀"} {currentBoard?.name ? currentBoard?.name : "My boards"}
                    <ExpandMoreIcon />
                </Stack>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {boards.map((board, index) => (
                        <MenuItem key={index} onClick={() => setCurrentBoard(board._id)}>{board.name}</MenuItem>
                    ))}
                </Menu>
            </Box>
            {countOfTasksLeft === 0 && countOfTasksLeft !== null && <Stack position={"relative"} direction="row" alignItems={"center"} sx={{
                fontSize: "14px",
                ...(fadeInFromBelowAnimation),
            }} >
                <Box sx={{
                    position: "absolute", left: "50%",
                    transform: "translate(-50%, 0)"
                }}>
                    <Lottie autoPlay={true} loop={false} animationData={Checkmark} style={{ width: '200px', height: '200px' }} />
                </Box>
                Board Tasks Are completed succesfully</Stack>}
            <Stack direction="row" alignItems={"self-end"} spacing={2} >
                <Box onClick={toggleExpandOrMinimize} sx={{ fontSize:"15px",fontWeight: "500", cursor: "pointer" }}>             {tabData?.expanded ? 'Minimize' : 'Expand'}
                </Box>
                <IconButton onClick={openBoardExternalLink} size="small" sx={{
                    color: "#fff", fontWeight: "300", p
                        : 0
                }}>
                    <BiLinkExternal />
                </IconButton>
            </Stack>

        </Stack>
    );
};

export default FocusBoardHeader;
