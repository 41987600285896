import BTInputField, { BTInputFieldProps } from "@components/bt-input-field/bt-input-field";

interface BTtextFieldWithLabelProps extends BTInputFieldProps {
  width?: string;
  maxWidth?: string;
  label?: string;
  name?: string;
  type?: string;
  divStyle?: any;
}

function BTtextFieldWithLabel({ maxWidth, width, label, divStyle = {}, ...btInputProps }: BTtextFieldWithLabelProps) {
  return (
    <div
      style={{
        // paddingBottom: `${props.label ? "35px" : "0px"}`,
        display: "flex",
        flexDirection: "column",
        maxWidth: `${maxWidth}`,
        width: `${width}`,
        ...divStyle
      }}
    >
      {label && (
        <span
          style={{
            color: "var(--primary)",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "27px",
            marginBottom: "10px",
          }}
        >
          {label}
        </span>
      )}
      <BTInputField
        {...btInputProps}
      />
    </div>
  );
}
export default BTtextFieldWithLabel;
