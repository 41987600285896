import { TasksGroup } from '@models/task-group'
import { Stack } from '@mui/system'
import { _taskgroupsSelectors } from '@reducers/DBServiceReducers'
import { mainSliceActions } from '@reducers/MainReducer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { BsThreeDots } from 'react-icons/bs'
import { DEFAULT_ACCENT_COLOR } from 'theme/colors'
import classes from "../../group-header/group_header.module.scss"
import { CellProps } from '../cell-interface'
import { getGroupTaskListLength } from '@reducers/TaskSelectors'

export default function GroupMenuHeader({
    groupId
}: CellProps) {
    const dispatch = useAppDispatch()
    const tasksGroup: TasksGroup = useAppSelector((state) => {
        return _taskgroupsSelectors.selectById(state, groupId)
    });

    const menuActiveGroupId = useAppSelector((state) => state.MainReducer.menuActiveGroupId)

    const toggleGroupMenu = () => {
        console.log("toggleGroupMenu press")
        dispatch(mainSliceActions.toggleGroupMenu(menuActiveGroupId ? '' : tasksGroup?._id))
    }
    const groupTaskListLength = useAppSelector((state) => getGroupTaskListLength(state, groupId))

    const direction = useAppSelector((state) => state.UserReducer.direction);

    return (

        <Stack direction="row" alignItems="center">

            <Stack alignItems="center" className={classes.group_header_menu} onClick={toggleGroupMenu}>
                <BsThreeDots size={20} id={`group-button-${tasksGroup?._id}`} />
            </Stack>

            <Stack alignItems="center"
                style={{
                    height: '40px',
                    width: 24,
                    backgroundColor: tasksGroup?.colorAccent ?? DEFAULT_ACCENT_COLOR,
                    borderTopLeftRadius: direction === "ltr" ? 12 : undefined,
                    borderTopRightRadius: direction === "rtl" ? 12 : undefined,
                    borderBottomLeftRadius: (tasksGroup?.isCollapsed || groupTaskListLength === 0) ? 12 : 0,
                    position: 'relative',
                    insetInlineStart: (tasksGroup?.isCollapsed || groupTaskListLength === 0) ? 6 : 9 ,
      
                }}>
            </Stack>
        </Stack >



    )
}