import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { selectUser } from '@reducers/UserSelectors';
import * as React from 'react';
import { useMemo } from 'react';
import { accessFormPopupActions } from '../../reducers/AccessFormPopupReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import AccessForm, { FormState } from '../access-form/access-form';

export interface IAccessFormPopupProps {
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function AccessFormPopup(props: IAccessFormPopupProps) {
    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.AccessFormPopupReducer.status);
    const user = useAppSelector(selectUser);
    const open = useMemo(() => status !== 'Closed' && !user, [status, user])
    const initialState: FormState = useMemo(() => status === 'Signup' ? FormState.Signup : FormState.Login, [status]);
    
    const closeAccessFormPopup = () => {
        dispatch(accessFormPopupActions.setStatus('Closed'));
    }

    return (
            <Dialog
                sx={{ '& .MuiDialog-paper': { maxWidth: '400px', width: '100%' } }}
                dir="ltr"
                TransitionComponent={Transition}
                open={open}
                onClose={closeAccessFormPopup}>
                <AccessForm initialState={initialState} />
            </Dialog>
    );
}

