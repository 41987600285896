import { Stack, Typography } from "@mui/material";
import { getBoardNameById } from '@reducers/BoardsSelectors';
import { useAppSelector } from "@store/hooks";
import { CellProps } from "../cell-interface";
import { messageService } from "@services/messageService";
import { isExtensionContextOrWhatsappView } from "@services/detect-context";
import { appConfig } from "config/app.config";

export default function BoardCell({ cellValue, readOnly, onUpdate, groupId, boardId }: CellProps) {
  const boardName = useAppSelector(state => getBoardNameById(state, boardId));

  function openBoardInNewTab() {
    const boardURL = `${appConfig.APP_ENDPOINT}/tasks/?boardID=${boardId}`;
    if (isExtensionContextOrWhatsappView()) {
        messageService.sendMessage('openTab', boardURL);
    } else {
        window.open(boardURL, '_blank')
    }
}

  return (
    <Stack sx={{cursor:'pointer'}} onClick={openBoardInNewTab} direction={"row"} alignItems={"center"} >
      <Typography sx={{ fontSize: "14px" }}>{boardName}</Typography>
    </Stack>
  );
}
