import BTButton from '@components/bt-button/bt-button'
import BTThemeProvider from '@components/bt-theme-provider'
import RowStack from '@components/common/row-stack/RowStack'
import { ChevronRightOutlined, Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { closeBulkDashboard } from '@reducers/CampaignReducer'
import { getIsCampaignWithoutContacts, selectCanContinueToNextTab, selectIsTimingValid } from '@reducers/CampaignSelectors'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import BulkAudiencesList from './audiences/bulk-audiences-list'
import LaunchCampaignFlowTabs from './bt-bulk-launch-campaign-flow-tabs'
import BulkDBFinish from './finish/bulk-db-finish'
import BulkSetMessage from './message-and-files/bulk-set-message'
import NextTabButton from './next-tab-button'
import BulkSetTimeAndDate from './time-and-date/bulk-set-time-and-date'
import { launchCampaignThunk } from '@reducers/CampaignThunks'
import { getRuntimeUrl } from '@services/storage'



export default function BTBulkDashboard() {
    const dispatch = useAppDispatch()
    
    const activeTab = useAppSelector((state) => state.CampaignReducer.activeTab);
    const canContinueToNextTab = useAppSelector((state) => selectCanContinueToNextTab(state));
    const isCampaignWithoutContacts = useAppSelector((state) => getIsCampaignWithoutContacts(state))
    const isTimingValid = useAppSelector((state) => selectIsTimingValid(state))
    const isCampaignLaunching = useAppSelector((state) => state.CampaignReducer.isCampaignLaunching)


    const isLaunchCampaignDisabled = isCampaignWithoutContacts || !isTimingValid || isCampaignLaunching;

    const dispatchCloseBulkDashboard = () => {
        dispatch(closeBulkDashboard());
    }

    const handleLaunchCampaignButtonClick = async () => {
        dispatch(launchCampaignThunk());
    };
    const srcFinishTab = getRuntimeUrl("/img/FinishTabWhite.svg")

    return (
        <BTThemeProvider>
            <Stack direction='column' sx={{
                height: 'calc(100vh - 60px)',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                zIndex: '1000',
                direction: 'ltr',
                overflowX: 'hidden',
            }}>
                <Stack sx={{
                    height: '100%',
                    alignItems: 'center',
                }}>

                    <LaunchCampaignFlowTabs />
                    <Box style={{ position: 'absolute', top: 5, right: 5 }}>  {/*  close button */}
                        <IconButton onClick={dispatchCloseBulkDashboard}>
                            <Close sx={{ color: 'var(--primary)', fontSize: '24px !important', }} />
                        </IconButton>
                    </Box>

                    <Box sx={{
                        height: '85%',
                        width: '660px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}>
                        {activeTab === 'audiences' &&
                            <BulkAudiencesList />
                        }


                        {activeTab === 'timeAndDate' &&
                            <BulkSetTimeAndDate />
                        }



                        {activeTab === 'messageAndFiles' &&
                            <BulkSetMessage />
                        }



                        {activeTab === 'finish' &&
                            <BulkDBFinish />
                        }
                    </Box>

                    <RowStack sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'var(--background-default)',
                        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.05)',
                        width: '100vw',
                        px: 3,
                        boxSizing: 'border-box',
                    }}>
                        <Box sx={{ marginLeft: 'auto', my: '10px', }}>
                            {activeTab !== 'finish' && <NextTabButton
                                isDisabled={!canContinueToNextTab} />}
                            {activeTab === 'finish' &&
                                <BTButton
                                    onClick={handleLaunchCampaignButtonClick}
                                    disabled={isLaunchCampaignDisabled}
                                    sx={{
                                        padding: "8px",
                                        borderRadius: "8px",
                                        background: "linear-gradient(44.54deg, #42d872 14.2%, #97e89c 85.23%)"
                                    }}
                                >
                                    <img src={srcFinishTab} alt="Finish Tab"
                                        style={{ marginRight: "5px", width: "25px", height: "25px" }} />
                                    Launch campaign
                                    <ChevronRightOutlined sx={{ marginInlineEnd: '2px', height: '14px', width: '14px' }} />
                                </BTButton>}
                        </Box>
                    </RowStack>
                </Stack>
            </Stack >
        </BTThemeProvider >
    )
}

