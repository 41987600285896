import React, { useEffect } from 'react';
import emailConfirm from "@assets/email-confirmation.png"
import { Box, Stack } from '@mui/material';
import BTButton from '@components/bt-button/bt-button';
import { useAppDispatch } from '@store/hooks';
import { useConfirmEmailMutation } from '@reducers/backend-api/backend-api';

const ConfirmEmail: React.FC = () => {
    const [confirmEmail] = useConfirmEmailMutation();

    const handleSendEmail = async () => {
        await confirmEmail({}).unwrap();
    };

    useEffect(() => {
        handleSendEmail();
    }, []);

    return (
        <Stack justifyContent={"center"} alignItems={"center"} p={4}>
            <Box> <img width="200" height="200" src={emailConfirm} alt="email confirmation" /></Box>
            <Stack spacing={2} sx={{ maxWidth: "450px",lineHeight:1.6 }} mt={2} mb={4}>
                <Box>🎉 Hey there!</Box>

                <Box>   We're just one tiny click away from getting you all set up. We've sent a confirmation email your way. Could you do us a quick favor and check your inbox? Click on the link in the email to confirm your address and let the fun begin!</Box>

                <Box>  Can't find the email? No worries, these things can be sneaky sometimes. Make sure to check your spam or junk folder</Box>

                <Box> Thanks for joining us! We're super excited to have you on board.</Box>
            </Stack>
            <BTButton onClick={() => handleSendEmail()} sx={{ boxShadow: "none" }} variant="contained" color="primary"  >
                Resend email confirmation
            </BTButton>
        </Stack>
    );
};

export default ConfirmEmail;