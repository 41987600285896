import { Stack } from "@mui/system";
import { getCurrentBoardId } from "@reducers/BoardsSelectors";
import { useAppSelector } from "@store/hooks";
import { memo } from "react";
import { BsArrow90DegUp } from "react-icons/bs";
import { DEFAULT_ACCENT_COLOR } from "theme/colors";
import FooterRow from "../footer-row/footer-row";
import NewTaskRowInput from "./new-task-row-input";
import classes from "./new-task-row.module.scss";
export interface Props {
  groupId: string;
  colorAccent?: string;
  parentTaskId?: string;
  isLastTaskInSubTasksGroup?: boolean;
}


function NewTaskRow({
  groupId,
  parentTaskId,
  colorAccent,
  isLastTaskInSubTasksGroup,
}: Props) {

  const IsNewSubTask = parentTaskId ? true : false;
  const color = colorAccent ? colorAccent : DEFAULT_ACCENT_COLOR;
  const direction = useAppSelector((state) => state.UserReducer.direction);
  const boardId = useAppSelector(getCurrentBoardId);
  const baseBorderWidth = IsNewSubTask ? 2 : 6;
  const marginRightValue = IsNewSubTask ? direction === "rtl" ? 43 : 42 : 48;
  const commonRadius = IsNewSubTask ? 0 : 8;
  const borderBottomRadius = !IsNewSubTask ? 8 : (isLastTaskInSubTasksGroup ? 12 : 0);
  
  const styles = {
    height: IsNewSubTask ? 40 : 35,
    borderRadius: commonRadius,
    borderInlineStart:`solid ${color} ${baseBorderWidth}px`,
    boxShadow: IsNewSubTask ? "unset" : undefined,
    marginTop: IsNewSubTask ? -1 : 8,
    marginInlineStart: marginRightValue,
    borderBottomLeftRadius: borderBottomRadius,
    borderBottomRightRadius: borderBottomRadius,
    borderBottomWidth: 0, 
    borderTopWidth: 0, 
  };
  

  return (
    <Stack alignItems="center" direction="row">
      <Stack alignItems="center" direction="row"
        className={classes.new_item_wrapper}
        style={styles}
      >
        {IsNewSubTask && (
          <Stack width={34} alignItems="center" style={{
            marginLeft: "5px",
          }}>
            <BsArrow90DegUp color={color} size={14} />
          </Stack>
        )}
        <NewTaskRowInput
          boardId={boardId}
          groupId={groupId}
          parentTaskId={parentTaskId}
        />

      </Stack>

      {!IsNewSubTask && <FooterRow groupId={groupId} />}
    </Stack>
  );
}

export default memo(NewTaskRow);
