import { Chip, Box, Stack } from "@mui/material";

interface CustomChipProps {
  title: string;
  countValue: number;
  backgroundColor?: string;
  color?: string;
}

function ScheduledMessagesCount({
  title,
  countValue,
  backgroundColor,
  color,
}: CustomChipProps) {
  return (
    <Chip
      sx={{
        backgroundColor: "var(--scheduled-message-status-tag-bg)",
      }}
      size="small"
      label={
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
        // sx={{
        //   backgroundColor,
        //   color,
        // }}
        >
          <Box sx={{ fontSize: "12px", color: 'var(--primary)', }}>{title}</Box>

          {countValue && countValue > 1 ? (
            <Box
              sx={{
                minWidth: "18px",
                minHeight: "18px",
                fontSize: "12px",
                borderRadius: "50%",
                backgroundColor: "white",
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
              }}
            >
              {countValue}
            </Box>
          ) : null}
        </Stack>
      }
    />
  );
}

export default ScheduledMessagesCount;
