import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Snooze, labelActions } from '@reducers/labels-reducer';
import { ButtonBase, CircularProgress, InputBase, colors } from '@mui/material';
import mainClasses from '../labels-feature.module.scss'
import { Close } from '@mui/icons-material';
import TalkToUsFooter from './talktous-footer';
import { _labelSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import {   toggleChatLabelThunk } from '@reducers/labels-reducer/labels-thunks';
import { useState } from 'react';
import moment from 'moment';
import classes from '../modals/modal.module.scss'


export default function CustomSnoozeModal() {
    const dispatch = useAppDispatch()
    const customSnoozeWaContactId = useAppSelector((state) => state.LabelReducer.customSnoozeWaContactId)
    const labelNetWorkStatus = useAppSelector((state) => state.LabelReducer.labelNetWorkStatus)
    const snoozedLabel = useAppSelector(state => _labelSelectOneObjectByQuery(state.DBLabelReducer, {
        name:'snoozed'
    }))
    const loadingState = useAppSelector((state) => state.LabelReducer.loadingState)
   
    const [snooze, setSnooze] = useState<Snooze>({ expiryDate: '', time: '' })
    const [isErr, setErr] = useState<boolean>(false)
    const currentTime = moment()


    function handleOnchane({ target: { value, name } }) {
        setSnooze({ ...snooze, [name]: value })
        setErr(false)
    }

    async function handleSnooze() {
        const customDate = new Date(`${snooze.expiryDate},${snooze.time}`)
        const futureDate = moment(customDate)
        if (!(snooze.expiryDate && snooze.time)) return

        if (futureDate.isBefore(currentTime)) {
            //date passed
            setErr(true)
        } else {
            setErr(false)
            const res = await dispatch(toggleChatLabelThunk({
                contactLabelId: '',
                labelId: snoozedLabel._id,
                waChatId: customSnoozeWaContactId,
                snooze: {
                    expiryDate: futureDate.toISOString()
                }
            }))

            if (res.payload) {
                setSnooze({})
                dispatch(labelActions.setCustomSnoozeWaContactId(''))
            }
        }
    }

    return (
        <div>

            <Modal
                open={customSnoozeWaContactId !== ''}
                onClose={() => {
                    dispatch(labelActions.setCustomSnoozeWaContactId(''))
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.container}>
                    <Box className={classes.modalHead}>
                        <Typography id="modal-modal-title"
                            sx={{ flex: 1, fontWeight: 600 }} variant="h6" component="h2">
                            Custom Date and Time
                        </Typography>

                        <ButtonBase
                        className={classes.closeModalButton}
                            sx={{ width: 35, height: 35 }}
                            onClick={() => {
                                dispatch(labelActions.setCustomSnoozeWaContactId(''))
                            }}
                        >
                            <Close sx={{ fontSize: '25px' }} />
                        </ButtonBase>
                    </Box>

                    <Box sx={styles.FormContainer}>
                        <InputBase
                            sx={{
                                ...styles.input,
                                color: 'var(--label-text-color)',
                                borderColor: isErr && snooze.expiryDate !== '' ? colors.red[500] : ''
                            }}
                            type='date'
                            name="expiryDate"
                            value={snooze.expiryDate}
                            onChange={handleOnchane}
                            placeholder='Type name here...' />
                        <InputBase
                            sx={{
                                ...styles.input,
                                mt: 1,
                                color: 'var(--label-text-color)',
                                borderColor: isErr && snooze.time !== '' ? colors.red[500] : ''
                            }}
                            type='time'
                            name="time"
                            value={snooze.time}
                            onChange={({ target: { value } }: any) => setSnooze({ ...snooze, time: value })}
                            placeholder='Type name here...' />
                    </Box>
                    <Box className={classes.suggestedLabels}>
                        <Box className={classes.createWrap}>
                            <ButtonBase
                            className={mainClasses.labelButtonBase}
                                onClick={handleSnooze}
                                disabled={!(snooze.expiryDate && snooze.time)}
                                sx={{
                                    flexBasis: '40%',
                                    backgroundColor: isErr && snooze.expiryDate && snooze.time ? colors.red[500] : 'var(--label-button-bg-color)',
                                    color: isErr && snooze.expiryDate && snooze.time ? '#fff' : '',
                                    '&:hover': {
                                        color: labelNetWorkStatus === 'update-label' ? '' : '#fff',
                                        backgroundColor: labelNetWorkStatus === 'update-label' ? colors.grey[200] : isErr && snooze.time !== '' ? colors.red[500] : '#546eff',
                                    }
                                }}>
                                {isErr && snooze.expiryDate && snooze.time ? 'Invalid Date' : 'Snooze'}
                                {loadingState.includes(`${customSnoozeWaContactId}-${snoozedLabel?.name}`)
                                    && <CircularProgress size={16} sx={{
                                        ml: 1,
                                    }} />}

                            </ButtonBase>
                        </Box>
                    </Box>
                    <TalkToUsFooter />
                </Box>
            </Modal>
        </div >
    );
}


const styles = {

    FormContainer: {
        width: '80%',
        margin: 'auto'
    },
    input: {
        height: '44px',
        width: '100%',
        padding: '0 5px',
        borderRadius: '10px',
        border: '1px solid #546eff',
        transition: '0.3s all',
    }
}