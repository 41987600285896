import { ContactLabel } from "@models/contact-label";
import { DBContactsLabelsThunks, contactsLabelsSelectByQuery, labelSelectors } from "@reducers/DBServiceReducers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import moment from 'moment';
import toast from 'react-hot-toast';
import { Snooze, labelActions } from "..";
import { hasTimeExpired } from "../helper-functions";
import { getContactLabelsByName } from "../selectors";

const notify = (message) => toast.success(message);

// rebuild contact nodes
export const rebuildContactNodesThunk = createAsyncThunk<void, undefined, { state: RootState }>
    ('labelSlice/rebuildContactNodesThunk', (_, thunkApi) => {
        window.postMessage({ cmd: "rebuild-contact-nodes" }, "*"
        );
    })




// filter chats by label
export const filterChatsByLabelIdThunk = createAsyncThunk<void, string, { state: RootState }>
    ("labelSlice/filterChatsThunk", async (labelId, thunkApi) => {
        const dispatch = thunkApi.dispatch
        const state = thunkApi.getState()
        const contactsLabels = contactsLabelsSelectByQuery(state.DBContactsLabelsReducer, {
            labelId,
        })

        const chatsThatShouldAppearInList = contactsLabels.reduce((accumulator, contactLabel) => {
            accumulator[contactLabel.waChatId] = true;
            return accumulator;
        }, {});

        window.postMessage({ cmd: "update-should-appear-chats-by-list", chatsThatShouldAppearInList }, "*");
        dispatch(labelActions.setActiveLabel(labelId))
    });

//Note to be updated with patchmany!!
// export const clearLabelChatsThunk = createAsyncThunk<void, string, { state: RootState }>
//     ("labelSlice/clearLabelChatsThunk", async (labelId, thunkApi) => {
//         const dispatch = thunkApi.dispatch
//         const state = thunkApi.getState()
//         const activeLabelId = state.LabelReducer.activeLabelId
//         const label = labelSelectors.selectById(state, labelId)
//         const contactLabels = contactsLabelsSelectByQuery(state.DBContactsLabelsReducer, { labelId })
//         contactLabels.forEach((contactLabel) => {
//             dispatch(DBContactsLabelsThunks.delete({ entity: { _id: contactLabel._id } }))
//         })
//         window.postMessage({
//             cmd: "clear-label-chats",
//             payload: {
//                 labelName: label.name,
//                 isFilterEnabled: activeLabelId === labelId
//             }
//         }, "*");
//         notify(`Cleared all chats in ${label.name} label.`)
//     });


// manage contact labels
export const toggleChatLabelThunk = createAsyncThunk<any, {
    waChatId: string
    labelId: string,
    contactLabelId: string,
    snooze?: Snooze
},
    { state: RootState }>
    ('labelSlice/toggleChatLabelThunk', async ({ waChatId, labelId, contactLabelId, snooze }, thunkApi) => {
        const dispatch = thunkApi.dispatch
        const state = thunkApi.getState()

        // const contactLabel: WapiContactLabel = {
        //     key: `__x_has_${label.name}`,
        //     currentLabel: activeLabel.name,
        //     waContactId: waContactId,
        // }

        // window.postMessage({ cmd: "toggle-contact-label", contactLabel }, "*");

        const label = labelSelectors.selectById(state, labelId)
        try {
            if (contactLabelId) {
                notify(`Chat removed from ${label?.name}`)
                await dispatch(DBContactsLabelsThunks.delete({ entity: { _id: contactLabelId } }))
            } else {
                notify(`Chat added to ${label?.name}`)

                let contactsLabel: ContactLabel = {
                    waChatId,
                    labelId,
                }
                if (snooze) {
                    contactsLabel.snooze = snooze
                }

                await dispatch(DBContactsLabelsThunks.create(contactsLabel))
            }
        } catch (error) {
            notify(`Opps an error occured!`)
        }
    })




// monitors snoozed contacts: unsnooze contact
export const removeContactFromSnoozedThunk = createAsyncThunk<void, undefined, { state: RootState }>
    ('labelSlice/removeContactFromSnoozedThunk', async (_, thunkAPI) => {
        const dispatch = thunkAPI.dispatch
        const state = thunkAPI.getState()
        const activeLabelId = state.LabelReducer.activeLabelId
        const snoozedContacts = getContactLabelsByName({ state, labelName: 'snoozed' })
        if (snoozedContacts.length) {
            snoozedContacts.forEach(async (contact) => {
                var futureTime = moment(contact?.snooze?.expiryDate);
                // The future time that you are waiting to expire
                if (futureTime.isValid && hasTimeExpired(futureTime)) {
                    const res = await dispatch(DBContactsLabelsThunks.delete({ entity: { _id: contact._id } }))
                    if (res.payload) {
                        dispatch(filterChatsByLabelIdThunk(activeLabelId))
                    }
                }
            })
        }

    })







