import { Box, Stack } from "@mui/system";
import AddAndRemove from "../add-and-remove";
import { useAppSelector } from "@store/hooks";

const DuplicateTaskAction = ({ index }) => {
    const prefix = index === 0 ? 'Then' : 'and then';
    const actions = useAppSelector((state) => state.AutomationReducer.actions);

    return (
        <Stack sx={{ fontSize: '24px' }} direction="row" justifyContent={"space-between"}>
            <Box>
                {prefix} duplicate task
            </Box>
            <AddAndRemove elements={actions} index={index} type={"actions"} componentName="DuplicateTaskAction"/>
        </Stack>
    );
};

export default DuplicateTaskAction;
