import classes from "./link_cell.module.scss";
import { pickerDialogActions } from "@reducers/PickerDialogReducer";
import { useAppDispatch } from "@store/hooks";
import { ReactComponent as AddIcon } from './add.svg';
import { ReactComponent as LinkIcon } from './small-link.svg';
import { RiCloseCircleFill } from "react-icons/ri";
import Tooltip from '@mui/material/Tooltip';
import { CellProps } from "../columns-cells/cell-interface";
import { Skeleton } from "@mui/material";
import { isTempId } from "@reducers/DBServiceReducers";




export default function LinkCell({
    cellValue,
    taskId,
    onUpdate,
    columnId,
    readOnly,
    togglePicker
}: CellProps) {
    const anchorId = `link_${taskId}_${columnId}`;
    function handleClick() {
        togglePicker({
            anchorId: anchorId,
            pickerType: 'link-menu'
        })
    }
    function handleClearValue(e) {
        e.stopPropagation()
        onUpdate(undefined);
    }
    return (
        isTempId(columnId) ? 
        ( <Skeleton  variant="rectangular" />)
        :
        <div className={classes.linkContainer} onClick={handleClick} id={anchorId}  >
            <div className={classes.linkCell}>
                {!cellValue?.link && (
                    <>
                        <AddIcon className={classes.addIcon} />
                        <LinkIcon className={classes.linkIcon} />
                    </>
                )}
                {cellValue?.link && (
                    <>
                        <Tooltip title={cellValue ? cellValue.link : ''} arrow placement="top" disableInteractive>
                            <a onClick={(event) => event.stopPropagation()} href={cellValue.link} target="_blank" rel="noreferrer" >{(cellValue.linkText && cellValue.link ? cellValue.linkText : '' || cellValue.link) ?? ''}</a>
                        </Tooltip>
                    </>
                )}

            </div>
            {(cellValue?.link && !readOnly) && (
                <RiCloseCircleFill
                    onClick={(e) => handleClearValue(e)}
                    className={classes.clearIcon}
                />
            )}
        </div>
    );
}
