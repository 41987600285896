import BtEditableBox from '@components/tasks/bt-editable-box/bt-editable-box'
import { MoreHorizOutlined, NoteAltOutlined } from '@mui/icons-material'
import { Box, Button, IconButton, Menu, MenuItem, Stack } from '@mui/material'
import { getCurrentBoardId } from '@reducers/BoardsSelectors'
import { DBFormThunks, DBGanttThunks, DBBoardViewThunks, DBKanbanThunks, DBWorkloadThunks, DBViewFiltersThunks, boardViewSelectByQuery } from '@reducers/DBServiceReducers'
import { DisplayMode, taskActions } from '@reducers/TasksReducer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import React, { useEffect, useRef, useState } from 'react'
import { BiPencil } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'
import { MdOutlineAnalytics, MdOutlineAssignment, MdOutlineAssignmentInd } from 'react-icons/md'
import classes from "./tasks-header.module.scss"
import { bulkActions } from '@reducers/BulkActionsReducer'
import { getBoardDataThunk } from '@reducers/BoardsThunks'
import { BoardView } from '@models/board-views'
import HomeIcon from '@mui/icons-material/Home';
import { namespace } from 'config/constants'
import { useTranslation } from 'react-i18next'
type Props = {
    featureId: string,
    featureName: string,
    type: string,
    index: number,
    isFullWidth?: boolean,
}

export default function TaskHeaderTab({ featureId, featureName, type, index, isFullWidth = false }: Props) {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const boardId = useAppSelector(getCurrentBoardId)
    const [editMode, setEditMode] = useState(false)
    // const [forceEditMode, setForceEditMode] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const ref = useRef(null);
    const currentBoardView = useAppSelector((state) => state.TasksReducer.currentBoardView) 

    const ITEM_HEIGHT = 35;
    const displayPanelSelectedBorder = '2px solid #579bfc';
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDelete = () => {
        switch (type) {
            case 'form': {
                dispatch(DBFormThunks.delete({
                    entity: {
                        _id: featureId,
                    }
                }))
                break;
            }
            case 'kanban': {
                dispatch(DBKanbanThunks.delete({
                    entity: {
                        _id: featureId,
                    }
                }))
                break;
            }
            case 'gantt': {
                dispatch(DBGanttThunks.delete({
                    entity: {
                        _id: featureId,
                    }
                }))
                break;
            }
            case 'workload': {
                dispatch(DBWorkloadThunks.delete({
                    entity: {
                        _id: featureId,
                    }
                }))
                break;
            }
            case 'boardView': {
                dispatch(DBBoardViewThunks.delete({
                    entity: {
                        _id: featureId,
                    }
                }))
                break;
            }
        }
        dispatch(taskActions.setCurrentBoardView({
            type: 'board',
            id: boardId
        }
        ))

    }
    const updateName = (value) => {
        switch (type) {
            case 'form': {
                // let _feature: BlueticksForm = feature
                dispatch(DBFormThunks.patch({
                    entity: {
                        _id: featureId,
                        name: value,
                    }
                }))
                break;
            }
            case 'kanban': {
                dispatch(DBKanbanThunks.patch({
                    entity: {
                        _id: featureId,
                        name: value,
                    }
                }))
                break;
            }
            case 'gantt': {
                dispatch(DBGanttThunks.patch({
                    entity: {
                        _id: featureId,
                        name: value,
                    }
                }))
                break;
            }
            case 'workload': {
                dispatch(DBWorkloadThunks.patch({
                    entity: {
                        _id: featureId,
                        name: value,
                    }
                }))
                break;
            } case 'boardView': {
                dispatch(DBBoardViewThunks.patch({
                    entity: {
                        _id: featureId,
                        name: value,
                    }
                }))
                break;
            }
                setEditMode(false)
        }
    }
    const EditableNameField = () => (
        <div ref={ref}>
            <BtEditableBox
                inputSx={{
                    width: '100%',
                    cursor: 'text',
                    border: '1px solid #53bdeb',
                    borderRadius: '4px',
                    fontWeight: editMode ? '700' : '400',
                    color: "var(--primary)",
                    background: 'var(--board-item-menu-hover)',
                    fontSize: '14px',
                    padding: '0 5px',
                    fontFamily: "'Inter', 'Assistant', sans-serif",
                    "& .MuiInputBase-input": {
                        padding: "0"
                    }
                }}
                boxSx={{
                    cursor: 'pointer',
                    color: "var(--primary)",
                    fontSize: '14px',
                    border: '1px solid transparent',
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    fontFamily: "'Inter', 'Assistant', sans-serif",
                    width: '200px',
                    padding: '0 7px',
                }}
                preventClickEdit={true}
                // forceEditMode={forceEditMode}
                forceEditMode={true}
                onBlur={(e) => {
                    setEditMode(false)
                }}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        setEditMode(false)
                        // setForceEditMode(false)
                    }
                }}
                style={{ display: "flex" }}
                text={featureName}
                onValueUpdate={(value) => {
                    if (value !== '') {
                        updateName(value)
                    }
                }}
                onKeyDown={(e) => e.stopPropagation()}
            />
        </div>
    )

    const getIconByType = type => {
        switch (type) {
            case 'form': {
                return <NoteAltOutlined style={{ marginLeft: '4px' }} />;
            }
            case 'kanban': {
                return <MdOutlineAnalytics style={{ transform: 'rotate(-180deg)', marginLeft: '4px', }} />
            }
            case 'gantt': {
                return <MdOutlineAssignment style={{ marginLeft: '4px' }} />
            }
            case 'workload': {
                return <MdOutlineAssignmentInd style={{ marginLeft: '4px' }} />
            }
            case 'board':{
                return <HomeIcon fontSize='small' style={{ marginLeft: '4px' }} />

            }
        }


    }

    return (
        <div
            data-testid={`${type}-tab`}
            style={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 6,
                width: isFullWidth ? '100%' : 'auto',
            }}>
            {!editMode &&
                <Stack direction="row"
                    className={classes.view_button_container}
                    sx={{ width: isFullWidth ? '100%' : 'auto', alignItem: "center" }}
                >
                    <Button
                        disableRipple={true}
                        data-testid='feature-tab-name'
                        onClick={() => {
                            dispatch(taskActions.setTaskViewSideDrawerOpenWith(""));
                            dispatch(bulkActions.setBulkTasksMap({}));
                            dispatch(bulkActions.setMovingTo(''));
                            dispatch(taskActions.setCurrentBoardView({
                                type: type as DisplayMode,
                                id: featureId.toString()
                            }))
                            if (type === 'boardView') {
                                dispatch(DBViewFiltersThunks.find({
                                    viewId: featureId,
                                    deleted: false,
                                    // $paginate: false,
                                }))
                            }
                        }}
                        className={classes.view_button}
                        style={{
                            opacity: currentBoardView.id === featureId.toString() ? 1 : 0.5,
                            borderBottom: currentBoardView.id === featureId.toString() ? displayPanelSelectedBorder : '2px solid lightgrey',
                            width: isFullWidth ? '100%' : 'auto',
                            justifyContent: isFullWidth ? 'flex-start' : 'center',
                            borderRadius:0,
                        }}
                        sx={{ ".MuiButton-startIcon": { marginInlineStart: '-4px', marginInlineEnd: '8px' } }}

                        startIcon={getIconByType(type)}>
                       <Box sx={{mt:"3px"}}> {featureName}</Box>
                    </Button>

                    {type !== "board" && <>
                        <IconButton
                            disableRipple={true}
                            data-testid='feature-tab-three-dots-menu-button'
                            className={classes.view_button_menu}
                            id={`feature-view-${index}`}
                            onClick={handleClick}
                            style={{
                                padding: "5px 6px",
                                position: "absolute",
                                right: 0,
                                opacity: 0,
                                borderRadius: "8px",
                                transition: '0.25s',
                            }}
                        >
                            <MoreHorizOutlined />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={`feature-view-${index}` === anchorEl?.id}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    maxWidth: '20ch',
                                },
                            }}
                        >
                            <MenuItem disableRipple={true} onClick={(event) => {
                                handleClose()
                                setEditMode(true)
                                event.stopPropagation()
                            }}>
                                <div data-testid='feature-tab-rename-button' className={classes.menu_list_item}>
                                    <BiPencil style={{
                                        width: '19px',
                                        height: '19px',
                                    }} />
                                    <span style={{ marginLeft: 5 }}>{t('taskHeaderTab.rename')}</span>
                                </div>
                            </MenuItem>
                            <MenuItem disableRipple={true} onClick={(e) => {
                                handleClose()
                                handleDelete()
                                e.stopPropagation()
                            }}>
                                <div data-testid='feature-tab-delete-button' className={classes.menu_list_item}>
                                    <BsTrash style={{
                                        width: '14px',
                                        height: '14px',
                                    }} />
                                    <span style={{ marginLeft: 5 }}>{t('taskHeaderTab.delete')}</span>
                                </div>
                            </MenuItem>
                        </Menu>
                    </>}


                </Stack>
            }
            {
                editMode &&
                <EditableNameField />
            }

        </div >
    )
}