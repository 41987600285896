import ToolbarButton from '@components/toolbar-button';
import { getSortedAndFilteredWorkspaceUserList } from '@reducers/BoardsSelectors';
import { selectCurrentWorkspace } from '@reducers/UserSelectors';
import { useAppSelector } from "@store/hooks";
import Randomstring from 'randomstring';
import { FaFilter } from 'react-icons/fa';
import { RiFileExcel2Line } from 'react-icons/ri';
import XLSX from "xlsx-js-style";

const hoverStyle = {
    '&:hover': {
        opacity: "1",
        background: "var(--button-hover)",
        boxShadow: "0 5px 12px rgba(0, 0, 0, 0.15)"
    }
};

export const UserManagementExport = () => {

    const sortedAndFilteredWorkspaceUserList = useAppSelector(state => getSortedAndFilteredWorkspaceUserList(state));

    const currentWorkspace = useAppSelector(selectCurrentWorkspace);
    const randomStr = Randomstring.generate(10)


    const exportUsers = () => {
        const data = sortedAndFilteredWorkspaceUserList.map(user => {
            const firstName = typeof user.profile?.firstName === 'string' ? user.profile.firstName : '';
            const lastName = typeof user.profile?.lastName === 'string' ? user.profile.lastName : '';
    
            return {
                Name: `${firstName} ${lastName}`.trim(), // Trim to remove extra spaces if one is missing
                Email: user.email,
                Type: user?.role,
                Status: user?.status
            };
        });
    

        const ws = XLSX.utils.json_to_sheet(data);

        ws['!cols'] = [
            { width: 30 }, // Column A - Name
            { width: 30 }, // Column B - Email
            { width: 30 }, // Column C - Type
            { width: 30 }  // Column D - Status
        ];

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Users');
        XLSX.writeFile(wb, `${currentWorkspace?.name}_-_${randomStr}.xlsx`);
    }


    return (
        <>

            <ToolbarButton
                startIconSVG={RiFileExcel2Line}
                onClickAction={exportUsers}
                buttonText="Export users"
                toolTipTitle="Export users to excel"
                additionalStyles={hoverStyle}
            >

            </ToolbarButton>



        </>
    );
};
