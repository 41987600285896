import { InputBase, SxProps, Theme } from '@mui/material'
import classes from './labels-feature.module.scss'



type Props = {
    value: string
    disabled?: boolean
    onChange: (event: any) => void
    placeholder: string
    sx?: SxProps<Theme>
}

export default function LabelInput({ value, disabled, onChange, placeholder, sx }: Props) {
    return (
        <InputBase
            disabled={disabled}
            value={value}
            onChange={onChange}
            sx={{
                height: '44px',
                flex: 1,
                padding: '0 5px',
                color: 'var(--label-text-color)',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                border: '1px solid transparent',
                transition: '0.3s all',
                ...sx
            }}
            classes={{ disabled: classes.LabelDisabledInput }}
            placeholder={placeholder} />

    )
}






