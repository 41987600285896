import BTButton from "@components/bt-button/bt-button";
import { BtMUIValidatedPhone } from "@components/bt-mui-validated-phone/bt-mui-validated-phone";
import { Alert, DialogActions, DialogContent, DialogTitle, Modal, Stack } from "@mui/material";
import { setStartChatWithPhoneNumberOpen } from "@reducers/MainReducer";
import { selectDefaultChatCountryCode } from "@reducers/UserSelectors";
import { setInvalidWANumber } from "@reducers/WhatsAppReducer";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { RootState } from "@store/index";
import React, { useCallback, useState } from "react";

export interface StartChatWithPhoneNumberState {
    phone?: string;
    validPhone?: boolean;
}

export const StartChatWithPhoneNumber = React.forwardRef(() => {
    const dispatch = useAppDispatch();
    const defaultChatCountryCode = useAppSelector(selectDefaultChatCountryCode);
    const isOpen = useAppSelector((state: RootState) => state.MainReducer.startChatWithPhoneNumberOpen);
    const [state, setState] = useState<StartChatWithPhoneNumberState>({
        phone: ''
    });
    const invalidWANumber = useAppSelector((state) => state.WhatsAppReducer.invalidWANumber);

    const handleClose = useCallback(() => {
        dispatch(setStartChatWithPhoneNumberOpen(false));
    }, [dispatch])

    const handleChange = (phone, validPhone) => {
        setState({
            ...state,
            phone,
            validPhone,
        });
        dispatch(setInvalidWANumber(undefined));
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (event.key === 'Enter') {
            setTimeout(() => {
                openPhoneChat();
            }, 200);
        }
    }

    const openPhoneChat = () => {
        // if (!state.validPhone) {
        //     return;
        // }

        handleClose();

        window.postMessage({
            cmd: 'openChatByPhone',
            phone: state.phone?.replace('+', '')
        }, '*');
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            sx={{
                "margin": "auto",
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
            }}>
            <Stack sx={{
                direction: 'ltr',
                alignItems: 'center', backgroundColor: 'var(--default-background)', borderRadius: '6px',
            }}>
                <DialogTitle sx={{
                    fontWeight: 700,
                    fontSize: '24px',
                }}>Start a chat with phone number</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '400px', }}>
                    <BtMUIValidatedPhone
                        value={state.phone ?? ''}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        countryCode={defaultChatCountryCode}
                    />
                    {invalidWANumber ? <Alert severity="error">Not a valid WhatsApp number!</Alert> : <></>}
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', p: 0, }}>
                    {/* <Button onClick={openPhoneChat} disabled={!state.validPhone} variant="contained" color="primary">
                    Chat
                </Button> */}
                    <BTButton
                        // disabled={!state.validPhone} 
                        onClick={openPhoneChat}
                        sx={{ width: '400px', height: '50px', marginBottom: '20px', }}
                    >
                        Start Chat
                    </BTButton>
                </DialogActions>
            </Stack>
        </Modal>
    );
})

export default StartChatWithPhoneNumber;
