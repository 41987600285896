import RowStack from '@components/common/row-stack/RowStack'
import { FormControl, MenuItem, Select } from '@mui/material'
import { importItemsActions } from '@reducers/ImportItemsReducer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { namespace } from 'config/constants'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsArrowRight } from 'react-icons/bs'
import { supportedColumnTypes } from './import-excel'
import classes from './import-excel.module.scss'
import ManageColumnsForImportExcel from './manage-columns-for-import-excel'

type Props = {
    importJsonData?: any[];
    columnsList: any;
}

export default function MapImportedDataDialog({
    importJsonData = undefined,
    columnsList,
}: Props) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation(namespace);

    const importDataMapping = useAppSelector((state) => state.ImportItemsReducer.importDataMapping);
    const newColumnList = useAppSelector((state) => state.ImportItemsReducer.newColumnList);


    const excelColumns = useMemo(() => {
        if (importJsonData) {
            let joinedObject = {};
            for (const row of importJsonData) {
                joinedObject = { ...joinedObject, ...row };
            }
            const _excelColumns = Object.keys(joinedObject)

            return _excelColumns;
        }
        else {
            return ['First name', 'Last name']
        }
    }, [importJsonData])



    function selectColumnToMap(key: string, _selectedColumn: string) {
        if (_selectedColumn === 'taskName') {
            dispatch(importItemsActions.setSelectedColumn(key))
        }
        dispatch(importItemsActions.setImportDataMapping({
            ...importDataMapping,
            [key]: _selectedColumn
        }))
    }



    return (
        <>
            <div className={classes.columnDescription}>
                <h3 style={{ display: 'flex', alignItems: 'center', fontWeight: 700, width: '330px', }}>
                    {t('importExcelToBlueticks.mapColumnsFromExcel')}
                    {t('importExcelToBlueticks.toColumnsInBoard')}
                </h3>
                <p>
                    {t('importExcelToBlueticks.excludeFirstRow')}
                </p>
            </div>
            <div className={classes.excelColumnsAndExisitngCoulumns}>
                <div className={classes.colLeft}>{t('importExcelToBlueticks.excelColumns')}</div>
                <div className={classes.colRight}>{t('importExcelToBlueticks.existingBoardColumns')}</div>
            </div>
            <div style={{
                overflowY: 'auto',
                height: '41vh',
                marginTop: '10px',
            }}>
                {excelColumns.map((col: string, index: number) => (
                    <RowStack sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }} key={index}>
                        <RowStack sx={{
                            color: 'var(--primary)',
                            width: '280px',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            {col}
                            <BsArrowRight color="var(--primary)" />
                        </RowStack>
                        <div>
                            <FormControl fullWidth>
                                <Select
                                    value={importDataMapping[col] ?? ''}
                                    onChange={({ target }) => selectColumnToMap(col, target.value)}
                                    sx={{
                                        maxHeight: '40px',
                                        width: '200px',
                                        my: '10px',
                                        color: 'var(--primary)',
                                    }}>
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {[...columnsList, ...newColumnList]
                                        .filter(column => supportedColumnTypes.includes(column.type))
                                        .map((column, index) => (
                                            <MenuItem key={index} value={column?._id} disabled={Object.values(importDataMapping).includes(column._id)} >
                                                {column.title ?? column.type}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </div>
                    </RowStack>
                ))}
                <ManageColumnsForImportExcel
                    supportedColumns={columnsList
                        .filter(column => (supportedColumnTypes).includes(column.type) && column.type !== 'task name')} />
            </div>
        </>
    )
}