import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PopupIframe = ({ url, isOpen, onClose }) => {
  console.log('PopupIframe', url, isOpen, onClose);
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        style: {
          margin: 0,
          width: '500px',
          height: '580px',
          maxWidth: '500px',
          maxHeight: '580px',
        },
      }}
    >
      <DialogContent style={{ padding: 0, height: '80vh' }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
        >
          <CloseIcon />
        </IconButton>
        <iframe
          src={url}
          width="100%"
          height="100%"
          frameBorder="0"
          title="Popup Content"
        />
      </DialogContent>
    </Dialog>
  );
};

export default PopupIframe;