import { colors } from "reusable/helpers";
import classes from "./label-accent-picker.module.scss";

type Props = {
  pickColor: (accent: string, index: number) => void;
};

export default function LabelAccentPicker({ pickColor, }: Props) {

  return (
    <div className={classes.accent_picker_wrapper}>
      {colors.map((accent, index) => (
        <div
          key={index}
          onClick={() => pickColor(accent, index)}
          className={classes.accent_box}
          style={{ backgroundColor: accent,borderRadius:"50%",width:"20px",height:"20px" }}
        ></div>
      ))}
    </div>
  );
}
