import { Workspace } from "@models/workspace";
import { createSelector } from "@reduxjs/toolkit";
import { workspaceSelectors } from "./DBServiceReducers";
import { RootState } from "@store/index";

export const testSelector = createSelector(
  [(state) => state,
  (_, xxx: string) => xxx],
  (state, xxx) => {
    console.log("param:" + xxx);
    return state.WorkspaceReducer.selectedWorkspaceId
  }
)

export const getSelectedWorkspace = createSelector(
  (state: RootState) => state,
  (state: RootState): Workspace => {
    let selectedWorkspace: Workspace = null;
    if (state.WorkspaceReducer.selectedWorkspaceId) {
      selectedWorkspace = workspaceSelectors.selectById(state, state.WorkspaceReducer.selectedWorkspaceId)
    }

    return selectedWorkspace;
  }
);

export const getWorkSpaceById = (workSpaceId) => createSelector(
  (state: RootState) => state,
  (state: RootState): Workspace => {
    let selectedWorkspace;
      selectedWorkspace = workspaceSelectors.selectById(state, workSpaceId);
   
    return selectedWorkspace;
  }
);