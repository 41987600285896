import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";

import { Workspace } from "@models/workspace";
import { DBWorkspaceThunks } from "@reducers/DBServiceReducers";
import { useRouter } from "@reducers/hooks/use-router";
import { useAppDispatch } from "@store/hooks";

import { handleUpload } from "@services/upload";

import replaceImg from "../../assets/icons/replaceImg.png";
import classes from "../../website/views/account-view/account-view.module.scss";

import BTButton from "@components/bt-button/bt-button";
import BTtextFieldWithLabel from "@components/bt-input-field-with-label/bt-input-field-with-label";
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import * as moment from 'moment';
import 'moment-timezone';
import { getAssetURL } from "@components/theme/theme-asset";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export interface WorkspaceFormProps {
    selectedWorkspace?: Workspace;
}

const WorkspaceForm = ({ selectedWorkspace }: WorkspaceFormProps) => {
    const router = useRouter();
    const dispatch = useAppDispatch();

    const logoUrl = getAssetURL("logo_clean.svg")
    const inputFilesRef = useRef<HTMLInputElement>(null);

    const [inputValues, setInputValues] = useState({
        name: selectedWorkspace?.name || '',
        routeUrl: selectedWorkspace?.routeUrl || '',
    });

    const [isLoading, setIsLoading] = useState(false);

    const [profileImgDataUrl, setProfileImgDataUrl] = useState<string>('');
    const [profileImgType, setProfileImgType] = useState<string>('');

    const onFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        const file = event.target.files[0];
        if (file && file.type.substring(0, 5) === "image") {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImgDataUrl(reader.result as string);
                setProfileImgType(file.type);
            };
            reader.readAsDataURL(file);
        } else {
            setProfileImgDataUrl('');
            setProfileImgType('');
        }
    }, []);

    const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    }, [inputValues]);

    const currentImage = useMemo(() => profileImgDataUrl || selectedWorkspace?.imgUrl, [profileImgDataUrl, selectedWorkspace?.imgUrl]);

    const cancelEdit = () => {
        router.navigate('/account-workspace');
    }

    const deleteWorkSpace  = async () => {
        console.log('deleteWorkSpace');
        await dispatch(
            DBWorkspaceThunks.patch({
                entity: {
                    _id: selectedWorkspace._id,
                    deleted:true
                }
            }))
  
            cancelEdit();

    }

    const [timezone, setTimezone] = useState(moment.tz.guess());
    const timezones = moment.tz.names();

    const handleChange = (event) => {
        setTimezone(event.target.value);
    };

    const saveWorkspace = useCallback(async () => {
        console.log('saveWorkspace');
        setIsLoading(true);

        const fieldsToPatch: Partial<Workspace> = { ...inputValues };
        if (profileImgDataUrl) {
            let urlLink = '';
            try {
                const response = await handleUpload(profileImgDataUrl, profileImgType);
                urlLink = response?.image_url;
            } catch (error) {
                console.log('upload error', error);
            }
            console.log('uploaded', urlLink);
            if (urlLink) {
                fieldsToPatch.imgUrl = urlLink;
            }


        }
        fieldsToPatch.timezone = timezone
        let res;
        if (selectedWorkspace?._id) {
            res = await dispatch(
                DBWorkspaceThunks.patch({
                    entity: {
                        _id: selectedWorkspace._id,
                        ...fieldsToPatch,
                    }
                })
            );
        } else {
            res = await dispatch(
                DBWorkspaceThunks.create({
                    ...fieldsToPatch,
                })
            );
        }
        setProfileImgDataUrl('');
        setProfileImgType('');

        setIsLoading(false);
        if (res?.payload?._id) {
            router.navigate('/account-workspace');
        }
    }, [inputValues, dispatch, profileImgDataUrl, profileImgType, selectedWorkspace?._id, router]);

    return (
        <div className={classes.pageContainer}>
            <div className={classes.titleContainer}>
                <h4 className={classes.pageTitle}>Workspace</h4>
            </div>
            <div className={classes.accountSettingBody}>
                   <Button   onClick={cancelEdit} variant="outlined" sx={{textTransform:"capitalize",fontSize:"14px"}} >
                        <ArrowBackIcon/> Back to workspaces
                    </Button>
                <div className={classes.editableField} style={{marginTop:"60px"}}>
                    <span className={classes.fieldName}>Workspace image</span>
                    <div style={{ position: "relative", maxWidth: "150px" }}>
                        <img
                            className={classes.profileImg}
                            src={currentImage ?? logoUrl}
                            style={{
                                minWidth: 150,
                                minHeight: 150
                            }}
                            width={150}
                            height={150}
                            alt=""
                        />

                        <label htmlFor="profileImg">
                            <img
                                style={{ cursor: "pointer" }}
                                className={classes.replaceImg}
                                src={replaceImg}
                                width={32}
                                height={32}
                                alt=""
                            />
                        </label>

                        <input
                            disabled={isLoading}
                            accept="image/*"
                            style={{ display: "none" }}
                            ref={inputFilesRef}
                            type="file"
                            id="profileImg"
                            onChange={onFileChange}
                        />
                    </div>
                </div>

                <div className={classes.editableField}>
                    <span className={classes.fieldName}>Workspace name</span>
                    <BTtextFieldWithLabel name="name" placeholder="Your workspace name" onChange={handleChangeInput} value={inputValues.name} disabled={isLoading} />
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="timezone-select-label">Timezone</InputLabel>
                        <Select
                            labelId="timezone-select-label"
                            id="timezone-select"
                            value={timezone}
                            label="Timezone"
                            onChange={handleChange}
                        >
                            {timezones.map((zone) => (
                                <MenuItem key={zone} value={zone}>
                                    {zone}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>{" "}
                {/* <div style={{ maxWidth: "unset" }} className={classes.editableField}>
                    <span className={classes.fieldName}>Workspace URL</span>

                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <BTtextFieldWithLabel name="routeUrl" placeholder="Your workspace url" onChange={handleChangeInput} value={inputValues.routeUrl} disabled={isLoading} />

                        <span style={{ marginLeft: "15px" }}>.blueticks.co</span>
                    </div>
                </div> */}
                <Stack
                    direction="row"
                    justifyContent={selectedWorkspace ? "space-between" : "flex-end"}
                    className={classes.editableField}
                    spacing={2}
                >
                    {/* <div>
                        <span
                            style={{
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                color: "var(--secondary)",
                            }}
                        >
                            When you change your account's URL, we'll redirect from the old
                            one for 30 days.
                        </span>
                    </div> */}
             
                    {selectedWorkspace && 
                       <BTButton btvariant="text" onClick={deleteWorkSpace} >
                        Delete workspace
                    </BTButton> 
                    }
                    <BTButton loading={isLoading} onClick={saveWorkspace} >
                        {selectedWorkspace ? 'Save changes' : 'Save'}
                    </BTButton>


                </Stack>
            </div>
        </div>
    );
};

export default WorkspaceForm;
