import { ReactComponent as DigitalClock } from '@assets/digital-clock.svg';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { accessFormPopupActions } from '@reducers/AccessFormPopupReducer';
import { DBNewTabFocusThunks, tasksSelectors } from "@reducers/DBServiceReducers";
import { clearTaskThunk, createTabFocusThunk, focusTabCreateNewTaskThunk, getCountOfTasksLeftToDo, getIsFocusedTaskDone, selectNewTabFocusByOwnerId, setTaskDoneThunk, switchTaskThunk, toggleTimerThunk, } from '@reducers/NewTabFocusThunks';
import { selectUser, selectUserId } from '@reducers/UserSelectors';
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useEffect, useRef, useState } from "react";
import FocusNextOrPrevious from './focus-next-or-previous';

const fadeOutUpStyle = {
    opacity: 0,
    transform: 'translateY(-10px)',
    transition: 'opacity 0.5s ease, transform 0.5s ease'
};

const fadeInFromBelowStyle = {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'opacity 0.5s ease, transform 0.5s ease'
};

const FocusTaskInput = () => {
    const dispatch = useAppDispatch()
    const [animationStyle, setAnimationStyle] = useState(fadeInFromBelowStyle);
    const [isSubmittingTask, setIsSubmittingTask] = useState(false);
    const [borderWidth, setBorderWidth] = useState(540);
    const [inputValue, setInputValue] = useState('');
    const user = useAppSelector(selectUser);

    const textWidthRef = useRef(null);
    const inputRef = useRef(null);
    const isFirstRender = useRef(true);

    const ownerId = useAppSelector(selectUserId)
    const tabData = useAppSelector(selectNewTabFocusByOwnerId);
    const focusedTaskId = tabData?.taskId
    const task = useAppSelector((state) => tasksSelectors.selectById(state, focusedTaskId));
    const isFocusedTaskDone = useAppSelector((state) => getIsFocusedTaskDone(state, focusedTaskId));
    const countOfTasksLeft = useAppSelector((state) => getCountOfTasksLeftToDo(state));




    const handleInputChange = (e) => {
        if (!user){
            dispatch(accessFormPopupActions.setStatus("Signup"))
        }
        setInputValue(e.target.value);
    };

    const switchTask = (nextOrPrevious) => {
        dispatch(switchTaskThunk(nextOrPrevious))
    };


    const clearTask = () => {
        setInputValue("");
        dispatch(clearTaskThunk())
        inputRef.current.focus();
    };

    const setFocusedTaskDone = async () => {
        dispatch(setTaskDoneThunk());
        if (countOfTasksLeft - 1 > 0) {
            switchTask("next");
        }
        if (countOfTasksLeft - 1 === 0) {
            clearTask();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue !== "") {
            createNewTask(inputValue);
        }
    };
    const isUserAuthorized = () => {
        if (!user) {
            dispatch(accessFormPopupActions.setStatus("Signup"))
        }
    }


    const createNewTask = async (text) => {
        setIsSubmittingTask(true);
        dispatch(focusTabCreateNewTaskThunk(text))
    }

    const showTimer = () => {
        dispatch(toggleTimerThunk())
    }


    useEffect(() => {
        async function fetchDataAndCreateTab() {
            await dispatch(DBNewTabFocusThunks.find({}));
            if (!tabData){
                dispatch(createTabFocusThunk());
            }
            setIsSubmittingTask(false);
        }

        if (ownerId) {
            fetchDataAndCreateTab();
        }
    }, [ownerId,user]);

    useEffect(() => {
        if (textWidthRef.current) {
            const newWidth = Math.max(540, textWidthRef?.current?.offsetWidth);
            setBorderWidth(newWidth);
        }
    }, [inputValue, task]);


    // useEffect(() => {
    //     if (!isSubmittingTask) {
    //         if (isFirstRender.current) {
    //             isFirstRender.current = false;
    //         } else {
    //             setAnimationStyle(fadeOutUpStyle);
    //             setTimeout(() => {
    //                 setAnimationStyle(fadeInFromBelowStyle);
    //             }, 500);
    //         }

    //     }
    // }, [focusedTaskId, isSubmittingTask]);

    return (
        <Stack
            height="50px"
            sx={{
                position: "relative",
                padding: "250px 0 100px", margin: "0 auto ", '&:hover .hover-icon': {
                    opacity: 1,
                    visibility: 'visible'
                }
            }}>


            <Stack sx={{ position: "relative", }} height="50px" width="100%" direction="column" justifyContent={"center"} alignItems={"center"}>

                {!focusedTaskId ? (
                    <label style={{ fontSize: '32px', color: "#fff" }}>
                        What are you working on now?</label>) : <label style={{ fontSize: '32px', color: "#fff" }}>Current Task </label>}

                <input
                    type="text"
                    value={task?.text ? task?.text : inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onClick={isUserAuthorized}
                    readOnly={focusedTaskId ? true : false}
                    style={{
                        cursor: focusedTaskId ? "default" : "text",
                        textAlign: 'center',
                        borderRadius: 0,
                        border: 'none',
                        width: borderWidth,
                        padding: borderWidth
                            > 540 ? "12px 40px" : "12px 0",
                        fontSize: '24px',
                        minWidth: '540px',
                        outline: 'none',
                        color: 'white',
                        fontFamily: "Poppins",
                        backgroundColor: 'transparent',
                        ...animationStyle

                    }}
                    ref={inputRef}
                />
                <div
                    style={{
                        background: "#fff", borderBottom: "2px solid #fff",
                        width: borderWidth,
                        visibility: focusedTaskId ? "hidden" : "visible",
                    }}>
                </div>

                <span

                    ref={textWidthRef}
                    style={{
                        visibility: 'hidden',
                        position: 'absolute',
                        whiteSpace: 'nowrap',
                        fontSize: '24px',

                    }}
                >
                    {task?.text ? task?.text : inputValue}
                </span>

                {focusedTaskId && (
                    <IconButton className="hover-icon"
                        onClick={setFocusedTaskDone} size="small" sx={{
                            position: "absolute", left: 0, bottom: "-12px", background: !isFocusedTaskDone ? "rgba(255,255,255,0.2)" : "#fff"
                            , opacity: 0,
                            visibility: 'hidden',
                            transition: 'opacity 0.3s',

                        }}>
                        <CheckIcon sx={{ color: !isFocusedTaskDone ? "#fff" : "#00C875" }} fontSize="small" />
                    </IconButton>

                )}


                {focusedTaskId && (
                    <IconButton className="hover-icon" size={"small"} sx={{
                        position: "absolute",
                        right: 0, bottom: "-12px", opacity: 0,
                        visibility: 'hidden',
                        transition: 'opacity 0.3s',
                        background: "rgba(255,255,255,0.2)",
                    }} onClick={clearTask}>
                        <CloseIcon fontSize="small" sx={{ color: "#fff" }} />
                    </IconButton>
                )}

                {focusedTaskId && !tabData?.timerShown && (
                    <IconButton onClick={showTimer} className="hover-icon" size={"medium"} sx={{
                        position: "absolute", top: "-70px",
                        transform: "translate(-50%, 0)",
                        left: "50%",
                        background: "rgba(255,255,255,0.2)",
                        transition: 'opacity 0.3s',
                        opacity: 0,
                        visibility: "hidden",
                    }} >
                        <DigitalClock />
                    </IconButton>
                )
                }

            </Stack>
            <FocusNextOrPrevious />

        </Stack>
    );
};

export default FocusTaskInput