import { CircularProgress } from '@mui/material';
import { addNewBoardThunk } from '@reducers/BoardsThunks';
import { useRouter } from '@reducers/hooks/use-router';
import { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useAppDispatch } from '@store/hooks';
import classes from './new-board-button.module.scss';
import { AnalyticsService } from '@services/analytics-service';
import { useTranslation } from 'react-i18next';
import { namespace } from 'config/constants';


function NewBoardButton() {
    const { t } = useTranslation(namespace);
    const router = useRouter()
    const dispatch = useAppDispatch()
    const analyticsService: AnalyticsService = new AnalyticsService();

    const [isLoading, setIsLoading] = useState(false)

    async function addNewBoard() {

        setIsLoading(true)
        const res: any = await dispatch(addNewBoardThunk({ boardName: t('recentBoards.newBoard'), emoji: "", template: '' }));
        analyticsService.event("create_new_board", {
            category: "userEngagement",
            action: "create new board",
        });

        const boardId = res.payload;
        router.navigate(`/tasks/?boardID=${boardId}`);
        setIsLoading(false)
    }

    return (

        <button className={classes.NewBoardButton} onClick={addNewBoard}>
            {isLoading ? <span className={classes.spinner}><CircularProgress size={18} />  </span> : <span className={classes.plusIcon}> <MdAdd /></span>}
            New Board
        </button>
    )
}

export default NewBoardButton