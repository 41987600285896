import RowStack from '@components/common/row-stack/RowStack'
import MessageTemplateEditor from '@components/message-template-editor/message-template-editor'
import { IconButton } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { setActiveTab } from '@reducers/CampaignReducer'
import { campaignsSelectOneObjectByQuery } from '@reducers/DBServiceReducers'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { BsPencil } from 'react-icons/bs'
import CampaignFinishTabTitle from './campaign-finish-tab-title'

type Props = {
    campaignId?: string;
}


export default function CampaignSummaryMessageTemplateDialog({ campaignId }: Props) {
    const dispatch = useAppDispatch()
    let campaign
    const _campaign = useAppSelector((state) => campaignsSelectOneObjectByQuery(state.DBCampaignReducer, { _id: campaignId }))
    const newCampaign = useAppSelector((state) => state.CampaignReducer.newCampaign)

    if (campaignId) campaign = _campaign
    else campaign = newCampaign

    return (
        <Box sx={{ width: '100%', }}>
            <RowStack sx={{ marginBottom: "15px" }}>
                <CampaignFinishTabTitle titleString={"Message:"} />
                {!campaignId && <IconButton
                    onClick={() => dispatch(setActiveTab('messageAndFiles'))}
                >
                    <BsPencil />
                </IconButton>}
            </RowStack>
            <Stack direction='row' sx={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
            }}>
                {(campaign?.template?.message && campaign?.template?.message !== '') &&
                    <MessageTemplateEditor
                        value={campaign?.template}
                        onChange={() => { }}
                        isPreview={true}
                        isDisplayRemoveAttachmentButttom={false}
                    />}
                {!(campaign?.template?.message && campaign?.template?.message !== '') &&
                    <Box sx={{
                        height: '44px',
                        borderRadius: '8px',
                        m: '6px 8px 6px 8px',
                        backgroundColor: '#e1f4df',
                        color: '#35be5f',
                        fontWeight: 600,
                        fontSize: '24px'
                    }}>
                        No message template was selected
                    </Box>
                }
            </Stack>
        </Box>
    )
}