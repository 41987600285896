import React from "react";


import { ColumnProps } from "../../../types/public-types";








import TimelineCell from "@components/tasks/columns-cells/timeline-cell/timeline-cell";
import { getCurrentBoard } from "@reducers/BoardsSelectors";
import { columnListSelector, getGanttTaskItem } from "@reducers/TaskSelectors";
import { useAppSelector } from "@store/hooks";

export const DateStartEndColumn: React.FC<ColumnProps> = ({

  data: {
    dateSetup: {
      dateFormats,
      dateLocale,
    },

    task,


  },
}) => {

  const currentBoard = useAppSelector(getCurrentBoard);
  const boardId = currentBoard._id;
  const columnList = useAppSelector(state => columnListSelector(state, currentBoard._id));
  const [firstTimelineColumn] = columnList.filter(column => column.type === 'timeline-picker');
  const columnId = firstTimelineColumn._id;
  const taskId = task.id;
  const groupId = task.parent;
  const ganttTaskItem: any = useAppSelector((state) => getGanttTaskItem(state, taskId, columnId));

  let data = {}

  if (task.type === "empty") {
    return null
  }


  if (!ganttTaskItem) {
    data = {
      startDate: new Date(task.start),
      endDate: new Date(task.end),
      progressInPercentage: 50
    }
  }



  const onValueUpdate = (value: string) => {
    console.log('value')
    // console.log(value)
    // setData(value)
    // dispatch(updateCellValueThunk({
    //   taskId,
    //   columnId,
    //   data: {
    //     value: value
    //   }
    // }));
  }
  const clearValue = () => {
    onValueUpdate(undefined)
  }
  // const onChange = (item) => {
  //   let startDate = item.startDate;
  //   let endDate = item.endDate;
  //   const today = new Date();
  //   let progressInPercentage = ((today.getTime() - startDate.getTime()) / (endDate.getTime() - startDate.getTime())) * 100;
  //   progressInPercentage = Math.trunc(progressInPercentage)
  //   const output = {
  //       startDate,
  //       endDate,
  //       progressInPercentage:50,
  //   }
  //   // onValueUpdate(output)
  // }
  // console.log('ganttTaskItem')
  // console.log(ganttTaskItem)



  try {
    return (
      <div style={{ margin: '0 10px' }}>
        <TimelineCell cellValue={ganttTaskItem ? ganttTaskItem : data} taskId={taskId} onUpdate={clearValue} openPicker={() => !ganttTaskItem ?? false} togglePicker={() => !ganttTaskItem ?? false} closePicker={() => !ganttTaskItem ?? true} boardId={boardId} columnId={columnId} groupId={groupId} isFormInEditMode={false} />
      </div>
    );
  } catch (e) {
    return (
      <>
        {task.start.toString()}
      </>
    );
  }
};
