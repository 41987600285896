import { getFilterConfigItemByColumnType, getFilterConfigItemByColumnTypeAndSelectedOperator, valuesListSelector } from "@reducers/TasksFilterPanelThunks";
import { useAppSelector } from '@store/hooks';
import { memo, useEffect, useState } from 'react';
import { BetweenPickersFilterRow } from './between_pickers_filter_row';
import FilterRowValueFieldSpecialDateComponent from './filterRowValueFieldSpecialDateComponent';
import { MultiselectFilterValue } from './multiselect_filter_value';
import TextFieldFilterRow from './text_field_filter_row';
import { SelectFilterValue } from "./select_filter_value";

function FilterRowValueField({
    isDisableTempId,
    selectedColumnType,
    selectedColumnId,
    operatorField,
    valueField,
    handleChangeValueField,
}) {

    const selectedFilterItem = useAppSelector((state) => getFilterConfigItemByColumnType(state, selectedColumnType));
    const selectedFilterItemOperator = useAppSelector((state) => getFilterConfigItemByColumnTypeAndSelectedOperator(state, selectedColumnType, operatorField));
    const valueFilterOptionList = useAppSelector((state) => valuesListSelector(state, selectedColumnType, selectedColumnId, operatorField));
    const [componentToDisplay, setComponentToDisplay] = useState<string>('');


    const handleMultiSelectChange = async (newValue) => {
        if (newValue.find(itemVal => itemVal.id === undefined && itemVal.label === "Blank")) {
            newValue = 'Blank'
        }
        handleChangeValueField(newValue)
    }
    const handleBetweenDatesChange = (startDate, endDate) => {
        if (startDate && endDate) {
            startDate = new Date(startDate).getTime()
            endDate = new Date(endDate).getTime()
            handleChangeValueField(startDate + 'isbetween' + endDate)
        }
    };

    const handleChangeTextValueField = (newValue) => {
        handleChangeValueField(newValue);
    };






    /* perfect */
    const whichComponentToDisplay = () => {

        const isSpecialDateOrTimelineValue = (selectedFilterItemOperator?.isSpecialDateFilter)

        if (selectedFilterItem.valueInputType === 'text' && !selectedFilterItemOperator?.isHideValueField && !isSpecialDateOrTimelineValue) {
            setComponentToDisplay("TextFieldFilterRow");
        }
        if (selectedFilterItem.valueInputType === 'person' && !selectedFilterItemOperator?.isHideValueField) {
            setComponentToDisplay("MultiselectFilterValuePerson");
        }
        if (selectedFilterItem.valueInputType === 'select' && !isSpecialDateOrTimelineValue && !selectedFilterItemOperator?.isSpecialBetweenDatesFilter && !selectedFilterItemOperator?.isHideValueField) {
            setComponentToDisplay("MultiselectFilterValueSelect");
        }
        if (selectedFilterItemOperator?.isSpecialBetweenDatesFilter) {
            setComponentToDisplay("BetweenPickersFilterRow");
        }
        if (selectedFilterItemOperator?.isMultiselectForTextField) {
            setComponentToDisplay("MultiselectFilterValueSelect");
        }
        if (isSpecialDateOrTimelineValue && !selectedFilterItemOperator?.isSpecialBetweenDatesFilter) {
            setComponentToDisplay("FilterRowValueFieldSpecialDateComponent");
        }
        if (selectedFilterItemOperator?.isSpecialDateSelectFilter || selectedFilterItemOperator?.isSpecialTimelineFilter) {
            setComponentToDisplay("FilterRowValueFieldSpecialSelectDateComponent");

        }

        return "";
    }

    useEffect(() => {
        whichComponentToDisplay()
    }, [selectedFilterItem, selectedFilterItemOperator])


    return (
        <>
            {(() => {
                switch (componentToDisplay) {
                    case "TextFieldFilterRow":
                        return <TextFieldFilterRow valueField={valueField} handleChangeTextValueField={handleChangeTextValueField} />;
                    case "MultiselectFilterValuePerson":
                        return <MultiselectFilterValue
                            onSelectionChange={handleMultiSelectChange}
                            isPerson={true}
                            options={valueFilterOptionList}
                            valueField={valueField ?? []}
                            isDisableTempId={isDisableTempId}
                        />;
                    case "MultiselectFilterValueSelect":
                        return <MultiselectFilterValue
                            onSelectionChange={handleMultiSelectChange}
                            options={valueFilterOptionList}
                            valueField={valueField ?? []}
                            isDisableTempId={isDisableTempId}
                        />;
                    case "BetweenPickersFilterRow":
                        return <BetweenPickersFilterRow
                            handleChangeValueField={handleBetweenDatesChange}
                            valueField={valueField}
                        />;
                    case "FilterRowValueFieldSpecialDateComponent":
                        return <FilterRowValueFieldSpecialDateComponent
                            handleChangeValueField={handleChangeValueField}
                            valueField={valueField}
                        />;
                    case "FilterRowValueFieldSpecialSelectDateComponent":
                        return <SelectFilterValue
                            onSelectionChange={handleChangeValueField}
                            options={valueFilterOptionList}
                            valueField={valueField}
                        />;

                    default:
                        return null;
                }
            })()}
        </>
    );
}


export default memo(FilterRowValueField);