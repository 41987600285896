import ContactLabelButton from "@components/contact-label-button/contact-label-button";
import ContactLabelsDisplay from "@components/contact-labels-display/contact-labels-display";
import FilterLabelsButton from "@components/filter-labels-button/filter-labels-button";
import DIVContentEditable from "@components/tasks/div-content-editable/div-content-editable";
import { Label } from "@models/label";
import { IconButton, Skeleton } from "@mui/material";
import { DBLabelThunks, labelSelectByQuery } from "@reducers/DBServiceReducers";
import { labelsActions } from "../../reducers/LabelsReducer";
import { useAppSelector } from "@store/hooks";
import { ReactElement, useEffect } from "react";
import { CgClipboard } from "react-icons/cg";
import { FiSearch } from "react-icons/fi";
import { MdAdd } from "react-icons/md";
import { RiDeleteBinLine, RiWhatsappFill } from "react-icons/ri";
import { useAppDispatch} from "@store/hooks";
import classes from "./manage-labels.module.scss";
import { selectUser } from "@reducers/UserSelectors";

export interface ManageLabelsProps {
  chatId?: string;
}

export default function ManageLabels(props: ManageLabelsProps): ReactElement {
  // // TODO:
  // use props.chatId,
  // remove temp chatId
  const tempChatId: string = "972548275828@c.us";

  console.log("ManageLabels rendering");
  // const router = useRouter();
  const dispatch = useAppDispatch()

  const user = useAppSelector(selectUser);

  const itemsLoaded = useAppSelector(
    (state) => state.DBLabelReducer.itemsLoaded
  );

  const labelsFilter = useAppSelector(
    (state) => state.LabelsReducer.labelsFilter
  );
  let labelsFilterClone = { ...labelsFilter };
  if (labelsFilterClone.displayName) {
    labelsFilterClone.displayName = new RegExp(
      labelsFilterClone.displayName,
      "i"
    );
  }
  const labels = useAppSelector((state) =>
    labelSelectByQuery(state.DBLabelReducer, labelsFilterClone)
  );

  async function addNewLabel() {
    const label: Label = {
      displayName: "New label",
      owner: user._id,
    };
    dispatch(DBLabelThunks.create(label));
  }

  async function deleteLabel(label: Label) {
    const _label: Label = { ...label };
    dispatch(DBLabelThunks.delete({ entity: _label }));
  }

  async function updateLabelName(label: Label, displayName: string) {
    const _label: Label = { ...label, displayName };
    dispatch(DBLabelThunks.patch({
      entity: _label
    }));
  }

  function filterLabels({ target }: any) {
    const nameFilter: string = target.value.toLocaleLowerCase();
    dispatch(labelsActions.setLabelFilterName(nameFilter));
  }

  useEffect(() => {
    console.log("get labels");
    dispatch(DBLabelThunks.find({ deleted: false, $paginate: false }));
  }, [dispatch]);

  return (
    <div className={classes.fixedWrapper}>
      <div className={classes.container}>
        <div
          className={classes.toggeableNavigation}
          style={{ flexBasis: "40%" }}
        >
          <h1>manage labels</h1>
          <div className={classes.searchBar}>
            <div className={classes.searchIcon}>
              <FiSearch />
            </div>
            <input
              type="text"
              onChange={filterLabels}
              className={classes.searchInput}
              placeholder="Filter labels..."
            />
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.myLabels}>
              <div>
                <IconButton
                  size="small"
                  onClick={addNewLabel}
                  className={classes.createLabel}
                >
                  <MdAdd />
                  Add Label
                </IconButton>
              </div>
            </div>

            {itemsLoaded ? (
              <>
                {labels?.map((label: Label, index: number) => (
                  <div key={index} className={classes.mappedLabelsWrapper}>
                    <div key={index} className={`${classes.listItem} }`}>
                      <div>
                        <IconButton size="small" className={classes.menuBtn}>
                          <CgClipboard />
                        </IconButton>
                        {label.chatId && (
                          <span className={classes.whatsapp_label_badge}>
                            <RiWhatsappFill />
                          </span>
                        )}
                      </div>

                      <DIVContentEditable
                        hasBorder={false}
                        text={label.displayName}
                        onValueUpdate={(labelName) => {
                          updateLabelName(label, labelName);
                        }}
                      />
                      <button
                        onClick={() => deleteLabel(label)}
                        className={classes.menuListItem}
                      >
                        <span>
                          <RiDeleteBinLine />
                        </span>
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div>
                {[1, 2, 3, 4, 6, 7, 8, 9].map((index) => (
                  <Skeleton
                    className={classes.customSkeletonStyle}
                    key={index}
                    variant="text"
                    height={60}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        <div>
          <h1>related labels componentes</h1>
          <FilterLabelsButton />
          <ContactLabelsDisplay chatId={tempChatId} />
          <ContactLabelButton chatId={tempChatId} />
        </div>
      </div>
    </div>
  );
}
