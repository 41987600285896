
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {  mediaManager } from "@reducers/MediaManagerReducer";
import { addNewVersionOrFileThunk } from '@reducers/MediaManagerThunks';
import { useAppDispatch, useAppSelector } from "@store/hooks";

export default function FileExistsDialog({ }) {
    const dispatch = useAppDispatch()
    // const mediaManager = useAppSelector((state) => state.MediaManagerReducer);
    const filesToDecideNewVersionOrNewFile = useAppSelector((state) => state.MediaManagerReducer.filesToDecideNewVersionOrNewFile);
    const handleCloseFileExists = () => {
        dispatch(
            mediaManager.setFilesToDecideNewVersionOrNewFile(undefined)
        );
    }

    const addNewVersionOrFile = (isNewVersion, currentFile) => {
        dispatch(addNewVersionOrFileThunk({ isNewVersion, currentFile }));
    }

    return (
        <div>
            <Dialog
                open={filesToDecideNewVersionOrNewFile.length > 0}
            >
                <DialogTitle>
                    {filesToDecideNewVersionOrNewFile[0].filename + " already exists. Do you want to add it as new version?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        It can be added as a new version, or as an additional file.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => addNewVersionOrFile(false, filesToDecideNewVersionOrNewFile[0])} >Add file</Button>
                    <Button onClick={() => addNewVersionOrFile(true, filesToDecideNewVersionOrNewFile[0])}>
                        Add new version
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
