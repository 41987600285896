import { Task } from '@models/task';
import { Button } from '@mui/material';
import { cellPersonListSelector, getSelectedBoardId } from '@reducers/BoardsSelectors';
import { getBulkTaskList } from '@reducers/BulkActionsReducer';
import { boardsSelectors, taskGroupsSelectByQuery } from '@reducers/DBServiceReducers';
import { columnListSelector, currentTasks, selectColumnOptions } from '@reducers/TaskSelectors';
import { useAppSelector } from '@store/hooks';
import { namespace } from 'config/constants';
import moment from 'moment';
import Randomstring from 'randomstring';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleRight } from 'react-icons/fa';
import { RiFileExcel2Line } from 'react-icons/ri';
import XLSX from "xlsx-js-style";
import classes from './export-to-excel.module.scss';
import { extractPhoneNumber } from '@reducers/WhatsAppSelectors';
interface SelectedTaskGroups {
    _id: string;
    name: string;
    tasks: Task[];
    colorAccent?: string;
}

export default function ExportExcel() {
    const { t } = useTranslation(namespace);
    const state = useAppSelector(state => state);
    const [selectedTaskGroups, setSelectedTaskGroups] = useState<SelectedTaskGroups[]>([]);
    const bulkTasksList = useAppSelector(getBulkTaskList)
    const currentTasksList = useAppSelector((state) => currentTasks(state))
    const selectedBoardId = useAppSelector(getSelectedBoardId);
    const board = useAppSelector((state) => boardsSelectors.selectById(state, selectedBoardId));
    const groups = useAppSelector((state) => taskGroupsSelectByQuery(state.DBTaskgroupsReducer, {
        boardId: selectedBoardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }))
    const columnsList = useAppSelector((state) => columnListSelector(state, selectedBoardId))
 
    const currentTasksSelectedOrTasksShown = bulkTasksList.length > 0 ? bulkTasksList : currentTasksList;
    const BoardName = `${board.name}`
    const randomStr = Randomstring.generate(10)
    const taskGroups: SelectedTaskGroups[] = [];
    const jsonData: any = [];

    function creatGroupsFromSelectedTasks() {
        currentTasksSelectedOrTasksShown.forEach(task => {
            const group = getTaskGroup(task.groupId);
            let groupIndex = taskGroups.findIndex(g => g._id === group?._id);
    
            if (groupIndex === -1) {
                taskGroups.push({ _id: group?._id, name: group?.name, tasks: [task], colorAccent: group.colorAccent });
            } else {
                taskGroups[groupIndex].tasks.push(task);
            }
        });
    
        // Sort tasks within each group and rearrange subtasks
        taskGroups.forEach(group => {
            group.tasks.sort((a, b) => a.order - b.order);
            let rearrangedTasks = [];
            group.tasks.forEach(task => {
                if (!task.parentTaskId) {
                    // Push parent tasks
                    rearrangedTasks.push(task);
                    // Find and push its subtasks
                    const subTasks = group.tasks.filter(t => t.parentTaskId === task._id);
                    rearrangedTasks.push(...subTasks);
                }
            });
            group.tasks = rearrangedTasks;
        });
        setSelectedTaskGroups([...taskGroups.reverse()]);
    }


    function getHeadColumns() {
        const ignoredColumns = ['context-menu', 'row-prefix', 'chat', 'row-suffix'];
        const headers = { 'BoardName': 'Name' };
        const widths = [{ wch: 100 }];
        columnsList.forEach((column, columnIndex) => {
            if (!ignoredColumns.includes(column.type)) {
                headers[column.type] = column.title;
                widths.push({ wch: column.width / 4 || 40 });
            }
        });

        return { headers, widths };
    }


    function hexToRgb(hex) {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex?.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `${result[1]}${result[2]}${result[3]}` : null;
    }

    function prepareData() {
        const ignoredColumns = ['context-menu', 'row-prefix', 'chat', 'row-suffix'];

        const headerTitles = ['Name', ...columnsList.filter(column => !ignoredColumns.includes(column.type)).map(column => column.title)];

        // First row with only the board name
        jsonData.push([`${board?.titleIcon ?? '✅'} ${board.name.toUpperCase()}`]);
        const borderStyle = {
            top: { style: 'thin', color: { rgb: "000000" } },
            left: { style: 'thin', color: { rgb: "000000" } },
            bottom: { style: 'thin', color: { rgb: "000000" } },
            right: { style: 'thin', color: { rgb: "000000" } },
        };
        // Add a spacer row
        jsonData.push(['']);
        selectedTaskGroups.forEach(group => {
            // Group name in a row
            const rgbColor = hexToRgb(group.colorAccent);

            // Group name in a row with style
            jsonData.push([{
                v: group.name,
                t: "s",
                s: {
                    font: {
                        bold: true,
                        color: { rgb: "FFFFFF" },
                        sz: 16,
                    },
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: rgbColor }
                    },
                    alignment: {
                        horizontal: 'center'
                    },

                }
            }]);
            const headerRow = headerTitles.map((title, index) => {
                return {
                    v: title,
                    t: "s",
                    s: {
                        font: {
                            bold: true,
                            sz: 14,
                        },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: "D6D6D6" }
                        },
                        alignment: {
                            horizontal: index === 0 ? 'left' : 'center'
                        },
                        border: borderStyle
                    }
                };
            });
            jsonData.push(headerRow);

            // Add tasks for each group
            group.tasks.forEach(task => {
                let taskRow:any[] = [
                    {
                        v: task?.parentTaskId ? `${t('exportExcel.subTaskPrefix')} - ${task.text}` : task.text,
                        t: "s",
                        s: {
                            alignment: {
                                horizontal: 'left'
                            },
                            font: {
                                sz: 16
                            }
                        }
                    }];


                columnsList.forEach((column, columnIndex) => {
                    if (!ignoredColumns.includes(column.type)) {
                        //console.log(currentTask);

                        const cellValue = getCellValue(task, column);
                        taskRow.push(cellValue);
                    }
                });
                jsonData.push(taskRow);
            });

            // Add spacer rows after each group
            jsonData.push([''], ['']);
        });
    }

    function exportExcel() {
        if (currentTasksSelectedOrTasksShown.length) {
            prepareData();
            const { widths } = getHeadColumns();
            const wb = XLSX.utils.book_new();

            const ws = XLSX.utils.aoa_to_sheet(jsonData);
            ws['!cols'] = widths;
          // addImageWithFormula(worksheet, 'B1', 'https://tudoboard.com/tudo-logo.svg');
            ws['B2'] = { f: `IMAGE("${'https://tudoboard.com/tudo-logo.svg'}")` } ;

            XLSX.utils.book_append_sheet(wb, ws, BoardName);
            XLSX.writeFile(wb, `${BoardName}_-_${randomStr}.xlsx`);
        }
    }


    const getCellValue = (task, column) => {
        let cellValue:any = '';
        let cellStyle: any = {
            alignment: {
                horizontal: 'center'
            },
            font: {
                sz: 15,
            }
        }
        switch (column.type) {
            case 'text-cell':
            case 'number':
            case 'checkbox':
            case 'phone-cell':
                cellValue = task?.customData?.[column?._id]?.value || '';

                break;

            case 'rating': {
                cellValue = task?.customData?.[column?._id]?.value ? task?.customData?.[column?._id]?.value + "/5" : '';
                break;
            }
            case 'whatsapp-id-cell': {

                const phoneNumber = extractPhoneNumber(task?.customData?.[column?._id]?.value);
                const isGroup = task?.customData?.[column?._id]?.value?.includes && task?.customData?.[column?._id]?.value?.includes('@g.us')
                cellValue = !isGroup ? (phoneNumber || "") : "";
                break;
            }
            case 'status-option-picker':
            case 'option-picker': {
                cellValue = task?.customData?.[column?._id]?.value || '';
                const selectedOption = selectColumnOptions(state, {
                    boardId: column.boardId,
                    columnId: column._id,
                    cellValue
                });
                cellValue = selectedOption?.label ? selectedOption.label : '';
                cellStyle = {
                    ...cellStyle,
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: selectedOption?.bgcolor ? hexToRgb(selectedOption.bgcolor) : "D6D6D6" }
                    },
                    font: {
                        color: { rgb: "FFFFFF" },
                        sz: 15,
                    },
                    border : {
                        top: { style: 'thin', color: { rgb: "FFFFFF" } },
                        left: { style: 'thin', color: { rgb: "FFFFFF" } },
                        bottom: { style: 'thin', color: { rgb: "FFFFFF" } },
                        right: { style: 'thin', color: { rgb: "FFFFFF" } },
                    }
                };
                break;
            }
            case 'complexity-picker':{
                cellValue = task?.customData?.[column?._id]?.value || '';
                const selectedOption = selectColumnOptions(state, {
                    boardId: column.boardId,
                    columnId: column._id,
                    cellValue
                });
                const complexity = selectedOption?.complexity ? selectedOption?.complexity : 0;
                cellValue = selectedOption?.label ? selectedOption.label + ' ' +  `[${complexity}]` : '';
                cellStyle = {
                    ...cellStyle,
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: selectedOption?.bgcolor ? hexToRgb(selectedOption.bgcolor) : "D6D6D6" }
                    },
                    font: {
                        color: { rgb: "FFFFFF" },
                        sz: 15,
                    },
                    border:{
                        top: { style: 'thin', color: { rgb: "FFFFFF" } },
                        left: { style: 'thin', color: { rgb: "FFFFFF" } },
                        bottom: { style: 'thin', color: { rgb: "FFFFFF" } },
                        right: { style: 'thin', color: { rgb: "FFFFFF" } },
                    }
        
            
                };
                break;
            }
            case 'files':{
                const files = task?.customData?.[column?._id]?.value || [];
                cellValue = createLinksString(files);

               
                break;
            }
            case 'datepicker': {
                const dateFormatted = moment(task?.customData?.[column?._id]?.value).isSame(new Date(), 'year') ? moment(task?.customData?.[column?._id]?.value).utc().format("MMM DD") : moment(task?.customData?.[column?._id]?.value).utc().format("MMM DD, YYYY");
                cellValue = task?.customData?.[column?._id]?.value ? dateFormatted : '';
                break;
            }

            case 'person-picker':{
            
                const cellOwnerList = cellPersonListSelector(state, task?.customData?.[column?._id]?.value, column.boardId)
                cellValue = createStringFromJson(cellOwnerList);
                break;
            }

            case 'link':{
                const value = task?.customData?.[column?._id]?.value ? task?.customData?.[column?._id]?.value : '';
                
                const formula = value &&  value?.linkText ?   `HYPERLINK("${value.link}", "${value.linkText}")`   :  value && value?.link ? `HYPERLINK("${value.link}")` : "" ;
                cellValue = formula;
                cellStyle = {
                    ...cellStyle,
                    font:{
                        color: { rgb: "0000FF" },
                        underline: true,
                        sz: 15,
                    },
                    }
                
                break;
            }


            case 'created': {
                const formattedDate = moment(task?.createdAt).isSame(new Date(), 'year') ? moment(task?.createdAt).format("MMM DD HH:mm") : moment(task?.createdAt).format("MMM DD, YYYY HH:mm");
                cellValue = formattedDate || '';
                break;
            }

            case 'last-updated': {
                const formattedDate = moment(task?.updatedAt).isSame(new Date(), 'year') ? moment(task?.updatedAt).format("MMM DD HH:mm") : moment(task?.updatedAt).format("MMM DD, YYYY HH:mm");
                cellValue = formattedDate || '';
                break;
            }

            case 'drop-down':{
                const value = task?.customData?.[column?._id]?.value ? task?.customData?.[column?._id]?.value : '';
                cellValue = value ? createDropdownString(value) : '';
                break;
            }

            default: {
                cellValue = ''
            }


        }
        if (column.type === "link"  && cellValue !== '') {
            return {f:cellValue,t:'s',s:cellStyle}
        }
      
   
        return { v: cellValue, t: "s", s: cellStyle };

    }

    function createStringFromJson(data) {
        return data.map(item => {
            const { firstName, lastName, email } = item.profile;
            return (firstName || lastName) ? `${firstName} ${lastName}`.trim() : email;
        }).join(', ');
    }

    const createLinksString = (links) => {
        return links.map(link => {
            const displayText = link.filename; 
            const url = link.url;
            return `${displayText}\n${url}`; 
        }).join('\n\n');
    };
    
    const createDropdownString = (values) => {
        return values.map(value => {
            
            const label = value.label;
            return `${label}`; 
        }).join(',');
    };


    
    function getTaskGroup(groupId) {
        const group = groups.find((group) => group._id === groupId);
        return group
    }
    

    useEffect(() => {
        creatGroupsFromSelectedTasks()
    }, [])
    
    return (
        <div className={classes.container} style={{ direction: 'ltr' }}>
            <div className={classes.exportHeader}>
                <img src="/img/blueticks_logo_icon.png" style={{ height: 20 }} alt="" /> <FaAngleRight size={15} />  <RiFileExcel2Line color='#437445' size={21} style={{ marginRight: 12 }} />
                {t('exportExcel.exportHeader')} </div>
            <h3 style={{ fontWeight: '400' }}>{t('exportExcel.exportQuestion')} <span style={{ color: "#53bdeb", fontWeight: '500' }}>{board.name}</span> {t('exportExcel.toExcelFile')}</h3>
            <div className={classes.buttonContainer}>
                <Button onClick={exportExcel} className={classes.export_button}>{t('exportExcel.exportButton')}</Button>
            </div>
        </div>
    )
}
