import BTDrawer from '@components/bt-drawer/bt-drawer';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { taskActions } from '@reducers/TasksReducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import MyWorkFilter from './my-work-filter';

export default function MyWorkCustomizeDrawer() {
    const dispatch = useAppDispatch();

    const taskViewSideDrawerOpen = useAppSelector(
        (state) => state.TasksReducer.taskViewSideDrawerOpen
    );


    function closeTaskViewSideDrawer() {
        dispatch(taskActions.setTaskViewSideDrawerOpenWith(""));
    }


    return (
        <BTDrawer variant={"persistent"} anchor="right" open={taskViewSideDrawerOpen !== ""}>
        <div style={{
            overflow: "auto",
            height: "100%",
            width: "450px",
            background: "var(--secondary-background-color)",
            padding: "0 20px"
        }}>

            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "spaceBetween",
                height: "60px",
                position: "relative"
            }}>
                <IconButton onClick={closeTaskViewSideDrawer}>
                    <Close />
                </IconButton>
            </div>
            {taskViewSideDrawerOpen === "my-work-filter" && <MyWorkFilter />}
        </div>
    </BTDrawer>
    );
}
