import { CellProps } from '@components/tasks/columns-cells/cell-interface';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { taskColumnOptionSelectByQuery } from '@reducers/DBServiceReducers';
import { dynamicColumnFilteredById } from '@reducers/TaskSelectors';
import { useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import { namespace } from 'config/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiCloseCircleFill } from 'react-icons/ri';

export default function PriorityStatusFormCell({
    cellValue,
    taskId,
    columnId,
    groupId,
    boardId,
    onUpdate,
    isFormInEditMode
}: CellProps) {
    const { t } = useTranslation(namespace);
    const columnOptions = useAppSelector((state: RootState) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }, undefined, {
        order: 1
    }))
    const column = useAppSelector((state) => dynamicColumnFilteredById(state, { boardId, columnId }));
    const title = () => {
        if (column?.title === '' || column?.title === undefined) {
            return column?.type
        }
        else {
            return column?.title
        }
    }

    const [option, setOption] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setOption(event.target.value);
        onUpdate(event.target.value)
    };

    function handleClearValue() {
        setOption('')
        onUpdate(undefined);
    }

    return (
        <Stack direction='row' sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
        }}>
            <FormControl style={{ width: '260px', }}>
                <InputLabel id="demo-simple-select-autowidth-label">{title()}</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={option}
                    onChange={handleChange}
                    disabled={isFormInEditMode ? true : false}
                    autoWidth
                    label={t('priorityStatusFormCell.label')}
                >
                    {columnOptions
                        .map((option, optionIndex) => {
                            return (
                                <MenuItem value={option._id} key={optionIndex}>{option.label === '' ? t('priorityStatusFormCell.empty') : option.label}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            {cellValue && (
                <RiCloseCircleFill
                    onClick={handleClearValue}
                // className={classes.clearIcon}
                />
            )}
        </Stack>
    )
}