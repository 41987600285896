
import BTButton from '@components/bt-button/bt-button';
import RowStack from '@components/common/row-stack/RowStack';
import ExtensionTopButtonBar from '@components/top-menu/extension-top-button-bar';
import { AddRounded } from '@mui/icons-material';
import { Divider, Fab } from '@mui/material';
import { accessFormPopupActions } from '@reducers/AccessFormPopupReducer';
import { activeSubscriptionSelector, selectUser } from '@reducers/UserSelectors';
import { labelActions } from '@reducers/labels-reducer';
import { openPaymentSite } from "@services/utils";
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { memo, useEffect, useState } from 'react';
import { TbArrowBigUp } from "react-icons/tb";
import LabelTopHeader from './label-top-header/label-top-header';
import LabelSettingsPopper from './poppers/label-settings-popper';
import BTThemeProvider from '@components/bt-theme-provider';
import RunningCampaignIndicator from '@components/bt-bulk-dashboard/running-campaign-indicator';




function TopHeader() {
  const user = useAppSelector(selectUser);
  const userIsLoggedIn = !!user?._id ?? false;
  const dispatch = useAppDispatch()
  const isLabelsEnabeld = false;
  const [isSideBarVersion, setIsSideBarVersion] = useState(false);

  useEffect(() => {
    document.querySelector('[tabindex="-1"] > header') ? setIsSideBarVersion(true) : setIsSideBarVersion(false);
  }, []);

  const activeSubscription = useAppSelector(activeSubscriptionSelector);
  //const managerOrCampaigner = activeSubscription?.plan.planType === "Manager" || activeSubscription?.plan.planType === "Campaigner";

  const accessToken = useAppSelector((state) => state.UserReducer.accessToken);

  console.log("TopHeader render");



  return (
    <BTThemeProvider >
      <RowStack sx={{
        dirction: 'ltr',
        position: 'relative',
        zIndex: 100,
        justifyContent: 'space-between',
        padding: '0px 16px',
        height: 47,
        borderBottom: '1px solid var(--top-header-border-bottom)',
        backgroundColor: 'rgba(108, 209, 241, 0.1)',
        ml: isSideBarVersion ? '53px' : 0,
      }}>

        <RowStack id="header-left-side" flexGrow={"3"}>
          <ExtensionTopButtonBar />
          {isLabelsEnabeld &&
            <>
              <LabelTopHeader />
              <Divider orientation="vertical" flexItem sx={{ marginLeft: "10px" }} />
            </>
          }
        </RowStack>

        <RowStack id="header-right-side" sx={{ flex: 1 }} gap={"10px"} direction={"row-reverse"} >
          {isLabelsEnabeld &&
            <>
              <Fab onClick={() => {
                if (userIsLoggedIn) {
                  dispatch(labelActions.toggleNewLabel(true))
                } else {
                  dispatch(accessFormPopupActions.setStatus('Login'))
                }
              }}

                sx={{
                  backgroundColor: 'white',
                  color: '#474747',
                  width: 35, // Adjusting size
                  height: 35,
                  minHeight: 'auto', // Override minimum height
                  '.MuiSvgIcon-root': { // Target the icon inside the Fab
                    fontSize: '20px', // Icon size
                  },
                  boxShadow: 'none',
                }}>
                <AddRounded />
              </Fab>

              <LabelSettingsPopper />
            </>
          }

          {!userIsLoggedIn &&
            <BTButton sx={{ marginRight: "15px" }} onClick={() => dispatch(accessFormPopupActions.setStatus('Login'))}>Log in</BTButton>
          }
          {userIsLoggedIn &&
            <>
              {!activeSubscription &&
                <BTButton onClick={() => openPaymentSite(accessToken)}
                  startIcon={<TbArrowBigUp size={"14"} />}
                  sx={{ width: "150px", marginInlineStart: "8px" }} btvariant="pink"  >
                  upgrade
                </BTButton>
              }
            </>
          }

          <RunningCampaignIndicator />
          
        </RowStack>

      </RowStack >
    </BTThemeProvider>
  )
}



export default memo(TopHeader);

