import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { CSSProperties } from 'react';
import { NotificationStack } from './notification-row';


type Props = {
    notificationId: string;
}

export default function OptionStatusComplexityColumnNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))
    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === notification.notificationUpdateType
    );
    const cellWrapperStyle: CSSProperties = { pointerEvents: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }

    return (
        <NotificationStack>
            <span>{notification.actionType}&nbsp;</span>
            {notification.oldValue &&
                <div style={{ ...cellWrapperStyle, minWidth: '50px' }}>
                    <CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={notification.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                </div>
            }
            <span style={{ paddingInline: '1px' }}>to</span>
            <div style={{ ...cellWrapperStyle, minWidth: '50px' }}>
                <CellWrapper
                    columnId={""}
                    taskId={""}
                    groupId={""}
                    boardId={''}
                    mockValue={notification.newValue}
                    readOnly={true}
                    cellComponent={cell.cellComponent}
                />
            </div>
            in&nbsp;
            <span style={{ fontWeight: 'bold' }}>{notification.taskName}-</span>
            <span style={{ fontWeight: 'bold' }}>{notification.actionOnEntityData}</span>{` `}
        </NotificationStack>
    )
}