import { AddOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { setActiveBulkView, setActiveTab } from '@reducers/CampaignReducer';
import { DBCampaignThunks, campaignsSelectByQuery } from '@reducers/DBServiceReducers';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useState } from 'react';
import BulkButton from './bulk-button';
import BulkSearchField from './bulk-search-field';
import CampaignDialogRow from './campaign-dialog-row';
import LazyLoadingVirtuoso from './lazy-loading-virtuoso';

export default function BulkCampaignDialog() {
    // selectors
    const dispatch = useAppDispatch()

    const isCampaignsLoaded = useAppSelector((state) => state.DBCampaignReducer.itemsLoaded);

    const [searchTerm, setSearchTerm] = useState("");

    const handleNewCampaignClick = () => {
        dispatch(setActiveTab('audiences'));
        dispatch(setActiveBulkView("newCampaignView"))
    };

    return (
        <Stack sx={{ width: '100%', }}>
            <Stack direction='row' sx={{
                width: '100%',
            }}>
                <BulkSearchField
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    placeHolderString="Search campaigns..."
                />
                <BulkButton
                    onClick={handleNewCampaignClick}
                    buttonString='New Campaign'
                    Icon={AddOutlined}
                    buttonWidth='230px'
                />
            </Stack>

            <Stack sx={{ border: '1px solid var(--campaign-border)', borderRadius: '12px', marginTop: '20px', }}>
                <LazyLoadingVirtuoso
                    height={500}
                    rowHeightString='106px'
                    filterObj={{
                        $or: [
                            { 'template.name': { $regex: searchTerm, $options: 'i' } },
                            { 'template.message': { $regex: searchTerm, $options: 'i' } }
                          ],
                    }}
                    DBThunks={DBCampaignThunks}
                    DBReducer={state => state.DBCampaignReducer}
                    selectByQuery={campaignsSelectByQuery}
                    Component={CampaignDialogRow}
                />

                {!isCampaignsLoaded &&
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <CircularProgress />
                    </Box>
                }
            </Stack>
        </Stack>
    )
}