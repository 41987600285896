import { Box } from "@mui/system";
import { useAppSelector } from "@store/hooks";
import { getQuickFilterOptionsListSelector } from "@reducers/TasksFilterPanelThunks";
import { QuickFilterColumnOptions } from "./quick_filter_column_options";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";

export const QuickFilterPanel = () => {
    const { t } = useTranslation(namespace);
    const columnsWithFilterOptions = useAppSelector(state => getQuickFilterOptionsListSelector(state))
    return (
        <div style={{ display: 'flex', gap: '0' }}>
            <div>
                <b style={{ fontSize: '14px', visibility: 'hidden' }} >groups</b>
                <Box sx={{ display: 'flex' }}>
                    {columnsWithFilterOptions.map((columnWithFilterOptions, i) => {
                        if (columnWithFilterOptions.colmunFilterOptionsWithUsageList?.length !== 0 && columnWithFilterOptions?.columnTitle === "Groups") {
                            return (
                                <QuickFilterColumnOptions
                                    key={columnWithFilterOptions.columnId}
                                    columnWithFilterOptions={columnWithFilterOptions}
                                />
                            )
                        }
                        return null;
                    })}
                </Box>
            </div>
            <div>
                <b style={{ fontSize: '14px', position: 'sticky', left: 0 }} >{t('quickFilterPanel.allColumns')}</b>
                <Box sx={{ display: 'flex' }}>
                    {columnsWithFilterOptions.map((columnWithFilterOptions, i) => {
                        if (columnWithFilterOptions.colmunFilterOptionsWithUsageList?.length !== 0 && columnWithFilterOptions?.columnTitle !== 'Files' && columnWithFilterOptions?.columnTitle !== 'Groups') {
                            return (
                                <QuickFilterColumnOptions
                                    key={columnWithFilterOptions.columnId}
                                    columnWithFilterOptions={columnWithFilterOptions}
                                />
                            )
                        }
                        return null;
                    })}
                </Box>
            </div>
        </div>
    )
}
