import { Kanban } from "@models/kanban";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { kanbanSelectByQuery } from "./DBServiceReducers";

export const kanbanIdList = createSelector(
  [
    (state, boardId) => kanbanSelectByQuery(state.DBKanbanReducer, { boardId })
  ],
  (kanbanList: Kanban[]) => {
    const kanbanIdList = kanbanList.map((kanban, index) => kanban._id)
    return kanbanIdList
  }
);

export interface KanbanState {
  settingsMode: boolean;
}

const initialState: KanbanState = {
  settingsMode: false,
}


const kanbanSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setSettingsMode(state, action: PayloadAction<boolean>) {
      state.settingsMode = action.payload;
    },
  }
})

export default kanbanSlice.reducer;

export const kanbanActions = kanbanSlice.actions;