import BtEditableBox from '@components/tasks/bt-editable-box/bt-editable-box'
import { Board } from '@models/board'
import { Box } from '@mui/system'
import { DBMyWorksThunks, _boardSelectors } from '@reducers/DBServiceReducers'
import { myWorkActions } from '@reducers/MyWorkReducer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useState } from 'react'
import { TfiAngleDown, TfiAngleUp } from 'react-icons/tfi'
import classes from "../../group-header/group_header.module.scss"
import { CellProps } from '../cell-interface'

export default function RowPrefixHeaderBoardView({
    boardId,
    mockValue
}: CellProps) {
    const dispatch = useAppDispatch()
    const boardTasksGroup: Board = useAppSelector((state) => {
        return _boardSelectors.selectById(state, boardId)
    });
    const myWorkPreferences = useAppSelector(state => state.MyWorkReducer.myWorkPreferences);

    const isBoardCurrentlyCollapsed = myWorkPreferences.collapsedBoards.includes(boardId) || mockValue.tasksCount === 0;

    const [isCollapsed, setIsCollapsed] = useState<boolean>(isBoardCurrentlyCollapsed);

    const groupTaskListLength = mockValue.tasksCount

    const inputTheme = {
        width: '100%',
        padding: "0.5px 5px",
        color: "var(--field-hover-border-color)",
        fontSize: '15px',
        border: '1px solid transparent',
        borderRadius: '4px',
        fontWeight: '600',
        background: 'var(--boards-panel-background)',
        "& .MuiInputBase-input": {
            padding: "0"
        }
    }
    const textTheme = {
        // minWidth: 100,
        cursor: 'text',
        color: "var(--field-hover-border-color)",
        padding: "0 5px",
        fontSize: '15px',
        border: '1px solid transparent',
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontWeight: '600',
        maxWidth: '260px',
        borderRadius: '4px',
        pointerEvents: "none",
        "&:hover": {
            border: " 1px dashed var(--default-background);"
        },
    }

    const handleColapse = async () => {
        const updatedIsCollapsed = !isCollapsed;
        setIsCollapsed(updatedIsCollapsed);

        let updatedCollapsedBoards;

        if (updatedIsCollapsed) {
            updatedCollapsedBoards = [...(myWorkPreferences.collapsedBoards || []), boardId];
        } else {
            updatedCollapsedBoards = (myWorkPreferences.collapsedBoards || []).filter(id => id !== boardId);
        }

        const updatedWorkPreferences = {
            ...myWorkPreferences,
            collapsedBoards: updatedCollapsedBoards
        };
        dispatch(myWorkActions.setMyWorkPrefernces(updatedWorkPreferences));

        await dispatch(DBMyWorksThunks.patch({
            entity: {
                _id: myWorkPreferences._id,
                collapsedBoards: updatedCollapsedBoards,
            }
        }));
    }

    return (
        <>

            <Box id={`group_color_picker_${boardId}`}
                className={classes.group_name_container} >
                <div
                    id={`group_header_${boardId}`}
                    className={classes.group_name_wrapper}
                    style={{
                        background: "white",
                        color: "black",
                        marginBottom: groupTaskListLength === 0 ? 2 : 0,
                        borderRadius: groupTaskListLength === 0 && "12px",
                    }}>

                    <BtEditableBox
                        data-testid='group-name-bteditablebox'
                        inputSx={inputTheme}
                        boxSx={textTheme}
                        text={boardTasksGroup?.name ?? boardId}
                    />

                    <button onClick={handleColapse} className={classes.collapse_button} style={{ color: "var(--field-hover-border-color)" }}>
                        {!isCollapsed ? <TfiAngleUp /> : <TfiAngleDown />}
                    </button>
                </div>



                {isCollapsed && (
                    <div style={{ marginLeft: 10, color: 'var(--text-color)' }}>
                        {groupTaskListLength === 0 ? "No items" :
                            (<>
                                {groupTaskListLength} Item{(groupTaskListLength as number > 1) ? "s" : ""}
                            </>
                            )}
                    </div>
                )}
            </Box>
        </>
    )
}