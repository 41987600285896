import RowStack from "@components/common/row-stack/RowStack";
import { WASession } from "@models/wasession";
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Stack, Tab, Tabs, } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { sessionSelectOneObjectByQuery } from "@reducers/DBServiceReducers";
import { toggleHowToScanQrModal } from "@reducers/MainReducer";
import { getRuntimeUrl } from "@services/storage";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import React, { useState } from "react";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const disconnectImage = getRuntimeUrl("img/disconnect.jpg");

const QrHowToScan = () => {
  const dispatch = useAppDispatch();
  const session: WASession = useAppSelector((state) => sessionSelectOneObjectByQuery(state.DBSessionReducer, {}))
  const isHowToScanQrModalOpen = useAppSelector((state) => state.MainReducer.isHowToScanQrModalOpen);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const sessionNotActive = () => {
    return (
      <div style={{ padding: '48px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
          <div style={{ marginRight: '32px', maxWidth: '380px' }}>
            <div style={{ fontSize: '28px', marginBottom: '32px' }}>
              <div>To keep using Blueticks when you are offline:</div>
              <div style={{ fontSize: '14px', margin: '10px 0' }}> Blueticks will send your messages even if your phone/computer is turned off</div>
            </div>
            <Tabs sx={{ "& .MuiTab-root": { fontFamily: "Poppins", textTransform: "capitalize", color: "#809BA8", minHeight: "50px", width: "180px" } }} value={value} onChange={handleChange} aria-label="Device tabs">
              <Tab icon={<AndroidIcon />} label="Android" />
              <Tab icon={<AppleIcon />} label="iPhone" />
            </Tabs>

            {/* Android Instructions */}
            {value === 0 &&
              <Stack mt={2} spacing={2}>
                <Box> 1. Open WhatsApp on your Android primary phone.</Box>
                <RowStack>2. Tap <MoreVertIcon /> {">"} Linked devices {">"} Link a device.</RowStack>
                <Box>3. Unlock your primary phone:
                  <ul>
                    <li style={{ marginBlockEnd: "8px" }}>If your device has biometric authentication, follow the on-screen instructions.</li>
                    <li>If you don’t have biometric authentication enabled, you’ll be prompted to enter the PIN you use to unlock your phone.</li>
                  </ul></Box>
                <Box>4. Point your Android phone at the screen of the device you want to link to scan the QR code.</Box>
              </Stack>


            }

            {/* Iphone Instructions */}

            {value === 1 &&

              <Stack mt={2} spacing={2}>
                <Box> 1. Open WhatsApp on your iPhone primary phone.</Box>
                <RowStack>2. Go to WhatsApp Settings {">"} Linked Devices {">"} Link a Device.</RowStack>
                <Box>3. Unlock your iPhone if you’re on iOS 14 or above:
                  <ul >
                    <li style={{ marginBlockEnd: "8px" }}>Use Touch ID or Face ID to unlock.</li>
                    <li>If you don’t have biometric authentication enabled, you’ll be prompted to enter the PIN you use to unlock your phone.</li>
                  </ul></Box>
                <Box>4. Point your iPhone at the screen of the device you want to link to scan the QR code.</Box>
              </Stack>


            }
          </div>
          {/* <div style={{ fontSize: '24px', padding: '48px', paddingTop: '0' }}>
            Blueticks will send your messages even if your phone/computer is turned off
          </div> */}
          <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.025)', display: 'flex', justifyContent: 'center' }}>
            <video controls style={{ width: '100%', height: 'auto' }}>
              <source src="https://web.whatsapp.com/whatsapp-webclient-login_c09223f0813e7c3adc16476cba2a5d0d.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    );
  };

  const sessionActive = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '48px' }}>
        <div style={{ marginRight: '32px', maxWidth: '380px' }}>
          <div style={{ fontSize: '28px', marginBottom: '32px' }}>
            <div>To disconnect Blueticks:</div>
          </div>
          <ol>
            <li style={{ fontSize: '18px', marginBottom: '18px', lineHeight: '28px' }}>1. Open WhatsApp on your phone</li>
            <li style={{ fontSize: '18px', marginBottom: '18px', lineHeight: '28px' }}>2. Tap Menu or Settings and select Linked Devices</li>
            <li style={{ fontSize: '18px', marginBottom: '18px', lineHeight: '28px' }}>3. Press on Blueticks in the list of devices</li>
            <li style={{ fontSize: '18px', marginBottom: '18px', lineHeight: '28px' }}>3. Select Logout</li>
          </ol>
          <div style={{ fontSize: '24px' }}>
            If you disconnect you will have to keep WhatsApp web open so Blueticks can send your scheduled messages
          </div>
        </div>
        <div style={{ width: '260px', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img alt="" src={disconnectImage} style={{ height: '100%' }} />
        </div>
      </div>
    );
  };

  return <>
    <Dialog
      dir="ltr"
      TransitionComponent={Transition}
      maxWidth='lg'
      open={isHowToScanQrModalOpen}
      onClose={() => { dispatch(toggleHowToScanQrModal(false)) }}
      PaperProps={{
        sx: {
          backgroundColor: '#ffffff',
          color: '#000000',
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={() => { dispatch(toggleHowToScanQrModal(false)) }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          {session && session.status === "connected" && sessionActive()}
          {session && session.status === "qrcode" && sessionNotActive()}
        </div>
      </DialogContent>
    </Dialog>
  </>;
}

export default QrHowToScan;
