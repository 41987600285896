import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { DBKanbanThunks, isTempId, kanbanSelectByQuery } from '@reducers/DBServiceReducers'
import { optionColumnList } from '@reducers/TaskSelectors'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { namespace } from 'config/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classes from "./kanban.module.scss"

type Props = {
    kanbanId: string,
}

export default function KanbanSettingsDrawer({ kanbanId }: Props) {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const [kanban] = useAppSelector((state) => kanbanSelectByQuery(state.DBKanbanReducer, { _id: kanbanId }, ['optionColumnId']));
    const columnList = useAppSelector(state => optionColumnList(state))

    const selectedColumnId = kanban?.optionColumnId || ""; 


    const handleDropDownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isTempId(event.target.value)) {
            dispatch(
                DBKanbanThunks.patch({
                    entity: {
                        _id: kanbanId,
                        optionColumnId: event.target.value,
                    }
                })
            )
        }
    }

    const BuildColumnSelect = () => {
        return (
            <Box sx={{
               // p: 2,
               minWidth:"250px",
               display:columnList.length <= 1 ? "none" : "block"
            }}>

                <FormControl fullWidth>
                    <Select
                   
             
                        value={selectedColumnId}
                        onChange={handleDropDownChange}
                        label={t('kanbanSettingsDrawer.pickYourPickerLabel')}
                        variant="standard"
                    >
                        {columnList
                            .map((column, columnIndex) => {
                                return (
                                    <MenuItem value={column._id} key={columnIndex}>{column.title ?? t('kanbanSettingsDrawer.unnamedColumn')}</MenuItem>
                                )
                            })
                        }
                    </Select>
                    <InputLabel sx={{left:"-14px",fontSize:"14px"}} id="label-ID" >{t('kanbanSettingsDrawer.pickYourPickerLabel')}</InputLabel>
                </FormControl>
            </Box>
        )
    }
    return (
        // <Stack direction='column' className={classes.settings_drawer}>
        //     <p>
        //         {t('kanbanSettingsDrawer.customizeView')}
        //     </p>
        //     {BuildColumnSelect()}
        // </Stack >
            BuildColumnSelect()
    )
}