import { BoardView } from "@models/board-views";
import { getSelectedBoardId } from "@reducers/BoardsSelectors";
import { boardViewSelectByQuery, viewFiltersSelectByQuery } from "@reducers/DBServiceReducers";
import { useAppSelector } from "@store/hooks";
import { AdvancedFilterRow } from "./advanced_filters_row";

export interface ViewFilter {
  _id?: string;
  viewId?: string;
  filterColumnId?: string; //change it
  filterAndOr?: string;
  filterOperator?: string;
  filterValue?: any;
  owner?: string;
}
const AdvancedFilters = () => {
  const currentBoardId = useAppSelector(getSelectedBoardId)
  const currentBoardView = useAppSelector((state) => state.TasksReducer.currentBoardView)
  const [boardView]: BoardView[] = useAppSelector((state) => boardViewSelectByQuery(state.DBBoardViewReducer, { boardId: currentBoardId, _id: currentBoardView.id }));
  const viewId = boardView?._id ?? currentBoardId
  const allViewFilters: ViewFilter[] = useAppSelector((state) =>
    viewFiltersSelectByQuery(
      state.DBViewFiltersReducer,
      {
        viewId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      null,
      {
        updatedAt: 1
      }
    )
  );

  const isVeryFirstFilterRow = allViewFilters.length === 0 ? true : false

  return (
    <div style={{ width: '100%' }}>
      {allViewFilters.map((viewFilter, index) => (
        <div key={viewFilter._id} style={{ margin: '10px 0' }}>
          <AdvancedFilterRow
            key={viewFilter._id}
            viewFilterId={viewFilter._id}
            isFirstFilter={index === 0 ? true : false}
            viewId={viewId}

          />
        </div>
      ))}
      <div style={{ margin: '10px 0' }}>
        <AdvancedFilterRow key={null} viewFilterId={null} viewId={null} isFirstFilter={isVeryFirstFilterRow} />
      </div>
    </div>
  );
};

export default AdvancedFilters;
