import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TasksGroup } from "../models/task-group";

export interface ShareDialogState {
  activeGroup?: TasksGroup;
}

const initialState: ShareDialogState = {};

const ShareDialogSlice = createSlice({
  name: "ShareDialog",
  initialState,
  reducers: {

  },
});

//export const { } = ShareDialogSlice.actions;
export default ShareDialogSlice.reducer;
