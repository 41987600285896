import { AvatarGroup, Box, Skeleton, Stack, Tooltip } from '@mui/material';
import { cellPersonListSelector } from '@reducers/BoardsSelectors';
import { useAppSelector } from "@store/hooks";
import { FaUserCircle } from 'react-icons/fa';
import { CellProps } from '../cell-interface';
import { isTempId } from '@reducers/DBServiceReducers';
import { PersonAvatar } from '@components/tasks/person-avatar/person-avatar';
import { userDisplayName } from '@models/user';

export type PersonPickerIDType = 'user' | 'option';

export interface PersonPickerValue {
  id: string;
  type: PersonPickerIDType;
}

export default function PersonPickerCell({
  taskId,
  cellValue,
  columnId,
  mockValue,
  togglePicker,
  boardId
}: CellProps<PersonPickerValue[]>) {
  const anchorId = `person_${taskId}_${columnId}`;
  const personList = useAppSelector(state => cellPersonListSelector(state, cellValue, boardId));
  const cellPersonList = personList.length > 0 ? personList : mockValue ?? [];

  const extraFilesTheme = {
    alignItems: "end",
    ".MuiAvatar-circular": {
      height: '20px',
      width: '20px',
      background: 'var(--primary)',
      color: 'white',
      fontSize: '10px',
    }
  }

  const personSize = 22;

  return (
    isTempId(columnId) ?
      (<Skeleton variant="rectangular" />)
      :
      <Stack direction={'row'} alignItems={'center'} id={anchorId}
        onClick={() => togglePicker({
          anchorId: anchorId,
          pickerType: 'person-picker',
        })}
        sx={{
          cursor: 'pointer',
          width: '100%',
          justifyContent: 'center',
          padding: !mockValue && "0 5px"
        }}
      >
        <Tooltip title={
          <Stack direction={'column'}>
            {cellPersonList.map((person, i) => <Box dir='auto' key={i}>{userDisplayName(person.profile)}</Box>)}
          </Stack>
        }>
          <AvatarGroup sx={extraFilesTheme} max={3}>
            {cellPersonList.map((person, i) => {
              const profile = person.profile ? person.profile : person;
              return (
                <Box key={i}>
                  <PersonAvatar
                    size={personSize}
                    email={profile?.email}
                    profileImg={profile?.profileImg}
                    lastName={(profile?.lastName === 'undefined' || profile?.lastName === undefined) ? ' ' : profile?.lastName}
                    firstName={profile?.firstName} />
                </Box>
              )
            })
            }
          </AvatarGroup>
        </Tooltip>
        {cellPersonList.length === 0 &&
          <FaUserCircle color="#809ba8" size={personSize} />
        }
      </Stack>
  )
}