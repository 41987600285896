import { Task } from "@models/task";
import AutomationReducer from "@reducers/AutomationReducer";
import MediaManagerReducer from "@reducers/MediaManagerReducer";

import ModalReducer from "@reducers/ModalReducer";
import UndoRedoReducer from "@reducers/UndoRedoReducer";
import UninstallReducer from "@reducers/UninstallReducer";
import {
  AnyAction,
  configureStore,
  createAsyncThunk,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { isBackground } from "@services/detect-context";
import { Message, messageService } from "@services/messageService";
import { appConfig } from "config/app.config";
import moment from "moment";
import devToolsEnhancer from "remote-redux-devtools";
import "symbol-observable";
import AccessFormPopupReducer from "../reducers/AccessFormPopupReducer";
import AddScheduledMessageButtonReducer, {
  addScheduledMessageButtonActions,
} from "../reducers/AddScheduledMessageButtonReducer";
import backendApi, {
  initAppData,
  middleware,
} from "../reducers/backend-api/backend-api";
import BoardsReducer from "../reducers/BoardsReducer";
import BoardViewReducer from "../reducers/BoardviewReducers";
import BulkActionsReducer from "../reducers/BulkActionsReducer";
import CampaignPanelReducer from "../reducers/CampaignReducer";
import ChatListReducer from "../reducers/ChatListReducer";
import { ContactTableReducer } from "../reducers/ContactTableReducer";
import {
  DBAutomationActionsReducer,
  DBAutomationConditionsReducer,
  DBAutomationReducer,
  DBAutomationTriggerReducer,
  DBBoardsReducer,
  DBBoardUserReducer,
  DBBoardViewReducer,
  DBBulkListReducer,
  DBCampaignReducer,
  DBContactReducer,
  DBContactsLabelsReducer,
  DBEntitySubscribersReducer,
  DBFeatureReducer, DBFormReducer, DBGanttReducer, DBInvoiceReducer, DBKanbanReducer, DBLabelReducer,
  DBLogReducer,
  DBMessageLogReducer,
  DBMyWorksReducer,
  DBNewTabFocusReducer,
  DBNotificationsReducer,
  DBQuickLaunchReducer,
  DBSessionReducer,
  DBSubscriptionReducer, DBTaskColumnOptionReducer, DBTaskColumnReducer, DBTaskgroupsReducer,
  DBTasksExtraDataFilesReducer,
  DBTasksExtraDataReducer,
  DBTasksReducer,
  DBTemplateReducer,
  DBTimeTrackingSessionReducer,
  DBUserMessageReducer,
  DBUserReducer,
  DBViewFiltersReducer,
  DBWebhooksReducer,
  DBWorkloadReducer,
  DBWorkspaceReducer,
  DBWorkspaceUserReducer,
} from "../reducers/DBServiceReducers";
import ExtraDataReducer from "../reducers/ExtraDataReducer";
import GanttReducer from "../reducers/GanttReducers";
import ImportItemsReducer from "../reducers/ImportItemsReducer";
import InlineEditReducer from "../reducers/InlineEditReducer";
import KanbanReducer from "../reducers/KanbanReducers";
import LabelsReducer from "../reducers/LabelsReducer";
import MainReducer, {
  initDarkLightModeAccordingToWhatsAppMode,
  initStorageWrapper,
} from "../reducers/MainReducer";
import PickerDialogReducer from "../reducers/PickerDialogReducer";
import ProfilePicReducer from "../reducers/ProfilePicReducer";
import ShareDialogReducer from "../reducers/ShareDialogReducer";
import SnippetsReducer from "../reducers/SnippetsReducer";
import SubscriptionPopupReducer from "../reducers/SubscriptionPopupReducer";
import TasksReducer from "../reducers/TasksReducer";
import UserReducer, {
  externalAccessToken,
  initAuth,
} from "../reducers/UserReducer";
import WhatsAppReducer, { setCurrentExtensionVersion, setUserInfo } from "../reducers/WhatsAppReducer";
import WorkspaceReducer from "../reducers/WorkspaceReducer";

import BtQuickPanelReducer from "@reducers/BtQuickPanelReducer";
import LabelReducer from "@reducers/labels-reducer";
import MyWorkReducer from "@reducers/MyWorkReducer";
import PaymentFormReducer from "@reducers/PaymentFormReducer";
import RecycleBinReducer from "@reducers/RecycleBinReducer";
import { initMessageChannel } from '@services/whatsapp.service';
import NotifierReducerReducer from "../reducers/NotifierReducer";
import WhatsAppViewReducer from "../reducers/WhatsAppViewReducer";
import * as types from "../reusable/actions";
import { MessageState } from "../types/interface";
import StickyHeaderReducer from "@reducers/StickyHeaderReducer";
import CalendarReducer from "@reducers/CalendarReducer";

let reducerMap = {
  DBSessionReducer,
  DBBulkListReducer,
  DBContactReducer,
  DBTemplateReducer,
  DBLabelReducer,
  DBCampaignReducer,
  DBTaskgroupsReducer,
  DBTasksExtraDataReducer,
  DBTasksExtraDataFilesReducer,
  DBMessageLogReducer,
  DBLogReducer,
  DBViewFiltersReducer,
  DBBoardsReducer,
  DBTasksReducer,
  DBInvoiceReducer,
  DBTaskColumnReducer,
  DBTaskColumnOptionReducer,
  DBUserReducer,
  DBWorkspaceReducer,
  DBKanbanReducer,
  DBBoardViewReducer,
  DBFeatureReducer,
  DBMyWorksReducer,
  DBGanttReducer,
  DBFormReducer,
  DBWorkloadReducer,
  DBWorkspaceUserReducer,
  DBBoardUserReducer,
  DBSubscriptionReducer,
  DBTimeTrackingSessionReducer,
  DBAutomationReducer,
  DBAutomationTriggerReducer,
  DBAutomationConditionsReducer,
  DBAutomationActionsReducer,
  DBNewTabFocusReducer,
  DBWebhooksReducer,
  UndoRedoReducer,
  ChatListReducer,
  TasksReducer,
  MyWorkReducer,
  KanbanReducer,
  BoardViewReducer,
  GanttReducer,
  BulkActionsReducer,
  BoardsReducer,
  WhatsAppReducer,
  PickerDialogReducer,
  MainReducer,

  MediaManagerReducer,
  AutomationReducer,
  InlineEditReducer,
  ExtraDataReducer,
  ShareDialogReducer,
  LabelsReducer,
  SubscriptionPopupReducer,
  CampaignReducer: CampaignPanelReducer,
  UserReducer,
  CalendarReducer,
  ContactTableReducer,
  DBUserMessageReducer,
  ProfilePicReducer,
  SnippetsReducer,
  AddScheduledMessageButtonReducer,
  AccessFormPopupReducer,
  backendApi,
  UninstallReducer,
  ModalReducer,
  ImportItemsReducer,
  WorkspaceReducer,
  WhatsAppViewReducer,
  NotifierReducerReducer,
  DBQuickLaunchReducer,
  BtQuickPanelReducer,
  PaymentFormReducer,
  RecycleBinReducer,
  LabelReducer,
  DBEntitySubscribersReducer,
  DBNotificationsReducer,
  DBContactsLabelsReducer,
  StickyHeaderReducer,
};

if (isBackground()) {
  reducerMap = {} as any;
}

let enhancers = [];
if (true || !appConfig.isProd) {
  enhancers.push(
    devToolsEnhancer({
      realtime: true,
      port: 8000,
      stateSanitizer: (state) => ({
        DBTasksReducer: state.DBTasksReducer,
        // ...state,
        // WhatsAppReducer: {
        //   ...state.WhatsAppReducer,
        //   contactList: state.WhatsAppReducer.contactList.slice(0, 100),
        //   contactMap: {},
        // },
      }),
    })
  );
}

const ignoreLargePathList = [
  "WhatsAppReducer.contactList",
  "WhatsAppReducer.contactMap",
];

const store = configureStore({
  /* Uncomment this to debug extension */
  // devTools: false,
  // enhancers,
  reducer: reducerMap,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { ignoredPaths: ignoreLargePathList },
      serializableCheck: false,
    }).concat(middleware),
});

export interface ScheduleTaskData {
  task: Task;
  to: string;
}

export const scheduleTaskThunk = createAsyncThunk<void, ScheduleTaskData>(
  types.SEND_SCHEDULED_TASK,
  async (data: ScheduleTaskData) => {
    dispatch(
      addScheduledMessageButtonActions.editMessage({
        message: data.task.text,
        contactList: [{ id: data.to }],
        dueDate: moment().toDate(),
        isRecurring: false,
        state: MessageState.editing,
      })
    );
  }
);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppThunkDispatch = typeof store.dispatch;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
const dispatch = store.dispatch;

/* uncommet to profile something */

/*
(window as any).profileStore = () => {
  const state: RootState = store.getState();

  const profile = {}
  Object.keys(state).forEach(key => {
    profile[key] = JSON.stringify(state[key]).length;
  })

  console.log(profile)
}
/*


/* init app functions */

dispatch(initAuth());
dispatch(initAppData());
dispatch(initStorageWrapper());
dispatch(initDarkLightModeAccordingToWhatsAppMode());
dispatch(initMessageChannel());

messageService.eventEmitter.on(
  "accessToken",
  (event: Message<{ accessToken: string }>) => {
    dispatch(externalAccessToken(event.data.accessToken));
  }
);

messageService.eventEmitter.on("userInfo", (userInfoMessage) => {
  store.dispatch(setUserInfo({ email: "", id: "" }));
});


messageService.eventEmitter.on("currentExtensionVersion", (event) => {
  console.log(event)
  store.dispatch(setCurrentExtensionVersion(event?.data?.currentVersion ?? ""))
});



