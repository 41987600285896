import BTButton from "@components/bt-button/bt-button";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import { switchWorkspace } from "@reducers/UserReducer";
import { useAcceptInvention } from "@reducers/hooks/use-accept-invitation";
import { useRouter } from "@reducers/hooks/use-router";
import { useAppDispatch } from "@store/hooks";
import { useMemo, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import classes from "./join-workspace.module.scss";

export interface JoinWorkSpaceState {
  loading: boolean;
  message?: string;
}

export const JoinWorkSpace = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const location = useLocation();
  const match = useMatch("/join-workspace/:workspaceId");
  const workspaceId = useMemo(() => match.params.workspaceId, [match]);
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const inviteId = useMemo(() => searchParams.get("inviteId"), [searchParams]);
  const [loadingWorkspace, setLoadingWorkspace] = useState(false);

  const {
    loading,
    message,
    authorized,
    showDialog,
    handleLoginWithDifferentUser,
    hideDialog, // Destructure hideDialog
  } = useAcceptInvention(workspaceId, inviteId, true);

  const handleDialogClose = () => {
    hideDialog();
  };

  const handleDialogYes = () => {
    handleDialogClose();
    handleLoginWithDifferentUser();
  };

  const handleDialogNo = () => {
    handleDialogClose();
  };

  return (
    loading ? <CircularProgress /> :
      <div className={classes.container}>
        <div className={classes.card}>
          <div className={classes.header}>
            <img alt="" src="/img/logo.png" style={{ height: 30 }} />
          </div>

          <Stack className={classes.body} direction="column" spacing={2}>
            {message && <>
              <h2>{message}</h2>
              <Stack direction="row" justifyContent="center">
                <BTButton onClick={handleLoginWithDifferentUser}>Switch User</BTButton>
              </Stack>
            </>}
            {workspaceId && authorized && <Stack direction="row" justifyContent="center">
              <BTButton loading={loadingWorkspace} onClick={async () => {
                setLoadingWorkspace(true);
                await dispatch(switchWorkspace({ workspaceId }));
                router.navigate(`/tasks`)
              }}>Go to Workspace</BTButton>
            </Stack>}
            <img src="/img/illustrator_img.png" alt="illustration" />
            <p>"It takes two flints to make a fire."</p>
          </Stack>
        </div>
        {showDialog && (
          <Dialog open={showDialog} onClose={handleDialogClose}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {message}
                <br />
                Do you wish to log in with a different user?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogNo} color="primary">
                No
              </Button>
              <Button onClick={handleDialogYes} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
  )



}

export default JoinWorkSpace;
