import { BoardRowType, CustomColumnData, Task } from "@models/task";
import { CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { isTempId } from "@reducers/DBServiceReducers";
import { taskActions } from "@reducers/TasksReducer";
import { addNewTaskThunk } from "@reducers/TaskThunks";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { memo, useState } from "react";
import { MdAdd } from "react-icons/md";
import classes from "./new-task-row.module.scss";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@services/analytics-service";
export interface Props {
  disabled?: boolean
  outsideStyles?: any
  boardId?: string;
  groupId?: string;
  parentTaskId?: string;
  customData?: Record<string, CustomColumnData>;
  onEnter?: undefined | any
  onChangeText?: undefined | any
  textValue?: undefined | any
}

function NewTaskRowInput({ groupId,
  boardId, disabled, parentTaskId, outsideStyles,
  customData, onEnter, onChangeText, textValue }: Props) {
  const analyticsService: AnalyticsService = useAnalyticsService();
  const dispatch = useAppDispatch()
  const IsNewSubTask = parentTaskId ? true : false;
  const activeNewTaskRow = useAppSelector((state) => state.TasksReducer.activeNewTaskRow)
  const [newTaskText, setnewTaskText] = useState<string>('')


  function preparedAutoFocusData() {
    dispatch(taskActions.setActiveNewTaskRow({
      groupId,
      parentTaskId,
    }))
  }


  async function addNewTask() {
    if (newTaskText === "" || newTaskText === "\n") {
      setnewTaskText('')
      return;
    }
    let task: Task = {
      text: newTaskText,
      boardId,
      groupId,
    };


    if (customData) {
      task = {
        ...task,
        customData
      }
    }


    if (IsNewSubTask) {
      analyticsService.event("create_new_sub_task", {
        category: "userEngagement",
        action: "create new task",
      });

      task = {
        ...task,
        taskType: BoardRowType.subTask,
        parentTaskId: parentTaskId,

      }
    }
    if (!IsNewSubTask) {
      analyticsService.event("create_new_task", {
        category: "userEngagement",
        action: "create new task",
      });

      task = {
        ...task,
        taskType: BoardRowType.task,
      }
    }

    dispatch(addNewTaskThunk(task))
    setnewTaskText('')
  }






  async function createMultipleTasksFromText(newTaskText: string) {
    const itemsList: string[] = newTaskText.split('\n').filter(e => e !== '')
    if (itemsList.length <= 1) {
      return;
    }

    itemsList.forEach((text) => {
      if (text && text !== '' && text !== '\n' && text !== '\r') {

        let task: Task = {
          text,
          boardId,
          groupId,
        }

        if (IsNewSubTask) {
          task = {
            ...task,
            taskType: BoardRowType.subTask,
            parentTaskId: parentTaskId,
          }
        }

        dispatch(addNewTaskThunk(task));
        setnewTaskText('')
      }
    });

    (newTaskText as any).b = 3
  }

  const isAutoFocused = () => {
    let isFocused = false
    if (activeNewTaskRow.groupId === groupId && activeNewTaskRow.parentTaskId === parentTaskId) {
      isFocused = true
    }
    return isFocused
  }

  // const color = colorAccent ? colorAccent : '#00c875';
  const color = 'var(--new-item-color)';


  const buildNewRowInput = () => <>
    <span
      // onClick={focusInput}
      style={{ paddingInlineStart: IsNewSubTask ? 4 : 6 }}
      className={classes.plus_container}> <MdAdd color={color}
        style={{
          fontSize: '20px',
          marginInlineEnd: '4px'
        }} />
    </span>
    <textarea
      disabled={disabled}
      data-testid='new-task-input-field'
      id={`footer-${groupId}`}
      onFocus={preparedAutoFocusData}
      rows={1}
      // type="text"
      value={newTaskText}
      onChange={({ target }: any) => {
        if (!onChangeText) {
          setnewTaskText(target.value)
        } else {
          onChangeText(target.value)
        }
        createMultipleTasksFromText(target.value);
      }}
      onBlur={() => {
        if (!onEnter) {
          addNewTask()
        }
        else {
          onEnter()
        }
        dispatch(taskActions.setActiveNewTaskRow({
          groupId: '',
          parentTaskId: '',
        }))
      }}
      //onPaste={handlePaste}
      autoFocus={isAutoFocused()}
      onKeyDown={(event: any) => {
        //event.preventDefault();

        if (event.key === 'Enter') {
          event.preventDefault()
          if (!onEnter) {
            addNewTask()
          }
          else {
            onEnter()
          }
          return false;

        }

      }}
      className={`${classes.new_item_input}`}
      style={{
        height: "20px",
        resize: "none",
        overflow: "hidden",
        whiteSpace: "nowrap",
        backgroundColor: "transparent",
        width: IsNewSubTask ? "120px" : "88px",
        marginInlineStart: IsNewSubTask ? 5 : 0,
        unicodeBidi: 'plaintext',
        paddingInlineEnd:"10px",
        ...outsideStyles
      }}
      placeholder={IsNewSubTask ? "New Sub Item" : "New Item"} />
  </>;

  return (
    <Stack direction={"row"}>
      {isTempId(groupId) ? <CircularProgress sx={{ color, ml: 1 }} size={24} /> : buildNewRowInput()}
    </Stack>
  )
}

export default memo(NewTaskRowInput);