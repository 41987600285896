import BTThemeProvider from "@components/bt-theme-provider";
import RowStack from "@components/common/row-stack/RowStack";
import {
    Stack
} from "@mui/material";
import { BulkView, setActiveBulkView, setActiveTab, setIsImportModalOpen, toggleBulkDashboardDisplay } from "@reducers/CampaignReducer";
import { MODAL, modalActions } from "@reducers/ModalReducer";
import { pickerDialogActions } from "@reducers/PickerDialogReducer";
import { taskActions } from "@reducers/TasksReducer";
import { getRuntimeUrl } from "@services/storage";
import { useAppDispatch } from "@store/hooks";
import { ExtentionMenuIconButton, ExtentionMenuTitle } from "./extention-menu-components";

type Props = {
    handleClose: () => void;
}

export default function CampaignExtensionMenu({ handleClose }: Props) {
    const dispatch = useAppDispatch()

    const newAudienceSVG = getRuntimeUrl("/img/newAudienceSVG.svg")
    const newTemplateSVG = getRuntimeUrl("/img/newTemplateSVG.svg")
    const launchCampaignIconButton = getRuntimeUrl("/img/topmenu/launchCampaignIconButton.svg")
    const manageAudiencesIcon = getRuntimeUrl("/img/manage-audiences.svg")
    const manageTemplatesIcon = getRuntimeUrl("/img/manage-templates.svg")
    const manageCampaignsIcon = getRuntimeUrl("/img/manage-campaigns.svg")


    const toggleBulkPanel = (campaignView?: BulkView) => {
        dispatch(taskActions.setIsTasksPanelOpen(false));
        if (campaignView) {
            dispatch(setActiveBulkView(campaignView))
            if (campaignView === 'newCampaignView') {
                dispatch(setActiveTab('audiences'))
            }
        }
        dispatch(toggleBulkDashboardDisplay());
        handleClose()
    };

    return (
        <BTThemeProvider>
            <Stack sx={{
                backgroundColor: 'var(--default-background)',
                direction: 'ltr',
            }}>

                <ExtentionMenuTitle text='CREATE' />

                <RowStack>

                    <ExtentionMenuIconButton
                        svg={launchCampaignIconButton}
                        label='New Campaign'
                        onClick={() => { toggleBulkPanel('newCampaignView') }}
                    />

                    <ExtentionMenuIconButton
                        svg={newAudienceSVG}
                        label='New audience'
                        onClick={() => {
                            dispatch(modalActions.setComponentToRender(MODAL.IMPORT_EXCEL_TO_BLUETICKS));
                            dispatch(pickerDialogActions.closePickerDialog())
                            dispatch(modalActions.setOpenedby('campaign'));
                            dispatch(setIsImportModalOpen(true))

                            dispatch(taskActions.setIsTasksPanelOpen(false));
                            dispatch(setActiveTab("manageAudiences"))
                            dispatch(setActiveBulkView('campaignManagementView'))
                            dispatch(toggleBulkDashboardDisplay());
                            handleClose()
                        }}

                    />
                    <ExtentionMenuIconButton
                        svg={newTemplateSVG}
                        label='New template'
                        onClick={() => { toggleBulkPanel('campaignNewTemplateViewFromWhatsappView') }}
                    />
                </RowStack>

                <ExtentionMenuTitle text='MANAGE' />

                <RowStack>

                    <ExtentionMenuIconButton
                        svg={manageCampaignsIcon}
                        label='Manage campaigns'
                        onClick={() => {
                            dispatch(taskActions.setIsTasksPanelOpen(false));
                            dispatch(setActiveTab("manageCampaigns"))
                            dispatch(setActiveBulkView('campaignManagementView'))
                            dispatch(toggleBulkDashboardDisplay());
                            handleClose()
                        }}
                    />

                    <ExtentionMenuIconButton
                        svg={manageAudiencesIcon}
                        label='Manage audiences'
                        onClick={() => {
                            dispatch(taskActions.setIsTasksPanelOpen(false));
                            dispatch(setActiveTab("manageAudiences"))
                            dispatch(setActiveBulkView('campaignManagementView'))
                            dispatch(toggleBulkDashboardDisplay());
                            handleClose()
                        }}

                    />
                    <ExtentionMenuIconButton
                        svg={manageTemplatesIcon}
                        label='Manage templates'
                        onClick={() => {
                            dispatch(taskActions.setIsTasksPanelOpen(false));
                            dispatch(setActiveTab("manageTemplates"))
                            dispatch(setActiveBulkView('campaignManagementView'))
                            dispatch(toggleBulkDashboardDisplay());
                            handleClose()
                        }}
                    />
                </RowStack>

            </Stack>
        </BTThemeProvider>
    )
}

