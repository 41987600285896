import { Box, Button, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-google-charts';
import { TransitionData, useGetNextStepMutation, useInitialStepMutation } from '@reducers/backend-api/backend-api';

const events = [
    'Schedule-open dialog',
    'bulk-templates',
    'bulk-campaigns',
    'bulk-bulk-new-list',
    'bulk-bulk-campaign-launched',
    'access-login-success'
]

export interface ChartSelection {
    name: string;
}

const AnalyticsView = () => {
    const [currentEvent, setCurrentEvent] = useState<string>(events[0]);
    const [sessionId, setSessionId] = useState<string | null>(null);
    const [transitionData, setTransisionData] = useState<TransitionData[]>([]);
    const [initialStep, { data: initialStepData }] = useInitialStepMutation();
    const [getNextStep, { data: nextStepData }] = useGetNextStepMutation();



    const _chartData = useMemo(() => transitionData
        .map((data, step) => Object.entries(data.transitions).map(([key, value]) => [`${data.fromEvent}-${step}`, `${key}-${step + 1}`, value]))
        .flat() as number[][], [transitionData])

    // Find min and max values
    const min_value = useMemo(() => Math.min(..._chartData.map((item) => item[2])), [_chartData]);
    const max_value = useMemo(() => Math.max(..._chartData.map((item) => item[2])), [_chartData]);

    // Normalize chartData
    const chartData = useMemo(() => _chartData.map((item) => {
        const logValue = Math.log10(item[2] - min_value + 1);
        const logMin = Math.log10(min_value - min_value + 1);
        const logMax = Math.log10(max_value - min_value + 1);
        const normalizedValue = Math.round(((logValue - logMin) / (logMax - logMin)) * 10);

        return [item[0], item[1], item[2]]
    }), [_chartData, max_value, min_value]);


    console.log(chartData)

    const processTransitionData = useCallback((data: TransitionData) => {
        setSessionId(data.sessionId);
        setTransisionData((prevData) => [...prevData, data])
    }, []);

    useEffect(() => {
        if (initialStepData) {
            // Pass stepNumber directly to the processTransitionData function
            processTransitionData(initialStepData);
        }
    }, [initialStepData, processTransitionData]);

    useEffect(() => {
        if (nextStepData) {
            processTransitionData(nextStepData);
        }
    }, [nextStepData, processTransitionData]);

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        setCurrentEvent(event.target.value as string);
    };

    const handleInitialStep = () => {
        initialStep({ cmd: 'initialStep', event: currentEvent });
    };

    const handleNextStep = (eventWithStep: string) => {
        if (sessionId) {
            const regex = /^(.*)-(\d+)$/;
            const matches = eventWithStep.match(regex);
            const event = matches ? matches[1] : null;
            getNextStep({ cmd: 'getNextStep', sessionId, event });
        }
    };

    const handleReset = () => {
        setTransisionData([]);
        setSessionId(null);
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <FormControl variant="outlined">
                    <Select value={currentEvent} onChange={handleSelectChange}>
                        {events.map((event) => (
                            <MenuItem key={event} value={event}>
                                {event}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={handleInitialStep}>
                    Load Initial Step
                </Button>
                <Button variant="contained" onClick={handleReset}>
                    Reset
                </Button>
            </Box>
            {transitionData.length > 0 && <Chart
                chartType="Sankey"
                loader={<div>Loading Chart</div>}
                data={[['From', 'To', 'Weight'], ...chartData]}
                options={{
                    sankey: {
                        node: {
                            colors: ['#a6cee3', '#b2df8a', '#fb9a99', '#fdbf6f', '#cab2d6', '#ffff99'],
                            interactivity: true,
                        },
                        link: {
                            colorMode: 'gradient',
                            colors: ['#a6cee3', '#b2df8a', '#fb9a99', '#fdbf6f', '#cab2d6', '#ffff99'],
                            label: {
                                color: '#000',
                                fontSize: 14,
                                bold: true,
                            },
                        },
                    },
                }}
                chartEvents={[
                    {
                        eventName: 'select',
                        callback: ({ chartWrapper }) => {
                            const selection: ChartSelection[] = chartWrapper.getChart().getSelection() as ChartSelection[];
                            if (selection.length > 0) {
                                const selectedItem = selection[0];
                                if (selectedItem && selectedItem.name) {
                                    const selectedNode = selectedItem.name;
                                    handleNextStep(selectedNode);
                                }
                            }
                        },
                    },
                ]}
                width="100%"
                height="600px"
            />}
        </Box>
    );
};

export default AnalyticsView;

