import { BoardView } from "@models/board-views";
import { ThunkAppDispatch } from "@store/hooks";
import { RootState } from "@store/index";
import { taskActions } from "./TasksReducer";

import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { btWaCommRequest } from "./backend-api/backend-api";
import { isExtensionContext } from "@services/detect-context";







// export interface GetWAContactsDataThunkResponse {
//     requestType: 'profilePicURL' | 'contactMap';
//     profilePicURL?: string;
//     contactMap?: any;
// }

export interface GetWAContactsDataunkThunkParams {
    waId: string;
}

export const getWAContactsDataThunk = createAsyncThunk<
    any,
    GetWAContactsDataunkThunkParams,
    { state: RootState }
>(
    "campaigns/getBoardEntitiesForImportModal",
    async (getWAContactsDataunkThunkParams: GetWAContactsDataunkThunkParams, thunkAPI) => {
        const { waId } = getWAContactsDataunkThunkParams;
        if (isExtensionContext()) {
            return
        }

        const state: RootState = thunkAPI.getState();
        const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;

        const profilePicURL = state?.CalendarReducer?.contactPicMap?.[waId];
        if (!profilePicURL) {
            dispatch(btWaCommRequest.initiate(waId));
        }

    })


