import { fetchArchivedOrDeletedData } from '@reducers/TaskThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Virtuoso } from 'react-virtuoso';
import ArchivedOrDeletedDataRow from './archived-or-deleted-data-row';
import { ArchivedOrDeletedSkeletonLoader } from './archived-or-deleted-skeleton-loader';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';

const StyledVirtuoso = styled(Virtuoso)`
  height: 500px;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    borderRadius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    backgroundColor: darkgrey;
    borderRadius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    backgroundColor: grey;
  }
`;


export const ArchivedOrDeletedDataTable= () => {
    const dispatch = useAppDispatch();

    const archivedOrDeletedData = useAppSelector((state) => state.RecycleBinReducer.archivedOrDeletedData);

    const archivedOrDeletedIsLoading = useAppSelector((state) => state.RecycleBinReducer.archivedOrDeletedIsLoading);
    console.log('is render')
    const searchText = useAppSelector((state) => state.RecycleBinReducer.searchText);
    const filters = useAppSelector(state => state.RecycleBinReducer.filters);
    const currentTab = useAppSelector((state) => state.RecycleBinReducer.currentTab);

    const loadMore = () => {
     //  if (archivedOrDeletedData.length < 40) return;
       
       const lastItem = archivedOrDeletedData[archivedOrDeletedData.length - 1];

       dispatch(fetchArchivedOrDeletedData({
           mode: currentTab,
           itemId: lastItem?._id ?? "",
           itemUpdatedAt: lastItem?.updatedAt.toString() ?? "",
           searchQuery: searchText,
           ...filters,
           showLoader: false
       }));

   };
    return (
        <div style={{ maxHeight: '800px', overflow: 'auto' }}>
            <Stack direction="row" py={2} style={{ borderBottom: '1px solid #ccc', fontSize: "14px" }}>
                <div style={{ flex: 1 }}><strong></strong></div>
                <div style={{ flex: 5 }}><strong>Name</strong></div>
                <div style={{ flex: 1 }}><strong>Type</strong></div>
                <div style={{ flex: 2 }}><strong>{currentTab === "Trash" ? "Deleted" : "Archived"} From</strong></div>
                <div style={{ flex: 2 }}><strong> Date</strong></div>
                <div style={{ flex: 2 }}><strong>{currentTab === "Trash" ? "Deleted" : "Archived"} by</strong></div>
                <div style={{ flex: 1 }}><strong>Action</strong></div>
            </Stack>

            {
                archivedOrDeletedIsLoading ? (
                    <ArchivedOrDeletedSkeletonLoader />
                ) : archivedOrDeletedData.length === 0 ? (
                    <Stack alignItems={"center"} justifyContent={"center"} style={{ height: 500 }}>
                        <img height="300" src="/img/no-result-found.png" alt="no results"/>

                        <Typography fontWeight={700}>No results found, check your filters</Typography>
                        </Stack>
                ) : (
                    <Virtuoso
                    style={{
                        height: 500, 
                    }}
                        data={archivedOrDeletedData}
                        totalCount={archivedOrDeletedData.length}
                        endReached={loadMore}
                        increaseViewportBy={2000}
                        itemContent={(index) => {
                            const item = archivedOrDeletedData[index];
                            return <ArchivedOrDeletedDataRow item={item}  />;
                        }}
                    />
                )
            }
        </div>
    );
}

