import { Tooltip } from "@mui/material";
import { useAppSelector } from "@store/hooks";
import { ReactElement, RefAttributes, cloneElement, isValidElement, useLayoutEffect, useRef, useState } from "react";

// Create an interface for these:
// { children, value,styling = {}, onBlur }
interface OverflowTooltipProps {
  children: ReactElement & RefAttributes<any>;
  value?: any;
  styling?: any;
  onBlur?: any;
}


export default function OverflowTooltip({ children, value, styling = {}, onBlur }: OverflowTooltipProps) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const textRef = useRef(null);
  const resizing = useAppSelector((state) => state.BoardsReducer.resizing);

  const checkOverflow = () => {
    const el = textRef.current;
    if (el) {
      setIsOverflowing(el.scrollWidth > el.clientWidth);
    }
  };

  useLayoutEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [value, resizing]);


  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    setIsFocused(false);
    checkOverflow();
    if (onBlur) onBlur();
  };

  let childElement = isValidElement(children) ? cloneElement(children, { ref: textRef, onFocus: handleFocus, onBlur: handleBlur }) : children;

  return (
    <Tooltip componentsProps={{tooltip: {sx:{ unicodeBidi: 'plaintext'}}}} title={!isFocused && isOverflowing ? value : ""} placement="top" arrow disableInteractive>
      {childElement}
    </Tooltip>

  );
}
