
import { Task } from '@models/task'
import { TasksGroup } from '@models/task-group'
import { Box, CircularProgress } from '@mui/material'
import { getSelectedBoardId } from '@reducers/BoardsSelectors'
import { bulkActions, getBulkTaskList } from '@reducers/BulkActionsReducer'
import { boardsSelectByQuery, DBTaskgroupsThunks, DBTasksThunks, taskGroupsSelectByQuery, tasksSelectors } from '@reducers/DBServiceReducers'
import { mainSliceActions } from '@reducers/MainReducer'
import { taskActions } from '@reducers/TasksReducer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import classes from './move-to-menu.module.scss'
import MoveTaskToGroup from './move-task-to-group'
import MoveTaskToBoard from './move-task-to-board'
import MoveGroupToBoardSelector from './move-group-to-board'


export default function MoveToDialog() {

    const groupIsMoving = useAppSelector((state) => state.BulkActionsReducer.groupIsMoving)
    const bulkTasksList = useAppSelector(getBulkTaskList)
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const move_to = useAppSelector((state) => state.BulkActionsReducer.move_to)
    const activeTaskid = useAppSelector((state) => state.TasksReducer.menuActiveTaskId)

    return (
        <Box style={{ maxWidth: 'auto', height: 'auto' }}>
            <Box className={classes.mapped_items}>
                {move_to === "group" ? (
                    <MoveTaskToGroup bulkTasksList={bulkTasksList} activeTaskid={activeTaskid} boardId={boardId} />
                ) : !groupIsMoving ? (
                    <MoveTaskToBoard bulkTasksList={bulkTasksList} activeTaskid={activeTaskid} boardId={boardId} />
                ) : (
                    <MoveGroupToBoardSelector/>
                )}
            </Box>
        </Box>
    );
}


