import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { AppThunkDispatch, RootState } from "./index";
import { useEffect, useState } from "react";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = (selector) => useSelector(selector, isEqual);

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
