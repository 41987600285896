import { logsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { LogStack } from '../../ExtraDataPanel';

type Props = { logId: string; }

export default function TaskDragToNewGroupLog({ logId, }: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))
    return (
        <LogStack direction='row'>
            {/* <div> */}
            {log.actionType}&nbsp;
            <b>{log.actionOnEntityData}</b>{` `}
            &nbsp;to group&nbsp;
            <b>{log.newValue.groupName}</b>{` `}
            {/* </div> */}
        </LogStack>
    )
}