import { AccountPage } from "@components/account-page/account-page";
import BTThemeProvider from "@components/bt-theme-provider";
import { useRouter } from "@reducers/hooks/use-router";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import PlanSelection from "../../../components/plan-selection/plan-selection";
import { SideBarItem } from "../../../components/sidebar/sidebar";
import { User } from "../../../models/user";
import classes from "../account-view/account-view.module.scss";
import { openPaymentDialogThunk } from "@reducers/PaymentFormThunks";
import { selectCurrentWorkspace } from "@reducers/UserSelectors";
import { Stack } from "@mui/system";
import BTButton from "@components/bt-button/bt-button";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";
import { DBSubscriptionThunks } from "@reducers/DBServiceReducers";

export interface AccountSubscriptionViewProps {

}

export interface AccountSubscriptionViewState {
    user?: User | null | undefined;
    loading: boolean;
}



const AccountSubscriptionView = () => {
    const { t } = useTranslation(namespace);
    const router = useRouter();
    const dispatch = useAppDispatch()
    const currentWorkspace = useAppSelector(selectCurrentWorkspace);

    useEffect(() => {
        if (router.query.purchaseSuccess !== undefined) {
            dispatch(openPaymentDialogThunk({ paymentSuccess: true }))
            router.navigate({
                pathname: router.pathname
            })
        }
    }, [dispatch, router, router.query.purchaseSuccess]);

    useEffect(() => {
        document.title = t('accountSubscriptionView.buySubscription');
    }, [t]);


    useEffect(() => {
        dispatch(DBSubscriptionThunks.find({}));
    }, [])

    const noWorkspaceView = () => {
        return <Stack sx={{
            width: 1,
            height: 1
        }}
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <span>{t('accountSubscriptionView.mustSelectWorkspace')}</span>
            <BTButton onClick={() => router.navigate('/account-workspace')}>{t('accountSubscriptionView.selectWorkspace')}</BTButton>
        </Stack>
    }

    const subscriptionView = () => {
        return <div className={classes.pageContainer}>
            <div className={classes.titleContainer}>
                <h4 className={classes.pageTitle}>{t('accountSubscriptionView.buySubscription')}</h4>
            </div>
            <div className={classes.accountSettingBody}>
                {currentWorkspace ? <PlanSelection /> : noWorkspaceView()}
            </div>
        </div>;
    }

    return (<BTThemeProvider>
        <AccountPage page={subscriptionView()} active={SideBarItem.Subscriptions}></AccountPage>
    </BTThemeProvider>);
}

export default AccountSubscriptionView;