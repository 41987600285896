import PromptDeleteAction from '@components/prompt-delete-action/prompt-delete-action';
import { Column, Gantt, Icons, OnChangeTasks, Task as GanttTask, TitleColumn, ViewMode } from '@components/reactgantt';
import { DateStartEndColumn } from '@components/reactgantt/components/task-list/columns/date-start-end-column';
import { PersonColumn } from '@components/reactgantt/components/task-list/columns/person-column';
import { Gantt as typeGantt } from "@models/gantt";
import { DBGanttThunks, ganttSelectOneFieldByQuery } from "@reducers/DBServiceReducers";
import { pickerDialogActions } from '@reducers/PickerDialogReducer';
import { getGanttTasks } from '@reducers/TaskSelectors';
import { moveTaskGroupByDays, updateCellValueThunk, updateGanttOrderTasksByDragableThunk } from '@reducers/TaskThunks';
import { UndoRedoAction } from '@reducers/UndoRedoReducer';
import { doActionThunk } from '@reducers/UndoRedoThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";


type Props = {
  gantt: typeGantt;
  columnId: string;
  today: any;
  openPopover: any;
}

export default function BTGantt({ gantt, columnId, today, openPopover }: Props) {
  const ganttTasks: GanttTask[] = useAppSelector((state) => getGanttTasks(state, columnId), isEqual);
  const currentDate = new Date();
  const [tasks, setTasks] = useState<GanttTask[]>(ganttTasks);
  const dispatch = useAppDispatch()
  const [arrowTasks, setArrowTasks] = useState<any>()
  const [deletePromptOpen, setDeletePromptOpen] = useState(false);
  const [fixDependency, setFixDependency] = useState(false);
  const [actionRelation, setActionRelation] = useState<any>();
  const viewMode = useAppSelector(state => ganttSelectOneFieldByQuery(state.DBGanttReducer, { _id: gantt?._id },
    'viewMode'));

  const firstTask = new Date(tasks[0]?.start)
  const x = window.innerHeight - 200

  const icons: Icons = {
    renderAddIcon: () => <>➕</>,
    renderClosedIcon: () => <>📁</>,
    renderDeleteIcon: () => <>➖</>,
    renderEditIcon: () => <>🗃</>,
    renderNoChildrenIcon: () => <></>,
    renderOpenedIcon: () => <>📂</>,
  };

  const columns: readonly Column[] = [
    {
      component: TitleColumn,
      width: 180,
      title: "Title",
    },
    {
      component: DateStartEndColumn,
      width: 200,
      title: "Task Duration",
    },
    {
      component: PersonColumn,
      width: 100,
      title: "Owner",
    },
  ];
  useEffect(() => {
    // Here render the component because the bag when change view mode
    // setTasks(ganttTasks)
    // openPopover(true)

  }, [viewMode])


  const handleTaskChange = (task: GanttTask) => {
    function addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    }
    // progressPercentage
    let value = {
      startDate: new Date(task?.start),
      endDate: new Date(task?.end),
    }
    if (viewMode === 'Year' || viewMode === 'Month' || viewMode === 'Week') {
      const sameTask = ganttTasks.find(t => t.id === task.id)
      if (sameTask.start.getTime() !== task.start.getTime() && sameTask.end.getTime() !== task.end.getTime()) {
        const days = (sameTask.end.getTime() - sameTask.start.getTime()) / (1000 * 3600 * 24)


        value = {
          startDate: new Date(task?.start),
          endDate: new Date(addDays(task?.start, days)),
        }
      }
    }

    if (task.type === 'project') {
      console.log(task.start)
      const projectTask = tasks.find(projTask => projTask.id === task.id)
      const timeDiff = task.start.getTime() - projectTask.start.getTime();
      const daysDiff = timeDiff / (1000 * 3600 * 24);
      const days = Math.round(daysDiff)

      dispatch(moveTaskGroupByDays({
        groupId: task?.id,
        days,
        columnId,
      }));

    }
    else {
      dispatch(updateCellValueThunk({
        taskId: task?.id,
        columnId,
        data: {
          value
        }
      }));
    }
  };



  const handleTasksRelation = (taskFrom: any, taskTo: any, isOneDescendant) => {
    console.log(taskFrom)
    const dependenciesList = [];
    const taskToid = taskTo[0].id;
    const ganttitem = gantt.tasksDependencies
    console.log(taskTo)

    // task from id
    const sourceId = taskFrom[0].id;
    // task from indicate
    const sourceTarget = taskFrom[1]
    // task to indicate
    const ownTarget = taskTo[1]

    const dependenciesTask = {
      sourceId,
      sourceTarget,
      ownTarget
    }

    console.log(dependenciesTask)
    if ((sourceId! == undefined && sourceTarget !== undefined && ownTarget !== undefined) || taskTo[0].id !== sourceId) {

      const taskToAddDependecies = tasks.filter((task) => task.id === taskToid)
      taskToAddDependecies[0].dependencies[0] = dependenciesTask;
      const newTasks = [...tasks]
      setTasks(newTasks)
      dependenciesList.push(dependenciesTask)
      dispatch(
        DBGanttThunks.patch({
          entity: {
            ...gantt,
            tasksDependencies: {
              ...gantt?.tasksDependencies,

              [taskToid]: dependenciesTask
            }
          }
        })
      )
    } else {
      console.warn('gantt dependency error')
    }

  }
  useEffect(() => {
    setTasks(ganttTasks)

  }, [ganttTasks])


  const onChangeHandle = useCallback<OnChangeTasks>((nextTasks, action) => {

    console.log('action.type')
    console.log(action.type)
    console.log(action)

    switch (action.type) {
      case 'fix_dependency_position':

        let task: any
        const taskHasClicked = action.payload.task;
        const taskToDependent = action.payload.dependentTasks;


        const dependetToTaskHasClicked = taskToDependent.find(task => task?.dependencies[0].sourceId === taskHasClicked.id)
        console.log(dependetToTaskHasClicked)

        if (taskHasClicked?.id === dependetToTaskHasClicked?.dependencies[0].sourceId) {
          const task: any = taskHasClicked;

        }

        break;
      case 'relation_change':

        let newTsaks2: any[] = [...nextTasks]
        console.log(newTsaks2)
        setTasks(newTsaks2)
        break
      case 'delete_relation_open_menu':
        let deleteArrowTasks: any[] = [...nextTasks]
        setDeletePromptOpen(true);
        setActionRelation(action)
        setArrowTasks(deleteArrowTasks)
        break;
      case "delete_relation":
        // const taskSelected = action.payload.taskTo
        // if (window.confirm(`Do yo want to remove relation between ${action.payload.taskFrom.name} and ${action.payload.taskTo.name}?`)) {
        //   let newTasks: any[] = [...nextTasks]
        //   setTasks(newTasks)

        // }
        break;
      case 'date_change':
        // display the new changes in the state

        let newTasksChanges: any[] = [...nextTasks]
        setTasks(newTasksChanges)

        break;
      case 'move_task_after':

        const tasksOrder = []
        const newTasks: any[] = [...nextTasks];
        newTasks.map((task, index) => {
          const taskOrder = {
            [task.id]: index
          }
          tasksOrder.push(taskOrder)
        })


        dispatch(
          DBGanttThunks.patch({
            entity: {
              _id: gantt._id,
              tasksOrder: [...tasksOrder]
            }
          })
        )


        dispatch(updateGanttOrderTasksByDragableThunk({ originalTaskList: tasks, newTaskList: newTasks, gantt: gantt }));
        setTasks(newTasks)
        break;
      case 'move_task_inside':
        break;
      default:
        let defaultTasks: any[] = [...nextTasks]
        setTasks(defaultTasks)
    }
  }, []);

  const handleColumnWidth = () => {
    switch (viewMode) {
      case 'Day':
        return 55
        break;
      case 'Week':
        return 120
        break;
      case 'Month':
        return 150
        break;
      case 'Year':
        return 600
        break;
    }
  }


  const handleFixDependency = (task) => {
    const value = {
      startDate: new Date(task?.start),
      endDate: new Date(task?.end),
    }
    dispatch(
      DBGanttThunks.patch({
        entity: {
          ...gantt,
          fixDependency: true
        }
      })
    )


    dispatch(updateCellValueThunk({
      taskId: task?.id,
      columnId,
      data: {
        value
      }
    }));

    dispatch(
      DBGanttThunks.patch({
        entity: {
          ...gantt,
          fixDependency: false
        }
      })
    )


    // dispatch(
    //   DBGanttThunks.patch({
    //     entity: {
    //       ...gantt,
    //       fixDependency: false
    //     }
    //   })
    // )

  }

  const handleDeleteConfirmationClosed = (shouldDelete?: boolean) => {
    if (shouldDelete === true) {


      const { ...cloneDependencies } = gantt.tasksDependencies
      const deleteId = actionRelation?.payload?.taskTo.id
      delete cloneDependencies[deleteId]


      dispatch(
        DBGanttThunks.patch({
          entity: {
            ...gantt,
            tasksDependencies: cloneDependencies
          }
        })
      )
      setTasks(arrowTasks)

    }
    const action: UndoRedoAction<string[], string> = {
      type: 'delete-arrow-relation',
      data: [gantt._id.toString()],
      deletedOrArchived:"deleted"
    }
    dispatch(doActionThunk({ action }),)
    setDeletePromptOpen(false)
    // dispatch(pickerDialogActions.closePickerDialog())
  }

  const handlePreStepsCount = () => {
    switch (viewMode) {
      case 'Day':
        return 45
        break;
      case 'Week':
        return 20
        break;
      case 'Month':
        return 2
        break;
      case 'Year':
        return 2
        break;
    }
  }
  return (
    <div>


      {ganttTasks.length > 0 ?

        <DndProvider backend={HTML5Backend}>
          <Gantt
            //  onChangeTasks={handleTaskChange}
            isShowDependencyWarnings={true}
            viewDate={today ?? firstTask}
            preStepsCount={handlePreStepsCount()}

            colors={{ todayColor: '#000' }}
            distances={{ columnWidth: handleColumnWidth(),barCornerRadius:10,ganttHeight: x}}
            viewMode={gantt.viewMode ?? ViewMode.Day}
            
            OnFirstEndTaskChange={handleTaskChange}
            tasks={ganttTasks}
            icons={icons}
            columns={columns}
            onFixDependencyPosition={handleFixDependency}
            isShowTaskNumbers={false}
            onChangeTasks={onChangeHandle}
            onRelationChange={handleTasksRelation}
          // onClick={(e)=>openPopover2}
          // onTaskClick={(task)=>{console.log(task)}}
          />
        </DndProvider>
        : <h1>there is no value in timeline columns</h1>}

      {<PromptDeleteAction title={`Delete relation between ${actionRelation?.payload?.taskFrom.name} to ${actionRelation?.payload?.taskTo.name} ?`} handleClose={handleDeleteConfirmationClosed} open={deletePromptOpen} />}
      {<PromptDeleteAction title={`do you want to fix all dependency? ?`} handleClose={handleFixDependency} open={fixDependency} />}
    </div>
  )
}