import { DefaultComponentProps, OverridableTypeMap } from "@mui/material/OverridableComponent";
import { Box } from "@mui/system";
import React, { CSSProperties, useRef, useState } from "react";
import { FiCheck, FiEdit2 } from "react-icons/fi";
import classes from "./inline_edit_text.module.scss";

export interface Props {
  key?: string;
  text: string;
  onValueUpdate: (text: string) => void;
  hasBorder?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  className?: string;
}

export interface InlineEditTextState {
  editing: boolean;
  internalValue?: string;

}


export default function 
DIVContentEditable({
  disabled,
  text,
  onValueUpdate,
  placeholder,
  style,
  className,
  hasBorder = true,
  ...restOfProps }: Props & DefaultComponentProps<OverridableTypeMap>) {
  const inputRef: any = useRef();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string | undefined>();

  function updateText(e) {
    setIsEditing(false);
    if (value === undefined) return;

    onValueUpdate(inputRef.current.value)
    setValue(undefined)

    e?.target?.blur();
  }

  // function toggleEditMode() {
  //   setIsEditing(!isEditing);
  // }

  function handleTextChange({ target }: any) {
    const value = target.value ? target.value : undefined;
    setValue(value);
  }

  return (
    <Box
      className={[classes.editableContainer, className].join(' ')}
      style={{
        border: isEditing && hasBorder ? "1px dashed var(--task-value-hover-color)" : "1px dashed transparent",
        ...style
      }}
      {...restOfProps}
      >

      <input
        value={(value || text) ?? ''}
        ref={inputRef}
        onClick={() => { setIsEditing(true) }}
        onBlur={updateText}
        onChange={handleTextChange}
        autoFocus={isEditing}
        placeholder={placeholder}
        className={classes.HTMLInput}
        onKeyPress={(e) => e.key === "Enter" && updateText(e)} />


      {/* {!disabled && <div className={classes.buttonContainer}>
        {isEditing ? (
          <button className={classes.icon} onClick={updateText}
            style={isEditing ? { color: "var(--task-value-hover-color)" } : {}}
          >
            <FiCheck />
          </button>
        ) : (
          // <button className={classes.icon} onClick={toggleEditMode}>
          //   <FiEdit2 />
          // </button>
        )}
      </div>} */}
    </Box>
  );
}

