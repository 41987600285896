import ContactAvatar from "@components/bulk-dashboard/contact-table/contact-avatar";
import { UserMessage } from "@models/user-message";
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { SyntheticEvent, useCallback } from "react";
import { EventProps } from "react-big-calendar";
import { EventPL } from "./message-calendar";
import classes from "./message-calendar.module.scss";
import { useAppDispatch, useAppSelector, useAppThunkDispatch } from "@store/hooks";
import { addScheduledMessageButtonActions } from "@reducers/AddScheduledMessageButtonReducer";
import { accessFormPopupActions } from "@reducers/AccessFormPopupReducer";
import { useSelector } from "react-redux";
import { selectUserObject } from "@reducers/UserSelectors";
import { MaybeUser } from "interfaces";
import { setHoveredDate } from "@reducers/WhatsAppReducer";

interface P {
  eventProps: EventProps<UserMessage>;
  onRightClick: (pl: EventPL) => void;
}

const handleRightClick = (e: SyntheticEvent, props: P) => {
  e.preventDefault();
  const { left, top, bottom, right } = e.currentTarget.getBoundingClientRect();
  props.onRightClick({ ...props.eventProps.event, left, top, bottom, right });
};

export const CustomMonthEvent = (props: P) => {
  const dispatch = useAppDispatch()


  const { title, event } = props.eventProps;



  const handleMouseEnter = () => {
    dispatch(setHoveredDate(moment(props.eventProps?.event?.dueDate)))
  };
  const handleMouseLeave = () => {
    dispatch(setHoveredDate(undefined))
  };



  return (
    <div className={classes.wrapper}
      onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
    >
      <Box
        className={classes.monthEvent}
        onContextMenu={(e: SyntheticEvent) => handleRightClick(e, props)}
      >
        <div style={{ width: '25px' }}>
          <ContactAvatar
            contactId={props.eventProps?.event?.contactList[0]?.id}
            size={25}
            sx={{
              m: '0px 0px 0px 2px',
            }}
          />
        </div>
        <div style={{ width: '52px' }} className={classes.time}>
          {moment(event.dueDate).format("hh:mma")}
        </div>
        <div style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</div>
        {props.eventProps?.event?.asset?.url &&
          <img
            alt={props.eventProps?.event?.asset?.url}
            src={props.eventProps?.event?.asset?.url}
            style={{
              width: '27px',
              height: '27px',
              objectFit: 'cover',
              objectPosition: 'center',
              marginInlineEnd: '3px',
            }} />
        }
      </Box>
    </div>
  );
};

export const CustomEvent = (props: P) => {
  const currentChat = useAppSelector(
    (state) => state.WhatsAppReducer.currentChat
  );
  const dispatch = useAppThunkDispatch();
  const user: MaybeUser = useSelector(selectUserObject);

  const { title, event } = props.eventProps;


  const isFirstHalfHour = moment(props.eventProps.event?.dueDate).minutes() <= 29 && moment(props.eventProps.event?.dueDate).minutes() >= 0;


  const handleNewMessage = useCallback(
    (dueDate = moment()) => {
      if (!user) {
        dispatch(accessFormPopupActions.setStatus("Signup"));
        return;
      }

      dispatch(
        addScheduledMessageButtonActions.editMessage({
          dueDate: dueDate.startOf("minute").toISOString(),
          message: "",
          isRecurring: false,
          cancelIfReceived: false,
          contactList: [
            {
              ...currentChat,
              id: currentChat?.id?._serialized,
              name: currentChat?.name ?? currentChat?.displayName,
            },
          ],
          calendarMessage: true,
        } as UserMessage)
      );
    },
    [currentChat, dispatch, user]
  );

  const onSelectSlot = (slotInfo) => {
    let slotStart = moment(slotInfo);
    handleNewMessage(slotStart);
  };


  return (
    <div className={classes.customEventWrapper}>
      <Box
        className={classes.customEvent}
        onContextMenu={(e: SyntheticEvent) => handleRightClick(e, props)}
        sx={{ backgroundColor: event.messageColor, }}
      >
        <div className={classes.time}>
          {moment(event.dueDate).format("hh:mma")}
        </div>
        <div style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</div>
      </Box >
      <IconButton className={classes.customEventAddButton} sx={{
        top: isFirstHalfHour ? '-2px' : '-1px',
      }} onClick={(e) => {
        e.stopPropagation()
        onSelectSlot(props?.eventProps?.event?.dueDate)
      }}>
        <AddIcon sx={{ width: "16px" }} />
      </IconButton>
    </div>
  );
};
