
import { MODAL, modalActions } from '@reducers/ModalReducer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { BsArchive, BsArrowRightCircle, BsTrash } from 'react-icons/bs'
import { MdClose, MdOutlineContentCopy, MdOutlineFileUpload } from 'react-icons/md'
import classes from './bulk-actions-dialog.module.scss'

import PromptDeleteAction from '@components/prompt-delete-action/prompt-delete-action'
import { useEffect, useState } from 'react'
import { getBulkTaskList, hasBulkTasks, bulkActions } from '@reducers/BulkActionsReducer'
import { addNewTaskThunk } from '@reducers/TaskThunks'
import { deleteOrArchiveSelectedTasks } from '@reducers/BulkActionThunks'

export default function BulkActionsDialog() {
  const dispatch = useAppDispatch()
  const bulkTasksList = useAppSelector(getBulkTaskList)
  const isMoveTo = useAppSelector((state) => state.BulkActionsReducer.isMoveTo)
  const isBulkActionsDialogOpen = useAppSelector(hasBulkTasks)
  const [open, setOpen] = useState(false);
  const direction = useAppSelector((state) => state.UserReducer.direction);
  const drawerIsOpen = useAppSelector(
    (state) => state.TasksReducer.taskViewSideDrawerOpen
);
  useEffect(() => {
    return () => {
      dispatch(bulkActions.toggleMoveTo(false))
    }
  }, [dispatch])


  // duplicate task/s
  function handleDuplicateItems() {
    bulkTasksList.forEach((task) => {
      const clonedTask = { ...task }
      delete clonedTask._id;
      clonedTask.text = `${clonedTask.text} - (copy)`
      delete clonedTask.order
      dispatch(addNewTaskThunk(clonedTask))
    })
    handleClose()
  }

  // delete task/s
  function handleDelete() {
    setOpen(true);
  }

  const archiveTasks = () => {
    dispatch(deleteOrArchiveSelectedTasks({deletedOrArchived:"archived"}))
  }
  const handleDeleteConfirmationClosed = (shouldDelete?: boolean) => {
    if (shouldDelete === true) {
      dispatch(deleteOrArchiveSelectedTasks({deletedOrArchived:"deleted"}))
    }
    setOpen(false);
  }

  // move to group or board task/s
  function handleMoveTo() {
    dispatch(bulkActions.setBulActionsMoveToId('id'))
    dispatch(bulkActions.toggleMoveTo(!isMoveTo))
  }

  // close the dialog
  function handleClose() {
    dispatch(bulkActions.setBulkTasksMap({}))
    dispatch(bulkActions.setBulActionsMoveToId(''))
    dispatch(bulkActions.toggleMoveTo(false))
    dispatch(bulkActions.setMovingTo(''));

  }


  function openExportModal() {
    if (bulkTasksList.length) {
      dispatch(modalActions.setComponentToRender(MODAL.EXPORT_TO_EXCEL))
    }
  }

  console.log(isBulkActionsDialogOpen)
  if (!isBulkActionsDialogOpen) return null;

  return (
    <div id="bulkTasksOperations" className={classes.fixed_wrapper} style={{
      color: 'var(--primary)',
      width:drawerIsOpen ? "60%" : "84%"
    }}>
      <PromptDeleteAction title={`Delete ${bulkTasksList.length} tasks?`} handleClose={handleDeleteConfirmationClosed} open={open} />
      <div className={classes.dialog_actions_menu}>
        <div style={{
          borderTopLeftRadius: direction === "ltr" ? "9px" : undefined,
          borderBottomLeftRadius: direction === "ltr" ? "9px" : undefined,
          borderBottomRightRadius: direction === "rtl" ? "9px" : undefined,
          borderTopRightRadius: direction === "rtl" ? "9px" : undefined
        }} className={` ${classes.aside_col}  ${classes.aside_left} `}>{bulkTasksList.length}</div>

        <div className={classes.selected_txt}>
          <p>Selected items</p>
        </div>
        <div className={classes.main_col}>

          <div className={classes.bulk_action_btn_wrap}>
            <button onClick={handleDuplicateItems} className={classes.bulk_action_btn}>
              <span>
                <MdOutlineContentCopy size={20} />
              </span>
              Duplicate
            </button>
          </div>

          <div className={classes.bulk_action_btn_wrap}>
            <button onClick={openExportModal} className={classes.bulk_action_btn}>
              <span>
                <MdOutlineFileUpload size={20} />
              </span>
              Export
            </button>
          </div>
          {/* <div className={classes.bulk_action_btn_wrap}>
            <button onClick={archiveTasks} className={classes.bulk_action_btn}>
              <span>
                <BsArchive />
              </span>
              Archive
            </button>
          </div> */}

          <div className={classes.bulk_action_btn_wrap}>
            <button data-testid='bulk-action-dialog-delete-button'
              onClick={handleDelete} className={classes.bulk_action_btn}>
              <span>
                <BsTrash size={20} />
              </span>
              Delete
            </button>
          </div>

          <div className={classes.bulk_action_btn_wrap}>
            <button onClick={handleMoveTo} className={classes.bulk_action_btn}
              id={`bulk-actions-menu-id`}
              style={{ position: 'relative' }}>
              <span>
                <BsArrowRightCircle size={20} />
              </span>
              Move to
            </button>
          </div>
        </div>
        <div style={{
          borderInlineStart: "1px solid var(--border-color)",
          borderTopLeftRadius: direction === "rtl" ? "9px" : undefined,
          borderBottomLeftRadius: direction === "rtl" ? "9px" : undefined,
          borderBottomRightRadius: direction === "ltr" ? "9px" : undefined,
          borderTopRightRadius: direction === "ltr" ? "9px" : undefined
        }} onClick={handleClose} className={`${classes.aside_col} ${classes.aside_right}`}>
          <MdClose size={24} />
        </div>
      </div>
    </div >
  )
}

