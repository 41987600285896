import { Box, Stack } from "@mui/material";
import { DBTasksThunks } from "@reducers/DBServiceReducers";
import { useAppDispatch } from "@store/hooks";
import { useEffect, useState } from "react";
import { TfiSmallcap } from "react-icons/tfi";

const TaskNewRowCellWrapper = ({ taskId, text }) => {
    const [taskText, setTaskText] = useState<string | undefined>();
    const dispatch = useAppDispatch()

    function updateTaskText(e) {
        setTaskText(taskText);
        e?.target?.blur();
        dispatch(DBTasksThunks.patch({
            entity: { _id: taskId, text: taskText }
        }));
    }

    function handleTextChange({ target }: any) {
        setTaskText(target.value ? target.value : undefined);
    }
    useEffect(() => {
        if (taskId) {
            setTaskText(text);
        }
    }, [taskId, text])
    return (
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} sx={{
            backgroundColor: "white",
            margin: "10px",
            padding: "10px",
            borderRadius: "8px",
        }}>
            <Box sx={{ textAlign: "center", fontSize: "13px" }}> <TfiSmallcap style={{ marginRight: "5px" }} />Task name</Box>
            <Box sx={{

                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: "60%",
                border: '1px dashed  #d0d4e4',
                padding: "4px",

            }}>
                <input value={taskText ?? ""} style={{ border: "none" }} onChange={handleTextChange}
                    type="text"
                    onKeyDown={(e) => e.key === "Enter" && updateTaskText(e)}
                    onBlur={(e) => updateTaskText(e)}
                />
            </Box>
        </Stack>
    );
};

export default TaskNewRowCellWrapper;