import { BTCheckbox } from "@components/atoms/bt-checkbox/bt-checkbox";
import BTDropDown from "@components/atoms/drop-down/bt-drop-down";
import BTTextField from "@components/atoms/text-field/bt-text-field";
import BTThemeProvider from "@components/bt-theme-provider";
import { Info } from "@mui/icons-material";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  StaticDatePicker,
  TimePicker
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, {
  useCallback,
  useMemo,
  useRef
} from "react";
import { theme } from "theme";
import { Schedule } from "../../types/interface";
import classes from "./schedule-picker.module.scss";

import { Frequency, Options, RRule } from "rrule";
import {
  extractWeekdayNumbers,
  fetchRule,
  initialRuleOptions
} from "./recurrence-utils";

export interface SchedulePickerProps {
  showRecurrence: boolean;
  showCancelIfReceived: boolean;
  schedule: Schedule;
  handleScheduleChange?: (schedule: Schedule) => void;
}

export const SchedulePicker = (
  props: SchedulePickerProps
) => {
  const ruleOptions = useMemo(() => {
    if (!!props.schedule.rruleset) {
      const rrule = fetchRule(props.schedule.rruleset);
      return rrule.origOptions
    } else {
      return initialRuleOptions(moment.utc(props.schedule.dueDate))
    }
  }, [props.schedule.dueDate, props.schedule.rruleset])

  const ends = useMemo(() => {
    if (ruleOptions.until) {
      return "on";
    } else if (ruleOptions.count) {
      return "after";
    } else {
      return "never";
    }
  }, [ruleOptions.count, ruleOptions.until]);

  const rrule = useMemo(() => new RRule(ruleOptions), [ruleOptions]);
  const inputRef = useRef<HTMLInputElement>(null);
  let dueDate = moment.utc(props.schedule.dueDate);
  const updateRuleOptions = useCallback((options: Partial<Options>) => {
    const rrule = new RRule({
      ...options,
      dtstart: moment.utc(dueDate).startOf('minute').toDate(),
    });
    const rruleset = rrule.toString()

    props.handleScheduleChange({
      ...props.schedule,
      rruleset,
    });
  }, [dueDate, props]);

  const handleSendDateChanged = useCallback((date: moment.Moment) => {
    if (!date?.isValid()) return;

    const rrule = new RRule({
      ...ruleOptions,
      dtstart: moment.utc(date).startOf('minute').toDate(),
    });

    // Convert date to UTC before setting in RRule
    const momentDate = moment.utc(date);
    props.handleScheduleChange({
      ...props.schedule,
      rruleset: rrule.toString(),
      dueDate: momentDate.toISOString(),
    });
  }, [props, ruleOptions]);


  const handleSendTimeChanged = (date) => {
    const momentDate = moment.utc(date);
    if (!momentDate?.isValid()) return;

    const newDueDate = moment(props.schedule.dueDate).set({ hour: moment(date).hours(), minute: moment(date).minutes() }).utc();
    if (!newDueDate.isValid()) return;

    const newDueDateString = newDueDate.toDate().toISOString()

    if (!props.schedule.isRecurring) {
      props.handleScheduleChange({
        ...props.schedule,
        dueDate: newDueDateString
      });
      return; // Exit early
    }

    let newRuleOptions = {
      ...ruleOptions,
      dtstart: moment.utc(newDueDate).startOf('minute').toDate(),
    };

    if (ruleOptions.freq === Frequency.WEEKLY || ruleOptions.freq === Frequency.MONTHLY || ruleOptions.freq === Frequency.YEARLY) {
      newRuleOptions.byhour = [momentDate.hour()];
      newRuleOptions.byminute = [momentDate.minute()];
      newRuleOptions.bysecond = [0];
    }

    const rrule = new RRule(newRuleOptions);

    props.handleScheduleChange({
      ...props.schedule,
      rruleset: rrule.toString(),
      dueDate: newDueDateString
    });
  }


  const handleCancelIfReceivedChange = (event: React.ChangeEvent<any>) => {
    props.handleScheduleChange({
      ...props.schedule,
      rruleset: rrule.toString(),
      cancelIfReceived: event.target.checked,
    });
  };

  const handleIsRecurringChange = () => {
    props.handleScheduleChange({
      ...props.schedule,
      rruleset: rrule.toString(),
      isRecurring: !props.schedule.isRecurring,
    });
  };

  const handleIntervalChange = (event: React.ChangeEvent<any>) => {
    updateRuleOptions({
      ...ruleOptions,
      interval: event.target.value as number,
    });
  };

  const handleFrequencyChange = (event: React.ChangeEvent<any>) => {
    const freq = event.target.value as Frequency;
    const currentDate = new Date();

    // Default options to clear other frequency-specific attributes
    const defaultOptions: Partial<Options> = {
      bysetpos: null,
      bymonth: null,
      bymonthday: null,
      byyearday: null,
      byweekno: null,
      byweekday: null,
      byhour: null,
      byminute: null,
      bysecond: 0
    };

    let newOptions: Partial<Options>;
    switch (freq) {
      case Frequency.YEARLY:
      case Frequency.MONTHLY:
        newOptions = {
          ...defaultOptions,
          freq,
          byhour: dueDate.hour(),
          byminute: dueDate.minute(),
        };
        break;
      case Frequency.WEEKLY:
        newOptions = {
          ...defaultOptions,
          byweekday: RRule[["SU", "MO", "TU", "WE", "TH", "FR", "SA"][currentDate.getDay()]].weekday,
          freq,
          byhour: dueDate.hour(),
          byminute: dueDate.minute(),
        };
        break;

      case Frequency.DAILY:
      case Frequency.HOURLY:
      case Frequency.MINUTELY:
        newOptions = { ...defaultOptions, interval: ruleOptions.interval || 1, freq };
        break;

      default:
        newOptions = { ...defaultOptions, ...ruleOptions };
        break;
    }

    updateRuleOptions(newOptions);
  };



  const handleByweekdayChange = (_, value: number[]) => {
    const updatedWeekdays = extractWeekdayNumbers(value);

    updateRuleOptions({
      ...ruleOptions,
      byweekday: updatedWeekdays,
    });
  };

  return (
    <BTThemeProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack>
          <Stack direction="row">
            <div
              style={{
                marginRight: theme.spacing(2),
              }}
            >
              <StaticDatePicker
                disablePast={!props.schedule.calendarMessage}
                openTo="day"
                value={dueDate ? moment.utc(dueDate).local() : null}
                onChange={handleSendDateChanged}
                sx={{
                  backgroundColor: "transparent",
                }}
                slotProps={{
                  actionBar: {
                    actions: [],

                  },
                  toolbar: {
                    hidden: true,
                  }

                }}
              />
            </div>
            <div className={classes.sendDetailsContainer}>
              <Box
                sx={{
                  width: "100%",
                  mb: theme.spacing(2),
                }}
              >
                <TimePicker
                  label="Send Time"
                  //mask="__:__"
                  ampm={false}
                  inputRef={inputRef}
                  value={moment.utc(dueDate).local()}
                  slotProps={{ textField: { variant: "standard" } }}

                  // DialogProps={{
                  //   PaperComponent: BTPaper,
                  // }}
                  onChange={handleSendTimeChanged}
                />






              </Box>
              <FormGroup>
                {props.showCancelIfReceived && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      control={
                        <BTCheckbox
                          checked={props.schedule?.cancelIfReceived || false}
                          onChange={handleCancelIfReceivedChange}
                          color="primary"
                        />
                      }
                      label="Cancel when a message is received"
                      labelPlacement="end"
                    />
                    <Tooltip
                      PopperProps={{ style: { direction: "ltr" } }}
                      title="Cancel the message if a message is received from the contact after it was scheduled"
                    >
                      <div>
                        <Info></Info>
                      </div>
                    </Tooltip>
                  </div>
                )}
                {props.showRecurrence && (
                  <FormControlLabel
                    control={
                      <BTCheckbox
                        checked={props.schedule.isRecurring}
                        onChange={handleIsRecurringChange}
                        color="primary"
                      />
                    }
                    label="Custom recurrence"
                    labelPlacement="end"
                  />
                )}
                {props.schedule.isRecurring && (
                  <Box className={classes.recurrence}>
                    <div className={classes.every}>
                      <span>Repeat Every</span>
                      <BTTextField
                        className={classes.interval}
                        variant="filled"
                        type="number"
                        inputProps={{ min: 1 }}
                        value={ruleOptions.interval?.toString() || "1"}
                        onChange={handleIntervalChange}
                        sx={{
                          ".MuiFilledInput-input": {
                            paddingTop: "8px",
                            width: "5em",
                          },
                        }}
                      />

                      <BTDropDown
                        className={classes.unit}
                        variant="filled"
                        value={ruleOptions.freq}
                        onChange={handleFrequencyChange}
                        sx={{
                          "&": {
                            width: "fit-content",
                            ".MuiFilledInput-input": {
                              paddingTop: "8px",
                            },
                          },
                        }}
                      >
                        <MenuItem value={Frequency.MINUTELY}>Minute</MenuItem>
                        <MenuItem value={Frequency.HOURLY}>Hour</MenuItem>
                        <MenuItem value={Frequency.DAILY}>Day</MenuItem>
                        <MenuItem value={Frequency.WEEKLY}>Week</MenuItem>
                        <MenuItem value={Frequency.MONTHLY}>Month</MenuItem>
                        <MenuItem value={Frequency.YEARLY}>Year</MenuItem>
                      </BTDropDown>
                    </div>
                    {ruleOptions.freq === Frequency.WEEKLY && (
                      <Box className={classes.weekday}>
                        <div>Repeat on</div>
                        <ToggleButtonGroup
                          onChange={handleByweekdayChange}
                          value={extractWeekdayNumbers(ruleOptions.byweekday)}
                          size="small"
                          sx={{
                            ".MuiToggleButtonGroup-grouped": {
                              ":not(:last-of-type),:not(:first-of-type)": {
                                borderTopLeftRadius: "12px",
                                borderBottomRightRadius: "12px",
                                borderTopRightRadius: "12px",
                                borderBottomLeftRadius: "12px",
                              },
                            },

                            ".MuiToggleButton-root": {
                              color: "var(--recurrence-repeat-on-color)",
                              backgroundColor:
                                "var(--recurrence-repeat-on-bg-color)",
                              border: "none",
                              fontSize: "12px",
                              lineHeight: "12px",
                              width: "24px",
                              height: "24px",
                              mr: 1,
                              ":hover": {
                                backgroundColor:
                                  "var(--recurrence-repeat-on-bg-color)",
                              },
                            },
                            ".MuiToggleButton-root.Mui-selected,.MuiToggleButton-root.Mui-selected:hover":
                            {
                              color:
                                "var(--recurrence-repeat-on-selected-color)",
                              backgroundColor:
                                "var(--recurrence-repeat-on-selected-bg-color)",
                            },
                          }}
                        >
                          <ToggleButton value={RRule.SU.weekday}>s</ToggleButton>
                          <ToggleButton value={RRule.MO.weekday}>m</ToggleButton>
                          <ToggleButton value={RRule.TU.weekday}>t</ToggleButton>
                          <ToggleButton value={RRule.WE.weekday}>w</ToggleButton>
                          <ToggleButton value={RRule.TH.weekday}>t</ToggleButton>
                          <ToggleButton value={RRule.FR.weekday}>f</ToggleButton>
                          <ToggleButton value={RRule.SA.weekday}>s</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    )}
                    <Box
                      sx={{
                        "&": { marginTop: "10px" },
                        ".dpicker": {
                          display: "flex",
                          alignItems: "center",
                          ".MuiFormControl-root": {
                            width: "40%",
                            marginLeft: "2em",
                            ".MuiInput-root.Mui-disabled": {
                              opacity: "0.5",
                            },
                          },
                        },
                      }}
                    >
                      <FormControl sx={{ mb: 1 }}>
                        <FormLabel style={{ color: "var(--primary)" }}>
                          Ends
                        </FormLabel>
                        <RadioGroup
                          sx={{ ".MuiRadio-root": { color: "var(--primary)" } }}
                          value={ends}
                          onChange={(_, v: "never" | "on" | "after") => {
                            switch (v) {
                              case "never": {
                                updateRuleOptions({
                                  ...ruleOptions,
                                  until: undefined,
                                  count: undefined,
                                })
                                break;
                              }
                              case "on": {
                                updateRuleOptions({
                                  ...ruleOptions,
                                  until: moment.utc().toDate(),
                                  count: undefined,
                                })
                                break;
                              }
                              case "after": {
                                updateRuleOptions({
                                  ...ruleOptions,
                                  until: undefined,
                                  count: 1,
                                })
                                break;
                              }
                            }
                          }}
                        >
                          <FormControlLabel
                            value="never"
                            control={<Radio />}
                            label="Never"
                          />
                          <Box
                            className="dpicker"
                            sx={{
                              "& .MuiCalendarPicker-viewTransitionContainer .PrivatePickersSlideTransition-root":
                              {
                                minHeight: "195px",
                              },
                            }}
                          >
                            <FormControlLabel
                              value="on"
                              control={<Radio />}
                              label="On"
                            />

                            <DesktopDatePicker
                              format="DD/MM/yyyy"
                              minDate={moment.utc()}
                              disabled={ends !== "on"}
                              value={moment.utc(ruleOptions.until)}
                              onChange={(newValue) => {
                                updateRuleOptions({
                                  ...ruleOptions,
                                  until: newValue.toDate(),
                                })
                              }}
                              slots={{ textField: BTTextField, ...props }}

                            />
                          </Box>

                          <div className={classes.occurrences}>
                            <FormControlLabel
                              value="after"
                              control={<Radio />}
                              label="After"
                            />
                            <Box
                              sx={[
                                { display: "flex", alignItems: "center" },
                                ends !== "after" && {
                                  opacity: "0.5",
                                },
                              ]}
                            >
                              <BTTextField
                                variant="filled"
                                type="number"
                                disabled={ends !== "after"}
                                inputProps={{ min: 1 }}
                                value={ruleOptions.count?.toString() || "1"}
                                onChange={(v) => {
                                  updateRuleOptions({
                                    ...ruleOptions,
                                    count: +v.target.value,
                                  })
                                }}
                                sx={{
                                  marginRight: "0.5em",
                                  ".MuiFilledInput-input": {
                                    paddingTop: "8px",
                                    width: "3em",
                                  },
                                }}
                              />
                              <span>Occurrences</span>
                            </Box>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                )}
              </FormGroup>
            </div>
          </Stack>
        </Stack>
      </LocalizationProvider>
    </BTThemeProvider>
  );
};

export default SchedulePicker;
