import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import BTButton from "@components/bt-button/bt-button";
import BTtextFieldWithLabel from "@components/bt-input-field-with-label/bt-input-field-with-label";
import PersonAvatar from '@components/tasks/person-avatar/person-avatar';
import { Alert, Snackbar } from '@mui/material';
import { Stack } from '@mui/system';
import { DBUserThunks } from '@reducers/DBServiceReducers';
import { selectUserObject } from "@reducers/UserSelectors";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import replaceImg from "../../../assets/icons/replaceImg.png";
import { AccountPage } from "../../../components/account-page/account-page";
import { SideBarItem } from "../../../components/sidebar/sidebar";
import { User, UserInterface } from "../../../models/user";
import { handleUpload } from "../../../services/upload";
import classes from "./account-view.module.scss";
import { modalActions } from '@reducers/ModalReducer';

export interface AccountViewProps { }
export interface AccountViewState {
  user?: User | null | undefined;
}

export const UpdateDetailsModal = () => {
  const dispatch = useAppDispatch()
  const user: UserInterface = useAppSelector(selectUserObject);


  const [isLoading, setIsLoading] = useState(false);

  const [inputValues, setInputValues] = useState({
    phone: user?.phone || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
  });

  const [profileImgDataUrl, setProfileImgDataUrl] = useState<string | undefined>();
  const [profileImgType, setProfileImgType] = useState<string | undefined>();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState<any>();
  const [snackbarMessage, setSnackbarMessage] = useState<string>();

  const onFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    if (file && file.type.indexOf('image') > -1) {
      const reader = new FileReader();
      reader.onloadend = () => {
        inputFilesRef.current.value = ''
        setProfileImgDataUrl(reader.result as string);
        setProfileImgType(file.type);
      };
      reader.readAsDataURL(file);
    } else {
      setProfileImgDataUrl('');
      setProfileImgType('');
    }
  }, []);

  const saveUser = useCallback(async () => {
    console.log('saveUser');
    setIsLoading(true);

    const fieldsToPatch: Partial<User> = { ...inputValues };
    if (profileImgDataUrl) {
      let urlLink = '';
      try {
        const response = await handleUpload(profileImgDataUrl, profileImgType);
        urlLink = response?.image_url;
      } catch (error) {
        console.log('upload error', error);
      }
      console.log('uploaded', urlLink);
      if (urlLink) {
        fieldsToPatch.profileImg = urlLink;
      }
    }


    const res: any = await dispatch(DBUserThunks.patch({
      entity: {
        _id: user?._id,
        ...fieldsToPatch,
      }
    }))
    if (res?.error) {
      setIsLoading(false);
    } else {
      setProfileImgDataUrl(undefined);
      setProfileImgType('');
      dispatch(modalActions.setComponentToRender(""));

    }
    setIsLoading(false);
  }, [dispatch, inputValues, profileImgDataUrl, profileImgType, user?._id]);

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  }, [inputValues]);

  const inputFilesRef = useRef<HTMLInputElement>(null);



  const accountView = () => {
    return (
  

        <div style={{padding:"24px"}}>
          <div className={classes.inputsContainer}>
            <div className={classes.editableField} style={{textAlign:"center"}}>
              <span className={classes.fieldName} style={{marginBottom:"5px"}}>Your image</span>
              <div style={{ position: "relative"}}>


                <label style={{cursor:"pointer"}} htmlFor="profileImg">
                  <PersonAvatar style={{justifyContent:"center"}} size={120} email={user?.email}
                    profileImg={profileImgDataUrl ?? user?.profileImg} lastName={user?.lastName}
                    firstName={user?.firstName} textSize={60} />
                </label>


                <input
                  disabled={isLoading}
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={inputFilesRef}
                  type="file"
                  id="profileImg"
                  onChange={onFileChange}
                />
              </div>
            </div>
            <Stack spacing={2}>
              <BTtextFieldWithLabel
                // --background-default
                label="First Name"
                placeholder="Your first name"
                onChange={handleChangeInput}
                name="firstName"
                value={inputValues.firstName}
                sx={{
                  backgroundColor: 'var(--background-default)',
                }}
              />
              <BTtextFieldWithLabel
                // --background-default
                label="Last Name"
                placeholder="Your last name"
                onChange={handleChangeInput}
                name="lastName"
                value={inputValues.lastName}
                sx={{
                  backgroundColor: 'var(--background-default)',
                }}
              />

              <BTtextFieldWithLabel
                label="Phone"
                placeholder="Your phone"
                onChange={handleChangeInput}
                name="phone"
                value={inputValues.phone}
                sx={{
                  backgroundColor: 'var(--background-default)',
                }}
              />
              <div
                style={{ paddingBottom: '0' }}
                className={classes.editableField}
              >

                <BTButton onClick={saveUser} loading={isLoading} >
                  Save changes
                </BTButton>
              </div>
            </Stack>

          </div>
        </div>

    );
  };

  return (
    accountView()
  );
};

export default UpdateDetailsModal;
