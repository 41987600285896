import { DBThunkHooks, HookOptions } from "../DBServiceReducers";
import axios from "axios";
import { backendApi } from "../backend-api/backend-api";
import { blobMap } from "@services/blob-service";
import { Template } from "@models/template";

const generateAsset = async (imageId, options: HookOptions) => {
    const image = blobMap.get(imageId)
    const res = await options.dispatch(
        backendApi.endpoints.getSignedUploadUrl.initiate(image.type)
    );

    let asset;
    if ("data" in res) {
        const { url, publicUrl } = res.data;

        try {
            console.log(res);
            await axios({
                method: "PUT",
                url,
                data: image,
                headers: {
                    "Content-Type": image.type,
                },
            });

            asset = {
                url: publicUrl,
                name: image.name,
                type: image.type,
            };
        } catch (e) {
            console.error(e);
        }
    }

    return asset;
}

export const SnippetHooks: DBThunkHooks = {
    before: {
        create: async (snippet: Template, options: HookOptions) => {
            if (snippet.templateType === "snippet" && snippet.imageId) {
                snippet.asset = await generateAsset(snippet.imageId, options);
            }
            return snippet;
        },
        patch: async (snippet: Template, options: HookOptions) => {
            if (snippet.templateType === "snippet" && snippet.imageId) {
                snippet.asset = await generateAsset(snippet.imageId, options);
            }
            return snippet;
        },
    },
};
