import { BTPaper } from "@components/atoms/bt-paper/bt-paper";
import FormsIncludedColumnsDialog from '@components/tasks-panel/form/forms-included-columns-dialog';
import KanbanColumnPicker from '@components/tasks-panel/kanban/kanban-column-picker';
import BoardHideColumnsDialog from "@components/tasks-panel/tasks-panel-header/board-included-columns-dialog";
import WorkloadIncludedColumnsDialog from '@components/tasks-panel/workload/workload-included-columns-dialog';
import { Popper } from '@mui/base/Popper';
import { ClickAwayListener } from "@mui/material";
import { pickerDialogActions } from "@reducers/PickerDialogReducer";
import { useAppDispatch } from "@store/hooks";
import { RootState } from "@store/index";
import { createElement, JSXElementConstructor, memo, ReactElement, useMemo, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import BoardMenuOptions from "../board-menu-options/board-menu-options";
import BoardMenuPicker from "../board-menu-picker/board-menu-picker";
import { PickerProps } from "../columns-cells/cell-interface";
import ComplexityPicker from "../columns-cells/complexity-picker/complexity-picker";
import OptionPicker from "../columns-cells/option-picker/option-picker";
// import TimeTrackerDialog from "../columns-cells/time-tracker-cell/time-tracker-dialog";
import BoardViewIncludedColumnsDialog from "@components/tasks-panel/board-views/board-view-included-columns-dialog";
import WhatsappIdCellEditPicker from "../columns-cells/whatsapp-id-cell/whatsapp-id-cell-edit-picker";
import PersonPicker from "../person-picker/person-picker";
import Addlink from "./Addlink";
import Datepicker from "./Datepicker";
import AddColumnDialog from "./pickers/add-column-dialog";
import ColumnContextMenuDialog from "./pickers/column-context-menu-dialog";
import Dropdown from "./pickers/Dropdown";
import FilesMenu from "./pickers/filesMenu";
import TimelinePicker from "./TimelinePicker";

const PickerTypeList = [
  'add-column-picker',
  'option-picker',
  'complexity-picker',
  'column-context-menu',
  'date-picker',
  'timeline-picker',
  'drop-down',
  'person-picker',
  'board-menu-picker',
  'link-menu',
  'kanban-drawer',
  'gantt-drawer',
  'forms-included-columns-dialog',
  'board-view-included-columns-dialog',
  'workload-included-columns-dialog',
  'time-tracker',
  'board-menu-options'
]
export type PickerType = typeof PickerTypeList[number];

const pickerTypeMap: Record<PickerType, JSXElementConstructor<PickerProps>> = {
  'add-column-picker': AddColumnDialog,
  'option-picker': OptionPicker,
  'complexity-picker': ComplexityPicker,
  'column-context-menu': ColumnContextMenuDialog,
  'date-picker': Datepicker,
  'timeline-picker': TimelinePicker || Datepicker,
  'files': FilesMenu,
  'drop-down': Dropdown,
  'person-picker': PersonPicker,
  'board-menu-picker': BoardMenuPicker,
  'link-menu': Addlink,
  'hide-columns-dialog': BoardHideColumnsDialog,
  'kanban-column-picker': KanbanColumnPicker,
  'forms-included-columns-dialog': FormsIncludedColumnsDialog,
  'board-view-included-columns-dialog': BoardViewIncludedColumnsDialog,
  'workload-included-columns-dialog': WorkloadIncludedColumnsDialog,
  'board-menu-options':BoardMenuOptions,
  'wid-cell-edit-picker': WhatsappIdCellEditPicker

  // 'time-tracker': TimeTrackerDialog,
}

export type OnPickerCloseCallback = () => void;

export interface Props {
  boardId: string;
}

function PickerDialog({ boardId }: Props) {
  const dispatch = useAppDispatch()
  const [arrowRef, setArrowRef] = useState(null);
  const pickerProps = useAppSelector((state: RootState) => state.PickerDialogReducer.pickerProps);
  const pickerAnchorId = useAppSelector((state) => state.PickerDialogReducer.pickerAnchorId);
  const mediaManager = document.getElementById("media-manager")
  const mediaManagerAnchor = pickerAnchorId ? mediaManager?.querySelector("#" + pickerAnchorId) : null;
  const popperAnchorEl = useMemo(() => pickerAnchorId ? mediaManagerAnchor ?? document.getElementById(pickerAnchorId) : null, [pickerAnchorId, mediaManagerAnchor]);

  let pickerContentComponent: ReactElement;


  console.log(`Picker Props: ${pickerProps}`)
  if (!pickerAnchorId) return <></>;
  let popperWidth: any = 150;



  function toggleMenu() {
    dispatch(pickerDialogActions.closePickerDialog());
  }

  function onClickAway(event) {
    toggleMenu();
  }

  const renderPicker = () => {
    if (pickerProps.type) {
      pickerContentComponent = createElement(pickerTypeMap[pickerProps.type], pickerProps)
    }

    return pickerContentComponent;
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div id="generalPicker">

        <Popper
          open={!!pickerAnchorId}
          style={{ zIndex: 9999, minWidth: popperWidth, direction: 'ltr' }}
          anchorEl={popperAnchorEl}
          placement="bottom"
          disablePortal={false}
          modifiers={[
            {
              name: "arrow",
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}
        >
          <>
            <span ref={setArrowRef} />
            <BTPaper style={{
              padding: '0px !important',
              borderRadius: '4px !important',
              background: 'var(--task-background-color) !important',
              boxShadow: '0 8px 16px 0px var(--box-shadow-color) !important'
            }}>
              <div>
                {renderPicker()}
              </div >
            </BTPaper >
          </>
        </Popper >

      </div >
    </ClickAwayListener >

  );
}

export default memo(PickerDialog);