import { Box, ClickAwayListener, Menu, Popper, Stack } from "@mui/material";
import { CellProps } from "../cell-interface";
import { taskGroupsSelectOneObjectByQuery } from "@reducers/DBServiceReducers";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { Circle } from "@mui/icons-material";
import { bulkActions } from "@reducers/BulkActionsReducer";
import MoveTaskToGroup from "@components/tasks-panel/bulk-actions-dialog/move-to/move-task-to-group";
import { useState } from "react";

export default function GroupCell({ groupId, taskId, boardId }: CellProps) {
  const dispatch = useAppDispatch();
  const [open, setIsOpen] = useState<boolean>(false)


  const group = useAppSelector((state) => taskGroupsSelectOneObjectByQuery(state.DBTaskgroupsReducer, { _id: groupId }))

  const togglePicker = () => {
    dispatch(bulkActions.setMovingTo('group'))
    setIsOpen(true)
  }
  const anchor = `group_${taskId}`
  const anchorEl = document.getElementById(anchor)
  const onClickAway = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Stack id={anchor} onClick={togglePicker} direction={"row"} alignItems={"center"} sx={{ flex: 1, fontSize: "14px", px: 1, cursor: "pointer" }}>
        <Circle sx={{ fontSize: "14px", color: group?.colorAccent, marginInlineEnd: "4px" }} />
        <Box sx={{ marginInlineStart: "15px" }}>{group.name}</Box>
      </Stack>
      <Menu
        sx={{ "padding": '0 10px' }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClickAway}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MoveTaskToGroup bulkTasksList={[]} activeTaskid={taskId} boardId={boardId} onMoveComplete={(status) => {
          setIsOpen(status)
        }} />
      </Menu>
    </>

  );
}
