
import { useAppSelector } from '@store/hooks';
import { PropsWithChildren, ReactElement } from 'react';

interface ConditionalWrapperProps {
  shouldLoadSelector: (state: any) => boolean;
}

export default function ConditionalWrapper({ children, shouldLoadSelector }: PropsWithChildren<ConditionalWrapperProps>): ReactElement {

  const shouldLoad = useAppSelector(shouldLoadSelector);

  return (
    <>
      {shouldLoad && children}
    </>
  );
}
