import { AvatarGroup, IconButton, Menu, MenuItem, Modal } from '@mui/material';
import { useAppSelector } from '@store/hooks';
import PersonAvatar from '../person-avatar/person-avatar';
import classes from "./task-subscribers-dialog.module.scss";
import { ManageAccountsOutlined, MoreVert } from '@mui/icons-material';
import { useState } from 'react';
import ManageSubscribersModal from './manage-subscribers-modal';
import { getTaskSubscribedUsersList } from '@reducers/ExtraDataThunks';


type Props = {}

export default function TaskSubscribersDialog({ }: Props) {
    const [isSubscriptionManagerModalOpen, setIsSubscriptionManagerModalOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const subscribedUsersList = useAppSelector((state) => getTaskSubscribedUsersList(state))

    const extraFilesTheme = {
        ".MuiAvatar-circular": {
            height: "32px",
            width: "32px",
            background: "var(--primary)",
            color: "white",
            fontSize: "10px",
            boxShadow: "rgb(0 0 0 / 10%) 2px 2px 2px",
            border: "1px solid rgb(186, 186, 186)",
        },
    };


    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    return (
        <div className={classes.members}>
            <AvatarGroup sx={extraFilesTheme} max={5}>
                {subscribedUsersList.sort(function (a, b) {
                    return a.hasOwnProperty("userId") ? -1 : b.hasOwnProperty("userId") ? 1 : 0
                }).map((person, index) => (
                    <div style={{ opacity: person?.status === "active" ? "1" : '0.5' }} key={index} className={classes.member}>
                        <PersonAvatar
                            size={32}
                            email={person.email}
                            profileImg={person?.profile?.profileImg}
                            lastName={(person?.profile?.lastName === 'undefined' || person?.profile?.lastName === undefined) ? ' ' : person?.profile.lastName}
                            firstName={person?.profile?.firstName}
                            toolTip={true}
                        />
                    </div>
                ))}
            </AvatarGroup>
            <IconButton onClick={handleMenuClick}>
                <MoreVert />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    setIsSubscriptionManagerModalOpen(true)
                    handleMenuClose()
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                        <ManageAccountsOutlined />
                        <p>Manage subscribers</p>
                    </div>
                </MenuItem>
            </Menu>
            <Modal
                id="subscription-manager"
                open={isSubscriptionManagerModalOpen}
                onClose={() => { setIsSubscriptionManagerModalOpen(false) }}
                sx={{
                    maxWidth: "1000px",
                    minWidth: "800px",
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ManageSubscribersModal onClose={() => { setIsSubscriptionManagerModalOpen(false) }} />
            </Modal>
        </div>
    )
}