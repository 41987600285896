import AddIcon from '@mui/icons-material/Add';
import { setHoveredDate } from '@reducers/WhatsAppReducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import classes from "./message-calendar.module.scss";
import React from 'react';
import { IconButton } from '@mui/material';
import moment from 'moment';


type Props = {
    date: any; // Consider using a more specific type for date if possible
    children?: React.ReactNode; // Marking children as optional
}

export const CustomMonthDayBackground = ({ children, date }: Props) => {
    const dispatch = useAppDispatch()

    const handleMouseEnter = () => {
        dispatch(setHoveredDate(date))
    };
    const handleMouseLeave = () => {
        dispatch(setHoveredDate(undefined))
    };



    return (
        <div className={classes.monthDayBackground} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
        </div>
    );
};


export const CustomDayBackground = ({ children, date }) => {
    const hoveredDate = useAppSelector((state) => state.WhatsAppReducer.hoveredDate);

    const dispatch = useAppDispatch()

    const handleMouseEnter = () => {
        dispatch(setHoveredDate(date))
    };
    const handleMouseLeave = () => {
        dispatch(setHoveredDate(undefined))
    };

    const hasNestedChildrenWithoutExcludedClass = React.Children.toArray(children).some(child =>
        React.isValidElement(child) &&
        child.props &&
        child.props.children &&
        // !child.props.className?.includes('customEvent') && // Check for absence of excluded class
        child.props.children !== undefined
    );

    const isHalfHour = moment(date).minutes() === 30

    return (
        <div className={classes.week_view_time_slot_wrapper} style={{
            position: 'relative',
            width: '100%',
            height: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
            zIndex: 9,
        }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {hoveredDate && !hasNestedChildrenWithoutExcludedClass &&
                <IconButton className={classes.addDayButton} sx={{
                    opacity: hoveredDate === date && '1 !important',
                    margin: isHalfHour ? '0px 4px 2px 0px !important' : '2px 4px 2px 0px !important',
                }}>
                    <AddIcon id={`${date}   ${hoveredDate}`} sx={{ width: "16px" }} />
                </IconButton>
            }
        </div>
    );
};