import { TextField, TextFieldProps } from "@mui/material"
import { Box } from "@mui/system"

const inputTheme = {
    width: '100%',
    ".MuiFormControl-root": {
        width: '100%'
    },
    ".MuiInputBase-root": {
        background: 'var(--default-background)',
        border: '1px solid #DEEEF2',
        borderRadius: '8px',
        '& .MuiInputBase-input': {
            padding: '6.5px 10px',
            fontSize: '14px'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            boxShadow: '2px 6px 12px rgba(80, 187, 238, 0.0625)'
        },
    }
}

export const SearchBox = (props: TextFieldProps) => {
    return <Box sx={{ padding: "5px" }}>
        <TextField
            sx={inputTheme}
            hiddenLabel
            
            {...props}
        />
    </Box>
}