import { Notification } from "@models/notification";
import { createSelector } from "@reduxjs/toolkit";
import { DBNotificationsThunks, notificationsSelectByQuery } from "./DBServiceReducers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { ThunkAppDispatch } from "@store/hooks";


export const getNumberOfUnseenNotifications = createSelector(
    [
        (state) => state,
    ],
    (state) => {

        const notifications: Notification[] = notificationsSelectByQuery(state.DBNotificationsReducer, {});

        const newNotifications = (notifications ?? []).filter((notification) => !notification.seen)

        return newNotifications.length
    }
);



export const updateAllUnseenNotificationsToSeenThunk = createAsyncThunk<void, void, { state: RootState }>(
    "tasks/undoTasksSort",
    async (_, thunkAPI) => {
      const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;
      const state = thunkAPI.getState();
      const displayedNewNotificationsIdArray = state.MainReducer.displayedNewNotifications

      const changes = displayedNewNotificationsIdArray.map((notificationId) => (
        {
          id: notificationId,
          changes: {
            seen: true
          }
        }
      ))

    dispatch(DBNotificationsThunks.patchMany(changes));
    }
  );