import { paymentFormActions } from "@reducers/PaymentFormReducer";
import { getShopperInfoThunk } from "@reducers/PaymentFormThunks";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import PopupIframe from "@views/admin-billing/popup-iframe";
import { useCallback, useEffect } from "react";

export const ChargebeeUpdatePaymentMethodPopup = () => {
    const dispatch = useAppDispatch();
    const isUpdatePaymentMethodPopupOpen = useAppSelector(state => state.PaymentFormReducer.isUpdatePaymentMethodPopupOpen);
    const manage_payment_sources_url = useAppSelector(state => state.PaymentFormReducer.manage_payment_sources_url);

    console.log(manage_payment_sources_url);

    const getShopperInfo = useCallback(() => {
        dispatch(getShopperInfoThunk());
    }, [dispatch]);

    useEffect(() => {
        getShopperInfo();
    }, [getShopperInfo])

    return <PopupIframe
        url={manage_payment_sources_url}
        isOpen={!!isUpdatePaymentMethodPopupOpen}
        onClose={() => {
            getShopperInfo();
            dispatch(paymentFormActions.setIsUpdatePaymentMethodPopupOpen(false));
        }}
    />;
}