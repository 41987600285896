import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { labelActions } from '@reducers/labels-reducer';
import { ButtonBase, CircularProgress, colors } from '@mui/material';
import { Close } from '@mui/icons-material';
import LabelForm from '../label-input-with-emoji-picker';
import TalkToUsFooter from './talktous-footer';
import { newLabelThunk } from '@reducers/labels-reducer/labels-thunks/crud';
import mainClasses from '../labels-feature.module.scss'
import classes from './modal.module.scss'
import { labelSelectByQuery } from '@reducers/DBServiceReducers';


export default function AddNewLabelModalModal() {
    const dispatch = useAppDispatch()
    const label = useAppSelector((state) => state.LabelReducer.label)
    const labels = useAppSelector((state) => labelSelectByQuery(state.DBLabelReducer, { type: 'custom' }))
    const isNewLabelModalOpen = useAppSelector((state) => state.LabelReducer.isNewLabelModalOpen)
    const labelNetWorkStatus = useAppSelector((state) => state.LabelReducer.labelNetWorkStatus)


    function isExisting(name: string) {
        const label = labels.find((_label) => _label.name === name)
        if (label) return label.name === name
    }

    return (
        <div>

            <Modal
                open={isNewLabelModalOpen}
                onClose={() => dispatch(labelActions.toggleNewLabel(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.container}>
                    <Box className={classes.modalHead}>
                        <Typography id="modal-modal-title"
                            sx={{ flex: 1, fontWeight: 600 }} variant="h6" component="h2">
                            Create Custom Label
                        </Typography>

                        <ButtonBase
                            className={classes.closeModalButton}
                            sx={{ width: 35, height: 35 }}
                            onClick={() => dispatch(labelActions.toggleNewLabel(false))}
                        >
                            <Close sx={{ fontSize: '25px' }} />
                        </ButtonBase>
                    </Box>

                    <LabelForm />

                    <Box className={classes.suggestedLabels}>
                        {labelSugstions.map((sugestion, index) => (
                            <ButtonBase
                                className={mainClasses.labelButtonBase}
                                onClick={() => {
                                    if (!isExisting(sugestion?.name ?? '')) {
                                        dispatch(labelActions.setLabel(sugestion))
                                    }
                                }}
                                key={index}

                                sx={{
                                    mx: .5, mb: 1,
                                    cursor: isExisting(sugestion?.name ?? '') ? 'not-allowed' : 'pointer',
                                    textTransform: 'capitalize'
                                }}
                            >
                                {sugestion.icon} {sugestion.name}
                            </ButtonBase>
                        ))}
                        <Box className={classes.createWrap}>
                            <ButtonBase
                                className={mainClasses.labelButtonBase}
                                onClick={() => dispatch(newLabelThunk())}
                                sx={{
                                    flexBasis: '40%!important',
                                    bgcolor: label.name ? '#546eff!important' : '',
                                    color: label.name ? '#fff!important' : '',
                                    '&:hover': {
                                        color: labelNetWorkStatus === 'create-label' ? '' : '#fff',
                                        backgroundColor: labelNetWorkStatus === 'create-label' ? colors.grey[200] : '#546eff',
                                    }
                                }}>
                                {labelNetWorkStatus === 'create-label' ? 'Creating..' : 'Create'}
                                {labelNetWorkStatus === 'create-label' && <CircularProgress size={16} sx={{
                                    ml: 1,
                                }} />}
                            </ButtonBase>
                        </Box>
                    </Box>
                    <TalkToUsFooter />
                </Box>
            </Modal>
        </div >
    );
}

const labelSugstions = [
    { icon: '🕧', name: 'networking' },
    { icon: '📔', name: 'contact' },
    { icon: '✌️', name: 'vip' },
    { icon: '🟫', name: 'business' },
    { icon: '🧑‍🤝‍🧑', name: 'new clients' },
    { icon: '💰', name: 'paid' }
]