import BTThemeProvider from "@components/bt-theme-provider";
import { Label } from "@models/label";
import { Chip } from "@mui/material";
import { useAppDispatch } from "@store/hooks";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import {
  chatLabelIdListToggleAddRemove,
  LabelsState
} from "../../reducers/LabelsReducer";
import { RootState } from "../../store";
import NamedIcon from "../named-icon/named-icon";


const StyleChip = (props) => <Chip {...props} />;

export interface ContactLabelsDisplayProps {
  chatId: string;
}

export interface ContactLabelsDisplayState { }

export default function ContactLabelsDisplay(
  props: ContactLabelsDisplayProps
): ReactElement {
  const dispatch = useAppDispatch()
  const { globalLabelList, chatsStateList } = useSelector<
    RootState,
    LabelsState
  >((state) => state.LabelsReducer);

  function onMouseDownHandle(e: any) {
    e.stopPropagation();
  }

  const handleDelete = (label: Label | undefined) => () => {
    console.log(`handle delete of ${label?.displayName}`);

    dispatch(
      chatLabelIdListToggleAddRemove({
        chatId: props.chatId,
        labelId: label?._id,
      })
    );
  };

  function getChatLabelIdList(): Array<string> | undefined {
    return chatsStateList?.find(
      (labeledChat: Label) => labeledChat.chatId === props.chatId
    )?.labelsIdList;
  }

  const chatLabelIdList = getChatLabelIdList();

  return (
    <BTThemeProvider>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          listStyle: "none",
          margin: 0,
        }}
      >
        {chatLabelIdList?.map((labelId: string, index: number) => {
          const label: Label | undefined = globalLabelList?.find(
            (label: Label, index: number) => label._id === labelId
          );

          const backgroundColor: string | undefined =
            label?.color !== undefined && label?.color !== ""
              ? label?.color
              : undefined;

          return (
            <StyleChip
              onMouseDown={onMouseDownHandle}
              key={labelId}
              size="small"
              onDelete={handleDelete(label)}
              label={label?.displayName}
              icon={
                label?.icon !== undefined ? (
                  <NamedIcon name={label?.icon} />
                ) : undefined
              }
              style={{
                backgroundColor: backgroundColor,
              }}
            />
          );
        }) ?? <></>}
      </div>
    </BTThemeProvider>
  );
}
