import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { logsSelectOneObjectByQuery, tasksSelectOneFieldById } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { LogStack } from '../../ExtraDataPanel';
import classes from "./log.module.scss";

type Props = {
    logId: string;
    isBoardLog?: boolean;
}

export default function CheckboxColumnLog({
    logId,
    isBoardLog = false,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))
    const taskName = useAppSelector(state => tasksSelectOneFieldById(state.DBTasksReducer, log?.taskId, 'text'))

    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === log.logUpdateType
    );

    return (
        <LogStack direction='row'>
            <>
                <span className={classes.log_text_element}>{log.actionType} &nbsp; </span>
                <div style={{ maxWidth: '20px', paddingTop: '3px' }}>
                    <CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={log.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                </div>
                <span>&nbsp; to &nbsp;</span>
                <div style={{ maxWidth: '20px', paddingTop: '3px' }}>
                    <CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={log.newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                </div>
            </>
            <span className={classes.log_text_element}>&nbsp;&nbsp;in&nbsp;</span>
            {isBoardLog && <span style={{ fontWeight: 'bold' }}>{taskName}-</span>}
            <span className={classes.log_text_element} style={{ fontWeight: 'bold' }}>{log.actionOnEntityData}</span>
        </LogStack>
    )
}