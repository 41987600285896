import BTButton from "@components/bt-button/bt-button";
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Stack } from "@mui/material";
import { subscriptionPopupActions } from "@reducers/SubscriptionPopupReducer";
import { activeSubscriptionSelector } from "@reducers/UserSelectors";
import { openPaymentSite } from "@services/utils";
import { useAppSelector } from "@store/hooks";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { getRuntimeUrl } from "../../services/storage";
import PlanDescription from "../plan-description/plan-description";
import { ReactComponent as Crown } from './royal-crown.svg';

export interface UpgradeBoxParams {
    onClose?: () => void;
}

export const UpgradeBox = ({
    onClose
}: UpgradeBoxParams) => {
    const dispatch = useDispatch();
    const rocketImage: string = useMemo(() => getRuntimeUrl("/img/upgrade.svg"), []);
    const lockedItem = useAppSelector(state => state.SubscriptionPopupReducer.lockedItem ?? 'messages');
    const accessToken = useAppSelector((state) => state.UserReducer.accessToken);
    const activeSubscription = useAppSelector(activeSubscriptionSelector);

    const closePopup = () => {
        dispatch(subscriptionPopupActions.closePopup())
        onClose?.apply(null, [])
    }

    enum PlanNames { free, scheduler, manager, pro }

    enum FeatureNames { messages, tasks, campaigns }

    const currentPlan = PlanNames.free; // TODO: Change this on build according to the user's plan
    const nameOrEmail = "mazuznoam6@gmail.com"; // TODO: Change this on build according to the user's name or email

    interface ModalDataPlan {
        name: string;
        statuses: {
            [FeatureNames.messages]: string;
            [FeatureNames.tasks]: string;
            [FeatureNames.campaigns]: string;
        },
        image: string;
        buttonText: string;
    }

    interface ModalDataPlans {
        [PlanNames.free]: ModalDataPlan
        [PlanNames.scheduler]: ModalDataPlan
        [PlanNames.manager]: ModalDataPlan
        [PlanNames.pro]: ModalDataPlan
    }

    const modalDataPlans: ModalDataPlans = {
        [PlanNames.free]: {
            name: "Free plan",
            statuses: {
                [FeatureNames.messages]: "Up to 1 at a time",
                [FeatureNames.tasks]: "Up to 10 per contact",
                [FeatureNames.campaigns]: "Unlimited, with branding",
            },
            image: "/img/locked-free-plan.svg",
            buttonText: "Unlock all features",
        },
        [PlanNames.scheduler]: {
            name: "Scheduler plan",
            statuses: {
                [FeatureNames.messages]: "Unlimited messages!",
                [FeatureNames.tasks]: "Up to 10 per contact",
                [FeatureNames.campaigns]: "Unlimited, with branding",
            },
            image: "/img/locked-premium-plan.svg",
            buttonText: "Upgrade now",
        },
        [PlanNames.manager]: {
            name: "Manager plan",
            statuses: {
                [FeatureNames.messages]: "Unlimited messages!",
                [FeatureNames.tasks]: "Unlimited tasks!",
                [FeatureNames.campaigns]: "Unlimited, with branding",
            },
            image: "/img/locked-premium-plan.svg",
            buttonText: "Upgrade now",
        },
        [PlanNames.pro]: {
            name: "Campaigner plan",
            statuses: {
                [FeatureNames.messages]: "Unlimited messages!",
                [FeatureNames.tasks]: "Unlimited tasks!",
                [FeatureNames.campaigns]: "Unlimited without branding!",
            },
            image: "/img/locked-premium-plan.svg",
            buttonText: "Upgrade now",
        },

    }

    const modalData = {
        nameOrEmail: nameOrEmail,
        planName: modalDataPlans[currentPlan].name,
        statuses: {
            [FeatureNames.messages]: modalDataPlans[currentPlan].statuses[FeatureNames.messages],
            [FeatureNames.tasks]: modalDataPlans[currentPlan].statuses[FeatureNames.tasks],
            [FeatureNames.campaigns]: modalDataPlans[currentPlan].statuses[FeatureNames.campaigns],
        },
        image: modalDataPlans[currentPlan].image,
        buttonText: modalDataPlans[currentPlan].buttonText,
    }

    return (
        <Box sx={{
            maxWidth: 510,
            boxSizing: 'border-box',
            px: 3,
            py: 3,
            backgroundColor: 'var(--upgrade-background-color)',
            fontFamily: "'Inter', 'Poppins', 'Assistant', sans-serif",
        }}>
            <Stack direction="row">
                <IconButton onClick={closePopup} sx={{ ml: 'auto' }}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Stack alignItems="center">
                <Stack direction="row" alignItems="center" sx={{
                    fontSize: 44,
                    lineHeight: '48px',
                    color: 'var(--primary)',
                    textAlign: 'center',
                    mb: 3,
                }}>Feature Locked</Stack>
                <Box sx={{ textAlign: 'center', mb: '60px', fontSize: 18 }}>
                    Go Premium to unlock
                    <span style={{ color: 'var(--upgrade-color)'}}>
                        <Box sx={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            mx: '4px',
                            position: 'relative',
                        }}>
                            <Crown />
                        </Box>
                        unlimited
                    </span> {lockedItem}
                </Box>
                <Box sx={{
                    mb: '60px'
                }}>
                    <img alt='' src={rocketImage}></img>
                </Box>
                {activeSubscription &&
                    <Box sx={{ mb: 3 }}>
                        <PlanDescription subscription={activeSubscription} />
                    </Box>
                }
                <BTButton sx={{
                    width: 1,
                }} btvariant="primary" onClick={() => openPaymentSite(accessToken)}>UPGRADE</BTButton>
            </Stack>
        </Box>
    );
}

export default UpgradeBox;
