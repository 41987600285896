import { Kanban, Kanban as typeKanban } from "@models/kanban"
import { getSelectedBoardId } from '@reducers/BoardsSelectors'
import { DBKanbanThunks } from '@reducers/DBServiceReducers'
import { taskActions } from '@reducers/TasksReducer'
import { selectUserId } from '@reducers/UserSelectors'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { addViewStyle } from "@views/task-view/add-feature-menu-button"
import { MdAddCircle, MdOutlineAnalytics } from 'react-icons/md'

export default function NewKanbanButton() {
    const selectedBoardId = useAppSelector(getSelectedBoardId)
    const dispatch = useAppDispatch()
    const userId = useAppSelector(selectUserId)
    const newKanban = async () => {
        const _kanban: typeKanban = {
            owner: userId,
            boardId: selectedBoardId,
            name: "Kanban",
        }
        dispatch(taskActions.setCurrentBoardView({
            type: "loader",
            id: '',
        }))
        const res = await dispatch(DBKanbanThunks.create(_kanban))
        // console.log('res')
        // console.log(res)
        const kanban = res.payload as Kanban
        dispatch(taskActions.setCurrentBoardView({
            type: "kanban",
            id: kanban._id.toString(),
        }))
    }

    return (
        <>
            <span data-testid='add-new-kanban-button'
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
                onClick={newKanban}>
                <MdOutlineAnalytics size={20} style={{ marginLeft: '4px' }} />
                Kanban
                <MdAddCircle className="add_view_icon" style={{
                    ...addViewStyle,
                    position: 'absolute',
                    right: 7,
                }} size={20} />
            </span>
        </>

    );
}