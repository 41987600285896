import NewKanbanButton from '@components/tasks-panel/kanban/new-kanban-button';
import { AddOutlined } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import classes from "./tasks-header.module.scss";
import NewFormButton from '@components/tasks-panel/form/new-form-button';
import NewWorkloadButton from '@components/tasks-panel/workload/new-workload-button';
import NewBoardViewsButton from '@components/tasks-panel/board-views/new-board-views-button';

type Props = {}

export default function AddFeatureMenuButton({ }: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const ITEM_HEIGHT = 48;

    const menuItemStyle = {
        fontSize: "14px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "0 12px 0 3px",
        height: "36px",
        border: "0",
        outline: "none",
        fontWeight: "600",
        backgroundColor: "transparent",
        borderRadius: "8px",
        color: "var(--primary)",
        fontFamily: "'Poppins', 'Assistant', sans-serif",
        marginBottom: "8px",
        justifyContent: "space-between",
        gap: "16px",
        transition: "0.1s",
        "&:hover": {
            background: "var(--button-hover)",
            boxShadow: "0 5px 12px rgb(0 0 0 / 15%)",
        },
        "&:hover .add_view_icon": {
            opacity: "1 !important",
        },
        "&:last-child": {
            marginBottom: "0",
        }
    }


    return (
        <div data-testid='add-feature-plus-button'>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.add_button}
                sx={{
                    width: 32,
                    height: 32,
                    padding: 0,
                    "&:hover": {
                        background: "var(--button-hover)",
                        boxShadow: "0 5px 12px rgb(0 0 0 / 15%)",
                    },
                }}
            >
                <AddOutlined />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        direction: 'ltr',
                        maxHeight: ITEM_HEIGHT * 4.5,
                        maxWidth: '20ch',
                        minWidth: '16ch',
                        padding: "4px 12px",
                    },
                }}
            >
                <MenuItem disableRipple={true} sx={{ ...menuItemStyle }} onClick={() => { handleClose() }}>
                    <div style={{ width: '100%' }}><NewKanbanButton /></div>
                </MenuItem>
                <MenuItem disableRipple={true} sx={{ ...menuItemStyle }} onClick={() => { handleClose() }}>
                    <div style={{ width: '100%' }}><NewFormButton /></div>
                </MenuItem>
                {/* <MenuItem disableRipple={true} sx={{ ...menuItemStyle }} onClick={() => { handleClose() }}>
                    <div style={{ width: '100%' }}><NewGanttButton /></div>
                </MenuItem> */}
                {/* <MenuItem disableRipple={true} sx={{ ...menuItemStyle }} onClick={() => { handleClose() }}>
                    <div style={{ width: '100%' }}><NewWorkloadButton /></div>
                </MenuItem>
                <MenuItem disableRipple={true} sx={{ ...menuItemStyle }} onClick={() => { handleClose() }}>
                    <div style={{ width: '100%' }}><NewBoardViewsButton /></div>
                </MenuItem> */}
            </Menu>
        </div>
    );
}


export const addViewStyle = {
    color: "#53bdeb",
    opacity: "0",
    transition: "0.1s",
}