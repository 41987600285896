import { AddScheduledMessageButtonState } from "@components/add-scheduled-message-button/add-scheduled-message-button";
import { fetchRule } from "@components/schedule-picker/recurrence-utils";
import { ContactInfo } from "@models/contact-info";
import { Person } from "@models/person";
import { LinkPreview, UserMessage } from "@models/user-message";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AnalyticsService } from "@services/analytics-service";
import { blobMap } from "@services/blob-service";
import { MessageService } from "@services/messageService";
import { MockWapi } from "@services/mock-wapi";
import { postMessageWithResponse } from "@services/post-message-to-whatsapp-functions";
import { initMessageSentConfirmation, initSendMessagesTimer } from "@services/schedule-service";
import { getBase64ImageFromUrl, getMessageFromInputField } from "@services/utils";
import { Chat, WhatsappChatMessage, WhatsappContact } from "@services/whatsapp";
import { AppDispatch, AppThunkDispatch, RootState } from "@store/index";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import randomstring from "randomstring";
import { RRule } from "rrule";
import { MessageState } from "types/interface";
import { accessFormPopupActions } from "./AccessFormPopupReducer";
import { addScheduledMessageButtonActions } from "./AddScheduledMessageButtonReducer";
import { boardsActions } from "./BoardsReducer";
import { getBoardByChat } from "./BoardsSelectors";
import { DBBoardsThunks, DBUserMessageThunks, webhooksSelectByQuery } from "./DBServiceReducers";
import { notifierSubscribeThunk } from "./NotifierThunks";
import { GetProfilePicResponse, getProfilePicThunk } from "./ProfilePicReducer";
import { selectAudienceSelectedContactList } from "./TaskSelectors";
import { updateBoardPersonListThunk } from "./TaskThunks";
import { taskActions } from "./TasksReducer";
import { selectUser, selectUserId } from "./UserSelectors";
import { WhatsappActions, privateSetCurrentChat, selectMergedContactList, setImportedContactsArray, setInitialized, setProfilePic } from "./WhatsAppReducer";
import { selectProfilePic } from "./WhatsAppSelectors";
import { canISend, createEntity, isWaSocketsLimitReached } from "./backend-api/backend-api";


export const EmittedPathList = ["create", "patch", "delete"];
export type EmittedPath = typeof EmittedPathList[number];

export interface UpdateEvent<T = any> {
    method: string;
    path: EmittedPath;
    entity: T
}

export interface QREvent {
    owner: string;
    qr: string;
}

export class Deferred<T = any> {
    promise: Promise<T>;
    reject!: () => void;
    resolve!: (data: any) => void;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}

export interface CheckAuthParams {
    wid?: string;
    accessToken?: string;
    email?: string;
}

export interface SubscriptionSuccessOptions {
    shareASaleUrl: string;
}

export enum GlobalTasksPanelState {
    closed,
    openSingle,
    openAll,
}

export type MenuOptionType =
    | "socketConnectionStatus"
    | "openChatByPhoneNumber"
    | "broadcastBulkCampaign"
    | "scheduledMessages"

export interface MenuOption {
    icon?: JSX.Element;
    text?: string;
    type: MenuOptionType;
}

const analyticsService = new AnalyticsService();
const messageService = new MessageService();


if (process.env.NODE_ENV === "development" && window.location.hostname.includes("localhost")) {
    new MockWapi();
}


export const handleNewMessageThunk = createAsyncThunk<
    void,
    WhatsappChatMessage,
    { state: RootState }
>("handleNewMessageThunk", async (message, thunkApi) => {
    const { getState } = thunkApi;
    const dispatch: AppDispatch = thunkApi.dispatch;
    const state: RootState = getState();

    const webhookList = webhooksSelectByQuery(state.DBWebhooksReducer, {})

    if (webhookList?.length > 0) {
        dispatch(createEntity.initiate({
            service: 'actions',
            entity: {
                cmd: "sendMessageToWebhook",
                message,
            }
        }))
        analyticsService.event("message_api_receive", {
            category: "messages",
            action: "api-receive",
            label: "regular",
        });
    }

    const userMessageList = Object.keys(
        state.DBUserMessageReducer.entities
    ).map((id) => state.DBUserMessageReducer.entities[id]);
    const contactMessageList = userMessageList.filter(_message => (_message.contactList ?? []).find(
        (contact) => contact.id === message.from?.id?._serialized
    ));
    if (contactMessageList) {
        for (const scheduledMessage of contactMessageList) {
            if (scheduledMessage.cancelIfReceived) {
                dispatch<any>(DBUserMessageThunks.delete({
                    entity: scheduledMessage,
                    options: { inCaseOfErrorRollbackOptimisticUpdateDisabled: true },
                }));
            }
        }
    }
});

export const onWapiInitialized = createAsyncThunk<
    void,
    void,
    { state: RootState }
>("onWapiInitialized", async (_, thunkApi) => {
    const dispatch: AppDispatch = thunkApi.dispatch;

    dispatch(setInitialized(true))
    dispatch(getMeThunk());
    const res = await messageService.sendMessage("getUserInfo", {});
    console.log(`User info result: ${JSON.stringify(res)}`);

    dispatch(getChatListThunk());
    dispatch(initSendMessagesTimer())
    dispatch(initMessageSentConfirmation())

    // dispatch(getCurrentChatThunk({}));
});


export const subscribeCurrentBoardToSocket = createAsyncThunk<
    void,
    void,
    { state: RootState }
>("subscribeCurrentBoardToSocket", async (_, thunkApi) => {

    const { dispatch, getState } = thunkApi;
    const state = getState();

    const chatBoard = getBoardByChat(state, state.WhatsAppReducer?.currentChat);
    if (chatBoard) {
        dispatch(notifierSubscribeThunk({ entityType: 'boards', entityId: chatBoard._id }))
    }
});

export const setCurrentChatThunk = createAsyncThunk<
    void,
    { chat: Chat },
    { state: RootState }
>("setCurrentChatThunk", async ({ chat }, thunkApi) => {

    const { dispatch, getState } = thunkApi;
    const state = getState();
    dispatch(privateSetCurrentChat(chat))

    const chatBoard = getBoardByChat(state, chat);
    if (chatBoard) {
        dispatch(notifierSubscribeThunk({ entityType: 'boards', entityId: chatBoard._id }))
    }
});

export const getCurrentChatThunk = createAsyncThunk<
    void,
    { clearInput?: boolean },
    { state: RootState }
>("getCurrentChatThunk", async (options, thunkApi) => {

    window.postMessage(
        {
            cmd: "getCurrentChat",
            clearInput: options?.clearInput,
        },
        "*"
    );
});



export const checkWaSocketsLimitThunk = createAsyncThunk(
    'checkWaSocketsLimit',
    async (_, thunkApi) => {
        const { dispatch, getState } = thunkApi;
        const state = getState();
        const userId = selectUserId(state)

        if (userId) {
            let res = await dispatch(isWaSocketsLimitReached.initiate({})).unwrap();
            dispatch(addScheduledMessageButtonActions.setIsSocketsLimitReached(res));
        }
    }
);

export const getChatListThunk = createAsyncThunk<
    void,
    void,
    { state: RootState }
>("getChatListThunk", async (options, thunkApi) => {

    window.postMessage(
        {
            cmd: "getChats",
        },
        "*"
    );
});

export const getWAContactImageRequestThunk = createAsyncThunk<
    void,
    void,
    { state: RootState }
>("getChatListThunk", async (options, thunkApi) => {

    window.postMessage(
        {
            cmd: "getWAContactImageRequest",
        },
        "*"
    );
});

export const getMeThunk = createAsyncThunk<
    void,
    void,
    { state: RootState }
>("getMeThunk", async (options, thunkApi) => {
    window.postMessage(
        {
            cmd: "getMe",
        },
        "*"
    );
});

export const focusContactThunk = createAsyncThunk<
    void,
    String,
    { state: RootState }
>("focusContactThunk", async (contactId, thunkApi) => {
    window.postMessage(
        {
            cmd: "focusContact",
            contactId,
        },
        "*"
    );
});

export const calculateNextDuedate = (dueDate: moment.Moment, message: UserMessage) => {
    if (!!message.rruleset) {
        const rule: RRule = fetchRule(message.rruleset);

        // Create a new Date object for the current time in UTC
        let currentDateUTC = moment.utc();

        // Get max of current date in UTC and due date in UTC
        let maxDateUTC = dueDate.isAfter(currentDateUTC) ? dueDate : currentDateUTC;

        let nextUTC = rule.after(maxDateUTC.toDate());
        if (nextUTC) {
            // Convert UTC date back to moment object
            dueDate = moment.utc(nextUTC);
        } else {
            return null;
        }
    } else {
        const diffUnits = moment.utc().diff(dueDate, message.recurenceUnit, true);

        if (diffUnits > 0) {
            const periods = Math.floor(diffUnits / message.recurrenceQuantity);
            dueDate.add((periods + 1) * message.recurrenceQuantity, message.recurenceUnit);
        }
    }

    return dueDate;
}

export const sendMessageThunk = createAsyncThunk<
    void,
    UserMessage,
    { state: RootState }
>("sendMessageThunk", async (_message: UserMessage, thunkApi) => {
    const state: RootState = thunkApi.getState();
    const dispatch: AppDispatch = thunkApi.dispatch;;
    const user = selectUser(state)

    const message = {
        ..._message,
        state: MessageState.pending
    }

    if (message._id) {
        const canISendRes = await dispatch(canISend.initiate(message._id)).unwrap();
        if (!canISendRes.canSend) {
            console.log('canISendRes', canISendRes)
            return;
        }
        console.log(canISendRes)
    }

    if (message.imageId && !message.asset?.url) {
        message.image = blobMap.get(message.imageId);
    }

    if (message.asset?.url) {
        // const regexResults = message.asset.url.match("blueticks-assets.storage.googleapis.com/(.+)")
        let url = message.asset.url;
        // if (regexResults.length > 1) {
        //   url = `${appConfig.API_ENDPOINT}/images/${regexResults[1]}`
        // } else {
        //   url = message.asset.url;
        // }

        try {
            // const asset = state.WhatsAppReducer.assetMap[url]
            // if (!!asset) {
            //     message.image = asset;
            // } else {
            // const response: Response = await fetch(url);
            // const blob = await response.blob();
            // message.image = new File([blob], message.asset.name, {
            //   type: message.asset.type,
            // });
            // this.assetMap.set(url, message.image);
            const response: AxiosResponse<Blob> = await axios.get(
                message.asset.url,
                { responseType: "blob" }
            );
            message.image = new File([response.data], message.asset.name, {
                type: message.asset.type,
            });
            dispatch(WhatsappActions.setAsset({
                url,
                file: message.image,
            }));
            //}
        } catch (e) {
            console.warn(e);
        }
    }

    analyticsService.event(`${message.isRecurring ? "recurring" : "regular"}_message_sent`, {
        category: "messages",
        action: "sent",
        label: message.isRecurring ? "recurring" : "regular",
        messageId: message.id,
        campaignId: message.campaignId,
    });

    let linkPreviewOverride: LinkPreview | undefined;
    if (message.linkPreview && message.sendLinkPreview) {
        const thumbnail = await getBase64ImageFromUrl(message.linkPreview.thumbnailUrl);
        linkPreviewOverride = {
            thumbnail
        };
    }


    if (!user?.impersonating) {
        console.log('sending message:', message)

        window.postMessage(
            {
                cmd: "sendMessage",
                message,
                linkPreviewOverride
            },
            "*"
        );
    }

    dispatch<any>(DBUserMessageThunks.delete({
        entity: message,
        options: { inCaseOfErrorRollbackOptimisticUpdateDisabled: true },
    }));

    if (message.isRecurring === true) {
        const newMessage: UserMessage = { ...message };
        delete newMessage.id;
        delete newMessage._id;
        newMessage.state = MessageState.pending;

        let dueDate = calculateNextDuedate(moment.utc(message.dueDate), message);

        if (dueDate.isValid()) {
            newMessage.state = MessageState.pending;
            newMessage.dueDate = dueDate.toISOString();
            dispatch(scheduleMessage(newMessage));
        }
    }
});

export const scheduleMessage = createAsyncThunk<
    void,
    UserMessage,
    { state: RootState; dispath: AppThunkDispatch }
>("scheduleMessage", async (_message, thunkApi) => {
    const message = {
        ..._message,
        id: randomstring.generate({ length: 8, charset: "alphanumeric" }),
        dueDate: moment(_message.dueDate).startOf("minute").toISOString(),
        status: 'pending',
        state: MessageState.pending,
    } as UserMessage;

    if (message._id) {
        thunkApi.dispatch<any>(DBUserMessageThunks.patch({entity: message}));
    } else {
        thunkApi.dispatch<any>(DBUserMessageThunks.create(message));
    }
});


export const handleClickScheduleMessageThunk = createAsyncThunk<
    void,
    void,
    { state: RootState }
>("scheduleMessage", async (_message, thunkApi) => {
    const state: RootState = thunkApi.getState();
    const dispatch: AppDispatch = thunkApi.dispatch;
    const user = selectUser(state)
    const _currentChat = state.WhatsAppReducer.currentChat;
    const currentChat = {
        ..._currentChat,
        participants: undefined,
    }

    const resetState: AddScheduledMessageButtonState = {
        showErrorDialog: false,
        open: false,
        message: {
            dueDate: undefined,
            message: "",
            isRecurring: false,
            state: MessageState.pending,
            cancelIfReceived: false,
        },
        isEmojiPicker: false,
    };

    if (!user) {

        analyticsService.event("login_before_schedule", {
            category: "Schedule",
            action: "login before schedule",
        });

        dispatch(accessFormPopupActions.setStatus('Login'));
        return;
    }



    dispatch(isWaSocketsLimitReached.initiate({})).unwrap().then((res) => {
        dispatch(addScheduledMessageButtonActions.setIsSocketsLimitReached(res));
    });

    const message = Object.assign({}, resetState.message);
    message.contactList = [
        {
            ...currentChat,
            id: currentChat?.id?._serialized,
            name: currentChat?.name ?? currentChat?.displayName,
        },
    ];
    message.message = getMessageFromInputField();
    message.dueDate = moment().toDate().toISOString();
    message.isTimeValid = true;

    analyticsService.event("open_schedule_dialog", {
        category: "Schedule",
        action: "open dialog",
    });

    dispatch(addScheduledMessageButtonActions.editMessage(message));
});



export const toggleTasksThunk = createAsyncThunk<
    void,
    UserMessage,
    { state: RootState }
>("scheduleMessage", async (_message, thunkApi) => {
    const state: RootState = thunkApi.getState();
    const dispatch: AppDispatch = thunkApi.dispatch;
    const user = selectUser(state)
    const isTasksPanelOpen = state.TasksReducer.isTasksPanelOpen

    const boardsLoaded = state.DBBoardsReducer.itemsLoaded

    if (!user) {
        dispatch(accessFormPopupActions.setStatus('Login'))
        return;
    }

    console.log("ToggleTasksButton: run only once");
    if (!boardsLoaded) {
        console.log("get boards");
        dispatch(DBBoardsThunks.find({})).then(() => {
            dispatch(subscribeCurrentBoardToSocket())
        });
    }

    dispatch(taskActions.setIsTasksPanelOpen(!isTasksPanelOpen));
});



export const createWAContactBoardThunk = createAsyncThunk<
    void,
    { clearInput?: boolean },
    { state: RootState }
>("getCurrentChatThunk", async (options, thunkApi) => {
    const state: RootState = thunkApi.getState();
    const dispatch: AppDispatch = thunkApi.dispatch;
    const currentChat: Chat = state.WhatsAppReducer.currentChat
    const userWA: WhatsappContact = state.WhatsAppReducer.me

    const user = selectUser(state)

    function createParticipant(participant: Partial<WhatsappContact>) {
        return {
            waId: participant?.id?._serialized ?? "",
            name: participant?.displayName ?? "",
            isMyContact: participant?.isMyContact,
            isWAContact: participant?.isWAContact,
        };
    }

    let personList = [];
    let _IdByWaChatIds: string[]

    if (currentChat?.isGroup) {
        personList = (currentChat?.participants ?? []).map(createParticipant);
        _IdByWaChatIds = [currentChat?.id?._serialized]
    } else {
        personList.push(createParticipant(currentChat.contact));
        personList.push(createParticipant(userWA));
        _IdByWaChatIds = [currentChat?.id?._serialized, userWA?.id?._serialized]
    }

    const board = {
        name: currentChat?.formattedTitle ?? currentChat?.displayName ?? currentChat?.name ?? currentChat.id?.user ?? 'New WA board',
        owner: user._id,
        personList: personList,
        isGroup: currentChat?.isGroup ?? false,
        isWABoard: true,
        _IdByWaChatIds: _IdByWaChatIds,
    };

    dispatch(boardsActions.setNewWaBoardStatus("creating WA board"));
    const waBoard: any = await dispatch(DBBoardsThunks.create(board));

    const personProfilePicPromiseList = currentChat.participants.map(participant =>
        dispatch(getProfilePicThunk(participant.id?._serialized)).unwrap()
    )

    Promise.all(personProfilePicPromiseList).then((responseList) => {
        const personList: Person[] = responseList.filter((response: any) => !response.error)
            .map((response: GetProfilePicResponse) => {
                const participant: Partial<WhatsappContact> | undefined = currentChat.participants.find((participant) => participant?.id?._serialized === response.waId)
                return {
                    ...participant,
                    profilePic: response.profilePic,
                }
            })

        dispatch(updateBoardPersonListThunk({
            boardId: waBoard.payload?._id,
            personList
        }));
    })
});


export const handleContactSelectionChangedThunk = createAsyncThunk<
    void,
    { contact: ContactInfo; isSelected: boolean },
    { state: RootState }
>("scheduleMessage", async ({ contact, isSelected }, thunkApi) => {
    const state: RootState = thunkApi.getState();
    const dispatch: AppDispatch = thunkApi.dispatch;
    const importedContactsArray = state.WhatsAppReducer.importedContactsArray;

    const _importedContactsArray = [...importedContactsArray];
    const chatIndex = _importedContactsArray.findIndex(_contact => _contact.whatsappId === contact.whatsappId);
    if (chatIndex > -1) {
        _importedContactsArray.splice(chatIndex, 1);
    }
    if (isSelected) {
        _importedContactsArray.push(contact);
    }
    dispatch(setImportedContactsArray(_importedContactsArray))
})

export const toggleContactSelectionThunk = createAsyncThunk<
    void,
    { contact: ContactInfo },
    { state: RootState }
>("toggleContactSelectionThunk", async ({ contact }, thunkApi) => {
    const state: RootState = thunkApi.getState();
    const dispatch: AppDispatch = thunkApi.dispatch;
    const isContactChecked = state.WhatsAppReducer.importedContactsArray.find(_contact => _contact.whatsappId === contact.id._serialized) !== undefined;
    dispatch(handleContactSelectionChangedThunk({ contact, isSelected: !isContactChecked }))
});



export const getProfilePicUrlThunk = createAsyncThunk<
    void,
    string,
    { state: RootState }
>("getProfilePicUrlThunk", async (contactId, thunkApi) => {
    const dispatch: AppDispatch = thunkApi.dispatch;
    const state = thunkApi.getState();

    let profilePic = selectProfilePic(state, contactId);

    if (profilePic === undefined) {
        profilePic = await postMessageWithResponse({ cmd: "getProfilePicUrl", contactId });
        dispatch(setProfilePic({ contactId, profilePic: profilePic ?? '' }));
    }
});

export const toggleSelectAllContactsThunk = createAsyncThunk<
    void,
    void,
    { state: RootState }
>("selectAllContactsThunk", async (isSelected, thunkApi) => {
    const state: RootState = thunkApi.getState();
    const dispatch: AppDispatch = thunkApi.dispatch;

    const importedContactList = state.WhatsAppReducer.importedContactsArray;

    if (importedContactList.length > 0) {
        dispatch(setImportedContactsArray([]))
    } else {
        const mergedContactList = selectMergedContactList(state);
        const audienceSelectedContactList = selectAudienceSelectedContactList(state);
        const audienceSelectedContactMap = audienceSelectedContactList.reduce((map, contactId) => {
            map[contactId] = contactId;
            return map;
        }, {} as Record<string, string>);

        const _importedContactsArray = mergedContactList.filter(contact => {
            return audienceSelectedContactMap[contact.id._serialized] === undefined;
        });
        dispatch(setImportedContactsArray(_importedContactsArray))
    }
});