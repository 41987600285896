import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { sessionSelectOneObjectByQuery } from "./DBServiceReducers";
import { selectUserId } from "./UserSelectors";
import { ContactInfo } from "@models/contact-info";


export const selectWhatsappContact = createSelector(
    [
        (state: RootState) => state,
        (state: RootState, whatsappId: string) => whatsappId,
    ],
    (state: RootState, whatsappId: string) => {
        return state.WhatsAppReducer.contactList.find(contact => contact.id._serialized === whatsappId)
    }
);

export const hasActiveSocketSession = createSelector(
    [
        (state: RootState) => state,
        (state: RootState) => selectUserId(state),
    ],
    (state: RootState, userId) => {
        const session = sessionSelectOneObjectByQuery(state.DBSessionReducer, {
            status: { $in: ["connected"] },
            owner: userId
        });
        return !!session;
    }
);


export const getIsContactChecked = createSelector(
    [
        (state, wid) => state,
        (state, wid) => wid,
    ],
    (state, wid) => {
        const importedContactsArray = state.WhatsAppReducer.importedContactsArray

        const isChecked = importedContactsArray?.filter(contact => contact?.whatsappId === wid).length > 0

        return isChecked
    }
);

export const extractPhoneNumber = (id: string) => {
    if (typeof id !== 'string') return null;

    const match = id?.match(/^(\d+)/);
    return match ? match[1] : null;
};

export const calculatedName = (contact: ContactInfo): string => {
    const _calcName = [contact?.firstName, contact?.lastName].filter(x => x).join(' ') || contact?.mobile;
    return contact?.name || contact?.displayName || contact?.shortName || _calcName || contact?.id?._serialized;
}

// Selector to select a whatsapp contact by id
export const selectWhatsappContactById = createSelector(
    [
        (state: RootState) => state.WhatsAppReducer.contactMap,
        (state: RootState, whatsappId: string) => whatsappId,
    ],
    (contactMap, whatsappId: string) => {
        return contactMap[whatsappId];
    }
);

// Selector to select a whatsapp contact name by id
export const selectWhatsappContactName = createSelector(
    [
        selectWhatsappContactById,
    ],
    (contact) => {
        return calculatedName(contact);
    }
);

export const selectIsGroup = createSelector(
    [
        (whatsappId: string) => whatsappId,
    ],
    (whatsappId: string) => {
        return whatsappId?.includes && whatsappId?.includes('@g.us');
    }
)

// Selector to select a profile picture by id
export const selectProfilePic = createSelector(
    [
        (state: RootState) => state.WhatsAppReducer.profilePicMap,
        (state: RootState, whatsappId: string) => whatsappId,
    ],
    (profilePicMap, whatsappId: string) => {
        return profilePicMap[whatsappId];
    }
);