import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { setExtraDataOfTaskId, setIsNewSubItemDialog } from "@reducers/ExtraDataReducer";
import { getPreviousOrNextTaskIdByCurrentTaskId } from "@reducers/TaskSelectors";
// import { setExtraDataOfTaskId } from "@reducers/ExtraDataReducer";
import { tasksSelectors } from "@reducers/DBServiceReducers";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { namespace } from "config/constants";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import ExtraDataPanelChat from "./ExtraDataPanelChat";
import classes from "./extraData.module.scss";


export enum DisplayModes {
  chat = "chat",
  logs = "logs",
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...rest } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...rest}
    >
      {value === index && children}
    </div>
  );
}

export function ExtraDataPanel(): ReactElement {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(namespace);
  const isEditorUploading = useAppSelector((state) => state.ExtraDataReducer.isEditorUploading)
  const displayExtraDataOfTaskById = useAppSelector((state) => state.ExtraDataReducer.displayExtraDataOfTaskById);
  const isMentionsDialogOpen = useAppSelector((state) => state.ExtraDataReducer.isMentionsDialogOpen)
  const isNewSubItemDialog = useAppSelector((state) => state.ExtraDataReducer.isNewSubItemDialog)
  const previousTaskId = useAppSelector((state) => getPreviousOrNextTaskIdByCurrentTaskId(state, displayExtraDataOfTaskById, 'previous'))
  const nextTaskId = useAppSelector((state) => getPreviousOrNextTaskIdByCurrentTaskId(state, displayExtraDataOfTaskById, 'next'))
  const task = useAppSelector((state) => tasksSelectors.selectById(state, displayExtraDataOfTaskById));
  const isTaskModalOpen = useAppSelector((state) => state.TasksReducer.isTaskModalOpen);

  const [displayMode, setDisplayMode] = useState('chat')

  const handlePrevious = () => {
    dispatch(setExtraDataOfTaskId(previousTaskId));
    updateUrlWithTaskId(previousTaskId)

  }
  const currentBoardView = useAppSelector((state) => state.TasksReducer.currentBoardView)


  const handleNext = () => {
    dispatch(setExtraDataOfTaskId(nextTaskId));
    updateUrlWithTaskId(nextTaskId)
  }

  const updateUrlWithTaskId = (taskId: string) => {
    let url = new URL(window.location.href);
    url.searchParams.delete('taskId');
    let newUrl = url + `&taskId=${taskId}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }

  return (
    <Stack direction='column' onClick={() => {
      if (isNewSubItemDialog && !isMentionsDialogOpen) {
        dispatch(setIsNewSubItemDialog(''))
      }
    }}>
      <Stack direction='row' sx={{
        display: 'flex',
        justifyContent: 'space-between',
        background: "#fff"
      }}>
        {/* <div className={classes.task_name}>
          {
            currentBoardView.type === 'board' && <EditTaskInput
              taskId={task?._id}
              boxSx={{
                whiteSpace: "",
                textOverflow: "",
                overflow: "",
                fontSize: "24px",
              }}
              inputSx={{
                fontSize: "24px",
                whiteSpace: "",
                textOverflow: "",
                overflow: "",
              }}
            />
          }
        </div> */}
        {/* <Stack direction='row'>
          <IconButton onClick={() => setDisplayMode('chat')} className={classes.nav_button}>
            <ChatOutlined /> Chat
          </IconButton>
          <IconButton onClick={() => setDisplayMode('log')} className={classes.nav_button}>
            <PsychologyOutlined /> Log
          </IconButton>
        </Stack> */}
        {
          !isTaskModalOpen &&
          <Stack spacing={1} direction='row' sx={{ position: "absolute", top: 9, right: 18 }}>
            {previousTaskId !== displayExtraDataOfTaskById &&
              <Tooltip arrow title={t('extraPanelData.previousTask')}>
                <IconButton onClick={() => handlePrevious()} className={classes.nav_button}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            }

            {nextTaskId !== displayExtraDataOfTaskById &&
              <Tooltip arrow title={t('extraPanelData.nextTask')}>

                <IconButton onClick={() => handleNext()} className={classes.nav_button}>
                  <ArrowForward />
                </IconButton>
              </Tooltip>
            }
          </Stack>


        }

      </Stack>


      <div>
        {/* {isEditorUploading && (
          <div className={classes.upload_status}>
            <div className={classes.upload_status_card}>
              <CircularProgress size={35} />
            </div>
          </div>
        )} */}


        <ExtraDataPanelChat />




      </div >
    </Stack >
  );
}


export const LogStack = ({ children, ...rest }) => {
  return (
    <Stack direction='row' gap={'1px'} {...rest} sx={{
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}>
      {children}
    </Stack>
  )
}
