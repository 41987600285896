import { AccessFormPopup } from "@components/access-form-popup/access-form-popup";
import { AddScheduledMessageButton } from "@components/add-scheduled-message-button/add-scheduled-message-button";
import ChatMessages from "@components/chat-messages/chat-messages";
import LabelsModals from "@components/labels-feature/labels-modals";
import TopHeader from "@components/labels-feature/top-header";
import { ScheduledContactList } from "@components/scheduled-contact-list/scheduled-contact-list";
import { SubscriptionUpgradePopup } from "@components/subscription-upgrade-popup/subscription-upgrade-popup";
import { Box, Stack } from "@mui/system";
import { DBBoardsThunks, DBCampaignThunks } from "@reducers/DBServiceReducers";
import { setWAcontacts } from "@reducers/WhatsAppReducer";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { RootState } from "@store/index";
import QrHowToScan from "@views/qr-view/qr-how-to-scan";
import QrModal from "@views/qr-view/qr-modal";
import ConditionalWrapper from "injections/ConditionalWrapper";
import { useEffect } from "react";
import WhatsappContactList from "./whatsapp-contact-list";
import WhatsappFooter from "./whatsapp-footer";
import StartChatWithPhoneNumber from "@components/start-chat-with-phone-number/start-chat-with-phone-number";

export interface AccountWorkspacesViewProps { }

const WhatsappView = () => {
  const dispatch = useAppDispatch();
  const currentChatId = useAppSelector((state) => state.WhatsAppReducer.currentChat?.id?._serialized);
  const currentChatName = useAppSelector((state: RootState) => state.WhatsAppReducer.currentChat?.name)

  useEffect(() => {
    dispatch(setWAcontacts([{
      id: {
        _serialized: "972544325389@c.us",
      },
      name: "Test",
      notifyName: "Test",
      displayName: "Test",
      formattedTitle: "Test",
      imageUrl: "https://www.w3schools.com/howto/img_avatar.png",
      isWAContact: true,
      isMyContact: true,
      getProfilePicThumb: () => { return {} }
    }, {
      id: {
        _serialized: "972548275828@c.us",
      },
      name: "NOAM MAZUZ",
      notifyName: "NOAM MAZUZ",
      displayName: "NOAM MAZUZ",
      formattedTitle: "NOAM MAZUZ",
      imageUrl: "https://www.w3schools.com/howto/img_avatar.png",
      isWAContact: true,
      isMyContact: true,
      getProfilePicThumb: () => { return {} }
    }, {
      id: {
        _serialized: "972528874114-1597620113@g.us",
      },
      name: "הצפות",
      notifyName: "הצפות",
      displayName: "הצפות",
      formattedTitle: "הצפות",
      imageUrl: "https://www.w3schools.com/howto/img_avatar.png",
      isWAContact: true,
      isMyContact: true,
      getProfilePicThumb: () => { return {} }
    }]))

    dispatch(DBCampaignThunks.find({}))
    dispatch(DBBoardsThunks.find({
      type: 'audience'
    }))
  }, [dispatch])


  const chatBox = () => {
    return <Stack>
      <span>Current chat: {currentChatName}</span>
      <ChatMessages />

      <Stack direction={"row"} style={{ marginTop: "25px" }}>
        <WhatsappFooter></WhatsappFooter>
        <AddScheduledMessageButton />
      </Stack>
    </Stack>
  }

  return (
    <div >
      <TopHeader />
      <Stack direction="row" >
        <Stack
          sx={{
            width: "500px",
            borderRight: "1px solid #d3d3d3",
            padding: "10px"
          }}>

          {/* <Box sx={{
            width: "200px",
            borderBottom: "1px solid #d3d3d3",
            paddingBottom: "15px",
            marginBottom: "15px",
          }}>

            <TopMenu />
          </Box> */}

          <ConditionalWrapper shouldLoadSelector={(state) => state.MainReducer.scheduledContactListOpen}>
            <ScheduledContactList />
          </ConditionalWrapper>
          <WhatsappContactList />


        </Stack>

        <Box>
          {currentChatId && chatBox()}
          {!currentChatId && <Box>No chat selected</Box>}
        </Box>
      </Stack>


      <AccessFormPopup />
      <SubscriptionUpgradePopup />

      <QrModal />
      <QrHowToScan />
      <LabelsModals />
      <StartChatWithPhoneNumber />
    </div>


  );
};

export default WhatsappView;
