import { ContactLabel } from "@models/contact-label"
import { contactsLabelsSelectors, contactsLabelsSelectByQuery, _labelSelectSelectOneFieldByQuery } from "@reducers/DBServiceReducers"
import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "@store/index"
import { getAllContactIds } from "./helper-functions"


// getContactIdsByLabelId selector
export const getContactIdsByLabelId = createSelector(
    [(data) => data as { state: RootState, labelId: string }],
    (params) => {
        const chatLabels: ContactLabel[] = contactsLabelsSelectors.selectAll(params.state)
        const chatsToShow = []
        const filtered = chatLabels.filter((chatLabel) => chatLabel.labelId === params.labelId)

        filtered.forEach((contactLabel) => {
            if (chatsToShow.includes(contactLabel.waChatId)) return
            chatsToShow.push(contactLabel.waChatId)
        })
        return chatsToShow
    })

// getContactLabelsByName selector 
export const getContactLabelsByName = createSelector(
    [(data) => data as { state: RootState, labelName: string }],
    (params) => {
        const contactLabels: ContactLabel[] = contactsLabelsSelectors.selectAll(params.state)
        const filteredContactLabels = contactLabels.filter((chatLabel) => chatLabel.labelName === params.labelName)
        return filteredContactLabels
    })


// getInboxContactIds selector 
export const getInboxContactIds = createSelector(
    [(state) => state as RootState,],
    (state,) => {
        const chatLabels: ContactLabel[] = contactsLabelsSelectors.selectAll(state)
        const contactList = state.WhatsAppReducer.contactList
        const contactListIds = [...getAllContactIds(contactList)]
        const filteredSnoozed = chatLabels.filter((snoozedContact) => snoozedContact.labelName === 'snoozed' || snoozedContact.labelName === 'archived')

        if (filteredSnoozed?.length) {
            filteredSnoozed.forEach((item) => {
                const id = contactListIds.find((waChatId) => waChatId === item.waChatId)
                if (id) {
                    const index = contactListIds.indexOf(id);
                    contactListIds.splice(index, 1)
                }
            })
        }

        return contactListIds
    })


export const getUnreadContactIds = createSelector(
    [(state) => state as RootState,],
    (state,) => {
        const contactList = state.WhatsAppReducer.contactList
        const contactIds: string[] = []
        contactList.forEach((contact) => {
            if (contact)
                contactIds.push(contact.id._serialized)
        })
        if (contactIds) return contactIds
    })


export const getBadgeNameAndCountByWaChatId = createSelector(
    [(state) => state as { state: RootState, waChatId }],
    (params,) => {
        if (!params?.waChatId) return { name: null, count: 0, waChatId: null }
        const contactLabelBadges = contactsLabelsSelectByQuery(
            params?.state.DBContactsLabelsReducer, {
            waChatId: params?.waChatId,
            labelType: 'custom'
        })

        if (contactLabelBadges) {
            const firstItem = contactLabelBadges[0]
            const icon = _labelSelectSelectOneFieldByQuery(params.state.DBLabelReducer, { _id: firstItem?.labelId }, 'icon')
            if (icon) {
                return {
                    name: `${icon} ${firstItem?.labelName}`,
                    count: contactLabelBadges.length,
                    waChatId: params.waChatId
                }
            }
        } else {
            return { name: null, count: 0, waChatId: null }
        }
    })

export const getSelectedContactLabelB = createSelector(
    [(state) => state as { state: RootState, waContactId, labelName: string }],
    (params,) => {
        const contactLabels = contactsLabelsSelectors.selectAll(params.state)
        const isStarred = contactLabels.find((item) => item.labelName === params.labelName && item?.waContactId === params.waContactId)
        if (isStarred) {
            return isStarred
        }


    })


export const getInboxCount = createSelector(
    [(state) => state as RootState,],
    (state,) => {
        const contactListIds = getInboxContactIds(state)
        return contactListIds.length
    })


export const getContactLabelsCount = createSelector(
    [(data) => data as { state: RootState, labelId: string },],
    (params) => {
        const contactLabels = contactsLabelsSelectByQuery(params.state.DBContactsLabelsReducer, {
            labelId: params.labelId
        })
        return contactLabels.length
    })

