import BulkTemplateDialog from '@components/bt-bulk-dashboard/bulk-template-dialog';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { Box, Button, Modal } from '@mui/material';
import { setIsTemplateDropdownModalOpen } from '@reducers/CampaignReducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import React, { useState } from 'react';



export default function MessageEditorTemplateDropdown() {
  const dispatch = useAppDispatch()
  const buttonRef = React.useRef(null);

  const isTemplateDropdownModalOpen = useAppSelector((state) => state.CampaignReducer.isTemplateDropdownModalOpen)

  const [position, setPosition] = useState({ top: 0, left: 0 });



  const handleOpenBulkTemplateDialog = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX
      });
    }
    dispatch(setIsTemplateDropdownModalOpen(true));
  };

  const handleCloseBulkTemplateDialog = () => {
    dispatch(setIsTemplateDropdownModalOpen(false));
  };




  return (
    <div>
      <Button
        ref={buttonRef}
        onClick={handleOpenBulkTemplateDialog}
        sx={{
          backgroundColor: 'var(--campaign-template-dropdown-button-bg)', // Adjust the color as needed
          color: 'white',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          borderRadius: '16px',
          fontWeight: 'medium',
          fontSize: '12px',
          padding: '6px 16px',
          '&:hover': {
            backgroundColor: 'var(--campaign-template-dropdown-button-hover)',  // Slightly darker shade for hover
          }
        }}
      >
        Templates
        <ExpandMoreOutlined style={{
          fontSize: '22px',
          marginInlineEnd: '-6px',
        }} />
      </Button>
      <Modal
        open={isTemplateDropdownModalOpen}
        onClose={handleCloseBulkTemplateDialog}
        style={{
          position: 'absolute',
          top: `${position.top}px`,
          left: `${position.left}px`,
          direction: 'ltr'
        }}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'transparent',
            }
          }
        }}
      >
        <Box sx={{ width: 500, p: 2, bgcolor: 'var(--campaign-template-modal-bg)', borderRadius: '12px', boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.1)' }}>
          <BulkTemplateDialog
            handleCloseBulkTemplateDialog={handleCloseBulkTemplateDialog} />
        </Box>
      </Modal>
    </div>
  )
}