import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { CSSProperties } from 'react';
import { NotificationStack } from './notification-row';

type Props = {
    notificationId: string;
}

export default function TimelineColumnNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))

    const cellWrapperStyle: CSSProperties = {
        pointerEvents: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center',
        paddingBlockStart: '3px',
        paddingInline: '8px'
    }

    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === notification.notificationUpdateType
    );

    let status
    if (!notification.oldValue && notification.newValue) { status = 'add data' }
    if (notification.oldValue && !notification.newValue) { status = 'remove data' }
    return (
        <NotificationStack direction='row'>
            {status === 'add data' &&
                <>
                    <span>{notification.actionType}&nbsp;</span>
                    <span>to</span>
                    <b style={cellWrapperStyle}><CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={notification.newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></b>
                </>
            }
            {status === 'remove data' &&
                <>
                    <span>removed&nbsp;</span>
                    <b style={cellWrapperStyle}><CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={notification.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></b>
                </>
            }
            <span>in&nbsp;</span>
            <span style={{ fontWeight: 'bold' }}>{notification.taskName}-</span>
            <span style={{ fontWeight: 'bold' }}> {notification.actionOnEntityData} </span>
        </NotificationStack>
    )
}