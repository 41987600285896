import { Box, Skeleton, Stack } from "@mui/material";
import { isTempId, taskColumnOptionSelectByQuery } from "@reducers/DBServiceReducers";
import { useAppSelector } from "@store/hooks";
import { RootState } from "@store/index";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { CellProps } from "../cell-interface";
import classes from "./option-picker.module.scss";
import Lottie from "lottie-react";
import Checkmark from '@assets/lottie/checkmark.json';

function OptionPickerCell({ taskId, cellValue, columnId, boardId, togglePicker, sx, mockValue, readOnly }: CellProps) {
  const pickerAnchorId = `options_${taskId}_${columnId}`;
  const [showAnimation, setShowAnimation] = useState(false);
  const previousColumnId = usePrevious(columnId);
  const firstRender = useRef(true); // useRef to track the first render

  const columnOptions = useAppSelector((state: RootState) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
    boardId,
    columnId,
    $or: [{ deleted: { $exists: false } }, { deleted: false }],
  }))
  const option = useMemo(() => columnOptions.find((opt) => opt._id && !isTempId(opt._id) && opt._id === cellValue), [columnOptions, cellValue]) ?? mockValue

  const defaultOption = useMemo(() => columnOptions.find((opt) => opt.isDefaultLabel), [columnOptions, cellValue])
  // const columnType = useAppSelector((state: RootState) => taskColumnSelectOneFieldByQuery(state.DBTaskColumnReducer, { _id: columnId }, 'type'))

  // const isComplexity = (columnType === 'complexity-picker') ? true : false
  const isComplexity = option?.complexity ? option?.complexity : mockValue?.complexity
  const optionLabel = option?.label ? option?.label : mockValue?.label ?? null;


  const styling: any = {
    height: 25,
    borderRadius: '5px',
    flex: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...sx
  }
  const bgColor = option?.bgcolor ? option?.bgcolor : mockValue?.bgcolor ?? 'var(--empty-picker-cell)'

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false; 
      return; 
    }
    if (optionLabel === "Done" && previousColumnId === columnId) {
      setShowAnimation(true);
      const timeoutId = setTimeout(() => setShowAnimation(false), 2000); 
      return () => clearTimeout(timeoutId);
    }

  }, [optionLabel,  columnId,  previousColumnId]);



  
  return (
    <Stack
      direction="row"
      sx={{
        width: 1,
        position: "relative",
        padding: sx ? '0px' : "0 5px",
        overflow: "hidden"
      }}
      id={pickerAnchorId}
      onClick={(event) => event.stopPropagation()}
      key={pickerAnchorId}
    >
      {isTempId(columnId) &&
        <Skeleton sx={{ background: 'var(--empty-picker-cell)' }} variant="rectangular" />
      }
      {!isTempId(columnId) && !isComplexity &&
        <button
          aria-haspopup="true"
          onClick={() => togglePicker({
            anchorId: pickerAnchorId,
            pickerType: 'option-picker'
          })}
          className={classes.pickerButton}
          style={{
            backgroundColor: bgColor,
            textTransform: "none",
            boxShadow: "none",
            color: "#fff",
            fontSize: 12,
            fontWeight: "600",
            fontFamily: "'Inter', 'Assistant', sans-serif",
            ...styling
          }}
          disabled={readOnly}
        >
          {showAnimation  &&
            <Box sx={{ position: "absolute", top: 0, right: 0, left: "0%", overFlow: "hidden" }}>
              <Lottie autoPlay={true} loop={false} animationData={Checkmark} style={{ width: '100%', height: '150px' }} />
            </Box>
          }
          {optionLabel ?? defaultOption?.label}
        </button>
      }
      {!isTempId(columnId) && isComplexity &&
        <button
          aria-haspopup="true"
          onClick={() => togglePicker({
            anchorId: pickerAnchorId,
            pickerType: 'option-picker'
          })}
          className={classes.pickerButton}
          style={{
            backgroundColor: bgColor,
            textTransform: "none",
            boxShadow: "none",
            color: "#fff",
            fontSize: 12,
            fontWeight: "600",
            fontFamily: "'Inter', 'Assistant', sans-serif",
            ...styling
          }}
          disabled={readOnly}
        >
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: '5px',
            paddingLeft: '5px',
          }}>
            <div>{optionLabel ?? defaultOption?.label}</div>&nbsp;
            <div style={{ border: '2px solid var(--border-color-two)', borderRadius: '5px', padding: '1px', minWidth: '15px' }}>{option?.complexity ? option?.complexity : mockValue?.complexity ?? ''}</div>
          </div>
        </button>
      }
    </Stack>
  );
}

export default memo(OptionPickerCell);

function usePrevious(value) {
  const ref = useRef();
  
  useEffect(() => {
    ref.current = value;
  }, [value]);
  
  return ref.current;
}