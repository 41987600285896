import { Dialog } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { AugmentedBlueticksBoardUser } from '@reducers/BoardsSelectors';
import { bulkActions } from '@reducers/BulkActionsReducer';
import { DBWorkloadThunks } from '@reducers/DBServiceReducers';
import { setExtraDataOfTaskId } from '@reducers/ExtraDataReducer';
import { taskActions } from '@reducers/TasksReducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import React, { useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd';
import KanbanCard from '../kanban/kanban-card';
import UserCard from './user-card';
import { workloadSortedUserList } from '@reducers/TaskSelectors';
import { updateTaskOrderInWorkloadUserThunk } from '@reducers/TaskThunks';

type Props = {
  boardId: string;
  workloadId: string;
}

export default function BTWorkload({ boardId, workloadId }: Props) {


  const userList: AugmentedBlueticksBoardUser[] = useAppSelector(state => workloadSortedUserList(state, workloadId));

  const dispatch = useAppDispatch()

  const [open, setOpen] = useState(false);



  const setKanbanCard = (event: React.MouseEvent<HTMLElement>, taskId) => {
    dispatch(taskActions.setTaskViewSideDrawerOpenWith(""));
    setOpen(true);
    // set taskId for extra data
    dispatch(setExtraDataOfTaskId(taskId));
  }


  const handleOnDragEnd = (result, provided) => {

    if (!result.destination) {
      return;
    }

    if (result.source.droppableId === "person") {
      const userId = result.draggableId
      const user = userList.find((option) => {
        return option._id === userId
      })
      const finalOrder = [...userList.filter(user => user?._id && user?._id !== userId)]

      finalOrder.splice(result.destination.index, 0, user);

      let usersOrder = {}
      const newUsersOrder = finalOrder.map((user, userIndex) => ({
        NullableId: user?._id, number: userIndex
      }))
      for (let i in newUsersOrder) {
        usersOrder = {
          ...usersOrder,
          [newUsersOrder[i].NullableId]: newUsersOrder[i].number
        }
      }

      dispatch(
        DBWorkloadThunks.patch({
          entity: {
            _id: workloadId,
            usersOrder,
          }
        })
      )

    }
    else {
      dispatch(updateTaskOrderInWorkloadUserThunk({
        draggedTaskId: result.draggableId.split('_')[0],
        sourceUserId: result.source.droppableId,
        destinationUserId: result.destination.droppableId,
        workloadId: workloadId,
        destinationIndex: result.destination.index,
      }))

    }
  }



  return (
    <>
      <Box sx={{
        backgroundColor: open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: open ? 9 : -9,
      }} />
      <DragDropContext onDragEnd={(result: DropResult, provided: ResponderProvided) => handleOnDragEnd(result, provided)}>
        <Dialog open={open} onClose={() => {
          setOpen(false)
          dispatch(bulkActions.setIsBulkActionsDialogOpen(false))
          dispatch(bulkActions.toggleMoveTo(false))
          dispatch(bulkActions.setMovingTo(""))
          dispatch(bulkActions.setBulActionsMoveToId(''))
          taskActions.toggleTaskMenu('')
        }}
        sx={{
          "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                  width: '950px',
                  maxWidth: "100%",
                  boxSizing: 'border-box',
              },
          },
      }}
        >
          <KanbanCard />
        </Dialog>



        <Droppable droppableId='person' direction="horizontal">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef} sx={{
              p: 5,
            }}>
              <Stack direction="row" spacing={2} sx={{
                overflow: 'visible',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}>
                {
                  userList
                    .map((user, userIndex) => {
                      return (
                        <Draggable
                          key={`${user?._id}_amit`}
                          draggableId={user?._id}
                          index={userIndex}
                          isDragDisabled={false}>
                          {(provided, snapshot) => (
                            <UserCard
                              snapshot={snapshot}
                              provided={provided}
                              boardId={boardId}
                              workloadId={workloadId}
                              userId={user?._id}
                              setKanbanCard={setKanbanCard}
                            />
                          )
                          }
                        </Draggable>
                      )
                    })}
                {provided.placeholder}
              </Stack>

            </Box>
          )}
        </Droppable>
      </DragDropContext >
    </>
  )
}