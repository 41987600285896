import { useAppDispatch, useAppSelector } from "@store/hooks";
import { ClockUI } from "./focus-timer-or-stopwatch";
import { selectNewTabFocusByOwnerId } from "@reducers/NewTabFocusThunks";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { DBNewTabFocusThunks } from "@reducers/DBServiceReducers";

export function Timer() {
    const dispatch = useAppDispatch();
    const tabData = useAppSelector(selectNewTabFocusByOwnerId);
    const defaultExpiryTime = new Date(new Date().getTime() + 600 * 2500);
    const initialExpiryTimestamp = tabData && tabData.remainingTime !== null && !tabData.isRunning
      ? new Date(new Date().getTime() + tabData.remainingTime)
      : new Date(tabData?.expiryTimestamp || defaultExpiryTime);
  
    const [expiryTimestamp, setExpiryTimestamp] = useState(initialExpiryTimestamp);
  
    const timer = useTimer({
      expiryTimestamp,
      onExpire: () => console.warn('Timer expired'),
      autoStart: false,
    });
  
    // Function to save timer state to Redux and DB
    const saveTimerStateToDB = ({ expiryTimestamp, remainingTime, pauseTimestamp, isRunning }) => {
      dispatch(DBNewTabFocusThunks.patch({
        entity: {
          _id: tabData._id,
          expiryTimestamp: expiryTimestamp ? expiryTimestamp.getTime() : null,
          remainingTime: remainingTime,
          pauseTimestamp: pauseTimestamp,
          isRunning: isRunning
        }
      }));
    };
  
    // Start or resume the timer
    const handleStart = () => {
      const currentTime = new Date().getTime();
      let newExpiryTimestamp;
  
      if (tabData?.remainingTime) {
        newExpiryTimestamp = new Date(currentTime + tabData.remainingTime);
      } else {
        newExpiryTimestamp = new Date(currentTime + 600 * 2500);
      }
  
      setExpiryTimestamp(newExpiryTimestamp);
  
      saveTimerStateToDB({
        expiryTimestamp: newExpiryTimestamp,
        remainingTime: null,
        pauseTimestamp: null,
        isRunning: true
      });
  
      timer.restart(newExpiryTimestamp);
    };
  
  
    const handlePause = () => {
      const currentTime = new Date();
      const remainingTime = expiryTimestamp.getTime() - currentTime.getTime();
      const pauseTimestamp = currentTime.getTime();
      setExpiryTimestamp(new Date(currentTime.getTime() + remainingTime));
  
      saveTimerStateToDB({
        expiryTimestamp: new Date(currentTime.getTime() + remainingTime),
        remainingTime: remainingTime,
        pauseTimestamp: pauseTimestamp,
        isRunning: false
      });
      timer.pause();
  
    };
  
  
    const handleReset = () => {
      const resetTime = defaultExpiryTime;
      setExpiryTimestamp(resetTime);
      timer.restart(resetTime);
    };
  
    const handleTimeEdit = (editedTime) => {
      const { days, hours, minutes, seconds } = editedTime;
      let newTime = new Date();
      newTime.setDate(newTime.getDate() + parseInt(days, 10));
      newTime.setHours(newTime.getHours() + parseInt(hours, 10));
      newTime.setMinutes(newTime.getMinutes() + parseInt(minutes, 10));
      newTime.setSeconds(newTime.getSeconds() + parseInt(seconds, 10));
  
      const currentTime = new Date().getTime();
      const newExpiryTimestamp = new Date(currentTime + newTime.getTime() - new Date().getTime());
  
      setExpiryTimestamp(newExpiryTimestamp);
  
      const isTimerRunning = tabData?.isRunning;
  
      if (isTimerRunning) {
        timer.restart(newExpiryTimestamp);
      } else {
        timer.pause();
      }
  
  
      saveTimerStateToDB({
        expiryTimestamp: newExpiryTimestamp,
        remainingTime: isTimerRunning ? null : newExpiryTimestamp.getTime() - currentTime,
        pauseTimestamp: isTimerRunning ? null : currentTime,
        isRunning: isTimerRunning
      });
  
      // Notify changes
    };
  
  
    useEffect(() => {
      if (tabData  ) {
        const tabInfo = tabData;
  
        if (tabInfo.remainingTime !== null && !tabInfo.isRunning) {
          const currentTime = new Date().getTime();
          let newExpiryTimestamp;
  
          if (tabData?.remainingTime) {
            newExpiryTimestamp = new Date(currentTime + tabData.remainingTime);
          } else {
            newExpiryTimestamp = new Date(currentTime + 600 * 2500); // Default 10 minutes
          }
  
          setExpiryTimestamp(newExpiryTimestamp);
  
  
          timer.pause();
  
        } else {
          const expiryTime = tabInfo.expiryTimestamp !== null ? new Date(tabInfo.expiryTimestamp) : new Date(defaultExpiryTime);
          setExpiryTimestamp(expiryTime);
          if (tabInfo.isRunning) {
            timer.restart(expiryTime);
          } else {
            timer.restart(expiryTime);
            timer.pause();
  
          }
        }
      }
    }, [tabData]);
  
  
    return (
      <ClockUI
        days={timer.days}
        hours={timer.hours}
        minutes={timer.minutes}
        seconds={timer.seconds}
        isRunning={timer.isRunning}
        onStart={handleStart}
        onPause={handlePause}
        onReset={handleReset}
        onTimeEdit={handleTimeEdit}
      />
    );
  }