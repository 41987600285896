import { Box } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { handleTimeAndDateTabOnChangeEventThunk } from '@reducers/CampaignThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import moment from 'moment';
import React, { useMemo } from 'react';


export default function BlueticksDatePicker() {
    const dispatch = useAppDispatch();
    const newCampaign = useAppSelector((state) => state.CampaignReducer.newCampaign);
    const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

    let isDueTimeDisablePast = useMemo(
        () => !newCampaign?.template?.calendarMessage,
        [newCampaign?.template?.calendarMessage]
    );

    let campaignDueTime = useMemo(() => {
        const due = newCampaign?.schedule?.dueDate && moment(newCampaign?.schedule?.dueDate);
        if (due) return isDueTimeDisablePast && due.isBefore(moment()) ? moment() : due;

        return null;
    }, [isDueTimeDisablePast, newCampaign?.schedule?.dueDate]);


    const handleDatePickerChange = (date) => {
        if (date?.isValid()) {
            campaignDueTime?.isValid() && date.hour(campaignDueTime.hour()).minute(campaignDueTime.minute());

            const time = date.toISOString();

            dispatch(handleTimeAndDateTabOnChangeEventThunk({
                changeType: 'setScheduleDueDate',
                changeObject: time,
            }));
        }
    };

    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    disabled={newCampaign?.schedule?.isNow}
                    disablePast
                    openTo="day"
                    views={['year', 'month', 'day']}
                    value={moment(newCampaign?.schedule?.dueDate)}
                    onChange={(newValue) => {
                        handleDatePickerChange(newValue);
                    }}
                    open={isDatePickerOpen}
                    onOpen={() => setIsDatePickerOpen(true)}
                    onClose={() => setIsDatePickerOpen(false)}
                    format={'DD/MM/YYYY'}
                    sx={{
                        width: '140px',
                        '& .MuiInputAdornment-root': {
                            '& button': {
                                color: !newCampaign?.schedule?.isNow ? '#35BE5F80 !important' : 'var(--campaign-time-and-date-disabled) !important',
                                margin: '0px 0px 0px -40px !important'
                            }
                        },
                        '& .MuiOutlinedInput-root': {
                            '& input': {
                                padding: 0,
                            },
                            '& fieldset': {
                                borderColor: 'transparent !important',
                                padding: 0,
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                            },
                        },
                    }}
                />
            </LocalizationProvider>
        </Box>
    )
}