import BTButton from "@components/bt-button/bt-button";
import BtEditableBox from "@components/tasks/bt-editable-box/bt-editable-box";
import { PersonAvatar } from "@components/tasks/person-avatar/person-avatar";
import { Board } from "@models/board";
import { AvatarGroup, Menu, Tooltip, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/system";
import {
  boardsActions
} from "@reducers/BoardsReducer";
import { getSelectedBoardCompleteUserList, getSelectedBoardId, isCurrentUserWorkspaceOwnerOrMember } from "@reducers/BoardsSelectors";
import { DBBoardsThunks, boardsSelectors, isTempId } from "@reducers/DBServiceReducers";
import { mainSliceActions } from "@reducers/MainReducer";
import { pickerDialogActions } from "@reducers/PickerDialogReducer";
import { taskActions } from "@reducers/TasksReducer";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@services/analytics-service";
import EmojiPicker from "emoji-picker-react";
import React, { ReactElement, useEffect } from "react";
import { BsPeopleFill, BsReverseListColumnsReverse, BsThreeDots } from "react-icons/bs";
import { TbArrowBigUp } from "react-icons/tb";
import { Link } from "react-router-dom";

import { recycleActions } from "@reducers/RecycleBinReducer";
import { activeSubscriptionSelector } from "@reducers/UserSelectors";
import { isExtensionContextOrWhatsappView } from "@services/detect-context";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";
import { theme } from "theme";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import classes from "./tasks-header.module.scss";
import ToggleDarkMode from "./toggle-dark-mode/toggle-dark-mode";
import NotificationsButton from "./notifications-button";
type Props = {
  isDisplayTasksHeader?: boolean;
  isDisplayBoardNameAndIcon?: boolean;
  isDisplaySharedUsersAvatar?: boolean;
  isDisplayShareButton?: boolean;
  isDisplayMenuButton?: boolean;
  isDisplayDarkBrightModeButton?: boolean;
  isDisplayUpgradeButton?: boolean;
}
export default function TasksHeader({
  isDisplayTasksHeader = true,
  isDisplayBoardNameAndIcon = true,
  isDisplaySharedUsersAvatar = true,
  isDisplayShareButton = true,
  isDisplayMenuButton = true,
  isDisplayDarkBrightModeButton = true,
  isDisplayUpgradeButton = true,
}: Props): ReactElement {
  const { t } = useTranslation(namespace);
  const [emojiAnchorEl, setEmojiAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(emojiAnchorEl);
  const handleEmojilClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEmojiAnchorEl(event.currentTarget);
  };
  const handleEmojiClose = () => {
    setEmojiAnchorEl(null);
  };
  const analyticsService: AnalyticsService = useAnalyticsService();

  // const isDesktopGreaterThen1300 = useMediaQuery(theme.breakpoints.up(1300));
  const dispatch = useAppDispatch();
  const selectedBoardId = useAppSelector(getSelectedBoardId);
  //const getActiveWorkSpaceMembers = useAppSelector(getWorkspaceMembers);
  //const canInviteUsers = isCurrentBoardOwner || isCurrentWorkspaceOwner;
  const canInviteUsers = useAppSelector(isCurrentUserWorkspaceOwnerOrMember);
  const isBoardRenameForced = useAppSelector((state) => state.BoardsReducer.isRenameBoard)
  const workspaceSubscription = useAppSelector(activeSubscriptionSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const setEditMode = (value) => {
    if (value === false) {
      dispatch(boardsActions.setRenameBoard(false))
    }
  }


  useEffect(() => {
    // dispatch(taskActions.setIsTasksPanelOpen(true)); setting isTasksPanelOpen to true here will mess up the bulk campaign feature in the extension, as it renders <TaskView/>
  }, []);

  const archiveOrDeletedAlertType = useAppSelector(state => state.RecycleBinReducer.archiveOrDeletedAlertType);

  const openArchive = () => {

    dispatch(recycleActions.setArchiveShown(false));
    dispatch(recycleActions.setArchivedOrDeletedData([]));
    dispatch(recycleActions.setSearchText(''));
    dispatch(recycleActions.setArchivedOrDeletedBulkItems([]));
    dispatch(recycleActions.setArchivedOrDeletedSnackMessage({ message: '', type: archiveOrDeletedAlertType }))
    dispatch(recycleActions.setFilters({
      type: [],
      date: '',
      board: []
    }));
    dispatch(recycleActions.setArchiveShown(true));


  }


  const board: Board = useAppSelector((state) =>
    boardsSelectors.selectById(state, selectedBoardId)
  );
  const userList = useAppSelector(getSelectedBoardCompleteUserList);

  console.log('userList:', userList)
  // const currentBoardView = useAppSelector((state) => state.TasksReducer.currentBoardView)
  // const [rename, setRename] = useState<Boolean>(false)
  // const open = Boolean(anchorElementId);

  const extraFilesTheme = {
    ".MuiAvatar-circular": {
      height: "32px",
      width: "32px",
      background: "var(--primary)",
      color: "white",
      fontSize: "10px",
      boxShadow: "rgb(0 0 0 / 10%) 2px 2px 2px",
      border: "1px solid rgb(186, 186, 186)",
    },
  };

  function openMenu(event, board: Board) {
    dispatch(
      pickerDialogActions.setPickerTypeAndId({
        pickerAnchorId: "task-header-board-menu-options",
        pickerProps: {
          boardId: board._id,
          type: "board-menu-options",
        },
      })
    );
  }

  const openInviteModal = () => {

    dispatch(mainSliceActions.inviteModalOpen(true))
    dispatch(taskActions.setTaskViewSideDrawerOpenWith("InviteUserModal"));


  }

  function saveChanges(value) {
    if (value) {
      dispatch(
        DBBoardsThunks.patch({
          entity: {
            ...board,
            name: value,
            isBoardNameEditting: false,
          },
        })
      );
      dispatch(boardsActions.openBoardItemMenu(false));
      dispatch(boardsActions.setMenuAnchorEl(null));
      dispatch(boardsActions.setRenameBoard(false))
      dispatch(boardsActions.setBoardEditModeByBoardId(""));
    }
  }
  const inputTheme = {
    color: "var(--primary)",
    width: "100%",
    padding: "0 10px",
    fontSize: "24px",
    fontWeight: "800",
    borderRadius: "8px",
    border: "1px solid #53bdeb",
    backgroundColor: "var(--secondary-background-color)",
    fontFamily: "'Poppins', 'Assistant', sans-serif",
    "& .MuiInputBase-input": {
      padding: "0",
    },
    // minWidth: 500
  };
  const textTheme = {
    color: "var(--primary)",
    fontSize: "24px",
    padding: "0 10px",
    borderRadius: "8px",
    fontWeight: "800",
    fontFamily: "'Poppins', 'Assistant', sans-serif",
    "&:hover": {
      border: "1px dashed var(--text-color)",
    },
    // minWidth: 200
  };

  const displayPanelSelectedBorder = '2px solid #579bfc';

  function setBoardIcon(arg1: any) {
    const emoji = arg1.emoji;
    dispatch(
      DBBoardsThunks.patch({
        entity: { ...board, titleIcon: `${emoji}` },
      })
    );
  }



  return (
    <>
      {isDisplayTasksHeader &&
        <Stack
          direction="row"
          className={classes.headerContainer}
          alignItems="center"
          justifyContent="space-between"
          flex="auto"
        >
          {/* left side */}
          {isDisplayBoardNameAndIcon &&
            <Stack direction='row' gap="16px" width="100%">
              {/* board name */}
              <span
                style={{ cursor: "pointer", fontSize: "24px" }}
                onClick={handleEmojilClick}
              >
                {board?.titleIcon ?? "😜"}
              </span>

              <Menu
                anchorEl={emojiAnchorEl}
                open={open}
                onClose={handleEmojiClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className={classes.setBoardIconPicker}>
                  <EmojiPicker onEmojiClick={setBoardIcon} />
                </div>
              </Menu>



              <BtEditableBox
                data-testid="tasks-header-board-name-bteditablebox"
                inputSx={inputTheme}
                disabled={isTempId(board?._id)}
                boxSx={textTheme}
                forceEditMode={isBoardRenameForced}
                text={board?.name}
                onValueUpdate={(value) => {
                  saveChanges(value);
                }}
                onEditModeChange={(value) => setEditMode(value)}

              />
            </Stack>
          }


          {/* right side */}
          <Stack direction="row" sx={{ marginInlineStart: 2 }}>
            <div className={classes.header_column_right}>
              {(

                <>
                  {isDisplaySharedUsersAvatar && !isMobile &&
                    <div className={classes.members}>
                      <AvatarGroup onClick={() => dispatch(mainSliceActions.inviteModalOpen(true))} sx={extraFilesTheme} max={5}>
                        {userList.sort(function (a, b) {
                          return a.hasOwnProperty("userId") ? -1 : b.hasOwnProperty("userId") ? 1 : 0
                        }).map((person, index) => (
                          <div style={{ opacity: person?.status === "active" ? "1" : '0.5' }} key={index} className={classes.member}>
                            <PersonAvatar
                              size={32}
                              email={person.email}
                              profileImg={person?.profile?.profileImg}
                              lastName={(person?.profile?.lastName === 'undefined' || person?.profile?.lastName === undefined) ? ' ' : person?.profile.lastName}
                              firstName={person?.profile?.firstName}
                              toolTip={true}
                            />
                          </div>
                        ))}
                      </AvatarGroup>
                    </div>
                  }
                  {board?.displayBetaFeatures && !isMobile &&
                    <>
                      <button
                        className={classes.button}
                        disabled={isTempId(board?._id)}
                        onClick={() => {
                          dispatch(mainSliceActions.inviteModalOpen(true))
                          dispatch(taskActions.setTaskViewSideDrawerOpenWith("ActivityLog"));
                        }
                        }
                      >
                        <BsReverseListColumnsReverse />
                        <span>{t('tasksHeader.activity')}</span>
                      </button>

                      <button
                        className={classes.button}
                        disabled={isTempId(board?._id)}
                        onClick={openInviteModal
                        }
                      >
                        <BsPeopleFill />
                        <span>{t('tasksHeader.shareBoard')}</span>
                      </button>
                    </>
                  }
                </>

              )}

              {isDisplayMenuButton && !isMobile && (

                <Tooltip title={t('tasksHeader.boardOptions')} placement="bottom" arrow >

                  <button
                    id="task-header-board-menu-options"
                    className={classes.button + " " + classes.with_shadow_on_hover}
                    disabled={isTempId(board?._id)}
                    onClick={(e) => openMenu(e, board)}
                  >
                    <BsThreeDots />
                  </button>
                </Tooltip>



              )}
            </div>
            {board?.displayBetaFeatures &&
              <NotificationsButton />
            }


            {isDisplayDarkBrightModeButton && !isMobile && <ToggleDarkMode />}
            {!workspaceSubscription && !workspaceSubscription?.isActive && !isExtensionContextOrWhatsappView() && isDisplayUpgradeButton && !isMobile &&
              <Link to="/account-subscription" onClick={() => {
                analyticsService.event('switch_to_upgrade', {
                  category: "upgradeFromTopHeader",
                  action: 'upgrade plan',
                });
              }}>
                <BTButton startIcon={<TbArrowBigUp size={"14"} />} sx={{ width: "150px", marginInlineStart: "8px" }} btvariant="pink"  >
                  {t('tasksHeader.upgrade')}
                </BTButton>
              </Link>
            }

            {/* <button
              className={classes.button}
              onClick={() => {
                openArchive()
              }
              }
            >
              <Archive />
              <span>Archive</span>
            </button> */}


          </Stack>
        </Stack >
      }
    </>
  );
}
