import BTTextField from "@components/atoms/text-field/bt-text-field";
import BTThemeProvider from "@components/bt-theme-provider";
import { Label, LabelContactInfo } from "@models/label";
import LabelIcon from "@mui/icons-material/Label";
import SearchIcon from "@mui/icons-material/Search";
import {
  Divider,
  InputAdornment,
  ListItem,
  ListItemText,
  Menu,
  MenuProps,
  Tooltip,
} from "@mui/material";
import { labelSelectByQuery } from "@reducers/DBServiceReducers";
import { useAppSelector } from "@store/hooks";
import React, { ReactElement, useState } from "react";
import { useAppDispatch} from "@store/hooks";
import {
  addRemoveLabelContactInfo,
  createLabelPopupOpen,
  labelsActions,
  manageLabelsOpen,
} from "../../reducers/LabelsReducer";
import { POP_DIRECTIONAL_FORTMATTING_UNICODE } from "../../reusable/common";
import { theme } from "../../theme";
import { LabelItemsList } from "../label-items-list/label-items-list";
import classes from "./contact-label-button.module.scss";

export interface ContactLabelButtonProps {
  chatId: string;
}

export interface ContactLabelButtonState {
  anchorEl: null | HTMLElement;
  dialogOpen: boolean;
  phone?: string;
  validPhone?: boolean;
  invalidWANumber: boolean;
  isVisible: boolean;
  labelItems: Array<Label>;
}

const StyledMenu = (props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    transformOrigin={{ vertical: "top", horizontal: "center" }}
    {...props}
  />
);

export default function ContactLabelButton(
  props: ContactLabelButtonProps
): ReactElement {
  const dispatch = useAppDispatch()

  const labelsFilter = useAppSelector(
    (state) => state.LabelsReducer.labelsFilter
  );
  let labelsFilterClone = { ...labelsFilter };
  if (labelsFilterClone.displayName) {
    labelsFilterClone.displayName = new RegExp(
      labelsFilterClone.displayName,
      "i"
    );
  }
  const labels = useAppSelector((state) =>
    labelSelectByQuery(state.DBLabelReducer, labelsFilterClone)
  );

  function filterBoards({ target }: any) {
    const nameFilter: string = target.value;
    dispatch(labelsActions.setLabelFilterName(nameFilter));
  }

  function getContactById(chatId) {
    return {
      id: chatId,
      name: "test-name",
      mobile: "test-mobile",
      firstName: "test-firstName",
      lastName: "test-lastName",
    };
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  function openDialog(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    setSearchText("");
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  }

  function onMouseDownHandle(e: any) {
    e.stopPropagation();
  }

  function handleClose() {
    setIsMenuOpen(false);
    setAnchorEl(null);
  }

  function handleCreateNewLabel(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    newLabel?: string
  ) {
    dispatch(createLabelPopupOpen(searchText));
    handleClose();
  }

  function openManageLabels(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    dispatch(manageLabelsOpen());
    handleClose();
  }

  function onLabelItemClick(label: Label) {
    dispatch(
      addRemoveLabelContactInfo({
        label: label,
        labelContactInfo: { id: props.chatId },
      })
    );
  }

  return (
    <BTThemeProvider>
      <Tooltip title="Open label menu">
        <div onClick={openDialog} onMouseDown={onMouseDownHandle}>
          <LabelIcon fontSize="small" />
        </div>
      </Tooltip>
      <StyledMenu
        id="contact-label-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
      >
        <div
          style={{
            padding: `${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
          }}
        >
          <p>{POP_DIRECTIONAL_FORTMATTING_UNICODE}Label as:</p>
          <BTTextField
            className={classes.margin}
            id="contact-input-with-icon-textfield"
            onChange={filterBoards}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        {labels === undefined || labels.length === 0 ? (
          <div key={"menu-empty-key-1"}></div>
        ) : (
          <LabelItemsList
            initialLabelItems={labels.map((label: Label, index: number) => ({
              label: label,
              selected:
                label?.waContactsIds?.findIndex(
                  (labelContactInfo: LabelContactInfo, index: number) =>
                    labelContactInfo.id === props.chatId
                ) !== -1,
            }))}
            onLabelItemClick={onLabelItemClick}
          />
        )}
        {searchText !== "" ? (
          true ? (
            <ListItem
              key={`list-items-label-bottom-segment-0`}
              role={undefined}
              dense
              button
              onClick={(event) => handleCreateNewLabel(event, searchText)}
            >
              <ListItemText
                id={`list-items-label-bottom-segment-id-0`}
                primary={`"${searchText}" (create new)`}
              />
            </ListItem>
          ) : (
            <div key={"menu-empty-key-2"}></div>
          )
        ) : (
          <div key={"contact-label-button-bottom-menu"}>
            <Divider />
            <ListItem
              key={`list-items-label-bottom-segment-1`}
              role={undefined}
              dense
              button
              onClick={(event) => handleCreateNewLabel(event)}
            >
              <ListItemText
                id={`list-items-label-bottom-segment-id-1`}
                primary={"Create new"}
              />
            </ListItem>
            <ListItem
              key={`list-items-label-bottom-segment-2`}
              role={undefined}
              dense
              button
              onClick={(event) => openManageLabels(event)}
            >
              <ListItemText
                id={`list-items-label-bottom-segment-id-2`}
                primary={"Manage labels"}
              />
            </ListItem>
          </div>
        )}
      </StyledMenu>
    </BTThemeProvider>
  );
}
