import { Subscription } from "@models/subscription";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { isExtensionContextOrWhatsappView } from "@services/detect-context";
import { AppDispatch, RootState } from "@store/index";
import Bowser from "bowser";
import { appConfig } from "config/app.config";
import moment from "moment";
import { io } from "socket.io-client";
import { DBReducerMap, DBSubscriptionThunks } from "./DBServiceReducers";
import { mainSliceActions } from "./MainReducer";
import { setSocketId } from "./UserReducer";
import { UpdateEvent } from "./WhatsappThunks";
import { NotifierActions } from "./NotifierReducer";
import { logoutWrapperThunk, validateSubscriptionsThunk } from "./UserThunks";
import { getProfilePicUrl } from "@services/extension/wapi-functions";
import { postMessageWithResponse } from "@services/post-message-to-whatsapp-functions";
import { btWaCommResponse } from "./backend-api/backend-api";
import { setContactPicMap } from "./CalendarReducer";


export const notifierDisconnectThunk = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("notifierDisconnectThunk", async (options, thunkApi) => {
  const state: RootState = thunkApi.getState();
  const dispatch: AppDispatch = thunkApi.dispatch;
  state.NotifierReducerReducer.notifierSocket?.disconnect();
  state.NotifierReducerReducer.notifierSocket.close();

  dispatch(setSocketId());
});

export const initializeNotifierConnectionThunk = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("initializeNotifierConnectionThunk", async (options, thunkApi) => {
  const state: RootState = thunkApi.getState();
  const dispatch: AppDispatch = thunkApi.dispatch;
  dispatch(notifierDisconnectThunk);

  const accessToken = state.UserReducer.accessToken;

  const parser = Bowser.getParser(window.navigator.userAgent);
  const browserInfo = parser.getBrowser();

  const isMobile = parser.is("mobile");
  const isTablet = parser.is("tablet");
  const isDesktop = parser.is("desktop");

  const notifierSocket = io(appConfig.NOTIFIER_ENDPOINT, {
    transports: ['websocket', 'polling'], // Use both transports
    upgrade: false,
    autoConnect: false,
  });

  dispatch(NotifierActions.setNotifierSocket(notifierSocket));
  notifierSocket.on("connect", () => {
    console.log(`Socket connected [${notifierSocket.id}]`);

    dispatch(setSocketId(notifierSocket.id));
    const isExtension = isExtensionContextOrWhatsappView();
    const connectedFrom = isExtension ? "extension" : "web";
    notifierSocket.emit(
      "authenticate",
      {
        accessToken,
        browserType: browserInfo.name,
        device: isMobile
          ? "Mobile"
          : isTablet
            ? "Tablet"
            : isDesktop
              ? "Desktop"
              : "Unknown",
        connectionTime: moment().format("DD/MM/YYYY HH:mm"),
        connectedFrom: connectedFrom,
        domain: window.location.href,
      },
      (newAuthResult) => {
        console.log(newAuthResult);

        dispatch(
          notifierSubscribeThunk({
            entityType: "boards",
            entityId: state.BoardsReducer.selectedBoardId,
          })
        );
      }
    );
  });

  notifierSocket.on("room-sockets", (sockets) => {
    console.log(sockets);
    dispatch(mainSliceActions.setDevices(sockets));
  });

  notifierSocket.on("logout", () => {
    dispatch(logoutWrapperThunk({ accessToken }))
  });

  notifierSocket.on("identify-socket", () => {
    alert("Current in use");
  });

  notifierSocket.on("error", () => {
    console.log("Socket error!");
    setTimeout(() => {
      dispatch(initializeNotifierConnectionThunk());
    }, 3000);
  });

  notifierSocket.on("update", (updateEvent: UpdateEvent) => {
    console.log(updateEvent);

    if (updateEvent?.entity?.qrcode) {
      let smallQr = updateEvent?.entity?.qrcode?.substring(25, 35)
      console.log('smallQr', smallQr);
    }


    const dbReducerSlice = DBReducerMap.get(updateEvent.path);
    if (!dbReducerSlice) {
      return;
    }

    switch (updateEvent.method) {
      case "create": {
        dispatch(dbReducerSlice.slice.actions.addOne(updateEvent.entity));
        break;
      }
      case "patch": {
        const state: RootState = thunkApi.getState();

        // check if the entity is already in the state
        const entity = dbReducerSlice.selectors.selectById(state, updateEvent.entity._id);

        if (entity) {
          dispatch(
            dbReducerSlice.slice.actions.updateOne({
              id: updateEvent.entity._id,
              changes: updateEvent.entity,
            })
          );
        } else {
          dispatch(dbReducerSlice.slice.actions.addOne(updateEvent.entity));
        }
        break;
      }
      case "remove": {
        dispatch(
          dbReducerSlice.slice.actions.removeOne(updateEvent.entity._id)
        );
        break;
      }
    }
  });


  notifierSocket.on("bt-wa-communication-socket-request", async (updateEvent: UpdateEvent) => {
    console.log('updateEvent', updateEvent);

    const waId = updateEvent.entity
    console.log('profilePic waId', waId);

    let profilePic: any = undefined
    if (waId) {
      profilePic = await postMessageWithResponse({ cmd: "getProfilePicUrl", contactId: waId });
      console.log('profilePic', profilePic);
    }

    if (profilePic) {
      dispatch(btWaCommResponse.initiate({ waId, profilePic }));
    }


  });


  notifierSocket.on("bt-wa-communication-socket-response", async (updateEvent: UpdateEvent) => {
    console.log('updateEvent', updateEvent);

    const response = updateEvent.entity
    console.log('profilePic profilePic', response);


    if (response) {
      dispatch(setContactPicMap([response.waId, response.profilePic]))
    }


  });

  // on sync-user-data event, get user subscriptions
  notifierSocket.on("sync-user-data", async (updateEvent: UpdateEvent) => {
    console.log('sync-user-data', updateEvent);

    // Essential for the app to work, must await before continuing
    const subscriptionListRes = await dispatch(DBSubscriptionThunks.find({}));
    const subscriptionList: Subscription[] = subscriptionListRes.payload;

    dispatch(validateSubscriptionsThunk(subscriptionList));
  });

  notifierSocket.connect();
});

export const notifierSubscribeThunk = createAsyncThunk<
  void,
  { entityType: string; entityId: string },
  { state: RootState }
>("notifierSubscribeThunk", async (params, thunkApi) => {
  const { entityType, entityId } = params;
  if (!entityType || !entityId) {
    return;
  }

  const state: RootState = thunkApi.getState();
  const notifierSocket = state.NotifierReducerReducer.notifierSocket;

  notifierSocket.emit("subscribe", {
    entity: entityType,
    id: entityId,
  });
});

export const notifierLogoutThunk = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("notifier/logout", async (socketId, thunkApi) => {
  const state: RootState = thunkApi.getState();
  const notifierSocket = state.NotifierReducerReducer.notifierSocket;
  notifierSocket.emit("logout-cmd", { socketId: socketId });
});


export const identifySocketThunk = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("notifier/identify", async (socketId, thunkApi) => {
  const state: RootState = thunkApi.getState();
  const notifierSocket = state.NotifierReducerReducer.notifierSocket;
  notifierSocket.emit("identify-cmd", { socketId: socketId });
});
