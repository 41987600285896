import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { labelActions } from '@reducers/labels-reducer';
import { ButtonBase, Checkbox, CircularProgress, MenuItem, colors } from '@mui/material';
import { Close } from '@mui/icons-material';
import TalkToUsFooter from './talktous-footer';
import { newLabelThunk } from '@reducers/labels-reducer/labels-thunks/crud';
import mainClasses from '../labels-feature.module.scss'
import classes from './modal.module.scss'
import LabelInput from '../label-input';



export default function SortChatsToNewLabel() {
    const dispatch = useAppDispatch()
    const chatList = useAppSelector((state) => state.WhatsAppReducer.chatList)
    const contactList = useAppSelector((state) => state.WhatsAppReducer.contactList)
    const label = useAppSelector((state) => state.LabelReducer.label)
    const isSortChatToLabelModalOpen = useAppSelector((state) => state.LabelReducer.isSortChatToLabelModalOpen)
    const labelNetWorkStatus = useAppSelector((state) => state.LabelReducer.labelNetWorkStatus)


    console.log(window.CopyStore)

    function handleOnChange() {

    }



    return (
        <div>

            <Modal
                open={isSortChatToLabelModalOpen}
                onClose={() => dispatch(labelActions.toggleSortChatToLabelModalOpen(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.sortChatToLabelcontainer}>
                    <Box className={classes.modalHead}>
                        <Typography id="modal-modal-title"
                            sx={{ flex: 1, fontWeight: 600 }} variant="h6" component="h2">
                            Sort Chat to new {label.name}
                        </Typography>

                        <ButtonBase
                            className={classes.closeModalButton}
                            sx={{ width: 35, height: 35 }}
                            onClick={() => dispatch(labelActions.toggleNewLabel(false))}
                        >
                            <Close sx={{ fontSize: '25px' }} />
                        </ButtonBase>
                    </Box>
                    <Box sx={{
                        height: 45,
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '10px',
                        margin: '0 15px',
                        border: `1px solid var(--label-border-color)`
                    }}>
                        <LabelInput value='' onChange={handleOnChange} placeholder='Search Chat by name' />

                    </Box>
                    <Box className={classes.ChatsWrapper}>
                        {chatList.map((chat, index) => (
                            <MenuItem key={index} sx={{ width: '100%', bgcolor: '#fff', borderBottom: `1px solid var(--label-border-color)` }}>
                                <Checkbox /> <img src={chat?.contact?.profilePicThumb?.profilePicUrl ?? ''} alt='' />  {chat.contact?.displayName ?? ''}
                            </MenuItem>
                        ))}
                    </Box>

                    <Box className={classes.suggestedLabels}>
                        <Box className={classes.createWrap} sx={{ gap: 2 }}>
                            <ButtonBase
                                className={mainClasses.labelButtonBase}
                                onClick={() => dispatch(labelActions.toggleSortChatToLabelModalOpen(false))}
                                sx={{
                                    flexBasis: '40%!important',
                                    bgcolor: label.name ? '#546eff!important' : '',
                                    color: label.name ? '#fff!important' : '',
                                    '&:hover': {
                                        color: labelNetWorkStatus === 'create-label' ? '' : '#fff',
                                        backgroundColor: labelNetWorkStatus === 'create-label' ? colors.grey[200] : '#546eff',
                                    }
                                }}>
                                Skip for now
                            </ButtonBase>

                            <ButtonBase
                                className={mainClasses.labelButtonBase}
                                onClick={() => dispatch(newLabelThunk())}
                                sx={{
                                    flexBasis: '40%!important',
                                    bgcolor: label.name ? '#546eff!important' : '',
                                    color: label.name ? '#fff!important' : '',
                                    '&:hover': {
                                        color: labelNetWorkStatus === 'create-label' ? '' : '#fff',
                                        backgroundColor: labelNetWorkStatus === 'create-label' ? colors.grey[200] : '#546eff',
                                    }
                                }}>
                                {labelNetWorkStatus === 'create-label' ? 'Saving..' : 'Save'}
                                {labelNetWorkStatus === 'create-label' && <CircularProgress size={16} sx={{
                                    ml: 1,
                                }} />}
                            </ButtonBase>

                        </Box>
                    </Box>
                    <TalkToUsFooter />
                </Box>
            </Modal>
        </div >
    );
}

