import { BoardRowType } from "@models/task";
import { DEFAULT_COLUMN_WIDTH, TaskColumn } from "@models/task-column";
import { Box } from "@mui/material";
import { getBlueticksThemeSelector, getCurrentBoardId } from "@reducers/BoardsSelectors";
import { boardUserSelectOneFieldById, tasksSelectOneFieldById } from "@reducers/DBServiceReducers";
import { getIsChecked, isFirstTaskInGroup, isLastTaskInGroup } from "@reducers/TaskSelectors";
import { useAppSelector } from "@store/hooks";
import { memo, useCallback, useMemo } from "react";
import { ColumnCell } from "./column-cell";
import classes from "./task_row.module.scss";

export interface Props {
  column: TaskColumn;
  index: number;
  taskId: string;
  columnListLength: number;
}

export interface TaskRowState {
  editing: boolean;
  internalValue?: string;
  showPlaceholder: boolean;
}

function TaskCell({
  column,
  index,
  taskId,
  columnListLength
}: Props) {
  const blueTicksTheme = useAppSelector((state) => getBlueticksThemeSelector(state))
  const isFirstInGroup = useAppSelector((state) => isFirstTaskInGroup(state, taskId));
  const isLastInGroup = useAppSelector((state) => isLastTaskInGroup(state, taskId));


  const isTaskInEditMode = useAppSelector(state => tasksSelectOneFieldById(state.DBTasksReducer, taskId, 'isTaskInEditMode'));
  const taskType = useAppSelector(state => tasksSelectOneFieldById(state.DBTasksReducer, taskId, 'taskType'));

  const displayExtraDataOfTaskById = useAppSelector((state) => state.ExtraDataReducer.displayExtraDataOfTaskById)
  const isChecked = useAppSelector((state) => getIsChecked({ state, taskId: taskId }))
  const direction = useAppSelector((state) => state.UserReducer.direction);
  const currentBoardId = useAppSelector(getCurrentBoardId);
  const textColumnWidth = useAppSelector((state) => boardUserSelectOneFieldById(state.DBBoardsReducer, currentBoardId, 'textColumnWidth'));

  const isScrollingStart = useAppSelector((state) => state.TasksReducer.isScrollingStart);


  const isRowActive = useMemo(() => {
    let bool: boolean = false

    if (isTaskInEditMode || taskId === displayExtraDataOfTaskById || isChecked) {
      bool = true
    }
    return bool
  }, [displayExtraDataOfTaskById, isChecked, isTaskInEditMode, taskId]);

  const normalizedBackgroundColor = useMemo(() => {
    if (isRowActive) {
      return 'var(--task-edit-mode)'
    }
    else if (column.type === 'context-menu') {
      // return 'var(--secondary-background-color)'
      return 'transparent'
    }
    else if (taskType === BoardRowType.subTask) {
      return 'var(--task-background-color)'
    }
    else {
      return 'var(--task-background-color)'
    }
  }, [column.type, isRowActive, taskType]);

  const getColumnWidth = useCallback((column, DEFAULT_COLUMN_WIDTH) => {
    return column.type === 'row-prefix'
      ? textColumnWidth ?? 400
      : column.width ?? DEFAULT_COLUMN_WIDTH;
  }, [textColumnWidth]);


  return (
    <Box
      key={index}
      className={
        `${column.type === 'context-menu' ? '' : classes.todoCell} ${`column_${column._id}`}`
      }

      sx={{
        //contain: "strict",
        pointerEvents: isScrollingStart ? "none" : "unset",
        width: getColumnWidth(column, DEFAULT_COLUMN_WIDTH),
        backgroundColor: normalizedBackgroundColor,
        borderTopRightRadius: direction === "ltr" && columnListLength - 1 === index && (isFirstInGroup) && 12,
        borderBottomRightRadius: direction === "ltr" && columnListLength - 1 === index && (isLastInGroup) && 12,
        borderTopLeftRadius: direction === "rtl" && columnListLength - 1 === index && (isFirstInGroup) && 12,
        borderBottomLeftRadius: direction === "rtl" && columnListLength - 1 === index && (isLastInGroup) && 12,
        // borderRight: currentResizingColumnWidth? '2px solid #4a9af4' : '1px solid transparent',
        transition: isScrollingStart ? undefined : 'width 0.05s',
        // border: isScrolling ? '1px solid red' : undefined,
        //transition: 'width 0.1s'
        // transition: resizing ? 'none' : '0.2s cubic-bezier(0.165, 0.84, 0.44, 1)',
        // transition: currentResizingColumnWidth ? 'none' : '0.2s cubic-bezier(0.165, 0.84, 0.44, 1)',
        marginInlineStart: column.type === 'row-prefix' ? "-1px" : "0",
        borderBottom: column.type !== 'context-menu' && blueTicksTheme?.boards?.tasksPanel?.tasks?.border,
        // position:isMobile &&  column.type === 'row-prefix'  ? 'sticky' : "",
        // left:isMobile &&  column.type === 'row-prefix'   ?  column.type === "row-prefix" ? "0" : 0 : "",
        // zIndex: isMobile && column.type === 'row-prefix' ? 100 : "",

      }}
    >

      <ColumnCell column={column} taskId={taskId} />
    </Box>

  );
}

export default memo(TaskCell);
