import BTButton from '@components/bt-button/bt-button';
import FormColumnCell from '@components/tasks-panel/form/form-column-cell';
import { Task } from '@models/task';
import { LinkOff, SendOutlined, ThumbUpOutlined } from '@mui/icons-material';
import { CircularProgress, Snackbar } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { getBoardDataThunk } from '@reducers/BoardsThunks';
import { DBFormThunks, formSelectByQuery, formSelectOneFieldByQuery, taskGroupsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { getSortedVisibleColumnList } from '@reducers/FormReducers';
import { addNewTaskThunk } from '@reducers/TaskThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import classes from "./form-preview.module.scss";
import { getRuntimeUrl } from '@services/storage';
import { namespace } from 'config/constants';
import { useTranslation } from 'react-i18next';

type formDisplayStateType = 'loader' | 'inactiveShareId' | 'activeShareId' | 'formSent'


type Props = {
    shareId: string,
}

export default function FormPreview({ shareId }: Props) {
    const { t } = useTranslation(namespace);
    const [form] = useAppSelector((state) => formSelectByQuery(state.DBFormReducer, { shareId }, ['_id', 'groupId', 'boardId', 'name', 'title', 'description']));
    const isDBFinishLoadingForms = useAppSelector(state => state.DBFormReducer.itemsLoaded)
    const boardId = form?.boardId
    const isActive = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: form?._id
    }, 'isActive'))
    const columnData = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: form?._id
    }, 'columnData'))
    const sortedVisibleColumnList = useAppSelector((state) => getSortedVisibleColumnList(state, columnData, boardId, form?._id))
    const firstGroup = useAppSelector((state) => taskGroupsSelectOneObjectByQuery(state.DBTaskgroupsReducer, {
        boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }))
    const formAfterSubmit = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: form?._id
    }, 'afterSubmit'))
    const logo = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: form?._id
    }, 'logo'))
    const bgColor = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: form?._id
    }, 'backgroundColor')) ?? 'var(--secondary-background-color)'

    const [newTask, setNewTask] = useState<Task>({
        text: '',
    })
    const [isSubmitted, setIsSubmitted] = useState(t('formPreview.submitButtonText'))
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const [formSnackbarMessage, setFormSnackbarMessage] = useState<string | undefined>()
    const [submitFail, setSubmitFail] = useState(false)
    const [formDisplayState, setFormDisplayState] = useState<formDisplayStateType>('loader')

    const dispatch = useAppDispatch()


    useEffect(() => {
        if (boardId) {
            dispatch(getBoardDataThunk({ boardId }))
        }
    }, [boardId, dispatch])

    useEffect(() => {
        dispatch(DBFormThunks.find({ shareId }))
    }, [dispatch, shareId]);

    useEffect(() => {
        if (boardId && !newTask?.boardId) {
            setNewTask({
                ...newTask,
                boardId,
            })
        }
    }, [boardId, newTask])

    useEffect(() => {
        if (firstGroup?._id && !newTask?.groupId) {
            if (form?.groupId) {
                setNewTask({
                    ...newTask,
                    groupId: form?.groupId,
                })
            }
            else {
                setNewTask({
                    ...newTask,
                    groupId: firstGroup?._id,
                })
            }
        }
    }, [firstGroup?._id, form?.groupId, newTask])


    const updateTask = (value: any, columnId) => {
        if (columnId === 'name') {
            setNewTask({
                ...newTask,
                text: value.target.value
            })
        }
        else {
            setNewTask({
                ...newTask,
                customData: {
                    ...newTask?.customData,
                    [columnId]: {
                        value
                    }
                }
            })
        }
    }


    const title = formAfterSubmit?.title
    const message = formAfterSubmit?.message

    const link = () => {
        if (!formAfterSubmit?.link) {
            return null;
        }

        if (formAfterSubmit?.link.startsWith('http://') || formAfterSubmit?.link.startsWith('https://')) {
            return formAfterSubmit?.link;
        }

        // Defaulting to 'http://' if no protocol is specified
        return `http://${formAfterSubmit?.link}`;
    };

    const isRequiredColumn = (i) => {
        return columnData[sortedVisibleColumnList[i]._id] && columnData[sortedVisibleColumnList[i]._id].required && sortedVisibleColumnList[i]._id !== 'name'
    }


    const isRequiredColumnNotFilled = (i) => {
        const isNotFilled = !newTask || !newTask.customData || !newTask.customData[sortedVisibleColumnList[i]._id] || newTask.customData[sortedVisibleColumnList[i]._id].value === undefined
        if (sortedVisibleColumnList[i].type === 'checkbox' && !newTask?.customData?.[sortedVisibleColumnList?.[i]?._id]?.value) {
            return true
        }

        return isNotFilled
    }

    const isRequiredNameColumn = (i) => {
        return columnData[sortedVisibleColumnList[i]._id] && columnData[sortedVisibleColumnList[i]._id].required && sortedVisibleColumnList[i]._id === 'name'
    }

    const handleFormSubmit = () => {
        let statusCounter = 0;
        for (let i = 0; i < sortedVisibleColumnList.length; i++) {
            if (isRequiredColumn(i)) {
                if (isRequiredColumnNotFilled(i)) {
                    statusCounter++;
                }
            }
            if (isRequiredNameColumn(i)) {
                if (newTask?.text === '') {
                    statusCounter++;
                }
            }
        }
        if (statusCounter !== 0) {
            setFormSnackbarMessage(t('formPreview.submitFailMessage'))
            setSubmitFail(true)
        }
        else {
            setIsSubmitDisabled(true)
            setIsSubmitted(t('formPreview.formSent'))
            dispatch(addNewTaskThunk(newTask))
            if (link()) {
                setTimeout(function () {
                    window.location.href = link();
                }, 2000);
            }
        }
    }

    const handleCloseSnackbar = () => {
        setFormSnackbarMessage(undefined)
    }


    useEffect(() => {
        const loadPage = async () => {

            const count = Object.values(columnData ?? { 'extraField': 1 }).reduce((acc: any, curr: any) => {
                return curr.visible !== false ? acc + 1 : acc;
            }, 0);

            // set isLoaded to true after page is loaded
            if (count === (sortedVisibleColumnList ?? []).length && isDBFinishLoadingForms) {
                if (!form) {
                    setFormDisplayState('inactiveShareId')

                }
                else {
                    setFormDisplayState('activeShareId')
                }
            }
        };

        if (isSubmitDisabled) {
            setFormDisplayState('formSent')
        }
        else {
            loadPage();
        }
    }, [columnData, sortedVisibleColumnList, isActive, form, isDBFinishLoadingForms, isSubmitDisabled])


    const BTLogo = getRuntimeUrl('/img/logo.svg');

    return (
        <Stack direction='column' sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: bgColor ? bgColor : 'var(--background-default)',
            paddingTop: '30px',
            minHeight: '100vh',
        }}>
            {formDisplayState === 'loader' &&
                <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <h1>
                            {t('formPreview.loadingForm')}
                        </h1>
                        <CircularProgress />
                    </Box>
                </>
            }
            {formDisplayState === 'inactiveShareId' &&
                <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img src={BTLogo} alt={'BTLogo'} />
                        <h1>
                            This form's link is no longer active
                        </h1>
                        <h2>Contact the owner for an up to date link</h2>
                        <LinkOff sx={{ fontSize: '50px', }} />
                    </Box>
                </>
            }
            {formDisplayState === 'activeShareId' &&
                <>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        open={!!formSnackbarMessage}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                        ContentProps={{
                            sx: {
                                display: 'block',
                                textAlign: "center"
                            }
                        }}
                        message={formSnackbarMessage}
                    />
                    {isActive &&
                        <div className={classes.form_wrapper} >
                            <Stack direction='column'>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}>
                                    <Box sx={{
                                        maxHeight: '150px',
                                        maxWidth: '150px',
                                    }}>
                                        {logo
                                            ?
                                            <Stack direction='row' sx={{
                                                height: '100px',
                                                width: 'auto',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginTop: '25px',
                                            }}>
                                                <img src={logo} alt="Form logo" />
                                            </Stack>
                                            :
                                            <></>
                                        }
                                    </Box>
                                </div>
                                <div className={classes.form_body}>
                                    <div style={{}}>
                                        <div style={{
                                            marginTop: '24px',
                                            marginBottom: '6px',
                                            marginInline: 'calc(16px + 24px + 16px)',
                                            padding: '4px 0',
                                            fontSize: '24px',
                                            fontWeight: '800',
                                        }}>
                                            {form?.title ?? form?.name ?? ''}
                                        </div>
                                        <div style={{
                                            marginBottom: '12px',
                                            marginInline: 'calc(16px + 24px + 16px)',
                                            padding: '4px 0',
                                            fontSize: '16px',
                                        }}>
                                            {form?.description ?? ''}
                                        </div>
                                    </div>
                                    {sortedVisibleColumnList &&
                                        (sortedVisibleColumnList?.map((column, index) => {
                                            if (column.type === 'checkbox') {
                                                return (
                                                    <Stack direction='row' className={classes.form_cell} sx={{}} key={index}>
                                                        <FormColumnCell
                                                            isFormInEditMode={false}
                                                            columnId={column._id}
                                                            formId={form._id}
                                                            submitFail={submitFail}
                                                            updateTask={updateTask}
                                                            newTask={newTask}
                                                        />

                                                    </Stack>
                                                )
                                            }
                                            else {
                                                return (
                                                    <Stack direction='row' className={classes.form_cell} key={index}>
                                                        <FormColumnCell
                                                            isFormInEditMode={false}
                                                            columnId={column._id}
                                                            formId={form._id}
                                                            submitFail={submitFail}
                                                            updateTask={updateTask}
                                                            newTask={newTask}
                                                        />

                                                    </Stack>
                                                )
                                            }
                                        }
                                        ))}
                                    <BTButton
                                        disabled={isSubmitDisabled}
                                        sx={{
                                            display: 'flex',
                                            alignSelf: 'center',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            marginBlock: '16px'
                                        }}
                                        onClick={handleFormSubmit}
                                        endIcon={<SendOutlined />}>
                                        {isSubmitted}
                                    </BTButton>
                                </div >
                            </Stack >
                        </div >
                    }
                    {isActive === false &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <h1>This form is not active</h1>
                        </div>}

                </>
            }
            {formDisplayState === 'formSent' &&
                <div className={classes.form_wrapper} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '32px 48px',
                }}>
                    <ThumbUpOutlined height={60} width={60} />
                    {title &&
                        <h1 style={{
                            maxWidth: '70%',
                            textAlign: 'center',
                        }}>
                            {title}
                        </h1>
                    }

                    {message &&
                        <p style={{
                            maxWidth: '95%',
                            textAlign: 'center',
                        }}>
                            {message}
                        </p>
                    }
                </div>
            }
        </Stack >
    )
}