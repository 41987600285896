import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { NotificationStack } from './notification-row';
import classes from "./notifications-drawer.module.scss";

type Props = {
    notificationId: string;
}

export default function NumberLinkTextPhoneColumnNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))
    
    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === notification.notificationUpdateType
    );
    const isPhone = notification.notificationUpdateType === 'phone-cell'
    const isLink = notification.notificationUpdateType === 'link'
    const isNumberOrText = notification.notificationUpdateType === 'number' || notification.notificationUpdateType === 'text-cell'

    const oldValue = isLink ? { link: notification.oldValue, linkText: undefined } : notification.oldValue
    const newValue = isLink ? { link: notification.newValue, linkText: undefined } : notification.newValue


    let status = ''
    if (oldValue && newValue) { status = 'change data' }
    if (!oldValue && newValue) { status = 'add data' }
    if (oldValue && !newValue) { status = 'remove data' }
    if (status === '') {
    }
    return (
        <NotificationStack direction='row'>
            {status === 'add data' &&
                <>
                    <span className={classes.notification_text_element}>{notification.actionType}&nbsp;</span>
                    <span className={classes.notification_text_element}>to&nbsp;</span>
                    {!isNumberOrText &&
                        <span style={{ paddingTop: `${isLink ? 1 : 3}px` }}>
                            <CellWrapper
                                sx={{ display: 'contents', height: "20px" }}
                                columnId={""}
                                taskId={""}
                                groupId={""}
                                boardId={''}
                                mockValue={newValue}
                                readOnly={true}
                                cellComponent={cell.cellComponent}
                            />
                        </span>}
                    {isNumberOrText &&
                        <span style={{ fontWeight: 'bold' }}>{notification.newValue}</span>
                    }
                </>
            }
            {status === 'remove data' &&
                <>
                    <span className={classes.notification_text_element}>removed&nbsp;</span>
                    {!isNumberOrText &&
                        <span style={{ paddingTop: `${isLink ? 1 : 3}px` }}>
                            <CellWrapper
                                sx={{
                                    display: 'contents',
                                    height: "20px"
                                }}
                                columnId={""}
                                taskId={""}
                                groupId={""}
                                boardId={''}
                                mockValue={oldValue}
                                readOnly={true}
                                cellComponent={cell.cellComponent}
                            />
                        </span>}
                    {isNumberOrText &&
                        <span style={{ fontWeight: 'bold' }}>{notification.oldValue}</span>
                    }
                </>
            }
            {status === 'change data' && !isLink &&
                <>
                    <span className={classes.notification_text_element}>changed&nbsp;</span>
                    {!isNumberOrText &&
                        <span style={{ paddingTop: `${isLink ? 1 : 3}px` }}><CellWrapper
                            sx={{ display: 'contents', height: "20px" }}
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={oldValue}
                            readOnly={true}
                            cellComponent={cell.cellComponent}
                        /></span>}
                    {isNumberOrText &&
                        <span style={{ fontWeight: 'bold' }}>{notification.oldValue}</span>
                    }
                    <span className={classes.notification_text_element}>&nbsp;to&nbsp;</span>
                    {!isNumberOrText &&
                        <span style={{ paddingTop: `${isLink ? 1 : 3}px` }}><CellWrapper
                            sx={{
                                display: 'contents', height: "20px"
                            }}
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={newValue}
                            readOnly={true}
                            cellComponent={cell.cellComponent}
                        /></span>}
                    {isNumberOrText &&
                        <span style={{ fontWeight: 'bold' }}>{notification.newValue}</span>
                    }
                </>
            }
            {isLink &&
                <>
                    {oldValue.link !== undefined &&
                        <>
                            <span className={classes.notification_text_element}>removed</span>
                            <span style={{ paddingTop: `${isLink ? 1 : 3}px` }}>
                                <CellWrapper
                                    sx={{ display: 'contents', height: "20px" }}
                                    columnId={""}
                                    taskId={""}
                                    groupId={""}
                                    boardId={''}
                                    mockValue={oldValue}
                                    readOnly={true}
                                    cellComponent={cell.cellComponent}
                                />
                            </span>
                        </>
                    }
                    {newValue.link !== undefined &&
                        <>
                            <span className={classes.notification_text_element}> changed to </span>
                            <span style={{ paddingTop: `${isLink ? 1 : 3}px` }}>
                                <CellWrapper
                                    sx={{ display: 'contents', height: "20px" }}
                                    columnId={""}
                                    taskId={""}
                                    groupId={""}
                                    boardId={''}
                                    mockValue={newValue}
                                    readOnly={true}
                                    cellComponent={cell.cellComponent}
                                />
                            </span>
                        </>
                    }
                </>
            }
            <span className={classes.notification_text_element}>{!isLink ? <>&nbsp;</> : null}in&nbsp;</span>
            <span style={{ fontWeight: 'bold' }}>{notification.taskName}-</span>
            <span className={classes.notification_text_element} style={{ fontWeight: 'bold' }}>{notification.actionOnEntityData}</span>
        </NotificationStack>
    )
}