import { styled } from '@mui/material/styles';
import { BiArrowBack } from 'react-icons/bi';
import { RiCheckDoubleFill } from 'react-icons/ri';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Box, ButtonBase } from '@mui/material';
import { QUICK_PANEL } from 'reusable/interfaces';
import { accessFormPopupActions } from '@reducers/AccessFormPopupReducer';
import { selectUserObject } from '@reducers/UserSelectors';
import { btQuickPanelActions } from '@reducers/BtQuickPanelReducer';


const background = "linear-gradient(66.67deg, #259ae9 0%, #6dd1f1 100%)"
const BtQuickPanelButton = styled(ButtonBase)({
    width: 50,
    height: 50,
    background,
    color: '#fff',
    borderRadius: 29,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginBottom: 5,
    textTransform: 'none',
    '&:hover': {
        background
    },
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
})



export default function BtQuickPanelMainMenuButton() {
    const isDragging = useAppSelector((state) => state.BtQuickPanelReducer.isDragging)
    const clientX = useAppSelector((state) => state.BtQuickPanelReducer.clientX)
    const popperId = useAppSelector((state) => state.BtQuickPanelReducer.popperId)
    const user = useAppSelector(selectUserObject);

    const dispatch = useAppDispatch()

    function openBtQuickPanel() {
        if (!user) {
            dispatch(accessFormPopupActions.setStatus('Login'))
            dispatch(btQuickPanelActions.setPopperId(''))
            dispatch(btQuickPanelActions.setTargetComponent(''))
            return;
        }
        dispatch(btQuickPanelActions.setPopperId(QUICK_PANEL.MAIN_POPPER_ID))
    }


    return (
        <Box>
            <BtQuickPanelButton
                disabled={isDragging}
                id={QUICK_PANEL.MAIN_POPPER_ID}
                onClick={openBtQuickPanel}
                sx={{ borderRadius: clientX > 15 ? 30 : '', cursor: isDragging ? 'move' : 'pointer' }}
            >
                {popperId === QUICK_PANEL.MAIN_POPPER_ID ?
                    <BiArrowBack size={22} /> : <RiCheckDoubleFill color="#fff" size={22} />
                }

            </BtQuickPanelButton>
        </Box>
    );
}


