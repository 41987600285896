import { BTPaperProps } from "@components/atoms/bt-paper/bt-paper";
import BTThemeProvider from "@components/bt-theme-provider";
import ContactAvatar from "@components/bulk-dashboard/contact-table/contact-avatar";
import RowStack from "@components/common/row-stack/RowStack";
import MessageEditor from "@components/message-editor/message-editor";
import { fetchRule, patchRuleSet, toRuleSet } from "@components/schedule-picker/recurrence-utils";
import SocketConnectionStatusButton from "@components/socket-connection-status-button/socket-connection-status-button";
import WhatsappContactSearchBox from "@components/tasks/columns-cells/whatsapp-id-cell/whatsapp-contact-search-box";
import WSButton from "@components/ws-button/ws-button";
import { UserMessage } from "@models/user-message";
import { WASession } from "@models/wasession";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Slide,
  Stack
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { userMessagesSelectByQuery } from "@reducers/DBServiceReducers";
import { toggleSocketModal } from "@reducers/MainReducer";
import { isOverLimitOfScheduledMessages } from "@reducers/UserMessageReducer";
import { selectCurrentUserSession } from "@reducers/UserSelectors";
import { selectFilteredMergedContactList } from "@reducers/WhatsAppReducer";
import { scheduleMessage } from "@reducers/WhatsappThunks";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@services/analytics-service";
import { isExtensionContext, isExtensionContextOrWhatsappView } from "@services/detect-context";
import { RootState } from "@store/index";
import { whatsAppContacts } from "@views/whatsapp-view/whatsapp-mocks";
import { appConfig } from "config/app.config";
import moment from "moment";
import React, {
  useEffect,
  useMemo,
  useState
} from "react";
import { useSelector } from "react-redux";
import { theme } from "theme";
import {
  confirmationDialogOption,
  confirmationDialogOptions
} from "website/views/calendar-view/message-calendar";
import { addScheduledMessageButtonActions } from "../../reducers/AddScheduledMessageButtonReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Schedule } from "../../types/interface";
import { Popup } from "../popup/popup";
import SchedulePicker from "../schedule-picker/schedule-picker";
import styles from "./add-scheduled-message-button.module.scss";
import { MessageConfirmationDialog } from "./message-confirmation-dialog";

type AddScheduledMessageButtonProps = {
  isDisabled?: boolean;
  classes?: any;
};

export interface MessageContact {
  id?: string;
  name?: string;
  displayName?: string;
  mobile?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddScheduledMessage = (props: AddScheduledMessageButtonProps) => {

  const analyticsService: AnalyticsService = useAnalyticsService();

  const dispatch = useAppDispatch();

  const [messageContact, setMessageContact] = useState<MessageContact>(null);
  const [searchFilter, setSearchFilter] = useState('');

  const currentChat = useAppSelector((state) => state.WhatsAppReducer.currentChat);
  const errorState = useAppSelector((state) => state.WhatsAppReducer.errorState);
  const message = useAppSelector((state) => state.AddScheduledMessageButtonReducer.message);
  const open = useAppSelector((state) => state.AddScheduledMessageButtonReducer.open);
  const showConfirmationDialog = useAppSelector((state) => state.AddScheduledMessageButtonReducer.showConfirmationDialog);
  const devices = useAppSelector((state) => state.MainReducer.devices)
  const isWaSocketsLimitReached = useAppSelector((state) => state.AddScheduledMessageButtonReducer.isWaSocketsLimitReached)
  const session: WASession = useAppSelector(state => selectCurrentUserSession(state));
  const isSocketConnectedToWhatsApp = session?.active && session?.status === "connected";
  const _filteredContacts = useSelector((state: RootState) => selectFilteredMergedContactList(state, searchFilter));

  const whatsappSockets = devices.filter(obj => obj.connectedFrom === "extension" && obj.domain === "https://web.whatsapp.com/");

  const messageList: UserMessage[] = useAppSelector((state) =>
    userMessagesSelectByQuery(state.DBUserMessageReducer, {
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    })
  );

  const isDisabled = useMemo(
    () => (isExtensionContextOrWhatsappView() && localStorage.getItem("schedulerReady") !== "true") || errorState,
    [errorState]
  );



  useEffect(() => {
    const contactList = message.contactList;

    if (contactList?.length && contactList[0]?.name && contactList[0]?.id) {
      setMessageContact({
        name: contactList[0].name,
        id: contactList[0].id,
        displayName: contactList[0].name,
      });

      // setDisplayContactList(false);
    } else if (currentChat) {
      setMessageContact({
        name: currentChat.name ?? currentChat.displayName ?? currentChat.contact?.name,
        id: currentChat?.id?._serialized,
        displayName: currentChat.contact?.name ?? currentChat.displayName,
      });
      // setDisplayContactList(false);
    } else {
      setMessageContact(null);
      // setDisplayContactList(true);
    }
  }, [currentChat, message.contactList]);


  const handleClose = () => {
    dispatch(addScheduledMessageButtonActions.closeDialog());
    setSearchFilter('')
  };


  const handleCloseLimitReached = () => {
    analyticsService.event("close_popup", {
      category: "purchasePopup",
      action: "close popup",
    });
    dispatch(addScheduledMessageButtonActions.closeDialog());
  };

  const scheduleSend = () => {
    const scheduleMessageFrom: string = "whatsapp" // or calendar
    if (scheduleMessageFrom === "calendar") {
      dispatch(addScheduledMessageButtonActions.setConfirmationDialog(true));
    } else {
      if (!message.isRecurring) {
        dispatch(scheduleMessage({
          ...message,
          rruleset: undefined,
          recurenceUnit: undefined,
          recurrenceQuantity: undefined,
        }));
      } else {
        dispatch(
          scheduleMessage(message)
        );
      }
      handleClose();
    }
  };

  const onEditRecurringDialogClose = (selection?: confirmationDialogOption) => {
    if (selection) {
      let current = { ...message };
      let original = messageList.find((m) => m._id === current._id);
      const originalRuleset = toRuleSet(original.rruleset);
      let triggerOriginal = true;

      //
      if (selection === "This message") {
        originalRuleset.exdate(
          moment.utc(current.dueDate).startOf("minute").toDate()
        );
        original = { ...original, rruleset: originalRuleset.toString() };

        delete current._id; // -> create
      } else if (selection === "This and following messages") {
        const patched = patchRuleSet(originalRuleset, {
          until: moment.utc(current.dueDate).subtract(1, "minute").toDate(),
        });
        original = { ...original, rruleset: patched.toString() };

        delete current._id; // -> create
      } else {
        triggerOriginal = false;
      }

      if (triggerOriginal) {
        dispatch(scheduleMessage(original));
      }
      dispatch(scheduleMessage(current));
    }
    handleClose();
  };

  const handleScheduleChange = (schedule: Schedule) => {
    if (schedule.rruleset) {
      const rrule = fetchRule(schedule.rruleset);
      const rruleset = rrule.toString()

      console.log(rruleset)
      console.log(rrule.toText())
    }

    dispatch(
      addScheduledMessageButtonActions.updateMessage({
        ...message,
        ...schedule,
        image: undefined,
      })
    );
  };

  const isOverLimit = useAppSelector(isOverLimitOfScheduledMessages);

  console.log(`[add-scheduled-message] open ${open}, isOverLimit: ${isOverLimit}`)

  const limitReachedDialog = () => {
    return (
      <BTThemeProvider>
        <Dialog
          dir="ltr"
          TransitionComponent={Transition}
          open={open && isOverLimit}
          onClose={handleCloseLimitReached}
        >
          <Popup
            headerTitle={"Only 1 message at a time"}
            headerSubtitle={"Go premium to unlock unlimited messages"}
            onClose={handleCloseLimitReached}
          />
        </Dialog>
      </BTThemeProvider>
    );
  };

  const errorStateDialog = () => {
    return (
      <Dialog
        dir="ltr"
        TransitionComponent={Transition}
        open={isDisabled}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-slide-title">{`Sync Error`}</DialogTitle>
        <DialogContent>
          <h1>Please refresh this page</h1>
        </DialogContent>
      </Dialog>
    );
  };

  const canSchedule = useMemo(() => {
    const isCanSchedule =
      message?.dueDate &&
      moment(message?.dueDate).isValid() &&
      messageContact?.id &&
      (message.message?.length > 0 ||
        message?.asset?.url ||
        message?.imageId
      )
    return isCanSchedule;
  }, [message?.dueDate, message.message?.length, message?.asset?.url, message?.imageId, messageContact?.id]);

  const { classes } = props;
  const buttonClasses = [styles?.alarmButton, classes?.alarmButton];
  if (isDisabled) {
    buttonClasses.push(classes?.disabled);
  }

  const filteredContacts = isExtensionContext() ? _filteredContacts : whatsAppContacts.filter((contact) => {
    return contact.waName.toLowerCase().includes(searchFilter.toLowerCase()) || contact.waId.includes(searchFilter.replace(/\+/g, ''));
  })

  const contactElement: () => JSX.Element = () => {
    // if (displayContactList) {
    const onChange = (value: string) => {
      setSearchFilter(value);
    };

    if (!messageContact) {

      const onSave = (c: any) => {
        setMessageContact({
          name: c?.name ?? c?.waName,
          id: c?.id?._serialized ?? c?.waId,
          displayName: c?.displayName ?? c?.waName,
        });
        setSearchFilter('');
      };

      const saveFreeInput = () => {
        let whatsappId = searchFilter.replace(/\+/g, '');
        if (!whatsappId.includes('@')) {
          whatsappId = `${whatsappId}@c.us`;
        }
        onSave({ waId: whatsappId })
      }
      return (
        <WhatsappContactSearchBox
          searchFilter={searchFilter}
          handleSearchFilterChange={onChange}
          saveFreeInput={saveFreeInput}
          filteredContacts={filteredContacts}
          save={onSave}
        />
      )
    }

    if (messageContact) {
      return <RowStack>
        <ContactAvatar size={24} textSize={12} contactId={messageContact.id} sx={{ m: '0px 8px 0px 10px' }} />
        <span style={{
          maxWidth: '190px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}>{messageContact.name ?? messageContact.id.split('@')[0]}</span>
        {/* <IconButton onClick={() => setMessageContact(null)}>
          <RiCloseCircleFill />
        </IconButton> */}
      </RowStack>;
    }
    // }
  };

  const updateMessage = (_message: UserMessage) => {
    dispatch(addScheduledMessageButtonActions.updateMessage({
      ...message,
      ..._message,
      image: undefined
    }));
  };

  return (
    <BTThemeProvider>
      {errorStateDialog()}
      {limitReachedDialog()}
      <Dialog dir="ltr"
        fullWidth={true}
        PaperProps={{
          sx: {
            maxWidth: 700,
            ...BTPaperProps,
          },
        }}
        open={open && !isOverLimit}
        TransitionComponent={Transition}
        onClose={handleClose}
      >


        <Stack sx={{ padding: "0" }} >
          {(false && whatsappSockets.length > 1) && (
            <Alert severity="error" sx={{
              color: "#df0000", ".MuiAlert-message": {
                width: "100%", textAlign: "center",
              }
            }}>
              <div> {whatsappSockets.length} Devices in use, Please close uncessary,    <Link sx={{
                textDecoration: "underline"

              }}
                component="a"
                target="_blank"
                href={`${appConfig.PRICING_PAGE}/devices`}
              > Click here to see all devices </Link></div>

            </Alert>
          )}

          {moment.utc(message?.dueDate).isBefore(
            moment.utc().subtract(1, "minute")
          ) && (
              <Alert sx={{
                alignItems: "center", boxShadow: "none", ".MuiAlert-message": {
                  width: "100%", textAlign: "center", padding: "0"
                }
              }} severity="warning">
                <div>Pay attention, The scheduled time has already passed.</div>
                <div>Please select a future date and time</div></Alert>
            )}

          {!isSocketConnectedToWhatsApp &&

            <Alert severity="info"
              sx={{
                alignItems: "center",
                boxShadow: "none",
                ".MuiAlert-message": {
                  width: "100%",
                  textAlign: "center",
                  padding: "0",
                }
              }}>
              <Box sx={{ textAlign: "center" }}>
                Pay attention, the message will be sent only if the browser is open.
              </Box>
              {!isWaSocketsLimitReached &&
                <RowStack
                  justifyContent={"center"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(toggleSocketModal(true))
                  }}>
                  <SocketConnectionStatusButton />
                  <div style={{ marginLeft: "5px" }}>
                    Connect your device to send messages when the browser is closed
                  </div>
                </RowStack>
              }
            </Alert>

          }
        </Stack>

        <Box sx={{
          m: "10px 0px 0px 140px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          fontWeight: 600,
        }}>
          <RowStack sx={{ height: '45px' }}>
            {`Schedule a message to`} <Box>{contactElement()}</Box>
          </RowStack>
        </Box>

        <DialogContent>
          <form className={classes?.container} noValidate>
            <SchedulePicker
              showRecurrence={true}
              showCancelIfReceived={true}
              schedule={message ?? {}}
              handleScheduleChange={handleScheduleChange}
            ></SchedulePicker>
            <Stack>
              <MessageEditor
                showTemplateButtons={false}
                value={message}
                onChange={updateMessage}
                editorContext='scheduleMessage' />
            </Stack>
            <DialogActions>
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="end"
                  spacing={theme.spacing(1)}
                  sx={{
                    width: "100%",
                    px: 2,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <WSButton sx={{ flex: "0 0 150px" }} onClick={handleClose}>
                    Cancel
                  </WSButton>
                  <WSButton
                    sx={{ flex: "0 0 250px" }}
                    onClick={() => {
                      analyticsService.event("first_action", {
                        category: "firstAction",
                        action: "first action",
                      });
                      scheduleSend()
                    }}
                    disabled={!canSchedule}
                    variant="contained"
                    color="primary"
                  >
                    Schedule Send
                  </WSButton>
                </Stack>
              </Stack>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog >
      {
        showConfirmationDialog && (
          <MessageConfirmationDialog
            title="Edit recurring message"
            onClose={onEditRecurringDialogClose}
            options={confirmationDialogOptions}
          />
        )
      }
    </BTThemeProvider >
  );
};

export default AddScheduledMessage;
