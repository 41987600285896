import { Box, Stack } from "@mui/system";
import { automation } from "@reducers/AutomationReducer";
import { selectMappedTaskGroups } from "@reducers/AutomationsSelectors";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useEffect, useState } from "react";
import AddAndRemove from "../add-and-remove";
import AutomationButton from "../automation-button";
import CustomDropdown from "../automation-dropdown";
import { setCurrentCondition } from "../automations-helper";

const InGroupCondition = ({ index }) => {
    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = useState(null);
    const [text, setText] = useState('this group');
    const prefix = 'and only if task is in'
    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    const groups = useAppSelector(selectMappedTaskGroups);
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOptionSelect = (option) => {
        dispatch(automation.setConditions(setCurrentCondition(conditions, index, { conditionType: conditions[index].conditionType, groupId: option._id  })));
        setText(option.label);
        handleClose()
    };


    useEffect(() => {
        if (conditions) {
            const selectedGroupId = conditions[index].groupId;
            const group = groups.find(item => item._id === selectedGroupId);
            setText(group?.label ?? 'group');
            dispatch(automation.fieldValidityChanged({ component: 'InGroupCondition', index, field: 'groupId', isValid: !!group?.label }));
        }
    }, [conditions, dispatch, groups, index]);


    return (
        <Stack key={index} sx={{ fontSize: '24px' }} direction="row" justifyContent={"space-between"}>
            <Box>
                {prefix}
                <AutomationButton
                    componentName='InGroupCondition'
                    index={index}
                    option='groupId'
                    label={text}
                    handleClick={handleClick}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />
            </Box>
            <AddAndRemove elements={conditions} index={index} type={"conditions"} componentName={"InGroupCondition"} />
            <CustomDropdown
                options={groups}
                anchorEl={anchorEl}
                onClose={handleClose}
                onOptionSelect={handleOptionSelect}
                onHoverOption={null}
            />
        </Stack>
    );
};

export default InGroupCondition;


