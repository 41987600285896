import { columnTypeList } from "@components/tasks/columns-cells/column-cell-defs";
import CellWrapper from "@components/tasks/columns-cells/cell-wrapper";
import { useAppSelector } from "@store/hooks";
import { getSelectedBoardId } from "@reducers/BoardsSelectors";
import { Box, Stack } from "@mui/material";

export default function MockdataTest() {
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const columnsListOfIds = [
      ['', 'person-picker', 'Owner',
        [{
          "_id": "649bf141a7c14755796f2247",
          "workspaceId": "6497f48235d7a95d4da4927b",
          "boardId": "649bf141a7c14755796f2244",
          "userId": "6497f48235d7a95d4da49279",
          "status": "active",
          "role": "owner",
          "email": "pinokiooo+5@gmail.com",
          "deleted": false,
          "createdAt": "2023-06-28T08:37:21.813Z",
          "updatedAt": "2023-06-28T08:37:21.813Z",
          "__v": 0,
          "profile": {
              "_id": "6497f48235d7a95d4da49279",
              "email": "pinokiooo+5@gmail.com",
              "firstName": "david",
              "lastName": "davidi"
          },
          "type": "user"
      }]],
      ["", 'status-option-picker', 'Status', { label: "Stuck", bgcolor: "blue" }],
      ['', 'datepicker', 'Date', "2022-12-07T22:00:00.000Z"],
      ['', 'text-cell', 'Date', "ggg"],
      ['', 'timeline-picker', 'timeline-picker', {
        "startDate": "2023-06-06T21:00:00.000Z",
        "endDate": "2023-06-13T21:00:00.000Z",
        "progressInPercentage": 264
      }],
      ['', 'files', 'File', [
  
        {
          "url": "https://blueticks-assets.storage.googleapis.com/ArIDKgAPRtCa0fWH",
          "type": "image/gif",
          "filename": "giphy.gif",
          "updatedAt": "25/06/2023 12:07:27",
          "version": 1,
          "versions": [
            {
              "url": "https://blueticks-assets.storage.googleapis.com/ArIDKgAPRtCa0fWH",
              "type": "image/gif",
              "filename": "giphy.gif",
              "updatedAt": "25/06/2023 12:07:27",
              "version": 1
            }
          ]
        },
        {
          "url": "https://blueticks-assets.storage.googleapis.com/Vh6BMqaqUe49RXVE",
          "type": "image/jpeg",
          "filename": "fe8f77fb-c1e4-4e9b-b241-f268a673d193.jpg",
          "updatedAt": "25/06/2023 12:18:30",
          "version": 1,
          "versions": [
            {
              "url": "https://blueticks-assets.storage.googleapis.com/Vh6BMqaqUe49RXVE",
              "type": "image/jpeg",
              "filename": "fe8f77fb-c1e4-4e9b-b241-f268a673d193.jpg",
              "updatedAt": "25/06/2023 12:18:30",
              "version": 1
            }
          ]
        }
  
      ]
  
      ],
      ['', "rating", 'rating', 2],
      ['', "checkbox", 'checkbox', true],
      ['', "phone-cell", 'phone', "972546603611"],
      ['', "number", 'number', 5555],
      ['', "last-updated", 'lastUpdated', "2023-06-25T13:57:19.044Z"],
      ['', "created", 'lastUpdated', "2023-05-25T13:57:19.044Z"],
      ['', 'link', 'File', {
        "link": "https://google.com",
        "linkText": "ggg"
      }],
      ['', 'complexity-picker', 'complexity', { label: "Diffcult", bgcolor: "black", complexity: "25" }],
      ['', 'drop-down', 'dropdown', [
        {
          "_id": "649841e1ced62b115567cdeb",
          "label": "asdasd",
          "order": 0
        },
        {
          "_id": "649841e3ced62b115567cdef",
          "label": "dsadd",
          "order": 1
        },
      ]],
    ]
  
    const ColumnRow = (column, index) => {
      const [cell] = columnTypeList.filter(
        (columnType) =>
          columnType.type === column[1]
      );
  
      return (
        <Stack key={`${index}_${column[0]}`} sx={{
          backgroundColor: "white",
          margin: "10px",
          padding: "15px",
          borderRadius: "8px"
        }}>
  
  
  
          <Box key={`${index}_${column[0]}`} sx={{
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: "200px"
          }}>
            <CellWrapper
              columnId={""}
              taskId={""}
              groupId={""}
              boardId={boardId}
              mockValue={column[3]}
              readOnly={true}
              cellComponent={cell.cellComponent}
            />
          </Box>
        </Stack>
  
      )
    }

  return (
    <Stack direction="row" flexWrap={"wrap"}>
          {columnsListOfIds
            .map((column, index) => (
              ColumnRow(column, index)
            ))
          }
    </Stack>
  );
}
