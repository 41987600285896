import { Subscription, SubscriptionObject } from "@models/subscription";
import { User } from "@models/user";
import { Workspace } from "@models/workspace";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import moment from "moment";
import {
  GenericState,
  logsSelectByQuery,
  sessionSelectOneObjectByQuery,
  subscriptionSelectByQuery,
  userSelectByQuery,
  userSelectOneObjectByQuery,
  workspaceSelectOneObjectByQuery,
  workspaceUserSelectOneObjectByQuery,
} from "./DBServiceReducers";

// Other code such as selectors can use the imported `RootState` type
export const selectUser = createSelector(
  [(state) => state],
  (state: RootState) => {
    const [user] = userSelectByQuery(state.DBUserReducer, {
      _id: state.UserReducer.userId,
    });
    return user;
  }
);

export const isLoggedInSelector = createSelector(
  [(state) => state, (state) => selectUser(state)],
  (state: RootState, user: User) => {
    return !!user;
  }
);

export const selectUserId = createSelector(
  [(state) => state],
  (state: RootState) => {
    const [user] = userSelectByQuery(state.DBUserReducer, {
      _id: state.UserReducer.userId,
    });
    return user?._id;
  }
);

export const selectCurrentUser = createSelector(
  [
    (state: RootState) => state.DBUserReducer,
    (state: RootState) => state.UserReducer?.userId,
  ],
  (state: GenericState<User>, userId: string) => {
    const user = userSelectOneObjectByQuery(state, { _id: userId });
    return user;
  }
);

export const selectCurrentUserEmail = createSelector(
  [
    (state: RootState) => state.DBUserReducer,
    (state: RootState) => selectCurrentUser(state),
  ],
  (state: GenericState<User>, user: User) => {
    return user?.email;
  }
);

export const selectUserObject = createSelector(
  [selectCurrentUser],
  (user: User) => {
    return user ? new User(user) : undefined;
  }
);

export const selectIsDarkMode = createSelector([selectUser], (user) => {
  return user?.isDarkMode;
});

export const selectDefaultChatCountryCode = createSelector(
  [selectUser],
  (user) => {
    return user?.defaultChatCountryCode;
  }
);

export const selectCurrentWorkspace = createSelector(
  [(state) => state, selectUserObject],
  (state: RootState, user) => {
    const currentWorkspace = workspaceSelectOneObjectByQuery(
      state.DBWorkspaceReducer,
      { _id: user?.currentWorkspace }
    );
    return currentWorkspace;
  }
);

//create selector
export const selectUserEngagementScore = createSelector(
  [(state) => state, selectUserObject],
  (state: RootState, user) => {
    const userLogs = logsSelectByQuery(state.DBLogReducer, {
      triggerById: user?._id,
    });

    const totalUserEngagementScore = userLogs.reduce(
      (accumulator, currentObject) => {
        return accumulator + (currentObject.userEngagementPoints || 0);
      },
      0
    );

    return totalUserEngagementScore;
  }
);
export const isUserWorkSpaceOwner = createSelector(
  [(state) => state, selectUserObject],
  (state: RootState, user) => {
    let isOwner = false;
    const currentWorkspace = workspaceSelectOneObjectByQuery(
      state.DBWorkspaceReducer,
      { _id: user?.currentWorkspace }
    );

    if (currentWorkspace && user) {
      isOwner = currentWorkspace.owner === user._id;
    }
    return isOwner;
  }
);

export const selectIsWorkspaceChanging = createSelector(
  [(state) => state, selectUserObject, selectCurrentWorkspace],
  (state: RootState, user, currentWorkspace) => {
    return currentWorkspace?._id !== user?.currentWorkspace;
  }
);

export const isCurrentUserWorkSpaceOwner = createSelector(
  (state) => state,
  selectUserObject,
  selectCurrentWorkspace,
  (state: RootState, user: User, currentWorkspace: Workspace) => {
    let isCurrentUserWorkSpaceOwner = false;
    if (user) {
      const workspaceOwner = workspaceUserSelectOneObjectByQuery(
        state.DBWorkspaceUserReducer,
        { userId: user?._id, workspaceId: currentWorkspace?._id }
      );
      isCurrentUserWorkSpaceOwner = !!workspaceOwner;
    }
    return isCurrentUserWorkSpaceOwner;
  }
);

export const selectCurrentUserWorkspace = createSelector(
  (state) => state,
  selectUserObject,
  (state: RootState, user: User) => {
    const workspace = workspaceSelectOneObjectByQuery(
      state.DBWorkspaceReducer,
      { owner: user?._id }
    );
    return workspace;
  }
);

export const subscriptionObjectListSelector = createSelector(
  (state: RootState) =>
    subscriptionSelectByQuery(state.DBSubscriptionReducer, {}),
  (subscriptionList) =>
    (subscriptionList ?? []).map(
      (subscription) => new SubscriptionObject(subscription)
    )
);

export const workspaceSubscriptionListSelector = createSelector(
  [
    (state: RootState) => state,
    (state: RootState) => selectCurrentUser(state),
    selectCurrentWorkspace,
  ],
  (state, user: User, currentWorkspace: Workspace) => {
    let subscriptionListObjects = [];

    const subscriptionList = currentWorkspace
      ? subscriptionSelectByQuery(state.DBSubscriptionReducer, {
        $or: [
          {
            _id: {
              $in: currentWorkspace?.subscriptionList
            },
          },
          {
            subscription_id: currentWorkspace?.chargebeeSubscriptionId
          },
          {
            workspaceId: currentWorkspace?._id
          }
        ]

      })
      : [];

    subscriptionListObjects = (subscriptionList ?? []).map(
      (subscription) => new SubscriptionObject(subscription)
    );

    subscriptionListObjects.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );

    return subscriptionListObjects;
  }
);

export const didUserHaveTrialSubscription = createSelector(
  [
    (state: RootState) => state,
    (state: RootState) => selectCurrentUser(state),
    selectCurrentWorkspace,
  ],
  (state, user: User, currentWorkspace: Workspace) => {
    const subscriptions = currentWorkspace
      ? subscriptionSelectByQuery(state.DBSubscriptionReducer, {
        _id: {
          $in: currentWorkspace?.subscriptionList,
        },
      })
      : [];

    const hasExpiredTrialSubscription = subscriptions.some(
      (subscription) =>
        subscription.type === "trial" &&
        moment(subscription.expiredDate).isBefore(moment())
    );

    return hasExpiredTrialSubscription;
  }
);

export const didUserHaveRealSubscription = createSelector(
  [
    (state: RootState) => state,
    (state: RootState) => selectCurrentUser(state),
    selectCurrentWorkspace,
  ],
  (state, user: User, currentWorkspace: Workspace) => {
    const subscriptions = currentWorkspace
      ? subscriptionSelectByQuery(state.DBSubscriptionReducer, {
        _id: {
          $in: currentWorkspace?.subscriptionList,
        },
      })
      : [];

    const hasRealSubscription = subscriptions.some(subscription => subscription.type !== "trial");

    return hasRealSubscription;
  }
);


export const latestWorkspaceSubscriptionSelector = createSelector(
  [
    (state: RootState) => state,
    (state: RootState) => workspaceSubscriptionListSelector(state),
  ],
  (
    state: RootState,
    subscriptionList: Subscription[]
  ): SubscriptionObject | undefined => {
    const sortedSubscriptionList = (subscriptionList ?? []).map(
      (subscription) => new SubscriptionObject(subscription)
    );
    sortedSubscriptionList.sort((a, b) =>
      moment(b.updatedAt).diff(moment(a.updatedAt))
    );

    const [latestWrokspaceSubscription] = sortedSubscriptionList;
    return latestWrokspaceSubscription;
  }
);

export const activeSubscriptionSelector = createSelector(
  [
    (state: RootState) => state,
    (state: RootState) => workspaceSubscriptionListSelector(state),
  ],
  (
    state: RootState,
    subscriptionList: Subscription[]
  ): SubscriptionObject | undefined => {
    const sortedSubscriptionList = (subscriptionList ?? [])
      .map((subscription) => new SubscriptionObject(subscription))
      .filter((subscription) => subscription?.isActive);

    sortedSubscriptionList.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );

    // Then, prioritize "chargebee" subscriptions
    sortedSubscriptionList.sort((a, b) => {
      if (a.type === 'chargebee' && b.type !== 'chargebee') return -1;
      if (a.type !== 'chargebee' && b.type === 'chargebee') return 1;
      return 0;
    });

    const [activeSubscription] = sortedSubscriptionList;
    return activeSubscription;
  }
);

// active subscription type selector
export const activeSubscriptionTypeSelector = createSelector(
  activeSubscriptionSelector,
  (activeSubscription: SubscriptionObject) => activeSubscription?.type
);

export const hasActiveSubscriptionSelector = createSelector(
  [activeSubscriptionSelector],
  (activeSubscription: SubscriptionObject) => activeSubscription !== undefined
);

export const hasInactiveSubscriptionSelector = createSelector(
  [
    (state) => subscriptionSelectByQuery(state, {}),
    hasActiveSubscriptionSelector,
  ],
  (subscriptionList: Subscription[], hasActiveSubscription: boolean) =>
    hasActiveSubscription && subscriptionList.length > 0
);

export const messageLimitSelector = createSelector(
  activeSubscriptionSelector,
  (activeSubscription: SubscriptionObject) =>
    activeSubscription?.messageLimit ?? 1
);

export const isOverLimitSelector = createSelector(
  (state: RootState, messageCount) => messageCount,
  messageLimitSelector,
  (messageCount: number, messageLimit: number) => messageCount > messageLimit
);

export const selectCurrentUserSession = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const session = sessionSelectOneObjectByQuery(
      state.DBSessionReducer,
      {
        owner: selectUserId(state),
        //status: { $in: ["qrcode", "connected"] },
      },
      { updatedAt: -1 }
    );
    return session;
  }
);

export const selectCurrentSessionQrCode = createSelector(
  selectCurrentUserSession,
  (session) => session?.qrcode
);

export const selectCurrentSessionId = createSelector(
  selectCurrentUserSession,
  (session) => session?._id
);

export const selectCurrentSessionStatus = createSelector(
  selectCurrentUserSession,
  (session) => session?.status
);

export const selectIsCurrentUserSessionConnected = createSelector(
  selectCurrentUserSession,
  (session) => session?.active && session?.status === "connected"
);
