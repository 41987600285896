import { BulkContact } from "@components/bulk-dashboard/contact-table/contact-table";
import { addScheduledMessageButtonActions } from "@reducers/AddScheduledMessageButtonReducer";
import { ProfilePicThumbEvent, updateProfilePic } from "@reducers/ProfilePicReducer";
import { closeAndOpenTasksIfOpen } from "@reducers/TaskThunks";
import {  setInvalidWANumber, setMe, setWAchats, setWAcontacts } from "@reducers/WhatsAppReducer";
import { handleNewMessageThunk, onWapiInitialized, setCurrentChatThunk } from "@reducers/WhatsappThunks";
import { createAsyncThunk } from "@reduxjs/toolkit";
import store, { AppDispatch, RootState } from "@store/index";
import moment from "moment";
import randomstring from "randomstring";
import { MessageState } from "types/interface";
import { blobMap } from "./blob-service";
import { MediaDescriptor } from "./whatsapp";

export const initMessageChannel = createAsyncThunk<void, void, { state: RootState }
>("initMessageChannel", async (_, thunkApi) => {
  const dispatch: AppDispatch = thunkApi.dispatch;

  chrome.runtime?.onMessage?.addListener((request) => {
    console.log(request);
    switch (request.action) {
      case "extensionUpdated": {
        console.log("extensionUpdated");
      }
    }
  });
  window.addEventListener("message", async (event: MessageEvent) => {
    if (event.data && event.data.cmd) {
      //console.log(`[${getContext()}] message received: ${event.data.cmd}`);
      // this.emit(event.data.cmd, event.data);
      switch (event.data.cmd) {

        case "wapi-initialized": {
          dispatch(onWapiInitialized())
          break;
        }
        case "contactList": {
          store.dispatch(setWAcontacts(event.data.contactList));
          store.dispatch(setWAchats(event.data.chatList));
          break;
        }
        case "contactImageRecord": {
          // store.dispatch(setContactImageRecord(event.data.contactImageRecord));
          break;
        }
        case "currentChat": {
          store.dispatch(setCurrentChatThunk({
            chat: event.data.currentChat
          }));
          store.dispatch(closeAndOpenTasksIfOpen());

          // Debug
          // (event.data.currentChat as Chat).participants.forEach(participant => getProfilePicThumb(participant.id?._serialized));
          break;
        }
        case "newMessage": {
          dispatch(handleNewMessageThunk(event.data))
          break;
        }
        case "me": {
          store.dispatch(setMe(event.data.me));
          break;
        }
        case "profilePicThumb": {
          store.dispatch(
            updateProfilePic(event.data as ProfilePicThumbEvent)
          );
          break;
        }
        case "invalidWAPhoneNumber": {
          store.dispatch(setInvalidWANumber(event.data.phone))
          break;
        }
        case "mediaBlobs": {
          console.log(event.data);
          const state = store.getState();
          const currentChat = state.WhatsAppReducer.currentChat;

          let image: File | undefined;
          let imageId: string | undefined;
          if (event.data.mediaBlobList && event.data.mediaBlobList.length > 0) {
            const mediaDescriptor: MediaDescriptor = event.data.mediaBlobList[0];
            const blob = (mediaDescriptor.file instanceof Blob || mediaDescriptor.file instanceof File) ? mediaDescriptor.file : mediaDescriptor.file._blob;
            image = new File([blob], mediaDescriptor.filename || '', { type: mediaDescriptor.mimetype });
            imageId = randomstring.generate(10);

            console.log(`Setting blob for imageId: ${imageId}`);
            blobMap.set(imageId, image);
          }
          const contact: BulkContact = {
            ...currentChat,
            name: currentChat?.displayName ?? currentChat?.contact?.displayName,
            id: currentChat?.id?._serialized
          };
          dispatch(addScheduledMessageButtonActions.editMessage({
            message: event.data.caption,
            imageId,
            contactList: [contact],
            dueDate: moment().toISOString(),
            isRecurring: false,
            state: MessageState.editing
          }))
          break;
        }

      }
    }
  });
  window.postMessage(
    {
      cmd: "initCheck",
    },
    "*"
  );

});