import { Stack } from "@mui/material"
import { isExtensionContext } from "@services/detect-context";
import PhoneInput from "react-phone-input-2"

export interface PhoneDisplayProps {
    phone: string;
}

export const PhoneDisplay = ({ phone }: PhoneDisplayProps) => {
    return phone && <Stack direction={"row"}>
        <PhoneInput
            containerStyle={{ width: "0", marginRight: "25px" }}
            value={phone?.toString() ?? ''}
            inputStyle={{ border: "none", paddingLeft: '0px', width: '100%', fontSize: '12px', height: '18px', cursor: "pointer", color: "#52bbe8", textDecoration: "underline", background: "transparent", textAlign: 'center', display: "none" }}
            disableDropdown
            disabled

            buttonStyle={{ border: "none", background: "transparent", left: "-5px", top: isExtensionContext() ? "-5px" : "-2px" }}
        />
        <a style={{ border: "none", width: 'fit-content', fontSize: '12px', height: '18px', cursor: "pointer", color: "#52bbe8", textDecoration: "underline", background: "transparent", textAlign: 'center', }} href={'tel:' + phone}>
            +{phone}
        </a>
    </Stack>
}