import { Step, StepIconProps, StepLabel, Stepper } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { BulkDashboardTab, setActiveTab } from '@reducers/CampaignReducer';
import { getCampaignDueDate, getCampaignSelectedAudenicesAmount, getIsCampaginMessageExist } from '@reducers/CampaignSelectors';
import { useAnalyticsService } from '@reducers/hooks/use-analytics-service';
import { AnalyticsService } from '@services/analytics-service';
import { getRuntimeUrl } from '@services/storage';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import React from 'react';


export default function LaunchCampaignFlowTabs() {
    const dispatch = useAppDispatch()
    const campaignSelectedAudenicesAmount = useAppSelector((state) => getCampaignSelectedAudenicesAmount(state));
    const campaignDueDate = useAppSelector((state) => getCampaignDueDate(state));
    const isCampaginMessageExist = useAppSelector((state) => getIsCampaginMessageExist(state));
    const activeTab = useAppSelector((state) => state.CampaignReducer.activeTab);
    const analyticsService: AnalyticsService = useAnalyticsService();
    const srcAudiencesTab = getRuntimeUrl("/img/AudiencesTabWhite.svg")
    const srcTimeAndDateTab = getRuntimeUrl("/img/TimeAndDateTabWhite.svg")
    const srcMessageAndFilesTab = getRuntimeUrl("/img/MessageAndFilesTabWhite.svg")
    const srcFinishTab = getRuntimeUrl("/img/FinishTabWhite.svg")

    const tabsData = [
        {
            label: "Select target audience",
            value: "audiences",
            iconSrc: srcAudiencesTab,
            alt: "Select target audience"
        },
        {
            label: "Write your message",
            value: "messageAndFiles",
            iconSrc: srcMessageAndFilesTab,
            alt: "Write your message"
        },
        {
            label: "Set the launch time for the campaign.",
            value: "timeAndDate",
            iconSrc: srcTimeAndDateTab,
            alt: "Set the launch time for the campaign."
        },
        {
            label: "Launch campaign",
            value: "finish",
            iconSrc: srcFinishTab,
            alt: "Launch campaign"
        },
    ];



    const getActiveIndex = () => {

        let activeTabIndex = 0;
        if (activeTab === "audiences") {
            activeTabIndex = 0;
        }
        else if (activeTab === "messageAndFiles") {
            activeTabIndex = 1;
        }
        else if (activeTab === "timeAndDate") {
            activeTabIndex = 2;
        }
        else if (activeTab === "finish") {
            activeTabIndex = 3;
        }

        console.log("getActiveIndex: activeTab", activeTabIndex)

        return activeTabIndex;
    }

    const isStepClickable = (stepValue: string) => {
        let isClickable = false;
        if (stepValue === "audiences") {
            isClickable = true; // First step, no previous condition.
        }
        else if (stepValue === "messageAndFiles") {
            // Now also checks if the first step is 'clickable'
            isClickable = campaignSelectedAudenicesAmount > 0;
        }
        else if (stepValue === "timeAndDate") {
            // Checks if the first and second steps are 'clickable'
            isClickable = campaignSelectedAudenicesAmount > 0 && isCampaginMessageExist;
        }
        else if (stepValue === "finish") {
            // Checks if the first, second, and third steps are 'clickable'
            isClickable = campaignSelectedAudenicesAmount > 0 && isCampaginMessageExist && !!campaignDueDate;
        }
        return isClickable;
    }


    const handleBulkCampaignTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        analyticsService.event('bulk_dashboard_tab_change', {
            category: 'bulk',
            action: newValue
        });

        dispatch(setActiveTab(newValue as BulkDashboardTab));
    }

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage: 'linear-gradient(128deg, rgb(58 190 95) 0%, rgb(71 125 40) 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage: 'linear-gradient(128deg, rgb(58 190 95) 0%, rgb(71 125 40) 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));


    const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean };
    }>(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',

        ...((ownerState.completed || ownerState.active) && {
            cursor: 'pointer',
            '&:hover': {
                backgroundImage: 'linear-gradient(128deg, rgb(43 143 71) 0%, rgb(50 89 28) 100%)',
            },
        }),

        ...(ownerState.active && {
            backgroundImage: 'linear-gradient(128deg, rgb(58 190 95) 0%, rgb(71 125 40) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage: 'linear-gradient(128deg, rgb(58 190 95) 0%, rgb(71 125 40) 100%)',
        }),

    }));

    function ColorlibStepIcon(props: StepIconProps) {
        const { active, completed, className } = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <img src={srcAudiencesTab} alt="Audiences Tab" />,
            2: <img src={srcMessageAndFilesTab} alt="Message and Files Tab" />,
            3: <img src={srcTimeAndDateTab} alt="Time and Date Tab" />,
            4: <img src={srcFinishTab} alt="Finish Tab" />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    return (
        <Stack direction='row' sx={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '20px',
        }}>
            {/* header tabs */}


            <Stepper alternativeLabel activeStep={getActiveIndex()} connector={<ColorlibConnector />}>
                {tabsData.map((step, index) => (
                    <Step
                        onClick={(e) => { isStepClickable(step.value) && handleBulkCampaignTabChange(e, step.value) }}
                        key={index}
                    >

                        <StepLabel StepIconComponent={ColorlibStepIcon}>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>


            {/* <Tabs sx={{
                ".MuiTabs-indicator": {
                    display: 'none',
                },
            }}
                value={activeTab} onChange={handleBulkCampaignTabChange}>
                {tabsData.map((tab) => (
                    <Tab
                        disabled={isTabDisabled(tab.value)}
                        key={tab.value}
                        sx={{
                            '&.Mui-selected': {
                                fontWeight: 'bold !important',
                                opacity: '1 !important',
                            },
                        }}
                        style={{
                            opacity: 0.65,
                            fontWeight: 'normal',
                            color: "var(--campaign-tabs-string)",
                            textTransform: 'none',
                            marginRight: '30px',
                            marginLeft: '30px',
                        }}
                        icon={<img src={tab.iconSrc} alt={tab.alt} />}
                        label={tab.label}
                        value={tab.value}
                    />
                ))}
            </Tabs> */}
        </Stack>
    )
}