import { AddOutlined } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { setIsImportModalOpen } from '@reducers/CampaignReducer';
import { selectNumberOfAudienceSelected } from '@reducers/CampaignSelectors';
import { DBBoardsThunks, boardsSelectByQuery } from '@reducers/DBServiceReducers';
import { MODAL, modalActions } from '@reducers/ModalReducer';
import { pickerDialogActions } from '@reducers/PickerDialogReducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useState } from 'react';
import BulkButton from '../bulk-button';
import BulkSearchField from '../bulk-search-field';
import CampaignTabHeader from '../campaign-tab-header';
import LazyLoadingVirtuoso from '../lazy-loading-virtuoso';
import BulkAudienceSelectRow from './bulk-audience-select-row';
import ImportCSVOrGoogleSheets from './import-csv-or-google-sheets';
import { importItemsActions } from '@reducers/ImportItemsReducer';
import { generateNewAudienceName } from '@services/utils';

export default function BulkAudiencesList() {
    const dispatch = useAppDispatch()
    const selectedAudience = useAppSelector((state) => selectNumberOfAudienceSelected(state))
    const activeBulkView = useAppSelector((state) => state.CampaignReducer.activeBulkView);
    const [searchTerm, setSearchTerm] = useState("");


    const handleclickNewAudience = (e) => {
        // dispatch(handleActiveBulkViewNavigationThunk({ navigate: "toNewAudience" }))
        e.stopPropagation();
        dispatch(modalActions.setComponentToRender(MODAL.IMPORT_EXCEL_TO_BLUETICKS));
        dispatch(pickerDialogActions.closePickerDialog())
        dispatch(modalActions.setOpenedby('campaign'));
        dispatch(setIsImportModalOpen(true))
        dispatch(importItemsActions.setNewAudienceName(generateNewAudienceName()))
    }
    const selectedString = (selectedAudience !== 0) ? `${selectedAudience} audiences selected` : 'No audiences selected'


    return (
        <div style={{
            height: '100%',
            width: '100%',
        }}>
            <CampaignTabHeader
                title={activeBulkView === "campaignManagementView" ? 'Manage Audiences' : 'Who to send?'}
                subTitle={activeBulkView === "campaignManagementView" ? 'Create, Edit, duplicate, and delete audiences' : 'Select the target audiences for your campaign.'} />


            <Stack sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '80%',
            }}>
                <Stack sx={{ width: '100%', }}>
                    <div style={{
                        display: 'flex',
                        marginBottom: '25px',
                    }}>
                        <BulkSearchField
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            placeHolderString="Search audiences..."
                        />
                        <BulkButton
                            onClick={handleclickNewAudience}
                            buttonString='New Audience'
                            Icon={AddOutlined}
                            buttonWidth='230px'
                        />
                    </div>

                    <Stack sx={{
                        border: '1px solid var(--campaign-border)',
                        borderRadius: '12px',
                    }}>
                        {/* todo - show only if campaign lanch flow */}
                        {activeBulkView === "newCampaignView" &&
                            <Stack direction='row' sx={{
                                backgroundColor: 'rgba(240, 242, 245, 0.25)',
                                height: '40px',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderTopRightRadius: '12px',
                                borderTopLeftRadius: '12px',
                            }}>
                                <p style={{ color: selectedAudience !== 0 ? '#35BE5F' : 'var(--primary)' }}>
                                    &nbsp; {selectedString}
                                </p>
                                {/* DO NOT DELETE
                                 <FormControlLabel
                                control={
                                    <Switch
                                        checked={isShowSelectedToggle}
                                        onChange={handleToggleChangeShowSelectedAudience}
                                        name="showSelected"
                                        color="primary"
                                    />
                                }
                                label="Show selected"
                            /> */}
                            </Stack>
                        }

                        <LazyLoadingVirtuoso
                            height={480}
                            rowHeightString='40px'
                            filterObj={{
                                'name': { $regex: searchTerm, $options: 'i' },
                                type: 'audience',
                            }}
                            DBThunks={DBBoardsThunks}
                            DBReducer={state => state.DBBoardsReducer}
                            selectByQuery={boardsSelectByQuery}
                            Component={BulkAudienceSelectRow}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <ImportCSVOrGoogleSheets />
        </div>
    )
}