import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useAppDispatch } from '@store/hooks';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { closePopup } from '../../reducers/SubscriptionPopupReducer';
import { RootState } from '../../store';
import UpgradeBox from '../upgrade-box/upgrade-box';

export interface ISubscriptionUpgradePopup {
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function SubscriptionUpgradePopup(props: ISubscriptionUpgradePopup) {
    const dispatch = useAppDispatch()
    const isOpen = useSelector((state: RootState) => state.SubscriptionPopupReducer.isOpen);
    
    const closeAccessFormPopup = () => {
        dispatch(closePopup())
    }

    return (
        <Dialog
            dir="ltr"
            TransitionComponent={Transition}
            open={isOpen}
            onClose={closeAccessFormPopup}>
            <UpgradeBox />
        </Dialog>
    );
}

