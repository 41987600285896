import CampaignTabHeader from '@components/bt-bulk-dashboard/campaign-tab-header';
import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, FormControlLabel, InputBase, Stack, Switch, Toolbar, Typography } from '@mui/material';
import { selectAllContactsSelected } from '@reducers/TaskSelectors';
import { useAppDispatch } from '@store/hooks';
import React, { DetailedHTMLProps, FC, PropsWithChildren, useCallback } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { setShowSelected, updateSearchValue } from '../../../reducers/ContactTableReducer';
import { selectFilteredContactList, selectMergedContactMap, selectedSelectedContactCount } from '../../../reducers/WhatsAppReducer';
import { useAppSelector } from '../../../store/hooks';
import { theme } from '../../../theme';
import ContactTableRow from './contact-table-row';
import classes from './contact-table.module.scss';
import { toggleSelectAllContactsThunk } from '@reducers/WhatsappThunks';
import RowStack from '@components/common/row-stack/RowStack';

export interface BulkContact {
    contactId?: string;
    name?: string;
    formattedTitle?: string;
    mobile?: string;
    firstName?: string;
    lastName?: string;
    imageUrl?: string;
    id?: string
}

export interface ContactTableProps {
    setImportedContacts?: React.Dispatch<React.SetStateAction<any[]>>
    importedContacts?: any[]
}




export const ContactTable: FC<ContactTableProps> = () => {
    const dispatch = useAppDispatch()

    const { showSelected } = useAppSelector((state) => state.ContactTableReducer);
    const mergedContactMap = useAppSelector((state) => selectMergedContactMap(state))
    const filteredContactList = useAppSelector((state) => selectFilteredContactList(state))
    const selectedContactCount = useAppSelector((state) => selectedSelectedContactCount(state))

    const allContactsSelected = useAppSelector((state) => selectAllContactsSelected(state))

    const isContactsTableLoaded = filteredContactList !== undefined && mergedContactMap !== undefined;


    const onSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateSearchValue(e.target.value));
    }

    const handleShowSelectedChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        dispatch(setShowSelected(checked));
    }

    const HeightPreservingItem = useCallback(
        ({
            children,
            ...props
        }: PropsWithChildren<
            DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
        >) => {
            return (
                <div
                    {...props}
                    className="height-preserving-container"
                    //dir={direction}
                    style={{ height: "80px", width: '100%' }}
                >
                    {children}
                </div>
            );
        },
        []
    );

    const selectAllContacts = useCallback(() => {
        dispatch(toggleSelectAllContactsThunk())
    }, [])

    return (<>
        <Stack dir="ltr"
            sx={{
                height: '67vh',
            }}>
            <CampaignTabHeader
                title='Import contacts to your audience'
                subTitle='' />
            {isContactsTableLoaded &&
                <>
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.row} >
                            <RowStack
                                justifyContent="center"
                                sx={{
                                    width: '80px'
                                }}>
                                <Checkbox
                                    onChange={(event) => selectAllContacts()}
                                    checked={allContactsSelected}
                                    indeterminate={selectedContactCount > 0 && !allContactsSelected}
                                    sx={{
                                        color: (selectedContactCount > 0) ? '#42d871!important' : 'grey',
                                    }}
                                />
                            </RowStack>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon style={{
                                        color: '#dadada',
                                        width: '0.8em',
                                        height: '0.8em',
                                        paddingTop: 2,
                                        marginInlineEnd: '5px',
                                    }} />
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{
                                        'aria-label': 'search',
                                    }}
                                    sx={{
                                        color: 'var(--text-color)'
                                    }}
                                    onChange={onSearchQueryChange}
                                />
                            </div>
                            <Typography variant="caption" style={{ color: '#52da79', fontSize: '0.8em', fontWeight: 500 }}>
                                &nbsp; {selectedContactCount}
                                &nbsp; contacts selected
                            </Typography>
                            <FormControlLabel
                                style={{ marginLeft: theme.spacing(2) }}
                                control={
                                    <Switch
                                        checked={showSelected}
                                        onChange={handleShowSelectedChange}
                                        name="checkedB"
                                        sx={{
                                            '& .MuiSwitch-switchBase': {
                                                color: showSelected ? '#42d871' : '#9b9d9e',
                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor: showSelected ? '#42d871!important' : '#a8a8a8',
                                            },
                                        }}
                                    />
                                }
                                label="Show Selected"
                            />
                        </div>
                    </Toolbar>
                    <div className="table-container" style={{ flex: '1 1 auto', }}>
                        <Virtuoso
                            components={{
                                Item: HeightPreservingItem,
                            }}
                            // endReached={loadMore}
                            increaseViewportBy={250}
                            data={filteredContactList}
                            style={{
                                height: '90%',
                                minWidth: '400px',
                                overflowY: 'auto', // Ensure vertical scrollbar is visible
                                borderRight: '1px solid var(--import-border)', // Add border to the right of the scroller
                                borderBottom: '1px solid var(--import-border)',
                                borderBottomLeftRadius: '12px',
                                borderBottomRightRadius: '12px',
                            }}
                            totalCount={filteredContactList.length}
                            itemContent={(index, contact) => {
                                return (
                                    <ContactTableRow contact={contact} />
                                );
                            }}
                        />
                    </div>
                </>
            }
        </Stack>
    </>);
}
