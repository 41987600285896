import BTButton from '@components/bt-button/bt-button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SouthIcon from '@mui/icons-material/South';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { automation } from '@reducers/AutomationReducer';
import { createAutomation, updateAutomationDetails } from '@reducers/AutomationThunks';
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useEffect, useState } from 'react';
import MoveGroupAction from './actions/move-group-action';
import NotifyPersonAction from './actions/notify-person-action';
import AutomationActions from './automation-actions';
import AutomationCondition from './automation-condition';
import AutmationTrigger from './automation-trigger';
import InGroupCondition from './conditions/in-group-condition';
import TaskNameChangesTrigger from './triggers/task-name-changes-trigger';
import PersonAssignedTrigger from './triggers/person-assigned-trigger';
import PersonIsSomeoneCondition from './conditions/person-is-someone-condition';
import AssignPersonAction from './actions/assign-person-action';
import ExtraDataUpdateTrigger from './triggers/task-extra-data-update-trigger';
import TaskCreatedTrigger from './triggers/task-created-trigger';
import TaskMovedToGroupTrigger from './triggers/task-moved-to-group-trigger';
import ChangeStatusAction from './actions/change-status-action';
import NumberMeetsCondition from './conditions/number-meets-condition';
import StatusChangesTrigger from './triggers/status-changes-trigger';
import StatusIsSomethingCondition from './conditions/status-is-something-condition';
import ColumnChangeTrigger from './triggers/column-change-trigger';
import EveryDayDateHasPassedTrigger from './triggers/every-day-if-date-has-passed-trigger';
import WhenDateArrivesTrigger from './triggers/when-date-arrives-trigger';
import CreateTaskUpdateAction from './actions/create-task-update-action';
import { selectUser } from '@reducers/UserSelectors';
import CreateTaskAction from './actions/create-task-action';
import CreateGroupAction from './actions/create-group-action';
import ClearColumnAction from './actions/clear-column-action';
import TaskMovedToBoardTrigger from './triggers/task-moved-to-board-trigger';
import EveryTimePeriod from './triggers/every-time-period-trigger';
import ArchiveOrDeleteAction from './actions/archive-or-delete-action';
import DuplicateGroupAction from './actions/duplicate-group-action';
import SetDateToTodayAction from './actions/set-date-to-today-action';
import IncreaseOrDecreaseAction from './actions/increase-or-decrease-action';
import ClearAssignessAction from './actions/clear-assignees-action';
import CreateBoardAction from './actions/create-board-action';
import CreateTaskOnBoardAction from './actions/create-task-on-board-action';
import DuplicateTaskAction from './actions/duplicate-task-action';

const CreateOrUpdateAutomation = () => {

    const dispatch = useAppDispatch()
    const triggerDetails = useAppSelector((state) => state.AutomationReducer.trigger);
    const actions = useAppSelector((state) => state.AutomationReducer.actions);
    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    const selectedAutomationId = useAppSelector((state) => state.AutomationReducer.selectedAutomationId);
    const selectedOwner = useAppSelector((state) => state.AutomationReducer.selectedAutomationOwner);
    const user = useAppSelector(selectUser);

    const isLoading = useAppSelector((state) => state.AutomationReducer.loader);
    const [buttonText, setButtonText] = useState('Create Automation');
    const validationStates = useAppSelector(state => state.AutomationReducer.validationStates);
    const isFormValid = Object.values(validationStates)
        .flatMap(obj => Object.values(obj))
        .every(isValid => isValid);

    const handleCreateOrUpdateAutomation = async (automationId) => {
        dispatch(automation.setAttemptedSumbission(true));
        if (!isFormFilled() || !isTriggerOrActionFilled()) {
            setTimeout(() => {
                dispatch(automation.setAttemptedSumbission(false));
            }, 5000);
            return;
        }
        if (automationId) {
            dispatch(updateAutomationDetails(automationId));
        } else {
            dispatch(createAutomation());
        }
    }
    const isFormFilled = () => {
        if (!isFormValid) {
            return false;
        }
        return true;
    };

    const isTriggerOrActionFilled = () => {
        if (triggerDetails.triggerType === "" || (actions.length === 1 && actions[0].actionType === "")) {
            return false;
        }
        return true;
    };


    const renderTriggerComponent = (triggerType) => {

        switch (triggerType) {
            case "":
                return <AutmationTrigger />;
            case "complexity-picker":
            case "status-option-picker":
            case "option-picker":
                return <StatusChangesTrigger />;
            case "person-picker":
                return <PersonAssignedTrigger />;
            case "task-name-changes":
                return <TaskNameChangesTrigger />
            case "column-change":
                return <ColumnChangeTrigger />
            case "task-extra-data-update":
                return <ExtraDataUpdateTrigger />
            case "task-created":
                return <TaskCreatedTrigger />
            case "task-moved-to-group":
                return <TaskMovedToGroupTrigger />
            case "task-moved-to-board":
                return <TaskMovedToBoardTrigger />
            case "date-passed":
                return <EveryDayDateHasPassedTrigger />
            case "date-arrives":
                return <WhenDateArrivesTrigger />
            case "every-time-period":
                return <EveryTimePeriod />
        }
    }

    function renderCondition(componentName, index) {
        switch (componentName) {
            case "":
                return <AutomationCondition key={index} />;
            case "task-is-in-group":
                return <InGroupCondition key={index} index={index} />;
            case "status-is-something":
                return <StatusIsSomethingCondition key={index} index={index} />;
            case "person-is-someone":
                return <PersonIsSomeoneCondition key={index} index={index} />;
            case "number-meets-condition":
                return <NumberMeetsCondition key={index} index={index} />;
        }
    }

    function renderExtraAction(componentName, index) {
        switch (componentName) {
            case "":
                return <AutomationActions key={index} index={index} />;
            case "move-task-to-group":
                return <MoveGroupAction key={index} index={index} />;
            case "notify-person":
                return <NotifyPersonAction key={index} index={index} />;
            case "assign-person":
                return <AssignPersonAction key={index} index={index} />;
            case "change-status":
                return <ChangeStatusAction key={index} index={index} />;
            case "create-task-update":
                return <CreateTaskUpdateAction key={index} index={index} />;
            case "create-task":
                return <CreateTaskAction key={index} index={index} taskType={"task"} />;
            case "create-sub-task":
                return <CreateTaskAction key={index} index={index} taskType={"subtask"} />;
            case "create-group":
                return <CreateGroupAction key={index} index={index} />;
            case "clear-column":
                return <ClearColumnAction key={index} index={index} />;
            case "duplicate-task":
                return <DuplicateTaskAction key={index} index={index} />;
            case "archive-task":
                return <ArchiveOrDeleteAction key={index} index={index} archiveOrDelete={"archive"} />;
            case "delete-task":
                return <ArchiveOrDeleteAction key={index} index={index} archiveOrDelete={"delete"} />;
            case "duplicate-group":
                return <DuplicateGroupAction key={index} index={index} />;
            case "set-date-to-today":
                return <SetDateToTodayAction key={index} index={index} />;
            case "increase-or-decrease":
                return <IncreaseOrDecreaseAction key={index} index={index} />;
            case "clear-assignees":
                return <ClearAssignessAction key={index} index={index} />;
            case "create-board":
                return <CreateBoardAction key={index} index={index} />;
            case "create-task-in-board":
                return <CreateTaskOnBoardAction key={index} index={index} />;
        }
    }
    

    const backToPanel = () => {
        dispatch(automation.setActiveView("automationsList"));
        dispatch(automation.resetAutomationState());
    }


    useEffect(() => {
        if (selectedAutomationId) {
            setButtonText('Update automation')
        }

    }, [selectedAutomationId])
    return (

        <Stack sx={{ fontFamily: "'Poppins','Assistant',sans-serif", }} >
            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} py={2}>
                <Button sx={{ textTransform: "initial" }} onClick={backToPanel}>
                    <ArrowBackIcon /> Back to automations</Button>

                {(selectedOwner._id && user._id !== selectedOwner._id) ? (
                    <Typography fontSize={"12px"} sx={{ border: "1px solid", borderRadius: "8px", p: "5px" }}>Read only - Contact
                        <b>{selectedOwner?.firstName ? ` ${selectedOwner.firstName} ${selectedOwner.lastName} ` : `${selectedOwner.email}`}
                        </b>
                        to edit</Typography>) : null}

            </Stack>
            <Stack sx={{ display: isLoading ? 'none' : 'flex', padding: "5% 20%" }}>
                <Stack>
                    {renderTriggerComponent(triggerDetails.triggerType)}
                </Stack>

                <Stack>
                    {conditions.map((condition, index) => (renderCondition(condition.conditionType, index)))}
                </Stack>

                <Box py={2}>
                    <SouthIcon sx={{ fontSize: "30px" }} />
                </Box>

                {actions.length > 0 ? actions.map((action, index) => (renderExtraAction(action.actionType, index))) : <AutomationActions index={0} />}

                {(selectedAutomationId && user._id === selectedOwner._id) || !selectedAutomationId ? (
                    <BTButton onClick={() => handleCreateOrUpdateAutomation(selectedAutomationId)}
                        sx={{ px: 0, py: 1, width: "200px", my: 2 }}>
                        {buttonText}
                        <AutoFixHighIcon style={{ marginInlineStart: '5px', height: '18px', width: '18px', marginBottom: "5px" }} />
                    </BTButton>) : null}
            </Stack>

            {isLoading ?
                <Stack sx={{ p: 4 }} alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress />
                </Stack> : ''
            }

        </Stack>
    );
};

export default CreateOrUpdateAutomation;

