import Snippet from "../../../components/snippets/Snippet";
import { selectIsToggleOn } from "@reducers/SnippetsReducer";
import { useAppSelector } from "../../../store/hooks";

function TemplatesView(props: any) {
  const isTemplatesOn = useAppSelector(selectIsToggleOn);

  return (
    // <>
    //   <AddTemplatesButton />
    //   {isTemplatesOn && <TemplatesList />}
    //   <TemplatePanel />
    // </>

    <Snippet open={true} onClose={function (): void { }} />
  );
}

export default TemplatesView;
