import classes from "./media-manager.module.scss";
import { useAppDispatch } from "@store/hooks";
import { mediaManager } from "@reducers/MediaManagerReducer";
import { useAppSelector } from "../../../store/hooks";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import DocViewer, { DocRenderer, DocViewerRenderers } from "@cyntler/react-doc-viewer";
import CancelIcon from '@mui/icons-material/Cancel';
import { BiDownload, BiTrash } from "react-icons/bi";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AiFillFileZip } from "react-icons/ai";
import { Tooltip } from "@mui/material";
import JSZip from 'jszip';
import JSZipUtils from "jszip-utils";
import FileSaver from 'file-saver';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IconButton } from '@mui/material';
import FilesVersions from "@components/files-versions/files-versions";
import MediaManagerHeader from "@views/media-manager-header/media-manager-header";
import MediaManagerSideBar from "@views/media-manager-sidebar/media-manager-sidebar";
import { getFilesCurrentIndexAndVersionCurrentIndex, getFileUrl, getMediaManagerFileList } from "@reducers/MediaManagerSelectors";
import { deleteFile } from "@reducers/MediaManagerThunks";
import MediaMangerEdit from "@components/media-manager-edit/media-manager-edit";
import { cellValueSelector } from "@reducers/TaskSelectors";


export default function MediaManager() {
    console.log('media manager render');
    const dispatch = useAppDispatch()
    const fileList = useAppSelector(getMediaManagerFileList);
    const taskId = useAppSelector((state) => state.MediaManagerReducer.taskId);
    const columnId = useAppSelector((state) => state.MediaManagerReducer.columnId);
    const cellValue = useAppSelector((state) => cellValueSelector(state, taskId, columnId))
    const isViewOnly = useAppSelector((state) => state.MediaManagerReducer.isViewOnly);
    const isMediaManagerShown = useAppSelector((state) => state.MediaManagerReducer.shown);
    const isShowVersions = useAppSelector((state) => state.MediaManagerReducer.showVersionTab);
    const isEditMode = useAppSelector((state) => state.MediaManagerReducer.showEditTab);
    const { filesCurrentIndex } = useAppSelector(getFilesCurrentIndexAndVersionCurrentIndex);
    const fileUrl = useAppSelector(getFileUrl);
    const filesLength = fileList?.length;
    const currentFileShown = fileList[filesCurrentIndex];
    const fileName = currentFileShown.filename;
    let filesStateUpdate = {};
    DocViewerRenderers.push(MyCustomWebpRenderer)
    function downloadFile(file) {
        FileSaver.saveAs(file.url, file.filename);
    }

    function downloadZip(files) {
        const zip = new JSZip();
        let count = 0;
        files.forEach(function (file) {
            var filename = file.filename;
            JSZipUtils.getBinaryContent(file.url, function (err, data) {
                if (err) {
                    throw err;
                }
                zip.file(filename, data, { binary: true });
                count++;
                if (count === files.length) {
                    zip.generateAsync({ type: 'blob' }).then(function (content) {
                        FileSaver.saveAs(content, 'download.zip');
                    });
                }
            });
        });
    }

    function switchFile(type) {
        if (filesLength === 1) return;
        if (type === 'prev') {
            filesStateUpdate["index"] = filesCurrentIndex === 0 ? filesLength - 1 : filesCurrentIndex - 1;
        } else {
            filesStateUpdate["index"] = filesCurrentIndex !== filesLength - 1 ? filesCurrentIndex + 1 : 0
        }
        filesStateUpdate["isVersionPreview"] = false;
        filesStateUpdate["versionIndex"] = 0;
        if (fileList[filesStateUpdate["index"]]._id) {
            dispatch(
                mediaManager.setTaskId(fileList[filesStateUpdate["index"]]._id),
            );
        }
        dispatch(
            mediaManager.switchFile(filesStateUpdate),
        );
    }

    function clearFile() {
        let fileListFiltered = cellValue.filter((_: number, index: number) => index !== filesCurrentIndex);
        fileListFiltered = fileListFiltered.length === 0 ? undefined : fileListFiltered;
        fileListFiltered === undefined ? closeMediaManager() : switchFile('next');

        //    let fileListFiltered = cellValue.filter((_: number, index: number) => index !== 1);
        //    switchFile('next');
        dispatch(deleteFile(fileListFiltered))
    }

    function closeMediaManager() {
        filesStateUpdate["shown"] = false;
        filesStateUpdate["taskId"] = null;
        dispatch(
            mediaManager.closeDialog(filesStateUpdate)
        );
    }

    function checkKey(e) {
        if (e.key === "ArrowRight"
        ) {
            switchFile('next')
        }
        if (e.key === "ArrowLeft"
        ) {
            switchFile('prev')
        }
    }




    return (
        <Dialog onKeyDown={checkKey} onClose={closeMediaManager} fullWidth maxWidth="xl" open={isMediaManagerShown}>
            <DialogContent sx={{ padding: "0px", overflow: 'hidden' }}>
                <div id="media-manager" className={classes.mediaManager}>
                    <div className={classes.mediaManagerHeader}>
                        <MediaManagerHeader file={currentFileShown} fileName={fileName} />
                        <div onClick={closeMediaManager}>
                            <IconButton color="primary" aria-label="close manager" component="label">
                                <CancelIcon color="action" />
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.mediaManagerBody}>
                        {fileUrl && (
                            <div className={classes.docPreviewContainer}>
                                <div className={filesLength === 1 ? classes.disabled : classes.arrowContainer}> <span className={classes.previousArrow} onClick={() => switchFile('prev')}>
                                    <Tooltip title={filesLength > 1 ? 'Previous file' : 'No other files'} arrow placement="top" disableInteractive>
                                        <IconButton>
                                            <ChevronLeftIcon style={{ fontSize: '50px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                                    <span className={classes.nextArrow} onClick={() => switchFile('next')}>
                                        <Tooltip title={filesLength > 1 ? 'Next file' : 'No other files'} arrow placement="top" disableInteractive>
                                            <IconButton>
                                                <ChevronRightIcon style={{ fontSize: '50px' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </span></div>
                                <DocViewer theme={{
                                    disableThemeScrollbar: true,
                                }} className={classes.docViewer} config={{
                                    header: {
                                        disableHeader: true,
                                        disableFileName: true,
                                        retainURLParams: true
                                    }
                                }} pluginRenderers={DocViewerRenderers} documents={[{ uri: fileUrl }]} />
                                <div className={classes.actions}>

                                    <Tooltip title="Download file" arrow placement="top" disableInteractive>
                                        <IconButton size="small" onClick={() => downloadFile(currentFileShown)}>
                                            <BiDownload />
                                        </IconButton>
                                    </Tooltip>
                                    {fileList && filesLength > 1 && (
                                        <Tooltip title={'Download ' + filesLength + ' files as zip'} arrow placement="top" disableInteractive>
                                            <IconButton size="small" onClick={() => downloadZip(fileList)}>
                                                <AiFillFileZip />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {!isViewOnly ? (<Tooltip title="Delete" arrow placement="top" disableInteractive>
                                        <IconButton size="small" onClick={() => clearFile()}>
                                            <BiTrash />
                                        </IconButton>
                                    </Tooltip>) : null}

                                </div>
                            </div>
                        )}
                        {isShowVersions && taskId && columnId && (
                            <div className={classes.versionsWrapper}>
                                <FilesVersions />
                            </div>
                        )}
                        {isEditMode ? (
                            <div className={classes.editWrapper}>
                                <MediaMangerEdit />
                            </div>
                        ) : null
                        }
                        {taskId && columnId &&
                            < MediaManagerSideBar />
                        }

                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}



const MyCustomWebpRenderer: DocRenderer = ({
    mainState: { currentDocument },
}) => {
    if (!currentDocument) return null;

    return (
        <div style={{ margin: "auto", overflow: "hidden", height: "100%" }} id="my-webp-renderer">
            <img id="webp-img" width="100%" height={"100%"} alt={currentDocument?.fileName} src={currentDocument.fileData as string} />
        </div>
    );
};

MyCustomWebpRenderer.fileTypes = ["webp", "image/webp"];
MyCustomWebpRenderer.weight = 1;