import BTInputField from "@components/bt-input-field/bt-input-field"
import { ExpandMore } from '@mui/icons-material'
import { Button, Menu, MenuItem, Stack, styled } from '@mui/material'
import { getRemainingFeatureForMoreButton } from '@reducers/BoardsSelectors'
import { DisplayMode, taskActions } from '@reducers/TasksReducer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { MutableRefObject, useRef, useState } from 'react'
import TaskHeaderTab from './task-header-tab'
import classes from "./tasks-header.module.scss"
import { namespace } from "config/constants"
import { useTranslation } from "react-i18next"


type Props = {
    width: number
    tabsRef: React.MutableRefObject<HTMLDivElement[]>
}

export default function TabsMoreButton({
    width,
    tabsRef,
}: Props) {
    const { t } = useTranslation(namespace);
    const [searchQuery, setSearchQuery] = useState('')

    const remainingFeaturesList = useAppSelector((state) => getRemainingFeatureForMoreButton(state, searchQuery, width, tabsRef));

    const dispatch = useAppDispatch()

    const searchInputRef: MutableRefObject<HTMLInputElement> = useRef();


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const SearchBox = () => (
        <div className={classes.search_input_container} >
            <BTInputField
                autoFocus={true}
                onKeyDown={(e) => e.stopPropagation()}
                className={classes.search_input}
                type="text"
                value={searchQuery}
                ref={searchInputRef}
                onChange={(event) => {
                    event.preventDefault()
                    setSearchQuery(event.target.value)
                }}
                placeholder={t('tabsMoreButton.searchPlaceholder')}
            />
        </div>
    )


    const CustomMenuItem = styled(MenuItem)({
        '&:hover': {
            backgroundColor: 'transparent', // Restore the original background color
            color: 'transparent', // Restore the original text color
        },
        '&:focus-visible': {
            backgroundColor: 'transparent', // Restore the original background color
            color: 'transparent', // Restore the original text color
        },
    });

    return (
        <Stack
            direction="row" alignItems="center"
            key={`tab_more_button`}
            className={classes.view_button_container}
        >
            <Button
                onClick={handleClick}
                className={classes.view_button}
                endIcon={<ExpandMore />}>
                {t('tabsMoreButton.more')}
            </ Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        maxHeight: 400,
                        maxWidth: 400,
                        width: 300,
                    },
                }}
            >
                <CustomMenuItem disableRipple
                    sx={{
                        '&.Mui-focusVisible, &.MuiListItem-root.Mui-selected, &:hover, &:active': {
                            backgroundColor: 'inherit', // Preserve the original background color
                        },
                    }}>
                    <Stack direction='column' sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        <SearchBox />
                    </Stack>
                </CustomMenuItem>
                {remainingFeaturesList.map((feature, index) =>
                    <CustomMenuItem
                        key={`more_button_feature_${index}`}
                        onClick={() => {
                            dispatch(taskActions.setCurrentBoardView({
                                type: feature?.type as DisplayMode,
                                id: feature?._id.toString()
                            }))
                        }}>
                        <TaskHeaderTab
                            featureId={feature?._id.toString()}
                            featureName={feature?.name}
                            type={feature?.type}
                            index={index}
                            isFullWidth={true} />
                    </CustomMenuItem>
                )
                }
            </Menu >
        </Stack >
    )
}