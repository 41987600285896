import { bulkActions } from '@reducers/BulkActionsReducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { BsArrowLeft } from 'react-icons/bs';
import MoveToDialog from './move-to-dialog';
import classes from './move-to-menu.module.scss';
import { namespace } from 'config/constants';
import { useTranslation } from 'react-i18next';

export default function MoveToMenu() {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch();
    const move_to = useAppSelector((state) => state.BulkActionsReducer.move_to);

    return (
        <div className={classes.move_to_menu_wrapper}>
            {move_to ? (
                <div>
                    <div className={classes.move_to_header}>
                        <p>{t('moveToMenu.choose')} {move_to}</p>
                        <button onClick={() => dispatch(bulkActions.setMovingTo(''))} className={classes.back_btn}>
                            <BsArrowLeft /> {t('moveToMenu.back')}
                        </button>
                    </div>
                    <MoveToDialog />
                </div>
            ) : (
                <>
                    <button onClick={() => dispatch(bulkActions.setMovingTo('group'))} className={classes.move_to_btn}>{t('moveToMenu.moveToGroup')}</button>
                    <button onClick={() => dispatch(bulkActions.setMovingTo('board'))} className={classes.move_to_btn}>{t('moveToMenu.moveToBoard')}</button>
                </>
            )}
        </div>
    );
}
