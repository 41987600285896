
import MockdataTest from '@components/tasks-panel/tasks-panel-header/mockdata-test'

export default function CellMockDataView() {

  return (
    <div >
      <MockdataTest />
    </div>
  )
}

