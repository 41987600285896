import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { cellValueSelector } from "./TaskSelectors";

export const getMediaManagerFileList = createSelector(
  (state) => state,
  (state: RootState) => {
    let fileList;
    if (state.MediaManagerReducer.filesTotal?.length > 0) {
      fileList = state.MediaManagerReducer.filesTotal;
    } else {
      fileList = cellValueSelector(
        state,
        state.MediaManagerReducer.taskId,
        state.MediaManagerReducer.columnId
      );
    }

    return fileList ?? [];
  }
);

export const getFilesCurrentIndexAndVersionCurrentIndex = createSelector(
  (state: RootState) => state,
  (state) => {
    const fileList = getMediaManagerFileList(state);
    const isVersionPreview = state.MediaManagerReducer.isVersionPreview;
    const fileStartingIndex = state.MediaManagerReducer.index;
    const versionIndex = state.MediaManagerReducer.versionIndex;
    let filesCurrentIndex = 0;
    let currentFileVersion = -1;
    if (!isVersionPreview) {
      filesCurrentIndex =
        fileStartingIndex === fileList?.length
          ? fileStartingIndex - 1
          : fileStartingIndex;
      currentFileVersion = -1;
    } else {
      filesCurrentIndex = fileStartingIndex;
      currentFileVersion = versionIndex;
    }
    return { fileList, filesCurrentIndex, currentFileVersion };
  }
);

export const getFileUrl = createSelector(
  (state: RootState) => state,
  (state) => {
    const fileList = getMediaManagerFileList(state);
    const isVersionPreview = state.MediaManagerReducer.isVersionPreview;
    const { filesCurrentIndex } =
      getFilesCurrentIndexAndVersionCurrentIndex(state);
    const versionIndex = state.MediaManagerReducer.versionIndex;
    const fileUrl = isVersionPreview
      ? fileList?.[filesCurrentIndex].versions?.[versionIndex].url
      : fileList?.[filesCurrentIndex].url;
    return fileUrl;
  }
);