// components/Wizard.js
import { useEffect, useState } from "react";
import { Modal, Box, Backdrop, Button } from "@mui/material";
import classes from "./wizard.module.scss";
import {
  CloseIcon,
  BackIcon,
  ScheduleSuperhero,
  TasksSuperhero,
  CampaignsSuperhero,
} from "./wizard-icons";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { setShowWizard } from "@reducers/WhatsAppReducer";

export enum WizardStep {
  main = "main",
  schedule = "schedule",
  tasks = "tasks",
  campaigns = "campaigns",
}

type WizardStepDataEntry = {
  title: string;
  description: string;
  youtubeId: string;
  illustration: JSX.Element;
};

export const WizardStepData: { [key in WizardStep]?: WizardStepDataEntry } = {
  [WizardStep.schedule]: {
    title: "⏰ Schedule a message",
    description:
      "In this video, you'll learn how to schedule a message to be sent at a later time.",
    youtubeId: "6RJhxVrymsg",
    illustration: ScheduleSuperhero,
  },
  [WizardStep.tasks]: {
    title: "✅ Manage tasks",
    description:
      "In this video, you'll learn how to manage your tasks and boost your productivity.",
    youtubeId: "HVDT7I1YivY",
    illustration: TasksSuperhero,
  },
  [WizardStep.campaigns]: {
    title: "🚀 Launch a campaign",
    description:
      "In this video, you'll learn how to launch a campaign to send messages to multiple contacts at once.",
    youtubeId: "OonNLmnZqAw",
    illustration: CampaignsSuperhero,
  },
};

function Wizard() {
  const LOCALSTORAGE_KEY = "wasOnboardingWizardShown";
  // const [showWizard, setShowWizard] = useState(false);
  // const open = useAppSelector((state) => state.WhatsAppReducer.isWizardOpen);
  const showWizard = useAppSelector((state) => state.WhatsAppReducer.showWizard)
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const wasOnboardingWizardShown = localStorage.getItem(LOCALSTORAGE_KEY);
    if (wasOnboardingWizardShown === null) {
      localStorage.setItem(LOCALSTORAGE_KEY, "true");
      dispatch(setShowWizard(true));
      setOpen(true)
    }
  }, [showWizard]);

  if (!showWizard) {
    return null;
  }

  return <WizardModal open={open} handleClose={() => { setOpen(false); dispatch(setShowWizard(false)) }} />;
}

interface WizardModalProps {
  open: boolean;
  handleClose: () => void;
}

export const WizardModal = ({ open, handleClose }: WizardModalProps) => {
  const [step, setStep] = useState(WizardStep.main);

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
     
          handleClose();
      
      }}
      closeAfterTransition
    >
      <Box
        dir="ltr"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 890,
          maxWidth: "100%",
          height: 483,
          boxSizing: "border-box",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          color: "#3b4a54",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {step === WizardStep.main && (
            <span style={{ cursor: "pointer" }} onClick={handleClose}>
              {CloseIcon}
            </span>
          )}
          {step !== WizardStep.main && (
            <span
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "12px",
              }}
              onClick={() => setStep(WizardStep.main)}
            >
              {BackIcon}
            </span>
          )}
          <img src="/img/blueticks-logo.svg" alt="" height="34" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            height: "calc(100% - 60px)",
          }}
        >

          {step === WizardStep.main && (
            <>
              <h2
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                  // marginTop: "40px",
                  // marginBottom: "40px",
                }}
              >
                Your new{" "}
                <span className={classes.blue_text_gradient}>superpowers</span>.
              </h2>
              <p style={{ marginBlockStart: `1em`, marginBlockEnd: `1em`,fontWeight:"600" }}>
              What would you like to begin with?
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "16px",
                  padding: "16px",
                }}
              >
                {Object.keys(WizardStepData).map((step: WizardStep) => (
                  <div
                    key={step}
                    className={classes.box}
                    onClick={() => setStep(step)}
                  >
                    {WizardStepData[step].illustration}
                    <span>{WizardStepData[step].title}</span>
                  </div>
                ))}
              </div>
            </>
          )}

          {step !== WizardStep.main && (
            <>
              <iframe
                style={{
                  height: `100%`,
                  borderRadius: `16px`,
                  border: `1px solid #f0f0f0`,
                  marginTop: "16px",
                }}
                id="video-iframe"
                allowFullScreen={true}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="YouTube video player"
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${WizardStepData[step].youtubeId}?autoplay=0&amp;amp;mute=0&amp;amp;controls=1&amp;amp;origin=https%3A%2F%2Fblueticks.co&amp;amp;playsinline=1&amp;amp;showinfo=0&amp;amp;rel=0&amp;amp;iv_load_policy=3&amp;amp;modestbranding=1&amp;amp;enablejsapi=1&amp;amp;widgetid=5`}
                data-gtm-yt-inspected-6="true"
                data-gtm-yt-inspected-16="true"
              ></iframe>
              <p
                style={{
                  textAlign: "center",
                  marginBlockStart: `1em`,
                  marginBlockEnd: `1em`,
                }}
              >
                {WizardStepData[step].description}
              </p>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default Wizard;
