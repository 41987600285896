import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs, IconButton, Tooltip, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import NoResults from '@assets/lottie/no-results.json';
import BTInputField from "@components/bt-input-field/bt-input-field";
import SearchIcon from '@mui/icons-material/Search';
import Lottie from "lottie-react";
import WhatsappContactBox from "@views/whatsapp-view/whatsapp-contact-box";
import MessageCalendar from "website/views/calendar-view/message-calendar";
import { getContactWithLastMessage } from "@reducers/UserMessageReducer";
import { focusContactThunk } from "@reducers/WhatsappThunks";
import { mainSliceActions } from "../../reducers/MainReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import classes from "./scheduled-contact-list.module.scss";
import { ScheduledMessagesLogs } from '@components/scheduled-messages-logs/scheduled-messages-logs';
import { DBMessageLogThunks, messageLogSelectByQuery } from '@reducers/DBServiceReducers';


export function ScheduledContactList(): React.ReactElement {
  const dispatch = useAppDispatch();
  const scheduledContactListOpen = useAppSelector((state) => state.MainReducer.scheduledContactListOpen);
  const [searchToken, setSearchToken] = useState<string | undefined>();
  const [selectedTab, setSelectedTab] = useState(0);

  const messageListGroupByPerson = useAppSelector(state => getContactWithLastMessage(state, searchToken));


  
  const messagesLogs = useAppSelector((state) =>
    messageLogSelectByQuery(state.DBMessageLogReducer,
      {
        ...(searchToken ? {messageId:searchToken} : {})
      },
    )
  );

  useEffect(() => {
    dispatch(DBMessageLogThunks.find({
      $sort: {
        createdAt:-1
      },
      $limit: -1
    }));
  }, [dispatch]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const closePanel = () => {
    dispatch(mainSliceActions.toggleScheduledContactList(false));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const focusWAContact = (contactId: string) => {
    dispatch(focusContactThunk(contactId));
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <div className={classes.contactListContainer}>
            {messageListGroupByPerson?.length === 0 ? (
              <Stack alignItems={"center"} style={{ padding: '24px' }}>
                <Lottie autoPlay={true} loop={true} animationData={NoResults} style={{ width: '250px', height: '250px' }} />
                <br />
                We couldn't find any scheduled messages.
              </Stack>
            ) : messageListGroupByPerson.map((message, index) => (
              <Box sx={{
                padding: "16px 11px",
                borderBottom: '1px solid var(--border-list)',
                '&:hover': {
                  backgroundColor: 'var(--background-default-hover)',
                  cursor: 'pointer',
                }
              }} key={`contact${index}`} onClick={(e) => focusWAContact(message.contactWAId)}>
                <WhatsappContactBox
                  profileImg={message.profileImg}
                  whatsappId={message.contactWAId}
                  message={message.message}
                  messageCount={message.messageCount}
                  messageScheduledCount={message.messageScheduledCount}
                  messageApiCount={message.messageApiCount}
                  messageCampaignCount={message.messageCampaignCount}
                  dueDate={message.dueDate}
                  email={message.email}
                />
              </Box>
            ))}
          </div>
        );
      case 1:
        return <div className={classes.contactListContainer}>
{/* 
          <ScheduledMessagesLogs  messagesLogs={messagesLogs}/> */}
        </div>;
      default:
        return null;
    }
  };

  return (
    scheduledContactListOpen && (
      <Box sx={{
        boxShadow: '0 5px 12px var(--shadow-default-color);',
        zIndex: 1000,
        position: 'relative',
        direction: 'ltr'
      }}>
   
        <Stack className={classes.header} direction="row" justifyContent="space-between" spacing={1}>
          <BTInputField
            placeholder={selectedTab === 1 ? 'Select by Message ID' : "Search scheduled messages"}
            value={searchToken}
            onChange={(event) => setSearchToken(event.target.value)}
            startAdornment={<SearchIcon sx={{ fontSize: "20px", color: 'var(--primary)' }} />}
            sx={{
              flex: 'auto',
              backgroundColor: 'var(--search-input-background)',
              padding: "0 14px",
              height: "35px",
              "& .MuiInputBase-input": {
                padding: "2px 0",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
                padding: 0
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
                boxShadow: "none"
              },
              "&::placeholder": {
                paddingLeft: "15px",
                fontSize: "14px"
              }
            }} />
          <Tooltip title="Close scheduled messages" arrow placement="top">
            <IconButton style={{ cursor: "pointer", marginRight: "8px", padding: 0, color: 'var(--primary)' }} onClick={closePanel}>
              <Close />
            </IconButton>
          </Tooltip>
        </Stack>
        {/* <Tabs sx={{background:"var(--default-background)"}} value={selectedTab} onChange={handleTabChange} aria-label="scheduled messages tabs">
          <Tab sx={{width:"50%",textTransform:"capitalize"}} label="Scheduled Messages" />
          <Tab sx={{width:"50%",textTransform:"capitalize"}} label="Logs" />
        </Tabs> */}
        {renderTabContent()}
      </Box>
    )
  );
}
