import { WhatsappContact } from "@services/whatsapp";
import moment, { Moment } from "moment";

//helper fn gets all contact ids list
export function getAllContactIds(contactList: WhatsappContact[]) {
    const contactIds: string[] = []
    contactList.forEach((contact) => {
        contactIds.push(contact.id._serialized)
    })
    if (contactIds) return contactIds
}



// Check if time has expired
export function hasTimeExpired(futureTime: Moment) {
    if (futureTime.isValid) {
        const date = new Date().toISOString();
        var currentTime = moment(date);
        return futureTime.isBefore(currentTime);
    }
}