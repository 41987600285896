import BTThemeProvider from '@components/bt-theme-provider';
import RowStack from '@components/common/row-stack/RowStack';
import Tooltip from '@mui/material/Tooltip';
import { Stack } from '@mui/system';
import { mainSliceActions } from '@reducers/MainReducer';
import { handleClickScheduleMessageThunk } from '@reducers/WhatsappThunks';
import { getRuntimeUrl } from '@services/storage';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { ExtentionMenuIconButton, ExtentionMenuTitle } from './extention-menu-components';

type Props = {
    handleClose: () => void;
}

export default function ManageMessagesMenu({
    handleClose,
}: Props) {
    const dispatch = useAppDispatch()
    const currentChat = useAppSelector((state) => state.WhatsAppReducer.currentChat)

    const manageMessagesIcon = getRuntimeUrl("/img/manage-messages.svg")
    const scheduleMessageSVG = getRuntimeUrl("/img/scheduleMessageSVG2.svg")


    const handleClickOpenScheduledMessageModal = async () => {
        dispatch(handleClickScheduleMessageThunk())
        handleClose()
    };

    return (
        <BTThemeProvider>
            <Stack sx={{
                backgroundColor: 'var(--default-background)',
                direction: 'ltr',
            }}>

                <ExtentionMenuTitle text='CREATE' />

                <RowStack>
                    {currentChat &&
                        <ExtentionMenuIconButton
                            svg={scheduleMessageSVG}
                            label='Schedule a message'
                            onClick={() => { handleClickOpenScheduledMessageModal() }}
                        />
                    }
                    {!currentChat &&
                        <Tooltip title='you need to select a chat in order to use this'>
                            <div style={{ width: '100%', }}>
                                <ExtentionMenuIconButton
                                    onClick={() => { handleClickOpenScheduledMessageModal() }}
                                    svg={scheduleMessageSVG}
                                    label='Schedule a message'
                                    filter={'grayscale(100%)'}
                                />
                            </div>
                        </Tooltip>
                    }
                </RowStack>

                <ExtentionMenuTitle text='MANAGE' />
                <RowStack>
                    <ExtentionMenuIconButton
                        svg={manageMessagesIcon}
                        label='Manage scheduled messages'
                        onClick={() => {
                            dispatch(mainSliceActions.toggleScheduledContactList());
                            handleClose()
                        }}
                    />
                </RowStack>
            </Stack>
        </BTThemeProvider>

    )
}