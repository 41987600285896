import { LinearProgress, LinearProgressProps, Stack, Tooltip, Typography, linearProgressClasses, styled } from '@mui/material';
import { activeSubscriptionSelector } from '@reducers/UserSelectors';
import { useAppSelector } from '@store/hooks';
import moment from 'moment';
interface CustomLinearProgressProps extends LinearProgressProps {
    trialEnded: boolean;
  }
const BorderLinearProgress = styled(LinearProgress, {
    shouldForwardProp: (prop) => prop !== 'trialEnded',
  })<CustomLinearProgressProps>(({ theme, trialEnded }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: trialEnded ? theme.palette.error.main : (theme.palette.mode === 'light' ? '#259ae9' : '#308fe8'),
    },
  }));
  
const TrialProgressBar = () => {
    const workspaceSubscription = useAppSelector(activeSubscriptionSelector);
    const expiredMoment = moment(workspaceSubscription?.expiredDate);
    const createdMoment = moment(workspaceSubscription.createdAt);

    const totalDays = Math.ceil(expiredMoment.diff(createdMoment, 'days', true));
    const daysLeft = Math.ceil(expiredMoment.diff(moment(), 'days', true));
    const progress = Math.max((daysLeft / totalDays) * 100, 0); 
    const trialEnded = daysLeft <= 0;

    return (
        <Stack direction="column" alignItems="center" spacing={1} sx={{width:"100%"}}>
            <Tooltip arrow title={trialEnded ? "Trial ended" : `${daysLeft} ${daysLeft === 1 ? 'Day' : 'Days'} left out of ${totalDays} for trial`}  placement="top">
                <Stack direction="row" width="100%" alignItems="center" spacing={1}>
                    <BorderLinearProgress variant="determinate" value={trialEnded ? 100 : progress} sx={{ flexGrow: 1 }} trialEnded={trialEnded} />
                    <Typography variant="body2" sx={{ flexGrow: 0 }}>
                        {trialEnded ? "Trial ended" : `${daysLeft}/${totalDays}`}
                    </Typography>
                </Stack>
            </Tooltip>
        </Stack>
    );
};

export default TrialProgressBar;