import React from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { automation } from "@reducers/AutomationReducer";
import { selectUser } from '@reducers/UserSelectors';
import { useAppDispatch, useAppSelector } from "@store/hooks";

const AddAndRemove = ({ index, elements, type, componentName = "" }) => {
    const dispatch = useAppDispatch()
    const selectedOwner = useAppSelector((state) => state.AutomationReducer.selectedAutomationOwner);
    const user = useAppSelector(selectUser);
    const selectedAutomationId = useAppSelector((state) => state.AutomationReducer.selectedAutomationId);
    const removeElement = (index) => {
        const elementsClone = [...elements];
        if (componentName) {
            dispatch(automation.removeFieldValidity({ component: componentName, index }));
        }
        switch (type) {
            case 'actions':
                elementsClone.splice(index, 1)
                dispatch(automation.setActions(elementsClone));
                break;
            case 'conditions':
                elementsClone.splice(index, 1)
                dispatch(automation.setConditions(elementsClone));
                break;
            case 'trigger':
                dispatch(automation.setTrigger({ triggerType: "" }),);
                dispatch(automation.setConditions([]));
                dispatch(automation.setActions([{ actionType: "" }]));
                dispatch(automation.resetValidationStates({}));
        }

    }

    const addElement = () => {
        const elementsClone = [...elements];
        switch (type) {
            case 'actions':
                elementsClone.push({ actionType: "" })
                dispatch(automation.setActions(elementsClone));
                break;
            case 'conditions':
                elementsClone.push({ conditionType: "" })
                dispatch(automation.setConditions(elementsClone));
                break;
            case 'trigger':
                elementsClone.push({ conditionType: "" })
                dispatch(automation.setConditions(elementsClone));
                break;
        }

    }

    return (
        (selectedAutomationId && user._id === selectedOwner._id) || !selectedAutomationId ? (
        <Stack direction={"row"} spacing={1}>
            {((type === 'trigger' && elements.length === 0) || (type !== 'trigger' && elements.length === index + 1)) && (
                <IconButton disabled={componentName === 'EveryTimePeriod'} sx={{ padding: 0,width:"30px",height:"30px" }} onClick={addElement}>
                    <AddIcon />
                </IconButton>
            )}

            <IconButton sx={{ padding: 0,width:"30px",height:"30px" }} onClick={() => removeElement(index)}>
                <DeleteIcon />
            </IconButton>
        </Stack>) : null
    )
   
};

export default React.memo(AddAndRemove);
