import React, { FC, useMemo } from 'react';
import { appConfig } from 'config/app.config';

interface ThemeAssetProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    assetName: string;
}

export const getAssetURL = (assetName: string) => {
    const assetContext = (require as any).context('../../assets/', true, /\.(svg|png|jpg|jpeg|ico)$/);
    let asset;
    try {
        asset = assetContext(`./${appConfig.asset_key}/${assetName}`);
    } catch (e) {
        asset = assetContext(`./default/${assetName}`);
    }
    return asset.default ?? asset;
};

const ThemeAsset: FC<ThemeAssetProps> = ({ assetName, ...props }) => {
    const assetSrc = useMemo(() => {
        return getAssetURL(assetName);
    }, [assetName]);

    return <img src={assetSrc} alt={props?.alt} {...props} />;
}

export default ThemeAsset;
