import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material'
import { Task } from '@models/task';
import { TasksGroup } from '@models/task-group';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { DBTasksThunks, DBTaskgroupsThunks, boardsSelectByQuery, taskGroupsSelectByQuery } from '@reducers/DBServiceReducers';
import MoveTaskToGroup from './move-task-to-group';
import classes from './move-to-menu.module.scss'
import { Board } from '@models/board';
import { bulkActions } from '@reducers/BulkActionsReducer';
import { taskActions } from '@reducers/TasksReducer';
import { BsSearch } from 'react-icons/bs';
import { namespace } from 'config/constants';
import { useTranslation } from 'react-i18next';

interface MoveTasksSelectorProps {
    bulkTasksList: Task[];
    activeTaskid: string;
    boardId: string;
}

const MoveTaskToBoard: React.FC<MoveTasksSelectorProps> = ({ bulkTasksList, activeTaskid, boardId }) => {
    const { t } = useTranslation(namespace);
    const [selectedBoardId, setSelectedBoardId] = useState<string | null>(null);
    const dispatch = useAppDispatch();
    const [filterName, setFilterName] = useState<string>('')
    const boards = useAppSelector((state) => boardsSelectByQuery(
        state.DBBoardsReducer,
        {
            name: new RegExp(filterName, "i"),
            $or: [{ deleted: { $exists: false } }, { deleted: false }]
        },
        [
            "_id",
            "updatedAt",
            "name",
            "titleIcon",
        ],
        {
            updatedAt: -1,
        }
    ))

    const groups = useAppSelector((state) => taskGroupsSelectByQuery(state.DBTaskgroupsReducer,
        {
            boardId:  selectedBoardId,
            name: new RegExp(filterName, "i"),
            $or: [{ deleted: { $exists: false } }, { deleted: false }]
        },
    ))

    function handleSearch({ target }: any) {
        setFilterName(target.value)
    }

    function moveTaskToSelectedBoardGroup(group: TasksGroup) {
        let tasksToPatch = bulkTasksList;
        if (tasksToPatch.length === 0) {
            tasksToPatch = [{ _id: activeTaskid }]
        }
        const tasksAreOnlySubTasks = tasksToPatch.every(task => task.parentTaskId !== undefined);

        const changes = tasksToPatch.map((task) => {
            let change: {
                id: string,
                changes: {
                    boardId: string,
                    groupId: string,
                    taskType?: string,
                    parentTaskId?: string,
                },
            } = {
                id: task._id,
                changes: {
                    boardId: group.boardId,
                    groupId: group._id,
                },
            };

            if (tasksAreOnlySubTasks) {
                change.changes.taskType = 'task';
                change.changes.parentTaskId = '';
            }

            return change;
        });

        dispatch(DBTasksThunks.patchMany(changes));

        dispatch(bulkActions.setIsBulkActionsDialogOpen(false));
        dispatch(bulkActions.toggleMoveTo(false));
        dispatch(bulkActions.setMovingTo(""));
        dispatch(bulkActions.setBulActionsMoveToId(''));
        dispatch(taskActions.toggleTaskMenu(''));
        dispatch(bulkActions.setBulkTasksMap({}))

    }


    const listItemStyles: any = {
        width: 198,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    return (
        <>


            {selectedBoardId ? (
                <>
                    {groups.length ? (
                        <MoveTaskToGroup bulkTasksList={bulkTasksList} activeTaskid={activeTaskid} boardId={selectedBoardId} />
                    ) : (
                        <Box className={classes.loadingGroups}>
                            <CircularProgress size={25} />
                        </Box>
                    )}
                </>
            ) : (
                <>
                    <Box className={classes.filter_container} onClick={(e) => e.stopPropagation()}>
                        <input type="text" onChange={handleSearch} placeholder={t('moveTaskToBoard.searchPlaceholder')} />
                        <button>
                            <BsSearch />
                        </button>
                    </Box>
                    {boards.length ? (
                        <>
                            {boards.map((board, index) => {
                                if (board._id === boardId) return null;
                                return (
                                    <button key={index}
                                        style={listItemStyles}
                                        onClick={() => {
                                            setSelectedBoardId(board._id);
                                            dispatch(DBTaskgroupsThunks.find({
                                                boardId: board._id,
                                                deleted: false,
                                                $paginate: false,
                                            }));
                                        }
                                        }>{board?.titleIcon} {board.name}</button>
                                )
                            })}
                        </>
                    ) : (
                        <Box className={classes.loadingGroups}>
                            <CircularProgress size={25} />
                        </Box>
                    )}
                </>
            )}
        </>
    );
}

export default MoveTaskToBoard;
