import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StorageWrapper } from "@services/storage";
import { AppDispatch, RootState } from "@store/index";
import { setErrorState, setTheme } from "./WhatsAppReducer";
interface State {
  isToggleableNavOpen: boolean;
  isInviteModalOpen: boolean;
  scheduledContactListOpen: boolean;
  formState: string,
  isEmojiPickerOpen: boolean;
  menuActiveGroupId: string
  isMobileMenuOpen: boolean,
  drawerIsOpen: boolean,
  devices: any[],
  isSocketModalOpen: boolean,
  isHowToScanQrModalOpen: boolean,
  newTabBoardIsOpen: boolean,
  newTabTimerShown: boolean,
  startChatWithPhoneNumberOpen: boolean,
  displayedNewNotifications: string[],
  staleSessionId?: string;
  newSessionRequested?: boolean;
}

const initialState: State = {
  isToggleableNavOpen: true,
  isInviteModalOpen: false,
  scheduledContactListOpen: false,
  formState: "",
  isEmojiPickerOpen: false,
  menuActiveGroupId: "",
  isMobileMenuOpen: false,
  drawerIsOpen: true,
  devices: [],
  isSocketModalOpen: false,
  isHowToScanQrModalOpen: false,
  newTabBoardIsOpen: false,
  newTabTimerShown: false,
  displayedNewNotifications: [],
  startChatWithPhoneNumberOpen: false,
  staleSessionId: null
};

const mainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    setStaleSessionId(state, action: PayloadAction<string>) {
      state.staleSessionId = action.payload;
      state.newSessionRequested = true;
    },
    handleToggleNav(state, { type, payload }: PayloadAction<boolean>) {
      state.isToggleableNavOpen = payload;
    },
    inviteModalOpen(state, { type, payload }: PayloadAction<boolean>) {
      state.isInviteModalOpen = payload;
    },
    toggleScheduledContactList(
      state,
      action: PayloadAction<boolean | undefined>
    ) {
      state.scheduledContactListOpen = action.payload ?? !state.scheduledContactListOpen;
    },
    closeScheduledContactList(
      state,
      action: PayloadAction<boolean | undefined>
    ) {
      state.scheduledContactListOpen = false;
    },
    setFormState(state, action: PayloadAction<any>) {
      state.formState = action.payload
    },
    setDevices(state, action: PayloadAction<any>) {
      state.devices = action.payload
    },
    toggleGroupMenu(state, action: PayloadAction<string>) {
      state.menuActiveGroupId = action.payload
    },
    toggleMobileMenu(state, action: PayloadAction<boolean>) {
      state.isMobileMenuOpen = action.payload
    },
    setDrawerToggle(state, action: PayloadAction<boolean>) {
      state.drawerIsOpen = action.payload
    },
    toggleSocketModal: (state, action: PayloadAction<boolean>) => {
      state.isSocketModalOpen = action.payload;
    },
    toggleHowToScanQrModal: (state, action: PayloadAction<boolean>) => {
      state.isHowToScanQrModalOpen = action.payload;
    },
    toggleNewTabBoardWindow: (state, action: PayloadAction<boolean>) => {
      state.newTabBoardIsOpen = action.payload;
    },
    toggleNewTabTimer: (state, action: PayloadAction<boolean>) => {
      state.newTabTimerShown = action.payload;
    },
    setDisplayedNewNotifications(state, action: PayloadAction<string>) {
      state.displayedNewNotifications = [...state.displayedNewNotifications, action.payload];
    },
    resetDisplayedNewNotifications(state) {
      state.displayedNewNotifications = [];
    },
    setStartChatWithPhoneNumberOpen(state, action: PayloadAction<boolean>) {
      state.startChatWithPhoneNumberOpen = action.payload;
    }
  },
});

const storageWrapper: StorageWrapper = new StorageWrapper();

export const initStorageWrapper = createAsyncThunk<void, void, { state: RootState }
>("initStorageWrapper", async (_, thunkApi) => {
  const dispatch: AppDispatch = thunkApi.dispatch;
  storageWrapper.on("storage-error", (error) => {
    console.log(error);
    dispatch(setErrorState(true));
  });
});

export const initDarkLightModeAccordingToWhatsAppMode = createAsyncThunk<void, void, { state: RootState }
>("initDarkLightModeAccordingToWhatsAppMode", async (_, thunkApi) => {
  const dispatch: AppDispatch = thunkApi.dispatch;

  const bodyObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.attributeName === "class") {
        dispatch(setTheme((mutation.target as Element).classList.contains("dark")
          ? "dark"
          : "light"));
      }
    });
  });
  if (document.querySelector("body")) {
    bodyObserver.observe(document.querySelector("body"), {
      attributes: true,
    });
  }

});

export const mainSliceActions = mainSlice.actions;

export const {
  toggleSocketModal,
  toggleHowToScanQrModal,
  toggleNewTabBoardWindow,
  toggleNewTabTimer,
  setDisplayedNewNotifications,
  resetDisplayedNewNotifications,
  setStartChatWithPhoneNumberOpen
} = mainSlice.actions;
export default mainSlice.reducer;
