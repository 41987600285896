import DeleteLottie from '@assets/lottie/delete-animation.json';
import BTButton from "@components/bt-button/bt-button";
import { Box, Stack } from "@mui/material";
import { useRouter } from "@reducers/hooks/use-router";
import { taskActions } from '@reducers/TasksReducer';
import { useAppSelector } from '@store/hooks';
import Lottie from 'lottie-react';
import { ReactElement } from "react";
import { useDispatch } from 'react-redux';



export default function DeletedBoardView(): ReactElement {
  const dispatch = useDispatch();
  const deletedBoardName = useAppSelector(state => state.TasksReducer.deletedBoardName);
  const router = useRouter();

  return (
    (<Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        margin: 'auto',
        backgroundColor: 'var(--secondary-background-color)'
      }}>

      <Lottie autoPlay={true} loop={false} animationData={DeleteLottie} style={{ width: '200px', height: '200px' }} />

      <h6 style={{
        margin: '0 0 8px',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#003750'
      }}>
        “{deletedBoardName}” board has been deleted
      </h6>
      <Box sx={{
        textAlign: 'center',
        width: '100%'
      }}>
        <span style={{
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '19px',
          color: '#003750',

        }}>
          We’ve successfully deleted your board. If anyone else tries to access it, they will get an error.
        </span>
      </Box>
      <Box>
        <BTButton data-testid="back-to-workspace-button" sx={{ margin: '20px auto' }} onClick={() => {
          dispatch(taskActions.clearDeletedBoardName())
          router.navigate('/tasks')
        }}>Back to boards</BTButton>
      </Box>
    </Stack>)
  );
}
