import { FormHelperText } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { mainSliceActions } from "@reducers/MainReducer";
import { clearAuthError } from "@reducers/UserReducer";
import { useAuthenticateMutation } from "@reducers/backend-api/backend-api";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCallback, useEffect, useState } from "react";
import { theme } from "../../../theme";

import BTButton from "@components/bt-button/bt-button";
import { useRouter } from "@reducers/hooks/use-router";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";
import ContinueWithDevider from "../continue-with-devider";
import FormHeader from "../form-header/header";
import Input from "../form-input/form-input";
import FormPatial from "../form-patial";
import GoogleLoginWrapper from "../google-auth-button/google-login-wrapper";
import classes from "./login-form.module.scss";
import { isExtensionContext } from "@services/detect-context";

export const LoginForm = () => {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch();
  const analyticsService = useAnalyticsService();
  const [authData, setAuthData] = useState({ email: '', password: '' });
  const [showValidation, setShowValidation] = useState(false);
  const [authenticate, { isLoading }] = useAuthenticateMutation();
  const authError = useAppSelector(state => state.UserReducer.authError);
  const impersonate = new URLSearchParams(window.location.search).get('impersonate');
  const errorFormColor = '#f72371';
  const router = useRouter();

  const handleInputChange = ({ target }) => {
    setAuthData(prev => ({ ...prev, [target.name]: target.value }));
    dispatch(clearAuthError());
  };

  const switchFormState = target => {
    const eventMap = {
      RESET_PASSWORD: "switch_to_forgot_password",
      SIGNUP: "switch_to_signup"
    };
    if (eventMap[target]) {
      analyticsService.event(eventMap[target], {
        category: "access",
        action: `switch to ${target.toLowerCase().replace('_', ' ')}`
      });
      dispatch(mainSliceActions.setFormState(target));

      if (!isExtensionContext()) {
        const currentUrlParams = new URLSearchParams(window.location.search);
        const paramsString = currentUrlParams.toString();
        const newPath = `/signup${paramsString ? `?${paramsString}` : ''}`;
        router.navigate(newPath);
      }
    }
  };

  const login = useCallback(async () => {
    if (!authData.email || !authData.password) {
      setShowValidation(true);
      return;
    }
    setShowValidation(false);
    try {
      await authenticate({ strategy: "local", ...authData, impersonate }).unwrap();
    } catch (error) {
      console.log(error);
    }
  }, [authData, authenticate, impersonate]);

  useEffect(() => {
    document.title = t('loginForm.pageTitle');
    const userExists = new URLSearchParams(window.location.search).get('hasUser');
    let userEmail = new URLSearchParams(window.location.search).get('email');

    if (userExists === '2') {
      setTimeout(() => {
        switchFormState("SIGNUP");
      }, 0);


    } else if (userExists === '1' && userEmail) {
      userEmail = userEmail.replace(/ /g, '+');
      setAuthData(prev => ({ ...prev, email: userEmail }));
    }
  }, [t]);

  const inputStyle = (key) => ({
    borderColor: showValidation && !authData[key] ? errorFormColor : '',
    marginBottom: key === "email" ? 5 : undefined,
    fontSize: '16px',
  });

  return (
    <FormPatial imagePath="/img/login-illustrator.png">
      <Box className={classes.FormContainer}>
        <FormHeader screenTitle={t('loginForm.header')} />
        <form className={classes.loginForm} onKeyDown={e => e.key === "Enter" && login()}>
          <Stack>
            {['email', 'password'].map((key, index) => (
              <Input
                key={`${key}-key`}
                value={authData[key]}
                data-testid={`login-${key}-input`}
                onChange={handleInputChange}
                name={key}
                style={inputStyle(key)}
                type={key}
                label={t(`loginForm.${key}Label`)} // Translation for label
                errorMessage={showValidation && !authData[key] ? t(`loginForm.${key}Required`) : ''}
                placeholder={t(`loginForm.${key}Placeholder`)} // Translation for placeholder
              />
            ))}
          </Stack>
          {authError && <FormHelperText error>{authError}</FormHelperText>}
          <Box className={classes.FormFooter}>
            <Box
              className={classes.FormLabelText}
              style={{ margin: '16px 0', cursor: 'pointer', color: theme.palette.primary.main }}
              onClick={() => switchFormState("RESET_PASSWORD")}>
              {t('loginForm.forgotPassword')}
            </Box>
            <BTButton
              data-testid="login-sign-in-button"
              loading={isLoading}
              sx={{ width: 1, padding: '15px 0' }}
              onClick={login}
            >
              {t('loginForm.loginButton')}
            </BTButton>
          </Box>
        </form>
        <Box>
          <ContinueWithDevider label={t('loginForm.orContinueWith')} />
          <Stack className={classes.provider_wrapper} direction="row" justifyContent="center">
            <GoogleLoginWrapper />
          </Stack>
          <Box
            className={classes.FormLabelText}
            style={{ marginTop: 16, cursor: "pointer" }}
            onClick={() => switchFormState("SIGNUP")}>
            {t('loginForm.dontHaveAccount')} <span className={classes.ButtonLink}>{t('loginForm.signUp')}</span>
          </Box>
        </Box>
      </Box>
    </FormPatial>
  );
};