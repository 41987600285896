import { WhatsappMessage } from '@components/whatsapp-message';
import { Stack } from '@mui/material';
import { useAppSelector } from '@store/hooks';
import { useEffect, useMemo, useRef } from 'react';
import sift from 'sift';

export default function ChatMessages() {
    const containerRef = useRef<HTMLDivElement>();
    const currentChat = useAppSelector(state => state.WhatsAppReducer.currentChat);

    const messageEntities = useAppSelector((state) => state.DBUserMessageReducer.entities)

    const messageList = useMemo(() => Object.keys(messageEntities)
        .map(id => messageEntities[id])
        .filter(sift({
            $or: [
                { deleted: { $exists: false } },
                { deleted: false },
            ],
            status: "pending",
            contactList: { $elemMatch: { id: currentChat?.id?._serialized } }
        })), [currentChat?.id?._serialized, messageEntities])

    useEffect(() => {
        setTimeout(() => {
            containerRef.current?.parentElement.scrollIntoView();
        })
    }, [messageList]);

    return (
        <Stack ref={containerRef} spacing={1}>
            {messageList.map((message, index) => <WhatsappMessage key={index} scheduledMessage={message} />)}
        </Stack>
    )
}