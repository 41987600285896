import { Task } from "@models/task";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/index";

export interface Interaction {
    bulkTasksMap: Record<string, Task>;
    anchorId?: string;
}
export interface BulkActionState {
    bulkTasksMap: Record<string, Task>;
    isBulkActionsDialogOpen: boolean;
    isExportTaskModalOpen: boolean
    move_to: string;
    isMoveTo: boolean;
    bulkActionMoveToId: string,
    groupIsMoving: boolean,
    anchorId?: string;
}
const initialState: BulkActionState = {
    bulkTasksMap: {},
    isBulkActionsDialogOpen: false,
    isExportTaskModalOpen: false,
    move_to: "",
    isMoveTo: false,
    bulkActionMoveToId: "",
    groupIsMoving: false
}
const BulkActions = createSlice({
    name: "BulkActions",
    initialState,
    reducers: {
        setBulkTasksMap(state, action: PayloadAction<Record<string, Task>>) {
            state.bulkTasksMap = action.payload
        },
        setIsBulkActionsDialogOpen(state, action: PayloadAction<boolean>) {
            state.isBulkActionsDialogOpen = action.payload;
        },
        setLastCheckedId(state, action: PayloadAction<string | undefined>) {
            state.anchorId = action.payload;
        },
        setLastInteraction(state, action: PayloadAction<Interaction>) {
            state.bulkTasksMap = action.payload.bulkTasksMap;
            state.anchorId = action.payload.anchorId;
        },
        toggleExportTaskModalOpen(state, action: PayloadAction<boolean>) {
            state.isExportTaskModalOpen = action.payload
        },
        setMovingTo(state, { payload }: PayloadAction<string>) {
            state.move_to = payload
        },
        setGroupIsMoving(state, { payload }: PayloadAction<boolean>) {
            state.groupIsMoving = payload
            console.log(payload)
        },
        toggleMoveTo(state, { payload }: PayloadAction<boolean>) {
            state.isMoveTo = payload
        },
        setBulActionsMoveToId(state, { payload }: PayloadAction<string>) {
            state.bulkActionMoveToId = payload
        }
    }
})

export const getBulkTaskList = createSelector(
    (state: RootState) => state.BulkActionsReducer,
    (state): Task[] => {
        return Object.keys(state.bulkTasksMap).map(key => state.bulkTasksMap[key]);
    }
);



export const hasBulkTasks = createSelector(
    [
        (state: RootState) => state.BulkActionsReducer,
        getBulkTaskList
    ],
    (state, taskList): boolean => {
        return taskList?.length > 0
    }
);


export const bulkActions = BulkActions.actions;
export default BulkActions.reducer;
