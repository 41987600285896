import { AccountPage } from "@components/account-page/account-page";
import BTThemeProvider from "@components/bt-theme-provider";
import { SideBarItem } from "@components/sidebar/sidebar";

import { useEffect } from "react";

import WorkspaceUsersMembers from "@components/account-users-table/workspace-users-members";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";
import { UserManagementHeader } from "./user-management-header";

export interface UserManagementViewProps {
  readOnly?: boolean;
}
export default function UserManagementView({readOnly}:UserManagementViewProps) {
  const { t } = useTranslation(namespace);  
  useEffect(() => {
    document.title = t('userManagementView.title');
  }, []);

  const userManagement = () => {
    return (
      <>
        <div
          style={{
            background: 'var(--background-default)',
            color: 'var(--primary)',
            padding: '0 40px',
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            boxShadow: "0 0 6px 0 rgb(0 55 80 / 10%)",
            position: "relative"
          }}>
          <h4
            style={{
              fontSize: '32px',
              fontWeight: '800',
              lineHeight: '48px',
              letterSpacing: '0em',
              margin: '0',
            }}>{t('userManagementView.members')}</h4>
        </div>

        <div
          style={{
            boxSizing: 'border-box',
            padding: '32px 40px',
            backgroundColor: 'var(--secondary-background-color)',
            margin: '0',
            overflowY: 'auto'
          }}>
          <UserManagementHeader readOnly={readOnly} />
          <WorkspaceUsersMembers readOnly={readOnly} />
        </div>
    
      </ >
    );
  };


  return (
    <BTThemeProvider>
      <AccountPage
        page={userManagement()}
        active={SideBarItem.UserManagement}
        readOnly={readOnly}
      ></AccountPage>
    </BTThemeProvider>
  );
};
