import { Button } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { automation } from "@reducers/AutomationReducer";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import React, { useCallback, useEffect, useState } from "react";
import { BiCommentDetail, BiCommentEdit } from "react-icons/bi";
import { BsColumns } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import { ImMoveUp } from "react-icons/im";
import { MdAssignmentInd } from "react-icons/md";
import { RiCalendarCheckLine } from "react-icons/ri";
import { TbCalendarTime } from "react-icons/tb";
import CustomDropdown from "./automation-dropdown";
import { errorStyle, buttonStyle } from "./automations-helper";
import { keyframes } from "@mui/system";




const bounceAnimation = keyframes`
0%, 100% { transform: translateY(0); }
50% { transform: translateY(-5px); }
`;

const AutmationTrigger = () => {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [text, setTriggerInitialText] = useState('When this happens');
    const [isTouched, setIsTouched] = useState(false);
    const [bounce, setBounce] = useState(false);
    const [isHover, setHoverState] = useState<boolean>(false);
    const hoverStyle = isHover ? { color: "#0072ff" } : {}
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const triggerOptions = [
        {
            label: 'status changes',
            name: 'status-option-picker',
            icon: <FiAlertTriangle fontSize="small" />
        },
        {
            label: 'task name changes',
            name: "task-name-changes",
            icon: <BiCommentEdit fontSize="small" />
        },
        {
            label: 'person assigned',
            name: "person-picker",
            icon: <MdAssignmentInd fontSize="small" />
        },
        {
            label: 'column changes',
            name: "column-change",
            icon: <BsColumns fontSize="small" />
        },
        {
            label: 'update created',
            name: "task-extra-data-update",
            icon: <BiCommentDetail fontSize="small" />
        },
        {
            label: 'task created',
            name: "task-created",
            icon: <FaTasks fontSize="small" />
        },
        {
            label: 'task moved to group',
            name: "task-moved-to-group",
            icon: <ImMoveUp fontSize="small" />
        },
        {
            label: 'task moved to board',
            name: "task-moved-to-board",
            icon: <ImMoveUp fontSize="small" />
        },
        {
            label: 'date passed',
            name: "date-passed",
            icon: <TbCalendarTime fontSize="small" />
        },
        {
            label: 'date arrives',
            name: "date-arrives",
            icon: <RiCalendarCheckLine fontSize="small" />
        },
        {
            label: 'every time period',
            name: "every-time-period",
            icon: <RiCalendarCheckLine fontSize="small" />
        },
    ]
    useEffect(() => {
        let bounceTimer;
        let interval;
        if (!isTouched) {
            setBounce(true);
            bounceTimer = setTimeout(() => setBounce(false), 2000);
            interval = setInterval(() => {
                setBounce(true);
                clearTimeout(bounceTimer);
                bounceTimer = setTimeout(() => setBounce(false), 2000);
            }, 10000);
        }
        return () => {
            clearInterval(interval);
            clearTimeout(bounceTimer);
        };
    }, [isTouched]);

    const openTriggerDropdown = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

    }, [setAnchorEl]);

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleOptionSelect = (option) => {
        setIsTouched(true);
        dispatch(automation.setTrigger({ triggerType: option.name }));
        dispatch(automation.setAttemptedSumbission(false));
        handleClose()
    };

    const onHover = (option, show) => {
        if (show) {
            setTriggerInitialText("When" + " " + option.label);
            setHoverState(true);
        } else {
            setTriggerInitialText('When this happens');
            setHoverState(false);
        }
    };
    return (
        <Stack key={0}>
            <Box>
                <Button sx={{
                    ...buttonStyle,
                    ...hoverStyle,
                    ...(attemptedSubmission ? errorStyle : {}),
                    animation: bounce && !anchorEl ? `${bounceAnimation} 4s ease infinite` : 'none',
                }} onClick={openTriggerDropdown}>{text}</Button>
            </Box>
            <CustomDropdown
                options={triggerOptions}
                anchorEl={anchorEl}
                onClose={handleClose}
                onOptionSelect={handleOptionSelect}
                onHoverOption={onHover}
            />
        </Stack>

    );
};

export default AutmationTrigger;
