import { getCreditCardLogo } from "@components/payment-form/payment-form";
import { userDisplayName } from "@models/user";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import getSymbolFromCurrency from "currency-symbol-map";
import { PaymentFormState } from "./PaymentFormReducer";
import { activeSubscriptionSelector, selectUserObject } from "./UserSelectors";

export const currencySymbolSelector = createSelector([
    (state: RootState) => state.PaymentFormReducer.estimate
], (estimate) => {
    const currencyCode = estimate?.subscription_estimate?.currency_code;
    return currencyCode ? getSymbolFromCurrency(currencyCode) : '$';
})

const creditCardReadySelector = createSelector(
    [
        (state: RootState) => state.PaymentFormReducer,
        (state: RootState) => selectBillingInfo(state),
    ],
    (PaymentFormState: PaymentFormState, billingInfo: BillingInfo) => {
        const {
            paymentsFieldsReady,
        } = PaymentFormState;
        return paymentsFieldsReady || billingInfo.creditCard
    }
)

export const isEnabledSelector = createSelector([
    (state: RootState) => state.PaymentFormReducer,
    (state: RootState) => creditCardReadySelector(state),
], (state: PaymentFormState, creditCardReady) => {
    const {
        paymentMethod,
        firstName,
        lastName,
        address,
        country,
        calculateCartIsLoading,
    } = state;

    return ((paymentMethod === 'CreditCard' && creditCardReady) || paymentMethod === 'Paypal') &&
        firstName &&
        lastName &&
        address &&
        country &&
        !calculateCartIsLoading;
})

export const tooltipListSelector = createSelector([
    (state: RootState) => state.PaymentFormReducer.firstName,
    (state: RootState) => state.PaymentFormReducer.lastName,
    (state: RootState) => state.PaymentFormReducer.address,
    (state: RootState) => state.PaymentFormReducer.country,
],
    (firstName, lastName, address, country) => {
        let tooltipList = [];
        const requiredFields = [
            { val: firstName, fieldName: 'First Name' },
            { val: lastName, fieldName: 'Last Name' },
            { val: address, fieldName: 'Address' },
            { val: country, fieldName: 'Country' },
        ]
        for (const requiredField of requiredFields) {
            if (!requiredField.val) {
                tooltipList.push(`Field '${requiredField.fieldName}' is required`)
            }
        }
        return tooltipList;
    })

export const showLoaderSelector = createSelector(
    [
        (state: RootState) => state.PaymentFormReducer.calculateCartIsLoading,
    ],
    (calculateCartIsLoading) => {
        return calculateCartIsLoading;
    }
);

const calcPaymentBox = (estimate: Estimate) => {
    const item_price = estimate?.item_price;
    const yearly_item_price = estimate?.yearly_item_price;

    const [initialTier] = item_price?.tiers ?? [];
    const [yearlyInitialTier] = yearly_item_price?.tiers ?? [];

    const [lineItem] = estimate?.invoice_estimate?.line_items ?? estimate?.next_invoice_estimate?.line_items ?? [];
    const seatCount = lineItem?.quantity;

    const amount_due = estimate?.invoice_estimate?.amount_due ?? estimate?.next_invoice_estimate?.amount_due ?? 0;

    // const basePrice = ((yearlyInitialTier ?? initialTier)?.price ?? 0) / 100 * seatCount;
    const basePrice = estimate?.invoice_estimate?.total / 100;
    let actualBasePRice = (initialTier?.price ?? 0) / 100 * seatCount;

    let yearlyDiscountAmount = 0;
    let yearlyDiscountPercentages = 0;
    if (yearly_item_price) {
        yearlyDiscountAmount = basePrice - actualBasePRice;
        yearlyDiscountPercentages = Math.round((yearlyDiscountAmount / basePrice) * 100);
    }

    const seatDiscountAmount = basePrice - estimate?.invoice_estimate?.sub_total;
    const seatDiscountPercentages = Math.round((seatDiscountAmount / basePrice) * 100);

    const totalCredit = (estimate?.credit_note_estimates ?? []).reduce((totalCredit, creditNote) => totalCredit + (creditNote.amount_available / 100), 0)
    const paymentBox = {
        basePrice,
        totalCredit,
        yearlyDiscountAmount,
        yearlyDiscountPercentages,
        seatDiscountAmount,
        seatDiscountPercentages,
        seatCount,
        amount_due
    }
    return paymentBox
};

export const selectPaymentBoxData = createSelector(
    [
        (state: RootState) => state.PaymentFormReducer.estimate,
    ],
    (estimate) => {
        return calcPaymentBox(estimate);
    }
);

export const selectAmountDue = createSelector(
    [
        (state: RootState) => state.PaymentFormReducer.estimate,
    ],
    (estimate) => {
        const paymentboxData = calcPaymentBox(estimate);
        return paymentboxData.amount_due;
    }
);


export const getIsVATIDValid = createSelector(
    [
        (state: RootState) => state,
    ],
    (state) => {
        const vatId = state.PaymentFormReducer.vatId;
        ///  return false if vatId is an 8-15 digit number
        const isCorrectLength = vatId?.length >= 8 && vatId?.length <= 15 && !isNaN(Number(vatId));
        const isVatId = !!vatId
        return isCorrectLength || !isVatId;
    }
);

interface BillingInfo {
    creditCard?: Card;
    paypal?: PayPal;
    cardType?: string;
    creditCardLogo?: string;
    last4digits?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    address1?: string;
    address2?: string;
    zip?: string;
    countryCode?: string;
    countryObject?: { isoCode: string; name: string };
    companyName?: string;
    vatId?: string;
}

export const staticSelectBillingInfo = (user, workspaceSubscription, shopper, customer: Customer, paymentSourceList: PaymentSource[]): BillingInfo => {
    let billingInfo: BillingInfo = {};

    const paymentMethod = paymentSourceList.find(source => source.reference_id === customer?.payment_method?.reference_id);
    const creditCard = paymentMethod?.card;
    billingInfo.creditCard = creditCard;
    billingInfo.cardType = creditCard?.brand;
    billingInfo.last4digits = creditCard?.last4;
    billingInfo.paypal = paymentMethod?.paypal;


    billingInfo.address1 = creditCard?.billing_addr1;
    billingInfo.address2 = creditCard?.billing_addr2;
    billingInfo.zip = creditCard?.billing_zip;
    billingInfo.countryCode = creditCard?.billing_country;

    if (workspaceSubscription?.type === 'bluesnap') {
        const creditCard = shopper?.subscription?.['credit-card'];
        billingInfo.creditCard = creditCard;
        billingInfo.cardType = creditCard?.['card-type'] ?? (shopper?.subscription?.['paypal-subscription'] ? 'PayPal' : undefined);
        billingInfo.last4digits = creditCard?.['last-four-digits'];

        const shopperContactInfo = shopper?.['shopper-info']?.['shopper-contact-info'];
        billingInfo.firstName = shopperContactInfo?.['first-name'];
        billingInfo.lastName = shopperContactInfo?.['last-name'];
        billingInfo.address1 = shopperContactInfo?.address1;
        billingInfo.address2 = shopperContactInfo?.address2;
        billingInfo.zip = shopperContactInfo?.zip?.toString();
        billingInfo.countryCode = user?.invoiceDetails?.country || shopperContactInfo?.country;
        billingInfo.companyName = shopperContactInfo?.['company-name'];
        billingInfo.vatId = user?.invoiceDetails?.vatId;
    } else if (workspaceSubscription?.type === 'chargebee') {
        const billing_address = customer?.billing_address;
        billingInfo.firstName = billing_address?.first_name || customer?.first_name;
        billingInfo.lastName = billing_address?.last_name || customer?.last_name;
        billingInfo.fullName = [billingInfo.firstName, billingInfo.lastName].filter(x => x).join(' ') || userDisplayName(user);
        billingInfo.address1 = billing_address?.line1;
        billingInfo.address2 = billing_address?.line2;
        billingInfo.zip = billing_address?.zip
        billingInfo.countryCode = billing_address?.country;
        billingInfo.companyName = billing_address?.company || customer?.company;
        billingInfo.vatId = customer?.vat_number;
    }

    billingInfo.creditCardLogo = billingInfo.cardType ? getCreditCardLogo(billingInfo.cardType) : undefined;
    billingInfo.countryObject = billingInfo.countryCode ? { isoCode: billingInfo.countryCode, name: '' } : undefined;

    return billingInfo;
}

export const selectBillingInfo = createSelector(
    [
        (state: RootState) => selectUserObject(state),
        (state: RootState) => activeSubscriptionSelector(state),
        (state: RootState) => state.PaymentFormReducer.shopper,
        (state: RootState) => state.PaymentFormReducer.customer,
        (state: RootState) => state.PaymentFormReducer.paymentSourceList,
    ],
    staticSelectBillingInfo
);