import BTButton from '@components/ws-button/ws-button'
import { Box, Stack } from '@mui/system'
import forbiddenAccessViewBackground from '../../../assets/forbidden-access-view-background.png'


export default function ForbiddenAccessView() {
    return (
        <Stack sx={{
            width: '100vw',
            height: '100vh',
            position: 'relative',
            backgroundImage: `url(${forbiddenAccessViewBackground})`,
            backgroundposition: 'center',
            backgroundSize: 'cover',
        }} direction='row' justifyContent='center' alignItems='center'>
            <Stack sx={{
                width: 585,
            }} justifyContent='center' alignItems='center'>
                <h1 style={{
                    color: '#013750',
                    fontSize: '32px',
                    fontWeight: '700',
                    lineHeight: '48px',
                    fontStyle: 'normal',
                    textAlign: 'center',
                }}>
                    You don't have access to this board
                </h1>
                <Box sx={{
                    width: 455,
                }}>
                    <h1 style={{
                        color: '#013750',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        fontStyle: 'normal',
                        textAlign: 'center',
                    }}>
                        Blueticks boards are private by default - if someone sent you the link to this board, ask them to invite you, or ask to join and wait to be approved.
                    </h1>
                </Box>
                <BTButton type="button" variant="contained" sx={{
                    my: 1
                }}>
                    Request to join as mazuznoam.4885@hotmail.com
                </BTButton>
                <BTButton type="button" variant="text">
                    {'Switch account >'}
                </BTButton>
            </Stack>
        </Stack>
    )
}