import { KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Stack } from '@mui/system';
import { setActiveBulkView, setCampaignIdForLogsView } from '@reducers/CampaignReducer';
import { getIsCampaignCompleteAndAllLogsAreComplete, selectCampaignLogs } from '@reducers/CampaignSelectors';
import { DBMessageLogThunks, campaignsSelectOneObjectByQuery, messageLogSelectByQuery } from '@reducers/DBServiceReducers';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import BulkButton from './bulk-button';
import BulkSearchField from './bulk-search-field';
import CampaignStatusLabel from './campaign-status-label';
import CampaignTabHeader from './campaign-tab-header';
import LazyLoadingVirtuoso from './lazy-loading-virtuoso';
import LogDialogRow from './log-dialog-row';
import { RootState } from '@store/index';



export default function BulkLogsDialog() {
    const dispatch = useAppDispatch()
    const campaignIdForLogsView = useAppSelector((state) => state.CampaignReducer.campaignIdForLogsView)
    const campaign = useAppSelector((state) => campaignsSelectOneObjectByQuery(state.DBCampaignReducer, {
        _id: campaignIdForLogsView,
    }))
    const isCampaignCompleteAndAllLogsAreComplete = useAppSelector((state) => getIsCampaignCompleteAndAllLogsAreComplete(state, campaignIdForLogsView))


    const [searchTerm, setSearchTerm] = useState("");



    useEffect(() => {
        if (!isCampaignCompleteAndAllLogsAreComplete) {
            const interval = setInterval(() => {
                dispatch(DBMessageLogThunks.find({
                    campaignId: campaignIdForLogsView,
                    $sort: {
                        createdAt: -1
                    },
                    $limit: -1
                }))
            }, 8000);
            return () => clearInterval(interval);
        }
    }, [campaign.campaignStatus, campaignIdForLogsView, dispatch, isCampaignCompleteAndAllLogsAreComplete]);

    useEffect(() => {
        dispatch(DBMessageLogThunks.find({
            campaignId: campaignIdForLogsView,
            $sort: {
                createdAt: -1
            },
            $limit: -1
        }))
    }, []);

    return (
        <Stack sx={{ width: '100%', alignItems: 'center' }}>
            <div style={{ width: '700px' }}>
                <Box sx={{ marginTop: '10px', height: '99px' }}>
                    <BulkButton
                        onClick={() => {
                            dispatch(setCampaignIdForLogsView(undefined));
                            dispatch(setActiveBulkView("campaignManagementView"))
                        }}
                        buttonString="Back"
                        Icon={KeyboardArrowLeft}
                    />
                </Box>
                <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    {campaign?.campaignStatus && (
                        <CampaignStatusLabel label={campaign.campaignStatus} />
                    )}
                    <CampaignTabHeader sx={{ marginTop: "10px" }} title={'Campaign Logs'} subTitle='Track the progress of your campaign' />

                </Stack>
                <BulkSearchField
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    placeHolderString="Search logs..."
                />
                <Stack sx={{ border: '1px solid rgba(0, 0, 0, 0.05)', borderRadius: '12px', marginTop: '20px', }}>
                    <LazyLoadingVirtuoso
                        height={500}
                        rowHeightString='80px'
                        filterObj={{
                            // _id: { $in: filteredIdArray },
                            campaignId: campaignIdForLogsView,
                        }}
                        DBThunks={DBMessageLogThunks}
                        DBReducer={state => state.DBMessageLogReducer}
                        selectByQuery={messageLogSelectByQuery}
                        selector={(state: RootState) => selectCampaignLogs(state, campaignIdForLogsView)}
                        Component={LogDialogRow}
                    />
                </Stack>
            </div>
        </Stack>
    )
}