import { ContactInfo, contactMatches } from "@models/contact-info";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { Chat, WhatsappContact } from "../services/whatsapp";
import { contactSelectByQuery } from "./DBServiceReducers";

export type ThemeType = "light" | "dark";

export interface UserInfo {
  email: string;
  id: string;
}
export interface WhatsAppState {
  currentChat: Chat | null;
  contactList: WhatsappContact[];
  contactMap: { [key: string]: WhatsappContact };
  profilePicMap: Record<string, string>;
  chatList: Chat[];
  chatMap: { [key: string]: Chat };
  theme: ThemeType;
  errorState: boolean;
  completeDetailsClosed: boolean;
  userInfo?: UserInfo;
  invalidWANumber?: string;
  me?: WhatsappContact;
  initialized: boolean;
  assetMap: Record<string, File>,
  currentExtensionVersion: string,
  importedContactsArray: ContactInfo[];
  showWizard?: boolean;
  hoveredDate?: any;
}

const initialState: WhatsAppState = {
  theme: "light",
  currentChat: null,
  contactList: [],
  contactMap: {},
  profilePicMap: {},
  chatMap: {},
  chatList: [],
  errorState: false,
  completeDetailsClosed: false,
  initialized: false,
  assetMap: {},
  currentExtensionVersion: "",
  importedContactsArray: [],
  showWizard: false,
  hoveredDate: undefined,
};

const WhatsAppSlice = createSlice({
  name: "whatsApp",
  initialState,
  reducers: {
    setAsset: (state, action: PayloadAction<{ url: string, file: File }>) => {
      state.assetMap[action.payload.url] = action.payload.file;
    },

    privateSetCurrentChat(state, action: PayloadAction<Chat>) {
      console.log("privateSetCurrentChat");
      state.currentChat = action.payload;
    },
    setWAcontacts(state, action: PayloadAction<WhatsappContact[]>) {
      console.log("setWAcontacts");
      state.contactMap = action.payload.reduce((contactMap, contact) => {
        const contactId = contact?.id?._serialized ?? contact?._id;
        if (contactId) {
          contactMap[contactId] = contact;
        }
        return contactMap;
      }, {} as Record<string, WhatsappContact>);
      state.contactList = Object.keys(state.contactMap).map(
        (contactId) => state.contactMap[contactId]
      );
    },
    setProfilePic(state, action: PayloadAction<{ contactId: string, profilePic: string }>) {
      state.profilePicMap[action.payload.contactId] = action.payload.profilePic;
    },
    setWAchats(state, action: PayloadAction<Chat[]>) {
      console.log("setWAchats");
      state.chatMap = action.payload.reduce((chatMap, chat) => {
        const chatId = chat?.id?._serialized;
        if (chatId) {
          chatMap[chatId] = chat;
        }
        return chatMap;
      }, {} as Record<string, Chat>);
      state.chatList = Object.keys(state.chatMap).map(
        (chatId) => state.chatMap[chatId]
      );
    },
    setTheme(state, action: PayloadAction<ThemeType>) {
      state.theme = action.payload;
    },
    setErrorState(state, action: PayloadAction<boolean>) {
      state.errorState = action.payload;
    },
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload;
    },
    setInvalidWANumber: (state, action: PayloadAction<string>) => {
      state.invalidWANumber = action.payload;
    },
    setMe: (state, action: PayloadAction<WhatsappContact>) => {
      state.me = action.payload;
    },
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    setCurrentExtensionVersion(state, action: PayloadAction<string>) {
      state.currentExtensionVersion = action.payload
    },
    setImportedContactsArray(state, action: PayloadAction<any[]>) {
      state.importedContactsArray = action.payload
    },
    setShowWizard(state, action: PayloadAction<boolean>) {
      state.showWizard = action.payload
    },
    setHoveredDate: (state, action: PayloadAction<any>) => {
      state.hoveredDate = action.payload;
    },
  },
});

export const selectMergedContactMap = createSelector([
  (state: RootState) => state.WhatsAppReducer.contactList,
  (state: RootState): ContactInfo[] => contactSelectByQuery(state.DBContactReducer, {
    $or: [
      { deleted: { $exists: false } },
      { deleted: false }
    ],
  })
],
  (contactList: WhatsappContact[], importedContactList: ContactInfo[]): Record<string, ContactInfo> => {
    let _mergedContactMap: Record<string, ContactInfo> = {};
    for (const waContact of contactList) {
      if (waContact.id?._serialized && !waContact.id?._serialized.includes('@lid')) {
        _mergedContactMap[waContact.id._serialized] = {
          ...waContact,
          whatsappId: waContact.id._serialized
        };
      }
    }
    for (const contact of importedContactList) {
      if (contact.whatsappId) {
        _mergedContactMap[contact.whatsappId] = {
          ...(_mergedContactMap[contact.whatsappId] ?? {}),
          ...contact
        };
      }
    }
    return _mergedContactMap
  }
);

export const selectMergedContactMapWithNamesOnly = createSelector([
  (state: RootState) => state
], (state) => {
  const mergedContactMap = selectMergedContactMap(state);
  // create from mergedContactMap a new map of all the contacts that has the 'name' field not undefined
  // and the value is the contact itself
  const mergedContactMapWithNamesOnly = Object
    .keys(mergedContactMap)
    .filter(whatsappId => mergedContactMap[whatsappId].name && mergedContactMap[whatsappId].name !== '')
    .reduce((mergedContactMapWithNamesOnly, whatsappId) => {
      mergedContactMapWithNamesOnly[whatsappId] = mergedContactMap[whatsappId];
      return mergedContactMapWithNamesOnly;
    }, {} as Record<string, ContactInfo>);


  return mergedContactMapWithNamesOnly
});

export const selectMergedContactList = createSelector([
  (state: RootState) => selectMergedContactMap(state)
], (mergedContactMap: Record<string, ContactInfo>) => {
  return Object
    .keys(mergedContactMap)
    .map(whatsappId => mergedContactMap[whatsappId])
    .filter(contact => contact.name && contact.name !== '')
});

export const selectFilteredMergedContactList = createSelector([
  (state: RootState) => selectMergedContactMap(state),
  (state: RootState, filterValue: string) => filterValue,
], (mergedContactMap: Record<string, ContactInfo>, filterValue: string) => {
  return Object
    .keys(mergedContactMap)
    .map(whatsappId => mergedContactMap[whatsappId])
    .filter(contact => contactMatches(filterValue, contact));
});

export const selectedSelectedContactCount = createSelector([
  (state: RootState) => state.WhatsAppReducer.importedContactsArray,
], (importedContactsArray) => {
  return importedContactsArray.length;
});

export const selectFilteredContactList = createSelector([
  (state: RootState) => state,
  (state: RootState) => selectMergedContactList(state),
  (state: RootState) => state.ContactTableReducer.filterValue,
  (state: RootState) => state.ContactTableReducer.showSelected,
  (state: RootState) => state.CampaignReducer.contactListPanelState.selectedListId,
], (state: RootState, mergedContactList: ContactInfo[], filterValue: string, showSelected: boolean, selectedListId) => {
  const importedContactsArray = state.WhatsAppReducer.importedContactsArray;
  const selectedIdList = importedContactsArray.map(contact => contact.whatsappId);
  const filteredContactList = mergedContactList
    .filter((contact: ContactInfo) => {
      return (filterValue ? contactMatches(filterValue, contact) : true) &&
        (showSelected ? selectedIdList.indexOf(contact.id?._serialized ?? contact.whatsappId ?? '') > -1 : true)
    });

  return filteredContactList
});

export const {
  privateSetCurrentChat,
  setWAcontacts,
  setWAchats,
  // setContactImageRecord,
  setTheme,
  setErrorState,
  setUserInfo,
  setInvalidWANumber,
  setMe,
  setInitialized,
  setCurrentExtensionVersion,
  setImportedContactsArray,
  setShowWizard,
  setProfilePic,
  setHoveredDate
} = WhatsAppSlice.actions;
export const WhatsappActions = WhatsAppSlice.actions;

export default WhatsAppSlice.reducer;
