import BTButton from '@components/bt-button/bt-button'
import BTtextFieldWithLabel from '@components/bt-input-field-with-label/bt-input-field-with-label'
import { Divider, FormControl, FormControlLabel, FormLabel, Grid, Menu, Radio, RadioGroup, Stack } from '@mui/material'
import { addNewBoardThunk, setSelectedBoardId } from '@reducers/BoardsThunks'
import { bulkActions } from '@reducers/BulkActionsReducer'
import { modalActions } from '@reducers/ModalReducer'
import { taskActions } from '@reducers/TasksReducer'
import { useRouter } from '@reducers/hooks/use-router'
import { useAppDispatch } from '@store/hooks'
import EmojiPicker from 'emoji-picker-react'
import React, { useState } from 'react'

function NewBoardDialog() {
    const dispatch = useAppDispatch()
    const router = useRouter();
    const [selectedValue, setSelectedValue] = useState('default');
    const [isNewBoardLoading, setIsNewBoardLoading] = useState(false);
    const [emoji, setEmoji] = useState('😊');
    const [boardName, setBoardName] = useState<string>("");
    const [emojiAnchorEl, setEmojiAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(emojiAnchorEl);


    const setBoardIcon = (arg) => {
        setEmoji(arg.emoji);
        handleEmojiClose()
    }

    const handleEmojilClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEmojiAnchorEl(event.currentTarget);
    };
    const handleEmojiClose = () => {
        setEmojiAnchorEl(null);
    };
    const options = [
        'tasks', 'recruiting', 'employees', 'clients', 'leads', 'campaigns'];

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const createBoard = async () => {
        setIsNewBoardLoading(true);
        const res: any = await dispatch(addNewBoardThunk({ boardName: boardName, emoji: emoji, template: selectedValue }));

        const boardId = res.payload;
        setIsNewBoardLoading(false);
        if (boardId) {
            dispatch(setSelectedBoardId({ boardId }));
            dispatch(taskActions.setCurrentBoardView({
                type: 'board',
                id: boardId
            }
            ))
            dispatch(taskActions.setPersonFilter(""));
            dispatch(bulkActions.setBulkTasksMap({}));
            dispatch(taskActions.setViewMode('tasks'))
            dispatch(modalActions.setComponentToRender(''))
            router.navigate(`/tasks/?boardID=${boardId}`);
           
        }
     

    }
    return (
        <Stack px={6} py={2} spacing={2}>
            <h2 style={{ padding: 0, margin: 0 }}>Create Board</h2>
            <Stack direction="row">
                <span
                    style={{ cursor: "pointer", fontSize: "24px", marginInlineEnd: "5px" }}
                    onClick={handleEmojilClick}
                >
                    {emoji}
                </span>
                <Menu
                    anchorEl={emojiAnchorEl}
                    open={open}
                    onClose={handleEmojiClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div>
                        <EmojiPicker onEmojiClick={setBoardIcon} />
                    </div>
                </Menu>
                <BTtextFieldWithLabel
                    placeholder="Board Name"
                    name="firstName"
                    value={boardName}
                    autoFocus
                    onChange={(e) => setBoardName(e.target.value)}
                    sx={{
                        backgroundColor: 'var(--background-default)',
                    }}
                />
            </Stack>
            <Divider />
            <FormControl component="fieldset">
                <FormLabel component="legend"
                    sx={{ marginBottom: "16px" }}>
                    Select what you're managing in this board
                </FormLabel>

                <RadioGroup row
                    aria-label="options"
                    name="options"
                    value={selectedValue}
                    onChange={handleChange}
                >
                    <Grid sx={{ maxWidth: "550px" }} container >
                        {options.map((option, index) => (
                            <Grid item  xs={6} sm={4} key={option}>
                                <FormControlLabel sx={{ textTransform: "capitalize" }} value={option} control={<Radio />} label={option} />
                            </Grid>
                        ))}
                    </Grid>

                </RadioGroup>
            </FormControl>
            <Stack>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3} my={2}>
                    <BTButton onClick={() => dispatch(modalActions.setComponentToRender(''))} btvariant='text' sx={{ textTransform: "capitalize" }}>Cancel</BTButton>
                    <BTButton loading={isNewBoardLoading}
                        onClick={createBoard} >Create board</BTButton>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default NewBoardDialog