import { keyframes, styled } from "@mui/system";
import { columnTypeList } from "@components/tasks/columns-cells/column-cell-defs";
import { AutomationTrigger,conditions,actions } from "@models/automation";
import { TextField } from "@mui/material";


export function setCurrentAction(actions: actions[], index: number, additionalFields: any) {
  return [
    ...actions.slice(0, index),
    { ...actions[index], ...additionalFields },
    ...actions.slice(index + 1)
  ];
}

export function setCurrentCondition(conditions: conditions[], index: number, additionalFields: any) {
  return [
    ...conditions.slice(0, index),
    { ...conditions[index], ...additionalFields },
    ...conditions.slice(index + 1)
  ];
}

export function setCurrentTrigger(trigger: AutomationTrigger, additionalFields: any) {
  return { ...trigger, ...additionalFields };
}

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

export const errorStyle = { 
  animation: `${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both`,  
  borderBottom: '1px solid #cf0000' 
};

export const buttonStyle = { padding: 0, color: "lightgrey", textTransform: "inherit", borderBottom: "1px solid lightgray", borderRadius: 0, fontSize: "24px", marginLeft: '5px',fontFamily:"'Poppins','Assistant',sans-serif",fontWeight:400 }

export const buttonSelectedStyle = { color: `var( --primary)` };

export const getColumnsIconColor = (type: string) => {
  let iconBgColor = columnTypeList.find(ct => ct.type === type)?.iconAccent;
  return iconBgColor;
}

export const getColumnsIcon = (type: string) => {
  let icon = columnTypeList.find(ct => ct.type === type)?.icon;
  return icon ? icon({ color: "#fff", size: 13 }) : <></>;
}


export const StyledTextField = styled(TextField)({
  marginTop: 0,
  fontFamily: "'Poppins', 'Assistant', sans-serif",
  "& .MuiInputBase-root:before": {
      border: "none",
  },
  "&.MuiFormControl-root .MuiInput-root:hover:not(.Mui-disabled):before": {
      border: "none",
  },
  "& .MuiInputBase-root:after": {
      border: "none",
  },
  "&.MuiTextField-root .MuiInputLabel-root": {
      top: "-25px",
      color: "var(--primary, rgb(0, 55, 80))",
      opacity: 1,
      fontSize: "24px",
      fontFamily: "'Poppins', 'Assistant', sans-serif",
      left: "10px",
  },
  "&.MuiTextField-root .MuiInputLabel-root.Mui-focused": {
      display: "none"
  },
  "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      display: "none"
  },
  "& .MuiInputBase-root": {
      margin: 0,
      fontFamily: "'Poppins', 'Assistant', sans-serif",
  },
  "& .MuiInputBase-input": {
      fontSize: "24px",
      padding: 0,
      paddingLeft: "10px",
      fontFamily: "'Poppins', 'Assistant', sans-serif",
  },
  '& .MuiInputBase-input::placeholder': {
    opacity: 1,
    fontFamily: "'Poppins', 'Assistant', sans-serif",
    color:"lightgrey",
  },
});