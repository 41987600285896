import MessageEditor from '@components/message-editor/message-editor';
import { Template } from '@models/template';
import { Box } from '@mui/system';


type Props = {
    value?: Partial<Template>,
    onSaveNew?: (newTemplate: any) => Promise<void>;
    isPreview?: boolean;
    isDisplayRemoveAttachmentButttom?: boolean;
    isFromCampaign?: boolean;
    onChange: (value) => void;
}

export default function MessageTemplateEditor({
    value,
    onSaveNew,
    isPreview = false,
    isDisplayRemoveAttachmentButttom = true,
    onChange,
}: Props) {

    return (
        <Box sx={{ width: '100%', height: '100%', }}>
            <MessageEditor
                showTemplateButtons
                value={value}
                onSaveNew={onSaveNew}
                isPreview={isPreview}
                isDisplayRemoveAttachmentButttom={isDisplayRemoveAttachmentButttom}
                onChange={onChange}
                editorContext='scheduleCampaign'
            />
        </Box>
    )
}