
import { CircularProgress, Stack } from '@mui/material';
import { DBMyWorksThunks } from '@reducers/DBServiceReducers';
import { fetchMyWorkThunk } from '@reducers/MyWorkThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect } from 'react';
import MyWorkTaskList from '../my-work-task-list';
import MyWorkCustomizeDrawer from './my-work-customize-drawer';
import MyWorkHeader from './my-work-header';
import { bulkActions } from '@reducers/BulkActionsReducer';
import { pickerDialogActions } from '@reducers/PickerDialogReducer';


export default function MyWorkView() {
    const dispatch = useAppDispatch();

    const isLoading = useAppSelector(state => state.MyWorkReducer.myWorkIsLoading)

    useEffect(() => {
        dispatch(bulkActions.setBulkTasksMap({}));
        dispatch(pickerDialogActions.closePickerDialog());

        dispatch(DBMyWorksThunks.find({}))
        dispatch(fetchMyWorkThunk())

    }, [dispatch]);

    return (
        <Stack p={3} sx={{ width: "100%" }}>

            <MyWorkCustomizeDrawer />

            <Stack sx={{ width: "100%" }} direction={"column"}>
                <h2>My work</h2>
                {isLoading &&
                    <Stack sx={{ height: "70vh" }} justifyContent={"center"} alignItems={"center"}>
                        <CircularProgress />
                    </Stack>
                }


                {!isLoading &&
                    <>
                        <MyWorkHeader />
                        <MyWorkTaskList />
                    </>
                }
            </Stack>
        </Stack >
    );
}


