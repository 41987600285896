import { ReactComponent as WaGroupSVG } from '@assets/waGroup.svg';
import { ReactComponent as WaPersonSVG } from '@assets/waPerson.svg';
import PersonAvatar from '@components/tasks/person-avatar/person-avatar';
import { Box, SxProps, Theme, Tooltip } from '@mui/material';
import { getWAContactsDataThunk } from '@reducers/CalendarThunks';
import { selectIsGroup, selectProfilePic, selectWhatsappContactName } from '@reducers/WhatsAppSelectors';
import { getProfilePicUrlThunk } from '@reducers/WhatsappThunks';
import { isExtensionContext } from '@services/detect-context';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import { memo, useEffect } from 'react';

export interface ContactAvatarProps {
    contactId: string;
    size?: number;
    textSize?: number;
    sx?: SxProps<Theme>,
    shouldGetProfilePic?: boolean;
}

const ContactAvatar = ({
    contactId,
    size = 55,
    textSize = 16,
    sx,
    shouldGetProfilePic = true,
}: ContactAvatarProps) => {
    const contactName = useAppSelector((state: RootState) => selectWhatsappContactName(state, contactId))
    const profilePicUrl = useAppSelector((state: RootState) => selectProfilePic(state, contactId));
    const btProfilePicUrl = useAppSelector((state: RootState) => state.CalendarReducer.contactPicMap[contactId])
    const isGroup = useAppSelector((state: RootState) => selectIsGroup(contactId));
    const dispatch = useAppDispatch();

    useEffect(() => {
        // set timeout is to wait getting profilePicUrl from redux state - DO NOT DELETE
        setTimeout(() => {
            if (shouldGetProfilePic && profilePicUrl === undefined) {
                dispatch(getProfilePicUrlThunk(contactId));
            }
        }, 200);
    }, [contactId, dispatch, profilePicUrl, shouldGetProfilePic]);


    useEffect(() => {
        if (!isExtensionContext()) {
            dispatch(getWAContactsDataThunk({ waId: contactId }));
        }
    }, [])

    return (
        <Tooltip title={contactName}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...sx
            }}>
                {(profilePicUrl ?? btProfilePicUrl) &&
                    <PersonAvatar
                        textSize={textSize} size={size}
                        profileImg={profilePicUrl ?? btProfilePicUrl}
                        bgcolor='#DFE5E7'
                    />
                }
                {!(profilePicUrl ?? btProfilePicUrl) && isGroup &&
                    <WaGroupSVG height={size} width={size} />
                }
                {!(profilePicUrl ?? btProfilePicUrl) && !isGroup &&
                    <WaPersonSVG height={size} width={size} />
                }
            </Box>
        </Tooltip>
    );
};

export default memo(ContactAvatar);

