import { createSelector } from "@reduxjs/toolkit";
import {
  GenericState,
  _tasksExtraDataFilesSelectOneObjectByQuery,
  boardUserSelectByQuery,
  boardUserSelectOneFieldById,
  boardViewSelectByQuery,
  boardViewSelectOneFieldByQuery,
  boardViewSelectOneObjectByQuery,
  boardsSelectors,
  ganttSelectByQuery,
  ganttSelectOneFieldByQuery,
  kanbanSelectOneFieldByQuery,
  kanbanSelectOneObjectByQuery,
  taskColumnOptionSelectByQuery,
  taskColumnOptionSelectOneObjectById,
  taskColumnSelectByQuery,
  taskColumnSelectors,
  taskGroupsSelectByQuery,
  taskGroupsSelectByQueryOnlyMaxMin,
  taskGroupsSelectOneFieldById,
  taskGroupsSelectOneFieldByQuery,
  taskGroupsSelectOneObjectByQuery,
  taskgroupsSelectors,
  tasksExtraDataSelectByQuery,
  tasksSelectByQuery,
  tasksSelectByQueryOnlyLength,
  tasksSelectOneFieldById,
  tasksSelectOneObjectById,
  tasksSelectors,
  viewFiltersSelectByQuery,
  workloadSelectOneFieldByQuery
} from "./DBServiceReducers";

import { Task as GanttTask } from "@components/reactgantt";
import {
  CompareFunctionOptions,
  TaskColumnType,
} from "@components/tasks/columns-cells/cell-interface";
import { columnTypeList, columnTypeMap } from "@components/tasks/columns-cells/column-cell-defs";
import {
  myWorkStaticColumnList,
  staticColumnList,
} from "@components/tasks/helpers/columnList";
import { mergeGroupsAndTasks } from "@components/tasks/helpers/elements-factory/elementsFactory";
import { ContactInfo } from "@models/contact-info";
import { ExtraDataFile, ExtraDataItem } from "@models/ExtraDataItem";
import { Gantt } from "@models/gantt";
import { BoardRowType, CustomColumnData, Task } from "@models/task";
import { ColumnType, TaskColumn } from "@models/task-column";
import { TaskColumnOption } from "@models/task-column-option";
import { TasksGroup } from "@models/task-group";
import { User } from "@models/user";
import { RootState } from "@store/index";
import { NullableId } from "./backend-api/backend-api";
import {
  AugmentedBlueticksBoardUser,
  getCurrentWorkspaceUserList,
  getMockPersonList,
  getSelectedBoardCompleteUserList,
  getSelectedBoardId
} from "./BoardsSelectors";
import { BatteryObject } from "./TaskInterfaces";
import { isTaskMatchingFiltersSelector } from "./TasksFilterPanelThunks";
import { selectMergedContactList } from "./WhatsAppReducer";

export function sortTasksByCustomOrder(baseTasks, tasksOrder) {
  // Create a new array of tasks with updated order
  const newTasks = baseTasks.map((task) => ({
    ...task,
    order: tasksOrder[task._id],
  }));

  // Sort the tasks based on the order
  newTasks.sort((a, b) => a.order - b.order);

  return newTasks;
}


export const baseTasks = createSelector(
  // Pass only the necessary parts of the state to each selector.
  [
    getSelectedBoardId,
    state => state.DBTasksReducer,
    state => state.TasksReducer.tasksFilter,
    state => state.DBTaskgroupsReducer,
    state => getBoardView(state),
  ],
  (boardId, DBTasksReducer, tasksFilter, DBTaskgroupsReducer, boardView) => {
    // Now use the directly passed states or results from other selectors.
    let baseTasks = tasksSelectByQuery(
      DBTasksReducer,
      {
        boardId,
        ...tasksFilter,
        deleted: false,
        archived: { $ne: true },
        isTemplate: { $ne: false },
      },
      null,
      {
        order: 1,
      }
    );


    const boardGroups = taskGroupsSelectByQuery(DBTaskgroupsReducer, {
      boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    });

    const boardGroupIdSet = new Set(boardGroups.map((group) => group._id));
    baseTasks = baseTasks.filter(
      (task) => task.groupId && boardGroupIdSet.has(task.groupId)
    );

    if (boardView && boardView.tasksOrder) {
      baseTasks = sortTasksByCustomOrder(baseTasks, boardView.tasksOrder);
    }

    return baseTasks;
  }
);

export const selectBoardTaskCount = createSelector(
  [(state: RootState) => state.DBTasksReducer, (_, boardId: string) => boardId],
  (DBTasksReducer, boardId) => {
    const taskList = tasksSelectByQuery(
      DBTasksReducer,
      {
        boardId,
        deleted: false,
        archived: { $ne: true },
        isTemplate: { $ne: false },
      },
      null,
      {
        order: 1,
      }
    );

    return taskList.length
  }
);

const personFilterSelector = (state: RootState) => state.TasksReducer.personFilter;
const currentBoardViewSelector = (state: RootState) => state.TasksReducer.currentBoardView;
const boardViewSelector = createSelector(
  [getSelectedBoardId, currentBoardViewSelector, (state: RootState) => state.DBBoardViewReducer],
  (boardId, currentBoardView, reducer) => boardViewSelectOneObjectByQuery(reducer, { boardId: boardId, _id: currentBoardView.id })
);
const filterListSelector = createSelector(
  [boardViewSelector, (state: RootState) => state.DBViewFiltersReducer, getSelectedBoardId],
  (boardView, reducer, boardId) => {
    let filterList = viewFiltersSelectByQuery(reducer, {
      viewId: boardView?._id ?? boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }, null, { updatedAt: 1 });
    if (boardView?.filterView) {
      filterList = [...boardView.filterView];
    }
    return filterList;
  }
);

export const currentTasks = createSelector(
  [
    baseTasks,
    (state: RootState) => personFilterSelector(state),
    (state: RootState) => getSelectedBoardId(state),
    (state: RootState) => currentBoardViewSelector(state).id,
    (state: RootState) => columnListSelector(state, getSelectedBoardId(state)),
    (state: RootState) => filterListSelector(state),
    (state: RootState) => state.DBTaskColumnOptionReducer,
  ],
  (baseTasks, personFilter, boardId, currentBoardViewId, columnList, filterList, DBTaskColumnOptionReducer) => {
    let filteredTasks = baseTasks;
    let tasksFilteredList = [];

    if (filterList.length > 0) {
      tasksFilteredList = filteredTasks.filter(task =>
        isTaskMatchingFiltersSelector(boardId, task, filterList, columnList, DBTaskColumnOptionReducer)
      );
    }

    if (personFilter !== "") {
      const personPickerColumns = columnList.filter(({ type }) => type === "person-picker");
      const personPickerColumnsIds = personPickerColumns.map(({ _id }) => _id);
      if (personPickerColumnsIds.length > 0) {
        filteredTasks = isTaskAssignedToOneOfThoseUsers(filteredTasks, personFilter, personPickerColumnsIds);
      }
    }

    if (filterList.length > 0) {
      filteredTasks = filteredTasks.filter(task => tasksFilteredList.includes(task));
    }

    return filteredTasks;
  }
);


export const compareValues = (a, b, options: CompareFunctionOptions) => {
  let compareVal;
  try {
    if (typeof a === "string" && typeof b === "string") {
      compareVal =
        options.sortOrder === "asc" ? a?.localeCompare(b) : b?.localeCompare(a);
    } else {
      a = a || Infinity;
      b = b || Infinity;
      compareVal = options.sortOrder === "asc" ? a - b : b - a;
    }
  } catch (e) {
    console.error(e);
  }

  return compareVal ?? 0;
};

export const sortTasksOrderByColumn = (
  taskList: Task[],
  column: TaskColumn,
  options: CompareFunctionOptions
): Task[] => {
  const columnType: TaskColumnType = columnTypeList.find(
    (columnType) => columnType.type === column.type
  );
  const compareFunction = columnType?.compareFunction ?? compareValues;

  taskList.sort((taskA, taskB) => {
    const valueA = cellValueByTaskAndColumnId(taskA, column);
    const valueB = cellValueByTaskAndColumnId(taskB, column);
    return compareFunction(valueA, valueB, options);
  });
  return taskList;
};

export const isTaskAssignedToOneOfThoseUsers = (
  tasks,
  personsToFilterBy,
  personPickerColumnsIds
) => {
  let filteredTasksByPerson = [];
  for (let i = 0; i < tasks.length; i++) {
    // console.log(`Task loop number ${i}`);
    let result = [];
    const currentTask = tasks[i];
    result = searchForPersonMatchInColumns(
      currentTask,
      personPickerColumnsIds,
      personsToFilterBy
    );
    if (result.length > 0) {
      filteredTasksByPerson.push(currentTask);
    }
  }
  return filteredTasksByPerson;
};

export const isTaskEqualToOptionColumn = (
  tasks,
  columnId,
  columnOptionId
) => { };

export const findDuplicates = (selectedOptionsCloned) => {
  const duplicates = {};
  selectedOptionsCloned.forEach((obj) => {
    if (!duplicates[obj?._id]) {
      duplicates[obj?._id] = 0;
    }
    duplicates[obj?._id]++;
  });
  return duplicates;
};

export const selectColumnOptions = (
  state,
  { boardId, columnId, cellValue }
) => {
  const columnOptions = taskColumnOptionSelectByQuery(
    state.DBTaskColumnOptionReducer,
    {
      boardId,
      columnId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }
  );

  //return by cellvalue from columnoptions
  const columnOption = columnOptions.find((option) => option._id === cellValue);

  return columnOption;
};

export const searchForPersonMatchInColumns = (
  task,
  currentColumn,
  personsToFilterBy
) => {
  let tasksMatchedByPersonSelected = [];
  let personsSelectedUserIdList = [];

  //loop columnsList
  for (let i = 0; i < currentColumn.length; i++) {
    //console.log(`Column loop number ${i}`);
    const personsSelected = task?.customData?.[currentColumn[i]]?.value;
    if (personsSelected) {
      //loop column persons selected
      personsSelected.forEach(function (item) {
        personsSelectedUserIdList.push(item.id);
      });
    }
    const isPersonMatch = personsSelectedUserIdList
      ? personsSelectedUserIdList.includes(personsToFilterBy)
      : false;
    if (isPersonMatch) {
      tasksMatchedByPersonSelected.push(task);
      return tasksMatchedByPersonSelected;
    }
    if (i === currentColumn.length - 1) {
      return tasksMatchedByPersonSelected;
    }
  }
};

export const getColumnList = createSelector(
  (state) => state,
  (state: RootState) => {
    let columnsData = [];
    const currentBoardId = getSelectedBoardId(state);
    const taskColumnState: GenericState<TaskColumn> = state.DBTaskColumnReducer;
    const columnList = taskColumnState?.entities;
    columnsData = Object.values(columnList);
    const finalColumnList = columnsData.filter(
      (columnItem) => columnItem.boardId === currentBoardId
    );
    return finalColumnList;
  }
);

export const boardTaskSelector = createSelector(
  (state) => state,
  (state: RootState) => {
    return tasksSelectByQuery(state.DBTasksReducer, {
      boardId: getSelectedBoardId(state),
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    });
  }
);

export const createProjectItemByGroup = createSelector(
  [
    (state: RootState) => state,
    (_, taskList: any[], columnId) => ({ taskList, columnId }),
  ],
  (state: RootState, { taskList, columnId }) => {
    const listOfGroupTasks = [];
    const listOfProjects = [];
    let groupId;
    let groupIdForBattery;
    for (let i = 0; i < taskList.length; i++) {
      const task = taskList[i];
      const taskGroupId = task?.groupId;
      const filterTasksByGroupID = taskList.filter((task) => {
        groupIdForBattery = task.groupId;
        return task.groupId === taskGroupId;
      });

      if (filterTasksByGroupID[0]?.groupId !== groupId) {
        groupId = filterTasksByGroupID[0]?.groupId;
        listOfGroupTasks.push(filterTasksByGroupID);
      }
    }

    const battaryGroup = getBattaryProgressGanttGroupBar(
      state,
      groupIdForBattery
    );
    console.log(battaryGroup);

    for (let i = 0; i < listOfGroupTasks.length; i++) {
      const projectTasks = listOfGroupTasks[i];
      let groupId, groupName, groupColor;

      const maxDate = new Date(
        Math.max(
          ...projectTasks.map((task) => {
            return new Date(task?.customData[columnId]?.value?.endDate);
          })
        )
      );
      const minDate = new Date(
        Math.min(
          ...projectTasks.map((task) => {
            return new Date(task?.customData[columnId]?.value?.startDate);
          })
        )
      );

      projectTasks.forEach((task) => {
        groupColor = task?.groupColor;
        groupId = task?.groupId;
        groupName = taskGroupsSelectOneFieldByQuery(
          state.DBTaskgroupsReducer,
          { _id: groupId },
          "name"
        );
      });

      const projectObj: any = {
        start: new Date(minDate),
        end: new Date(maxDate),
        name: groupName,
        id: groupId,
        progress: 100,
        isDisable: false,
        type: "project",
        hideChildren: false,
        styles: {
          projectBackgroundColor: groupColor,
          projectBackgroundCriticalColor: groupColor,
          projectBackgroundSelectedColor: groupColor,
          // groupProgressColor:'linear-gradient(90deg, rgba(37,154,233,1) 1%, rgba(0,212,255,0) 1%)',
          // groupProgressCriticalColor:'red',
          // projectProgressColor: "red",
          // projectProgressCriticalColor: "linear-gradient(90deg, rgba(37,154,233,1) 20%, rgba(0,212,255,0) 1%);",
          // projectProgressSelectedColor: "linear-gradient(90deg, rgba(37,154,233,1) 20%, rgba(0,212,255,0) 1%)",
          // projectProgressSelectedCriticalColor: "linear-gradient(90deg, rgba(37,154,233,1) 20%, rgba(0,212,255,0) 1%)",
        },
      };
      const thisProjectExist = listOfProjects.find(
        (task) => projectObj.id === task.id
      );
      if (!thisProjectExist) {
        listOfProjects.push(projectObj);
      }
    }

    return listOfProjects;
  }
);

export const getGanttTaskItem = createSelector(
  [
    (state: RootState) => state,
    (_, taskId: any, columnId) => ({ taskId, columnId }),
  ],
  (state: RootState, { taskId, columnId }) => {
    const ganttTaskList = currentTasks(state);

    for (let i = 0; i < ganttTaskList.length; i++) {
      const task = ganttTaskList[i];
      if (taskId === task._id) {
        return task.customData[columnId]?.value;
      }
    }
  }
);

export const getGanttColumnValueItem = createSelector(
  [
    (state: RootState) => state,
    (_, picker: string, taskId: any) => ({ picker, taskId }),
  ],
  (state: RootState, { picker, taskId }) => {
    const boardId = getSelectedBoardId(state);
    const finalList = [];
    const columnList = columnListSelector(state, boardId);
    const allColumns = columnList.filter((column) => column.type === picker);

    allColumns.forEach((column) => {
      if (column.type === picker) {
        const ganttTask: any = getGanttTaskItem(state, taskId, column?._id);
        if (ganttTask) {
          finalList.push(...ganttTask);
        }
      }
    });

    return finalList;
  }
);

export const getFixedDependencyList = createSelector(
  [
    (state: RootState) => state,
    (_, ganttTaskList: any[]) => ({ ganttTaskList }),
  ],
  (state: RootState, { ganttTaskList }) => {
    const currentBoardTasks = currentTasks(state);
    function addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    }
    function minusDays(date, days) {
      date.setDate(date.getDate() - days);
      return date;
    }
    for (let i = 0; i < ganttTaskList.length; i++) {
      const task = ganttTaskList[i];
      if (
        task?.type === "task" &&
        Object.keys(task?.dependencies[0] ?? {}).length !== 0
      ) {
        const arrowEndTarget = task?.dependencies[0]?.ownTarget;
        const arrowStartTarget = task?.dependencies[0]?.sourceTarget;
        const taskSendArrowId = task?.dependencies[0]?.sourceId;
        const taskSendArrow = ganttTaskList.find((task) => {
          if (taskSendArrowId === task?.id) return task;
        });

        switch (arrowEndTarget) {
          case "startOfTask":
            if (arrowStartTarget === "startOfTask") {
              if (taskSendArrow.start.getTime() > task?.start.getTime()) {
                const days =
                  (taskSendArrow.start.getTime() - task?.start.getTime()) /
                  (1000 * 3600 * 24);
                task.start = addDays(task.start, days);
                task.end = addDays(task.end, days);
              }
            } else if (arrowStartTarget === "endOfTask") {
              if (taskSendArrow.end.getTime() > task?.start.getTime()) {
                const days =
                  (taskSendArrow.end.getTime() - task?.start.getTime()) /
                  (1000 * 3600 * 24);
                task.start = addDays(task.start, days + 1);
                task.end = addDays(task.end, days + 1);
              }
            }
            break;
          case "endOfTask":
            if (arrowStartTarget === "startOfTask") {
              if (taskSendArrow.start.getTime() >= task?.end.getTime()) {
                const days =
                  (taskSendArrow.start.getTime() - task?.end.getTime()) /
                  (1000 * 3600 * 24);
                task.start = addDays(task.start, days + 1);
                task.end = addDays(task.end, days + 1);
              }
            } else if (arrowStartTarget === "endOfTask") {
              if (taskSendArrow.end.getTime() >= task?.end.getTime()) {
                const days =
                  (taskSendArrow.end.getTime() - task?.end.getTime()) /
                  (1000 * 3600 * 24);
                task.start = addDays(task.start, days + 1);
                task.end = addDays(task.end, days + 1);
              }
            }

            break;
        }
      }
    }
  }
);

export const getBattaryProgressGanttGroupBar = createSelector(
  [(state: RootState) => state, (_, groupId: string) => ({ groupId })],
  (state: RootState, { groupId }) => {
    const boardId = getSelectedBoardId(state);

    const type = "status-option-picker";
    const columnList = columnListSelector(state, boardId).map(
      (column, index) =>
      ({
        type: column.type,
        _id: column._id,
        title: column.title,
      } as TaskColumn)
    );
    const columnRes = columnList.filter(
      (columnItem) => columnItem.type === type
    );
    const columnId = columnRes[0]?._id;
    const params = {
      groupId,
      columnId,
      boardId,
      columnType: "status-option-picker",
    };
    const batteryIndicator: BatteryObject[] = [];
    const values = getColumnValues(state, params);
    let percentageForTask;

    const getPercentage = (optionCount, totalTasks) => {
      const percentage = (optionCount / totalTasks) * 100;
      return percentage % 1 !== 0 ? percentage.toFixed(2) : percentage;
    };
    const selectedOptions = values?.selectedOptionsCloned?.filter(
      (obj, index, self) => {
        return index === self.findIndex((t) => t?._id === obj?._id);
      }
    );
    const sortedSelectedOptions = selectedOptions?.sort(
      (a, b) => a?.order - b?.order
    );
    let res = `linear-gradient(`;

    sortedSelectedOptions.map((column, index) => {
      // console.log(column)
      const columnColor = column?.bgcolor;

      percentageForTask = getPercentage(column?.count, values?.tasksLength);
      batteryIndicator.push({
        bgColor: columnColor,
        percentage: percentageForTask,
      });
    });
    // console.log(batteryIndicator)
    batteryIndicator.map((groupOfStatus) => {
      const itemGradient = `90deg, ${groupOfStatus.bgColor}, ${groupOfStatus.percentage},`;
      res += itemGradient;
    });
    res += " )";
    // console.log(res)
    return res;
  }
);

export const getGanttTasks = createSelector(
  [(state: RootState) => state, (_, columnId: NullableId) => columnId],
  (state: RootState, columnId) => {
    const boardId = getSelectedBoardId(state);
    const tasksList = getTasksWithValueInColumnIdSelector(state, columnId);
    const projectList = createProjectItemByGroup(state, tasksList, columnId);
    const ganttsListInBoard = ganttSelectByQuery(state.DBGanttReducer, {
      boardId: boardId,
    });
    const orderTasksBy = ganttSelectOneFieldByQuery(
      state.DBGanttReducer,
      { _id: ganttsListInBoard[0]._id },
      "orderTasksBy"
    );
    const milestoneTasks = ganttSelectOneFieldByQuery(
      state.DBGanttReducer,
      { _id: ganttsListInBoard[0]._id },
      "milestoneTasks"
    );
    const groupView = ganttSelectOneFieldByQuery(
      state.DBGanttReducer,
      { _id: ganttsListInBoard[0]._id },
      "showTasksByGroup"
    );

    const fixDependency = ganttSelectOneFieldByQuery(
      state.DBGanttReducer,
      { _id: ganttsListInBoard[0]._id },
      "fixDependency"
    );

    // const defaultColors: ColorStyles = {
    //   arrowColor: "grey",
    //   arrowCriticalColor: "#ff0000",
    //   arrowWarningColor: "#000",
    //   barProgressColor: "#000",
    //   barProgressCriticalColor: "#ff1919",
    //   barProgressSelectedColor: "#8282f5",
    //   barProgressSelectedCriticalColor: "#ff0000",
    //   barBackgroundColor: "#000",
    //   barBackgroundCriticalColor: "#ff6363",
    //   barBackgroundSelectedColor: "#aeb8c2",
    //   barBackgroundSelectedCriticalColor: "#ff8e8e",
    //   groupProgressColor: "#2dbb2e",
    //   groupProgressCriticalColor: "#2dbb2e",
    //   groupProgressSelectedColor: "#28a329",
    //   groupProgressSelectedCriticalColor: "#28a329",
    //   groupBackgroundColor: "#006bc1",
    //   groupBackgroundCriticalColor: "#006bc1",
    //   groupBackgroundSelectedColor: "#407fbf",
    //   groupBackgroundSelectedCriticalColor: "#407fbf",
    //   projectProgressColor: "#7db59a",
    //   projectProgressCriticalColor: "#7db59a",
    //   projectProgressSelectedColor: "#59a985",
    //   projectProgressSelectedCriticalColor: "#59a985",
    //   projectBackgroundColor: "#fac465",
    //   projectBackgroundCriticalColor: "#fac465",
    //   projectBackgroundSelectedColor: "#f7bb53",
    //   projectBackgroundSelectedCriticalColor: "#f7bb53",
    //   milestoneBackgroundColor: "#f1c453",
    //   milestoneBackgroundCriticalColor: "#ff8e8e",
    //   milestoneBackgroundSelectedColor: "#f29e4c",
    //   milestoneBackgroundSelectedCriticalColor: "#ff0000",
    //   evenTaskBackgroundColor: "#f5f5f5",
    //   holidayBackgroundColor: "rgba(233, 233, 233, 0.3)",
    //   selectedTaskBackgroundColor: "rgba(252, 248, 227, 0.5)",
    //   todayColor: "rgba(252, 248, 227, 0.5)",
    //   contextMenuBoxShadow: "rgb(0 0 0 / 25%) 1px 1px 5px 1px",
    //   contextMenuBgColor: "#fff",
    //   contextMenuTextColor: "inherit",
    // };
    const completeGanttList = [];
    const groupTasks: TasksGroup[] = taskgroupsSelectors.selectAll(state);
    const ganttTasks: GanttTask[] = [];
    const currentDate = new Date();
    for (let i = 0; i < tasksList.length; i++) {
      const ganttTask = tasksList[i];

      // Get ID value
      const ganttTaskId = tasksList[i]?._id;
      // Get Dependencies
      const dependenciesInTask = [];
      const ganttDependency = ganttsListInBoard[0]?.tasksDependencies;
      const dependencyKeys = ganttDependency
        ? Object.keys(ganttDependency)
        : {};
      if (Object.keys(dependencyKeys).length !== 0) {
        const addToDeps = ganttDependency[ganttTaskId];
        dependenciesInTask[0] = { ...addToDeps };
      }
      // GET Date value
      const cellValue = cellValueSelector(state, tasksList[i]?._id, columnId);
      let startDate = new Date(cellValue?.startDate) ?? new Date(currentDate);
      let endDate =
        new Date(cellValue?.endDate) ?? new Date(currentDate.getDate() + 1);

      // GET Progress value
      const progress = 0;
      // Get task name value
      const ganttTaskName = tasksList[i]?.text;
      // Get groupId value
      const groupId = groupTasks.find(
        (groupId) => groupId._id === ganttTask.groupId
      );
      // Get Color Value
      const groupColor = ganttTask?.groupColor;
      if (startDate >= endDate) {
        const helper = startDate;
        startDate = endDate;
        endDate = helper;
      }
      const x = milestoneTasks?.find((id) => ganttTaskId === id);

      const taskObj: GanttTask = {
        start: new Date(startDate),
        end: new Date(endDate),
        name: ganttTaskName,
        id: ganttTaskId,
        type: x ? "milestone" : "task",
        progress: progress,
        isDisabled: false,
        dependencies: dependenciesInTask,
        parent: groupId?._id,
        styles: {
          barBackgroundColor: groupColor,
          barBackgroundSelectedColor: groupColor,
        },
      };

      const thisTaskExist = ganttTasks.find((task) => taskObj.id === task.id);
      if (!thisTaskExist) {
        ganttTasks.push(taskObj);
      }
    }
    if (groupView) {
      completeGanttList.push(...ganttTasks, ...projectList);
    } else {
      completeGanttList.push(...ganttTasks);
    }
    let sortedGanttList = getSortedGanttTasksFromTasksOrder(
      state,
      completeGanttList,
      ganttsListInBoard[0]
    );

    if (fixDependency === true) {
      getFixedDependencyList(state, sortedGanttList);
    }
    if (orderTasksBy === "customOrder" || orderTasksBy === undefined) {
      sortedGanttList = getSortedGanttTasksFromTasksOrder(
        state,
        completeGanttList,
        ganttsListInBoard[0]
      );
    } else if (orderTasksBy === "startDate") {
      sortedGanttList = completeGanttList.sort(function (a, b) {
        return a.start - b.start;
      });
    } else if (orderTasksBy === "endDate") {
      sortedGanttList = completeGanttList.sort(function (a, b) {
        return b.end - a.end;
      });
    }

    return sortedGanttList;
  }
);

export const getTasksType = createSelector(
  [
    (state: RootState) => state,
    (_state, taskId: NullableId, gantt: Gantt) => ({ taskId, gantt }),
  ],
  (state: RootState, { taskId, gantt }) => {
    const milestoneTasks = gantt?.milestoneTasks;
    console.log(milestoneTasks);

    if (milestoneTasks?.find((taskIdFromList) => taskId === taskIdFromList)) {
      return "milestone";
    } else {
      return "task";
    }
  }
);

export const getSortedGanttTasksFromTasksOrder = createSelector(
  [
    (state: RootState) => state,
    (_state, ganttTaskList: any[], gantt: Gantt) => ({ ganttTaskList, gantt }),
  ],
  (state: RootState, { ganttTaskList, gantt }) => {
    const ganttTasksOrderList = gantt?.tasksOrder;
    let sortedList = [];
    if (ganttTasksOrderList?.length > 1) {
      for (const taskId in ganttTasksOrderList) {
        const taskOrderItemId = Object.keys(ganttTasksOrderList[taskId]);
        Object.values(ganttTasksOrderList[taskId]);
        const task = ganttTaskList.find(
          (task) => task.id === taskOrderItemId[0]
        );
        sortedList.push(task);
      }
      const listOfItemThatNotInTasksOrder = ganttTaskList.filter(
        (task) => !sortedList.includes(task)
      );
      sortedList.push(...listOfItemThatNotInTasksOrder);
    } else {
      sortedList = [...ganttTaskList];
    }
    return sortedList;
  }
);

export const currentGroups = createSelector(
  [(state) => state],
  (state: RootState) => {
    return taskGroupsSelectByQuery(
      state.DBTaskgroupsReducer,
      {
        boardId: getSelectedBoardId(state),
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
        archived: { $ne: true },
      },
      [
        "_id",
        "isCollapsed",
        "colorAccent",
        "order",
        "isFooterAutoFocused",
        "isSubFooterAutoFocused",
      ],
      {
        order: 1,
      }
    );
  }
);

export const selectElementsList = createSelector(
  [
    currentTasks,
    currentGroups,
    getSelectedBoardId,
    (state) => state.TasksReducer.currentBoardView,
    (state) => state.TasksReducer.isGroupDragging,
    (state) => state.TasksReducer.toggleTasksMap
  ],
  (currentTasks, currentGroups, selectedBoardId, currentBoardView, isGroupDragging, toggleTasksMap) => {
    let hideEmptyGroups = false;
    // const [boardView]: BoardView[] = boardViewSelectByQuery(
    //   state.DBBoardViewReducer,
    //   { boardId: selectedBoardId, _id: currentBoardView.id }
    // );

    // let viewFilterList = viewFiltersSelectByQuery(
    //   state.DBViewFiltersReducer,
    //   {
    //     viewId: boardView?._id ?? selectedBoardId,
    //     $or: [{ deleted: { $exists: false } }, { deleted: false }],
    //   },
    //   null,
    //   {
    //     updatedAt: 1,
    //   }
    // );

    // if (
    //   state.TasksReducer.tasksFilter.text ||
    //   state.TasksReducer.personFilter ||
    //   viewFilterList.length > 0
    // ) {
    //   hideEmptyGroups = true;
    // }

    const elementsList = mergeGroupsAndTasks(
      selectedBoardId,
      currentGroups,
      currentTasks,
      hideEmptyGroups,
      isGroupDragging,
      toggleTasksMap
    );
    return elementsList;
  }
);

export const cellValueByTaskAndColumnId = (task: Task, column: TaskColumn) => {
  const columnType: TaskColumnType = columnTypeList.find(
    (columnType) => columnType.type === column?.type
  );
  // Use a custom cell value function or the default custom data selector if it is not defined
  let value;
  if (columnType?.cellValueFunction) {
    value = columnType.cellValueFunction(task);
  } else {
    const customColumnData: CustomColumnData | undefined =
      task?.customData && task?.customData[column?._id];
    value = customColumnData?.value;
  }
  return value;
};

export const cellValueSelector = createSelector(
  [
    (state: RootState) => state,
    (state, taskId, columnId) => ({ taskId, columnId }),
  ],
  (state: RootState, { taskId, columnId }) => {
    const task = tasksSelectors.selectById(state, taskId);
    const column = taskColumnSelectors.selectById(state, columnId);

    // return columnType?.cellValueFunction ? columnType.cellValueFunction(task) : cellValueByTaskAndColumnId(task, column);
    return cellValueByTaskAndColumnId(task, column);
  }
);

export const getGroupListOnlyIdsAndNames = createSelector(
  [(state) => state, getSelectedBoardId],
  (state, boardId) => {
    const groupListOnlyIdsAndNames = taskGroupsSelectByQuery(
      state.DBTaskgroupsReducer,
      {
        boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      ["_id", "name"]
    );

    return groupListOnlyIdsAndNames;
  }
);

export const getColumnTypeById = createSelector(
  [
    getSelectedBoardId,
    (state, columnId) => state,
    (state, columnId) => columnId,
  ],
  (state, columnId) => {
    const columnList = getColumnList(state);
    const columnType = columnList.find((column) => columnId === column?._id);
    return columnType;
  }
);

export const columnListSelectorForKanban = createSelector(
  [
    getSelectedBoardId,
    (state, kanbanId) => state,
    (state, kanbanId) => kanbanId,
  ],
  (boardId, state, kanbanId) => {
    const includedColumns = kanbanSelectOneFieldByQuery(
      state.DBKanbanReducer,
      { _id: kanbanId },
      "includedColumns"
    );

    const dynamicColumnList = taskColumnSelectByQuery(
      state.DBTaskColumnReducer,
      {
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
        boardId,
      },
      ["_id", "type", "title"],
      {
        order: 1,
      }
    );

    let columnList;

    columnList = [...dynamicColumnList]
      .filter((column) => includedColumns && includedColumns[column?._id])
      .sort((colA, colB) => (colA?.order ?? 0) - (colB?.order ?? 0));

    return columnList.map(
      (column, index) =>
      ({
        _id: column?._id,
        type: column?.type,
        title: column?.title,
      } as TaskColumn)
    );
  }
);

export const getGroupIdForKanbanColumns = createSelector(
  [(state) => state, getSelectedBoardId],
  (state, boardId) => {
    const groupId = taskGroupsSelectOneFieldByQuery(
      state.DBTaskgroupsReducer,
      {
        boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      ["_id"],
      {
        order: -1,
      }
    );

    return groupId;
  }
);

export const filterTaskColumns = (state, boardId) =>
  taskColumnSelectByQuery(
    state.DBTaskColumnReducer,
    {
      $and: [
        { $or: [{ deleted: { $exists: false } }, { deleted: false }] },
        { $or: [{ isVisible: true }, { isVisible: { $exists: false } }] },
      ],
      boardId: boardId ?? "No Board", // if no board add a filter so no columns will be returned
    },
    [],
    {
      order: 1,
    }
  );

export const columnListSelectorForBoardView = createSelector(
  [getSelectedBoardId, (state, _) => state, (_, boardViewId) => boardViewId],
  (boardId, state, boardViewId) => {
    const includedColumns = boardViewSelectOneFieldByQuery(
      state.DBBoardViewReducer,
      { _id: boardViewId },
      "includedColumns"
    );

    const dynamicColumnList = taskColumnSelectByQuery(
      state.DBTaskColumnReducer,
      {
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
        boardId,
      },
      ["_id", "type", "title"],
      {
        order: 1,
      }
    );

    let columnList;
    columnList = [...dynamicColumnList]
      .filter((column) => includedColumns && includedColumns[column?._id])
      .sort((colA, colB) => (colA?.order ?? 0) - (colB?.order ?? 0));
    return columnList.map(
      (column, index) =>
      ({
        _id: column?._id,
        type: column?.type,
        title: column?.title,
      } as TaskColumn)
    );
  }
);

export const getBoardView = createSelector(
  [getSelectedBoardId, (state) => state],
  (_, state) => {
    const boardId = getSelectedBoardId(state);

    const currentBoardView = state.TasksReducer.currentBoardView;
    const [boardViewId] = boardViewSelectByQuery(state.DBBoardViewReducer, {
      boardId: boardId,
      _id: currentBoardView.id,
    });
    return boardViewId;
  }
);

export const columnListForDisplaySelector = createSelector(
  [(state) => state, filterTaskColumns, (_, __, groupId?) => groupId],
  (state, dynamicColumnList, groupId) => {
    let columnList;
    let viewMode = state.TasksReducer.viewMode;

    if (viewMode === "tasks") {
      const boardId = getSelectedBoardId(state);
      const boardType = boardUserSelectOneFieldById(state.DBBoardsReducer, boardId, "type");
      const isDisplayChat = boardType !== "audience";

      const currentBoardView = state.TasksReducer.currentBoardView;
      const boardViewId = boardViewSelectByQuery(state.DBBoardViewReducer, {
        boardId: boardId,
        _id: currentBoardView.id,
      });

      if (boardViewId.length > 0) {
        const includedBoardViewColumnList = columnListSelectorForBoardView(
          state,
          currentBoardView.id
        );
        columnList = [...staticColumnList, ...includedBoardViewColumnList].sort(
          (colA, colB) => (colA.order ?? 0) - (colB.order ?? 0)
        );
      } else {
        const groupTaskListLength = getGroupTasksListWithoutSubTasks(
          state,
          groupId
        );
        const isCollapsed = taskGroupsSelectOneFieldByQuery(
          state.DBTaskgroupsReducer,
          {
            _id: groupId,
          },
          ["isCollapsed"]
        );

        columnList = [...staticColumnList, ...dynamicColumnList].sort(
          (colA, colB) => (colA.order ?? 0) - (colB.order ?? 0)
        );
        if (groupTaskListLength === 0 || isCollapsed) {
          columnList = columnList.splice(0, 2);
        }
        columnList = columnList.filter(col => (col.type === 'chat' && isDisplayChat) || (col.type !== 'chat'));
      }
    } else {
      const myWorkPreferences = state.MyWorkReducer.myWorkPreferences;
      columnList = getMyWorkColumnsList(myWorkPreferences, dynamicColumnList);
    }

    return columnList;
  }
);

export const getMyWorkColumnsList = (myWorkPreferences, dynamicColumnList) => {
  let columnList = [];
  const desiredTypesOrder = [
    "person-picker",
    "datepicker",
    "status-option-picker",
  ];
  const newTitles = {
    "person-picker": "Owner",
    "status-option-picker": "Status",
    datepicker: "Date",
  };

  let foundTypes = {};
  const desiredColumns = dynamicColumnList
    .filter((col) => {
      if (desiredTypesOrder.includes(col.type) && !foundTypes[col.type]) {
        foundTypes[col.type] = true;
        return true;
      }
      return false;
    })
    .map((col) => ({
      ...col,
      width: 180,
      title: newTitles[col.type],
    }))
    .sort(
      (a, b) =>
        desiredTypesOrder.indexOf(a.type) - desiredTypesOrder.indexOf(b.type)
    );
  // Insert 'empty-cell' columns for missing types
  desiredTypesOrder.forEach((type, index) => {
    if (!foundTypes[type]) {
      const insertPosition = desiredColumns.findIndex(
        (col) => desiredTypesOrder.indexOf(col.type) > index
      );

      const emptyColumn = {
        _id: "empty-cell",
        type: "empty-cell",
        title: newTitles[type],
        width: 180,
        static: true,
        outerCell: true,
        order: index,
      };

      if (insertPosition === -1) {
        desiredColumns.push(emptyColumn);
      } else {
        desiredColumns.splice(insertPosition, 0, emptyColumn);
      }
    }
  });

  if (myWorkPreferences?.selectedColumns) {
    desiredColumns.forEach((col, index) => {
      const selectedCol = myWorkPreferences?.selectedColumns[col.boardId];
      if (selectedCol) {
        if (selectedCol.status && col.type === "status-option-picker") {
          const replacement = dynamicColumnList.find(
            (dCol) => dCol._id === selectedCol.status
          );
          if (replacement) {
            desiredColumns[index] = {
              ...replacement,
              width: 250,
              title: newTitles[replacement.type],
            };
          }
        } else if (selectedCol.date && col.type === "datepicker") {
          const replacement = dynamicColumnList.find(
            (dCol) => dCol._id === selectedCol.date
          );
          if (replacement) {
            desiredColumns[index] = {
              ...replacement,
              width: 100,
              title: newTitles[replacement.type],
            };
          }
        }
      }
    });
  }

  columnList = [...myWorkStaticColumnList, ...desiredColumns];

  return columnList;
};

export const dynamicColumnList = createSelector(
  [(state, boardId) => columnListSelector(state, boardId)],
  (columnList: TaskColumn[]) => {
    return columnList.filter((column) => !column.static);
  }
);

export const getColumnsForDisplayForWorkloadUserCard = createSelector(
  [
    (state) => state,
    (state, boardId) => dynamicColumnList(state, boardId),
    (state, boardId, workloadId) => workloadId,
  ],
  (state, columnList: TaskColumn[], workloadId) => {
    const includedColumns = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "includedColumns"
    );

    const columnsForDisplay = columnList.filter(
      (column) => includedColumns && includedColumns[column._id]
    );

    return columnsForDisplay;
  }
);

export const getOrderedTaskListForWorkloadUserCard = createSelector(
  [
    getSelectedBoardId,
    (state) => state,
    (_, userId) => userId,
    (__, ___, workloadId) => workloadId,
  ],
  (boardId, state, userId, workloadId) => {
    const personPickerId = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "personPickerId"
    );

    const _tasksList = tasksSelectByQuery(
      state.DBTasksReducer,
      {
        boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      [],
      {}
    );

    const tasksOrderByUser = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "tasksOrderByUser"
    );

    const isUserInTask = (task: Task, userId) => {
      if (task.customData !== undefined) {
        if (task.customData[personPickerId] !== undefined) {
          if (task.customData[personPickerId].value !== undefined) {
            if (task.customData[personPickerId].value.length !== 0) {
              let counter = 0;
              for (
                let i = 0;
                i < task.customData[personPickerId].value.length;
                i++
              ) {
                if (task.customData[personPickerId].value[i].id === userId) {
                  counter++;
                }
              }
              if (counter !== 0) {
                return true;
              }
            } else {
              return userId === "emptystateuserfordisplay";
            }
          } else {
            return userId === "emptystateuserfordisplay";
          }
        } else {
          return userId === "emptystateuserfordisplay";
        }
      } else {
        return false;
      }
    };

    const setTaskOrderForSort = (taskId, length) => {
      if (tasksOrderByUser !== undefined) {
        if (tasksOrderByUser[userId] !== undefined) {
          const userOrder = tasksOrderByUser[userId];
          if (userOrder[taskId] !== undefined) {
            return userOrder[taskId];
          }
        }
      } else {
        return length;
      }
    };

    const tasksList = _tasksList
      .filter((task) => isUserInTask(task, userId))
      .sort((a, b) => {
        const tasksLength = _tasksList.length;
        const aOrder = setTaskOrderForSort(a._id, tasksLength);
        const bOrder = setTaskOrderForSort(b._id, tasksLength);
        return aOrder - bOrder;
      });

    return tasksList.map(
      (task) => ({ _id: task._id, customData: task.customData } as Task)
    );
  }
);

export const getUserCapacityList = createSelector(
  [
    (state, userId, workloadId, complexityPickerId) => state,
    (state, userId, workloadId, complexityPickerId) => userId,
    (state, userId, workloadId, complexityPickerId) => workloadId,
    (state, userId, workloadId, complexityPickerId) => complexityPickerId,
    getSelectedBoardId,
  ],
  (state, userId, workloadId, complexityPickerId, boardId) => {
    const tasksList = getOrderedTaskListForWorkloadUserCard(
      state,
      userId,
      workloadId
    );

    const columnOptions = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId: complexityPickerId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    const workloadPeople = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "people"
    );

    const isUserInWorkloadPeople = (userId) => {
      if (workloadPeople && workloadPeople[userId]) {
        return true;
      } else {
        return false;
      }
    };

    const _defaultCapacity = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "defaultCapacity"
    );

    const defaultCapacity =
      _defaultCapacity === undefined ? 100 : _defaultCapacity;
    let profileCapacity;
    if (isUserInWorkloadPeople(userId)) {
      profileCapacity = workloadPeople[userId].capacity ?? defaultCapacity;
    } else {
      profileCapacity = defaultCapacity;
    }

    const userCapacityList = tasksList.map((task, index) => {
      const option = columnOptions.find(
        (option) =>
          task.customData !== undefined &&
          task.customData[complexityPickerId] !== undefined &&
          task.customData[complexityPickerId].value.toString() !== "" &&
          option._id === task.customData[complexityPickerId].value
      );
      const taskComplexityPercentage =
        (option?.complexity / profileCapacity) * 100 ?? 0;
      const taskComplexityColor =
        option?.bgcolor ?? "var(--default-background)";
      return [taskComplexityPercentage, taskComplexityColor];
    });

    return userCapacityList;
  }
);

export const getTaskComplexityList = createSelector(
  [
    (state, userId, workloadId, complexityPickerId) => state,
    (state, userId, workloadId, complexityPickerId) => userId,
    (state, userId, workloadId, complexityPickerId) => workloadId,
    (state, userId, workloadId, complexityPickerId) => complexityPickerId,
    getSelectedBoardId,
  ],
  (state, userId, workloadId, complexityPickerId, boardId) => {
    const tasksList = getOrderedTaskListForWorkloadUserCard(
      state,
      userId,
      workloadId
    );

    const columnOptions = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId: complexityPickerId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    const taskComplexityList = tasksList.map((task, index) => {
      const option = columnOptions.find(
        (option) =>
          task.customData !== undefined &&
          task.customData[complexityPickerId] !== undefined &&
          task.customData[complexityPickerId].value.toString() !== "" &&
          option._id === task.customData[complexityPickerId].value
      );
      return option?.complexity ?? 0;
    });

    return taskComplexityList;
  }
);

export const getProfileCapacity = createSelector(
  [
    (state, workloadId, userId) => state,
    (state, workloadId, userId) => workloadId,
    (state, workloadId, userId) => userId,
  ],
  (state, workloadId, userId) => {
    const workloadPeople = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "people"
    );
    const _defaultCapacity = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "defaultCapacity"
    );

    const defaultCapacity =
      _defaultCapacity === undefined ? 100 : _defaultCapacity;
    const isUserInWorkloadPeople = (userId) => {
      if (workloadPeople && workloadPeople[userId]) {
        return true;
      } else {
        return false;
      }
    };

    let profileCapacity;
    if (isUserInWorkloadPeople(userId)) {
      profileCapacity = workloadPeople[userId].capacity ?? defaultCapacity;
    } else {
      profileCapacity = defaultCapacity;
    }

    return profileCapacity;
  }
);

export const getDefaultCapacity = createSelector(
  [(state, workloadId) => state, (state, workloadId) => workloadId],
  (state, workloadId) => {
    const _defaultCapacity = workloadSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      {
        _id: workloadId,
      },
      "defaultCapacity"
    );

    const defaultCapacity =
      _defaultCapacity === undefined ? 100 : _defaultCapacity;

    return defaultCapacity;
  }
);

export const getDynamicColumnList = createSelector(
  [getSelectedBoardId, (state) => state],
  (boardId, state) => {
    const columnList = dynamicColumnList(state, boardId);
    return columnList.filter((column) => !column.static);
  }
);

export const getDynamicColumnListForBTCheckbox = createSelector(
  [(state, displayMode) => state, (_, displayMode) => displayMode],
  (state, displayMode) => {
    let columnList: TaskColumn[];
    if (displayMode === "board") {
      columnList = getDynamicColumnList(state).map(
        (column, index) =>
        ({
          _id: column._id,
          title: column.title,
          type: column.type,
          isVisible: column.isVisible,
        } as TaskColumn)
      );
    } else {
      columnList = getDynamicColumnList(state).map(
        (column, index) =>
        ({
          _id: column._id,
          title: column.title,
          type: column.type,
        } as TaskColumn)
      );
    }

    return columnList;
  }
);

export const timelinePickerColumnList = createSelector(
  [(state, boardId) => columnListSelector(state, boardId)],
  (columnList: TaskColumn[]) => {
    return columnList.filter((column) => column.type === "timeline-picker");
  }
);

export const optionColumnList = createSelector(
  [(state) => state],
  (state) => {
    const boardId = getSelectedBoardId(state);

    const columnList = columnListSelector(state, boardId).map(
      (column, index) =>
      ({
        type: column.type,
        _id: column._id,
        title: column.title,
      } as TaskColumn)
    );

    return columnList.filter(
      (column) =>
        column.type === "option-picker" ||
        column.type === "status-option-picker"
      // || column.type === 'complexity-picker'
    );
  }
);

export const selectColumnListByType = createSelector(
  [
    (state, boardId, type) => type,
    (state, boardId, type) => columnListSelector(state, boardId),
  ],
  (type: string, columnList: TaskColumn[]) => {
    return columnList.filter((column) => column.type === type);
  }
);

export const dynamicColumnListForForms = createSelector(
  [(state, boardId) => dynamicColumnList(state, boardId)],
  (columnList: TaskColumn[]) => {
    let returnList = columnList.filter((column) => {
      const cell = columnTypeList.find(
        (columnType) => columnType.type === column.type
      );
      return cell?.isCellInForms;
    });
    const nameTask: TaskColumn = {
      _id: "name",
      type: "row-prefix",
      title: "Task Name",
    };
    returnList = [nameTask, ...returnList];

    return returnList;
  }
);

export const dynamicColumnsListOfIdsTypesAndTitles = createSelector(
  [(state, boardId) => dynamicColumnList(state, boardId)],
  (columnList: TaskColumn[]) => {
    const returnList = columnList.map((column, index) => {
      return [column._id, column.type, column.title];
    });

    return returnList;
  }
);

export const dynamicColumnFilteredById = createSelector(
  [
    (state, { columnId }) => ({ columnId }),
    (state, { boardId }) => dynamicColumnList(state, boardId),
  ],
  ({ columnId }, columnList: TaskColumn[]) => {
    return columnList.find((column) => {
      return column._id === columnId;
    });
  }
);

//get sort object from selected board
export const getBoard = createSelector(
  (state: RootState) => state,
  (state) => {
    const { selectedBoardId } = state.BoardsReducer;
    return boardsSelectors.selectById(state, selectedBoardId);
  }
);

export const getSelectedPersons = createSelector(
  (state: RootState) => state,
  (state) => {
    return state.TasksReducer.personFilter;
  }
);

export const getAssignedUserListToTasks = createSelector(
  [(state) => state, baseTasks, getSelectedBoardId],
  (state: RootState, tasks, boardId) => {
    let currentAssignedUsers = [];
    let filteredAssignedUserList = [];
    if (tasks.length > 0) {
      const columnList = columnListSelector(state, boardId);
      const personPickerColumns = columnList.filter((obj) => {
        return obj.type === "person-picker";
      });
      const personPickerColumnsIds = personPickerColumns.map(({ _id }) => _id);
      if (personPickerColumnsIds.length > 0) {
        for (let i = 0; i < tasks.length; i++) {
          personPickerColumnsIds.forEach(function (item) {
            const currentUserAssigned = tasks[i]?.customData?.[item]?.value;
            if (currentUserAssigned) {
              currentAssignedUsers.push(currentUserAssigned);
            }
          });
        }
      }
    }
    if (currentAssignedUsers.length > 0) {
      filteredAssignedUserList = currentAssignedUsers
        .flatMap((element) => element)
        .filter(
          (user, index, array) =>
            array.findIndex((t) => t?.id === user?.id) === index
        )
        .map((user) => user.id);
    }

    const selectedBoardUsers = boardUserSelectByQuery(
      state.DBBoardUserReducer,
      {
        _id: {
          $in: filteredAssignedUserList,
        },
      }
    );

    const _mockPersonList = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        _id: {
          $in: filteredAssignedUserList,
        },
        boardId,
        imageUrl: { $exists: true },
      }
    );
    const mockPersonList = _mockPersonList.map((option) => {
      const [firstName, lastName] = option.label.split(" ");
      const workspaceUser: AugmentedBlueticksBoardUser = {
        _id: option._id,
        status: "active",
        profile: {
          firstName,
          lastName,
          profileImg: option.imageUrl,
        } as User,
        type: "option",
      };
      return workspaceUser;
    });
    const assignedUsersAndMockUser = [...selectedBoardUsers, ...mockPersonList];
    return assignedUsersAndMockUser;
  }
);

export const areAllGroupsCollapsedSelector = createSelector(
  [(state) => state, currentGroups],
  (state, currentGroupList) => {
    return currentGroupList.every((group) => group.isCollapsed);
  }
);

export const getPreviousOrNextTaskIdByCurrentTaskId = createSelector(
  [
    (state, taskId, type) => state,
    (state, taskId, type) => taskId,
    (state, taskId, type) => type,
  ],
  (state, taskId, type) => {
    const elementList = selectElementsList(state).filter(
      (e) => e?.type === "task"
    );
    const numberOfElements = elementList.length;
    const currentTaskIndex: number = elementList.indexOf(
      elementList.find((e) => e?.id === taskId)
    );
    if (type === "previous") {
      if (currentTaskIndex === 0) {
        return elementList[numberOfElements - 1]?.id;
      } else {
        return elementList[currentTaskIndex - 1]?.id;
      }
    } else {
      if (currentTaskIndex === numberOfElements - 1) {
        return elementList[0]?.id;
      } else {
        return elementList[currentTaskIndex + 1]?.id;
      }
    }
  }
);

export const selectOrderedBoardTasksAndSubtasks = createSelector(
  [(state: RootState) => state, selectElementsList],
  (state: RootState, elementsList) => {
    const taskList = elementsList
      .filter(
        (element) =>
          [BoardRowType.task, BoardRowType.subTask].indexOf(element.type) > -1
      )
      .map((element) => state.DBTasksReducer.entities[element.taskId]);

    return taskList;
  }
);

export const getCurrentGroupsLength = createSelector(
  currentGroups,
  (currentGroups): number => {
    return currentGroups.length;
  }
);

export const getExtradataByTaskId = createSelector(
  [(state: RootState) => state, (_: RootState, taskId: string) => taskId],
  (state, taskId) => {
    const extraData = tasksExtraDataSelectByQuery(
      state.DBTasksExtraDataReducer,
      {
        taskId: taskId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      ["_id"],
      { createdAt: -1 }
    );
    return extraData;
  }
);

export const getTaskExtradataLength = createSelector(
  getExtradataByTaskId,
  (extraData): number => {
    return extraData.length ?? 0;
  }
);

export const getExtraDataMergedFiles = createSelector(
  [(state) => state],
  (state: RootState) => {
    const boardId = state.BoardsReducer.selectedBoardId;
    const displayExtraDataOfTaskById =
      state.ExtraDataReducer.displayExtraDataOfTaskById;
    let mergedData: any;
    const filesColumns = taskColumnSelectByQuery(state.DBTaskColumnReducer, {
      boardId: boardId,
      type: "files",
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    });

    const filteredRes = filesColumns.map((item) => item._id);

    const task = tasksSelectOneObjectById(state.DBTasksReducer, displayExtraDataOfTaskById);

    const extraData = tasksExtraDataSelectByQuery(
      state.DBTasksExtraDataReducer,
      {
        taskId: displayExtraDataOfTaskById,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    const extraDataFiles = _tasksExtraDataFilesSelectOneObjectByQuery(
      state.DBTasksExtraDataFilesReducer,
      {
        taskId: displayExtraDataOfTaskById,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    mergedData = mergeData(task, filteredRes, extraData, extraDataFiles);

    return mergedData ?? [];
  }
);

function mergeData(
  task: Task,
  filesColumnsIds: NullableId[],
  extraDataItems: ExtraDataItem[],
  extraDataFiles: ExtraDataFile
) {
  let tasksFilesCells = [];
  let taskUpdates = [];
  let taskGeneralFiles = [];

  // Process filesColumnsIds
  filesColumnsIds.forEach((fileColumnId) => {
    const data = task?.customData?.[fileColumnId]?.value;
    if (data) {
      tasksFilesCells.push(...data);
    }
  });

  function extractUrlsFromChildren(children) {
    let urls = [];
    children.forEach((child) => {
      if (child.type === "link" && child.url) {
        urls.push({
          type: "link",
          url: child.url,
        });
      }
      if (child.children) {
        urls.push(...extractUrlsFromChildren(child.children));
      }
    });
    return urls;
  }

  // Process extraDataItems for Task Updates
  if (extraDataItems && extraDataItems.length > 0) {
    extraDataItems.forEach((item) => {
      if (item.extraData) {
        item.extraData.forEach((extraItem) => {
          if ("url" in extraItem) {
            taskUpdates.push({
              type: extraItem.type,
              url: extraItem.url,
            });
          }

          // if (extraItem && "children" in extraItem && extraItem.children) {
          //   const extractedUrls = extractUrlsFromChildren(extraItem.children);
          //   taskUpdates.push(...extractedUrls);
          // }
        });
      }
    });
  }

  // Process extraDataFiles for Task General Files
  if (extraDataFiles && extraDataFiles.files.length > 0) {
    taskGeneralFiles.push(...extraDataFiles.files);
  }

  return {
    tasks: tasksFilesCells,
    updates: taskUpdates,
    generalFiles: taskGeneralFiles,
  };
}

export const getIncludedColumnsByBoardId = createSelector(
  [
    (state: RootState, boardId: any) => state,
    (state: RootState, boardId: any) => boardId,
  ],
  (state: RootState, boardId) => {
    const dynamicColumnList = taskColumnSelectByQuery(
      state.DBTaskColumnReducer,
      {
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
        boardId,
      },
      ["_id", "isVisible"],
      {
        order: 1,
      }
    );

    return dynamicColumnList;
  }
);

export const getGroupsMaxOrder = createSelector(
  [(state) => state],
  (state: RootState) => {
    const boardId = state.BoardsReducer.selectedBoardId;
    return taskGroupsSelectByQueryOnlyMaxMin(
      state.DBTaskgroupsReducer,
      {
        boardId: boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      "order",
      "max"
    );
  }
);

export const getPersonById = createSelector(
  [getSelectedBoardCompleteUserList, getSelectedPersons],
  (personList, personId) => {
    return personList.find((p) => p._id === personId);
  }
);

export const getGroupTasksListWithoutSubTasks = createSelector(
  [(state: RootState) => state, (_, groupId) => ({ groupId })],
  (state: RootState, { groupId }) => {
    const groupTasksLength = tasksSelectByQueryOnlyLength(
      state.DBTasksReducer,
      {
        groupId: groupId,
        parentTaskId: { $exists: false },
      }
    );
    return groupId ? groupTasksLength : "";
  }
);

export const kanbanColumnOptionsSorted = createSelector(
  [
    getSelectedBoardId,
    (state, kanbanId) => state,
    (state, kanbanId) => kanbanId,
  ],
  (boardId, state, kanbanId) => {
    const optionColumnId: NullableId = kanbanSelectOneFieldByQuery(
      state.DBKanbanReducer,
      { _id: kanbanId },
      "optionColumnId"
    );
    const columnOptions: TaskColumnOption[] = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId: optionColumnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      undefined,
      {
        order: 1,
      }
    );
    const kanban = kanbanSelectOneObjectByQuery(state.DBKanbanReducer, {
      boardId,
      _id: kanbanId,
    });
    const sortedOptions = columnOptions
      .sort((a, b) => {
        if (kanban.columnsOrder) {
          const numberOfOptions = columnOptions.length;

          let aOrder = numberOfOptions;
          if (kanban.columnsOrder[a._id] !== undefined) {
            aOrder = kanban.columnsOrder[a._id];
          }

          let bOrder = numberOfOptions;
          if (kanban.columnsOrder[b._id] !== undefined) {
            bOrder = kanban.columnsOrder[b._id];
          }
          return aOrder - bOrder;
        } else {
          return a.order - b.order;
        }
      })
      .map((option, index) => ({
        ...option,
        order: index,
      }));
    return sortedOptions;
  }
);

export const isKanbanOptionPickerExists = createSelector(
  [(state) => state, (_, kanbanId) => kanbanId],
  (state, kanbanId) => {
    const optionColumnId = kanbanSelectOneFieldByQuery(
      state.DBKanbanReducer,
      { _id: kanbanId },
      "optionColumnId"
    );
    return optionColumnId !== undefined;
  }
);

export const isWorkloadComplexityPickerExist = createSelector(
  [(state) => state, (_, workloadId) => workloadId],
  (state, workloadId) => {
    const complexityPickerId = kanbanSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      { _id: workloadId },
      "complexityPickerId"
    );
    return !(complexityPickerId === null || complexityPickerId === undefined);
  }
);

export const isWorkloadPersonPickerExist = createSelector(
  [(state) => state, (_, workloadId) => workloadId],
  (state, workloadId) => {
    const personPickerId = kanbanSelectOneFieldByQuery(
      state.DBWorkloadReducer,
      { _id: workloadId },
      "personPickerId"
    );
    return !(personPickerId === null || personPickerId === undefined);
  }
);

export const workloadSortedUserList = createSelector(
  [
    (state, workloadId) => state,
    (state, workloadId) => workloadId,
    getSelectedBoardId,
  ],
  (state, workloadId, boardId) => {
    const emptyStateUser: AugmentedBlueticksBoardUser = {
      _id: "emptystateuserfordisplay", // 24 letters
      type: "user",
      boardId,
      name: "Empty Tasks",
      firstName: "Empty",
      lastName: "Tasks",
    };
    // const mockPersonList = getMockPersonList(state)
    const userList = [
      ...getSelectedBoardCompleteUserList(state),
      emptyStateUser,
    ];
    const usersOrder =
      workloadSelectOneFieldByQuery(
        state.DBWorkloadReducer,
        {
          _id: workloadId,
        },
        "usersOrder"
      ) ?? {};

    const allKeys = Object.keys(usersOrder);
    const notFound = userList.filter((user) => !allKeys.includes(user._id));

    const returnList: AugmentedBlueticksBoardUser[] = [];

    if (Object.keys(usersOrder).length !== 0) {
      for (const key in usersOrder) {
        if (Object.prototype.hasOwnProperty.call(usersOrder, key)) {
          const idx = usersOrder[key];
          const usr = userList.find((_usr) => _usr._id === key);
          if (usr) {
            returnList[idx] = usr;
          }
        }
      }

      let _returnList = returnList.filter((x) => x);
      _returnList.push(...notFound);
      return _returnList;
      // returnList = userList.sort((a, b) => {
      //   const numberOfUsers = userList.length
      //   const aOrder = usersOrder[a._id] === undefined ? (numberOfUsers + 1) : usersOrder[a._id]
      //   const bOrder = usersOrder[b._id] === undefined ? (numberOfUsers + 1) : usersOrder[b._id]
      //   return aOrder - bOrder
      //   // return bOrder - aOrder
      // })
      // return returnList
    } else {
      return userList;
    }
  }
);

export const getWorkloadUserForUserCard = createSelector(
  [(userId) => userId, (_, state) => state, (_, __, workloadId) => workloadId],
  (userId, state, workloadId) => {
    const userList = workloadSortedUserList(state, workloadId);

    return userList.find((user) => user._id === userId);
  }
);

export const getIsShowSubTasks = createSelector(
  [(params) => params],
  (params: { state: RootState; taskId }) => {
    return params.state.TasksReducer.toggleTasksMap[params.taskId];
  }
);

export const getUserForLogs = createSelector(
  [(state, value) => state, (state, value) => value],
  (state, value) => {
    const allUsersList = [
      ...getSelectedBoardCompleteUserList(state),
      ...getMockPersonList(state),
    ];
    const user = allUsersList.find((user) => user.userId === value?._id)
      ?.profile ??
      allUsersList.find((user) => user._id === value._id)?.profile ?? {
      firstName: value.firstName,
      lastName: value.lastName,
      email: "www@www.www",
    };

    return user as User;
  }
);

export const getTaskAccentColor = createSelector(
  [
    (type) => type,
    (_, groupId) => groupId,
    (_, __, state) => state,
    (_, __, ___, taskId) => taskId,
    (_, __, ___, ____, columnId) => columnId,
  ],
  (type, groupId, state, taskId, columnId) => {
    let groupColor;
    if (type === "kanban") {
      groupColor = taskGroupsSelectOneObjectByQuery(
        state.DBTaskgroupsReducer,
        {
          _id: groupId,
        }

      );
    }
    if (type === "workload") {
      const customData = tasksSelectOneFieldById(
        state.DBTasksReducer,
        taskId,
        "customData"
      );
      let complexityOptionId;
      if (customData !== null) {
        complexityOptionId = customData[columnId];
      }
      let complexityOption;
      if (complexityOptionId) {
        complexityOption = taskColumnOptionSelectOneObjectById(
          state.DBTaskColumnOptionReducer,
          complexityOptionId.value
        );
      }
      groupColor = complexityOption?.bgcolor;
    }

    return groupColor;
  }
);

export const getOneFieldFromTaskGroupByTaskId = createSelector(
  [(params) => params],
  (params: { state: RootState; taskId; field: string }) => {

    const task: Task | undefined = tasksSelectors.selectById(params.state, params.taskId)
    const groupId = task?.groupId

    const selectedField = taskGroupsSelectOneFieldById(params.state.DBTaskgroupsReducer, groupId, params.field)

    return selectedField;
  }
);

//selector that returns checked tasks bool
export const getIsChecked = createSelector(
  [(params) => params],
  (params: { state: RootState; taskId: string }) => {
    const bulkTasksMap = params.state.BulkActionsReducer.bulkTasksMap;
    const task = tasksSelectors.selectById(params.state, params.taskId);
    return !!bulkTasksMap[task?._id];
  }
);

// selector that counts subtasks
export const getSubTasksCount = createSelector(
  [(state) => state, (_, taskId) => taskId],
  (state, taskId) => {
    const subtasksOfTheSameTask = tasksSelectByQuery(state.DBTasksReducer, {
      parentTaskId: taskId,
    });
    return subtasksOfTheSameTask.length;
  }
);

// selector for taskcheck for UI only
export const isLastTaskInGroup = createSelector(
  (state, taskId) => ({ state, taskId }),
  selectElementsList,
  (params: any, elementsList: any) => {
    if (!params.taskId) return false;
    var tasks = elementsList.filter((el) => el?.type === BoardRowType.task);
    const task = tasks.find((el) => el?.id === params.taskId);
    tasks = tasks.filter((el) => el?.groupId === task?.groupId);
    return tasks.length && tasks[tasks.length - 1].id === params.taskId;
  }
);

// export const isLastTaskWithSubtasksInGroup = createSelector(
//   (state, taskId, subTasksCount) => ({ state, taskId, subTasksCount }),
//   selectElementsList,
//   (params, elementsList) => {
//     if (!params.taskId) return false;
//     var tasks = elementsList.filter((el) => el?.type === BoardRowType.task);
//     const task = tasks.find((el) => el?.id === params.taskId);
//     tasks = tasks.filter((el) => {
//       return el?.groupId === task?.groupId
//     });
//     tasks = tasks.filter((el) => tasks.indexOf(el) <= tasks.indexOf(task) || tasks.indexOf(el) > tasks.indexOf(task) + params.subTasksCount)
//     return tasks.length && tasks[tasks.length - 1].id == params.taskId;
//   }
// );

export const isFirstTaskInGroup = createSelector(
  (state, taskId) => ({ state, taskId }),
  selectElementsList,
  (params: any, elementsList: any) => {
    var tasks = elementsList.filter((el) => el?.type === BoardRowType.task);
    const task = tasks.find((el) => el?.id === params.taskId);
    tasks = tasks.filter((el) => el?.groupId === task?.groupId);
    return tasks.length && tasks[0].id === params.taskId;
  }
);

export const handleSubTasks = (isChecking, taskSubTasks, bulkTasksMap) => {
  let uncheckParent = false;
  for (let subTask of taskSubTasks) {
    if (isChecking) {
      bulkTasksMap[subTask._id] = subTask;
    } else {
      if (bulkTasksMap[subTask._id]) {
        delete bulkTasksMap[subTask._id];
        uncheckParent = true;
      }
    }
  }
  return {
    anchorId: isChecking
      ? taskSubTasks[taskSubTasks.length - 1]._id
      : undefined,
    uncheckParent,
  };
};

export const handleTaskSelection = (isChecking, taskId, task, bulkTasksMap) => {
  isChecking ? (bulkTasksMap[taskId] = task) : delete bulkTasksMap[taskId];
  return isChecking ? taskId : undefined;
};

export const selectDropdownOptionsAndPickedValues = createSelector(
  [(state) => state, (state, params: any) => params],
  (state: RootState, params) => {
    const { taskId, columnId, boardId } = params;
    const cellValue = cellValueSelector(state, taskId, columnId);
    const columnOptions = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    const dropdownValues = tasksSelectByQuery(state.DBTasksReducer, {
      boardId,
      [`customData.${columnId}`]: {
        $exists: true,
      },
    });
    const flattenDropdownValuesIds = dropdownValues
      .map((obj) => obj?.customData[columnId].value)
      .flat();
    const dropdownValuesIds = flattenDropdownValuesIds.map(
      (value) => value?._id
    );
    const extractValues = columnOptions
      .filter((option) => cellValue?.some((value) => value._id === option._id))
      .map((s) => s._id);
    const optionsTopick = columnOptions.filter(
      (option) => !extractValues.includes(option._id)
    );
    const pickedOptions = columnOptions.filter((option) =>
      extractValues.includes(option._id)
    );

    return { columnOptions, optionsTopick, pickedOptions, dropdownValuesIds };
  }
);

export const columnOptionIsInUsed = createSelector(
  [(state) => state, (state, params: any) => params],
  (state: RootState, params) => {
    const { columnId, boardId } = params;

    const tasks = tasksSelectByQuery(
      state.DBTasksReducer,
      {
        boardId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    const columnOptions = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      }
    );

    const columnOptionsIds = columnOptions.map((option) => option._id);
    const columnValues = tasks
      .map((obj) => obj?.customData?.[columnId]?.value)
      .flat();

    const columnValuesIds = columnValues.map((value) => value);

    const columnOptionsInUse = columnOptionsIds.filter((option) =>
      columnValuesIds.includes(option)
    );
    return columnOptionsInUse;
  }
);


export const getColumnValues = createSelector(
  [(state) => state, (state, params: any) => params],
  (state: RootState, params) => {
    const { groupId, columnId, boardId, columnType } = params;
    const currentBoardTasks = currentTasks(state);
    const currentBoardTaskIds = currentBoardTasks.map((task) => task._id);

    const columnValues = tasksSelectByQuery(state.DBTasksReducer, {
      boardId,
      groupId,
      taskType: { $ne: "subTask" },
      [`customData.${columnId}`]: {
        $exists: true,
      },
    }).filter((task) => currentBoardTaskIds.includes(task._id));

    const tasksLength = tasksSelectByQueryOnlyLength(state.DBTasksReducer, {
      boardId,
      groupId,
      taskType: { $ne: "subTask" },
    });
    let res;
    const columnValuesArray = columnValues
      .map((obj) => obj?.customData[columnId].value)
      .flat();
    if (columnType === "number" || columnType === "checkbox") {
      res = columnValuesArray;
    }
    if (columnType === "checkbox") {
      res = { length: tasksLength, data: columnValuesArray };
    }
    if (columnType === "person-picker") {
      res = columnValuesArray;
    }
    if (columnType === "rating") {
      res = { length: tasksLength, data: columnValuesArray };
    }
    if (
      columnType === "status-option-picker" ||
      columnType === "option-picker" ||
      columnType === "complexity-picker"
    ) {
      const tasks = tasksSelectByQuery(state.DBTasksReducer, {
        groupId,
        taskType: { $ne: "subTask" },
      }).filter((task) => currentBoardTaskIds.includes(task._id));
      const tasksLength = tasks.length;
      const columnOptions = taskColumnOptionSelectByQuery(
        state.DBTaskColumnOptionReducer,
        {
          boardId,
          columnId,
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
        }
      );

      const count = {};
      columnValuesArray.forEach((element) => {
        count[element] = (count[element] || 0) + 1;
      });

      const selectedOptions = columnValuesArray.map((id) =>
        columnOptions.find((el) => el._id === id)
      );

      const selectedOptionsCloned = JSON.parse(JSON.stringify(selectedOptions));
      const duplicates = findDuplicates(selectedOptionsCloned);
      if (duplicates) {
        selectedOptionsCloned.forEach((obj) => {
          const count = duplicates[obj?._id];
          if (obj && count && count >= 1) {
            obj.count = count;
          }
        });
      }
      res = { selectedOptionsCloned, tasksLength };
    }

    if (columnType === "files") {
      let allImages = [];
      columnValues?.flatMap((item) => {
        const files =
          item.customData[columnId]?.value?.map((obj) => ({
            ...obj,
            _id: item._id,
          })) ?? [];
        if (files.length > 0) {
          allImages.push(files);
        }
      });
      res = allImages.flat();
    }

    return res;
  }
);

export const getTasksWithValueInColumnIdSelector = createSelector(
  [(state: RootState) => state, (_, columnId: NullableId) => columnId],
  (state: RootState, columnId) => {
    const currentBoardTasks = currentTasks(state);
    const matchingList = [];
    for (let i = 0; i < currentBoardTasks.length; i++) {
      const task = currentBoardTasks[i];
      if (task?.customData && task?.customData[columnId]?.value) {
        matchingList.push(task);
      }
    }
    return matchingList;
  }
);

export const getGroupTaskListLength = createSelector(
  [(state: RootState) => state, (_, groupId) => ({ groupId })],
  (state: RootState, { groupId }) => {
    if (!groupId) return "";

    const groupTasksLength = tasksSelectByQueryOnlyLength(
      state.DBTasksReducer,
      {
        groupId: groupId,
      }
    );

    return groupTasksLength;
  }
);

export const columnListSelector = createSelector(
  [
    (state, boardId) =>
      taskColumnSelectByQuery(
        state.DBTaskColumnReducer,
        {
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
          boardId: boardId ?? "No Board", // if no board add a filter so no columns will be returned
        },
        [],
        {
          order: 1,
        }
      ),
    (state, __, groupId?) => getGroupTaskListLength(state, groupId),
  ],
  (dynamicColumnList, groupTaskListLength) => {
    let columnList: TaskColumn[];

    columnList = [...staticColumnList, ...dynamicColumnList].sort(
      (colA, colB) => (colA.order ?? 0) - (colB.order ?? 0)
    );
    if (groupTaskListLength === 0) {
      columnList = columnList.splice(0, 2);
    }
    return columnList;
  }
);

export const columnTaskSelector = createSelector(
  [
    (state: RootState) => state,
    (state, columnId: NullableId, columnValue, isDefault) => ({
      columnId,
      columnValue,
      isDefault,
    }),
  ],
  (state: RootState, { columnId, columnValue, isDefault }) => {
    const currentBoardTasks = currentTasks(state);
    const columnOptionsWithoutDefault = taskColumnOptionSelectByQuery(
      state.DBTaskColumnOptionReducer,
      {
        boardId: getSelectedBoardId(state),
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
      },
      undefined,
      {
        order: 1,
      }
    )
      .filter((option) => option._id !== columnValue)
      .map((option) => option._id);

    let matchingList = [];
    let unMatchingList = [];
    for (let i = 0; i < currentBoardTasks.length; i++) {
      let isMatchingCustomValue = false;
      const task = currentBoardTasks[i];
      if (task.customData) {
        if (task.customData[columnId]) {
          if (task.customData[columnId].value === columnValue) {
            isMatchingCustomValue = true;
          }
        }
      }

      if (isMatchingCustomValue) {
        matchingList.push(task);
      } else if (isDefault) {
        if (task.customData) {
          if (task.customData[columnId] === undefined) {
            unMatchingList.push(task);
          } else if (
            columnOptionsWithoutDefault.indexOf(
              task.customData[columnId].value
            ) === -1
          ) {
            unMatchingList.push(task);
          }
        } else {
          unMatchingList.push(task);
        }
      }
    }

    const finalList = [...matchingList, ...unMatchingList].sort((a, b) => {
      const aOrder =
        a.customData === undefined ||
          a.customData[columnId] === undefined ||
          a.customData[columnId].order === undefined
          ? -1
          : a.customData[columnId].order;
      const bOrder =
        b.customData === undefined ||
          b.customData[columnId] === undefined ||
          b.customData[columnId].order === undefined
          ? -1
          : b.customData[columnId].order;
      return aOrder - bOrder;
    });

    return finalList;
  }
);

export const getKanbanColumnTasksByOrder = createSelector(
  [
    getSelectedBoardId,
    (state) => state,
    (_, kanbanId) => kanbanId,
    (state, __, columnId, columnValue, isDefault) =>
      columnTaskSelector(state, columnId, columnValue, isDefault),
  ],
  (boardId, state, kanbanId: NullableId, columnOptionTasks: Task[]) => {
    const kanban = kanbanSelectOneObjectByQuery(state.DBKanbanReducer, {
      boardId,
      _id: kanbanId,
    });
    let orderedTasksList: Task[] = [];
    const numberOfTasks = columnOptionTasks.length;
    if (kanban.tasksOrder) {
      orderedTasksList = columnOptionTasks.sort((a, b) => {
        let aOrder = numberOfTasks;
        if (kanban.tasksOrder[a._id] !== undefined) {
          aOrder = kanban.tasksOrder[a._id];
        }

        let bOrder = numberOfTasks;
        if (kanban.tasksOrder[b._id] !== undefined) {
          bOrder = kanban.tasksOrder[b._id];
        }

        return aOrder - bOrder;
      });
    } else {
      orderedTasksList = columnOptionTasks;
    }

    const returnList = orderedTasksList.map(
      (task, index) => ({ _id: task._id } as Task)
    );

    return returnList;
  }
);

export const selectColumnDetailsById = createSelector(
  [
    (state, boardId, _) => columnListSelector(state, boardId),
    (_, boardId, columnId) => columnId,
  ],
  (columnList, columnId) => columnList.find((column) => column._id === columnId)
);
export const getWorkspaceUserById = createSelector(
  [getCurrentWorkspaceUserList, (state, userId) => userId],
  (userList, userId) => {
    return userList.find((user) => user.userId === userId);
  }
);

export const usedComponentSelector = createSelector(
  [
    (state, colType: ColumnType) => colType,
    (state, colType) => state.TasksReducer.isScrollingStart,
    (state, colType) => state.TasksReducer.isScrollingEnd,
    (state, colType) => state.TasksReducer.currentFPS,
  ],
  (colType, isScrollingStart, isScrollingEnd, currentFPS) => {
    const columnType = columnTypeMap.get(colType)
    let usedComponent;
    if ((isScrollingStart || !isScrollingEnd) && currentFPS < 30) {
      console.log("%c Scrolling component used", "color: red");


      usedComponent = columnType?.scrollingCellComponent ?? columnType?.cellComponent;
    } else {
      usedComponent = columnType?.cellComponent;
    }

    return usedComponent;
  }
);

export const selectAudienceSelectedContactList = createSelector(
  [
    (state) => state.DBTasksReducer,
    (state) => state.DBTaskColumnReducer,
    (state) => getSelectedBoardId(state),
  ],
  (DBTasksReducer, DBTaskColumnReducer, boardId) => {
    const boardTaskList = tasksSelectByQuery(DBTasksReducer, {
      boardId,
    });
    const currentBoardIdColumnList = taskColumnSelectByQuery(
      DBTaskColumnReducer,
      {
        deleted: false,
        boardId,
      }
    )
    const contactColumn = currentBoardIdColumnList.find((column) => column.type === "whatsapp-id-cell");
    const audienceSelectedContactList = boardTaskList.map((task) => {
      return task.customData[contactColumn._id]?.value as string;
    });

    return audienceSelectedContactList;
  }
);

export const selectAllContactsSelected = createSelector(
  [
    (state) => state.WhatsAppReducer,
    (state: RootState) => selectMergedContactList(state),
    (state) => selectAudienceSelectedContactList(state),
  ],
  (WhatsAppReducer, mergedContactList: ContactInfo[], audienceSelectedContactList) => {
    const importedContactsArray = WhatsAppReducer.importedContactsArray;
    const selectedIdList = importedContactsArray.map(contact => contact.whatsappId);

    const allContacts = mergedContactList.map((contact) => contact.whatsappId);
    // Compare selectedIdList and audienceSelectedContactList with allContacts using Set
    const selectedContacts = new Set([...selectedIdList, ...audienceSelectedContactList]);
    const allContactsSet = new Set(allContacts);

    // Check if all selected contacts are in the audienceSelectedContacts
    const allContactsSelected = selectedContacts.size === allContactsSet.size && [...selectedContacts].every((contact) => allContactsSet.has(contact));

    return allContactsSelected;
  }
);


export const selectDoesContactExistInBoard = createSelector(
  [
    (state) => selectAudienceSelectedContactList(state),
    (state, whatsappId) => whatsappId
  ],
  (audienceSelectedContactList, whatsappId) => {
    const contactExistsInBoard = audienceSelectedContactList.some((contactId) => {
      return contactId === whatsappId;
    })

    return contactExistsInBoard;
  }
);