import BTInputField from '@components/bt-input-field/bt-input-field';
import { Task } from "@models/task";
import { Box, Popover } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { Stack } from "@mui/system";
import { automation } from "@reducers/AutomationReducer";
import { getActiveBoards } from "@reducers/BoardsSelectors";
import { DBTaskColumnOptionThunks, DBTaskColumnThunks, DBTaskgroupsThunks, DBTasksThunks, taskGroupsSelectByQuery, tasksSelectOneObjectByQuery } from "@reducers/DBServiceReducers";
import { addNewTaskThunk } from "@reducers/TaskThunks";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCallback, useEffect, useState } from "react";
import AddAndRemove from "../add-and-remove";
import AutomationButton from '../automation-button';
import { setCurrentAction } from "../automations-helper";
import ColumnRow from '../dropdowns/column-row';
import GroupDropdown from '../dropdowns/groups-dropdown';
import TaskNewRowCellWrapper from '../dropdowns/task-row-cell-wrapper';
import { dynamicColumnsListOfIdsTypesAndTitles } from '@reducers/TaskSelectors';

const CreateTaskOnBoardAction = ({ index }) => {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [boardName, setBoardName] = useState<string>('board')
    const [searchValue, setSearchValue] = useState('');
    const [taskDuplicated, setTaskDuplicated] = useState(false);
    const [boardId, setBoardId] = useState<string>();
    const actions = useAppSelector((state) => state.AutomationReducer.actions);
    const taskId = actions[index].taskId;
    const boards = useAppSelector(getActiveBoards);
    const columnsListOfIds = useAppSelector((state) => dynamicColumnsListOfIdsTypesAndTitles(state, boardId))
    const { attemptedSubmission, validationStates } = useAppSelector((state) => state.AutomationReducer);
    const task = useAppSelector((state) => tasksSelectOneObjectByQuery(state.DBTasksReducer, {
        _id: taskId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    },
    ))

    const groups = useAppSelector((state) => taskGroupsSelectByQuery(state.DBTaskgroupsReducer,
        {
            boardId: boardId,
            $or: [{ deleted: { $exists: false } }, { deleted: false }]
        },
    ))


    const prefix = index === 0 ? 'Then' : 'and then'
    const open = Boolean(anchorEl);






    // dispatch(
    //     DBTaskgroupsThunks.find({
    //         boardId: boardId,
    //         deleted: false,
    //         $paginate: false,
    //     })
    // );
    // dispatch(
    //     DBTaskColumnThunks.find({
    //         boardId: boardId,
    //         deleted: false,
    //         $paginate: false,
    //     })
    // )
    // dispatch(
    //     DBTaskColumnOptionThunks.find({
    //         boardId: boardId,
    //         deleted: false,
    //         $paginate: false,
    //     })
    // );



    const handleClose = () => setAnchorEl(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose2 = () => {
        setAnchorEl2(null);
        resetSearchValue()
    }
    const handleClick2 = (event) => setAnchorEl2(event.currentTarget);



    const createNewTask = useCallback(async () => {

        let task: Task = {
            text: "",
            boardId,
            groupId: groups[0]?._id,
            isTemplate: true
        };

        const { payload } = await dispatch(addNewTaskThunk(task)) as any;
        dispatch(automation.setActions(setCurrentAction(actions, index, { taskId: payload?._id })));
    }, [actions, boardId, dispatch, groups, index]);


    const duplicateTaskTemplate = useCallback(async () => {
        const { _id, ...taskWithoutId } = task;
        const { payload } = await dispatch(addNewTaskThunk(taskWithoutId)) as any;
        console.log(payload);
        dispatch(automation.setActions(setCurrentAction(actions, index, { taskId: payload?._id })));
        console.log('duplicate  task');

    }, [actions, dispatch, index, task]);

    const handleSelectOnChange = (board) => {
        setBoardId(board._id);
        setBoardName(board.name)
        dispatch(automation.setActions(setCurrentAction(actions, index, { boardId: board._id })));
        handleClose2();
    };

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const resetSearchValue = useCallback(() => {
        setSearchValue('');
    }, []);


    const filteredOptions = boards.filter((option) =>
        option.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    useEffect(() => {
        if (boardId) {
            setBoardName(boardName)
            dispatch(
                DBTaskgroupsThunks.find({
                    boardId: boardId,
                    deleted: false,
                    $paginate: false,
                })
            );
            dispatch(
                DBTaskColumnThunks.find({
                    boardId: boardId,
                    deleted: false,
                    $paginate: false,
                })
            )
            dispatch(
                DBTaskColumnOptionThunks.find({
                    boardId: boardId,
                    deleted: false,
                    $paginate: false,
                })
            );
        }
    }, [boardId, boardName, dispatch]);

    useEffect(() => {
        if (taskId && boardId) {
            dispatch(
                DBTasksThunks.find({
                    boardId: boardId,
                    deleted: false,
                    $paginate: false,

                })
            );
        }
    }, [taskId, boardId, dispatch]);

    useEffect(() => {
        if (actions[index]?.boardId) {
            setBoardId(actions[index].boardId.toString());
            const board = boards.find(item => item._id === actions[index].boardId.toString());
            setBoardName(board.name)
        }

        if (!taskId && boardId && groups[0]._id) {
            createNewTask();
        }

        if (boardId) {
            setBoardName(boardName)
        }

        if (taskId && task && !taskDuplicated) {
            duplicateTaskTemplate();
            setTaskDuplicated(true);
        }

        dispatch(automation.fieldValidityChanged({ component: 'CreateTaskOnBoardAction', index, field: 'taskText', isValid: !!task?.text }));
        dispatch(automation.fieldValidityChanged({ component: 'CreateTaskOnBoardAction', index, field: 'boardId', isValid: !!boardId }));
    }, [taskId, task, createNewTask, duplicateTaskTemplate, taskDuplicated, dispatch, index, boardId, groups, boardName, actions, boards]);

    return (
        <Stack key={index} sx={{ fontSize: "24px" }} direction="row" justifyContent={"space-between"}>
            <Box>
                {prefix} create a
                <AutomationButton
                    componentName='CreateTaskOnBoardAction'
                    index={index}
                    option='taskText'
                    label={"task"}
                    handleClick={handleClick}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        sx: { width: "400px", backgroundColor: "#E8F9FD" }
                    }}>

                    {boardId ? (
                        <>
                            <TaskNewRowCellWrapper taskId={taskId} text={task?.text} />
                            <GroupDropdown boardId={boardId} taskId={taskId} groups={groups} />
                            {columnsListOfIds
                                .filter(column => column[1] !== 'created' && column[1] !== 'last-updated')
                                .map((column, index) => (
                                    <ColumnRow column={column} index={index} taskId={taskId} boardId={boardId} />
                                ))
                            }
                        </>
                    ) : (<Box p={5} textAlign={"center"}>First select board</Box>)
                    }
                </Popover>

                in

                <AutomationButton
                    componentName='CreateTaskOnBoardAction'
                    index={index}
                    option='boardId'
                    label={boardName}
                    handleClick={handleClick2}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                />

                <Popover
                    id={"boards"}
                    open={Boolean(anchorEl2)}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    PaperProps={{
                        style: {
                            maxHeight: '50%',
                            width: '250px',
                            padding: "10px"
                        },
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <BTInputField
                        placeholder="Filter options"
                        onChange={handleInputChange}
                        autoFocus
                        fullWidth
                        autoComplete='off'
                        margin="dense"
                        sx={{ marginBottom: "10px" }}
                    />

                    {filteredOptions.map((board) => (
                        <MenuItem sx={{ fontSize: "13px" }} key={board._id} onClick={() => handleSelectOnChange(board)}>
                            <Box sx={{ marginRight: "5px" }}>{board.titleIcon}</Box>
                            <Box> {board.name}</Box>
                        </MenuItem>
                    ))}
                </Popover>


            </Box>
            <AddAndRemove elements={actions} index={index} type={"actions"} componentName="CreateTaskOnBoardAction" />
        </Stack>
    );
};

export default CreateTaskOnBoardAction;


