import BTBulkManagement from "@components/bt-bulk-dashboard/bt-bulk-management"
import BulkLogsDialog from "@components/bt-bulk-dashboard/bulk-logs-dialog"
import { Box } from "@mui/material"
import { setActiveBulkView } from "@reducers/CampaignReducer"
import { useAppDispatch, useAppSelector } from "@store/hooks"
import { useEffect } from "react"

export const CampaignManagementView = () => {
    const dispatch = useAppDispatch()
    const activeBulkView = useAppSelector((state) => state.CampaignReducer.activeBulkView);

    useEffect(() => {
        dispatch(setActiveBulkView('campaignManagementView'))
    }, [])

    return <Box>
        {
            activeBulkView === "campaignManagementView" &&
            <BTBulkManagement />
        }

        {
            (activeBulkView === "campaignLogsView") &&
            <BulkLogsDialog />
        }
    </Box>
}