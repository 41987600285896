import BTButton from '@components/bt-button/bt-button'
import { getCurrentBoard } from '@reducers/BoardsSelectors'
import { addNewColumnThunk } from '@reducers/TaskThunks'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { namespace } from 'config/constants'
import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'

export default function NewPersonColumnButton(type) {
    const { t } = useTranslation(namespace);
    const currentBoard = useAppSelector(getCurrentBoard)
    const dispatch = useAppDispatch()

    function newPersonColumn(type, title) {
        dispatch(addNewColumnThunk({
            type,
            title,
            boardId: currentBoard._id,
        }))
    }

    return (
        <>
                <BTButton sx={{
                    width: '100%',
                    maxWidth: "250px",
                    margin: '8px'
                    }} onClick={() => newPersonColumn('person-picker', 'Person')} startIcon={<MdAdd />} >
                    {t('newPersonColumnButton.addPersonColumn')}
                </BTButton>
        </>

    );
}