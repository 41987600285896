import CellWrapper from "@components/tasks/columns-cells/cell-wrapper";
import { columnTypeList } from "@components/tasks/columns-cells/column-cell-defs";
import { Box, Stack } from "@mui/material";
import { getColumnsIcon } from "../automations-helper";

const ColumnRow = ({ column, index, taskId, boardId }) => {
 
    const [cell] = columnTypeList.filter(
        (columnType) =>
            columnType.type === column[1]
    );
    let backgroundIndicator = true;
    if (['timeline-picker', 'option-picker', 'status-option-picker', 'complexity-picker'].indexOf(column[1]) !== -1) {
        backgroundIndicator = false
    }
    return (


        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} key={`${index}_${column[0]}`} sx={{
            backgroundColor: "white",
            margin: "10px",
            padding: "10px",
            borderRadius: "8px",
        }}>

            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ textAlign: "center", fontSize: "13px" }}>
                <Box sx={{ marginRight: "5px" }}>{getColumnsIcon(column)}</Box> {column[2] ?? column[1]}</Stack>
            <Box sx={{

                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: "60%",
                border: '1px dashed  #d0d4e4',
                padding: "4px",
                backgroundColor: backgroundIndicator ? 'var(--background-default))' : 'transparent',
            }}>

                <CellWrapper
                    columnId={column[0]}
                    columnType={column[1]}
                    taskId={taskId}
                    boardId={boardId}
                    cellComponent={cell.cellComponent}
                    isOnKanban
                />

            </Box>


        </Stack>
    );
};

export default ColumnRow;