import { createAsyncThunk } from "@reduxjs/toolkit";
import { isExtensionContextOrWhatsappView } from "@services/detect-context";
import { globalHistory } from "@services/global-history";
import { messageService } from "@services/messageService";
import { RootState } from "@store/index";
import { BLUETICKS_TOKEN_KEY } from "reusable/common";
import { accessFormPopupActions } from "./AccessFormPopupReducer";
import { DBSessionThunks, SessionReducerActions } from "./DBServiceReducers";
import { mainSliceActions, toggleSocketModal } from "./MainReducer";
import { logoutThunk } from "./UserReducer";
import { selectCurrentSessionId, selectUserId } from "./UserSelectors";
import { backendApiEndpoints } from "./backend-api/backend-api";
import { Subscription } from "@models/subscription";


export const logoutWrapperThunk = createAsyncThunk<void, { accessToken: string | null }, { state: RootState }>(
  "logoutThunk",
  ({ accessToken }, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(logoutThunk());


    if (accessToken) {
      window.localStorage.removeItem(BLUETICKS_TOKEN_KEY);
      const url = new URL(window.location.href);
      url.searchParams.delete("accessToken");
      window?.history?.replaceState(null, "", url);
      messageService.sendMessage<string>("logout", {});
      messageService.sendPageMessage("logout", {});
      messageService.sendMessageToTab("logout", {});
    }
    //never navigate to /login in whatsApp!
    if (!isExtensionContextOrWhatsappView()) {
      globalHistory?.push("/login");
    }
  }
);

export const openSocketModalThunk = createAsyncThunk<void, void, { state: RootState }>(
  "openSocketModalThunk",
  async (_, thunkApi) => {
    const { dispatch } = thunkApi;
    const state = thunkApi.getState();

    const userId = selectUserId(state);
    if (!userId) {
      dispatch(accessFormPopupActions.setStatus("Signup"));
    } else {
      const sessionId = selectCurrentSessionId(state);
      dispatch(SessionReducerActions.removeOne(sessionId));
      dispatch(toggleSocketModal(true));
    }
  }
);

export const createNewSessionThunk = createAsyncThunk<void, void, { state: RootState }>(
  "createNewSessionThunk",
  async (_, thunkApi) => {
    const { dispatch } = thunkApi;
    await dispatch(SessionReducerActions.clearState());
    await dispatch(backendApiEndpoints.createSession.initiate());
    await dispatch(DBSessionThunks.find({}));
  }
);

// Validate that the user does not have redundant subscriptions
export const validateSubscriptionsThunk = createAsyncThunk<void, Subscription[], { state: RootState }>(
  "validateSubscriptionsThunk",
  async (subscriptionList: Subscription[], thunkApi) => {
    const { dispatch } = thunkApi;
    

    const hasActiveChargebee = subscriptionList.some(sub => 
      sub.type === 'chargebee' && sub.status.toLowerCase() === 'active'
    );
    
    // Get all active Bluesnap subscriptions
    const activeBluesnapSubs = subscriptionList.filter(sub => 
      sub.type === 'bluesnap' && sub.origStatus.toUpperCase() === 'ACTIVE'
    );
    
    // Check if there's at least one active Bluesnap subscription
    const hasActiveBluesnap = activeBluesnapSubs.length > 0;
    
    // If both conditions are met, return the active Bluesnap subscriptions
    if (hasActiveChargebee && hasActiveBluesnap) {
      for (const sub of activeBluesnapSubs) {
        await dispatch(backendApiEndpoints.cancelSubscription.initiate({
          subscriptionID: sub._id,
          reason: 'Redundant subscription'
        }));
      }
    }
  }
);
