import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WhatsappContact } from '../services/whatsapp';

interface ChatListState {
    contactList: WhatsappContact[];
}

const initialState: ChatListState = { contactList: [] };

const chatListSlice = createSlice({
    name: 'chatList',
    initialState,
    reducers: {
        setContactList(state, action: PayloadAction<WhatsappContact[]>) {
            state.contactList = action.payload;
        }
    }
})

export const { setContactList } = chatListSlice.actions;
export default chatListSlice.reducer;
