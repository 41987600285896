import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton, Stack } from "@mui/material";
import { getCountOfTasksLeftToDo, switchTaskThunk } from '@reducers/NewTabFocusThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
const FocusNextOrPrevious = () => {
    const dispatch = useAppDispatch()
    const countOfTasksLeft = useAppSelector((state) => getCountOfTasksLeftToDo(state));

    const switchTask = (nextOrPrevious) => {
        dispatch(switchTaskThunk(nextOrPrevious))
    };

    return (
        <>

            {countOfTasksLeft > 1 && (

                <Stack direction="row" mt={6} justifyContent={"center"} spacing={2}>
                    <IconButton sx={{
                        color: "#fff", background: "rgba(255,255,255,0.2)", opacity: 0,
                        visibility: "hidden",
                    }} onClick={() => switchTask("previous")} className="hover-icon" size={"medium"}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton sx={{
                        color: "#fff", background: "rgba(255,255,255,0.2)", opacity: 0,
                        visibility: "hidden",
                    }} onClick={() => switchTask("next")} className="hover-icon" size={"medium"}>
                        <NavigateNextIcon />
                    </IconButton>
                </Stack>

            )}
        </>


    )
}


export default FocusNextOrPrevious