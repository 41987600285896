

import ContactAvatar from '@components/bulk-dashboard/contact-table/contact-avatar';
import ScheduledMessagesCount from "@components/scheduled-messages-count/scheduled-messages-count";
import { Box, Stack } from "@mui/system";
import { selectWhatsappContact } from "@reducers/WhatsAppSelectors";
import { WhatsappContact } from "@services/whatsapp";
import { useAppSelector } from "@store/hooks";
import moment from "moment";

interface WhatsappContactProps {
  profileImg?: string;
  whatsappId?: string;
  contactName?: string;
  message?: string;
  messageCount?: string;
  messageScheduledCount?: number;
  messageApiCount?: number;
  messageCampaignCount?: number;
  dueDate?: string;
  email?: string;
}

const WhatsappContactBox = ({ email, profileImg, message, contactName: _contactName, whatsappId, messageCount, messageScheduledCount, messageApiCount, messageCampaignCount, dueDate }: WhatsappContactProps) => {
  const whatsappContact: WhatsappContact | undefined = useAppSelector(state => selectWhatsappContact(state, whatsappId));

  const _displayName = whatsappContact?.displayName ?? ''
  const displayName = _displayName?.indexOf('+') === 0 ? (whatsappContact?.name ?? whatsappContact?.displayName) : _displayName
  const contactName = _contactName ?? displayName;
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-end"
      sx={{ maxWidth: "100%", position: 'relative'}}>
      <Stack direction="row" alignItems="center" sx={{ minWidth: "30%", maxWidth: "60%" }} >
        <ContactAvatar
          sx={{ mr: 1 }}
          contactId={whatsappId}
          size={49}
          textSize={12}
        />
        <Stack sx={{ maxWidth: '100%' }}>
          <Box sx={{
            marginLeft: "10px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}>
            {contactName}
          </Box>
          <Box sx={{
            marginLeft: "10px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}>
            {message}
          </Box>
        </Stack>
      </Stack>
      <Stack sx={{
        height: 49
      }} spacing={0.5} justifyContent={'center'} alignItems={'center'}>
        <Stack direction="row" spacing={0.5} justifyContent={'center'}>
          {messageScheduledCount ? <ScheduledMessagesCount title="SCHEDULED" countValue={messageScheduledCount} /> : null}
          {messageApiCount ? <ScheduledMessagesCount title="API" countValue={messageApiCount} /> : null}
          {messageCampaignCount ? <ScheduledMessagesCount title="CAMPAIGN" countValue={messageCampaignCount} /> : null}
        </Stack>
        <Box sx={{ fontSize: "12px", direction: "ltr", whiteSpace: "nowrap" }}>{moment(dueDate).format("HH:mm DD/MM/yyyy")}</Box>
      </Stack>
    </Stack>
  );
};

export default WhatsappContactBox;
