import { Box, Typography,styled } from '@mui/material'

const Container = styled(Box)(() => ({
    height: 60,
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid var(--label-border-color)`
}))


export default function TalkToUsFooter() {
    return (
        <Container>
            <Typography sx={{
                flex: 1,
                fontWeight: 600,
                color: '#546eff',
            }}>
                Blueticks
            </Typography>
            <Box sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Typography
                    sx={{ fontSize: 13 }}>
                    Have any question?
                </Typography>
                <Typography
                    sx={{
                        color: '#546eff',
                        ml: .2,
                        fontSize: 13, cursor: 'pointer'
                    }}>
                    talk to us
                </Typography>
            </Box>
        </Container>
    )
}