import { BlueticksForm } from "@models/form";
import { TaskColumn } from "@models/task-column";
import { createSelector } from "@reduxjs/toolkit";
import { formSelectByQuery, formSelectOneObjectByQuery } from "./DBServiceReducers";
import { dynamicColumnListForForms } from "./TaskSelectors";

export const formColumnList = createSelector( /// create a selector that updates order of column, if its missing from the form's columnData and return
  /// a list of columns that is sorted by the order of the columnData's column order
  [
    (state, columnData, boardId) => (state),
    (state, columnData, boardId) => (columnData),
    (state, columnData, boardId) => (boardId),
  ],
  (state, columnData, boardId) => {
    const columnList = dynamicColumnListForForms(state, boardId)
    const sortedColumnList = columnList?.sort((a, b) => {
      const aOrder =
        columnData === undefined ||
          columnData[a._id] === undefined ||
          columnData[a._id].order === undefined
          ? columnList.length + 1
          : columnData[a._id].order;
      const bOrder =
        columnData === undefined ||
          columnData[b._id] === undefined ||
          columnData[b._id].order === undefined
          ? columnList.length + 1
          : columnData[b._id].order;
      return aOrder - bOrder;
    })
    return sortedColumnList.map((column, index) => ({ _id: column._id, type: column.type, title: column.title } as TaskColumn));
  }
);


export const getSortedVisibleColumnList = createSelector( /// create a selector that updates order of column, if its missing from the form's columnData and return
  /// a list of columns that is sorted by the order of the columnData's column order
  [
    (state, columnData, boardId, formId) => (state),
    (state, columnData, boardId, formId) => (columnData),
    (state, columnData, boardId, formId) => (boardId),
    (state, columnData, boardId, formId) => (formId),
  ],
  (state, columnData, boardId, formId) => {
    
    const form = formSelectOneObjectByQuery(state.DBFormReducer, {
      _id: formId,
      boardId,
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    })
    const sortedColumnList = formColumnList(state, columnData, boardId)
    .filter(column => ((form?.columnData[column._id] === undefined || form?.columnData[column._id].visible === undefined) || form?.columnData[column._id].visible))

    return sortedColumnList.map((column, index) => ({ _id: column._id, type: column.type, title: column.title } as TaskColumn));
  }
);


export const formIdList = createSelector(
  [
    (state, boardId) => formSelectByQuery(state.DBFormReducer, { boardId })
  ],
  (formList: BlueticksForm[]) => {
    const formIdList = formList.map((kanban, index) => kanban._id)
    return formIdList
  }
);
// export interface FormsState {
//   formEditModeByFormId: string;
// }

// const initialState: FormsState = {
//   formEditModeByFormId: "",
// };

// const FormsSlice = createSlice({
//   name: "forms",
//   initialState,
//   reducers: {
//     setFormEditModeByFormId(state, { payload }: PayloadAction<string>) {
//       state.formEditModeByFormId = payload
//     },
//   }
// });

// export const formsActions = FormsSlice.actions;
// export default FormsSlice.reducer;
