import { Workspace } from "@models/workspace";
import { Alert, AlertColor, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@mui/material";
import { openPaymentDialogThunk } from "@reducers/PaymentFormThunks";
import { changePaymentPlan, refreshUser } from "@reducers/UserReducer";
import { activeSubscriptionSelector, selectCurrentWorkspace } from "@reducers/UserSelectors";
import { useCancelSubscriptionMutation, useGetUserPaymentParamsMutation } from "@reducers/backend-api/backend-api";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { TbArrowBigDown, TbArrowBigUp } from "react-icons/tb";
import { PlanDescriptor } from '../../config/app.config';
import classes from './bluesnap-button.module.scss';
import { paymentFormActions } from "@reducers/PaymentFormReducer";
import BTButton from "@components/bt-button/bt-button";
import { modifyPlanName } from "@services/utils";

export enum ButtonType {
    Downgrade,
    Upgrade,
    Current
}

export interface BluesnapButtonProps {
    type: ButtonType;
    planId: string;
    planName: string;
    seats: number;
    contractId: string;
    style?: React.CSSProperties | undefined;
    onClick?: (plan: PlanDescriptor) => void;
    disabled?: boolean;
}


function BluesnapButton(props: PropsWithChildren<BluesnapButtonProps>) {
    const dispatch = useAppDispatch()
    const activeSubscription = useAppSelector(activeSubscriptionSelector);
    const workspace: Workspace = useAppSelector(selectCurrentWorkspace)
    const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>();
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>();
    const [triggerCancelSubscription, { isLoading: isCancellingSubscription }] = useCancelSubscriptionMutation();
    const [changePlanLoading, setChangePlanLoading] = useState(false);

    const [
        getUserPaymentParams,
        { isLoading: paymentParamsLoading, data }
    ] = useGetUserPaymentParamsMutation();

    const isLoading = useMemo(() =>
        changePlanLoading ||
        paymentParamsLoading ||
        isCancellingSubscription,
        [changePlanLoading, isCancellingSubscription, paymentParamsLoading]);

    const [modalOpen, setModalOpen] = useState(false);
    const [planChangeConfirmed, setPlanChangeConfirmed] = useState(false);
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleConfirmPlanChange = () => {
        setPlanChangeConfirmed(true);
        setModalOpen(false);
    };

    const executePlanChange = useCallback(async () => {
        setChangePlanLoading(true);
        const res: any = await dispatch(changePaymentPlan({ productId: props.planId }));
        const { changeSuccessful } = res.payload;

        if (changeSuccessful) {
            await dispatch(refreshUser());
            dispatch(paymentFormActions.setPaymentSuccessType(props.type === ButtonType.Upgrade ? 'upgrade' : 'downgrade'));
            dispatch(paymentFormActions.setPaymentSuccess(true));
        } else {
            setSnackbarMessage(res?.payload?.data?.message ?? 'Error updating plan - please contact support');
            setSnackbarSeverity('error');
        }

        setChangePlanLoading(false);
    }, [dispatch, props.planId, props.type]);

    useEffect(() => {
        if (planChangeConfirmed) {
            executePlanChange();
            setPlanChangeConfirmed(false);
        }
    }, [executePlanChange, planChangeConfirmed]);

    useEffect(() => {
        if (props.planId) {
            getUserPaymentParams({
                productId: props.planId
            })
        }
    }, [getUserPaymentParams, props.planId])

    async function payWithBluesnap() {
        if (props.onClick) {
            props.onClick(data?.plan);
        }

        // If the subscription is active and paid via credit card we can change the plan
        if (activeSubscription && data?.subscription?.paymentSource?.creditCardInfo) {
            if (props.planId) {
                handleOpenModal();
            } else {
                // Cancel Subscription
                if (activeSubscription?._id) {
                    triggerCancelSubscription({
                        subscriptionID: activeSubscription?._id,
                        reason: 'User cancelled'
                    });
                }
            }
        } else if (props.planId) {
            // If we have a planId but the subscription is not active or not paid via credit card (PayPal...) we open the payment dialog
            dispatch(openPaymentDialogThunk({
                planId: props.planId,
                contractId: props.contractId,
                workspaceId: workspace._id.toString(),
                seats: props.seats,
            }))
        }
    }

    let className;
    switch (props.type) {
        case ButtonType.Downgrade: {
            className = classes.blueButton;
            break;
        }
        case ButtonType.Upgrade: {
            className = classes.pinkButton;
            break;
        }
        case ButtonType.Current: {
            className = classes.transparentButton;
            break;
        }

    }

    return <>
        <Snackbar
            open={!!snackbarMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={6000}
            onClose={() => {
                setSnackbarMessage(undefined);
            }}
        >
            <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
        </Snackbar>

        <Dialog open={modalOpen} onClose={handleCloseModal}>
            <DialogTitle>Confirm Plan Change</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to {props.type === ButtonType.Upgrade ? 'upgrade' : 'downgrade'} your plan to
                    <Box sx={{ fontWeight: "700" }}> {modifyPlanName(props.planName)}?</Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal} color="primary">
                    Cancel
                </Button>
                <BTButton onClick={handleConfirmPlanChange} color="primary">
                    Confirm
                </BTButton>
            </DialogActions>
        </Dialog>
        {<Button
            onClick={payWithBluesnap}
            disabled={(isLoading && !!props.planId) || props.disabled}
            startIcon={props.type === ButtonType.Upgrade ? <TbArrowBigUp /> : <TbArrowBigDown />}
            className={className}
            style={{
                minHeight: 35,
                minWidth: 200,
                maxWidth: 750,
                textTransform: 'capitalize',
                fontWeight: 800,
                padding: "10px 24px",
                borderRadius: "8px",
                fontSize: '20px',
                marginBottom: 0,
                transition: "transform 0.1s, box-shadow 0.1s",
                ...(props.style ?? {}),
            }}
        >
            {isLoading ? <CircularProgress color={props.type === ButtonType.Upgrade ? 'primary' : 'secondary'} size='32px' /> : props.children}
        </Button>}

    </>;
}

export default BluesnapButton;