import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { NotificationStack } from './notification-row';


type Props = {
    notificationId: string;
}

export default function FilesColumnNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))

    const cell = columnTypeList.find(
        (columnType) =>
            columnType.type === notification.notificationUpdateType
    );

    let status
    if (notification.oldValue && notification.newValue) { status = 'change data' }
    if (!notification.oldValue && notification.newValue) { status = 'add data' }
    if (notification.oldValue && !notification.newValue) { status = 'remove data' }
    return (
        <NotificationStack direction='row'>
            {status === 'add data' &&
                <>
                    <span>{notification.actionType} &nbsp; </span>
                    <CellWrapper
                        sx={{ display: 'contents', height: "20px" }}
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={[notification.newValue]}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                    <span> &nbsp;to &nbsp;</span>
                </>
            }
            {status === 'remove data' &&
                <>
                    <span> removed &nbsp;</span>
                    <CellWrapper
                        sx={{ display: 'contents', height: "20px" }}
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={[notification.oldValue]}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                    <span>&nbsp; from&nbsp; </span>
                </>
            }
            <span style={{ fontWeight: 'bold' }}>{notification.taskName}-</span>
            <span style={{ fontWeight: 'bold' }}>{notification.actionOnEntityData}</span>
        </NotificationStack>
    )
}