import PersonAvatar from "@components/tasks/person-avatar/person-avatar";
import { userDisplayName } from "@models/user";
import { Avatar, AvatarGroup, Box, Stack, Tooltip } from "@mui/material";
import { cellPersonListSelector } from "@reducers/BoardsSelectors";
import { NullableId } from "@reducers/backend-api/backend-api";
import { useAppSelector } from "@store/hooks";
import { memo } from "react";
interface Props {
    values: any;
    boardId: string;
}
function PersonPickerSumCell({ values, boardId }: Props) {
    const personList = useAppSelector(state => cellPersonListSelector(state, values, boardId));
    const cellPersonList = personList.length > 0 ? personList : []

    const maxAvatars = 4;
    const extraUsers = cellPersonList.length > maxAvatars ? cellPersonList.slice(maxAvatars) : [];
    const extraUsersTooltip = cellPersonList.map(user => userDisplayName(user.profile)).join(', ');

    const extraFilesTheme = {
        alignItems: "end",
        ".MuiAvatar-circular": {
            height: '20px',
            width: '20px',
            background: 'var(--primary)',
            color: 'white',
            fontSize: '10px',
        }
    }

    return (
        <Stack sx={{ position: "relative" }} alignItems={"center"} flexGrow={1} textAlign={"center"}>
              
              <Tooltip title={extraUsersTooltip} arrow placement="bottom" >
              <AvatarGroup max={maxAvatars} sx={extraFilesTheme}>
            {cellPersonList.slice(0, maxAvatars).map((person, i) => (
                <Box key={i}>
                    <PersonAvatar
                        size={22}
                        email={person?.email}
                        profileImg={person?.profile?.profileImg}
                        lastName={person?.lastName}
                        firstName={person?.firstName}
                    />
                </Box>
            ))}

            {extraUsers.length > 0 && (
                <Tooltip title={extraUsersTooltip} arrow placement="bottom" >
                    <Avatar>+{extraUsers.length}</Avatar>
                </Tooltip>
            )}
        </AvatarGroup>
        </Tooltip>
        </Stack>
    );
}

export default memo(PersonPickerSumCell)