import { updateCellValueThunk } from "@reducers/TaskThunks";
import { useCallback, useEffect, useState } from "react";
import { PickerProps } from "../columns-cells/cell-interface";
import classes from "./add_link.module.scss";
import { useAppDispatch } from "@store/hooks";
import { pickerDialogActions } from "@reducers/PickerDialogReducer";
import { ClickAwayListener } from "@mui/base";
import BTButton from "@components/bt-button/bt-button";
import { useCleanupRegister } from "./use-cleanup-register";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";

// import { Button } from "@mui/material";


// export interface Props {
//     link: string;
//     linkText: string;
//     onValueUpdate: (link: string, text: string) => void;
// }

export default function Addlink({
    cellValue: link,
    columnId,
    taskId,
}: PickerProps) {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const [linkAddress, setLink] = useState<string | undefined>();
    const [displayText, setDisplayText] = useState<string | undefined>();
    const submit = useCallback(() => {

        console.log("submit date picker")
        let url = linkAddress;
        let textToDisplay = displayText;
        if (url && !isValidHttpUrl(url)) {
            url = linkAddress.replace(/^/, 'https://');
        }
        dispatch(updateCellValueThunk({
            taskId,
            columnId,
            data: {
                value: { link: url, linkText: textToDisplay }
            }
        }));
        dispatch(pickerDialogActions.closePickerDialog());
    }, [columnId, dispatch, taskId, linkAddress, displayText]);

    function isValidHttpUrl(string) {
        try {
            const prefix = new URL(string);
            return prefix.protocol === 'http:' || prefix.protocol === 'https:';
        } catch (err) {
            return false;
        }
    }

    useCleanupRegister(() => {
        submit()
    });

    useEffect(() => {
        setLink(link?.link);
        setDisplayText(link?.linkText);
    }, [link?.link, link?.linkText]);

    return (
        <div className={classes.addLink}  >
            <label htmlFor="url">{t('addLink.url')}</label>
            <input autoFocus id="url" type="text" value={linkAddress ?? ''} placeholder={t('addLink.placeholderExample')} onChange={({ target }) => setLink(target.value ? target.value : undefined)} onKeyDown={(e) => e.key === "Enter" && submit()}
            />
            <label htmlFor="text">{t('addLink.textToDisplay')}</label>
            <input id="text" type="text" value={displayText ?? ''}
                placeholder={t('addLink.textToDisplay')} onChange={({ target }) => setDisplayText(target.value ? target.value : undefined)} onKeyDown={(e) => (e.key === "Enter") && submit()}
            />

            <BTButton color="primary" onClick={submit} sx={{
                height: "35px"
            }}>
                {t('addLink.save')}
            </BTButton>
        </div>
    );
}
