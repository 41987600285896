import { userDisplayName } from "@models/user";
import { logoutThunk } from "@reducers/UserReducer";
import { activeSubscriptionSelector, selectCurrentUser } from "@reducers/UserSelectors";
import { getRuntimeUrl } from "@services/storage";
import { modifyPlanName, openPaymentSite } from "@services/utils";
import { useAppSelector, useAppThunkDispatch } from "@store/hooks";
import classes from "./status-box.module.scss";
import { useEffect } from "react";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@services/analytics-service";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

enum PlanNames { free, scheduler, manager, campaigner }
enum FeatureNames { messages, tasks, campaigns }

interface ModalDataPlan {
    name: string;
    statuses: {
        [FeatureNames.messages]: string;
        [FeatureNames.tasks]: string;
        [FeatureNames.campaigns]: string;
    },
    image: string;
    buttonText: string;
}

export type ModalDataPlans = {
    [key in PlanNames]: ModalDataPlan
}

const modalDataPlans: ModalDataPlans = {
    [PlanNames.free]: {
        name: "Free plan",
        statuses: {
            [FeatureNames.messages]: "Up to 1 at a time",
            [FeatureNames.tasks]: "Up to 10 per contact",
            [FeatureNames.campaigns]: "Unlimited, with branding",
        },
        image: "/img/locked-free-plan.svg",
        buttonText: "Unlock all features",
    },
    [PlanNames.scheduler]: {

        name: "Scheduler plan",
        statuses: {
            [FeatureNames.messages]: "Unlimited messages!",
            [FeatureNames.tasks]: "Up to 10 per contact",
            [FeatureNames.campaigns]: "Unlimited, with branding",
        },
        image: "/img/locked-premium-plan.svg",
        buttonText: "Upgrade now",
    },
    [PlanNames.manager]: {
        name: "Manager plan",
        statuses: {
            [FeatureNames.messages]: "Unlimited messages!",
            [FeatureNames.tasks]: "Unlimited tasks!",
            [FeatureNames.campaigns]: "Unlimited, with branding",
        },
        image: "/img/locked-premium-plan.svg",
        buttonText: "Upgrade now",
    },
    [PlanNames.campaigner]: {
        name: "Campaigner plan",
        statuses: {
            [FeatureNames.messages]: "Unlimited messages!",
            [FeatureNames.tasks]: "Unlimited tasks!",
            [FeatureNames.campaigns]: "Unlimited without branding!",
        },
        image: "/img/locked-premium-plan.svg",
        buttonText: "Upgrade now",
    },

}

export interface StatusBoxParams {
    onClose?: () => void;
}

export const StatusBox = ({
    onClose
}: StatusBoxParams) => {
    const analyticsService: AnalyticsService = useAnalyticsService();

    const dispatch = useAppThunkDispatch();
    const accessToken = useAppSelector((state) => state.UserReducer.accessToken);
    const displayName = useAppSelector((state) => userDisplayName(selectCurrentUser(state)));
    const activeSubscription = useAppSelector(activeSubscriptionSelector);

    const isSubscriptionLoaded = useAppSelector((state) => state.DBSubscriptionReducer.itemsLoaded);




    let currentPlan = PlanNames.free;
    switch (activeSubscription?.planType) {
        case 'Basic': {
            currentPlan = PlanNames.scheduler;
            break;
        }
        case 'Standard': {
            currentPlan = PlanNames.manager;
            break;
        }
        case 'Pro': {
            currentPlan = PlanNames.campaigner;
            break;
        }
    }

    const modalData = {
        nameOrEmail: displayName,
        planName: modalDataPlans[currentPlan].name,
        statuses: {
            [FeatureNames.messages]: modalDataPlans[currentPlan].statuses[FeatureNames.messages],
            [FeatureNames.tasks]: modalDataPlans[currentPlan].statuses[FeatureNames.tasks],
            [FeatureNames.campaigns]: modalDataPlans[currentPlan].statuses[FeatureNames.campaigns],
        },
        image: modalDataPlans[currentPlan].image,
        buttonText: modalDataPlans[currentPlan].buttonText,
    }

    console.log("modalData", modalData);
    console.log("activeSubscription", activeSubscription);

    useEffect(() => {
        analyticsService.event("popping_up", {
            category: "purchasePopup",
            action: "popping up",
        });
    }, []);


    return (
        <>

            <div className={classes.modal}>
                <div className={classes.header}>
                    <span className={classes.icon_and_email}>
                        <img src="/img/blueticks_logo_icon.png" alt="" />
                        <span>{modalData.nameOrEmail}</span>
                    </span>
                    <button className={classes.log_out} onClick={() => {
                        analyticsService.event("click_log_out", {
                            category: "purchasePopup",
                            action: "click log out",
                        });
                        dispatch(logoutThunk())
                    }}>Log out</button>
                </div>

                {isSubscriptionLoaded ? (
                    <div className={classes.body}>
                        <div className={classes.plan}>
                            <div className={classes.plan_name}>
                                {modifyPlanName(modalData.planName)}
                            </div>
                            <div className={classes.features}>
                                <div className={classes.feature}>
                                    <div className={classes.feature_name}>Messages</div>
                                    <div className={classes.feature_status}>{modalData.statuses[FeatureNames.messages]}</div>
                                </div>
                                <div className={classes.feature}>
                                    <div className={classes.feature_name}>Tasks</div>
                                    <div className={classes.feature_status}>{modalData.statuses[FeatureNames.tasks]}</div>
                                </div>
                                <div className={classes.feature}>
                                    <div className={classes.feature_name}>Campaigns</div>
                                    <div className={classes.feature_status}>{modalData.statuses[FeatureNames.campaigns]}</div>
                                </div>
                                <div className={classes.feature}>
                                    <div className={classes.feature_name}>Seats</div>
                                    <div className={classes.feature_status}>{activeSubscription?.seatCount ?? 1}</div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.image} >
                            <img src={getRuntimeUrl(modalData.image)} alt="" />
                        </div>

                        <div className={classes.bottom}>
                            {modalData.planName.includes('Free') && (

                                <>
                                    <button
                                        className={classes.ctaButton}
                                        onClick={() => {
                                            analyticsService.event("click_unlock_all_features", {
                                                category: "upgrade",
                                                action: "click unlock all features",
                                            });
                                            openPaymentSite(accessToken);
                                        }}
                                    >
                                        {modalData.buttonText}
                                    </button>
                                    <p style={{ margin: "13px 0 4px 0" }}>
                                        Only 1 message at a time,<br /> Go premium to unlock unlimited messages.
                                    </p>
                                    <button className={classes.link} onClick={() => openPaymentSite(accessToken)}>
                                        See our pricing plans
                                    </button>
                                </>
                            )}

                            {(modalData.planName.includes('Scheduler') || modalData.planName.includes('Manager')) && (
                                <>
                                    <button
                                        className={classes.ctaButton}
                                        onClick={() => {
                                            analyticsService.event("click_unlock_all_features", {
                                                category: "upgrade",
                                                action: "click unlock all features",
                                            });
                                            openPaymentSite(accessToken);
                                        }}
                                    >
                                        {modalData.buttonText}
                                    </button>
                                    <p>
                                        Upgrade to Pro and unlock scheduling with your computer off,
                                        <br /> send campaigns without "Powered by Blueticks".
                                    </p>
                                    <button className={classes.link} onClick={() => openPaymentSite(accessToken)}>
                                        See our pricing plans
                                    </button>
                                </>
                            )}

                            {modalData.planName.includes('Campaigner') && false && (
                                <>
                                    <button
                                        className={classes.ctaButton}
                                        onClick={() => {
                                            analyticsService.event("click_unlock_yearly_subscription", {
                                                category: "upgrade",
                                                action: "click unlock yearly subscription",
                                            });
                                            openPaymentSite(accessToken);
                                        }}
                                    >
                                        Upgrade to Yearly and Save Money!
                                    </button>
                                    <p>Switch to a yearly plan and enjoy savings on your subscription.</p>
                                </>
                            )}
                        </div>


                    </div>
                ) : (
                    <div className={classes.body} style={{ height: "500px" }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ p: 1 }}
                        >
                            <CircularProgress></CircularProgress>
                        </Stack>
                    </div>
                )}
            </div >

        </>
    );
}

export default StatusBox;
