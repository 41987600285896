import { useMemo } from "react";
import queryString from "qs";
import { messageService } from "@services/messageService";
import { appConfig } from "config/app.config";

export function useRouterExtension() {
  // Helper function to create a URL from a pathname and query parameters.
  const createUrl = (pathname, query = {}) => {
    const queryStringified = queryString.stringify(query);
    return `${pathname}?${queryStringified}`;
  };

  // This function simulates navigation by opening a new tab with the specified URL.
  const navigate = (pathname, query = {}) => {
    const url = createUrl(pathname, query);

    if (chrome.tabs) {
      // We are on the extension popup
      chrome.tabs.create({
        url
      });
    } else if (chrome.extension) {
      // We are on the content script
      const link = `${appConfig.APP_ENDPOINT}${url}`;
      messageService.sendMessage('openTab', link);
    }
  };


  const router = useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      navigate,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search.substring(1)), // Convert string to object witout the "?"
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      location,
      history: navigate,
    };
  }, [location, navigate]);

  return router; // This custom hook returns the router object.

}
