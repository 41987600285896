import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { DBWorkspaceThunks, DBWorkspaceUserThunks } from "./DBServiceReducers";

export const getWorkspaceDataThunk = createAsyncThunk<Promise<any>, { workspaceId: string }, { state: RootState }>(
    "getWorkspaceDataThunk",
    async ({ workspaceId }, thunkApi) => {
        const { dispatch } = thunkApi;

        const promiseList = [];
        promiseList.push(
            dispatch(
                DBWorkspaceThunks.find({
                    returnAlsoWorkspaceOwnerName: true,
                    _id: workspaceId,
                    deleted: { $ne: true },
                    $sort: {
                        name: 1,
                    },
                    $limit: -1,
                })
            ));

        promiseList.push(
            dispatch(
                DBWorkspaceUserThunks.find({
                    deleted: { $ne: true },
                    $sort: {
                        name: 1,
                    },
                    $limit: -1,
                })
            ));

        return Promise.all(promiseList);
    }
);