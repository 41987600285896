import Wizard from "./wizard";

export interface AccountWorkspacesViewProps { }

const WizardView = () => {

  return (
    <div style={{
      position: 'absolute',
      inset: 0,
    }}>
      <img src="https://i.imgur.com/XHGBxC4.png" alt="" style={{
        position: 'absolute',
        inset: 0,
        height: '100vh',
        maxWidth: '100vw',
        filter: 'blur(10px)',
      }} />
      <Wizard />
    </div>
  );

};

export default WizardView;



