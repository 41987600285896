import PersonAvatar from "@components/tasks/person-avatar/person-avatar";
import { ToggleButton } from "@mui/material";
import { NullableId } from "@reducers/backend-api/backend-api";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { isRatingColumn, toggleFilterItemThunk } from "../../../reducers/TasksFilterPanelThunks";
import { isTempId } from "@reducers/DBServiceReducers";
import { userDisplayName } from "@models/user";
import { getAssignedUserListToTasks } from "@reducers/TaskSelectors";

type QuickFilterOptionButtonProps = {
    columnOptionInUse: any;
    columnId: NullableId;
};

export const QuickFilterOptionButton = ({ columnOptionInUse, columnId }: QuickFilterOptionButtonProps) => {
    const dispatch = useAppDispatch()
    const currentAssignedMemberList = useAppSelector(getAssignedUserListToTasks);
    const person = currentAssignedMemberList.find(member => columnOptionInUse.id === member._id)
    function toggleFilterItem(id, name, columnId, viewFilterId,) {
        dispatch(toggleFilterItemThunk({ id, name, columnId, viewFilterId }))
    }
    const ratingColumn = useAppSelector((state) => isRatingColumn(state, columnId));
    return (
        <ToggleButton
            key={columnOptionInUse?.id || columnOptionInUse?.name}
            value="check"
            disabled={isTempId(columnOptionInUse.viewFilterId)}
            selected={!!columnOptionInUse.viewFilterId}
            onChange={
                () => toggleFilterItem(
                    columnOptionInUse?.id,
                    columnOptionInUse?.name,
                    columnId,
                    columnOptionInUse?.viewFilterId
                )}
            sx={{ maxWidth: '120px', fontSize: '12px', display: 'flex', justifyContent: 'space-between', textTransform: 'unset', "&.Mui-selected":{
                background:"#64e1ff87",border:"1px solid #fff"
            } }}
        >
            {
                columnOptionInUse?.isPerson && person?.profile && (
                    <span style={{ marginInlineEnd: '6px' }}>
                        <PersonAvatar
                            size={16}
                            email={person?.email}
                            profileImg={person?.profile?.profileImg}
                            lastName={person?.profile?.lastName}
                            firstName={person?.profile?.firstName}
                           
                        />
                    </span>
                )
            }
            {columnOptionInUse?.isPerson && !person?.profile && (<PersonAvatar size={16} email={person?.email} style={{ fontSize: "14px", marginInlineEnd: '6px' }} />)}
            {
                columnOptionInUse?.bgColor && (
                    <div style={{
                        backgroundColor: columnOptionInUse?.bgColor,
                        borderRadius: '50%',
                        width: '5px',
                        height: '5px',
                        marginInlineEnd: '6px'
                    }}>

                    </div>
                )}
            <div style={{
                textOverflow: 'ellipsis',
                maxWidth: '80px',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }} key={person?._id}>
                {columnOptionInUse.isPerson &&
                    <>
                        {userDisplayName(person.profile)}
                    </>
                }
                {!columnOptionInUse.isPerson && <>
                    {columnOptionInUse?.name ? `${columnOptionInUse.name} ${ratingColumn ? 'stars' : ''}` : 'Blank'}

                </>
                }
            </div>
            <div>{columnOptionInUse?.count ?? '0'}</div>
        </ToggleButton>


    )
}
