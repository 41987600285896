import { Template } from "@models/template";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnalyticsService } from "@services/analytics-service";
import { RootState } from "@store/index";
import moment from "moment";
import { Campaign } from "../models/campaign-model";
import { accessFormPopupActions } from "./AccessFormPopupReducer";
import {
  deleteCampaign,
  nextCampaignMessage,
  resumeCampaign
} from "./backend-api/backend-api";
import {
  campaignReducerActions,
  userMessagesSelectByQuery,
  userMesssageReducerActions
} from "./DBServiceReducers";
import { ThemeType } from "./WhatsAppReducer";

export type BulkDashboardTab =
  | "audiences"
  | "timeAndDate"
  | "messageAndFiles"
  | "finish"
  | "manageAudiences"
  | "manageTemplates"
  | "manageCampaigns";


export type BulkView =
  | ""
  | "campaignManagementView" // management view
  | "newCampaignView" // new campaign view
  | "campaignBoardFromManagementView" // board from management view
  | "campaignBoardFromNewCampaignView" // board from new campaign view
  | "campaignLogsView" // logs view
  | "campaignNewAudienceFromManagementView" // new audience from management view
  | "campaignNewAudienceFromNewCampaignView" // new audience from new campaign view
  | "campaignNewAudienceFromWhatsappView" // new audience from whatsapp view
  | "campaignNewTemplateViewFromManagement" // new template view from management view
  | "campaignNewTemplateViewFromNewCampaignView" // new template view from new campaign view
  | "campaignNewTemplateViewFromWhatsappView" // new template view from whatsapp view
  | "campaignLogsView" // campaign logs view


export const defaultNewCampaign: Partial<Campaign> = {
  name: "",
  campaignStatus: 'initial',
  boardIDArray: null,
  listIndex: '',
  template: null,
  templateIndex: '',
  schedule: { isNow: true, dueDate: moment().toISOString(), cancelIfReceived: false },
  timing: {
    messageInterval: 8,
    enforceSendingWindow: false,
    automaticTiming: {
      active: true,
      age: 0,
      activity: 0,
    }
  },
};
export interface ContactListPanelState {
  selectedListId?: string;
  contextMenuAnchor?: HTMLButtonElement;
}

export interface CampaignDashboardState {
  open: boolean;
  activeTab: BulkDashboardTab;
  paymentPopupOpen: boolean;
  themeType?: ThemeType;
  contactListPanelState: ContactListPanelState;
  newCampaign?: Partial<Campaign>;
  currentTemplate?: Partial<Template>;
  isLoadingDuplicateBoard: boolean;
  isAdvancedModeOpen?: boolean;
  campaignIdForLogsView?: string;
  campaigns: Campaign[];
  isLoadingDuplicateTemplate: boolean;
  activeBulkView: BulkView;
  previousBulkView: BulkView;
  isTemplateDropdownModalOpen: boolean;
  isImportModalOpen: boolean;
  isCampaignLaunching: boolean;
}

const initialState: CampaignDashboardState = {
  open: false,
  activeTab: "audiences",
  paymentPopupOpen: false,
  contactListPanelState: {},
  newCampaign: defaultNewCampaign,
  currentTemplate: {
    name: `New Template`,
    message: "",
  } as Partial<Template>,
  isLoadingDuplicateBoard: false,
  isAdvancedModeOpen: false,
  campaignIdForLogsView: "",
  campaigns: [],
  isLoadingDuplicateTemplate: false,
  activeBulkView: "newCampaignView",
  previousBulkView: "",
  isTemplateDropdownModalOpen: false,
  isImportModalOpen: false,
  isCampaignLaunching: false,
};

export const nextCampaignMessageThunk = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("nextCampaignMessageThunk", (campaignId, thunkApi) => {
  const { dispatch } = thunkApi;

  dispatch(nextCampaignMessage.initiate(campaignId)).then((res) => {
    if ("data" in res) {
      dispatch(
        campaignReducerActions.updateOne({
          id: campaignId,
          changes: res.data.campaign,
        })
      );
      if (res.data.campaignMessages?.length > 0) {
        // const localTimeMessages = res.data.campaignMessages.map(message => ({
        //   ...message,
        //   dueDate: moment(message.dueDate).local().format()
        // }))
        dispatch(
          userMesssageReducerActions.upsertMany(res.data.campaignMessages)
        );
      }
    }
  });
});

export const resumeCampaignThunk = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("resumeCampaignThunk", (campaignId, thunkApi) => {
  const { dispatch } = thunkApi;

  dispatch(resumeCampaign.initiate(campaignId)).then((res) => {
    if ("data" in res) {
      dispatch(
        campaignReducerActions.updateOne({
          id: campaignId,
          changes: res.data.campaign,
        })
      );
      if (res.data.campaignMessages?.length > 0) {
        dispatch(
          userMesssageReducerActions.upsertMany(res.data.campaignMessages)
        );
      }
    }
  });
});

export const deleteCampaignThunk = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("deleteCampaignThunk", async (campaignId, thunkApi) => {
  const { dispatch } = thunkApi;

  const state = thunkApi.getState();
  const campaignMessages = userMessagesSelectByQuery(
    state.DBUserMessageReducer,
    {
      campaignId,
    }
  );
  dispatch(
    userMesssageReducerActions.removeMany(
      campaignMessages.map((message) => message._id)
    )
  );
  dispatch(campaignReducerActions.removeOne(campaignId));
  await dispatch(deleteCampaign.initiate(campaignId));
});


const CampaignSlice = createSlice({
  name: "Campaigns",
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<BulkDashboardTab>) => {
      state.activeTab = action.payload;
    },
    nextTab: (state) => {
      const tabs: BulkDashboardTab[] = [
        "audiences",
        "messageAndFiles",
        "timeAndDate",
        "finish",
      ];
      const currentTabIndex = tabs.indexOf(state.activeTab);
      if (currentTabIndex < tabs.length - 1) {
        state.activeTab = tabs[currentTabIndex + 1];
      }
    },
    openPaymentPopup: (state) => {
      state.paymentPopupOpen = true;
    },
    closePaymentPopup: (state) => {
      state.paymentPopupOpen = false;
    },
    updateBulkDashboardDisplay: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    openBulkDashboard: (state) => {
      state.open = true;
    },
    closeBulkDashboard: (state) => {
      state.open = false;
    },
    updateThemeType: (state, action: PayloadAction<ThemeType>) => {
      state.themeType = action.payload;
    },
    setSelectedBulkListId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.contactListPanelState.selectedListId = action.payload;
    },
    setNewCampaign: (state, action: PayloadAction<Campaign | undefined>) => {
      state.newCampaign = action.payload;
    },
    setActiveBulkView: (state, action: PayloadAction<BulkView>) => {
      state.activeBulkView = action.payload;
    },
    setCurrentTemplate: (state, action: PayloadAction<Template>) => {
      state.currentTemplate = action.payload;
    },
    setIsLoadingDuplicateBoard: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDuplicateBoard = action.payload;
    },
    setIsAdvancedModeOpen: (state, action: PayloadAction<boolean>) => {
      state.isAdvancedModeOpen = action.payload;
    },
    setCampaignIdForLogsView: (state, action: PayloadAction<string>) => {
      state.campaignIdForLogsView = action.payload;
    },
    setCampaigns: (state, action: PayloadAction<Campaign[]>) => {
      state.campaigns = action.payload;
    },
    setIsLoadingDuplicateTemplate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDuplicateTemplate = action.payload;
    },
    setIsTemplateDropdownModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isTemplateDropdownModalOpen = action.payload;
    },
    setIsImportModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isImportModalOpen = action.payload;
    },
    setIsCampaignLaunching: (state, action: PayloadAction<boolean>) => {
      state.isCampaignLaunching = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveTab, (state, action) => {
      state.activeTab = action.payload;
      // Send analytics event for setting active tab
      const analyticsService: AnalyticsService = new AnalyticsService();

      analyticsService.event('bulk_dashboard_tab_change', {
        category: 'bulk',
        action: state.activeTab,
      });
    });
  },
});

export const toggleBulkDashboardDisplay = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("toggleBulkDashboardDisplay", (accessToken, thunkApi) => {
  const state = thunkApi.getState();
  const { dispatch } = thunkApi;
  const hasUser = !!state.UserReducer.userId;

  if (!hasUser) {
    dispatch(accessFormPopupActions.setStatus("Signup"));
  } else {
    dispatch(updateBulkDashboardDisplay(!state.CampaignReducer.open));
  }
});

export const {
  setActiveTab,
  openPaymentPopup,
  closePaymentPopup,
  updateBulkDashboardDisplay,
  openBulkDashboard,
  closeBulkDashboard,
  updateThemeType,
  setSelectedBulkListId,
  setNewCampaign,
  setCurrentTemplate,
  setIsLoadingDuplicateBoard,
  setIsAdvancedModeOpen,
  setCampaignIdForLogsView,
  setCampaigns,
  setIsLoadingDuplicateTemplate,
  setActiveBulkView,
  setIsTemplateDropdownModalOpen,
  setIsImportModalOpen,
  nextTab,
  setIsCampaignLaunching
} = CampaignSlice.actions;
export default CampaignSlice.reducer;


