export async function postMessageWithResponse<T>(data: { cmd: string } & Record<string, any>): Promise<T> {
  return new Promise((resolve, reject) => {
    const channel = new MessageChannel();

    channel.port1.onmessage = (event: MessageEvent) => {
      //if (event?.data?.cmd === `${data.cmd}Response`) {
      //console.log(`postMessageWithResponse Response for :   ${data.cmd}:`, event.data);
      resolve(event?.data);
      //}
    };

    window.postMessage(data, "*", [channel.port2]);
  });
}



export async function getWaContactInfoFromListItemSelector(uniqueSelector) {
  let contactInfo = undefined;
  try {
    contactInfo = await postMessageWithResponse({ cmd: "getWaContactInfoFromListItemSelector", selector: uniqueSelector });
    //console.log("getWaContactInfoFromListItemSelector", contactInfo);
  } catch (error) {
    console.error("Error fetching contactInfo for uniqueSelector:", uniqueSelector, error);
  }
  return contactInfo;

}