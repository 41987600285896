import { Stack } from '@mui/system';
import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import RenderUpdate from '../editor/RenderUpdate';

type Props = { notificationId: string; }

export default function ExtraDataNotification({ notificationId, }: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))
    
    const actionString = () => {
        if (notification.actionType === 'mentioned') {
            return 'Mentioned you on'
        }
        if (notification.notificationUpdateType === 'new extra data') {
            return 'Left an update on'
        }
        if (notification.notificationUpdateType === 'updated extra data') {
            return 'Edited an update on'
        }
        if (notification.notificationUpdateType === 'deleted extra data') {
            return 'Ddeleted the update on'
        }
    }
    return (
        <Stack sx={{ width: '100%', }}>
            <p style={{ marginTop: '29px', marginBottom: 0 }}>&nbsp;{actionString()}&nbsp;
                <b>{notification.taskName}</b>{`: `}<br /></p>
            <RenderUpdate update={notification.actionOnEntityData} bgColor={'transparent'} />
        </Stack>
    )
}