import { Box, Stack } from '@mui/material';
import Modal from '@mui/material/Modal';
import { bulkActions } from '@reducers/BulkActionsReducer';
import { DBKanbanThunks } from '@reducers/DBServiceReducers';
import { setExtraDataOfTaskId } from '@reducers/ExtraDataReducer';
import { taskActions } from '@reducers/TasksReducer';
import { NullableId } from "@reducers/backend-api/backend-api";
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import React, { useState } from "react";
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd';
import KanbanCard from './kanban-card';
import KanbanColumn from './kanban-column';
import { kanbanColumnOptionsSorted } from '@reducers/TaskSelectors';
import { updateTaskOrderValueThunk } from '@reducers/TaskThunks';

type Props = {
    kanbanId: NullableId;
}

export default function Kanban({ kanbanId }: Props) {
    const dispatch = useAppDispatch()
    const columnOptions = useAppSelector((state: RootState) => kanbanColumnOptionsSorted(state, kanbanId))
    const [open, setOpen] = useState(false);
    const setKanbanCard = (event: React.MouseEvent<HTMLElement>, taskId) => {
        dispatch(taskActions.setTaskViewSideDrawerOpenWith(""));
        dispatch(taskActions.toggleTaskModal(true));

        // set taskId for extra data
        dispatch(setExtraDataOfTaskId(taskId));
    }

    const handleOnDragEnd = (result: DropResult, provided: ResponderProvided) => {

        if (!result.destination) {
            return;
        }

        if (result.source.droppableId === "label") {

            const [_, optionId] = result.draggableId.split("option_List_")
            const option = columnOptions.find((option) => {
                return option._id === optionId
            })
            const finalOrder = [...columnOptions.filter(option => option._id && option._id !== optionId)]

            finalOrder.splice(result.destination.index, 0, option);

            let columnsOrder = {}
            const newColumnsOrder = finalOrder.map((columnOption, columnOptionListIndex) => ({
                NullableId: columnOption._id, number: columnOptionListIndex
            }))
            for (let i in newColumnsOrder) {
                columnsOrder = {
                    ...columnsOrder,
                    [newColumnsOrder[i].NullableId]: newColumnsOrder[i].number
                }
            }

            dispatch(
                DBKanbanThunks.patch({
                    entity: {
                        _id: kanbanId,
                        columnsOrder: columnsOrder,
                    }
                })
            )

        }
        else {
            const [___, option_value] = result.destination.droppableId.split("option_list_")
            const [_, taskId] = result.draggableId.split('task_')
            const option = columnOptions.find((option) => {
                return option._id === option_value
            })
            const valueCheck = (option.isDefaultLabel === true)

            dispatch(updateTaskOrderValueThunk({
                taskId: taskId,
                optionId: option_value,
                destinationIndex: result.destination.index,
                isDefault: valueCheck,
                kanbanId,
            }))

        }
    }


    return (
        <>
            <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                zIndex: open ? 9 : -9,
            }} />
            <DragDropContext onDragEnd={(result: DropResult, provided: ResponderProvided) => handleOnDragEnd(result, provided)}>

                <Droppable droppableId='label' direction="horizontal">
                    {(provided) => (
                        <Box {...provided.droppableProps} ref={provided.innerRef} sx={{
                            p: 5,
                        }}>

                            <Stack direction="row" spacing={2} sx={{
                                height: '68vh',
                                overflow: 'auto',
                            }}>
                                {
                                    columnOptions
                                        .map((option, index) => {
                                            return (
                                                <Draggable
                                                    key={`${option._id}`}
                                                    draggableId={`option_List_${option._id}`}
                                                    index={index}
                                                    isDragDisabled={!option._id}>
                                                    {(provided, snapshot) => (
                                                        <KanbanColumn
                                                            snapshot={snapshot}
                                                            provided={provided}
                                                            option={option}
                                                            kanbanId={kanbanId}
                                                            setKanbanCard={setKanbanCard}
                                                            key={index}
                                                        />
                                                    )
                                                    }
                                                </Draggable>
                                            )
                                        })}
                                {provided.placeholder}
                            </Stack>
                        </Box>
                    )}
                </Droppable>
            </DragDropContext >

            <Modal
                open={open}
                onClose={() => {

                    setOpen(false)
                    dispatch(bulkActions.setIsBulkActionsDialogOpen(false))
                    dispatch(bulkActions.toggleMoveTo(false))
                    dispatch(bulkActions.setMovingTo(""))
                    dispatch(bulkActions.setBulActionsMoveToId(''))
                    taskActions.toggleTaskMenu('')
                }}
                sx={{
                    "maxWidth": "1000px",
                    "minWidth": "800px",
                    "margin": "auto",
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                }}
            >
                <div><KanbanCard /></div>
            </Modal>
        </>
    )
}
