import PersonAvatar from "@components/tasks/person-avatar/person-avatar";
import { IconButton, Menu } from "@mui/material";
import { selectCurrentWorkspace, selectUserObject } from "@reducers/UserSelectors";
import { useAppSelector } from "@store/hooks";
import * as React from "react";
import SmallUserMenu from "./small-user-menu";
import styles from "./user-control-panel.module.scss";
import { BsThreeDots } from "react-icons/bs";
import { Box } from "@mui/system";
import { userDisplayName } from "@models/user";

export interface UserControlPanelProps {
  isSideMenuOpen: boolean;
  readOnly?: boolean;
}

const UserControlPanel = ({ isSideMenuOpen,readOnly = false }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const currentWorkspace = useAppSelector((state) => selectCurrentWorkspace(state));
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const direction = useAppSelector((state) => state.UserReducer.direction);

  const user = useAppSelector(selectUserObject);
  console.log(`user control panel rendering`);

  return (
    <div className={styles.mainUserContainer} style={{pointerEvents:readOnly ? "none" : "all"}}>
      <Menu 
        sx={{
          "& .MuiPaper-elevation": {
            boxShadow: "0 5px 12px var(--shadow-default-color)",
            borderRadius: "10px !important",
          }
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <SmallUserMenu />
      </Menu>

      <div className={styles.user_container}>
        <IconButton
          size="small"
          aria-label="display more actions"
          edge="end"
          color="inherit"
          className={styles.user_avatar}
          onClick={handleClick}
        >
          <PersonAvatar
            size={44}
            email={user?.email}
            profileImg={user?.profileImg}
            lastName={user?.lastName}
            firstName={user?.firstName}
          />
        </IconButton>
        {isSideMenuOpen && (
          <span onClick={handleClick} className={styles.user_name}>
            {userDisplayName(user)}
            <span onClick={handleClick} className={styles.workspace_name}>
              {currentWorkspace?.name}
            </span>
          </span>
        )}
      </div>

      <Box sx={{ marginInlineStart:"auto" }}>
        <button onClick={handleClick} className={styles.board_item_menu} data-testid="three-dots-button">
          <span style={{ display: isSideMenuOpen ? "contents" : "none" }}>
            <BsThreeDots size={35} color={"var(--primary)"} />
          </span>
        </button>
      </Box>
    </div>
  );
};
export default UserControlPanel;
