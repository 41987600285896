import { Box, Stack } from '@mui/material'
import { tasksSelectOneFieldById } from '@reducers/DBServiceReducers'
import { getIsShowSubTasks, getOneFieldFromTaskGroupByTaskId, isLastTaskInGroup } from '@reducers/TaskSelectors'
import { checkIfMenuActiveTaskIdInBulkAction } from '@reducers/TaskThunks'
import { taskActions } from '@reducers/TasksReducer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { BsThreeDots } from 'react-icons/bs'
import { DEFAULT_ACCENT_COLOR } from 'theme/colors'
import classes from "../../task-row/task_row.module.scss"
import { CellProps } from '../cell-interface'

export default function RowMenuCell({
    taskId,
    isOnKanban,
}: CellProps) {
    const dispatch = useAppDispatch()
    const menuActiveTaskId = useAppSelector((state) => state.TasksReducer.menuActiveTaskId)
    const colorAccent = useAppSelector((state) => getOneFieldFromTaskGroupByTaskId({ state, taskId, field: 'colorAccent' }))
    const isLastInGroup = useAppSelector((state) => isLastTaskInGroup(state, taskId))
    const parentTaskId = useAppSelector((state) => tasksSelectOneFieldById(state.DBTasksReducer, taskId, "parentTaskId"))
    const isSubtask = parentTaskId ? true : false;
    const isShowSubTasks = useAppSelector((state) => getIsShowSubTasks({ state, taskId }))
    const color = colorAccent ?? DEFAULT_ACCENT_COLOR;
    const direction = useAppSelector((state) => state.UserReducer.direction);

    const currentDraggedId = useAppSelector((state) => state.TasksReducer.currentDraggedId)

    const toggleTaskMenu = () => {
        dispatch(taskActions.toggleTaskMenu(menuActiveTaskId ? '' : taskId));
        dispatch(checkIfMenuActiveTaskIdInBulkAction());
    }

    return (
        <Stack direction="row" alignItems="center">
            <Box>
                <Stack
                    onClick={toggleTaskMenu}
                    alignItems="center"
                    width={!isOnKanban ? 40 : 24}
                    // style={{
                    //     marginRight: !isOnKanban ? 0 : 6,
                    // }}
                    className={
                        classes.task_row_menu_button + " " +
                        (isOnKanban ? classes.task_row_menu_button_in_kanban : "")
                    } >
                    <BsThreeDots size={20} id={`task-button-${taskId}`} />
                </Stack>
            </Box>
            {!isOnKanban ? (
                <Stack alignItems="center"
                    style={{
                        height: isLastInGroup && !isShowSubTasks ? 39 : 40,
                        backgroundColor: isSubtask ? "var(--task-background-color)" : color,
                        width: isSubtask ? 3 : isLastInGroup && !isShowSubTasks ? 24 : 5,
                        flexShrink: 0,
                        position: 'relative',
                        insetInlineStart: isSubtask ? 2 : 0,
                        borderInlineStart: isSubtask ? `2px solid ${color}` : "none",
                        borderBottomLeftRadius: direction === 'ltr' ? !isSubtask && isLastInGroup && !isShowSubTasks && currentDraggedId !== taskId && 12 : undefined,
                        borderBottomRightRadius: direction === 'rtl' ? !isSubtask && isLastInGroup && !isShowSubTasks && 12 : undefined
                    }}>
                </Stack>) : ""}
        </Stack >
    )
}