import { ReactComponent as WaGroupSVG } from '@assets/waGroup.svg';
import { ReactComponent as WaPersonSVG } from '@assets/waPerson.svg';
import RowStack from '@components/common/row-stack/RowStack';
import PersonAvatar from '@components/tasks/person-avatar/person-avatar';
import { ColumnData } from '@components/virtualized-table/virtualized-table';
import { MessageLog } from '@models/message-log';
import { Box, Chip, Stack, SxProps, Theme } from '@mui/material';
import { closeBulkDashboard } from '@reducers/CampaignReducer';
import { messageLogSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { selectMergedContactMap } from '@reducers/WhatsAppReducer';
import { calculatedName, selectProfilePic } from '@reducers/WhatsAppSelectors';
import { focusContactThunk, getProfilePicUrlThunk } from '@reducers/WhatsappThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';

const tableCellStyle: SxProps<Theme> = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'var(--text-color)',
    fontWeight: 'bold'
}


type Props = {
    itemId: string;
    index: number;
}

export default function LogDialogRow({
    itemId,
    index,
}: Props) {
    const dispatch = useAppDispatch();
    const rowData = useAppSelector((state) => messageLogSelectOneObjectByQuery(state.DBMessageLogReducer, {
        $or: [
            { deleted: { $exists: false } },
            { deleted: false }
        ],
        _id: itemId,
    }))
    const mergedContactMap = useAppSelector((state) => selectMergedContactMap(state));

    const whatsappId = rowData?.message?.contactList?.[0]?.id
    const isGroup = whatsappId?.includes('@g.us')
    const profilePicUrl = useAppSelector((state: RootState) => selectProfilePic(state, whatsappId));

    useEffect(() => {
        dispatch(getProfilePicUrlThunk(whatsappId));
    }, [dispatch, whatsappId]);



    const contactPhone = () => {
        if (rowData?.message?.contactList?.[0]?.id.includes('@c.us')) {
            const phone = rowData?.message?.contactList?.[0]?.id?.replace('@c.us', '');
            return phone ? ` +${phone}` : '';
        }
        return ''
    }


    const statusField = (messageLog: MessageLog) => {
        let _statusField;

        switch (messageLog.status) {
            case 'pending':
            case 'delivered':
            case 'sent': {
                _statusField = <Stack sx={{ justifyContent: 'end', }}>
                    <RowStack sx={{ fontWeight: 400, }} spacing={1}>
                        <span>{moment(messageLog.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                        {<Chip sx={{ 
                            cursor: 'pointer',
                            '& .MuiChip-label': {
                                color: 'var(--text-color)'
                            },
                        }} label={messageLog.status} />}
                    </RowStack>
                </Stack>
                break;
            }
        }

        return _statusField;
    }



    const columnList: ColumnData<MessageLog>[] = useMemo(() => {
        let _columnList = [
            { type: 'profileImg', label: '', valueFn: () => '', dataKey: 'profileImg' },
            { type: 'field', label: 'DISPLAY NAME', path: 'displayName', dataKey: 'displayName', ml: 2 },
            { type: 'field', label: 'STATUS', path: 'status', dataKey: 'status', valueFn: statusField, ml: 'auto' },
        ];

        return _columnList;
    }, []);


    const focusWAContact = () => {
        const contactId = rowData?.message?.contactList?.[0]?.id;
        if (contactId) {
            dispatch(focusContactThunk(contactId));
            dispatch(closeBulkDashboard());
        }
    };

    return (
        <Stack
            direction='row'
            key={index}
            onClick={focusWAContact}    
            sx={{
                p: 2,
                borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                alignItems: 'center',
                cursor: 'pointer',
            }}>
            {columnList.map((column, cellIndex) => {
                let cellContent: React.ReactNode;

                switch (column.type) {
                    case 'field': {
                        switch (column.path) {
                            case 'displayName': {
                                cellContent = <span >
                                    {calculatedName(mergedContactMap[rowData?.message?.contactList?.[0]?.id])}
                                    {contactPhone()}
                                </span>;
                                break;
                            }
                            case 'status': {
                                cellContent = <span>{column.valueFn(rowData)}</span>;
                                break;
                            }
                        }

                        break;
                    }
                    case 'profileImg': {
                        const [firstName, lastName] = (calculatedName(mergedContactMap[rowData?.message?.contactList?.[0]?.id]) ?? '').split(' ')
                        cellContent =
                            <>
                                {profilePicUrl &&
                                    <PersonAvatar
                                        textSize={16} size={55}
                                        profileImg={profilePicUrl}
                                        lastName={lastName ?? ''}
                                        firstName={firstName ?? ''}
                                        bgcolor='#42d871'
                                    />
                                }
                                {
                                    !profilePicUrl && isGroup &&
                                    <WaGroupSVG />
                                }
                                {
                                    !profilePicUrl && !isGroup &&
                                    <WaPersonSVG />
                                }
                            </>
                        break;
                    }
                }
                return (
                    <RowStack sx={{
                        ...tableCellStyle,
                        borderBottom: 'none',
                        ml: column.ml,
                    }}
                        key={cellIndex}
                    >
                        {cellContent}
                    </RowStack>
                );
            })}
        </Stack>
    )
}