import { BTPaper } from "@components/atoms/bt-paper/bt-paper";
import { AttachFile } from "@mui/icons-material";
import { memo, useMemo } from "react";
import { theme } from "../../theme";
import classes from "./attachment-preview.module.scss";

export interface AttachmentPreviewProps {
  attachment?: File | null;
  attachmentUrl?: string;
  attachmentType?: string;
  attachmentName?: string;
  isFromCampaigns?: boolean;
}

export interface AttachmentPreviewState {
  attachmentUrl?: string;
}

export const AttachmentPreview = (props: AttachmentPreviewProps) => {
  const blobUrl = useMemo(() => props.attachment ? URL.createObjectURL(props.attachment) : undefined, [props.attachment]);
  const attachmentUrl = useMemo(() => props.attachmentUrl ?? blobUrl, [blobUrl, props.attachmentUrl])
  const attachmentType = useMemo(() => props.attachment?.type ?? props.attachmentType, [props.attachment?.type, props.attachmentType]);

  const attachmentPreview = () => {
    if (attachmentUrl) {
      if (
        ["image/png", "image/jpeg", "image/gif", "image/bmp", "image/webp"].indexOf(
          attachmentType ?? ""
        ) > -1
      ) {
        return (
          <>
            <img style={{ height: props?.isFromCampaigns && '74px', }} src={attachmentUrl} alt="" />
          </>
        );
      } else if ((attachmentType?.indexOf("video") ?? -1) > -1) {
        return (
          <video controls>
            <source src={attachmentUrl} type={attachmentType}></source>
          </video>
        );
      } else {
        return (
          <div className={classes.fileAttachmentContainer}>
            <BTPaper
              style={{
                padding: theme.spacing(1),
                display: "flex",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <AttachFile />
              {
                <span>
                  {props.attachmentName ?? props.attachment?.name}
                </span>
              }
            </BTPaper>
          </div>
        );
      }
    } else {
      return <div></div>;
    }
  }

  return (
    <div className={classes.imageContainer}>{attachmentPreview()}</div>
  )
}

export default memo(AttachmentPreview);