import NewTaskGroupButton from "@components/tasks-action-bar/add-task-group-button/new-task-group-button";
import NewBoardButton from "@views/boards-panel/new-board-button";

import { ReactElement } from "react";
import { BiTaskX } from "react-icons/bi";
import NewFormButton from "./form/new-form-button";
import NewTimelineColumnButton from "./gantt/NewTimelineColumnButton";
import NewPriorityColumnButton from "./kanban/NewPriorityColumnButton";
import NewKanbanButton from "./kanban/new-kanban-button";
import classes from "./tasks-panel.module.scss";
import NewComplexityColumnButton from "./workload/new-complexity-column-button";
import NewPersonColumnButton from "./workload/new-person-column-button";
import { useAppSelector } from "@store/hooks";
import { getBlueticksThemeSelector } from "@reducers/BoardsSelectors";

type Props = {
  textValue: string,
  height: string,
  target: string
}

export default function GeneralScreenWithButtonAndText({ textValue, target, height }: Props): ReactElement {
  const theme = useAppSelector((state) => getBlueticksThemeSelector(state))

  return (
    <div className={classes.emptyBoard} style={{ height, backgroundColor: theme?.boards?.tasksPanel?.background }}>
      <div>
        <div className={classes.taskXIcon}>
          <BiTaskX />
        </div>
        <p style={{ margin: "8px 0", whiteSpace: 'nowrap' }}
          dangerouslySetInnerHTML={{ __html: textValue }}
        />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {target === 'group' && <NewTaskGroupButton position="top" />}
          {target === 'board' && <NewBoardButton />}
          {target === 'status' && <NewPriorityColumnButton />}
          {target === 'kanbanStatus' && <NewKanbanButton />}
          {target === 'formStatus' && <NewFormButton />}
          {target === 'ganttStatus' && <NewTimelineColumnButton />}
          {target === 'complexity' && <NewComplexityColumnButton />}
          {target === 'person' && <NewPersonColumnButton />}
          {target === 'personAndComplexity' && <><NewPersonColumnButton /><NewComplexityColumnButton /></>}
        </div>
      </div>
    </div>
  );
}

