import BTButton from '@components/bt-button/bt-button';
import FilesIcons from '@components/files-icons/filesIcons';
import { Box, CircularProgress, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import { _TasksExtraDataFilesThunks } from '@reducers/DBServiceReducers';
import { mediaManager } from '@reducers/MediaManagerReducer';
import { pickerDialogActions } from '@reducers/PickerDialogReducer';
import { getExtraDataMergedFiles } from '@reducers/TaskSelectors';
import { createOrUpdateTaskExtraDataFile } from '@reducers/TaskThunks';
import { handleUpload } from '@services/upload';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { namespace } from 'config/constants';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from "jszip-utils";
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillFileZip } from 'react-icons/ai';


const titleStyle = {
    fontSize: "12px",
    color: "#917f73",
    fontWeight: "600",
    //underline
    textDecoration: "underline",
}
export const ExtraDataFiles = () => {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const totalFiles: any = useAppSelector(getExtraDataMergedFiles);
    const allFiles = [totalFiles.tasks, totalFiles.updates, totalFiles.generalFiles].flat();



    const fileInputRef = useRef(null);

    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };
    const [isDragging, setIsDragging] = useState(false);

    const uploadFile = async (base64file: string, mimeType: string) => {
        try {
            const response: any = await handleUpload(base64file, mimeType);
            if (response.uploadResponse.url) {
                return response.image_url;
            }
        } catch (err) {
            console.log(err);
        }
    };

    const uploadFiles = useCallback(async (files) => {
        if (files.length) {
            const promises = files.map((file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = async () => {
                        const base64file = reader.result;
                        const urlLink = await uploadFile(base64file.toString(), file.type);
                        const fileDetails = {
                            url: urlLink,
                            type: file.type,
                            filename: file.name,
                            // updatedAt: moment().format('DD/MM/YYYY HH:mm:ss'),
                        };
                        resolve(fileDetails);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            });
            return Promise.all(promises);
        }
    }, []);

    function openMediaManager(e, i, fileCategory) {
        e.stopPropagation();

        let relativeIndex = i;
        if (fileCategory === 'updates') {
            relativeIndex += totalFiles.tasks.length;
        } else if (fileCategory === 'generalFiles') {
            relativeIndex += totalFiles.tasks.length + totalFiles.updates.length;
        }

        const currentFilesAndIndex = {}
        currentFilesAndIndex["index"] = relativeIndex;
        currentFilesAndIndex["shown"] = true;
        currentFilesAndIndex["filesTotal"] = allFiles

        currentFilesAndIndex["isViewOnly"] = true;

        dispatch(
            mediaManager.setSelectedCellFilesAndCurrentindex(currentFilesAndIndex)
        );

        dispatch(pickerDialogActions.closePickerDialog());
    }

    const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        const selectedFiles = Array.from(event.target.files || []);
       
        const uploadedFilesDetails = await uploadFiles(selectedFiles);
        await dispatch(createOrUpdateTaskExtraDataFile(uploadedFilesDetails))
        // const res = await dispatch(fetchAndMergeData(displayExtraDataOfTaskById)).unwrap();
        //setTotalFiles(res);
        setIsLoading(false);

    };

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        setIsLoading(true);
        setIsDragging(false);
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files || []);
        const uploadedFilesDetails = await uploadFiles(droppedFiles);
        await dispatch(createOrUpdateTaskExtraDataFile(uploadedFilesDetails))

        setIsLoading(false);
    };

    function downloadZip() {
        const zip = new JSZip();
        let count = 0;
        const allFiles = [totalFiles.tasks, totalFiles.updates, totalFiles.generalFiles].flat();

        allFiles.forEach(function (file) {
            var filename = file.filename;
            JSZipUtils.getBinaryContent(file.url, function (err, data) {
                if (err) {
                    throw err;
                }
                zip.file(filename, data, { binary: true });
                count++;
                if (count === allFiles.length) {
                    zip.generateAsync({ type: 'blob' }).then(function (content) {
                        FileSaver.saveAs(content, 'download.zip');
                    });
                }
            });
        });
    }



    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
        setTimeout(() => {
            setIsDragging(false);
        }, 4000);
    };

    return (
        <Stack
            mt={2}
            onDrop={handleDrop}
            onDragEnter={handleDragOver}
            style={{ minHeight: "300px", padding: '1rem', border: isDragging ? '2px dashed lightgrey' : "", position: "relative" }}
        >
            <Stack sx={{ mb: 2 }} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                {isDragging &&

                    <Stack justifyContent={"center"} alignItems={"center"} sx={{
                        background: "#fffffff0", position: "absolute",
                        zIndex: 9,
                        right: 0,
                        left: 0,
                        top: 0,
                        bottom: 0
                    }}> {t('extraDataFiles.dropHereToUpload')}</Stack>
                }
                <BTButton onClick={handleFileButtonClick} >
                    {t('extraDataFiles.dropHereToUpload')}
                </BTButton>

                {allFiles && allFiles.length > 1 && (
                    <Tooltip title={`${t('extraDataFiles.download')} ${allFiles.length} ${t('extraDataFiles.filesZip')}`} arrow placement="top" disableInteractive>
                        <IconButton size="medium" onClick={() => downloadZip()}>
                            <AiFillFileZip />
                        </IconButton>
                    </Tooltip>
                )}

            </Stack>
            <input ref={fileInputRef}
                type="file" hidden multiple onChange={handleFileInputChange} />

            {isLoading ? (
                <Stack sx={{ height: "250px" }} justifyContent={"center"} alignItems={"center"}> <CircularProgress /></Stack>
            ) : (
                <Stack spacing={2}>
                    {totalFiles?.tasks?.length > 0 &&

                        (<>
                            <Box sx={titleStyle}>{t('extraDataFiles.tasksCellsFiles')}</Box>
                            <Grid alignItems={"center"} container gap={2}>
                                {totalFiles?.tasks.map((file, index) => (
                                    <Grid item xs={1} key={`tasks_${index}`}>
                                        <Box sx={{ cursor: "pointer" }} onClick={(e) => {
                                            openMediaManager(e, index, "tasks");
                                        }}>
                                            <FilesIcons style={{ width: 30, height: 30 }} file={file} />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                        )

                    }

                    {totalFiles?.updates?.length > 0 &&


                        <>
                            <Box sx={titleStyle}>{t('extraDataFiles.updatesFiles')}</Box>

                            <Grid alignItems={"center"} container gap={2}>
                                {totalFiles?.updates.map((file, index) => (
                                    <Grid item xs={1} key={`updates${index}`}>
                                        <Box sx={{ cursor: "pointer" }} onClick={(e) => {
                                            openMediaManager(e, index, "updates");
                                        }}>
                                            <FilesIcons style={{ width: 30, height: 30 }} file={file} />

                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </>

                    }

                    {totalFiles?.generalFiles?.length > 0 &&


                        <>
                            <Box sx={titleStyle}>{t('extraDataFiles.generalFiles')}</Box>

                            <Grid alignItems={"center"} container gap={2}>

                                {totalFiles?.generalFiles.map((file, index) => (
                                    <Grid item xs={1} key={`General${index}`}>
                                        <Box sx={{ cursor: "pointer" }} onClick={(e) => {
                                            openMediaManager(e, index, "generalFiles");
                                        }}>
                                        
                                            <FilesIcons style={{ width: 30, height: 30, }} file={file} />
                                           

                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    }
                </Stack>
            )}
        </Stack>
    );

}