import { TasksGroup } from "@models/task-group";
import { ClickAwayListener, Popper } from "@mui/material";
import { DBTaskgroupsThunks, taskgroupsSelectors } from "@reducers/DBServiceReducers";
import { pickerDialogActions } from "@reducers/PickerDialogReducer";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { ReactElement } from "react";
import { CirclePicker } from "react-color";
import { groupcolors } from "theme";
import classes from "./color-picker.module.scss";

export default function GroupColorPicker(): ReactElement {
  const dispatch = useAppDispatch()
  const activeGroupId = useAppSelector((state) => state.PickerDialogReducer.activeGroupId)
  const tasksGroup: TasksGroup = useAppSelector((state) =>
    taskgroupsSelectors.selectById(state, activeGroupId)
  );

  const id = activeGroupId ? 'group-color-picker-popper' : undefined;

  let ColorPickerAnchorEl
  ColorPickerAnchorEl = document.getElementById(`group_color_picker_${activeGroupId}`)

  function handleOnChange({ hex }) {
    console.log(hex)
    dispatch(DBTaskgroupsThunks.patch({
      entity: { ...tasksGroup, colorAccent: hex }
    }))
    dispatch(pickerDialogActions.setActiveGroupId(''))
  }

  return (<>
    {activeGroupId && (
      <ClickAwayListener onClickAway={() => dispatch(pickerDialogActions.setActiveGroupId(''))}>
        <div style={{ position: 'relative', zIndex: 1300 }}>
          <Popper
            id={id}
            open={true}
            anchorEl={ColorPickerAnchorEl}
            style={{ zIndex: 1300, left:-56 }}
          >
            <div
              className={classes.pickerContainer}
            // style={{ borderRight: `3px solid ${currentColor || '#53bdeb'}` }}
            // id="group_header"
            >
              <CirclePicker
                color={tasksGroup?.colorAccent ?? ''}
                onChange={handleOnChange}
                width="180px"
                circleSize={20}
                circleSpacing={10}
                colors= {groupcolors}
              />
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
    )}

  </>

  );
}
