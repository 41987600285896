import { useSelector } from "react-redux";
import closeBillingModal from "../../../assets/icons/closeBillingModal.png";
import { User, userDisplayName } from "../../../models/user";
import classes from "../account-view/account-view.module.scss";

import BTButton from "@components/bt-button/bt-button";
import BTDrawer from "@components/bt-drawer/bt-drawer";
import EditContactDetailsForm, { countryList } from "@components/edit-contact-details-form/edit-contact-details-form";
import InvoiceTable from "@components/invoice-table/invoice-table";
import ReusableModal from "@components/modals/reusable-modal";
import { getCreditCardLogo } from "@components/payment-form/payment-form";
import TrialProgressBar from "@components/trial-progress-bar/trial-progress-bar";
import { ShopperInfo } from "@models/shopper-info";
import { Alert, Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { DBSubscriptionThunks } from "@reducers/DBServiceReducers";
import { MODAL, modalActions } from "@reducers/ModalReducer";
import { paymentFormActions } from "@reducers/PaymentFormReducer";
import { openPaymentDialogThunk } from "@reducers/PaymentFormThunks";
import { cancelSubscriptionThunk, updateChargeDateThunk } from "@reducers/UserReducer";
import { activeSubscriptionSelector, selectUserObject } from "@reducers/UserSelectors";
import { useGetShopperInfoMutation, useGetWorkspaceInvoicesMutation, useUpdateShopperInfoMutation } from "@reducers/backend-api/backend-api";
import { useRouter } from "@reducers/hooks/use-router";
import { modifyPlanName } from "@services/utils";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { PlanDescriptor } from "config/app.config";
import { namespace } from "config/constants";
import moment from "moment";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface AdminBillingViewProps {
  readOnly?: boolean;

}

export interface AdminBillingViewState {
  user?: User | null | undefined;
  loading: boolean;
}

interface BillingPaymentMethod {
  cardType: string;
  last4digits: string;
  isDefault?: boolean;
  isBackup?: boolean;
}

const AdminBillingView = ({ readOnly }: AdminBillingViewProps) => {
  const { t } = useTranslation(namespace);
  const router = useRouter();
  const user = useSelector(selectUserObject);
  const workspaceSubscription = useAppSelector(activeSubscriptionSelector) ?? null;
  const [getWorkspaceInvoices, { data: workspaceInvoices }] = useGetWorkspaceInvoicesMutation();
  const [getShopperInfo, { data: shopper, isLoading: getShopperInfoLoading }] = useGetShopperInfoMutation();
  const [updateShopperInfo, { isLoading: updateShopperInfoLoading }] = useUpdateShopperInfoMutation();
  const creditCardDetailsUpdated = useAppSelector(state => state.PaymentFormReducer.creditCardDetailsUpdated)
  const [subscriptionCancelling, setSubscriptionCancelling] = useState(false);
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  const [drawerView, setCurrentDrawerView] = useState<string>();
  const [nextChargeDate, setNextChargeDate] = useState('');
  const today = new Date().toISOString().split('T')[0];
  const handleDateChange = (event) => {
    setNextChargeDate(event.target.value);
  };

  const [invoice] = workspaceInvoices?.invoiceList ?? [];

  const plan: PlanDescriptor = workspaceSubscription?.plan as PlanDescriptor;

  const billingType = useMemo(() => plan?.intervalUnit === 'month' ? t('adminBillingView.motnhly') : t('adminBillingView.yearly'), [plan?.intervalUnit, t])
  const dispatch = useAppDispatch()

  const isLoading = getShopperInfoLoading || updateShopperInfoLoading;

  useEffect(() => {
    dispatch(DBSubscriptionThunks.find({}));
    getWorkspaceInvoices();
    getShopperInfo();
  }, [])

  useEffect(() => {
    if (creditCardDetailsUpdated) {
      getShopperInfo();
      dispatch(paymentFormActions.setCreditCardDetailsUpdated(false));

    }
  }, [creditCardDetailsUpdated, dispatch, getShopperInfo])

  //const creditCard = invoice?.["financial-transactions"]?.["financial-transaction"]?.["credit-card"] ?? {};
  const creditCard = shopper?.subscription?.["credit-card"]

  //const cardType = creditCard["card-type"] ?? invoice?.["financial-transactions"]?.["financial-transaction"]?.["payment-method"];
  const cardType = creditCard?.["card-type"] ?? (shopper?.shopper?.subscription?.['paypal-subscription'] ? 'PayPal' : undefined);
  const creditCardLogo = useMemo(() => cardType ? getCreditCardLogo(cardType) : undefined, [cardType]);

  const noSubscription = (user && !workspaceSubscription) || (!invoice && !isLoading);

  const shopperContactInfo = useMemo(() => {
    return shopper?.shopper?.["shopper-info"]?.["shopper-contact-info"];
  }, [shopper]);

  const firstName = shopperContactInfo?.['first-name'];
  const lastName = shopperContactInfo?.['last-name'];
  const fullName = [firstName, lastName].filter(x => x).join(' ') || userDisplayName(user);
  const address1 = shopperContactInfo?.address1;
  const address2 = shopperContactInfo?.address2;
  const zip = shopperContactInfo?.zip?.toString();
  const countryCode = user?.invoiceDetails?.country || shopperContactInfo?.country;
  const countryObject = countryList.find(country => country.isoCode.toLowerCase() === countryCode?.toLowerCase());
  const companyName = shopperContactInfo?.['company-name'];
  const vatId = user?.invoiceDetails?.vatId;

  const fullAddress = [address1, zip, countryObject?.name].filter(x => x && x !== '--').join(', ');
  const invoiceDetails: ShopperInfo = {
    firstName,
    lastName,
    companyName,
    address1,
    address2,
    zip,
    vatId,
    country: countryObject?.isoCode
  }

  const planName = shopper?.planName;

  const toggleDrawer = (value) => {
    setCurrentDrawerView(value)
    setDrawerStatus(!drawerStatus);
  };

  const handleDateChangeSubmit = async () => {
    const formattedDate = moment(nextChargeDate).format('DD-MMM-YY');
    let res = await dispatch(updateChargeDateThunk({
      subscriptionId: workspaceSubscription.subscription_id,
      nextChargeDate: formattedDate
    }));
    if (res) {
      getWorkspaceInvoices();
      getShopperInfo();
      await dispatch(DBSubscriptionThunks.find({}));
    }

    // if ((res.payload as { status?: number })?.status === 204){
    //   alert('Date updated successfully')
    // }
    // if ((res.payload as { error?: string })?.error){
    //   alert('Error updating date')
    // }
  }

  const callCancelSubscription = useCallback(async () => {
    setSubscriptionCancelling(true);
    await dispatch(cancelSubscriptionThunk({
      subscriptionID: workspaceSubscription._id,
      reason: 'Cancelled on account page'
    }));
    setSubscriptionCancelling(false);
  }, [dispatch, workspaceSubscription?._id])

  const handleSubmit = async (shopperInfo: ShopperInfo) => {
    toggleDrawer("");
    await updateShopperInfo({
      shopperInfo
    });
    await getShopperInfo();
  };

  const paymentInfoBox = () => <Box
    className={classes.billingCardContainer}
    style={{
      display: "flex",
      justifyContent: "space-between",
      gap: "30px",
      height: "130px",
      alignItems: "center",
    }}
  >
    {invoice ? <div
      style={{ display: "flex", alignItems: "center", gap: "40px" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          border: "1px solid #0000001A",
        }}
      >
        <img alt='' src={creditCardLogo} style={{ height: 50 }} />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h6 className={classes.billingSmallTitleCard}>
          {t('adminBillingView.paymentMethod')}
        </h6>
        {creditCard && <h3
          style={{
            display: "flex",
            gap: "5px",
          }}
          className={classes.billingBlueTtl}
        >
          {cardType}

          {cardType !== 'PayPal' &&
            <Typography
              className={classes.billingBlueTtl}
              style={{ fontWeight: 400 }}
            >
              {`${t('adminBillingView.endingWith')} ${(creditCard["card-last-four-digits"] ?? '').toString().padStart(4, '0')}`}
            </Typography>
          }
        </h3>}
      </div>
    </div> : <CircularProgress />}

    {cardType !== 'PayPal' &&
      <Button
        disabled={readOnly}
        onClick={() => {
          dispatch(openPaymentDialogThunk({}))
        }}
        className={classes.billingCardBtn}>
        {t('adminBillingView.changePaymentMethod')}{" "}
      </Button>
    }


  </Box>

  useEffect(() => {
    document.title = t('adminBillingView.documentTitle');
  }, [t]);

  const getTrialDaysLeft = () => {
    const expiredDate = moment(workspaceSubscription.expiredDate);
    const currentDate = moment();
    const daysLeft = expiredDate.diff(currentDate, 'days');
    return daysLeft
  }


  const buildBillingView = () =>


    <Stack spacing={4} className={classes.billingPageContainer}>
      {(user?.role === "support" || user?.role === "admin") &&
        <Stack className={classes.billingCardContainer} spacing={2}>
          <Box>Current subscription id: {workspaceSubscription?.subscription_id}</Box>
          <Box className={classes.billingSmallTitleCard}>Support actions:</Box>
          <br />
          <Button variant="outlined" onClick={() => dispatch(modalActions.setComponentToRender(MODAL.UPDATE_SUBSCRIPTION_WORKSPACE))}>Transfer subscription to other workspace </Button>
          <TextField
            label="Update Next Charge Date"
            type="date"
            value={nextChargeDate}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: today
            }}
            variant="outlined"
          />
          <Button onClick={handleDateChangeSubmit} variant="contained" color="primary">
            Submit
          </Button>

        </Stack>
      }
      <div
        className={classes.billingCardContainer}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          rowGap: "15px",
          columnGap: "25px"
        }}
      >
        <Box className={classes.billingSmallTitleCard}>Current plan</Box>
        <Box className={classes.billingSmallTitleCard}>Team size</Box>
        <Box className={classes.billingSmallTitleCard}>Billing period</Box>
        <Box className={classes.billingSmallTitleCard}>Amount</Box>
        <Box
          className={classes.billingBlueTtl}
          data-testid={'admin-billing-view-current-plan'}
        >

          {modifyPlanName(planName)}

        </Box>
        <Box
          className={classes.billingBlueTtl}
          data-testid={'admin-billing-view-team-size'}
        >
          {pluralize('member', plan?.seatCount ?? 1, true)}
        </Box>
        <Box
          className={classes.billingBlueTtl}
          data-testid={'admin-billing-view-billing-period'}
        >
          {billingType}
        </Box>
        <Box
          className={classes.billingBlueTtl}
          data-testid={'admin-billing-view-billing-period'}
        >
          ${plan?.price}
        </Box>

        <Button disabled={readOnly} sx={{ maxWidth: '116px' }} onClick={() => router.navigate('/account-subscription')} className={classes.billingCardBtn}>Change plan </Button>

        <Button disabled={readOnly} onClick={() => router.navigate('/account-subscription')} className={classes.billingCardBtn}>
          Edit team size
        </Button>
        {workspaceSubscription.isLive ? <BTButton
          loading={subscriptionCancelling}
          onClick={() => {
            callCancelSubscription()
          }}
          btvariant="text"
        >
          Cancel Subscription
        </BTButton> : <Alert sx={{ ".MuiAlert-message": { overflow: "hidden" } }} severity="error">Cancelled</Alert>}
        {workspaceSubscription.isActive ? <Stack
          alignItems="flex-start"
          justifyContent="center"
          style={{
            fontSize: "12px",
            color: "var(--secondary-text-color)",
            fontWeight: "400",
            lineHeight: "16px",
          }}
        >
          {workspaceSubscription.isLive ? 'Renewal time' : 'Expires'}: <br />
          {shopper?.subscription?.["next-charge-date"]}
        </Stack> : <Alert severity="error">Expired</Alert>}

      </div>
      {paymentInfoBox()}
      <div
        style={{
          display: "flex",
          gap: "30px",
        }}
      >
        <div
          className={
            classes.billingCardContainer +
            " " +
            classes.invoiceHistoryCard
          }
        >
          <div>
            <h4>Invoices</h4>
            <span>
              View and download all your invoices from once place.
            </span>
          </div>
          <BTButton btvariant="secondary" onClick={() => {
            toggleDrawer("Invoices");
          }} >
            View invoices
          </BTButton>
        </div>
      </div>
      <Box className={classes.billingCardContainer} >
        {!shopperContactInfo ?
          <Box >
            <CircularProgress />
          </Box> :
          <Box>
            <Box sx={{
              fontSize: "20px",
              fontWeight: "700",
            }}>
              Invoice details
            </Box>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                //gridTemplateRows: "1fr 1.5fr 1fr",
                gap: "15px",
                marginTop: "10px"
              }}
            >
              <Box className={classes.billingSmallTitleCard}>Company Name</Box>
              <Box className={classes.billingSmallTitleCard}>Full name</Box>
              <Box className={classes.billingSmallTitleCard}>Address</Box>
              <Box>{companyName}</Box>
              <Box>
                {fullName}
              </Box>
              <Box >
                {fullAddress}
              </Box>
              <BTButton disabled={readOnly}
                btvariant="secondary" onClick={() => {
                  toggleDrawer("Invoice details")
                }} >
                Edit Invoice Details
              </BTButton>
            </div>
          </Box>
        }


      </Box >

    </Stack>

  const buildNoSubscriptionView = () => <Stack>
    <Box>
      You do not have a subscription yet
    </Box>
    <BTButton onClick={() => {
      router.navigate({
        pathname: '/account-subscription'
      })
    }} >
      Upgrade
    </BTButton>
  </Stack>

  const buildTrialView = () => <Stack spacing={4} className={classes.billingPageContainer}>
    <div
      className={classes.billingCardContainer}
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        rowGap: "15px",
        columnGap: "25px"
      }}
    >
      <Box className={classes.billingSmallTitleCard}>{t('adminBillingView.currentPlan')}</Box>
      <Box className={classes.billingSmallTitleCard}>{t('adminBillingView.teamSize')}</Box>
      <Box className={classes.billingSmallTitleCard}>{t('adminBillingView.billingPeriod')}</Box>
      <Box className={classes.billingSmallTitleCard}>{t('adminBillingView.amount')}</Box>
      <Box
        sx={{ whiteSpace: 'nowrap' }}
        className={classes.billingBlueTtl}
      >
        {plan?.planName.split(' ').slice(0, 2).join(' ')}


      </Box>
      <Box
        className={classes.billingBlueTtl}
      >
        {pluralize('member', plan?.seatCount ?? 1, true)}
      </Box>
      <Box
        className={classes.billingBlueTtl}
      >
        Trial
      </Box>
      <Box
        className={classes.billingBlueTtl}
        data-testid={'admin-billing-view-billing-period'}
      >
        ${plan?.price}
      </Box>
      <Button sx={{ maxWidth: '116px' }} onClick={() => router.navigate('/account-subscription')} className={classes.billingCardBtn}>{t('adminBillingView.changePlan')} </Button>
      <Button onClick={() => router.navigate('/account-subscription')} className={classes.billingCardBtn}>
        {t('adminBillingView.editTeamSize')}
      </Button>
      <Stack justifyContent={"center"}> <TrialProgressBar /></Stack>
      {getTrialDaysLeft() === 0 ? <Alert severity="error">Expired</Alert> : <Alert severity="success">Active</Alert>}
      {workspaceSubscription.isLive ? <BTButton
        loading={subscriptionCancelling}
        onClick={() => {
          callCancelSubscription()
        }}
        btvariant="text"
      >
        {t('adminBillingView.cancelSubscription')}
      </BTButton> : <Alert severity="error">{t('adminBillingView.cancelled')}</Alert>}
      {workspaceSubscription.isActive ? <Stack
        alignItems="flex-start"
        justifyContent="center"
        style={{
          fontSize: "12px",
          color: "#013750",
          fontWeight: "400",
          lineHeight: "16px",
        }}
      >
        {workspaceSubscription.isLive ? t('adminBillingView.renewalTime') : t('adminBillingView.expires')}: <br />
        {workspaceSubscription?.renewalTime?.format('MMM DD, YYYY')}
      </Stack> : <Alert severity="error">{t('adminBillingView.expired')}</Alert>}
    </div>
    {paymentInfoBox()}
    <div
      style={{
        display: "flex",
        gap: "30px",
      }}
    >
      <div
        className={
          classes.billingCardContainer +
          " " +
          classes.invoiceHistoryCard
        }
      >
        <div>
          <h4>{t('adminBillingView.invoices')}</h4>
          <span>
            {t('adminBillingView.viewDownloadInvoices')}
          </span>
        </div>
        <BTButton btvariant="secondary" onClick={() => {
          toggleDrawer("Invoices");
        }} >
          {t('adminBillingView.viewInvoices')}
        </BTButton>
      </div>
    </div>
    <Box className={classes.billingCardContainer} >
      {!shopperContactInfo ?
        <Box >
          <CircularProgress />
        </Box> :
        <Box>
          <Box sx={{
            fontSize: "20px",
            fontWeight: "700",
          }}>
            {t('adminBillingView.invoiceDetails')}
          </Box>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              //gridTemplateRows: "1fr 1.5fr 1fr",
              gap: "15px",
              marginTop: "10px"
            }}
          >
            <Box className={classes.billingSmallTitleCard}>{t('adminBillingView.companyName')}</Box>
            <Box className={classes.billingSmallTitleCard}>{t('adminBillingView.fullName')}</Box>
            <Box className={classes.billingSmallTitleCard}>{t('adminBillingView.address')}</Box>
            <Box>{companyName}</Box>
            <Box>
              {fullName}
            </Box>
            <Box >
              {fullAddress}
            </Box>
            <BTButton btvariant="secondary" onClick={() => {
              toggleDrawer("Invoice details")
            }} >
              {t('adminBillingView.editInvoiceDetails')}
            </BTButton>
          </div>
        </Box>
      }
    </Box>



  </Stack>



  return (
    <div className={classes.pageContainer}>
      <div className={classes.titleContainer}>
        <h4 className={classes.pageTitle}>{t('adminBillingView.viewTitle')}</h4>
      </div>
      <div
        style={{
          display: "flex",

          position: "relative",
        }}
        className={classes.accountSettingBody}
      >

        {!isLoading && !noSubscription && workspaceSubscription?.plan_id !== 'trial' && buildBillingView()}
        {!isLoading && noSubscription && workspaceSubscription?.plan_id !== 'trial' && buildNoSubscriptionView()}
        {!isLoading && noSubscription && workspaceSubscription?.plan_id === 'trial' && buildTrialView()}
        {isLoading && <Stack direction="row" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>}

        {drawerStatus &&
          <BTDrawer
            anchor="right"
            open={drawerStatus}
            onClose={toggleDrawer}
            sx={{
              width: drawerView === "Invoices" ? "" : "320px",
              ".MuiPaper-root": {
                overflow: "hidden"
              },

            }}
          >
            <Box sx={{
              width: drawerView === "Invoices" ? "750px" : "320px",
              overflow: "auto",
              height: "100%",
            }}
              className={classes.billingOpenModal}>
              <Button sx={{
                minWidth: '0',
                p: 0,
                textAlign: "left",

              }}
                onClick={() => {
                  toggleDrawer("");
                }}
              >
                <img alt='' src={closeBillingModal} width={16} height={16} />
              </Button>

              <h3 className={classes.modalTitle}>{drawerView}</h3>

              {drawerView === "Invoices" &&
                <InvoiceTable />
              }
              {drawerView === "Invoice details" &&
                <EditContactDetailsForm initialValue={invoiceDetails} onSubmit={handleSubmit} />
              }
            </Box>
          </BTDrawer>

        }
        <ReusableModal />
      </div >
    </div >
  );
};

export default AdminBillingView;
