import { LocalTaskData, Task } from "@models/task";
import { TaskColumnOption } from "@models/task-column-option";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FreeLimits } from "interfaces";
import { ActiveNewTaskRow } from "types/interface";
import * as typed from "../reusable/interfaces";

export type DisplayMode = "board" | 'loader' | "kanban" | "form" | 'gantt' | 'workload' | 'boardView';
export type currentBoardView = {
  type: DisplayMode,
  id?: string,
};
export type SortOrder = "asc" | "desc";

export interface ViewOffset {
  top: number;
  left: number;
}

export interface TaskState {
  displayMode: DisplayMode;
  currentBoardView: currentBoardView;
  isTasksPanelOpen: boolean;
  isShareDialog: boolean;
  deleteTaskGroupDialogOpen: boolean;
  taskGroupIdPendingDelete?: {
    chatId: string;
    groupIndex: number | any;
  };
  taskViewContainerOffset: ViewOffset;
  extraDataTaskId: string;
  editableTaskId: string;
  openGroupMenuById: string;
  openGroupColorPickerById: string;
  loginDialogOpen: boolean;
  fullScreen: boolean;
  pickerOptions: TaskColumnOption[];
  isShowAll: boolean;
  isDarkMode: boolean;
  isSortActive: boolean;
  sortOrder?: SortOrder;
  sortingTarget: string;
  sortColumn: string;
  activeColumn: string;
  isSortPendingSave: boolean;
  isPendingReorder: boolean;
  tasksFilter: any;
  advancedFilterConditions: any[];
  menuActiveTaskId: string;
  personFilter: string;
  sortByColumn: string;
  isGroupDragging?: boolean;
  activeNewTaskRow: ActiveNewTaskRow;
  toggleTasksMap: Record<string, boolean>;
  originalTasksOrder: any;
  localData: Record<string, LocalTaskData>;
  forceEditModeToGroupNameByGroupId: string;
  forceEditModeByTaskId: string;
  taskSnackbarMessage?: string;
  upgradeSnackbarMessage?: string;
  taskViewSideDrawerOpen?: string;
  deletedBoardName?: string;
  limits?: FreeLimits
  newTaskText: string
  creatingFirstBoardStatus: string,
  viewMode: string,
  isTaskModalOpen: boolean;
  isNewTab: boolean;
  focusTask: Task;
  currentDraggedId: string;
  isScrollingEnd?: boolean;
  isScrollingStart?: boolean;
  scrollVelocity?: number;
  currentFPS?: number;
}


const initialState: TaskState = {
  taskViewContainerOffset: {
    top: 0,
    left: 0,
  },
  displayMode: "board",
  currentBoardView: {
    type: "board",
    id: undefined,
  },
  isTasksPanelOpen: false,
  isShareDialog: false,
  deleteTaskGroupDialogOpen: false,
  extraDataTaskId: "",
  editableTaskId: "",
  openGroupMenuById: "",
  openGroupColorPickerById: "",
  loginDialogOpen: false,
  fullScreen: false,
  pickerOptions: [],
  isShowAll: false,
  isDarkMode: false,
  isSortActive: false,
  sortingTarget: "",
  sortColumn: "",
  activeColumn: "",
  isSortPendingSave: false,
  isPendingReorder: false,
  tasksFilter: {
    $or: [{ deleted: { $exists: false } }, { deleted: false }],
  },
  advancedFilterConditions: [],
  menuActiveTaskId: "",
  personFilter: "",
  sortByColumn: "",
  activeNewTaskRow: {
    groupId: "",
  },
  toggleTasksMap: {},
  originalTasksOrder: [],
  localData: {},
  forceEditModeToGroupNameByGroupId: "",
  forceEditModeByTaskId: "",
  taskViewSideDrawerOpen: "",
  // Do not update the defaults, if you need more tasks/boards, update your environment in the backend
  limits: {
    tasks: 10,
    boards: 2
  },
  newTaskText: '',
  creatingFirstBoardStatus: '',
  viewMode: 'tasks',
  isTaskModalOpen: false,
  isNewTab: false,
  focusTask: {},
  currentDraggedId: '',
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setCurrentFPS: (state, action: PayloadAction<number>) => {
      state.currentFPS = action.payload;
    },
    setScrollVelocity: (state, action: PayloadAction<number>) => {
      state.scrollVelocity = action.payload;
    },
    setTaskViewIsScrollingEnd: (state, action: PayloadAction<boolean>) => {
      state.isScrollingEnd = true;
      if (!action.payload) {
        state.isScrollingStart = false;
      }
    },
    setTaskViewIsScrollingStart: (state, action: PayloadAction<boolean>) => {
      state.isScrollingStart = action.payload;
    },
    setTaskViewContainerOffset: (state, action: PayloadAction<ViewOffset>) => {
      state.taskViewContainerOffset = action.payload;
    },
    setDeletedBoardName: (state, { payload }: PayloadAction<string>) => {
      state.deletedBoardName = payload;
    },
    clearDeletedBoardName: (state) => {
      delete state.deletedBoardName;
    },
    setTaskSnackbarMessage: (state, { payload }: PayloadAction<string>) => {
      state.taskSnackbarMessage = payload;
    },
    setUpgradeSnackbarMessage: (state, { payload }: PayloadAction<string>) => {
      state.upgradeSnackbarMessage = payload;
    },
    clearTaskSnackbarMessage: (state) => {
      delete state.taskSnackbarMessage;
    },
    toggleConfirmDialog(
      state,
      { type, payload }: PayloadAction<typed.ConfirmDeleteDialogPayload>
    ) {
      state.deleteTaskGroupDialogOpen = payload.deleteTaskGroupDialogOpen;
      state.taskGroupIdPendingDelete = payload.taskGroupIdPendingDelete;
    },
    setIsGroupDragging(state, action: PayloadAction<boolean>) {
      state.isGroupDragging = action.payload;
    },
    setFreeLimits(state, action: PayloadAction<FreeLimits>): void {
      state.limits = action.payload;
    },
    handleShareDialog(state, { type, payload }: PayloadAction<boolean>) {
      state.isShareDialog = payload;
    },
    setIsTasksPanelOpen(state, action: PayloadAction<boolean>) {
      state.isTasksPanelOpen = action.payload;
    },
    setPickerOptions(state, action: PayloadAction<TaskColumnOption[]>) {
      state.pickerOptions = action.payload;
    },
    setOpenGroupMenuById(state, action: PayloadAction<string>) {
      state.openGroupMenuById = action.payload;
    },
    setOpenGroupColorPickerById(state, action: PayloadAction<string>) {
      state.openGroupColorPickerById = action.payload;
    },
    setLoginDialogOpen(state, action: PayloadAction<boolean>) {
      state.loginDialogOpen = action.payload;
    },
    setFullScreen(state, action: PayloadAction<boolean>) {
      state.fullScreen = action.payload;
    },

    setSortByColumnAndOrder(
      state,
      { payload }: PayloadAction<{ sortByColumn: string; sortOrder: SortOrder }>
    ) {
      state.sortByColumn = payload.sortByColumn;
      state.sortOrder = payload.sortOrder;
    },

    setSortIsPendingReorder(state, { payload }: PayloadAction<boolean>) {
      state.isPendingReorder = payload;
    },
    setTasksFilterName(state, action: PayloadAction<string>) {
      state.tasksFilter.text = action.payload

        ? new RegExp(action.payload, "i")
        : "";
      if (state.tasksFilter.text === "") {
        delete state.tasksFilter.text;
      }
    },
    setPersonFilter(state, action: PayloadAction<string>) {
      state.personFilter = action.payload ? action.payload : "";
    },

    toggleTaskMenu(state, { payload }: PayloadAction<string>) {
      state.menuActiveTaskId = payload;
    },

    setDisplayMode(state, action: PayloadAction<DisplayMode>) {
      state.displayMode = action.payload;
    },
    setCurrentBoardView(state, action: PayloadAction<currentBoardView>) {
      state.currentBoardView = action.payload;
    },
    setActiveNewTaskRow(state, action: PayloadAction<ActiveNewTaskRow>) {
      state.activeNewTaskRow = action.payload;
    },
    openSubtasksForTaskList(state, action: PayloadAction<string[]>) {
      for (const taskId of action.payload) {
        state.toggleTasksMap[taskId] = true;
      }
    },
    closeSubtasksForTaskList(state, action: PayloadAction<string[]>) {
      for (const taskId of action.payload) {
        delete state.toggleTasksMap[taskId];
      }
    },
    toggleTask(state, action: PayloadAction<string>) {
      const taskIdForToggle = action.payload;
      if (!state.toggleTasksMap[taskIdForToggle]) {
        state.toggleTasksMap[taskIdForToggle] = true;
      } else {
        delete state.toggleTasksMap[taskIdForToggle];
      }
    },
    setOriginalTaskOrder(state, action: PayloadAction<Task[]>) {
      state.originalTasksOrder = action.payload;
    },
    setSortActive(state, action: PayloadAction<boolean>) {
      state.isSortActive = action.payload;
    },
    setForceEditModeToGroupNameByGroupId(state, action: PayloadAction<string>) {
      state.forceEditModeToGroupNameByGroupId = action.payload;
    },

    setForceEditModeByTaskid(state, action: PayloadAction<string>) {
      state.forceEditModeByTaskId = action.payload;
    },
    setTaskViewSideDrawerOpenWith(state, action: PayloadAction<string>) {
      state.taskViewSideDrawerOpen = action.payload;
    },
    setnewTaskText(state, action: PayloadAction<string>) {
      state.newTaskText = action.payload;
    },
    setCreatingFirstBoardStatus(state, action: PayloadAction<string>) {
      state.creatingFirstBoardStatus = action.payload;
    },
    setViewMode(state, action: PayloadAction<string>) {
      state.viewMode = action.payload;
    },
    toggleTaskModal(state, action: PayloadAction<boolean>) {
      state.isTaskModalOpen = action.payload;
    },
    setIsNewTab(state, action: PayloadAction<boolean>) {
      state.isNewTab = action.payload;
    },
    setFocusedTask(state, action: PayloadAction<Task>) {
      state.focusTask = action.payload;
    },
    setCurrentDraggedId(state, action: PayloadAction<string>) {
      state.currentDraggedId = action.payload;
    }
  },
});

export default taskSlice.reducer;

export const taskActions = taskSlice.actions;



