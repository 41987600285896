import RocketFireSmokeAnimation from '@assets/lottie/rocket-fire-smoke-animation.json';
import { Box } from '@mui/material';
import Stack from '@mui/system/Stack';
import Lottie from "lottie-react";
import { useEffect } from 'react';
import { modalActions } from "@reducers/ModalReducer";
import { useAppDispatch } from '@store/hooks';

const TrialModalActivateAnimation = () => {
    const dispatch = useAppDispatch();

    const rocketStyle = {
        position: "absolute",
        animation: 'liftOff 8s forwards',
        '@keyframes liftOff': {
            '0%': {
                transform: 'translateY(500px) scale(1)',
                opacity: 1
            },
            '25%': {
                opacity: 1
            },
            '100%': {
                transform: 'translateY(-800px) scale(1.5)',
                opacity: 0
            }
        },
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(modalActions.setAnimationComponentToRender(''));
        }, 8000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Stack justifyContent={"center"} alignItems={"center"} style={{
            position: "absolute",
            zIndex: 99999999999999,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
        }}>
            <Box sx={{ ...rocketStyle, }}>
                <Lottie autoPlay={true} loop={true}
                    animationData={RocketFireSmokeAnimation}
                    style={{ height: '250px' }} />
            </Box>
        </Stack>
    );
};

export default TrialModalActivateAnimation;