import { BluesnapInvoice } from '@models/order';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Modal, Stack, TextField, Typography } from '@mui/material';
import { activeSubscriptionSelector } from '@reducers/UserSelectors';
import { refundClient, useGetWorkspaceInvoicesMutation } from '@reducers/backend-api/backend-api';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import GroupedBlueSnapInvoice, { GroupedInvoice } from './grouped-bluesnap-invoice';
import GroupedChargebeeTransaction, { GroupedTransaction } from './grouped-chargebee-transaction';


export default function InvoiceTable() {
    const dispatch = useAppDispatch()
    const [isRefundModalOpen, setRefundModalOpen] = useState(false);
    const [refundDetails, setRefundDetails] = useState({
        amount: 0,
        reason: '',
        cancelSubscriptions: false,
    });
    const [currentInvoiceId, setCurrentInvoiceId] = useState(null);
    const [maxRefundAmount, setMaxRefundAmount] = useState(null);

    const workspaceSubscription = useAppSelector(activeSubscriptionSelector);

    const [getWorkspaceInvoices, { data, isLoading }] = useGetWorkspaceInvoicesMutation();


    useEffect(() => {
        getWorkspaceInvoices();
    }, [getWorkspaceInvoices])


    const invoiceList: BluesnapInvoice[] = data?.invoiceList ?? [];
    const chargebeeTransactionList = data?.chargebeeTransactionList ?? [];

    const groupInvoicesWithRefunds = useCallback((invoices: BluesnapInvoice[]): GroupedInvoice[] => {
        const grouped: { [key: string]: GroupedInvoice } = {};
        invoices.forEach(invoice => {
            if (invoice['reversal-type'] === 'REFUND') {
                const originalInvoiceId = invoice['original-invoice-id'];
                if (!grouped[originalInvoiceId]) {
                    grouped[originalInvoiceId] = { invoice: null, refunds: [] };
                }
                grouped[originalInvoiceId].refunds.push(invoice);
            } else {
                if (!grouped[invoice['invoice-id']]) {
                    grouped[invoice['invoice-id']] = { invoice, refunds: [] };
                } else {
                    grouped[invoice['invoice-id']].invoice = invoice;
                }
            }
        });
        return Object.values(grouped);
    }, []);

    const groupChargebeeTransactions = useCallback((transactions: ChargebeeTransaction[]): GroupedTransaction[] => {
        const grouped: { [key: string]: GroupedTransaction } = {};
        transactions.forEach(transaction => {
            if (transaction.linked_credit_notes?.length > 0) {
                const [creditNote] = transaction.linked_credit_notes ?? [];
                const originalInvoiceId = creditNote?.cn_reference_invoice_id;
                if (!grouped[originalInvoiceId]) {
                    grouped[originalInvoiceId] = { transaction, refunds: [] };
                }
                grouped[originalInvoiceId].refunds.push(transaction);
            } else {
                const [invoice] = transaction.linked_invoices ?? [];
                const invoiceId = invoice?.invoice_id;
                
                if (!grouped[invoiceId]) {
                    grouped[invoiceId] = { transaction, refunds: [] };
                }
            }
        });
        return Object.values(grouped);
    }, []);

    const groupedInvoices = groupInvoicesWithRefunds(invoiceList);
    const groupedChargebeeTransactions = groupChargebeeTransactions(chargebeeTransactionList);

    const handleOpenModal = (event, invoiceId: string) => {
        event.stopPropagation();
        const invoice = groupedInvoices.find(group => (group.invoice?.['invoice-id']?.toString()) === invoiceId)
        if (invoice) {

            const totalRefunded = invoice.refunds.reduce((acc, refund) => acc + Math.abs(refund['financial-transactions']['financial-transaction'].amount), 0);

            const originalAmount = invoice.invoice['financial-transactions']['financial-transaction'].amount;

            const maxAmount = originalAmount - totalRefunded;

            setCurrentInvoiceId(invoiceId);
            setMaxRefundAmount(maxAmount);
            setRefundModalOpen(true);

            setRefundDetails(prevState => ({
                ...prevState,
                amount: maxAmount < 0 ? 0 : maxAmount,
            }));
        }
    };


    const handleCloseModal = () => {
        setRefundModalOpen(false);
        setRefundDetails({ amount: 0, reason: '', cancelSubscriptions: false });
        setCurrentInvoiceId(null);
        setMaxRefundAmount(null);
    };


    const submitRefund = async () => {
        if (currentInvoiceId && refundDetails.amount <= maxRefundAmount) {
            const res = await dispatch(refundClient.initiate({
                subscriptionId: workspaceSubscription?._id,
                invoiceId: currentInvoiceId,
                ...refundDetails,

            })).unwrap();
            handleCloseModal();
            if (res?.statusObj?.status === 200) {
                getWorkspaceInvoices();
            }

        } else {
            alert("Refund amount exceeds the allowable limit.");
        }
    };

    const buildInvoiceTable = () => (
        <Stack spacing={1} mb={5}>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gap: 2,
                p: 2,
                // backgroundColor: 'background.paper',
                borderRadius: 1,
            }}>
                <Typography sx={{ fontWeight: 'bold' }}>Invoice id</Typography>
                <Typography sx={{ fontWeight: 'bold', textAlign: "center" }}>Date</Typography>
                <Typography sx={{ fontWeight: 'bold', textAlign: "center" }}>Card</Typography>
                <Typography sx={{ fontWeight: 'bold', textAlign: "center" }}>Amount</Typography>
                <Typography sx={{ fontWeight: 'bold', textAlign: "center" }}>Currency</Typography>
            </Box>

            {groupedChargebeeTransactions.map((group: GroupedTransaction, index) => 
                <GroupedChargebeeTransaction 
                key={index}
                group={group}
                index={index}
                handleOpenModal={handleOpenModal} 
            />)}

            {groupedInvoices.sort((a, b) => {
                const dateA = new Date(a.invoice['financial-transactions']['financial-transaction']['date-created']).getTime();
                const dateB = new Date(b.invoice['financial-transactions']['financial-transaction']['date-created']).getTime();
                return dateB - dateA;
            }).map((group: GroupedInvoice, index) => <GroupedBlueSnapInvoice
                key={index}
                index={index}
                group={group}
                handleOpenModal={handleOpenModal}
            />)}
        </Stack>
    );


    const refundModalBody = (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h6" component="h2">
                Refund Invoice - {currentInvoiceId}
            </Typography>
            <TextField
                label="Amount"
                type="number"
                fullWidth
                variant="outlined"
                value={refundDetails.amount}
                onChange={(e) => setRefundDetails({ ...refundDetails, amount: Number(e.target.value) })}
                inputProps={{ min: 0, max: maxRefundAmount }}
                error={refundDetails.amount > maxRefundAmount}
                helperText={refundDetails.amount > maxRefundAmount ? `Refund amount should not exceed ${maxRefundAmount}` : ''}
            />
            <TextField
                label="Reason"
                fullWidth
                variant="outlined"
                value={refundDetails.reason}
                onChange={(e) => setRefundDetails({ ...refundDetails, reason: e.target.value })}
            />
            <FormControlLabel
                control={<Checkbox checked={refundDetails.cancelSubscriptions} onChange={(e) => setRefundDetails({ ...refundDetails, cancelSubscriptions: e.target.checked })} />}
                label="Cancel Subscription"
            />
            <Button variant="contained" onClick={submitRefund}>Submit Refund</Button>
        </Box>
    );

    return (
        <React.Fragment>
            {isLoading ? <CircularProgress /> : buildInvoiceTable()}
            <Modal
                open={isRefundModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {refundModalBody}
            </Modal>
        </React.Fragment>
    );



}