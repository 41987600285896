
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import WebsiteComponent from "./website/website";

import { appConfig } from "config/app.config";
import { getAssetURL } from '@components/theme/theme-asset';
import "config/i18next.config";
import { ConfirmProvider } from 'material-ui-confirm';
const root = createRoot(document.getElementById("root"));

const environment = appConfig.ENV;


document.addEventListener("DOMContentLoaded", function (event) {
  const faviconURL = getAssetURL('favicon.ico'); // Assuming your favicon's name is 'favicon.ico'

  let faviconLink = document.querySelector("link[rel*='icon']") as HTMLLinkElement;;
  if (!faviconLink) {
    faviconLink = document.createElement('link') as HTMLLinkElement;;
    faviconLink.rel = 'icon';
    document.head.appendChild(faviconLink);
  }
  faviconLink.href = faviconURL;






  root.render(
    <Provider store={store}>
      <ConfirmProvider>
        <WebsiteComponent />
      </ConfirmProvider>
    </Provider>
  );
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
