import RowStack from '@components/common/row-stack/RowStack';
import { Grading, InfoOutlined, MoreVert, PauseOutlined, PlayArrowOutlined, Stop } from '@mui/icons-material';
import { Box, IconButton, Menu, Modal, Stack } from '@mui/material';
import { setActiveBulkView, setCampaignIdForLogsView } from '@reducers/CampaignReducer';
import { getBoardNameByBoardIdRecord, getTaskSumByBoardIdRecord } from '@reducers/CampaignSelectors';
import { DBCampaignThunks, campaignsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { getRuntimeUrl } from '@services/storage';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useState } from 'react';
import classes from './bulk_item_row_menu.module.scss';
import CampaignStatusLabel from './campaign-status-label';
import BulkDBFinish from './finish/bulk-db-finish';
import TemplateRowDetails from './template-row-details';

type Props = {
    itemId: string;
    index: number;
};

export default function CampaignDialogRow({ itemId, index }: Props) {
    const dispatch = useAppDispatch();
    const campaign = useAppSelector(state => campaignsSelectOneObjectByQuery(state.DBCampaignReducer, {
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
        _id: itemId
    }));
    const taskSumByBoardIdRecord = useAppSelector(getTaskSumByBoardIdRecord);
    const boardNameByBoardIdRecord = useAppSelector(getBoardNameByBoardIdRecord);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpenMenu = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => setAnchorEl(null);

    const handleViewLogsClick = () => {
        dispatch(setCampaignIdForLogsView(itemId));
        dispatch(setActiveBulkView("campaignLogsView"));
        handleCloseMenu();
    };


    const totalNumberOfContacts = () => {
        if (campaign?.list && campaign?.list?.contactIdList) {
            return campaign?.list?.contactIdList?.length
        }
        else {
            return (campaign?.boardIDArray?.reduce((sum, id) => sum + taskSumByBoardIdRecord[id], 0) || 0)
        }
    };

    const formatISOCampaignCreationTime = (isoString) => isoString ?
        `Started ${new Date(isoString).toLocaleDateString('en-GB')} - ${new Date(isoString).toLocaleTimeString('en-GB', { hour12: false })}`
        : 'Starting time unavailable to display';

    const campaignStatusImageSrc = {
        pending: getRuntimeUrl("/img/pendingTagSVG.svg"),
        paused: getRuntimeUrl("/img/pausedTagSVG.svg"),
        running: getRuntimeUrl("/img/runningTagSVG.svg"),
        completed: getRuntimeUrl("/img/completedTagSVG.svg"),
        aborted: getRuntimeUrl("/img/abortSVG.svg")
    };

    return (
        <RowStack key={index}
            sx={{
                justifyContent: 'space-between',
                borderBottom: '1px solid var(--campaign-border)',
                height: "85px",
                padding: '10px 10px 10px 10px',
            }}>
            <CampaignInformation
                campaign={campaign}
                formatISOCampaignCreationTime={formatISOCampaignCreationTime}
                totalNumberOfContacts={totalNumberOfContacts}
                boardNameByBoardIdRecord={boardNameByBoardIdRecord}
            />
            <CampaignStatusAndActions
                campaign={campaign}
                campaignStatusImageSrc={campaignStatusImageSrc}
                handleViewLogsClick={handleViewLogsClick}
                handleOpenMenu={handleOpenMenu}
                anchorEl={anchorEl}
                handleCloseMenu={handleCloseMenu}
            />
        </RowStack>
    );
}

const CampaignInformation = ({ campaign, formatISOCampaignCreationTime, totalNumberOfContacts, boardNameByBoardIdRecord }) => (
    <Stack    spacing={1} sx={{
        width: '547px',
        height: '100%',
     
    }}>
        <RowStack sx={{ marginBottom: '5px' }} >
            <span style={{ fontSize: '18px', }}>{campaign?.name}</span>
            <span style={{ fontSize: '12px', fontWeight: 600, color: '#a6a6a6', marginRight: "5px",textDecoration:"underline" }}>
               Campaign  {formatISOCampaignCreationTime(campaign?.schedule?.dueDate)}
            </span>
            <span style={{
                color: 'var(--campaign-dialog-green-text)',
                fontWeight: 600,
                fontSize: '12px',
                textDecoration:"underline" 
            }}>
                {isNaN(totalNumberOfContacts()) ? 'Unknown number of contacts' : `${totalNumberOfContacts()} contacts`}
            </span>
        </RowStack>
        {/* Audiences and Contacts */}
        <AudiencesAndContacts campaign={campaign} boardNameByBoardIdRecord={boardNameByBoardIdRecord} />
        {/* Template Details */}
        <TemplateRowDetails template={campaign?.template} />
    </Stack>
);

const AudiencesAndContacts = ({ campaign, boardNameByBoardIdRecord }) => {
    if (!campaign?.boardIDArray?.length) {
        return null;
    }

    return (
        <RowStack sx={{ marginBottom: '5px' }}>
            {campaign.boardIDArray
                .filter((boardID, index) => index < 2)
                .map((audienceBoardId, index) => (
                 
                    <span key={index}
                        style={{
                            color: 'var(--campaign-dialog-green-text)',
                            fontWeight: 600, fontSize: '14px'
                        }}>
                               <span style={{color:"#000"}}>Audience name: &nbsp;</span>
                        {boardNameByBoardIdRecord[audienceBoardId] ?? 'Untitled audience'}, &nbsp;
                        {campaign.boardIDArray.length > 2 && index === 1 && ` and ${campaign.boardIDArray.length - 2} more...`}
                    </span>
                ))
            }
        </RowStack>
    );
};


const CampaignStatusAndActions = ({ campaign, campaignStatusImageSrc, handleViewLogsClick, handleOpenMenu, anchorEl, handleCloseMenu }) => {
    const dispatch = useAppDispatch()
    const handleChangeCampaignStatus = (type) => {
        dispatch(DBCampaignThunks.patch({
            entity: {
                ...campaign,
                campaignStatus: type,
            }
        }));
        handleCloseMenu();
    };

    return (
        <RowStack sx={{ justifyContent: 'space-around' }}>
            <Stack sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                {/* Campaign Status */}
                {campaign?.campaignStatus && (
                    <CampaignStatusLabel label={campaign.campaignStatus} />
                )}
                {/* <Button onClick={handleViewLogsClick}
                    sx={{
                        '&:hover': {
                            backgroundColor: 'var(--campaign-dialog-row-hover)',
                        },
                        fontWeight: 600,
                        fontSize: '12px',
                        color: 'var(--primary)',
                        marginTop: '10px',
                        width: 'max-content',

                    }}>
                    VIEW LOGS
                </Button> */}
            </Stack>
            {/* Campaign Actions Menu */}
            <CampaignActionsMenu
                campaign={campaign}
                handleViewLogsClick={handleViewLogsClick}
                handleChangeCampaignStatus={handleChangeCampaignStatus}
                anchorEl={anchorEl}
                handleCloseMenu={handleCloseMenu}
                handleOpenMenu={handleOpenMenu}
            />
        </RowStack>
    )
};

const CampaignActionsMenu = ({ campaign, handleViewLogsClick, handleChangeCampaignStatus, anchorEl, handleCloseMenu, handleOpenMenu }) => {
    const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
    return (
        <Box onClick={(e) => e.stopPropagation()} sx={{ width: '20%' }}>
            <IconButton onClick={(e) => handleOpenMenu(e)}
                sx={{
                    color: '#7f9887',
                    width: 'auto',
                    p: 0,
                    '&:hover': {
                        backgroundColor: 'var(--campaign-dialog-row-hover)',
                    },
                }}>
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                PaperProps={{
                    style: {
                        borderRadius: '6px',
                        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.05)',
                    },
                }}
                MenuListProps={{
                    sx: {
                        padding: 0, // Attempting to target the padding directly
                        // If the above doesn't work, you might need a more specific target using global selectors
                        '& .MuiList-root': {
                            padding: 0, // This is a more targeted approach
                        },
                    },
                }}
            >
                <Box className={classes.menu_wrapper}>
                    <button onClick={handleViewLogsClick} className={classes.menu_list_item}>
                        <Grading />
                        <span style={{ marginInlineStart: 5 }}>View logs</span>
                    </button>
                    {campaign?.campaignStatus === 'running' && (
                        <button onClick={() => handleChangeCampaignStatus('paused')} className={classes.menu_list_item}>
                            <PauseOutlined style={{ width: '17px', }} />
                            <span style={{ marginInlineStart: 5 }}>Pause campaign</span>
                        </button>
                    )}
                    {campaign?.campaignStatus === 'paused' && (
                        <button onClick={() => handleChangeCampaignStatus('running')} className={classes.menu_list_item}>
                            <PlayArrowOutlined style={{ width: '17px', }} />
                            <span style={{ marginInlineStart: 5 }}>Resume campaign</span>
                        </button>
                    )}
                    {!['aborted', 'completed'].includes(campaign?.campaignStatus) && (
                        <button onClick={() => handleChangeCampaignStatus('aborted')} className={classes.menu_list_item}>
                            <Stop style={{ color: 'red' }} />
                            <span style={{ marginInlineStart: 5, color: 'red' }}>Abort campaign</span>
                        </button>
                    )}
                    <button onClick={() => {
                        setIsSummaryModalOpen(true)
                        handleCloseMenu()
                    }} className={classes.menu_list_item}>
                        <InfoOutlined />
                        <span style={{ marginInlineStart: 5 }}>Summary</span>
                    </button>
                </Box>
            </Menu>
            <Modal
                open={isSummaryModalOpen}
                onClose={() => {
                    setIsSummaryModalOpen(false)
                }}
                sx={{
                    "margin": "auto",
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                }}
            >
                <div style={{
                    backgroundColor: 'white', padding: '7px', borderRadius: '8px', width: '700px',
                }}>
                    <BulkDBFinish campaignId={campaign?._id} />
                </div>
            </Modal>

        </Box>
    );
}