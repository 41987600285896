import { BlueticksForm } from '@models/form'
import { NoteAltOutlined } from '@mui/icons-material'
import { getCurrentBoard } from '@reducers/BoardsSelectors'
import { DBFormThunks } from '@reducers/DBServiceReducers'
import { dynamicColumnListForForms } from '@reducers/TaskSelectors'
import { taskActions } from '@reducers/TasksReducer'
import { selectUserId } from '@reducers/UserSelectors'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { addViewStyle } from '@views/task-view/add-feature-menu-button'
import { namespace } from 'config/constants'
import randomstring from 'randomstring'
import { useTranslation } from 'react-i18next'
import { MdAddCircle } from 'react-icons/md'

export default function NewFormButton() {
    const { t } = useTranslation(namespace);
    const currentBoard = useAppSelector(getCurrentBoard)
    const dispatch = useAppDispatch()
    const userId = useAppSelector(selectUserId)
    const columnList = useAppSelector(state => dynamicColumnListForForms(state, currentBoard._id))

    const newForm = async () => {
        let newColumnData = {}
        for (let i = 0; i < columnList.length; i++) {
            if (columnList[i]._id === 'name') {
                newColumnData = {
                    ...newColumnData,
                    [columnList[i]._id]: {
                        order: -99,
                        visible: true,
                        title: t('newFormButton.newTaskTitle'),
                        description: t('newFormButton.newTaskDescription'),
                        required: false,
                    }
                }
            }
            else {
                newColumnData = {
                    ...newColumnData,
                    [columnList[i]._id]: {
                        order: i,
                        visible: true,
                        title: columnList[i].title === '' ? columnList[i].type : columnList[i].title,
                        required: false,
                    }
                }
            }
        }
        const uniqueId = randomstring.generate(24)
        const _form: BlueticksForm = {
            owner: userId,
            boardId: currentBoard._id,
            name: "Form",
            columnData: newColumnData,
            shareId: uniqueId,
            isActive: true,
            afterSubmit: {
                title: t('newFormButton.formAfterSubmitTitle'),
                message: t('newFormButton.formAfterSubmitMessage'),
            },
            backgroundColor: '#e8f9fd',
        }
        dispatch(taskActions.setCurrentBoardView({
            type: "loader",
            id: '',
        }))
        const res = await dispatch(DBFormThunks.create(_form))
        const form = res.payload as BlueticksForm
        dispatch(taskActions.setCurrentBoardView({
            type: "form",
            id: form._id,
        }))
    }

    return (
        <>
            <span data-testid='add-new-form-button'
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
                onClick={newForm}>
                <NoteAltOutlined style={{ marginLeft: '4px', width: "20px" }} />
                Form
                <MdAddCircle className="add_view_icon" style={{
                    ...addViewStyle,
                    position: 'absolute',
                    right: 7,
                }} size={20} />
            </span>
        </>

    );
}