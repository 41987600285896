import { Tooltip } from "@mui/material";
import { useAppSelector } from "@store/hooks";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { useAppDispatch } from "@store/hooks";
import classes from "../group_header.module.scss";
import { SortOrder, taskActions } from "@reducers/TasksReducer";
import { saveTaskOrder, sortTasks, undoTasksSort } from "@reducers/TaskThunks";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";



type Props = {
  columnId: string;
}

export default function SortButton(props: Props) {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch()
  const sortOrder: SortOrder | undefined = useAppSelector((state) => state.TasksReducer.sortOrder)
  const activeColumn = useAppSelector((state) => state.TasksReducer.sortByColumn)
  const { columnId } = props;

  const saveSort = () => {
    dispatch(saveTaskOrder())
    dispatch(taskActions.setSortByColumnAndOrder({
      sortByColumn: '',
      sortOrder: undefined
    }))
    dispatch(taskActions.setSortActive(false))
  }

  const SortByColumnAndOrder = (columnId) => {
    let newSortOrder;
    switch (sortOrder) {
      case 'asc': {
        newSortOrder = 'desc';
        break;
      }
      case 'desc':
      default: {
        newSortOrder = 'asc';
        break;
      }
    }
    dispatch(taskActions.setSortByColumnAndOrder({
      sortByColumn: columnId,
      sortOrder: newSortOrder
    }))

    dispatch(sortTasks({
      sortOrder: newSortOrder,
      sortColumnId: columnId
    }))
  }

  function undoChanges() {
    dispatch(undoTasksSort())
    dispatch(taskActions.setSortByColumnAndOrder({
      sortByColumn: '',
      sortOrder: undefined
    }))
  }



  return (
    <div style={{
      display: (sortOrder && activeColumn === columnId) ? 'flex' : '',
      // right: columnName === 'text' ? '110px':''
    }} className={`${classes.sort_flex_wrapper} ${sortOrder && activeColumn === columnId ? classes.currentlySorted : ''}
   `}>
      <button onClick={undoChanges}
        className={classes.aside_btn}>
        {t('sortButton.clearButton')}
      </button>


      <Tooltip title={t('sortButton.sortTooltip')} arrow placement="top">
        <button onClick={() => SortByColumnAndOrder(columnId)} className={classes.sortButton}>
          <div className={`${classes.caretsWrap}`}>
            <div className={classes.caretDown}
              style={{ color: sortOrder && activeColumn === columnId ? sortOrder === 'asc' ? '#fff' : '#ffffff6b' : '#000' }}
            ><AiFillCaretDown /></div>
            <div className={classes.caretUp}
              style={{ color: sortOrder && activeColumn === columnId ? sortOrder === 'desc' ? '#fff' : '#ffffff6b' : '#000' }}
            ><AiFillCaretUp /></div>
          </div>
        </button>
      </Tooltip>

      <button
        onClick={saveSort}
        className={classes.aside_btn}>
        {t('sortButton.saveButton')}
      </button>

    </div>

  )
}
