import { PaymentIntent } from "@chargebee/chargebee-js-types";
import { BluesnapCountry } from "@components/payment-form/country-list";
import { Currency, CurrencyList } from "@components/payment-form/currency-codes";
import { BluesnapShopper } from "@models/bluesnap-shopper";
import { UserInterface } from "@models/user";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getShopperInfoThunk, validateZipCodeThunk } from "./PaymentFormThunks";
import { ScriptState } from "./hooks/use-external-script";

export type PaymentMethod = 'CreditCard' | 'Paypal';
export interface PaymentFormState {
    estimate?: Estimate;
    paymentIntent?: PaymentIntent;
    appliedCouponCode?: string;
    calculateCartIsLoading: boolean;
    address: string;
    country?: BluesnapCountry;
    firstName: string;
    lastName: string;
    vatId: string;
    vatStatus?: VATStatus;
    vatValidationMessage?: string;
    vatValidationLoading?: boolean;
    companyName: string;
    paymentMethod: PaymentMethod;
    scriptState: ScriptState;
    paymentsFieldsReady: boolean;
    zip?: string;
    processingPayment: boolean;
    errorMessage?: string;
    cartErrorMessage?: string;
    open: boolean;
    planId?: string;
    contractId?: string;
    workspaceId?: string;
    paymentSuccess?: boolean;
    paymentSuccessType?: 'upgrade' | 'downgrade';
    currency: Currency;
    endpoint?: string;
    isScriptReady: boolean;
    isInitialized: boolean;
    creditCardDetailsUpdated: boolean;
    seats?: number;
    zipValidationMessage?: string;
    zipValidationLoading: boolean;
    zipStatus: 'VALID' | 'INVALID' | 'UNKNOWN';
    shopper?: BluesnapShopper;
    customer?: Customer;
    getShopperInfoLoading: boolean;
    paymentSourceList: PaymentSource[];
    isUpdatePaymentMethodPopupOpen?: boolean;
    manage_payment_sources_url?: string;
    isGettingPaymentUrl?: boolean;
}

const USDCurrency = CurrencyList.find(currency => currency.code === 'USD');
const initialState: PaymentFormState = {
    calculateCartIsLoading: false,
    address: '',
    firstName: '',
    lastName: '',
    vatId: '',
    zip: '',
    companyName: '',
    paymentMethod: 'CreditCard',
    scriptState: "idle",
    paymentsFieldsReady: false,
    processingPayment: false,
    open: false,
    currency: USDCurrency,
    isScriptReady: false,
    isInitialized: false,
    creditCardDetailsUpdated: false,
    zipValidationMessage: '',
    zipValidationLoading: false,
    zipStatus: 'UNKNOWN',
    shopper: undefined,
    customer: undefined,
    getShopperInfoLoading: false,
    paymentSourceList: [],
    isGettingPaymentUrl: false
};

const PaymentFormSlice = createSlice({
    name: "paymentFormSlice",
    initialState,
    reducers: {
        setManagePaymentSourcesUrl: (state, action: PayloadAction<string>) => {
            state.manage_payment_sources_url = action.payload;
        },
        setIsUpdatePaymentMethodPopupOpen: (state, action: PayloadAction<boolean>) => {
            state.isUpdatePaymentMethodPopupOpen = action.payload;
        },
        setAddress: (state, action: PayloadAction<string>) => {
            state.address = action.payload;
        },
        setCountry: (state, action: PayloadAction<BluesnapCountry>) => {
            state.country = action.payload;
        },
        setFirstName: (state, action: PayloadAction<string>) => {
            state.firstName = action.payload;
        },
        setLastName: (state, action: PayloadAction<string>) => {
            state.lastName = action.payload;
        },
        setVatId: (state, action: PayloadAction<string>) => {
            state.vatId = action.payload;
        },
        setCompanyName: (state, action: PayloadAction<string>) => {
            state.companyName = action.payload;
        },
        setAddress1: (state, action: PayloadAction<string>) => {
            state.address = action.payload;
        },
        setAddress2: (state, action: PayloadAction<string>) => {
            state.address = action.payload;
        },
        setProcessingPayment: (state, action: PayloadAction<boolean>) => {
            state.processingPayment = action.payload;
        },
        setPaymentsFieldsReady: (state, action: PayloadAction<boolean>) => {
            state.paymentsFieldsReady = action.payload;
        },
        setErrorMessage: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },
        setCartErrorMessage: (state, action: PayloadAction<string>) => {
            state.cartErrorMessage = action.payload;
        },
        setPaymentIntent: (state, action: PayloadAction<PaymentIntent>) => {
            state.paymentIntent = action.payload;
        },
        clearPaymentIntent: (state) => {
            delete state.paymentIntent;
        },
        setPaymentSourceList: (state, action: PayloadAction<PaymentSource[]>) => {
            state.paymentSourceList = action.payload;
        },
        setPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
            state.paymentMethod = action.payload;

            delete state.paymentIntent;

            if (state.paymentMethod === 'Paypal') {
                state.isScriptReady = false;
                state.isInitialized = false;
            }
        },
        openDialog: (state, action: PayloadAction<{ seats?: number, planId?: string, contractId?: string, workspaceId?: string, paymentSuccess?: boolean, user: UserInterface }>) => {
            const { user } = action.payload;

            state.open = true;
            state.planId = action.payload?.planId;
            state.contractId = action.payload?.contractId;
            state.workspaceId = action.payload?.workspaceId;
            state.paymentSuccess = action.payload?.paymentSuccess;
            state.seats = action.payload?.seats;


            state.firstName = user.firstName;
            state.lastName = user.lastName;
            state.companyName = user.invoiceDetails?.companyName;
            state.address = user.invoiceDetails?.address;
            state.vatId = user.invoiceDetails?.vatId;

            state.isScriptReady = false;
            state.isInitialized = false;
            delete state.paymentIntent;
        },
        closeDialog: (state) => {
            state.open = false;
            state.processingPayment = false;
            delete state.planId;
            delete state.contractId;
            delete state.workspaceId;
            delete state.errorMessage;
            delete state.cartErrorMessage;
            delete state.paymentIntent;
            delete state.estimate;
            delete state.seats;
        },
        setPaymentSuccess: (state, action: PayloadAction<boolean>) => {
            state.paymentSuccess = action.payload;
        },
        setCreditCardDetailsUpdated: (state, action: PayloadAction<boolean>) => {
            state.creditCardDetailsUpdated = action.payload;
        },
        setPaymentSuccessType: (state, action: PayloadAction<'upgrade' | 'downgrade'>) => {
            state.paymentSuccessType = action.payload;
        },
        setCurrency: (state, action: PayloadAction<Currency>) => {
            state.currency = action.payload;
        },
        setEndpoint: (state, action: PayloadAction<string>) => {
            state.endpoint = action.payload;
        },
        setZip: (state, action: PayloadAction<string>) => {
            state.zip = action.payload;
        },
        setEstimate: (state, action: PayloadAction<Estimate>) => {
            state.estimate = action.payload;
        },
        setAppliedCouponCode: (state, action: PayloadAction<string>) => {
            state.appliedCouponCode = action.payload;
        },
        setCalculatingCart: (state, action: PayloadAction<boolean>) => {
            state.calculateCartIsLoading = action.payload;
        },
        setIsScriptReady: (state, action: PayloadAction<boolean>) => {
            state.isScriptReady = action.payload;
        },
        setIsInitizalized: (state, action: PayloadAction<boolean>) => {
            state.isInitialized = action.payload;
        },
        setScriptState: (state, action: PayloadAction<ScriptState>) => {
            state.scriptState = action.payload;
            if (state.scriptState === 'ready') {
                state.isScriptReady = true;
            }
            if (state.paymentMethod === 'Paypal') {
                state.isScriptReady = false;
                state.isInitialized = false;
            }
        },
        setVatValidationLoading: (state, action: PayloadAction<boolean>) => {
            state.vatValidationLoading = action.payload;
        },
        setVatValidation: (state, action: PayloadAction<{ vatStatus: VATStatus, message?: string }>) => {
            state.vatStatus = action.payload.vatStatus;
            state.vatValidationMessage = action.payload.message;
            state.vatValidationLoading = false;
        },
        setShopper: (state, action: PayloadAction<BluesnapShopper>) => {
            state.shopper = action.payload;
        },
        setCustomer: (state, action: PayloadAction<Customer>) => {
            state.customer = action.payload;
        },
        setIsGettingPaymentUrl: (state, action: PayloadAction<boolean>) => {
            state.isGettingPaymentUrl = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(validateZipCodeThunk.pending, (state) => {
            state.zipValidationLoading = true;
        });
        builder.addCase(validateZipCodeThunk.fulfilled, (state, action) => {
            state.zipValidationLoading = false;
            state.zipStatus = action.payload.isValid ? 'VALID' : 'INVALID';
            state.zipValidationMessage = action.payload.message;
        });
        builder.addCase(validateZipCodeThunk.rejected, (state) => {
            state.zipValidationLoading = false;
            state.zipStatus = 'UNKNOWN';
            state.zipValidationMessage = 'An error occurred while validating the zip code.';
        });

        // Add matchers for getShopperInfoThunk
        builder.addCase(getShopperInfoThunk.pending, (state) => {
            state.getShopperInfoLoading = true;
        });
        builder.addCase(getShopperInfoThunk.fulfilled, (state, action) => {
            state.getShopperInfoLoading = false;
            if (action.payload.type === 'bluesnap') {
                state.shopper = action.payload.data.shopper;
                state.customer = undefined;
            } else {
                state.customer = action.payload.data.customer;
                state.shopper = undefined;
            }
            state.manage_payment_sources_url = action.payload.data.manage_payment_sources_url;
            state.paymentSourceList = action.payload.data.paymentSourceList;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.address = action.payload.address1;
            state.zip = action.payload.zip;
            state.country = {
                code: action.payload.countryObject?.isoCode,
                name: action.payload.countryObject?.name
            };
            state.companyName = action.payload.companyName;
            state.vatId = action.payload.vatId;

        });
        builder.addCase(getShopperInfoThunk.rejected, (state) => {
            state.getShopperInfoLoading = false;
            state.shopper = undefined;
            state.customer = undefined;
        });
    }
});

export const paymentFormActions = PaymentFormSlice.actions;
export default PaymentFormSlice.reducer;