import NewTaskGroupButton from "@components/tasks-action-bar/add-task-group-button/new-task-group-button";
import { PickerProps } from "@components/tasks/columns-cells/cell-interface";
import ToolbarButton from "@components/toolbar-button";
import { SettingsOutlined, ViewWeek } from "@mui/icons-material";
import { Button, Menu, MenuProps, Stack, alpha, styled, useMediaQuery, useTheme } from "@mui/material";
import { getBlueticksThemeSelector, getCurrentBoard, getSelectedBoardId } from "@reducers/BoardsSelectors";
import { ganttActions } from "@reducers/GanttReducers";
import { MODAL, modalActions } from "@reducers/ModalReducer";
import { pickerDialogActions } from "@reducers/PickerDialogReducer";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import SearchTasks from "@views/task-view/search-tasks/search-tasks";
import { useMemo, useState } from "react";
import { MdFileDownload, MdFileUpload } from "react-icons/md";
import TaskPanelHeaderImportButton from "../filter-button/import-button";
import OpenFilterPanelButton from "../filter-button/open-filters-panel-button";
import PersonButtonFilter from "../person-button-filter/person-button-filter";
import classes from "./tasks-panel-header.module.scss";
import { automation } from "@reducers/AutomationReducer";
import { MdAutoAwesome } from "react-icons/md";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";
import KanbanSettingsDrawer from "../kanban/kanban-settings-drawer";
import { Board } from "@models/board";
import { boardsSelectors } from "@reducers/DBServiceReducers";

type Props = {
  isDisplayNewGroupButton?: boolean;
  isDisplaySearchField?: boolean;
  isDisplayPersonButton?: boolean;
  isDisplayColumnsButton?: boolean;
  isDisplayImportButton?: boolean;
  isDisplayExportButton?: boolean;
  isFromCampaign?: boolean;
}


function TasksPanelHeader({
  isDisplayNewGroupButton = true,
  isDisplaySearchField = true,
  isDisplayPersonButton = true,
  isDisplayColumnsButton = true,
  isDisplayImportButton = true,
  isDisplayExportButton = true,
  isFromCampaign = false,
}: Props) {
  const { t } = useTranslation(namespace);
  const _theme = useAppSelector((state) => getBlueticksThemeSelector(state))
  const currentBoard = useAppSelector(getCurrentBoard)
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const direction = useAppSelector((state) => state.UserReducer.direction);
  const currentBoardView = useAppSelector((state) => state.TasksReducer.currentBoardView)
  const selectedBoardId = useAppSelector(getSelectedBoardId);
  const board: Board = useAppSelector((state) =>
    boardsSelectors.selectById(state, selectedBoardId)
  );

  const [anchorElImportMenu, setAnchorElImportMenu] = useState<null | HTMLElement>(null);
  const openImportMenu = Boolean(anchorElImportMenu);

  const dispatch = useAppDispatch();
  function openExportModal() {
    dispatch(modalActions.setComponentToRender(MODAL.EXPORT_TO_EXCEL));
  }

  function openImportModal() {
    if (isFromCampaign) {
      dispatch(
        modalActions.setOpenedby('campaign')
      );
    }
    dispatch(
      modalActions.setComponentToRender(MODAL.IMPORT_EXCEL_TO_BLUETICKS)
    );
  }

  function openAutomationsModal() {
    dispatch(
      automation.setIsShown(true)
    );
  }


  const pickerType = useMemo(() => {
    if (currentBoardView.type === 'board') {
      return 'hide-columns-dialog'
    }
    if (currentBoardView.type === 'boardView') {
      return 'board-view-included-columns-dialog'
    }
    if (currentBoardView.type === 'kanban') {
      return 'kanban-column-picker'
    }

    if (currentBoardView.type === 'gantt') {
      return 'gantt-drawer'
    }
    if (currentBoardView.type === 'form') {
      return 'forms-included-columns-dialog'
    }
    if (currentBoardView.type === 'workload') {
      return 'workload-included-columns-dialog'
    }
  }, [currentBoardView])
  const pickerAnchorId = useMemo(() => {
    if (currentBoardView.type === 'board') {
      return `hideColumns_${currentBoard?._id}`
    }
    if (currentBoardView.type === 'kanban') {
      return `kanbanDrawer_${currentBoard?._id}`
    } if (currentBoardView.type === 'gantt') {
      return `ganttDrawer_${currentBoard?._id}`
    }
    if (currentBoardView.type === 'form') {
      return `form_column_${currentBoard?._id}`
    }
    if (currentBoardView.type === 'workload') {
      return `workload_column_${currentBoard?._id}`
    }
    if (currentBoardView.type === 'boardView') {
      return `boardView_column_${currentBoard?._id}`
    }
  }, [currentBoard?._id, currentBoardView])

  const togglePicker = ({
    anchorId,
    pickerType
  }) => {
    dispatch(pickerDialogActions.togglePicker({
      pickerAnchorId: anchorId,
      pickerProps: {
        boardId: currentBoard?._id,
        type: pickerType
      } as PickerProps
    }))
  }

  const ganttSettingsMode = useAppSelector((state) => state.GanttReducer.settingsMode)

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }))


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElImportMenu(event.currentTarget);
  };


  return (
    <Stack direction="row" sx={{
      //  paddingBottom: "52px",
      display: (currentBoardView.type === 'form' || currentBoardView.type === 'workload' || currentBoardView.type === 'gantt') ? 'none' : 'block',
      backgroundColor: _theme?.boards?.tasksPanel?.background,
    }}>
      <div className={classes.headerContainer}>
        <Stack direction="row" spacing={0.7} className={classes.header} style={{ backgroundColor: _theme?.boards?.tasksPanel?.background }}>
          {isDisplayNewGroupButton && currentBoardView.type === "board" && <NewTaskGroupButton position="top" />}

          {isDesktop && (
            <Stack direction="row" spacing={0.7}>
              <>
                {isDisplaySearchField && <SearchTasks />}
                {isDisplayPersonButton && <PersonButtonFilter />}
                <OpenFilterPanelButton />



                {/* <Button
                  id={pickerAnchorId}
                  key={pickerAnchorId}
                  onClick={() => togglePicker({
                    anchorId: pickerAnchorId,
                    pickerType: pickerType,
                  })}
                  startIcon={<ViewWeek style={{ width: 16, height: 16, marginInlineEnd: "3px" }} />}
                  className={classes.button_col}
                  sx={{
                    cursor: "pointer",
                    opacity: '0.5',
                    flexWrap: 'nowrap',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                    height: '36px',
                    padding: '0 16px',
                    fontSize: '14px',
                    borderRadius: '8px',
                    fontWeight: '600',
                    border: '0',
                    backgroundColor: 'transparent',
                    outline: 'none',
                    color: 'var(--secondary-text-color)',
                    display: 'flex',
                    textTransform: 'none',
                  }}>Columns</Button> */}

                {
                  isDisplayColumnsButton &&
                  <ToolbarButton
                    id={pickerAnchorId}
                    key={pickerAnchorId}
                    startIconSVG={ViewWeek}
                    toolTipTitle={t('tasksPanelHeader.hideColumnsTooltip')}
                    onClickAction={() => togglePicker({
                      anchorId: pickerAnchorId,
                      pickerType: pickerType,
                    })}
                    buttonText={t('tasksPanelHeader.columns')}
                    className={classes.button_col}
                  />
                }

                {
                  isDisplayImportButton &&
                  <TaskPanelHeaderImportButton
                    isFromCampaign={isFromCampaign}
                  />
                }


                {/* {isDisplayExportButton &&
                  <>
                    <ToolbarButton
                      startIconSVG={MdFileUpload}
                      onClickAction={openExportModal}
                      buttonText={t('tasksPanelHeader.export')}
                      className={classes.button_col}
                      toolTipTitle={t('tasksPanelHeader.exportDataTooltip')}
                    />
                    {currentBoardView.type === 'gantt' && (<Button
                      onClick={
                        () => dispatch(ganttActions.setSettingsMode(!ganttSettingsMode))
                      }
                      className={classes.button_col}
                      startIcon={<SettingsOutlined style={{ width: 24, height: 24 }} />}
                      value={t('tasksPanelHeader.export')}
                      style={{
                        display: currentBoardView.type === 'gantt' ? 'block' : 'none',
                        position: "absolute",
                        right: 0,
                      }}
                    />)
                    }
                  </>
                } */}

                {/* <ToolbarButton
                    startIconSVG={MdFileDownload}
                    onClickAction={openImportModal}
                    buttonText={t('tasksPanelHeader.import')}
                    toolTipTitle={t('tasksPanelHeader.importDataTooltip')}
                    className={classes.button_col}
                  /> */}


                {/* <ToolbarButton
                  startIconSVG={MdFileUpload}
                  onClickAction={openExportModal}
                  buttonText={t('tasksPanelHeader.export')}
                  toolTipTitle={t('tasksPanelHeader.exportToExcel')}
                  className={classes.button_col}
                /> */}

                {currentBoardView.type === 'gantt' && (<Button
                  onClick={
                    () => dispatch(ganttActions.setSettingsMode(!ganttSettingsMode))
                  }
                  className={classes.button_col}
                  startIcon={<SettingsOutlined style={{ width: 24, height: 24 }} />}
                  value={t('tasksPanelHeader.export')}
                  style={{
                    display: currentBoardView.type === 'gantt' ? 'block' : 'none',
                    position: "absolute",
                    right: 0,
                  }}
                />)}
                {board?.displayBetaFeatures &&
                  <ToolbarButton
                    onClickAction={openAutomationsModal}
                    className={classes.button_col}
                    startIcon={<MdAutoAwesome style={{ width: 16, height: 16 }} />}
                    buttonText="Automations"
                    toolTipTitle="Create automations"
                  />
                }




              </>
            </Stack >
          )
          }

          {/* <Button
              className={classes.button_col}
              icon={<FaFilter />} value="Filter"
          />
          <Button
              className={classes.button_col}
              icon={<MdViewColumn />} value="Columns"
          />
          <Button
              className={classes.button_col}
              icon={<AiOutlineColumnHeight />} value="Item Height"
          /> */}
          {currentBoardView.type === "kanban" &&
            <KanbanSettingsDrawer kanbanId={currentBoardView.id} />

          }

        </Stack >
      </div >
    </Stack >
  );
}

// type ButtonProps = {
//     className: any,
//     icon: any,
//     value: string;
//     onClick: () => void;
// }

// export function Button({ className, icon, value, onClick }: ButtonProps) {
//     return (
//         <button onClick={onClick} className={className}>
//             {icon}
//             <span>{value}</span>
//         </button>
//     )
// }

export default TasksPanelHeader;
