import styled from '@emotion/styled'
import { Button, Box, Popper, ClickAwayListener } from '@mui/material'
import QuickMessage from './quick-message/quick-message'
import classes from './bt-quick-panel.module.scss'
import QuickTask from './quick-task/quick-task'
import StartChat from './start-chat/start-chat'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useEffect } from 'react'
import { QUICK_PANEL } from 'reusable/interfaces'
import { ReactComponent as QuickTaskIcon } from '@assets/new-task.svg'
import { btQuickPanelActions } from '@reducers/BtQuickPanelReducer'



const MenuItemButon = styled(Button)({
    width: 'fit-content',
    backgroundColor: '#fff',
    color: '#111',
    borderRadius: 29,
    marginBottom: 5,
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#fff',
    }
})



export default function PanelMenuPopper() {
    const dispatch = useAppDispatch()
    const targetComponent = useAppSelector((state) => state.BtQuickPanelReducer.targetComponent)
    const popperId = useAppSelector((state) => state.BtQuickPanelReducer.popperId)
    const isDragging = useAppSelector((state) => state.BtQuickPanelReducer.isDragging)

    useEffect(() => {
        return () => {
            dispatch(btQuickPanelActions.setTargetComponent(""))
        }
    }, [dispatch])


    let anchorEl
    if (popperId === QUICK_PANEL.MAIN_POPPER_ID) {
        anchorEl = document.getElementById(popperId)
    }

    if (popperId !== QUICK_PANEL.MAIN_POPPER_ID || isDragging) return null

    return (
        <ClickAwayListener
            onClickAway={() => dispatch(btQuickPanelActions.setPopperId(''))}
        >
            <Popper
                anchorEl={anchorEl}
                open={popperId === QUICK_PANEL.MAIN_POPPER_ID}
                id={popperId}
                placement='right-end'
                sx={{ zIndex: 1390 }}
            >
                <Box sx={{ mx: 1 }}>
                    {targetComponent === "" ? (
                        <Box className={classes.Menu}>
                            <MenuItemButon
                                variant='contained'
                                startIcon={<QuickTaskIcon />}
                                onClick={() => dispatch(btQuickPanelActions.setTargetComponent(QUICK_PANEL.TASK))}>
                                Quick task
                            </MenuItemButon>
                            {/* <MenuItemButon onClick={() => clickHandler('message')} startIcon={<AddQuickMessageIcon />} variant='contained'>
                                Quick message
                            </MenuItemButon>
                            <MenuItemButon onClick={() => clickHandler('chat')} startIcon={<StartAchatIcon />} variant='contained'>
                                Start a chat
                            </MenuItemButon> */}

                        </Box>
                    ) : (<Box className={classes.Menu}>
                        {targetComponent === QUICK_PANEL.TASK && <QuickTask />}
                        {targetComponent === QUICK_PANEL.MESSAGE && <QuickMessage />}
                        {targetComponent === QUICK_PANEL.CHAT && <StartChat />}
                         </Box>)
                    }
                </Box>
            </Popper>
        </ClickAwayListener>
    )
}
