export interface MockWhatsappUser {
  waId: string;
  waName: string;
}

export const whatsAppContacts = [
  {
    "waId": "120363022993732524@g.us",
    "waName": "יונתן נועם וגיא"
  },
  {
    "waId": "972526847417@c.us",
    "waName": "+972 52-684-7417"
  },
  {
    "waId": "972502209099-1427964966@g.us",
    "waName": "ברצלונה באילת ?"
  },
  {
    "waId": "120363029270759158@g.us",
    "waName": "K8S BonBon"
  },
  {
    "waId": "972546757919@c.us",
    "waName": "+972 54-675-7919"
  },
  {
    "waId": "972544325389@c.us",
    "waName": "+972 54-432-5389"
  },
  {
    "waId": "972502452234@c.us",
    "waName": "+972 50-245-2234"
  },
  {
    "waId": "972505923293@c.us",
    "waName": "+972 50-592-3293"
  },
  {
    "waId": "972528853701@c.us",
    "waName": "+972 52-885-3701"
  },
  {
    "waId": "120363025473962007@g.us",
    "waName": "עין טובה פיתוח"
  },
  {
    "waId": "972544256669@c.us",
    "waName": "+972 54-425-6669"
  },
  {
    "waId": "972586666789-1357061568@g.us",
    "waName": "מזוז קלאב "
  },
  {
    "waId": "972546601130@c.us",
    "waName": "+972 54-660-1130"
  },
  {
    "waId": "972586307949@c.us",
    "waName": "+972 58-630-7949"
  },
  {
    "waId": "972545494021@c.us",
    "waName": "+972 54-549-4021"
  },
  {
    "waId": "972502452234-1377978278@g.us",
    "waName": "משפחת כ\"ץ"
  },
  {
    "waId": "972546603611@c.us",
    "waName": "+972 54-660-3611"
  },
  {
    "waId": "972504149620@c.us",
    "waName": "+972 50-414-9620"
  },
  {
    "waId": "120363025690136237@g.us",
    "waName": "Blueticks Support"
  },
  {
    "waId": "120363043423885955@g.us",
    "waName": "תשלום שכירות דירה"
  },
  {
    "waId": "260961598265@c.us",
    "waName": "+260 96 1598265"
  },
  {
    "waId": "972548275828-1620720320@g.us",
    "waName": "יונתן בני ונועם"
  },
  {
    "waId": "120363046981984234@g.us",
    "waName": "כץ לבנברג מזוז"
  },
  {
    "waId": "972505283239@c.us",
    "waName": "+972 50-528-3239"
  },
  {
    "waId": "972547838465@c.us",
    "waName": "+972 54-783-8465"
  },
  {
    "waId": "120363027738300253@g.us",
    "waName": "גן חופית  🐦🏠❤"
  },
  {
    "waId": "972548275828-1605525646@g.us",
    "waName": "test"
  },
  {
    "waId": "120363062012873423@g.us",
    "waName": "הופעה עדן חסון"
  },
  {
    "waId": "972504778844@c.us",
    "waName": "+972 50-477-8844"
  },
  {
    "waId": "120363046744251159@g.us",
    "waName": "פקטורינג - CAPITAL 360"
  },
  {
    "waId": "972509444776@c.us",
    "waName": "+972 50-944-4776"
  },
  {
    "waId": "972506906969@c.us",
    "waName": "+972 50-690-6969"
  },
  {
    "waId": "972542090160@c.us",
    "waName": "+972 54-209-0160"
  },
  {
    "waId": "972545555265@c.us",
    "waName": "+972 54-555-5265"
  },
  {
    "waId": "972529555057@c.us",
    "waName": "+972 52-955-5057"
  },
  {
    "waId": "972507471091@c.us",
    "waName": "+972 50-747-1091"
  },
  {
    "waId": "972524587110@c.us",
    "waName": "+972 52-458-7110"
  },
  {
    "waId": "972525972208@c.us",
    "waName": "+972 52-597-2208"
  },
  {
    "waId": "120363066713024507@g.us",
    "waName": "נתנאל עמית ונועם"
  },
  {
    "waId": "120363042655341787@g.us",
    "waName": "Shlomo - k8s team"
  },
  {
    "waId": "972528082015@c.us",
    "waName": "+972 52-808-2015"
  },
  {
    "waId": "972523292333@c.us",
    "waName": "+972 52-329-2333"
  },
  {
    "waId": "972524440131@c.us",
    "waName": "+972 52-444-0131"
  },
  {
    "waId": "120363039900136716@g.us",
    "waName": "Blueticks management"
  },
  {
    "waId": "120363047050950197@g.us",
    "waName": "Gorilla - Tudo Cyprus"
  },
  {
    "waId": "972525656601@c.us",
    "waName": "+972 52-565-6601"
  },
  {
    "waId": "972546666183@c.us",
    "waName": "+972 54-666-6183"
  },
  {
    "waId": "972543138999@c.us",
    "waName": "+972 54-313-8999"
  },
  {
    "waId": "120363041981609643@g.us",
    "waName": "Blueticks PR in Brazil"
  },
  {
    "waId": "972546638481@c.us",
    "waName": "+972 54-663-8481"
  },
  {
    "waId": "972509434305@c.us",
    "waName": "+972 50-943-4305"
  },
  {
    "waId": "972535277071@c.us",
    "waName": "+972 53-527-7071"
  },
  {
    "waId": "972544432003@c.us",
    "waName": "+972 54-443-2003"
  },
  {
    "waId": "972526460014@c.us",
    "waName": "+972 52-646-0014"
  },
  {
    "waId": "120363040047114071@g.us",
    "waName": "כפיר גוני ונועם"
  },
  {
    "waId": "972547762811@c.us",
    "waName": "+972 54-776-2811"
  },
  {
    "waId": "120363075927377495@g.us",
    "waName": "ולדימיר יוגין ונועם"
  },
  {
    "waId": "972504772170@c.us",
    "waName": "+972 50-477-2170"
  },
  {
    "waId": "972525796416@c.us",
    "waName": "+972 52-579-6416"
  },
  {
    "waId": "120363040747818397@g.us",
    "waName": "שרגא אלי ונועם"
  },
  {
    "waId": "972548275828-1630347496@g.us",
    "waName": "Blueticks R&D"
  },
  {
    "waId": "972545738031@c.us",
    "waName": "מקדונלד'ס ישראל | McDonald's Israel"
  },
  {
    "waId": "972548275828-1617877808@g.us",
    "waName": "עמית יונתן ונועם"
  },
  {
    "waId": "120363027870653290@g.us",
    "waName": "העלאת אפליקציה רונית רפאל"
  },
  {
    "waId": "972544932323@c.us",
    "waName": "+972 54-493-2323"
  },
  {
    "waId": "972527879997@c.us",
    "waName": "+972 52-787-9997"
  },
  {
    "waId": "972504743329@c.us",
    "waName": "+972 50-474-3329"
  },
  {
    "waId": "972503433454@c.us",
    "waName": "+972 50-343-3454"
  },
  {
    "waId": "972525262244@c.us",
    "waName": "+972 52-526-2244"
  },
  {
    "waId": "972522327244@c.us",
    "waName": "+972 52-232-7244"
  },
  {
    "waId": "120363025034081923@g.us",
    "waName": "חבר מביא חבר yes"
  },
  {
    "waId": "120363047122751113@g.us",
    "waName": "עמית מתן ונועם"
  },
  {
    "waId": "972558807911-1493534654@g.us",
    "waName": "שבט אחים ואחיות❤"
  },
  {
    "waId": "120363027411754937@g.us",
    "waName": "עיריית תל אביב-דפי משרות"
  },
  {
    "waId": "97249912121@c.us",
    "waName": "+972 49912121"
  },
  {
    "waId": "972505570370@c.us",
    "waName": "+972 50-557-0370"
  },
  {
    "waId": "972508807400@c.us",
    "waName": "+972 50-880-7400"
  },
  {
    "waId": "972507845857@c.us",
    "waName": "+972 50-784-5857"
  },
  {
    "waId": "972553066862@c.us",
    "waName": "+972 55-306-6862"
  },
  {
    "waId": "972528693572@c.us",
    "waName": "+972 52-869-3572"
  },
  {
    "waId": "972545750800@c.us",
    "waName": "+972 54-575-0800"
  },
  {
    "waId": "972509812780@c.us",
    "waName": "+972 50-981-2780"
  },
  {
    "waId": "972548275828-1604839041@g.us",
    "waName": "אפיק חנות ספרים🌟"
  },
  {
    "waId": "972539240820@c.us",
    "waName": "+972 53-924-0820"
  },
  {
    "waId": "972558896324@c.us",
    "waName": "+972 55-889-6324"
  },
  {
    "waId": "972542405446@c.us",
    "waName": "+972 54-240-5446"
  },
  {
    "waId": "972508147677@c.us",
    "waName": "+972 50-814-7677"
  },
  {
    "waId": "972524564342@c.us",
    "waName": "+972 52-456-4342"
  },
  {
    "waId": "972502244476@c.us",
    "waName": "+972 50-224-4476"
  },
  {
    "waId": "972544486639@c.us",
    "waName": "+972 54-448-6639"
  },
  {
    "waId": "972528874114-1561446912@g.us",
    "waName": "צוות רוקט"
  },
  {
    "waId": "972505240159@c.us",
    "waName": "+972 50-524-0159"
  },
  {
    "waId": "972549490781@c.us",
    "waName": "+972 54-949-0781"
  },
  {
    "waId": "972548275828-1604613864@g.us",
    "waName": "all around player🌟"
  },
  {
    "waId": "972526004427@c.us",
    "waName": "+972 52-600-4427"
  },
  {
    "waId": "120363024528540482@g.us",
    "waName": "שרגא בני ונועם"
  },
  {
    "waId": "972547844550@c.us",
    "waName": "+972 54-784-4550"
  },
  {
    "waId": "972548275828-1608858763@g.us",
    "waName": "תום אור ונועם"
  },
  {
    "waId": "972524258705@c.us",
    "waName": "+972 52-425-8705"
  },
  {
    "waId": "972732130505@c.us",
    "waName": "iStore Business"
  },
  {
    "waId": "972559429945@c.us",
    "waName": "+972 55-942-9945"
  },
  {
    "waId": "120363043096147461@g.us",
    "waName": "חבר מביא חבר האוניברסיטה"
  },
  {
    "waId": "120363043772206696@g.us",
    "waName": "Blueticks Support Team"
  },
  {
    "waId": "120363042606450616@g.us",
    "waName": "טופס PWC נילוסופט"
  },
  {
    "waId": "120363046493459553@g.us",
    "waName": "יונתן אוריאל ונועם"
  },
  {
    "waId": "972528874114-1526542685@g.us",
    "waName": "תום יונתן ונועם"
  },
  {
    "waId": "972527018587@c.us",
    "waName": "+972 52-701-8587"
  },
  {
    "waId": "972528643030@c.us",
    "waName": "+972 52-864-3030"
  },
  {
    "waId": "972508230016@c.us",
    "waName": "+972 50-823-0016"
  },
  {
    "waId": "972535300479@c.us",
    "waName": "+972 53-530-0479"
  },
  {
    "waId": "972509898948@c.us",
    "waName": "+972 50-989-8948"
  },
  {
    "waId": "972527574846@c.us",
    "waName": "+972 52-757-4846"
  },
  {
    "waId": "972558807911@c.us",
    "waName": "+972 55-880-7911"
  },
  {
    "waId": "972585140223@c.us",
    "waName": "+972 58-514-0223"
  },
  {
    "waId": "972584333367@c.us",
    "waName": "+972 58-433-3367"
  },
  {
    "waId": "972525214167@c.us",
    "waName": "+972 52-521-4167"
  },
  {
    "waId": "972549747214@c.us",
    "waName": "+972 54-974-7214"
  },
  {
    "waId": "972507356644@c.us",
    "waName": "+972 50-735-6644"
  },
  {
    "waId": "120363028410464910@g.us",
    "waName": "אפליקציה דיטוקס הגר"
  },
  {
    "waId": "972505330820@c.us",
    "waName": "+972 50-533-0820"
  },
  {
    "waId": "972542333128@c.us",
    "waName": "+972 54-233-3128"
  },
  {
    "waId": "972543028423@c.us",
    "waName": "+972 54-302-8423"
  },
  {
    "waId": "120363046984674050@g.us",
    "waName": "העברת מקל בפיפלביז"
  },
  {
    "waId": "972544325389-1618902641@g.us",
    "waName": "Blueticks 2.0☀️"
  },
  {
    "waId": "972543008636@c.us",
    "waName": "+972 54-300-8636"
  },
  {
    "waId": "120363028612070750@g.us",
    "waName": "שלמה - רשת מוסכים K8S"
  },
  {
    "waId": "972502299432@c.us",
    "waName": "+972 50-229-9432"
  },
  {
    "waId": "120363040172877073@g.us",
    "waName": "יונתן מתן ונועם"
  },
  {
    "waId": "972548275828-1607716745@g.us",
    "waName": "צעירי המשפחה😎"
  },
  {
    "waId": "972504753333@c.us",
    "waName": "+972 50-475-3333"
  },
  {
    "waId": "972542368882@c.us",
    "waName": "+972 54-236-8882"
  },
  {
    "waId": "972547805947@c.us",
    "waName": "+972 54-780-5947"
  },
  {
    "waId": "972548275828-1626599160@g.us",
    "waName": "יונתן נועם ואלון"
  },
  {
    "waId": "972545415357@c.us",
    "waName": "+972 54-541-5357"
  },
  {
    "waId": "972548275828-1612253652@g.us",
    "waName": "סיניה גיא ונועם"
  },
  {
    "waId": "972505798266@c.us",
    "waName": "+972 50-579-8266"
  },
  {
    "waId": "972547402632@c.us",
    "waName": "+972 54-740-2632"
  },
  {
    "waId": "972503303377@c.us",
    "waName": "+972 50-330-3377"
  },
  {
    "waId": "120363046400304623@g.us",
    "waName": "מחשבים חדשים"
  },
  {
    "waId": "97226234882@c.us",
    "waName": "+972 26234882"
  },
  {
    "waId": "972523844078@c.us",
    "waName": "+972 52-384-4078"
  },
  {
    "waId": "972544540530@c.us",
    "waName": "+972 54-454-0530"
  },
  {
    "waId": "972523343625@c.us",
    "waName": "+972 52-334-3625"
  },
  {
    "waId": "972548275828-1605558638@g.us",
    "waName": "חשבוניות לעסק"
  },
  {
    "waId": "972548275828-1609176746@g.us",
    "waName": "נועם דור ותום"
  },
  {
    "waId": "972522412389@c.us",
    "waName": "+972 52-241-2389"
  },
  {
    "waId": "972559911262@c.us",
    "waName": "+972 55-991-1262"
  },
  {
    "waId": "972548275828-1624993989@g.us",
    "waName": "3 המוסקטרים ⭐"
  },
  {
    "waId": "972548011010@c.us",
    "waName": "+972 54-801-1010"
  },
  {
    "waId": "972547141521@c.us",
    "waName": "+972 54-714-1521"
  },
  {
    "waId": "972526808740@c.us",
    "waName": "+972 52-680-8740"
  },
  {
    "waId": "972545801380@c.us",
    "waName": "+972 54-580-1380"
  },
  {
    "waId": "447971976260@c.us",
    "waName": "+44 7971 976260"
  },
  {
    "waId": "972508555550@c.us",
    "waName": "+972 50-855-5550"
  },
  {
    "waId": "972557002220@c.us",
    "waName": "+972 55-700-2220"
  },
  {
    "waId": "972523258482@c.us",
    "waName": "+972 52-325-8482"
  },
  {
    "waId": "972523442199@c.us",
    "waName": "+972 52-344-2199"
  },
  {
    "waId": "120363025098818271@g.us",
    "waName": "יונתן מרגו ונועם"
  },
  {
    "waId": "972544749803@c.us",
    "waName": "+972 54-474-9803"
  },
  {
    "waId": "972542550226@c.us",
    "waName": "+972 54-255-0226"
  },
  {
    "waId": "972546666185@c.us",
    "waName": "+972 54-666-6185"
  },
  {
    "waId": "120363029836085340@g.us",
    "waName": "בילו 38 😜"
  },
  {
    "waId": "972524304276@c.us",
    "waName": "+972 52-430-4276"
  },
  {
    "waId": "972546666182@c.us",
    "waName": "+972 54-666-6182"
  },
  {
    "waId": "972544265552@c.us",
    "waName": "+972 54-426-5552"
  },
  {
    "waId": "972503455712@c.us",
    "waName": "+972 50-345-5712"
  },
  {
    "waId": "972545656675@c.us",
    "waName": "+972 54-565-6675"
  },
  {
    "waId": "972526720445@c.us",
    "waName": "+972 52-672-0445"
  },
  {
    "waId": "972543084066@c.us",
    "waName": "+972 54-308-4066"
  },
  {
    "waId": "120363028311943533@g.us",
    "waName": "תשלום חוב - נועם מזוז"
  },
  {
    "waId": "972508524202@c.us",
    "waName": "+972 50-852-4202"
  },
  {
    "waId": "972545513444@c.us",
    "waName": "+972 54-551-3444"
  },
  {
    "waId": "972526150996@c.us",
    "waName": "+972 52-615-0996"
  },
  {
    "waId": "972525245678@c.us",
    "waName": "+972 52-524-5678"
  },
  {
    "waId": "972544325389-1607371725@g.us",
    "waName": "נועם יונתן ואביחי"
  },
  {
    "waId": "972544325389-1611826562@g.us",
    "waName": "chit chat"
  },
  {
    "waId": "972548275828-1613392128@g.us",
    "waName": "speedster"
  },
  {
    "waId": "972524790784@c.us",
    "waName": "+972 52-479-0784"
  },
  {
    "waId": "972545615070@c.us",
    "waName": "+972 54-561-5070"
  },
  {
    "waId": "972546807202@c.us",
    "waName": "+972 54-680-7202"
  },
  {
    "waId": "120363025490628936@g.us",
    "waName": "רונית נועם ועדי"
  },
  {
    "waId": "972543377428@c.us",
    "waName": "+972 54-337-7428"
  },
  {
    "waId": "972502222451@c.us",
    "waName": "+972 50-222-2451"
  },
  {
    "waId": "972547477145@c.us",
    "waName": "+972 54-747-7145"
  },
  {
    "waId": "972526417438@c.us",
    "waName": "+972 52-641-7438"
  },
  {
    "waId": "972546148723@c.us",
    "waName": "+972 54-614-8723"
  },
  {
    "waId": "120363040836530039@g.us",
    "waName": "יוגה - בית ראשונים"
  },
  {
    "waId": "120363040785150087@g.us",
    "waName": "פילאטיס - גליל ים"
  },
  {
    "waId": "120363039603964182@g.us",
    "waName": "פילאטיס - בית קינן"
  },
  {
    "waId": "120363022773947737@g.us",
    "waName": "יוגה - חוף אכדיה"
  },
  {
    "waId": "120363039709701424@g.us",
    "waName": "יוגה - גן בן שפר"
  },
  {
    "waId": "120363023648973045@g.us",
    "waName": "סלסה - נעים בעיר"
  },
  {
    "waId": "120363039953572647@g.us",
    "waName": "אימון רצועות - נעים בעיר"
  },
  {
    "waId": "120363042805405559@g.us",
    "waName": "כדורעף חופים - נעים בעיר"
  },
  {
    "waId": "972542080881@c.us",
    "waName": "+972 54-208-0881"
  },
  {
    "waId": "972546238686@c.us",
    "waName": "+972 54-623-8686"
  },
  {
    "waId": "972502452232@c.us",
    "waName": "+972 50-245-2232"
  },
  {
    "waId": "972529439706@c.us",
    "waName": "+972 52-943-9706"
  },
  {
    "waId": "972525643858@c.us",
    "waName": "+972 52-564-3858"
  },
  {
    "waId": "972549191741@c.us",
    "waName": "+972 54-919-1741"
  },
  {
    "waId": "972585534567@c.us",
    "waName": "+972 58-553-4567"
  },
  {
    "waId": "972507902455@c.us",
    "waName": "+972 50-790-2455"
  },
  {
    "waId": "972528531588@c.us",
    "waName": "+972 52-853-1588"
  },
  {
    "waId": "972584477807@c.us",
    "waName": "+972 58-447-7807"
  },
  {
    "waId": "972548275828-1606647192@g.us",
    "waName": "דור מתן ונועם"
  },
  {
    "waId": "120363029419492419@g.us",
    "waName": "שכירות אורלי נועם ואביחי"
  },
  {
    "waId": "972506212780@c.us",
    "waName": "+972 50-621-2780"
  },
  {
    "waId": "972548275828-1609145711@g.us",
    "waName": "קמפוס דיגיטלי DEV"
  },
  {
    "waId": "120363045883549374@g.us",
    "waName": "K8s citrix"
  },
  {
    "waId": "972502245088@c.us",
    "waName": "+972 50-224-5088"
  },
  {
    "waId": "972548275828@c.us",
    "waName": "+972 54-827-5828"
  },
  {
    "waId": "972544555194@c.us",
    "waName": "+972 54-455-5194"
  },
  {
    "waId": "972548009307@c.us",
    "waName": "+972 54-800-9307"
  },
  {
    "waId": "972544845940@c.us",
    "waName": "+972 54-484-5940"
  },
  {
    "waId": "120363027794695423@g.us",
    "waName": "שכירות אורלי נועם ואביחי"
  },
  {
    "waId": "972528545420@c.us",
    "waName": "+972 52-854-5420"
  },
  {
    "waId": "972505260045@c.us",
    "waName": "+972 50-526-0045"
  },
  {
    "waId": "972547920828@c.us",
    "waName": "+972 54-792-0828"
  },
  {
    "waId": "972508870071@c.us",
    "waName": "+972 50-887-0071"
  },
  {
    "waId": "972557000103@c.us",
    "waName": "חברת החשמל"
  },
  {
    "waId": "972522330027@c.us",
    "waName": "+972 52-233-0027"
  },
  {
    "waId": "972528874114-1595374173@g.us",
    "waName": "Blueticks"
  },
  {
    "waId": "972545910250@c.us",
    "waName": "+972 54-591-0250"
  },
  {
    "waId": "972543028423-1567542188@g.us",
    "waName": "אותיות פורחות באויר"
  },
  {
    "waId": "972504778800@c.us",
    "waName": "+972 50-477-8800"
  },
  {
    "waId": "120363046367591788@g.us",
    "waName": "אתר Jobinfo"
  },
  {
    "waId": "972505691063@c.us",
    "waName": "+972 50-569-1063"
  },
  {
    "waId": "972544509995@c.us",
    "waName": "+972 54-450-9995"
  },
  {
    "waId": "120363024757807832@g.us",
    "waName": "GSL Kickoff"
  },
  {
    "waId": "972537540398@c.us",
    "waName": "+972 53-754-0398"
  },
  {
    "waId": "972506695223@c.us",
    "waName": "+972 50-669-5223"
  },
  {
    "waId": "972506781323@c.us",
    "waName": "+972 50-678-1323"
  },
  {
    "waId": "972523510752@c.us",
    "waName": "+972 52-351-0752"
  },
  {
    "waId": "972586150996@c.us",
    "waName": "+972 58-615-0996"
  },
  {
    "waId": "972544435566@c.us",
    "waName": "+972 54-443-5566"
  },
  {
    "waId": "120363028381504726@g.us",
    "waName": "new comu"
  },
  {
    "waId": "120363042674029575@g.us",
    "waName": "Blueticks + J&A"
  },
  {
    "waId": "972507210393@c.us",
    "waName": "+972 50-721-0393"
  },
  {
    "waId": "972546208941@c.us",
    "waName": "+972 54-620-8941"
  },
  {
    "waId": "79854153542@c.us",
    "waName": "+7 985 415-35-42"
  },
  {
    "waId": "120363026829857104@g.us",
    "waName": "דפי קריירה עיריית תל אביב"
  },
  {
    "waId": "972546668151@c.us",
    "waName": "+972 54-666-8151"
  },
  {
    "waId": "972525741076@c.us",
    "waName": "+972 52-574-1076"
  },
  {
    "waId": "41789099000@c.us",
    "waName": "+41 78 909 90 00"
  },
  {
    "waId": "120363027098523581@g.us",
    "waName": "ניוד פנימי ביטוח ישיר"
  },
  {
    "waId": "972504224250@c.us",
    "waName": "+972 50-422-4250"
  },
  {
    "waId": "491623193687@c.us",
    "waName": "+49 162 3193687"
  },
  {
    "waId": "972544435520@c.us",
    "waName": "+972 54-443-5520"
  },
  {
    "waId": "972548301013@c.us",
    "waName": "+972 54-830-1013"
  },
  {
    "waId": "4915236842406@c.us",
    "waName": "+49 1523 6842406"
  },
  {
    "waId": "972587778888@c.us",
    "waName": "גולן טלקום שירות"
  },
  {
    "waId": "120363022904863039@g.us",
    "waName": "GSL development"
  },
  {
    "waId": "972523451115@c.us",
    "waName": "+972 52-345-1115"
  },
  {
    "waId": "972507206410@c.us",
    "waName": "+972 50-720-6410"
  },
  {
    "waId": "972546812020@c.us",
    "waName": "+972 54-681-2020"
  },
  {
    "waId": "972504547745@c.us",
    "waName": "+972 50-454-7745"
  },
  {
    "waId": "972507717474@c.us",
    "waName": "+972 50-771-7474"
  },
  {
    "waId": "972503360310@c.us",
    "waName": "+972 50-336-0310"
  },
  {
    "waId": "972525258535@c.us",
    "waName": "+972 52-525-8535"
  },
  {
    "waId": "972522965065@c.us",
    "waName": "+972 52-296-5065"
  },
  {
    "waId": "972546227706@c.us",
    "waName": "+972 54-622-7706"
  },
  {
    "waId": "972524500291@c.us",
    "waName": "+972 52-450-0291"
  },
  {
    "waId": "972503075870@c.us",
    "waName": "+972 50-307-5870"
  },
  {
    "waId": "120363022472200702@g.us",
    "waName": "אוהד נועם ויונתן"
  },
  {
    "waId": "972544435819@c.us",
    "waName": "+972 54-443-5819"
  },
  {
    "waId": "972547081578@c.us",
    "waName": "+972 54-708-1578"
  },
  {
    "waId": "972502333038@c.us",
    "waName": "+972 50-233-3038"
  },
  {
    "waId": "972528667169@c.us",
    "waName": "+972 52-866-7169"
  },
  {
    "waId": "923212672848@c.us",
    "waName": "+92 321 2672848"
  },
  {
    "waId": "972545944952@c.us",
    "waName": "+972 54-594-4952"
  },
  {
    "waId": "972502055106@c.us",
    "waName": "+972 50-205-5106"
  },
  {
    "waId": "972508840489@c.us",
    "waName": "+972 50-884-0489"
  },
  {
    "waId": "972549586327@c.us",
    "waName": "+972 54-958-6327"
  },
  {
    "waId": "120363027120847599@g.us",
    "waName": "חיבור למודל 2023"
  },
  {
    "waId": "972523994088@c.us",
    "waName": "+972 52-399-4088"
  },
  {
    "waId": "972544848405@c.us",
    "waName": "+972 54-484-8405"
  },
  {
    "waId": "972548275828-1624871782@g.us",
    "waName": "emerj סגירת תשלום עם נועם"
  },
  {
    "waId": "972505330820-1611825651@g.us",
    "waName": "צרכים אותך נועם omega360"
  },
  {
    "waId": "972528874114-1597620113@g.us",
    "waName": "הצפות"
  },
  {
    "waId": "972544418104@c.us",
    "waName": "+972 54-441-8104"
  },
  {
    "waId": "972523531436@c.us",
    "waName": "+972 52-353-1436"
  },
  {
    "waId": "19177505158@c.us",
    "waName": "+1 (917) 750-5158"
  },
  {
    "waId": "120363042966768037@g.us",
    "waName": "הצפות שומר שבת"
  },
  {
    "waId": "972527313057@c.us",
    "waName": "+972 52-731-3057"
  },
  {
    "waId": "972542312743@c.us",
    "waName": "+972 54-231-2743"
  },
  {
    "waId": "120363045686452765@g.us",
    "waName": "דור נועם ועמית"
  },
  {
    "waId": "972549132019@c.us",
    "waName": "+972 54-913-2019"
  },
  {
    "waId": "972523451115-1619815782@g.us",
    "waName": "סברס אתר+אפליקציה"
  },
  {
    "waId": "972502147700@c.us",
    "waName": "+972 50-214-7700"
  },
  {
    "waId": "972529559755@c.us",
    "waName": "+972 52-955-9755"
  },
  {
    "waId": "12015654280@c.us",
    "waName": "+1 (201) 565-4280"
  },
  {
    "waId": "972548275828-1634750055@g.us",
    "waName": "Blueticks design"
  },
  {
    "waId": "972546666182-1510823674@g.us",
    "waName": "אבטחת מידע וסייבר פיפלביז"
  },
  {
    "waId": "972524801522@c.us",
    "waName": "+972 52-480-1522"
  },
  {
    "waId": "972545600464@c.us",
    "waName": "+972 54-560-0464"
  },
  {
    "waId": "972526453540@c.us",
    "waName": "+972 52-645-3540"
  },
  {
    "waId": "972543913950@c.us",
    "waName": "+972 54-391-3950"
  },
  {
    "waId": "972507724070@c.us",
    "waName": "+972 50-772-4070"
  },
  {
    "waId": "972587602018@c.us",
    "waName": "+972 58-760-2018"
  },
  {
    "waId": "972549991894@c.us",
    "waName": "+972 54-999-1894"
  },
  {
    "waId": "972545660055@c.us",
    "waName": "+972 54-566-0055"
  },
  {
    "waId": "972527067518@c.us",
    "waName": "+972 52-706-7518"
  },
  {
    "waId": "972544469539@c.us",
    "waName": "+972 54-446-9539"
  },
  {
    "waId": "972528468885@c.us",
    "waName": "+972 52-846-8885"
  },
  {
    "waId": "972522047980@c.us",
    "waName": "KSP"
  },
  {
    "waId": "972548275828-1621773139@g.us",
    "waName": "foodtrek פיתוח"
  },
  {
    "waId": "972542064093@c.us",
    "waName": "+972 54-206-4093"
  },
  {
    "waId": "972509807789@c.us",
    "waName": "+972 50-980-7789"
  },
  {
    "waId": "972527030414@c.us",
    "waName": "+972 52-703-0414"
  },
  {
    "waId": "972546677639@c.us",
    "waName": "+972 54-667-7639"
  },
  {
    "waId": "972584294541@c.us",
    "waName": "+972 58-429-4541"
  },
  {
    "waId": "972525323681@c.us",
    "waName": "+972 52-532-3681"
  },
  {
    "waId": "972544485380@c.us",
    "waName": "+972 54-448-5380"
  },
  {
    "waId": "972504690919@c.us",
    "waName": "+972 50-469-0919"
  },
  {
    "waId": "972506233939@c.us",
    "waName": "+972 50-623-3939"
  },
  {
    "waId": "972548073866@c.us",
    "waName": "+972 54-807-3866"
  },
  {
    "waId": "972548017328@c.us",
    "waName": "+972 54-801-7328"
  },
  {
    "waId": "972505728602@c.us",
    "waName": "+972 50-572-8602"
  },
  {
    "waId": "972548275828-1604613794@g.us",
    "waName": "AAP management🌟"
  },
  {
    "waId": "972526899988@c.us",
    "waName": "+972 52-689-9988"
  },
  {
    "waId": "972526453553@c.us",
    "waName": "+972 52-645-3553"
  },
  {
    "waId": "972508941194@c.us",
    "waName": "+972 50-894-1194"
  },
  {
    "waId": "972528940100@c.us",
    "waName": "+972 52-894-0100"
  },
  {
    "waId": "972506002000@c.us",
    "waName": "+972 50-600-2000"
  },
  {
    "waId": "972535303960@c.us",
    "waName": "+972 53-530-3960"
  },
  {
    "waId": "972548275828-1606334028@g.us",
    "waName": "easywed🌟 אתר"
  },
  {
    "waId": "972507512777@c.us",
    "waName": "+972 50-751-2777"
  },
  {
    "waId": "442037691599@c.us",
    "waName": "+44 20 3769 1599"
  },
  {
    "waId": "972586313468@c.us",
    "waName": "+972 58-631-3468"
  },
  {
    "waId": "120363040409847915@g.us",
    "waName": "pipelbiz dashboard"
  },
  {
    "waId": "972502510852@c.us",
    "waName": "+972 50-251-0852"
  },
  {
    "waId": "972505344263@c.us",
    "waName": "+972 50-534-4263"
  },
  {
    "waId": "120363039171243786@g.us",
    "waName": "פישינג"
  },
  {
    "waId": "972585615160@c.us",
    "waName": "+972 58-561-5160"
  },
  {
    "waId": "972544458534@c.us",
    "waName": "+972 54-445-8534"
  },
  {
    "waId": "972522846824@c.us",
    "waName": "+972 52-284-6824"
  },
  {
    "waId": "972548275828-1635941757@g.us",
    "waName": "My tasks"
  },
  {
    "waId": "120363026642449200@g.us",
    "waName": "Liam Yonatan & Noam"
  },
  {
    "waId": "972542277572@c.us",
    "waName": "+972 54-227-7572"
  },
  {
    "waId": "972544312565@c.us",
    "waName": "+972 54-431-2565"
  },
  {
    "waId": "972502525339@c.us",
    "waName": "+972 50-252-5339"
  },
  {
    "waId": "97286190952@c.us",
    "waName": "+972 86190952"
  },
  {
    "waId": "972545456366@c.us",
    "waName": "+972 54-545-6366"
  },
  {
    "waId": "972504226871@c.us",
    "waName": "+972 50-422-6871"
  },
  {
    "waId": "972587500701@c.us",
    "waName": "+972 58-750-0701"
  },
  {
    "waId": "972544508833@c.us",
    "waName": "+972 54-450-8833"
  },
  {
    "waId": "972525558917@c.us",
    "waName": "+972 52-555-8917"
  },
  {
    "waId": "972504244248@c.us",
    "waName": "+972 50-424-4248"
  },
  {
    "waId": "972545964489@c.us",
    "waName": "+972 54-596-4489"
  },
  {
    "waId": "972528669404@c.us",
    "waName": "+972 52-866-9404"
  },
  {
    "waId": "972524474105@c.us",
    "waName": "+972 52-447-4105"
  },
  {
    "waId": "120363043193241757@g.us",
    "waName": "ירושלים אנחנו באים"
  },
  {
    "waId": "972584744505@c.us",
    "waName": "+972 58-474-4505"
  },
  {
    "waId": "972542394886@c.us",
    "waName": "+972 54-239-4886"
  },
  {
    "waId": "120363025072141203@g.us",
    "waName": "גל יונתן ונועם"
  },
  {
    "waId": "972507664440@c.us",
    "waName": "Ivory"
  },
  {
    "waId": "972526857088@c.us",
    "waName": "+972 52-685-7088"
  },
  {
    "waId": "972505977737@c.us",
    "waName": "+972 50-597-7737"
  },
  {
    "waId": "972545914994@c.us",
    "waName": "+972 54-591-4994"
  },
  {
    "waId": "120363023967787327@g.us",
    "waName": "הטמעה של ספק משלוחים חדש"
  },
  {
    "waId": "972503985512-1629819000@g.us",
    "waName": "גן ראם אנשי קשר תשפ״ב"
  },
  {
    "waId": "972522922103@c.us",
    "waName": "+972 52-292-2103"
  },
  {
    "waId": "972526976564@c.us",
    "waName": "+972 52-697-6564"
  },
  {
    "waId": "972542800100@c.us",
    "waName": "+972 54-280-0100"
  },
  {
    "waId": "972544974596@c.us",
    "waName": "+972 54-497-4596"
  },
  {
    "waId": "120363039062413903@g.us",
    "waName": "מרכז קריירה + נועם"
  },
  {
    "waId": "972545445278@c.us",
    "waName": "+972 54-544-5278"
  },
  {
    "waId": "972547666611@c.us",
    "waName": "max"
  },
  {
    "waId": "972543228822@c.us",
    "waName": "Partner"
  },
  {
    "waId": "120363022127215142@g.us",
    "waName": "נילוסופט ומכללה למנהל"
  },
  {
    "waId": "972544409210@c.us",
    "waName": "+972 54-440-9210"
  },
  {
    "waId": "972559259926@c.us",
    "waName": "+972 55-925-9926"
  },
  {
    "waId": "972548275828-1610300744@g.us",
    "waName": "תום אור ונועם ודור"
  },
  {
    "waId": "120363025411549420@g.us",
    "waName": "שגעתם את רבי"
  },
  {
    "waId": "972523475095@c.us",
    "waName": "+972 52-347-5095"
  },
  {
    "waId": "120363023612165580@g.us",
    "waName": "GREEN SWAN DEV"
  },
  {
    "waId": "120363043953867202@g.us",
    "waName": "BDO / מזוז"
  },
  {
    "waId": "972506485485@c.us",
    "waName": "+972 50-648-5485"
  },
  {
    "waId": "972525556111@c.us",
    "waName": "+972 52-555-6111"
  },
  {
    "waId": "972523785279@c.us",
    "waName": "+972 52-378-5279"
  },
  {
    "waId": "972523489497@c.us",
    "waName": "+972 52-348-9497"
  },
  {
    "waId": "972505943560@c.us",
    "waName": "+972 50-594-3560"
  },
  {
    "waId": "972546720807@c.us",
    "waName": "+972 54-672-0807"
  },
  {
    "waId": "972548275828-1604613000@g.us",
    "waName": "קמפוס דיגיטלי DEV🌟"
  },
  {
    "waId": "972547956696@c.us",
    "waName": "+972 54-795-6696"
  },
  {
    "waId": "972547493109@c.us",
    "waName": "+972 54-749-3109"
  },
  {
    "waId": "972529688805@c.us",
    "waName": "+972 52-968-8805"
  },
  {
    "waId": "972523332152@c.us",
    "waName": "+972 52-333-2152"
  },
  {
    "waId": "972538400280@c.us",
    "waName": "+972 53-840-0280"
  },
  {
    "waId": "972525380406@c.us",
    "waName": "+972 52-538-0406"
  },
  {
    "waId": "120363026450380603@g.us",
    "waName": "טיפול בניאגרה בדירה באפגד"
  },
  {
    "waId": "972586665999@c.us",
    "waName": "+972 58-666-5999"
  },
  {
    "waId": "972545300930@c.us",
    "waName": "+972 54-530-0930"
  },
  {
    "waId": "972528771430@c.us",
    "waName": "+972 52-877-1430"
  },
  {
    "waId": "120363023742526003@g.us",
    "waName": "קנדל טאצ תשלום - קבוצה 2"
  },
  {
    "waId": "972506141032@c.us",
    "waName": "+972 50-614-1032"
  },
  {
    "waId": "972548131212@c.us",
    "waName": "+972 54-813-1212"
  },
  {
    "waId": "972524676141@c.us",
    "waName": "+972 52-467-6141"
  },
  {
    "waId": "972512007943@c.us",
    "waName": "+972 51-200-7943"
  },
  {
    "waId": "972505251374@c.us",
    "waName": "+972 50-525-1374"
  },
  {
    "waId": "120363021628228210@g.us",
    "waName": "הראל נועם ואוהד"
  },
  {
    "waId": "5511975573139@c.us",
    "waName": "+55 11 97557-3139"
  },
  {
    "waId": "972544967555@c.us",
    "waName": "+972 54-496-7555"
  },
  {
    "waId": "972586194321@c.us",
    "waName": "+972 58-619-4321"
  },
  {
    "waId": "972507339473@c.us",
    "waName": "+972 50-733-9473"
  },
  {
    "waId": "972505562592@c.us",
    "waName": "+972 50-556-2592"
  },
  {
    "waId": "972502099994@c.us",
    "waName": "+972 50-209-9994"
  },
  {
    "waId": "972526486344@c.us",
    "waName": "+972 52-648-6344"
  },
  {
    "waId": "972536626001@c.us",
    "waName": "+972 53-662-6001"
  },
  {
    "waId": "972509008415@c.us",
    "waName": "+972 50-900-8415"
  },
  {
    "waId": "972523826416@c.us",
    "waName": "+972 52-382-6416"
  },
  {
    "waId": "972528403099@c.us",
    "waName": "+972 52-840-3099"
  },
  {
    "waId": "97526366528@c.us",
    "waName": "+975 26366528"
  },
  {
    "waId": "972505936644@c.us",
    "waName": "+972 50-593-6644"
  },
  {
    "waId": "972542428682@c.us",
    "waName": "+972 54-242-8682"
  },
  {
    "waId": "442080995425@c.us",
    "waName": "+44 20 8099 5425"
  },
  {
    "waId": "972548377779@c.us",
    "waName": "Partner"
  },
  {
    "waId": "972507826100@c.us",
    "waName": "+972 50-782-6100"
  },
  {
    "waId": "972545364832@c.us",
    "waName": "+972 54-536-4832"
  },
  {
    "waId": "972548275828-1604830864@g.us",
    "waName": "אתר- TruForMe🌟"
  },
  {
    "waId": "972548275828-1604781890@g.us",
    "waName": "RehumaticMonitor DEV🌟"
  },
  {
    "waId": "972502330083@c.us",
    "waName": "+972 50-233-0083"
  },
  {
    "waId": "972528895484@c.us",
    "waName": "+972 52-889-5484"
  },
  {
    "waId": "972548832413@c.us",
    "waName": "+972 54-883-2413"
  },
  {
    "waId": "972525054425@c.us",
    "waName": "+972 52-505-4425"
  },
  {
    "waId": "972548017327@c.us",
    "waName": "+972 54-801-7327"
  },
  {
    "waId": "972544781009@c.us",
    "waName": "+972 54-478-1009"
  },
  {
    "waId": "972523671250@c.us",
    "waName": "+972 52-367-1250"
  },
  {
    "waId": "972546821861@c.us",
    "waName": "+972 54-682-1861"
  },
  {
    "waId": "120363023568507200@g.us",
    "waName": "פרוייקט ג'וניור"
  },
  {
    "waId": "972525406824@c.us",
    "waName": "+972 52-540-6824"
  },
  {
    "waId": "972546616766@c.us",
    "waName": "+972 54-661-6766"
  },
  {
    "waId": "19142632551@c.us",
    "waName": "+1 (914) 263-2551"
  },
  {
    "waId": "972587706694@c.us",
    "waName": "+972 58-770-6694"
  },
  {
    "waId": "972512775011@c.us",
    "waName": "+972 51-277-5011"
  },
  {
    "waId": "120363042453024840@g.us",
    "waName": "MG team"
  },
  {
    "waId": "120363022204810378@g.us",
    "waName": "אנדוראיד 12 תיקון"
  },
  {
    "waId": "120363023629041675@g.us",
    "waName": "בועז מונדיי"
  },
  {
    "waId": "972546393878@c.us",
    "waName": "+972 54-639-3878"
  },
  {
    "waId": "972548275828-1632076846@g.us",
    "waName": "Blueticks Marketing"
  },
  {
    "waId": "972528611660@c.us",
    "waName": "+972 52-861-1660"
  },
  {
    "waId": "972545579913@c.us",
    "waName": "+972 54-557-9913"
  },
  {
    "waId": "972523888570@c.us",
    "waName": "+972 52-388-8570"
  },
  {
    "waId": "972549005449@c.us",
    "waName": "+972 54-900-5449"
  },
  {
    "waId": "972586565789@c.us",
    "waName": "+972 58-656-5789"
  },
  {
    "waId": "972502245088-1614592332@g.us",
    "waName": "מרכז קריירה"
  },
  {
    "waId": "972545869989@c.us",
    "waName": "+972 54-586-9989"
  },
  {
    "waId": "972538856900@c.us",
    "waName": "HOT"
  },
  {
    "waId": "972545408281@c.us",
    "waName": "+972 54-540-8281"
  },
  {
    "waId": "972526898650-1557932325@g.us",
    "waName": "פישינג אינטגרטי"
  },
  {
    "waId": "972524315761@c.us",
    "waName": "+972 52-431-5761"
  },
  {
    "waId": "972525219616@c.us",
    "waName": "+972 52-521-9616"
  },
  {
    "waId": "972542133614-1468165760@g.us",
    "waName": "Physimax IL"
  },
  {
    "waId": "972542276515@c.us",
    "waName": "+972 54-227-6515"
  },
  {
    "waId": "972504789991@c.us",
    "waName": "+972 50-478-9991"
  },
  {
    "waId": "972523575717@c.us",
    "waName": "+972 52-357-5717"
  },
  {
    "waId": "972525000866@c.us",
    "waName": "+972 52-500-0866"
  },
  {
    "waId": "972525224088@c.us",
    "waName": "+972 52-522-4088"
  },
  {
    "waId": "972527785851@c.us",
    "waName": "+972 52-778-5851"
  },
  {
    "waId": "120363022371283396@g.us",
    "waName": "אחסון אתר צאקאין"
  },
  {
    "waId": "972544302719@c.us",
    "waName": "+972 54-430-2719"
  },
  {
    "waId": "972544248405@c.us",
    "waName": "+972 54-424-8405"
  },
  {
    "waId": "972504696000@c.us",
    "waName": "+972 50-469-6000"
  },
  {
    "waId": "120363019364593940@g.us",
    "waName": "עזרה לרז בבלוטיקס API"
  },
  {
    "waId": "972545615070-1584886543@g.us",
    "waName": "Pmax-RD"
  },
  {
    "waId": "972522408836@c.us",
    "waName": "+972 52-240-8836"
  },
  {
    "waId": "972527306608@c.us",
    "waName": "+972 52-730-6608"
  },
  {
    "waId": "120363038763949341@g.us",
    "waName": "שרת הדפסות - פתירת בעיות"
  },
  {
    "waId": "972544325389-1605607229@g.us",
    "waName": "פיזימקס 2.0 ☀️"
  },
  {
    "waId": "120363041256012961@g.us",
    "waName": "כתיבת שיר"
  },
  {
    "waId": "972542310688-1605812052@g.us",
    "waName": "עדכוני פיתוח מנועם טרנזיט"
  },
  {
    "waId": "972524766228@c.us",
    "waName": "+972 52-476-6228"
  },
  {
    "waId": "972546935333@c.us",
    "waName": "+972 54-693-5333"
  },
  {
    "waId": "972546781744@c.us",
    "waName": "+972 54-678-1744"
  },
  {
    "waId": "972504444946@c.us",
    "waName": "+972 50-444-4946"
  },
  {
    "waId": "972525453609@c.us",
    "waName": "+972 52-545-3609"
  },
  {
    "waId": "972526749001@c.us",
    "waName": "+972 52-674-9001"
  },
  {
    "waId": "972509561123@c.us",
    "waName": "+972 50-956-1123"
  },
  {
    "waId": "120363039243348393@g.us",
    "waName": "סגירת תשלום MOOD"
  },
  {
    "waId": "972544454790@c.us",
    "waName": "+972 54-445-4790"
  },
  {
    "waId": "972533574067@c.us",
    "waName": "+972 53-357-4067"
  },
  {
    "waId": "972538735319@c.us",
    "waName": "+972 53-873-5319"
  },
  {
    "waId": "22997100514@c.us",
    "waName": "+229 97 10 05 14"
  },
  {
    "waId": "972506887080@c.us",
    "waName": "+972 50-688-7080"
  },
  {
    "waId": "972547273422@c.us",
    "waName": "+972 54-727-3422"
  },
  {
    "waId": "972505837703@c.us",
    "waName": "+972 50-583-7703"
  },
  {
    "waId": "972502992338@c.us",
    "waName": "+972 50-299-2338"
  },
  {
    "waId": "972546970903@c.us",
    "waName": "+972 54-697-0903"
  },
  {
    "waId": "120363041091751730@g.us",
    "waName": "MeteorOps <> Mazuz"
  },
  {
    "waId": "972528874114-1507039813@g.us",
    "waName": "שרית אימיילים"
  },
  {
    "waId": "972523451115-1635702515@g.us",
    "waName": "Sabres 2 go app"
  },
  {
    "waId": "972549304423@c.us",
    "waName": "+972 54-930-4423"
  },
  {
    "waId": "972524899545@c.us",
    "waName": "+972 52-489-9545"
  },
  {
    "waId": "972535560505@c.us",
    "waName": "+972 53-556-0505"
  },
  {
    "waId": "972525522608@c.us",
    "waName": "+972 52-552-2608"
  },
  {
    "waId": "972547390829@c.us",
    "waName": "+972 54-739-0829"
  },
  {
    "waId": "972534219601@c.us",
    "waName": "+972 53-421-9601"
  },
  {
    "waId": "19173093170@c.us",
    "waName": "+1 (917) 309-3170"
  },
  {
    "waId": "972544360900@c.us",
    "waName": "+972 54-436-0900"
  },
  {
    "waId": "972524043654@c.us",
    "waName": "+972 52-404-3654"
  },
  {
    "waId": "972528242084@c.us",
    "waName": "+972 52-824-2084"
  },
  {
    "waId": "972549370601@c.us",
    "waName": "+972 54-937-0601"
  },
  {
    "waId": "972545961306@c.us",
    "waName": "+972 54-596-1306"
  },
  {
    "waId": "972544238795@c.us",
    "waName": "+972 54-423-8795"
  },
  {
    "waId": "120363020648876234@g.us",
    "waName": "פתרון באג לשחר מהדסטרט"
  },
  {
    "waId": "120363020811671820@g.us",
    "waName": "יונתן רביד ונועם"
  },
  {
    "waId": "120363023811012841@g.us",
    "waName": "סגירת חשבון אתר טרופורמי"
  },
  {
    "waId": "120363037366058952@g.us",
    "waName": "Whatsapp schedule"
  },
  {
    "waId": "120363038122834471@g.us",
    "waName": "the drop team"
  },
  {
    "waId": "120363038463796115@g.us",
    "waName": "NFT2ThEfAcE"
  },
  {
    "waId": "120363038584674510@g.us",
    "waName": "tranzit"
  },
  {
    "waId": "120363039012677490@g.us",
    "waName": "Blueticks - video"
  },
  {
    "waId": "120363047617293222@g.us",
    "waName": "new comu"
  },
  {
    "waId": "16462648472-1614339220@g.us",
    "waName": "WhatsApp leads BRZ"
  },
  {
    "waId": "18189198700-1616178237@g.us",
    "waName": "Noam abol Eli"
  },
  {
    "waId": "35799012646-1619003762@g.us",
    "waName": "Social trading"
  },
  {
    "waId": "972502222451-1607885272@g.us",
    "waName": "קורס עדן בן זקן"
  },
  {
    "waId": "972502222451-1611764331@g.us",
    "waName": "עולם המוזיקה סלוגן"
  },
  {
    "waId": "972502508373-1611498596@g.us",
    "waName": "Project apt"
  },
  {
    "waId": "972504149620-1635672718@g.us",
    "waName": "פיתוח אלפיה"
  },
  {
    "waId": "972504443181-1617213613@g.us",
    "waName": "PB Team YSolutions"
  },
  {
    "waId": "972504778844-1617538565@g.us",
    "waName": "חיבור אתר לחברת שיווק"
  },
  {
    "waId": "972504778844-1621450822@g.us",
    "waName": "אתר עדן"
  },
  {
    "waId": "972504778844-1626860487@g.us",
    "waName": "ניהול אתר ומשלוחים מישל"
  },
  {
    "waId": "972505330820-1619715570@g.us",
    "waName": "אתר קורסים"
  },
  {
    "waId": "972507206410-1423684690@g.us",
    "waName": "gorilla"
  },
  {
    "waId": "972508147677-1621934954@g.us",
    "waName": "Zoll - OQVA - Vision"
  },
  {
    "waId": "972509434305-1616008022@g.us",
    "waName": "Dinesh"
  },
  {
    "waId": "972509434305-1616008245@g.us",
    "waName": "Gilfoyle"
  },
  {
    "waId": "972509434305-1616014977@g.us",
    "waName": "Dinesh"
  },
  {
    "waId": "972509448051-1598979761@g.us",
    "waName": "ספינה על מים"
  },
  {
    "waId": "972509448051-1617019194@g.us",
    "waName": "בעיות מדידה אייסטודנט"
  },
  {
    "waId": "972509898948-1489589425@g.us",
    "waName": "מערכת צ׳אפ  -ניהול"
  },
  {
    "waId": "972522965065-1617873933@g.us",
    "waName": "אוורלנד פיתוח"
  },
  {
    "waId": "972522965065-1620198675@g.us",
    "waName": "פיתוח אלפייה"
  },
  {
    "waId": "972523888570-1620203691@g.us",
    "waName": "פיתוח אפליקציית סייבר"
  },
  {
    "waId": "972523888570-1624947374@g.us",
    "waName": "קבוצת IT cytaka"
  },
  {
    "waId": "972523888570-1628780466@g.us",
    "waName": "תמחור פרוייקט תפילין"
  },
  {
    "waId": "972523888570-1628879296@g.us",
    "waName": "פורום שאלות - MonkeyBall"
  },
  {
    "waId": "972524440131-1609412332@g.us",
    "waName": "אפליקציה למתקני כושר"
  },
  {
    "waId": "972524474105-1612528838@g.us",
    "waName": "יעוץ על חיבוריות (API's)"
  },
  {
    "waId": "972524474105-1612718132@g.us",
    "waName": "שירות שידרוג DB "
  },
  {
    "waId": "972524474105-1619107315@g.us",
    "waName": "Intro Noam - Smadar"
  },
  {
    "waId": "972524474105-1628699441@g.us",
    "waName": "Intro Daria - Noam"
  },
  {
    "waId": "972524587110-1605452214@g.us",
    "waName": "תום אור נועם וטל (חדש)"
  },
  {
    "waId": "972524587110-1621856533@g.us",
    "waName": "פיפלביז - SEO"
  },
  {
    "waId": "972524587110-1626621046@g.us",
    "waName": "פיפלביז אנליטיקס"
  },
  {
    "waId": "972525245678-1629203516@g.us",
    "waName": "תכנון פישינג חילן"
  },
  {
    "waId": "972525534447-1629225387@g.us",
    "waName": "Cytaka -PT"
  },
  {
    "waId": "972526088400-1613559881@g.us",
    "waName": "וידאו סופי עדן בן זקן"
  },
  {
    "waId": "972526272111-1618237178@g.us",
    "waName": "פרויקט ג׳נאו"
  },
  {
    "waId": "972528263924-1607068901@g.us",
    "waName": "I-capital"
  },
  {
    "waId": "972528560394-1589692187@g.us",
    "waName": "EvEdge website tech & des"
  },
  {
    "waId": "972528874114-1539279207@g.us",
    "waName": "istudnet dev"
  },
  {
    "waId": "972528874114-1563953742@g.us",
    "waName": "פיתוח שותפים"
  },
  {
    "waId": "972535277071-1539150610@g.us",
    "waName": "'Tranzit' dev"
  },
  {
    "waId": "972542550226-1617720825@g.us",
    "waName": "Tagra's Website & Mon"
  },
  {
    "waId": "972543028423-1575201471@g.us",
    "waName": "חב\"ד בקמפוס - פיתוח"
  },
  {
    "waId": "972543028423-1594579018@g.us",
    "waName": "מגיהי הגה-אות"
  },
  {
    "waId": "972543028423-1606394686@g.us",
    "waName": "מחנה משותף | פיתוח"
  },
  {
    "waId": "972544312565-1600871715@g.us",
    "waName": "צוות שרת  ראומטולוגיה"
  },
  {
    "waId": "972544312565-1604179284@g.us",
    "waName": "RheumaticMonitor App F&F😄"
  },
  {
    "waId": "972544312565-1606856518@g.us",
    "waName": "מפתחי אפליקציה צמיד"
  },
  {
    "waId": "972544325389-1603093128@g.us",
    "waName": "אפליקציה קמפוס - השקה"
  },
  {
    "waId": "972544325389-1604868292@g.us",
    "waName": "iStudent Dev NoClients ☀️"
  },
  {
    "waId": "972544325389-1605182831@g.us",
    "waName": "באז - העברת מקל"
  },
  {
    "waId": "972544325389-1620809287@g.us",
    "waName": "monkeyball no clients"
  },
  {
    "waId": "972544325389-1635761782@g.us",
    "waName": "אינטגרציה קמפוס-סיסביינד"
  },
  {
    "waId": "972544334549-1576403115@g.us",
    "waName": "אפליקציית סטודנטים 2020"
  },
  {
    "waId": "972544873007-1632920944@g.us",
    "waName": "גישה בgit"
  },
  {
    "waId": "972547704843-1626199671@g.us",
    "waName": "קליניכ״ץ מזוז🤓"
  },
  {
    "waId": "972548089529-1623584796@g.us",
    "waName": "איי לייק"
  },
  {
    "waId": "972548117417-1625846194@g.us",
    "waName": "Cyber Load Testing"
  },
  {
    "waId": "972548275828-1604612710@g.us",
    "waName": "NoClients Rheumatic Dev🌟"
  },
  {
    "waId": "972548275828-1604613932@g.us",
    "waName": "all around player DEV🌟"
  },
  {
    "waId": "972548275828-1604831957@g.us",
    "waName": "landanano - marketo🌟"
  },
  {
    "waId": "972548275828-1604929507@g.us",
    "waName": "physimax management🌟"
  },
  {
    "waId": "972548275828-1605000041@g.us",
    "waName": "thinkingames DEV"
  },
  {
    "waId": "972548275828-1605133905@g.us",
    "waName": "טרנזיט - מובילים עסקיים🌟"
  },
  {
    "waId": "972548275828-1605282464@g.us",
    "waName": "vzn-modules rocks!🌟"
  },
  {
    "waId": "972548275828-1605890876@g.us",
    "waName": "סוללת עורכי דין"
  },
  {
    "waId": "972548275828-1606072836@g.us",
    "waName": "תמי גור פיתוח🌟"
  },
  {
    "waId": "972548275828-1606311270@g.us",
    "waName": "תיקוני סקיצת פה"
  },
  {
    "waId": "972548275828-1606745906@g.us",
    "waName": "פיתוח אתר כריזמה"
  },
  {
    "waId": "972548275828-1606756265@g.us",
    "waName": "סימון יונתן ונועם"
  },
  {
    "waId": "972548275828-1606999303@g.us",
    "waName": "omega rocks!"
  },
  {
    "waId": "972548275828-1608478732@g.us",
    "waName": "זום ראיון DevOps"
  },
  {
    "waId": "972548275828-1608740409@g.us",
    "waName": "אינטגרציה לSAP של טרופרומ"
  },
  {
    "waId": "972548275828-1609165276@g.us",
    "waName": "טכנולוגיה"
  },
  {
    "waId": "972548275828-1610377524@g.us",
    "waName": "פיתוח - שוק ההון"
  },
  {
    "waId": "972548275828-1610400010@g.us",
    "waName": "אלגוטריידינג"
  },
  {
    "waId": "972548275828-1610897184@g.us",
    "waName": "פיתוח שוק ההון צוות 1"
  },
  {
    "waId": "972548275828-1611150316@g.us",
    "waName": "Utopia - Vizion Dev Team1"
  },
  {
    "waId": "972548275828-1611154970@g.us",
    "waName": "tranzit NoClients"
  },
  {
    "waId": "972548275828-1611622306@g.us",
    "waName": "check"
  },
  {
    "waId": "972548275828-1612440754@g.us",
    "waName": "מצגת משקיעים צאפ"
  },
  {
    "waId": "972548275828-1613307664@g.us",
    "waName": "אבי <> מיכאל <> הכרות"
  },
  {
    "waId": "972548275828-1613571181@g.us",
    "waName": "שינוי פיתוח באיביאדג"
  },
  {
    "waId": "972548275828-1615553153@g.us",
    "waName": "רונית עדי ונועם"
  },
  {
    "waId": "972548275828-1615997315@g.us",
    "waName": "עדן מייקאפ שילוח מוצרים"
  },
  {
    "waId": "972548275828-1616168299@g.us",
    "waName": "בלוטיקס - ריבוי משתמשים"
  },
  {
    "waId": "972548275828-1616424238@g.us",
    "waName": "אבטחת מידע - הסכם"
  },
  {
    "waId": "972548275828-1616591303@g.us",
    "waName": "טרופורמי CRM"
  },
  {
    "waId": "972548275828-1616856739@g.us",
    "waName": "GoniOpinion app dev"
  },
  {
    "waId": "972548275828-1617017906@g.us",
    "waName": "עידן יונתן נועם ואסף"
  },
  {
    "waId": "972548275828-1617708074@g.us",
    "waName": "חימום מספרים ישראלים"
  },
  {
    "waId": "972548275828-1619346701@g.us",
    "waName": "יונתן פאול ונועם"
  },
  {
    "waId": "972548275828-1619905501@g.us",
    "waName": "הטמעת FULLSTORY"
  },
  {
    "waId": "972548275828-1623617120@g.us",
    "waName": "שרגא אופיר ונועם"
  },
  {
    "waId": "972548275828-1624005931@g.us",
    "waName": "תשלום ראומטולוגיה"
  },
  {
    "waId": "972548275828-1624875888@g.us",
    "waName": "alpia no clients"
  },
  {
    "waId": "972548275828-1625042587@g.us",
    "waName": "exchanga app"
  },
  {
    "waId": "972548275828-1625048113@g.us",
    "waName": "תיקונים סופיים צ'קאין"
  },
  {
    "waId": "972548275828-1625579668@g.us",
    "waName": "ברוקרג'"
  },
  {
    "waId": "972548275828-1626860882@g.us",
    "waName": "עמיר לירון ונועם"
  },
  {
    "waId": "972548275828-1627391167@g.us",
    "waName": "עמיר לירון ונועם"
  },
  {
    "waId": "972548275828-1627561806@g.us",
    "waName": "נועם אלון ואריה"
  },
  {
    "waId": "972548275828-1627651003@g.us",
    "waName": "nuxt-education"
  },
  {
    "waId": "972548275828-1629119863@g.us",
    "waName": "אביחי אלון ונועם"
  },
  {
    "waId": "972548275828-1630322315@g.us",
    "waName": "אתר איימי"
  },
  {
    "waId": "972548275828-1630479178@g.us",
    "waName": "אתר גוני R&D"
  },
  {
    "waId": "972548275828-1631516676@g.us",
    "waName": "צ'קאין אתר גרסא חדשה"
  },
  {
    "waId": "972548275828-1633448814@g.us",
    "waName": "Blueticks Brazil publish"
  },
  {
    "waId": "972548275828-1635263311@g.us",
    "waName": "תום אור ונועם"
  },
  {
    "waId": "972549490781-1615791151@g.us",
    "waName": "old easywed"
  },
  {
    "waId": "972549987166-1620723085@g.us",
    "waName": "צוות דורון סייבר"
  }
]