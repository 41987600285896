import { Chat, WhatsappContact } from "./whatsapp";
import mockContacts from "./mock-contacts.json";
import mockChats from "./mock-chats.json";

export async function getMockContacts(): Promise<Partial<WhatsappContact>[]> {
  return mockContacts.mockContacts;
}
export async function getMockChats(): Promise<Partial<Chat>[]> {
  return mockChats.mockChats;
}
