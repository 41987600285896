import { CircularProgress } from "@mui/material";
import { useAddUserToTaskGroupMutation } from "@reducers/backend-api/backend-api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMatch } from "react-router-dom";
import classes from "./join-task-board.scss";
export interface JoinTaskBoardProps {
}

export interface JoinTaskBoardState {
  loading: boolean;
  message?: string;
}

export const JoinTaskBoard = () => {
  const match = useMatch('/join-task-board/:shareId')
  const shareId = useMemo(() => match.params.shareId, [match]);
  const [message, setMessage] = useState('');
  const [addUserToTaskGroup, { isLoading }] = useAddUserToTaskGroupMutation();

  const _addUserToTaskGroup = useCallback(async () => {
    try {
      await addUserToTaskGroup(shareId);
      setMessage("Successfully joined the group 🎉")
    } catch (e) {
      setMessage("Something went wrong...")
    }
  }, [addUserToTaskGroup, shareId]);

  useEffect(() => {
    _addUserToTaskGroup();
  }, [_addUserToTaskGroup]);

  const JoinTaskBoardPage = () => (
    <div className={classes.container}>
      <div className={classes.card}>
        <div className={classes.header}>
          <div className={classes.branding}>
            <img alt="" src="/img/logo.png" style={{ height: 30 }} />
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <h2>{message}</h2>
          <div className={classes.illustratorContaner}>
            <img src="/img/illustrator_img.png" alt="illustration" />
            <p
              style={{
                textAlign: "center",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              "It takes two flints to make a fire "
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return isLoading ? <CircularProgress /> : JoinTaskBoardPage();
}

export default JoinTaskBoard;
