import { Box, Menu, MenuItem, MenuList } from "@mui/material";
import { taskColumnOptionSelectByQuery } from "@reducers/DBServiceReducers";
import { useAppSelector } from "@store/hooks";
import { RootState } from '@store/index';
import { CSSProperties } from "react";

const styling: CSSProperties = {
    height: 25,
    borderRadius: '5px',
    flex: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

const ColumnOptionsSelector = ({ boardId, columnId, onSelectOption, anchorEl, handleClose }) => {
    const columnOptions = useAppSelector((state: RootState) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }))

    return (
        <Menu anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuList>
                {columnId ? (
                    <Box>{columnOptions.map((option) => (
                        <MenuItem key={option.label} onClick={() => onSelectOption(option)} >
                            <button
                                aria-haspopup="true"
                                style={{
                                    backgroundColor: option?.bgcolor ?? 'var(--empty-picker-cell)',
                                    color: "#fff",
                                    fontWeight: "600",
                                    border: 'none',
                                    ...styling
                                }}
                            >
                                {option?.label ?? ''}
                            </button>
                        </MenuItem>
                    ))}</Box>) : <Box px={2}>First select column</Box>}
            </MenuList>
        </Menu>
    )
}
export default ColumnOptionsSelector