import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { labelActions } from '@reducers/labels-reducer';
import { ButtonBase, CircularProgress, colors } from '@mui/material';

import { Close } from '@mui/icons-material';
import LabelForm from '../label-input-with-emoji-picker';
import TalkToUsFooter from './talktous-footer';
import mainClasses from '../labels-feature.module.scss'
import classes from './modal.module.scss'
import { deleteLabelThunk } from '@reducers/labels-reducer/labels-thunks/crud';

export default function DeleteLabelModalModal() {
    const dispatch = useAppDispatch()
    const isLabelDeletting = useAppSelector((state) => state.LabelReducer.isLabelDeletting)
    const labelNetWorkStatus = useAppSelector((state) => state.LabelReducer.labelNetWorkStatus)

    return (
        <div>
            <Modal
                open={isLabelDeletting}
                onClose={() => {
                    dispatch(labelActions.setLabel({}))
                    dispatch(labelActions.toggleDeleteLabel(false))
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.container}>
                    <Box className={classes.modalHead}>
                        <Typography id="modal-modal-title"
                            sx={{ flex: 1, fontWeight: 600 }} variant="h6" component="h2">
                            Label will be deleted?
                        </Typography>

                        <ButtonBase
                        className={classes.closeModalButton}
                            onClick={() => {
                                dispatch(labelActions.setLabel({}))
                                dispatch(labelActions.toggleDeleteLabel(false))
                            }}
                        >
                            <Close sx={{ fontSize: '25px' }} />
                        </ButtonBase>
                    </Box>

                    <LabelForm disabled={true} />
                    <Box className={classes.suggestedLabels}>
                        <Box className={classes.createWrap}>
                            <ButtonBase
                            className={mainClasses.labelButtonBase}
                                sx={{ flexBasis: '40%', mr: 1, }}
                                onClick={() => {
                                    dispatch(labelActions.setLabel({}))
                                    dispatch(labelActions.toggleDeleteLabel(false))
                                }}
                            >Cancel
                            </ButtonBase>
                            <ButtonBase
                             className={mainClasses.labelButtonBase}
                                onClick={() => dispatch(deleteLabelThunk())}
                                sx={{
                                    flexBasis: '40%!important',
                                    color: '#fff',
                                    backgroundColor: colors.red[600],
                                    '&:hover': {
                                        backgroundColor: colors.red[500],
                                    }
                                }}>
                                {labelNetWorkStatus === 'delete-label' ? 'Deletting..' : 'Delete'}
                                {labelNetWorkStatus === 'delete-label' && <CircularProgress size={16} sx={{
                                    ml: 1,
                                }} />}

                            </ButtonBase>

                        </Box>
                    </Box>
                    <TalkToUsFooter />
                </Box>
            </Modal>
        </div >
    );
}


