import { PickerProps } from "@components/tasks/columns-cells/cell-interface";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// This is an infrastructure code.
// Any change to this file must be coordinated with Yonatan Naor with a proper pull request

export interface PickerDialogState {
  isEditModeOptions: boolean;
  pickerAnchorId?: string;
  pickerProps?: PickerProps;
  activeGroupId: string;
  componentIdsForCleanup: string[];
  pickerChangeIndicator?: number;
}

const initialState: PickerDialogState = {
  isEditModeOptions: false,
  activeGroupId: "",
  componentIdsForCleanup: [],
};

export interface SetPickerOptions {
  pickerAnchorId?: string;
  pickerProps?: PickerProps;
}

const PickerDialogSlice = createSlice({
  name: "pickerDialog",
  initialState,
  reducers: {
    closePickerDialog(state) {
      state.pickerAnchorId = ""
      state.pickerProps = null
    },
    togglePicker(state, { type, payload }: PayloadAction<SetPickerOptions>) {
      if (state.pickerAnchorId === payload.pickerAnchorId) {
        state.pickerAnchorId = "";
        state.pickerProps = null;
      } else {
        state.pickerAnchorId = payload.pickerAnchorId;
        state.pickerProps = payload.pickerProps;
      }
      state.pickerChangeIndicator = Date.now(); // update the change indicator whenever picker changes
    },
    setPickerTypeAndId(state, { type, payload }: PayloadAction<SetPickerOptions>) {
      state.pickerAnchorId = payload.pickerAnchorId;
      state.pickerProps = payload.pickerProps;
    },
    setEditModeOptions(state, action: PayloadAction<boolean>) {
      state.isEditModeOptions = action.payload
    },
    setActiveGroupId(state, action: PayloadAction<string>) {
      state.activeGroupId = action.payload
    },
    registerComponentForCleanup: (state, action: PayloadAction<string>) => {
      state.componentIdsForCleanup.push(action.payload);
    },
    unregisterComponentForCleanup: (state, action: PayloadAction<string>) => {
      state.componentIdsForCleanup = state.componentIdsForCleanup.filter(id => id !== action.payload);
    },
  },
});

export const pickerDialogActions = PickerDialogSlice.actions;
export default PickerDialogSlice.reducer;



