import BtEditableBox from '@components/tasks/bt-editable-box/bt-editable-box';
import { TaskColumn } from '@models/task-column';
import { TasksGroup } from '@models/task-group';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { DBTaskColumnThunks, _taskgroupsSelectors } from '@reducers/DBServiceReducers';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { namespace } from 'config/constants';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps, TaskColumnType } from '../cell-interface';
import { columnTypeList } from '../column-cell-defs';
import { selectColumnDetailsById } from '@reducers/TaskSelectors';


const EditableColumnHeaderCell = memo(({
    groupId,
    columnId,
    openPicker
}: CellProps) => {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const tasksGroup: TasksGroup = useAppSelector((state) => {
        return _taskgroupsSelectors.selectById(state, groupId)
    });


    const [isEditing, setIsEditing] = useState<boolean>(false);
    const column = useAppSelector(state => selectColumnDetailsById(state, tasksGroup?.boardId, columnId));

    const inputTheme = {
        cursor: 'text',
        width: "100%",
        color: "var(--primary)",
        fontSize: '12px',
        border: '1px solid #53bdeb',
        borderRadius: '4px',
        fontFamily: "'Inter', sans-serif",
        fontWeight: '600',
        background: 'var(--board-item-menu-hover)',
        "& .MuiInputBase-input": {
            textAlign: 'center',
            padding: "1px 4px 2px 0px",
            borderRadius: '4px'
        }
    }
    const updateColumnTitle = (column: TaskColumn, title: string, columnType) => {
        if (title === '') {
            dispatch(DBTaskColumnThunks.patch({
                entity: {
                    ...column,
                    title: columnType.title
                }
            }))
        }
        else {
            dispatch(DBTaskColumnThunks.patch({
                entity: {
                    ...column,
                    title
                }
            }))
        }
    }

    const openColumnContextDialog = (column: TaskColumn) => {
        openPicker({
            anchorId: `column_${tasksGroup?._id}_${columnId}_dots`,
            pickerType: 'column-context-menu'
        })
    }
    const setEditMode = (value) => {
        setIsEditing(value)
    }

    // const columnType: string = column.type
    const columnType: TaskColumnType = columnTypeList.find(
        (columnType) => columnType.type === column.type
    );
    const isStaticColumn = columnType.static || column.static;

    const textTheme = {
        cursor: !isStaticColumn ? " text" : "default",
        textAlign: "center",
        width: "100%",
        color: "var(--cell-name-color)",
        whiteSpace: "nowrap",
        textOverflow: 'ellipsis',
        outline: "none",
        backgroundColor: "transparent",
        fontSize: '12px',
        border: '1px solid transparent',
        borderRadius: '4px',
        overflow: "hidden",
        fontFamily: "'Inter', sans-serif",
        padding: "3px",
        // fontWeight: '600',
        "&:hover": {
            border: !isStaticColumn ? " 1px dashed var(--text-color);" : "1px dashed transparent",
        }
    }

    return (
        <Stack direction="row" justifyContent='center'
            sx={{
                padding: "8px 0",
                overflow: "hidden",

                '&:hover button': {
                    visibility: 'visible'
                },
                width: "100%",

                // transition: resizing ? 'none' : '0.2s cubic-bezier(0.165, 0.84, 0.44, 1)',
            }}
        >

            {isStaticColumn ?
                (
                    <Tooltip placement="top" arrow title={t('editableColumnHeaderCell.tooltipTitle')}>
                        <Box sx={textTheme}>
                            {column?.title}
                        </Box>
                    </Tooltip>) :
                <BtEditableBox
                    data-testid={`column-title-bteditablebox-${columnType.type}`}
                    onEditModeChange={(value) => setEditMode(value)}
                    inputSx={inputTheme}
                    boxSx={textTheme}
                    disabled={isStaticColumn}
                    placeholder={columnType.title}
                    text={column?.title}

                    onValueUpdate={(value) => {
                        updateColumnTitle(column, value, columnType)
                    }}
                />
            }

            {(!isEditing && !isStaticColumn) &&
                <IconButton
                    sx={{
                        visibility: 'hidden',
                        padding: '0',
                        position: 'absolute',
                        right: '10px'
                    }}
                    id={`column_${tasksGroup?._id}_${columnId}_dots`}
                    onClick={() => openColumnContextDialog(column)}
                    data-testid={`column-three-dots-menu-button-${columnType.type}`}
                >
                    <MoreHorizIcon />
                </IconButton>}
        </Stack>
    )
})

export default EditableColumnHeaderCell