
import BTButton from "@components/bt-button/bt-button";
import { TasksGroup } from "@models/task-group";
import { getSelectedBoardId } from "@reducers/BoardsSelectors";
import { isTempId } from "@reducers/DBServiceReducers";
import {  createNewGroupAtBottom, createNewGroupAtTopThunk } from "@reducers/TaskThunks";
import { selectUser } from "@reducers/UserSelectors";
import { useAppDispatch } from "@store/hooks";
import randomColor from 'randomcolor';
import { FaPlus } from "react-icons/fa";
import { useAppSelector } from "../../../store/hooks";
import { AnalyticsService } from "@services/analytics-service";
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";
type NewTaskGroupButtonProps = {
  position: "top" | "bottom";
}

export default function NewTaskGroupButton({position}: NewTaskGroupButtonProps) {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser);
  const selectedBoardId = useAppSelector(getSelectedBoardId);
  const analyticsService: AnalyticsService = new AnalyticsService();

  function newTaskGroup() {
    if (user?._id && selectedBoardId) {
      const taskGroup: TasksGroup = {
        name: t('newTaskGroupButton.newGroup'),
        owner: user._id,
        createdBy: user._id,
        boardId: selectedBoardId,
        colorAccent: randomColor(),
      };

      if (position === "top"){
        taskGroup.order = 0
        dispatch(createNewGroupAtTopThunk(taskGroup));
     
      } else if (position === "bottom"){
        dispatch(createNewGroupAtBottom(taskGroup))
    
  
      }
      analyticsService.event("create_new_group", {
        category: "userEngagement",
        action: `create new group ${position}`,
      });
    }
  }

  return (
    <>
      <BTButton
      id="new-group-button"
      data-testid='tasks-header-new-group-button'
        sx={{  minWidth: "150px", flexShrink: '0', span: { margin: "0" } }}
        onClick={newTaskGroup}
        disabled={isTempId(selectedBoardId)}
      >
        <FaPlus style={{ marginInlineEnd: '5px', height: '14px', width: '14px' }} />
        {t('newTaskGroupButton.newGroup')}
      </BTButton>

    </>

  );
}
