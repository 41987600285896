import FilesIcons from '@components/files-icons/filesIcons'
import { getSelectedBoardName } from '@reducers/BoardsSelectors';
import { tasksSelectors } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import classes from "./media_manager_header.module.scss";

export interface Props {
    file: Record<string, any>;
    fileName: string;
}

const MediaManagerHeader = ({ file, fileName }: Props) => {
    const selectedBoardName = useAppSelector(getSelectedBoardName);

    const taskId = useAppSelector((state) => state.MediaManagerReducer.taskId);
    const task = useAppSelector((state) => tasksSelectors.selectById(state, taskId)
    );

    return (
        <div className={classes.fileDetails}>
            <FilesIcons file={file} />
            <div className={classes.fileNameAndPath}>
                <span>{fileName}</span>
                {task ? <span>{selectedBoardName} {">"} {task.text} {">"} Files </span> : "All Files"}
               
            </div>
        </div>)
}

export default MediaManagerHeader