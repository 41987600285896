import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { NotificationStack } from './notification-row';

type Props = {
    notificationId: string;
}

export default function InviteAcceptRemoveRestoreBoardUserNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))
    const notificationType = notification.actionType

    return (
        <NotificationStack direction='row'>
            {['invited', 'restored'].includes(notificationType) === true &&
                <>
                    <p>{notification.triggerByData}</p>
                    <p>{notification.actionType}</p>
                    <p>{notification.actionOnEntityData}</p>
                    <p>to</p>
                    <p style={{ fontWeight: 'bold' }}>{notification.customData}</p>
                </>
            }
            {notificationType === 'accepted' &&
                <>
                    <p>{notification.triggerByData}</p>
                    <p>{notification.actionType}</p>
                    <p>the invitation to</p>
                    <p style={{ fontWeight: 'bold' }}>{notification.actionOnEntityData}</p>
                </>
            }
            {notificationType === 'removed' &&
                <>
                    <p>{notification.triggerByData}</p>
                    <p>{notification.actionType}</p>
                    <p>{notification.actionOnEntityData}</p>
                    <p>from</p>
                    <p style={{ fontWeight: 'bold' }}>{notification.customData}</p>
                </>
            }
        </NotificationStack>
    )
}