export type RecurrenceUnit =
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "year";

export interface Schedule {
  isNow?: boolean;
  dueDate?: string;
  isTimeValid?: boolean;
  isRecurring?: boolean;
  recurrenceQuantity?: number;
  recurenceUnit?: RecurrenceUnit;
  cancelIfReceived?: boolean;
  rruleset?: string;
  calendarMessage?: boolean;
}

export enum MessageState {
  pending,
  sending,
  editing,
}

export interface Asset {
  name: string;
  type: string;
  url: string;
}

export interface Contact {
  id: string;
  name?: string;
  displayName?: string;
  imageUrl?: string;
  mobile?: string;
}

export type ActiveNewTaskRow = {
  groupId: string;
  parentTaskId?: string
}

export type PreservedGroup = {
  _id: string,
  boardId?: string,
  deleted: boolean
}
export type PreservedBoard = {
  _id: string,
  deleted: boolean
}

export type ActionsReduerData = {
  group: PreservedGroup,
}