import { Box, Link, Paper, Stack, Typography } from "@mui/material";
import { appConfig } from "config/app.config";

export default function WebHookGuideZapier() {
  return (
    <Stack sx={{ padding: 5, maxWidth: "1200px", margin: "0 auto" }}>
      <div
        style={{
          textAlign: "center",
          marginBottom: "24px",
        }}
      >
        <Stack direction="row" alignItems={"center"} justifyContent={"center"}>
          <img alt="logo" style={{ width: "250px", height: "80px" }} src="img/logo.png"></img>
          <Box fontSize={"80px"} padding={"0 16px"}>+</Box>
          <img alt="logo" style={{ width: "150px", height: "50px" }} src="img/zapier/zapierlogo.png"></img>

        </Stack>

      </div>

      <Box component="main" sx={{ p: 1 }}>
        <Typography variant="h4" gutterBottom textAlign={"center"}>
          Creating and Integrating Webhooks with Zapier<br /> Your Complete Guide
        </Typography>

        <Typography variant="h6">Introduction:</Typography>
        <Typography paragraph>
          This guide will take you through the process of creating and integrating a custom webhook using Zapier. By the end of this walkthrough, you will be able to send data over HTTP to trigger Zaps in Zapier from Blueticks.
        </Typography>

        {/* Phase 1: Initializing Your Webhook */}
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Stack spacing={4}>
            <Box>
              <Typography variant="h6">Phase 1: Starting Your Zap</Typography>
              <Typography paragraph>- Navigate to your Zapier dashboard and click on the ‘Create’ button to start a new Zap.</Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/zapier/zap1.png" alt="Initializing Webhook" />
              </div>

              <Typography paragraph>- Click on Trigger</Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/zapier/zap2.png" alt="Initializing Webhook" />
              </div>

              <Typography paragraph>-In the ‘Choose Trigger’ section, search for and select ‘Webhooks by Zapier’ as the trigger.</Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/zapier/zap3.png" alt="Initializing Webhook" />
              </div>


            </Box>

            {/* <Box>
              <Typography variant="body1" gutterBottom>2. Creating a New Webhook:</Typography>
              <Typography paragraph>- Select the purple button to add a new scenario.</Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screenshot2.png" alt="Open scenario builder" />
              </div>
              <Typography paragraph>- From the modules that appear, choose 'Webhooks' or search for it in the search bar.</Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screenshot3.png" alt="Select module" />
              </div>
            </Box> */}
          </Stack>
        </Paper>

        {/* Phase 2: Setting Up Your Webhook */}
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>


          <Stack spacing={1}>
            <Box>
              <Typography variant="h6">Phase 2: Configuring Your Webhook</Typography>
              <Typography variant="body1" gutterBottom>1. Setting Up the Trigger:</Typography>
              <Typography paragraph>- Within the ‘Webhooks by Zapier’ option, choose ‘Catch Hook’ which will wait for new POST, PUT, or GET requests.</Typography>
      
              <div style={{ textAlign: "center" }}>
                <img src="img/zapier/zap4.png" alt="Setting Up Webhook" />
              </div>

        
            </Box>

            <Box>

              <Typography variant="body1" gutterBottom>2. Obtaining the Webhook URL:</Typography>
              <Typography paragraph>- Once you select the ‘Catch Hook’, Zapier will provide you with a custom webhook URL. This URL will be used to send data from Blueticks.</Typography>
              <Typography paragraph>- Copy the webhook URL from Test tab.</Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/zapier/zap5.png" alt="Setting Up Webhook" />
              </div>

        
            </Box>

            
          </Stack>
        </Paper>

        {/* Phase 3: Configuring and Using Your Webhook */}
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Stack spacing={1}>
            <Box>
              <Typography variant="h6" gutterBottom>Phase 3: Configuring and Using Your Webhook</Typography>


          
            </Box>

            <Box>
              <Typography paragraph>- Navigate to the API Page from the user settings on or by clicking &nbsp;
                <Link target="_blank" href={appConfig.APP_ENDPOINT + "/admin-api"}>here</Link>
              </Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/make/screenshot10.png" alt="Configuring Webhook" />
              </div>
              <Typography paragraph>- Switch to the webhooks tab
              </Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screenshot11.png" alt="Configuring Webhook" />
              </div>
              <Typography paragraph>- Paste the webhook url from Zapier and click on Add webhook
              </Typography>

              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screenshot12.png" alt="Configuring Webhook" />
              </div>
            </Box>

            <Box>
              <Typography variant="h6" gutterBottom>Conclusion and Next Steps:</Typography>
              <Typography paragraph>
              With your webhook now set up and tested, you can integrate it with other actions on Zapier to automate your workflows. If you encounter any issues, Zapier’s support documentation and community forums are excellent resources for troubleshooting tips and additional support.
              </Typography>

              
        <Typography variant="h6" gutterBottom>Additional Resources:</Typography>
        <Typography paragraph>

              -Explore Zapier’s extensive <Link href="https://zapier.com/apps/webhook/help">help documentation</Link> for more detailed information and guides on using webhooks and creating zaps.
        </Typography>
            </Box>
          </Stack>
        </Paper>

     

      </Box>

      <div
        style={{
          textAlign: "center",
          backgroundImage: `url(img/api-bg.svg)`,
          backgroundSize: "contain",
          marginBottom: "24px",
          minHeight: "150px",
        }}
      ></div>
    </Stack>
  );
}
