import BTThemeProvider from "@components/bt-theme-provider";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { getRuntimeUrl } from "@services/storage";
import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../../components/footer/footer";
import PlanSelection from "../../../components/plan-selection/plan-selection";
import SubscriptionSelection from "../../../components/subscription-selection/subscription-selection";
import { theme } from "../../../theme";
import classes from "./subscription-view.module.scss";

export interface SubscriptionViewProps { }

export interface SubscriptionViewState {
  selectedPlan: string;
  paymentPopupOpen: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SubscriptionView = () => {
  const logoImage = useMemo(() => getRuntimeUrl("img/logo.png"), []);
  const analyticsService = useAnalyticsService();
  const [selectedPlan] = useState('professional')
  const [paymentPopupOpen, setPaymentPopupOpen] = useState(false)

  useEffect(() => {
    analyticsService.event("view_item_list", {
      category: "ecommerce",
      action: "view_item_list",
      item_list_name: "pricing page",
      item_list_id: "pricing_1",
    })
  }, [analyticsService])

  const handlePaymentPopupClose = () => {
    setPaymentPopupOpen(false);
  };

  return (
    <>
      <BTThemeProvider>
        <div>
          <div className={classes.logoContainer} style={{
            textAlign: "center",
            paddingTop: theme.spacing(4)
          }}>
            <img alt='' src={logoImage}></img>
          </div>
          <div className={classes.pricingContainer}>
            <span className={classes.title}>We're excited to introduce</span>
            <h1 style={{ marginBottom: theme.spacing(3) }}>
              <span>Blueticks Premium</span>
            </h1>
            <PlanSelection />
            <span style={{ marginTop: theme.spacing(4) }}>
              Please use this tool responsibly and refrain from sending spam
              messages
            </span>
            <div className={classes.security}>
              <span className={classes.subTitle}>
                GDPR Compliant, 100% Secured
              </span>
            </div>
            <div className={classes.security}>
              <img alt='' src="img/GDPR-badge.png" />
            </div>
          </div>
        </div>
        <Footer></Footer>
      </BTThemeProvider>
      <Dialog
        dir="ltr"
        TransitionComponent={Transition}
        open={paymentPopupOpen}
        onClose={handlePaymentPopupClose}
      >
        <SubscriptionSelection
          selectedPlan={selectedPlan}
        ></SubscriptionSelection>
      </Dialog>
    </>
  );
}

export default SubscriptionView;
