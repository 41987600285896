import { Box, Stack } from "@mui/material";
import { useAppSelector } from "@store/hooks";
import { memo } from "react";
import { CellFooterProps } from "../cell-interface";
import BatterySumCell from "./battery-sum-cell";
import FilesSumCell from "./files-sum-cell";
import NumberSumCell from "./number-sum-cell";
import CheckboxSumCell from "./checkbox-sum-cell";
import RatingSumCell from "./rating-sum-cell";
import PersonPickerSumCell from "./personpicker-sum-cell";
import { DEFAULT_COLUMN_WIDTH } from "@models/task-column";
import { getColumnValues } from "@reducers/TaskSelectors";
import { WhatsApp } from "@mui/icons-material";
import { WhatsappIdCell } from "../whatsapp-id-cell/whatsapp-id-cell";




function ColumnFooterCell({
    groupId,
    columnId,
    boardId,
    columnType,
    customData,
    column,
    index
}: CellFooterProps) {

    const footerCell = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--secondary-text-color)",
        fontWeight: "400",
        fontSize: "15px",
        height: "39px",
        boxSizing: "border-box"
    }
    const values = useAppSelector((state) => getColumnValues(state, { groupId: groupId, columnId: columnId, boardId: boardId, columnType: columnType }))
    return (
        <Box
            key={index}
            className={`column_${columnId}`}
            sx={{
                ...footerCell,
                width: column.width ?? DEFAULT_COLUMN_WIDTH,
                borderRight: '2px solid transparent',
                transition: 'width 0.05s',
            }}
        >
            <Stack direction="row" alignItems={"center"} flexGrow={1} textAlign={"center"}
                sx={{ background: 'var(--task-background-color)', width: "100%", height: "100%", overflow: "hidden", textOverflow: "ellipsis" }} >
                {columnType === "number" ?
                    (<NumberSumCell columnId={columnId} values={values} />) : null}


                {columnType === "status-option-picker" || columnType === "option-picker" || columnType === "complexity-picker" ?

                    (<BatterySumCell values={values} />) : null}
                {columnType === "files" ?

                    (<FilesSumCell values={values} columnId={columnId} groupId={groupId} />) : null}

                {columnType === "checkbox" ?

                    (<CheckboxSumCell values={values} columnId={columnId} />) : null}
                {columnType === "rating" ?

                    (<RatingSumCell values={values} columnId={columnId} />) : null}
                {columnType === "person-picker" ?
                    (<PersonPickerSumCell values={values} boardId={boardId.toString()} />) : null}

            </Stack>
        </Box>
    );
}


export default memo(ColumnFooterCell)