import { AugmentedBlueticksBoardUser } from "@reducers/BoardsSelectors";
import { User, userDisplayName } from "./user";
import { BlueticksWorkspaceUser } from "./workspace-user";


const BoardUserStatusList = ['pending', 'active']
export type BoardUserStatus = typeof BoardUserStatusList[number];

export interface BlueticksBoardUser {
    _id?: string;
    boardId: string;
    userId?: string;
    status?: BoardUserStatus;
    profile?: Partial<User>;
    email?: string;
    waId?: string;
    name?: string;
    firstName?: string;
    lastName?: string;   
    workspaceUser?: BlueticksWorkspaceUser;
    dirtySessionId?: string;
}

export const boardUserDisplayName = (user: AugmentedBlueticksBoardUser | BlueticksBoardUser) => userDisplayName(user?.profile || user) || user?.email;