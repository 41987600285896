import { Board } from "@models/board";
import { Task } from "@models/task";
import { TaskColumn } from "@models/task-column";
import { TasksGroup } from "@models/task-group";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// create a "selector creator" that uses lodash.isequal instead of ===
export type ArchivedOrDeletedItem = Task | TasksGroup | Board | TaskColumn;

export interface RecycleState {
  isArchiveShown?: boolean;
  archivedOrDeletedData: any;
  archivedOrDeletedIsLoading:boolean;
  archivedOrDeletedBulkItems:any;
  currentTab:string;
  searchText:string;
  filters:{type:string[],date:string,board:string[]},
  archiveOrDeletedSnackMessage: string;
  archiveOrDeletedAlertType: 'success' | 'error' | 'info' | null;
}

const initialState: RecycleState = {
  isArchiveShown: false,
  archivedOrDeletedData: [],
  archivedOrDeletedIsLoading:false,
  archivedOrDeletedBulkItems:[],
  currentTab:'Trash',
  searchText: "",
  filters: {
      type: [],
      date: '',
      board: [],
  },
  archiveOrDeletedSnackMessage: '',
  archiveOrDeletedAlertType: null
};


const RecycleBinSlice = createSlice({
  name: "recycle",
  initialState,
  reducers: {
    setArchiveShown(state, action: PayloadAction<boolean>) {
      state.isArchiveShown = action.payload;
    },
    setArchivedOrDeletedData(state, action: PayloadAction<any[]>) {
      state.archivedOrDeletedData = action.payload
    },
   setArchivedOrDeletedIsLoading(state, action: PayloadAction<boolean>) {
      state.archivedOrDeletedIsLoading = action.payload
    },
    setArchivedOrDeletedBulkItems(state, action: PayloadAction<ArchivedOrDeletedItem[]>) {
      state.archivedOrDeletedBulkItems = action.payload
    },
    setCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload
    },
    setFilters(state, action: PayloadAction<{ type: string[]; date: string; board: string[] }>) {
      state.filters = action.payload;
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setArchivedOrDeletedSnackMessage: (state, action: PayloadAction<{ message: string; type: 'success' | 'error' | 'info' }>) => {
      state.archiveOrDeletedSnackMessage = action.payload.message;
      state.archiveOrDeletedAlertType = action.payload.type;
    },
  },
});

export const recycleActions = RecycleBinSlice.actions;
export default RecycleBinSlice.reducer;
