import { ExtraDataItem } from "@models/ExtraDataItem";
import { EntitySubscriber } from "@models/entity-subscriber";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkAppDispatch } from "@store/hooks";
import { RootState } from "@store/index";
import { getSelectedBoardId, getSelectedBoardUserList } from "./BoardsSelectors";
import { DBEntitySubscribersThunks, DBTasksExtraDataThunks, entitySubscribersSelectByQuery, entitySubscribersSelectOneObjectByQuery, tasksSelectors } from "./DBServiceReducers";
import { selectUserId, selectUserObject } from "./UserSelectors";
import { createSelector } from "@reduxjs/toolkit";




const handleSubscritionCreationOrUpdate = async (newEntitySubscriber: EntitySubscriber, thunkAPI) => {
    const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;
    const state = thunkAPI.getState();
    const userId = selectUserId(state)
    const boardId = getSelectedBoardId(state)
    const entitySubscriber = await entitySubscribersSelectOneObjectByQuery(
        state.DBEntitySubscribersReducer,
        {
            ...newEntitySubscriber,
        })

    /// check if the is an entity subscriber with entityType = 'board' and same userId and boardId with deleted= false
    const boardSubscriber = await entitySubscribersSelectOneObjectByQuery(
        state.DBEntitySubscribersReducer,
        {
            userId,
            boardId,
            entityType: 'board',
        })

    if (!boardSubscriber) {
        if (entitySubscriber && entitySubscriber.deleted) {

            dispatch(DBEntitySubscribersThunks.patch({ entity: { ...entitySubscriber, deleted: false } }))
        }
        if (!entitySubscriber) {
            dispatch(DBEntitySubscribersThunks.create(newEntitySubscriber))
        }
    }
}


const handleSubscritionCancelation = async (newEntitySubscriber: EntitySubscriber, thunkAPI) => {
    const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;
    const state = thunkAPI.getState();

    const entitySubscriber = await entitySubscribersSelectOneObjectByQuery(
        state.DBEntitySubscribersReducer,
        {
            ...newEntitySubscriber,
        })

    if (entitySubscriber && !entitySubscriber.deleted) {
        const newEntitySubscriber = {
            ...entitySubscriber,
            deleted: true
        }
        dispatch(DBEntitySubscribersThunks.patch({ entity: newEntitySubscriber }))
    }
}


export const CreateExtraDataAndEntitySuscribersThunk = createAsyncThunk<
    void,
    {
        editorValue: any;
        taskId: string;
    },
    { state: RootState }
>("tasks/addNewTaskGroupThunk", async ({ editorValue, taskId }, thunkAPI) => {
    const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;
    const state = thunkAPI.getState();
    const task = tasksSelectors.selectById(state, taskId)
    const boardId = getSelectedBoardId(state)
    // const user = selectUserObject(state)

    let _editorValue = editorValue.editorValue;
    let parentItemId = editorValue.parentItemId;

    const mentionedUsersIds = []
    for (let children of _editorValue) {
        for (let descendant of children?.children) {
            if (descendant?.type && descendant?.type === 'mention') {
                mentionedUsersIds.push(descendant.userId)
            }
        }
    }


    const extraDataItem: ExtraDataItem = {
        taskId: task._id,
        groupId: task.groupId,
        boardId: task.boardId,
        author: "",
        extraData: _editorValue,
        isUpdating: false,
        isPinedToTop: false,
        parentItemId,
    };
    dispatch(DBTasksExtraDataThunks.create(extraDataItem));

    for (let userId of mentionedUsersIds) {
        handleSubscritionCreationOrUpdate({
            userId,
            entityType: 'task' as 'task' | 'board',
            taskId: task._id,
            boardId,
        }, thunkAPI)
    }

});


export const createSubscriptionThunk = createAsyncThunk<
    void,
    { userId },
    { state: RootState }
>("tasks/addNewTaskGroupThunk", async ({ userId }, thunkAPI) => {
    const state = thunkAPI.getState();
    const taskId = state.ExtraDataReducer.displayExtraDataOfTaskById
    const boardId = getSelectedBoardId(state)

    handleSubscritionCreationOrUpdate({
        userId,
        entityType: 'task' as 'task' | 'board',
        taskId,
        boardId,
    }, thunkAPI)

});


export const cancelSubscriptionThunk = createAsyncThunk<
    void,
    { userId },
    { state: RootState }
>("tasks/addNewTaskGroupThunk", async ({ userId }, thunkAPI) => {
    const state = thunkAPI.getState();
    const taskId = state.ExtraDataReducer.displayExtraDataOfTaskById
    const boardId = getSelectedBoardId(state)

    handleSubscritionCancelation({
        userId,
        entityType: 'task' as 'task' | 'board',
        taskId,
        boardId,
    }, thunkAPI)

});




export const getTaskSubscribedUsersList = createSelector(
    [
        (state) => state,
        (state) => getSelectedBoardUserList(state)
    ],
    (state, boardUserList) => {
        const taskId = state.ExtraDataReducer.displayExtraDataOfTaskById
        const boardId = getSelectedBoardId(state);
        const taskSubscribersList = entitySubscribersSelectByQuery(
            state.DBEntitySubscribersReducer,
            {
                boardId,
                taskId,
                entityType: 'task',
                deleted: false,
            }
        );
        const boardSubscribersList = entitySubscribersSelectByQuery(
            state.DBEntitySubscribersReducer,
            {
                boardId,
                entityType: 'board',
                deleted: false,
            }
        );
        const userIdsList = [...taskSubscribersList, ...boardSubscribersList].map(subscriber => subscriber.userId)

        return boardUserList.filter(user => userIdsList?.includes(user.userId))

    }
);


export const getFilteredUserListForSubscriptionManagement = createSelector(
    [
        (state, searchTerm) => state,
        (state, searchTerm) => searchTerm,
        (state, searchTerm) => getSelectedBoardUserList(state),
    ],
    (state, searchTerm, boardUserList) => {

        const subscribersIdList = getTaskSubscribedUsersList(state).map(subscriber => subscriber.userId)

        const filteredList = boardUserList
            .filter(user => !subscribersIdList?.includes(user.userId))
            .filter(user => user?.profile?.firstName?.toLowerCase().includes(searchTerm?.toLowerCase())
                || user?.profile?.lastName?.toLowerCase().includes(searchTerm?.toLowerCase())
                || user?.email?.toLowerCase().includes(searchTerm?.toLowerCase())
            )


        return filteredList
    }
);


export const getIsUserSubscribed = createSelector(
    [
        (state, userId) => state,
        (state, userId) => userId,
        (state, userId) => getTaskSubscribedUsersList(state),
    ],
    (state, userId, subscribersList) => {
        const taskId = state.ExtraDataReducer.displayExtraDataOfTaskById
        const boardId = getSelectedBoardId(state);
        const taskSubscribersList = entitySubscribersSelectByQuery(
            state.DBEntitySubscribersReducer,
            {
                boardId,
                taskId,
                entityType: 'task',
                deleted: false,
            }
        );
        const isSubscribed = subscribersList?.map(user => user?.userId)?.includes(userId)
        const isSubscribedToBoard = taskSubscribersList?.filter(subscriber => subscriber.userId === userId
            && subscriber.entityType === 'board').length !== 0

        return [isSubscribed, isSubscribedToBoard]

    }
);

