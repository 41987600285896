import moment, { Moment } from "moment";
import { ByWeekday, Frequency, Options, RRule, RRuleSet, Weekday, WeekdayStr, rrulestr } from "rrule";
import { RecurrenceUnit, Schedule } from "types/interface";

export type CherryPickOptions = Pick<Options, "freq" | "dtstart" | "interval" | "wkst" | "count" | "until" | "byweekday" | "bymonthday">;
type CherryPickKeys = keyof CherryPickOptions;
const allowedKeys: CherryPickKeys[] = ["freq", "dtstart", "interval", "wkst", "count", "until", "byweekday", "bymonthday"];


const weekdaystr = (m: moment.Moment) => Weekday.fromStr(
  m.format("dd").toUpperCase() as WeekdayStr
)

export const weekStart = weekdaystr(moment().day(0));
export const currentDay = weekdaystr(moment());

export const extractSignificantProps = (rule: RRule) => {
  return {
    freq: rule.origOptions.freq,
    bymonthday: Array.isArray(rule.options.bymonthday) 
                ? rule.options.bymonthday 
                : [rule.options.bymonthday].filter(Boolean),
    byweekday: Array.isArray(rule.options.byweekday) 
                ? rule.options.byweekday 
                : [rule.options.byweekday].filter(Boolean),
  };
};

export function getByWeekDay(dueDate) {
  const dayOfWeek = dueDate.day();  // 0 (Sunday) to 6 (Saturday)
  const nthWeek = Math.ceil(dueDate.date() / 7);  // Which occurrence of the day in the month

  switch (dayOfWeek) {
      case 0: return RRule.SU.nth(nthWeek);
      case 1: return RRule.MO.nth(nthWeek);
      case 2: return RRule.TU.nth(nthWeek);
      case 3: return RRule.WE.nth(nthWeek);
      case 4: return RRule.TH.nth(nthWeek);
      case 5: return RRule.FR.nth(nthWeek);
      case 6: return RRule.SA.nth(nthWeek);
      default: throw new Error("Invalid day of the week.");
  }
}

const cherryPick = (rule: RRule) => Object.keys(rule.origOptions).filter(key => allowedKeys.includes(key as CherryPickKeys)).reduce((obj, key) => {
  obj[key] = rule.origOptions[key];
  return obj;
}, {} as CherryPickOptions);



export const ruleOptionsFromString = (rfcString: string): CherryPickOptions => cherryPick(RRule.fromString(rfcString))

export const defaultRuleOptions = (freq = RRule.WEEKLY, interval = 1) => ({
  freq: freq,
  interval: interval,
  byweekday: [currentDay.weekday],
  wkst: weekStart,
} as CherryPickOptions)

export const getWeekDay = (m: moment.Moment) => [Weekday.fromStr(
  m.format("dd").toUpperCase() as WeekdayStr
).weekday]

export const initialRuleOptions = (m: moment.Moment) =>
  ({ ...defaultRuleOptions(), byweekday: getWeekDay(m) } as Partial<Options>)


export const toRuleSet = (rfcString: string) => rrulestr(rfcString, {
  forceset: true,
}) as RRuleSet;

export const fetchRule = (rfcString: string): RRule => toRuleSet(rfcString)._rrule[0];

export const extractWeekdayNumbers = (weekdays: ByWeekday | ByWeekday[]): number[] => {
  // If it's just a single value, wrap it in an array for consistent processing
  const arrayWeekdays = Array.isArray(weekdays) ? weekdays : [weekdays];

  return arrayWeekdays.map(day => {
    if (day instanceof Weekday) {
      return day.weekday;
    }
    return day as number;
  });
};

// Helper function to determine the closest occurrence either the next or the previous
export function getClosestOccurrence(currentDate: Moment, nextOccurrence: Moment | null, prevOccurrence: Moment | null): moment.Moment | null {
  if (nextOccurrence && prevOccurrence) {
    const nextDiff = Math.abs(nextOccurrence.diff(currentDate));
    const prevDiff = Math.abs(prevOccurrence.diff(currentDate));
    return nextDiff < prevDiff ? nextOccurrence : prevOccurrence;
  } else if (nextOccurrence) {
    return nextOccurrence;
  } else if (prevOccurrence) {
    return prevOccurrence;
  }
  return null;
}

export const patchRuleSet = (set: RRuleSet, patch: Partial<CherryPickOptions>) => {
  const { dtstart, _rrule } = set;
  const newSet = new RRuleSet();
  newSet.dtstart = dtstart;
  set.exdates().forEach(x => newSet.exdate(x));

  _rrule.forEach(r => {
    newSet.rrule(new RRule({ ...ruleOptionsFromString(RRule.optionsToString(r.origOptions)), ...patch }))
  })
  return newSet
}
export const getRuleValue = (set: RRuleSet, key: CherryPickKeys) => set._rrule[0].origOptions[key];

export const parseOldUnits = (recurenceUnit: RecurrenceUnit, recurrenceQuantity: number): RRuleSet => {
  const ruleset = new RRuleSet();
  ruleset.rrule(
    new RRule(
      defaultRuleOptions(
        parseRecurrenceUnit(recurenceUnit),
        recurrenceQuantity
      )
    )
  );
  return ruleset;
}

export const getDefaultOptions = (recurenceUnit: RecurrenceUnit, recurrenceQuantity: number) =>
  defaultRuleOptions(parseRecurrenceUnit(recurenceUnit), recurrenceQuantity)

export const optionsFromRuleset = (schedule: Schedule) => {
  let options;
  if (!!schedule.rruleset) {
    const ruleRfc = toRuleSet(schedule.rruleset)._rrule[0].toString();
    options = ruleOptionsFromString(ruleRfc);

    const { byweekday, freq } = options;
    if (freq === Frequency.WEEKLY) {
      const bwd = (byweekday as Weekday[]).map((d) => d.weekday);
      options = { ...options, byweekday: bwd };
    }
  } else {
    options = getDefaultOptions(
      schedule.recurenceUnit,
      schedule.recurrenceQuantity
    )
  }
  return options;
}

export const parseRecurrenceUnit = (unit: RecurrenceUnit): Frequency => {
  switch (unit) {
    case "day":
      return Frequency.DAILY;
    case "hour":
      return Frequency.HOURLY;
    case "minute":
      return Frequency.MINUTELY;
    case "month":
      return Frequency.MONTHLY;
    case "week":
      return Frequency.WEEKLY;
    case "year":
      return Frequency.YEARLY;
    default:
      return Frequency.DAILY;
  }
};

export const weekOfMonth = (m: moment.Moment): number => {
  const { months } = m.toObject();
  if (months !== moment(m).add(7, "d").toObject().months) return -1
  if (months !== moment(m).subtract(7, "d").toObject().months) return 1
  if (months !== moment(m).subtract(14, "d").toObject().months) return 2
  if (months !== moment(m).subtract(21, "d").toObject().months) return 3
  if (months !== moment(m).subtract(28, "d").toObject().months) return 4
};
