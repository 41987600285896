import { ContentCopyOutlined, IntegrationInstructionsOutlined, RotateRightOutlined, ShareOutlined } from '@mui/icons-material'
import { Button, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { DBFormThunks } from '@reducers/DBServiceReducers'
import { taskActions } from '@reducers/TasksReducer'
import { useAppDispatch } from '@store/hooks'
import { namespace } from 'config/constants'
import randomstring from 'randomstring'
import { useTranslation } from 'react-i18next'

type Props = {
    formId: string,
    shareId: string,
}

export default function FormShare({ formId, shareId }: Props) {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const url = window.location.origin + '/form-preview/' + shareId
    const embedString = `<iframe src="${url}" width="700" height="550" style="border: 0px; box-shadow: 3px 53px 45px 0px lightgrey;"></iframe>`

    const regenerateLink = () => {
        const uniqueId = randomstring.generate(24)
        dispatch(DBFormThunks.patch({
            entity: {
                _id: formId,
                shareId: uniqueId
            }
        }))
    }

    return (
        <Stack direction='column'>
            <div>
                <h1 style={{
                    textAlign: 'center',
                    marginTop: '90px'
                }}>
                    {t('formShare.headerTitle')}
                </h1>
                <p style={{
                    textAlign: 'center',
                }}>
                    {t('formShare.headerDescription')}
                </p>
            </div>
            <div>
                <Stack direction='column' sx={{
                    paddingTop: '50px',
                    paddingBottom: '10px',
                    alignItems: 'flex-start'
                    }}>
                    <Stack direction='row' alignItems={'center'} gap={'6px'} style={{fontWeight: '600'}}>
                        <ShareOutlined style={{
                            cursor: 'pointer',
                            fontSize: '20px',
                        }} />
                        {t('formShare.shareableLink')}
                    </Stack>
                    <Stack direction='row' sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '400px',
                        marginTop: '10px',
                    }}>
                        {/* <TextField
                            hiddenLabel
                            InputProps={{
                                readOnly: true,
                            }}
                            id="filleddisabled-hidden-label-small"
                            value={url}
                            variant="filled"
                            size="small"
                            style={{ width: '300px', background: 'white', fontSize: '14px' }}
                        /> */}
                        <span style={{
                            width: '300px',
                            background: 'white',
                            fontSize: '14px',
                            borderRadius: '8px',
                            border: '1px solid lightgrey',
                            boxShadow: '0px 0px 5px 0px lightgrey',
                            padding: '4px 8px',
                            userSelect: 'all'
                        }}>
                            {url}
                        </span>
                        <Button
                            startIcon={<ContentCopyOutlined />}
                            style={{ textTransform: 'none', }}
                            value="copy"
                            onClick={() => {
                                navigator.clipboard.writeText(url)
                                dispatch(taskActions.setTaskSnackbarMessage(t('formShare.copySuccessMessage')))
                            }}
                        >
                            {t('formShare.copyButtonLabel')}
                        </Button>
                    </Stack>
                    <Button
                        startIcon={<RotateRightOutlined />}
                        style={{ textTransform: 'none', fontSize: '12px', width: '140px' }}
                        size="small"
                        value="regenerate"
                        onClick={regenerateLink}
                    >
                        {t('formShare.regenerateLinkButtonLabel')}
                    </Button>
                </Stack>
                <Stack direction='column' sx={{ paddingY: '10px' }}>
                <Stack direction='row' alignItems={'center'} gap={'6px'} style={{fontWeight: '600'}}>
                        <IntegrationInstructionsOutlined style={{
                            cursor: 'pointer',
                            fontSize: '20px',
                        }} />
                        {t('formShare.embedSectionLabel')}
                    </Stack>
                    <Stack direction='row' sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '400px',
                        marginTop: '10px',
                    }}>
                        {/* <TextField
                            hiddenLabel
                            InputProps={{
                                readOnly: true,
                            }}
                            id="filleddisabled-hidden-label-small"
                            value={embedString}
                            variant="filled"
                            size="small"
                            style={{ width: '300px', background: 'white' }}
                        /> */}
                        <span style={{
                            width: '300px',
                            background: 'white',
                            fontSize: '14px',
                            borderRadius: '8px',
                            border: '1px solid lightgrey',
                            boxShadow: '0px 0px 5px 0px lightgrey',
                            padding: '4px 8px',
                            userSelect: 'all'
                        }}>
                            {embedString}
                        </span>
                        <Button
                            startIcon={<ContentCopyOutlined />}
                            style={{ textTransform: 'none', }}
                            value="copy"
                            onClick={() => {
                                navigator.clipboard.writeText(embedString)
                                dispatch(taskActions.setTaskSnackbarMessage(t('formShare.embedCopySuccessMessage')))
                            }}>
                            {t('formShare.copyButtonLabel')}
                        </Button>
                    </Stack>
                </Stack>
            </div>
        </Stack>
    )
}