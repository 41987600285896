import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkDispatch, RootState } from "@store/index";
import moment from "moment";
import { backendApiEndpoints } from "./backend-api/backend-api";
import { boardsReducerActions, boardsSelectOneObjectByQuery, BoardUserReducerActions, DBBoardsThunks, DBBoardUserThunks, DBGanttThunks, DBTaskgroupsThunks, DBTasksThunks, GanttReducerActions, taskgroupsReducerActions, tasksReducerActions, tasksSelectByQuery } from "./DBServiceReducers";
import { taskActions } from "./TasksReducer";
import { selectCurrentWorkspace } from "./UserSelectors";
import { getCurrentBoardId, getFirstBoardId } from "./BoardsSelectors";
import { setSelectedBoardId } from "./BoardsThunks";

export const UndoToastTimout = 15000;

export interface ActionThunkApi {
    state: RootState;
    dispatch: AppThunkDispatch;
}

export type ActionType = 'delete-task-list' | 'delete-group-list' | 'delete-board-list' | 'delete-board-user-list' | 'delete-arrow-relation';
export const ActionTypeDisplayName = {
    'delete-task-list': 'task',
    'delete-group-list': 'group',
    'delete-board-list': 'board',
    'delete-board-user-list': 'board user',
    'delete-arrow-relation': 'gantt-arrow'
}

export interface DoActionParams<T = any> {
    data: T;
    thunkApi: ActionThunkApi;
    deletedOrArchived?: string;
}

export interface UndoActionParams<T = any, DoActionResponse = any> {
    data: T;
    thunkApi: ActionThunkApi;
    doActionResponse: DoActionResponse;
}

export interface ActionDescriptor<T = any, DoActionResponse = any> {
    doAction: (params: DoActionParams) => Promise<DoActionResponse>,
    undoAction: (params: UndoActionParams) => void,
}

export const ActionMap: Record<ActionType, ActionDescriptor> = {
    'delete-task-list': {
        doAction: async (params) => {
            const { data: taskIdList, thunkApi } = params;
            const { dispatch, state } = thunkApi;
            const currentWorkspace = selectCurrentWorkspace(state);
            const [taskId] = taskIdList;
            const [task] = tasksSelectByQuery(state.DBTasksReducer, {
                _id: taskId
            })

            // dispatch(tasksReducerActions.updateMany(
            //     taskIdList.map((taskId, index) => ({
            //         id: taskId,
            //         changes: {
            //             deleted: true,
            //         },
            //     }))
            // ));

            dispatch(tasksReducerActions.removeMany(taskIdList));

            const { recycleId } = await dispatch(backendApiEndpoints.requestRecycle.initiate({
                workspaceId: currentWorkspace._id,
                deletedOrArchived: params.deletedOrArchived,
                itemList: taskIdList.map(taskId => ({
                    id: taskId,
                    type: 'tasks',
                }))
            })).unwrap();

            return { recycleId, boardId: task.boardId };
        },
        undoAction: async (params) => {
            const { thunkApi, doActionResponse } = params;
            const { recycleId, boardId, deletedOrArchived } = doActionResponse;
            const { dispatch } = thunkApi;

            await dispatch(backendApiEndpoints.restoreRequest.initiate({
                recycleId,
                deletedOrArchived
            }))
       
           await  dispatch(DBTasksThunks.find({
                boardId,
                $paginate: false,
            })).unwrap();
           
                dispatch(undoRedoActions.clearUndoToast())
            
           
        },
    } as ActionDescriptor<string[], { recycleId: string, boardId: string }>,
    'delete-group-list': {
        doAction: async (params) => {
            const { data: groupIdList, thunkApi } = params;

            const { dispatch, state } = thunkApi;
            const currentWorkspace = selectCurrentWorkspace(state);

            const [groupId] = groupIdList;
            const [group] = tasksSelectByQuery(state.DBTaskgroupsReducer, {
                _id: groupId
            })

            dispatch(taskgroupsReducerActions.removeMany(groupIdList));

            const { recycleId } = await dispatch(backendApiEndpoints.requestRecycle.initiate({
                workspaceId: currentWorkspace._id,
                deletedOrArchived: params.deletedOrArchived,
                itemList: groupIdList.map(taskId => ({
                    id: taskId,
                    type: 'task-groups'
                }))
            })).unwrap();

            return { recycleId, boardId: group.boardId };
        },
        undoAction: async (params) => {
            const { thunkApi, doActionResponse } = params;
            const { recycleId, deletedOrArchived, boardId } = doActionResponse;
            const { dispatch } = thunkApi;

            await dispatch(backendApiEndpoints.restoreRequest.initiate({
                recycleId,
                deletedOrArchived
            }))

            dispatch(DBTaskgroupsThunks.find({
                boardId,
                $paginate: false,
            }))
            dispatch(DBTasksThunks.find({
                boardId,
                $paginate: false,
            }))
            dispatch(undoRedoActions.clearUndoToast())

        },
    } as ActionDescriptor<string[], { recycleId: string, boardId: string }>,
    'delete-board-list': {
        doAction: async (params) => {
            const { data: boardIdList, thunkApi } = params;
            const { dispatch, state } = thunkApi;
            const currentWorkspace = selectCurrentWorkspace(state);
            const currentBoardId = await getCurrentBoardId(state)

            dispatch(boardsReducerActions.removeMany(boardIdList));
            const [firstDeletedBoardId] = boardIdList;
            const firstDeletedBoard = boardsSelectOneObjectByQuery(state.DBBoardsReducer, {
                _id: firstDeletedBoardId
            })
            dispatch(taskActions.setDeletedBoardName(firstDeletedBoard?.name))

            const { recycleId } = await dispatch(backendApiEndpoints.requestRecycle.initiate({
                workspaceId: currentWorkspace._id,
                deletedOrArchived: params.deletedOrArchived,
                itemList: boardIdList.map(boardId => ({
                    id: boardId,
                    type: 'boards'
                }))
            })).unwrap();

            const firtBoardId = await getFirstBoardId(state)

            if (firstDeletedBoard._id === currentBoardId) {
                await dispatch(setSelectedBoardId({ boardId: firtBoardId }));
            }


            return { recycleId, boardIdList };
        },
        undoAction: async (params) => {
            const { thunkApi, doActionResponse } = params;
            const { recycleId, boardIdList } = doActionResponse;
            const { dispatch } = thunkApi;

           await dispatch(backendApiEndpoints.restoreRequest.initiate({
                recycleId
            })).unwrap()
            
            dispatch(DBBoardsThunks.find({
                $paginate: false,
                $id: {
                    $in: boardIdList
                }
            }))
            dispatch(taskActions.clearDeletedBoardName());
            dispatch(undoRedoActions.clearUndoToast())

        },
        
    } as ActionDescriptor<string[], { recycleId: string, boardIdList: string[] }>,
    'delete-board-user-list': {
        doAction: async (params) => {
            const { data: boardUserIdList, thunkApi } = params;
            const { dispatch, state } = thunkApi;
            const currentWorkspace = selectCurrentWorkspace(state);

            dispatch(BoardUserReducerActions.removeMany(boardUserIdList));

            const { recycleId } = await dispatch(backendApiEndpoints.requestRecycle.initiate({
                workspaceId: currentWorkspace._id,
                deletedOrArchived: params.deletedOrArchived,
                itemList: boardUserIdList.map(boardUserId => ({
                    id: boardUserId,
                    type: 'board-users'
                }))
            })).unwrap();

            return { recycleId, boardUserIdList };
        },
        undoAction: async (params) => {
            const { thunkApi, doActionResponse } = params;
            const { recycleId, boardUserIdList } = doActionResponse;
            const { dispatch } = thunkApi;

            await dispatch(backendApiEndpoints.restoreRequest.initiate({
                recycleId
            }))

            dispatch(DBBoardUserThunks.find({
                $paginate: false,
                _id: {
                    $in: boardUserIdList
                }
            }))
            dispatch(undoRedoActions.clearUndoToast())

        },
    } as ActionDescriptor<string[], { recycleId: string, boardUserIdList: string[] }>,
    'delete-arrow-relation': {
        doAction: async (params) => {
            const { data: gantt, thunkApi } = params;
            const { dispatch, state } = thunkApi;
            const currentWorkspace = selectCurrentWorkspace(state);
            let cloneDependencies
            gantt.tasksDependencies = { ...cloneDependencies }

            dispatch(GanttReducerActions.updateMany(gantt.cloneDependencies));

            const { recycleId } = await dispatch(backendApiEndpoints.requestRecycle.initiate({
                workspaceId: currentWorkspace._id,
                deletedOrArchived: params.deletedOrArchived,
                itemList: gantt.map(ganttId => ({
                    id: ganttId,
                    type: 'delete-arrow-relation'
                }))
            })).unwrap();

            return { recycleId, gantt };
        },
        undoAction: async (params) => {
            const { thunkApi, doActionResponse } = params;
            const { recycleId, gantt } = doActionResponse;
            const { dispatch } = thunkApi;

            await dispatch(backendApiEndpoints.restoreRequest.initiate({
                recycleId
            }))

            dispatch(DBGanttThunks.find({
                $paginate: false,
                _id: {
                    $in: gantt
                }
            }))
            dispatch(undoRedoActions.clearUndoToast())

        },
    } as ActionDescriptor<string[], { recycleId: string, gantt: string[] }>
}

export interface UndoRedoAction<T = any, DoActionResponse = any> {
    type: ActionType;
    data: T;
    deletedOrArchived: string;
    doActionResponse?: DoActionResponse;
}

export interface UndoRedoState {
    undoStack: UndoRedoAction[];
    redoStack: UndoRedoAction[];
    timerHandle?: number;
    timerDueTime?: string;
}

export const initialState: UndoRedoState = {
    undoStack: [],
    redoStack: [],
}

export const undoRedoSlice = createSlice({
    name: "undoRedoSlice",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        pushUndoAction: (state, action: PayloadAction<UndoRedoAction>) => {
            state.undoStack = [
                ...state.undoStack,
                action.payload
            ]
        },
        pushRedoAction: (state, action: PayloadAction<UndoRedoAction>) => {
            state.redoStack = [
                ...state.redoStack,
                action.payload
            ]
        },
        showUndoToast: (state) => {
            state.timerDueTime = moment().add(UndoToastTimout, 'milliseconds').toISOString();
        },
        clearUndoToast: (state) => {
            delete state.timerDueTime;
            delete state.timerHandle;
        },
        updateToastTimerHandler: (state, action: PayloadAction<NodeJS.Timeout>) => {
            state.timerHandle = action.payload as unknown as number;
        }
    }
});

export const undoRedoActions = undoRedoSlice.actions;
export default undoRedoSlice.reducer;

