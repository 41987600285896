import { Box } from '@mui/material'
import EmojiPickerPopper from './poppers/emoji-picker-popper'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { labelActions } from '@reducers/labels-reducer'
import LabelInput from './label-input'


type Props = {
    disabled?: boolean
}

export default function LabelForm({ disabled }: Props) {
    const dispatch = useAppDispatch()
    const label = useAppSelector((state) => state.LabelReducer.label)
    return (
        <Box sx={{
            height: 45,
            display: 'flex',
            alignItems: 'center',
            borderRadius: '10px',
            margin: '0 15px',
            border: `1px solid var(--label-border-color)`
        }}>
            <Box>
                <EmojiPickerPopper label={label} disabled={disabled} />
            </Box>
            <LabelInput
                value={label.name} disabled={disabled}
                onChange={({ target }: any) => dispatch(labelActions.setLabel({ ...label, name: target.value }))}
                placeholder='Type name here...'
                sx={{
                    ':hover': {
                        border: disabled ? 0 : '1px solid #546eff'
                    }
                }}
            />
        </Box>
    )
}

