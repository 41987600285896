import BTButton from '@components/bt-button/bt-button';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Switch, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { automation } from '@reducers/AutomationReducer';
import { setCurrentAutomation } from '@reducers/AutomationThunks';
import { getSelectedBoardId } from '@reducers/BoardsSelectors';
import { DBAutomationActionsThunks, DBAutomationConditionsThunks, DBAutomationTriggerThunks, DBAutomationsThunks, automationsSelectByQuery } from '@reducers/DBServiceReducers';
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { FaPlus } from 'react-icons/fa';
import TranslateAutomationToText from './automation-translate-to-text';
import { useEffect } from 'react';
import PersonAvatar from '@components/tasks/person-avatar/person-avatar';
import { selectUser } from '@reducers/UserSelectors';
import { grey } from "@mui/material/colors";
import { styled } from '@mui/system';
import UpdateIcon from '@mui/icons-material/Update';
const AutomationsList = () => {
    const dispatch = useAppDispatch()
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const automations = useAppSelector((state) => automationsSelectByQuery
        (state.DBAutomationReducer, { boardId: boardId, deleted: false }))

    const user = useAppSelector(selectUser);

    const createAutomation = () => {
        dispatch(automation.resetAutomationState());
        dispatch(automation.setActiveView("currentAutomation"));
    }

    const editAutomation = (e, automationId, ownerObj) => {
        e.stopPropagation();
        dispatch(automation.setSelectedAutomationId(automationId));
        dispatch(automation.setSelectedAutomationOwner(ownerObj))
        dispatch(setCurrentAutomation());
        dispatch(automation.setActiveView("currentAutomation"));
    }

    const toggleAutomation = (e, automationId, active) => {
        e.stopPropagation();
        dispatch(DBAutomationsThunks.patch({ entity: { _id: automationId.toString(), active: !active } }));
    }

    const deleteAutomation = (e, automationId) => {
        e.stopPropagation();
        dispatch(DBAutomationsThunks.patch({ entity: { _id: automationId.toString(), deleted: true } }));
    }


    useEffect(() => {
        dispatch(DBAutomationsThunks.find({ $limit: -1 }));
        dispatch(DBAutomationTriggerThunks.find({ $limit: -1 }));
        dispatch(DBAutomationConditionsThunks.find({ $limit: -1 }));
        dispatch(DBAutomationActionsThunks.find({ $limit: -1 }));
    }, [dispatch]);
    return (
        <Stack>
            <Stack mx={8} direction={"row"} justifyContent={"space-between"} alignItems={"center"} flexWrap={"wrap"}>
                <h2>Board automations - {automations.length}</h2>
                <BTButton onClick={createAutomation}>
                    <FaPlus style={{ marginInlineEnd: '5px', height: '14px', width: '14px' }} />
                    Add new automation</BTButton>
            </Stack>
            <Stack>
                {
                    automations.map((automation, index) =>
                        <Stack mx={8} direction="row" justifyContent={"space-between"} alignItems={"center"} sx={{ border: "1px solid lightgrey", my: 2, borderRadius: "8px", boxShadow: '0 2px 6px 0 #eeeeee',transition:"all 0.2s", cursor: 'pointer', "&:hover": { boxShadow: '0 6px 20px 0 #e0e0e0;',transform: "translate3d(0, -2px, 0)",border:"none" } }} key={index}>
                            <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} onClick={(e) => editAutomation(e, automation._id, automation?.ownerObj)} sx={{ width: '100%', p: 2 }}>
                                <Stack width="100%">
                                    <TranslateAutomationToText id={automation._id} />
                                </Stack>
                                <Stack width={"50%"} spacing={2} >
                                    <Stack direction="row" alignItems={"center"} justifyContent={"flex-end"}>
                                        <Stack direction="row" alignItems={"center"}>
                                            <Stack direction="row" alignItems={"center"}>
                                                <Stack sx={{ cursor: user._id !== automation?.ownerObj._id ? 'not-allowed' : 'pointer' }} direction="row" alignItems={"center"} onClick={(e) => {
                                                    if (user._id === automation?.ownerObj._id) {
                                                        e.stopPropagation();
                                                        toggleAutomation(e, automation._id, automation.active);
                                                    }
                                                }}>
                                                    <Typography fontSize={12}>Off</Typography>
                                                    <CustomSwitch
                                                        disabled={user._id !== automation?.ownerObj._id}
                                                        checked={automation.active}
                                                    />
                                                    <Typography fontSize={12}>On</Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Box pl={4} onClick={e => e.stopPropagation()}>
                                            <IconButton disabled={user._id !== automation?.ownerObj._id} onClick={(e) => deleteAutomation(e, automation._id)} sx={{ padding: 0 }} >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Stack>
                                    <Stack direction="row" alignItems={"center"} justifyContent={"flex-end"}>
                                        <PersonAvatar
                                            toolTip={true}
                                            size={22}
                                            email={automation?.ownerObj?.email}
                                            profileImg={automation?.ownerObj?.profileImg}
                                            lastName={automation?.ownerObj?.lastName}
                                            firstName={automation?.ownerObj?.firstName}
                                        />
                                        <Typography pr={"3px"} pl={"5px"} fontSize={"12px"}>Created by</Typography>

                                        <Typography fontSize={"12px"}>
                                            {automation?.ownerObj?.firstName ? `${automation?.ownerObj?.firstName} ${automation?.ownerObj?.lastName} ` : `${automation?.ownerObj?.email}`} </Typography>

                                        <Box pl={"5px"}>
                                            <TimeAgo updatedAt={automation.updatedAt} />
                                        </Box>

                                    </Stack>

                                </Stack>

                            </Stack>

                        </Stack>
                    )
                }
            </Stack>
        </Stack>
    );
};

export default AutomationsList;




interface TimeAgoProps {
    updatedAt: string;
}

const TimeAgo: React.FC<TimeAgoProps> = ({ updatedAt }) => {
    let timeAgo = "";

    const date: Date = new Date(updatedAt);
    const now: Date = new Date();

    const diffInMinutes: number = (now.getTime() - date.getTime()) / 1000 / 60; 
    const diffInHours: number = diffInMinutes / 60;
    const diffInDays: number = diffInHours / 24;
    const diffInMonths: number = diffInDays / 30;
    const diffInYears: number = diffInMonths / 12;

    if (diffInMinutes < 5) {
        timeAgo = "just now";
    } else if (diffInMinutes < 60) {
        timeAgo = Math.round(diffInMinutes) + " minutes ago";
    } else if (diffInHours < 24) {
        timeAgo = Math.round(diffInHours) + " hours ago";
    } else if (diffInDays < 30) {
        timeAgo = Math.round(diffInDays) + " days ago";
    } else if (diffInMonths < 12) {
        timeAgo = Math.round(diffInMonths) + " months ago";
    } else {
        timeAgo = Math.round(diffInYears) + " years ago";
    }

    return (
        <Box >
            <Tooltip title={date.toString()} arrow disableInteractive>
                <Stack direction="row" alignItems={"center"}>
                    <UpdateIcon sx={{ pr: "4px", fontSize: "16px" }} />  <Typography fontSize={"12px"}> Updated {timeAgo}</Typography>
            </Stack>
        </Tooltip>
        </Box>
    );
};


const GreySwitch = styled(Switch)({
    color: grey[300],
    '&.Mui-checked': {
        color: grey[300],
        cursor: "not-allowed",
    },
    '& .Mui-checked + .MuiSwitch-track': {
        backgroundColor: grey[300],
        cursor: "not-allowed",
        opacity: "0.5"
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: grey[300],
        cursor: "not-allowed",
    },
    '& .MuiSwitch-track': {
        backgroundColor: grey[300],
        cursor: "not-allowed",
    }
});

const CustomSwitch = ({ disabled, checked }) => {
    return disabled ? (
        <Tooltip title={"Automation can only be edited by creator"} arrow placement="top" ><GreySwitch disabled checked={checked} /></Tooltip>
    ) : (
        <Switch checked={checked} />
    );
};