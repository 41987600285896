import BTThemeProvider from "@components/bt-theme-provider";
import MessageTemplateEditor from "@components/message-template-editor/message-template-editor";
import {
  Close
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, IconButton
} from "@mui/material";
import { closeSnippetDialog } from "@reducers/SnippetsReducer";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useAppDispatch} from "@store/hooks";
import { theme } from "theme";
import { Template } from "../../models/template";
import MessageEditorFooter from "../message-editor/MessageEditorFooter";
import classes from "./Snippet.module.scss";



interface SnippetProps {
  open: boolean;
  edit?: Template;
  onClose: () => void;
}



function Snippet(props: SnippetProps): React.ReactElement {
  const dispatch = useAppDispatch()
  const bottomElRef = useRef<any>(null);

  useEffect(() => {
    bottomElRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleSaveSnippet = async () => {
    dispatch(closeSnippetDialog());
  };

  return (
    <BTThemeProvider>
      <Dialog
        scroll="paper"
        open={props.open}
        onClose={props.onClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            maxWidth: 750,
            backgroundColor: "var(--snippets-background-color)",
          },
        }}
      >
        <div className={classes.title}>
          <DialogTitle>Add Snippet message</DialogTitle>
          <IconButton onClick={props.onClose} sx={{ color: "var(--icon)" }}>
            <Close />
          </IconButton>
        </div>
        <DialogContent>
          <MessageTemplateEditor 
            value={props.edit} onChange={(template => {
            
          })}/>
          <div
            ref={bottomElRef}
            className={classes.actions}
            style={{ marginTop: theme.spacing(1) }}
          >
            <Button
              // onClick={props.onClose}
              className={classes.cancelBtn}
              variant="outlined"
              style={{
                color: "var(--snippet-btn-color)",
                borderColor: "var(--snippet-btn-color)",
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveSnippet}
              className={classes.saveBtn}
              color="primary"
              style={{ marginLeft: theme.spacing(2) }}
            >
              Save Snippet
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <MessageEditorFooter
            confirmBtnTxt="Save Snippet"
            onCancel={props.onClose}
            onConfirm={handleSaveSnippet}
          />
        </DialogActions>
      </Dialog>
    </BTThemeProvider>
  );
}

export default Snippet;
