import { HighlightOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import classes from "./task-subscribers-dialog.module.scss";

import BTInputField from '@components/bt-input-field/bt-input-field';
import { getFilteredUserListForSubscriptionManagement, getTaskSubscribedUsersList } from '@reducers/ExtraDataThunks';
import { useAppSelector } from '@store/hooks';
import { useState } from 'react';
import SubscriptionManagementEntityRow from './subscription-management-entity-row';

type Props = {
    onClose: () => void;
}

export default function ManageSubscribersModal({
    onClose,
}: Props) {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const filteredUserList = useAppSelector((state) => getFilteredUserListForSubscriptionManagement(state, (searchTerm ?? '')))
    const subscribedUsersList = useAppSelector((state) => getTaskSubscribedUsersList(state))



    return (
        <Stack sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 560,
            height: 506,
            bgcolor: 'var(--default-background)',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
        }}>
            <IconButton
                aria-label="close"
                onClick={onClose}
                style={{ position: 'absolute', right: 8, top: 8 }}>
                <HighlightOff />
            </IconButton>
            <h2>Task Subscribers</h2>
            <div style={{
                border: '1px solid var(--border-color)',
                borderRadius: '8px',
                width: '100%',
                maxHeight: '250px',
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '10px',
            }}>
                <BTInputField className={classes.search_input}
                    sx={{ width: '100%' }}
                    type="text"
                    onChange={({ target }: any) => setSearchTerm(target.value)}
                    placeholder="Search board members" />
                {filteredUserList.map((user, index) => {
                    return (
                        <SubscriptionManagementEntityRow user={user} index={index} />

                    )
                })}
                {filteredUserList?.length === 0 &&
                    <Stack sx={{ alignItems: 'center', }}>
                        <p>There are no more user to subscribe to this task</p>
                        <p>Click the 'Share Board' button to invite new board members</p>
                    </Stack>
                }
            </div>
            <div style={{
                width: '100%',
                maxHeight: '250px',
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '10px',
            }}>
                {subscribedUsersList.map((user, index) => {
                    return (
                        <SubscriptionManagementEntityRow user={user} index={index} />
                    )
                })}
            </div>
        </Stack >
    )
}