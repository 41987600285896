import { DBContactsLabelsThunks, DBLabelThunks, labelSelectByQueryOnlyMaxMin } from "@reducers/DBServiceReducers"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "@store/index"
import { labelActions } from ".."

//create new label
export const newLabelThunk = createAsyncThunk<void, undefined, { state: RootState }>
    ('labelSlice/newLabelThunk', async (_, thunkApi) => {
        const dispatch = thunkApi.dispatch
        const state = thunkApi.getState()
        const label = state.LabelReducer.label
        dispatch(labelActions.setLabelNetworkStatus('create-label'))

        const labelMaxOrder =
            labelSelectByQueryOnlyMaxMin(
                state.DBLabelReducer,
                {
                    $or: [{
                        deleted: { $exists: false }
                    }, { deleted: false }],
                }, "order", "max") ?? 0;

        const res = await dispatch(DBLabelThunks.create({
            ...label,
            order: labelMaxOrder + 1,
            type: 'custom'
        }))
        if (res.payload) {
            //handle success part
            dispatch(labelActions.setLabelNetworkStatus('create-label-success'))
            dispatch(labelActions.toggleNewLabel(false))
            //dispatch(labelActions.toggleSortChatToLabelModalOpen(true))
        }

    })

//update custom label only
export const saveSelectedChatsToLabelThunk = createAsyncThunk<void, undefined, { state: RootState }>
    ('labelSlice/saveSelectedChatsToLabelThunk', async (_, thunkApi) => {
        const dispatch = thunkApi.dispatch
        const state = thunkApi.getState()
        const label = state.LabelReducer.label
        dispatch(labelActions.setLabelNetworkStatus('update-label'))
        const res = await dispatch(DBLabelThunks.patch({ entity: label }))
        if (res.payload) {
            //handle success part
            dispatch(labelActions.setLabelNetworkStatus('update-label-success'))
            dispatch(labelActions.toggleEditLabel(false))
            dispatch(labelActions.setLabel({}))
        }
    })
//update custom label only
export const updateLabelThunk = createAsyncThunk<void, undefined, { state: RootState }>('labelSlice/updateLabelThunk', async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch
    const state = thunkApi.getState()
    const label = state.LabelReducer.label
    dispatch(labelActions.setLabelNetworkStatus('update-label'))
    const res = await dispatch(DBLabelThunks.patch({ entity: label }))
    if (res.payload) {
        //handle success part
        dispatch(labelActions.setLabelNetworkStatus('update-label-success'))
        dispatch(labelActions.toggleEditLabel(false))
        dispatch(labelActions.setLabel({}))
    }
})

// delete label
export const deleteLabelThunk = createAsyncThunk<void, undefined, { state: RootState }>('labelSlice/deleteLabelThunk', async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch
    const state = thunkApi.getState()
    const label = state.LabelReducer.label
    dispatch(labelActions.setLabelNetworkStatus('delete-label'))
    const res = await dispatch(DBLabelThunks.delete({ entity: label }))
    if (res.payload) {
        //handle success part
        dispatch(labelActions.setLabelNetworkStatus('delete-label-success'))
        dispatch(labelActions.toggleDeleteLabel(false))
        dispatch(labelActions.setLabel({}))
    }
})



export const loadLabelsAndContactLabelsThunk = createAsyncThunk<void, undefined, { state: RootState }>
    ('labelSlice/fetchContactLabelsThunk', async (owner, thunkApi) => {
        const dispatch = thunkApi.dispatch
        //const state = thunkApi.getState()
        //const activeLabelId = state.LabelReducer.activeLabelId
        await dispatch(DBLabelThunks.find({
            deleted: false, $sort: { order: 1, },
            $paginate: false,
        }))


        await dispatch(DBContactsLabelsThunks.find({
            deleted: false,
            $paginate: false,
        }))

    })




