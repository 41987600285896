import React, { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  BaseTextFieldProps
} from '@mui/material';
import 'react-international-phone/style.css';
import {
  CountryData,
  CountryIso2,
  defaultCountries,
  parseCountry,
  usePhoneInput
} from 'react-international-phone';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { changeDefaultChatCountryCode } from '@reducers/UserReducer';
import { selectUserId } from '@reducers/UserSelectors';
import { useDebounce } from 'react-use';
import ExtensionFlagImage from '@components/extension-flag-image/extension-flag-image';

export interface BtMUIValidatedPhoneProps extends BaseTextFieldProps {
  value: string;
  countryCode: string;
  onChange: (phone: string, valid: boolean) => void;
}

interface ValidationMessage {
  message: string;
  isInputValid: boolean;
}

const getCountryCodeFromBrowserLocale = (locale: string): string | null => {
  const parts = locale.split('-');
  let countryCode = null;
  if (parts.length > 1) {
    countryCode = parts[1].toLowerCase();
  }

  const isExist: boolean = defaultCountries.some((countryData: CountryData) => {
    const country = parseCountry(countryData); // Parsing CountryData to get iso2
    return country.iso2 === countryCode;
  });

  return isExist ? countryCode : 'us';
}

export const BtMUIValidatedPhone: React.FC<BtMUIValidatedPhoneProps> = ({
  value,
  countryCode,
  onChange,
  ...restProps
}) => {
  const userId = useAppSelector(selectUserId);
  const debounceMS: number = 400;
  const digitsFeedbackThreshold: number = 4;
  const invalidPhoneStr: string = "Invalid phone number";
  const flagSize: string = "24px";
  const thinSpaceUnicode: string = "\u2009";
  const phoneUtils = PhoneNumberUtil.getInstance();
  const [debouncedIsValid, setDebouncedIsValid] = useState(true);
  const [debouncedHelperText, setDebouncedHelperText] = useState('');

  // add inputValueAr state 
  const [inputValueAr, setInputValueAr] = useState<string>('+54');

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: countryCode ?? getCountryCodeFromBrowserLocale(navigator.language),
    value,
    countries: defaultCountries,
    onChange: (data) => {

      // if country change to ar, set inputValueAr value +54
      if (country.iso2 === 'ar') {
        // if (inputValueAr not start with +54) set it to +54
        if (inputValueAr === "" || (!inputValueAr.startsWith('+54') && !inputValueAr.startsWith('54') && inputValueAr.length > 3)) {
          setInputValueAr('+54');
        }
      }

      if (country.iso2 !== 'ar') {
        setInputValueAr('+54');
        const { isValid, formattedPhoneNumber } = validatePhoneAndFeedback(data.phone);
        const resPhone = formattedPhoneNumber != null ? formattedPhoneNumber : data.phone;
        onChange(resPhone, isValid);
      }


      dispatch(changeDefaultChatCountryCode({ defaultChatCountryCode: data.country.iso2 }));
    },
  });
  const dispatch = useAppDispatch()

  const handlePhoneValueChangeAr = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    let phone = value.replace(/[^0-9+]/g, '');


    // if phone not start with + add +
    if (!phone.startsWith('+')) {
      phone = `+${phone}`;
    }

    setInputValueAr(phone);
    onChange(phone, true);
  }

  const [debouncedValidationMsg, setDebouncedValidationMsg] = useState<ValidationMessage>({ message: "", isInputValid: true });

  // delay the UI update by debounceMS milliseconds
  useDebounce(
    () => {
      setDebouncedIsValid(debouncedValidationMsg.isInputValid);
      setDebouncedHelperText(debouncedValidationMsg.message);
    },
    debounceMS, // delay
    [debouncedValidationMsg] // Dependencies
  );

  const validatePhoneAndFeedback = (phone: string): { isValid: boolean, formattedPhoneNumber: string } => {
    let isValid: boolean = false;
    let formattedPhoneNumber: string = null;
    let msg: string = "";
    try {
      const parsedPhoneNumber = phoneUtils.parseAndKeepRawInput(phone);
      isValid = phoneUtils.isPossibleNumber(parsedPhoneNumber);
      formattedPhoneNumber = phoneUtils.format(
        parsedPhoneNumber,
        PhoneNumberFormat.E164
      );

      if (isValid) {
        msg = "";
      } else {
        msg = invalidPhoneStr;
      }
    } catch (error) {
      msg = invalidPhoneStr;
      // Keeping formattedPhoneNumber as null because parsing failed
    }

    setDebouncedValidationMsg({
      message: phone.length > digitsFeedbackThreshold ? msg : "",
      isInputValid: isValid
    });

    return { isValid, formattedPhoneNumber };
  };

  const helperTextElement = useMemo(() => {
    if (debouncedHelperText.length > 0) {
      return (
        <span style={{ color: !debouncedIsValid ? 'red' : 'initial' }}>{debouncedHelperText}</span>
      );
    } else {
      return thinSpaceUnicode;
    }
  }, [debouncedHelperText, debouncedIsValid]);

  return (

    country.iso2 !== 'ar' ?
      <TextField
        sx={{
          width: '100%',
          '& div': {
            backgroundColor: 'var(--field-background-color)',
            color: 'var(--primary)'
          },
        }}
        variant="outlined"
        helperText={value.length > 0 ? helperTextElement : "Enter number with country code"}
        color="primary"
        placeholder="Phone number"
        value={inputValue}
        onChange={handlePhoneValueChange}
        type="tel"
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px', color: 'var(--primary)' }}>
              <Select
                MenuProps={{
                  style: {
                    height: '300px',
                    width: '360px',
                    top: '10px',
                    left: '-34px',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                sx={{
                  width: '100%',
                  fieldset: { display: 'none' },
                  '&.Mui-focused:has(div[aria-expanded="false"])': { fieldset: { display: 'block' } },
                  '.MuiSelect-select': { padding: '8px', paddingRight: '24px !important' },
                  svg: { right: 0, color: 'var(--primary)', },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value as CountryIso2)}
                renderValue={(value) => <ExtensionFlagImage iso2={value} style={{ display: 'flex', width: flagSize, height: flagSize }} />}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <ExtensionFlagImage iso2={country.iso2} style={{ marginRight: '8px', width: flagSize, height: flagSize }} />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="gray">+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          ),
        }}
        {...restProps}
      />
      :
      <TextField
        sx={{
          width: '100%',
          '& div': {
            backgroundColor: 'var(--field-background-color)',
            color: 'var(--primary)'
          },
        }}
        variant="outlined"
        helperText={value.length > 0 ? helperTextElement : "Enter number with country code"}
        color="primary"
        placeholder="Phone number"
        value={inputValueAr}
        onChange={handlePhoneValueChangeAr}
        type="tel"
        // inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px', color: 'var(--primary)' }}>
              <Select
                MenuProps={{
                  style: {
                    height: '300px',
                    width: '360px',
                    top: '10px',
                    left: '-34px',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                sx={{
                  width: '100%',
                  fieldset: { display: 'none' },
                  '&.Mui-focused:has(div[aria-expanded="false"])': { fieldset: { display: 'block' } },
                  '.MuiSelect-select': { padding: '8px', paddingRight: '24px !important' },
                  svg: { right: 0, color: 'var(--primary)', },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value as CountryIso2)}
                renderValue={(value) => <ExtensionFlagImage iso2={value} style={{ display: 'flex', width: flagSize, height: flagSize }} />}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <ExtensionFlagImage iso2={country.iso2} style={{ marginRight: '8px', width: flagSize, height: flagSize }} />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="gray">+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          ),
        }}
        {...restProps}
      />


  );
};

export default BtMUIValidatedPhone;
