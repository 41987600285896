import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { NotificationStack } from './notification-row';

type Props = { notificationId: string; }

export default function TaskDragToNewGroupNotification({ notificationId, }: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))
    return (
        <NotificationStack direction='row'>
            {/* <div> */}
            {notification.actionType}&nbsp;
            <b>{notification.actionOnEntityData}</b>{` `}
            &nbsp;to group&nbsp;
            <b>{notification.newValue.groupName}</b>{` `}
            {/* </div> */}
        </NotificationStack>
    )
}