import { Tab, Tabs } from '@mui/material';
import { Stack } from '@mui/system';
import { BulkDashboardTab, setActiveTab } from '@reducers/CampaignReducer';
import { useAnalyticsService } from '@reducers/hooks/use-analytics-service';
import { AnalyticsService } from '@services/analytics-service';
import { getRuntimeUrl } from '@services/storage';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import React from 'react'

const srcBulkManagementAudiencesTabSVG = getRuntimeUrl("/img/bulkManagementAudiencesTabSVG.svg")
const srcBulkManagementTemplatesTabSVG = getRuntimeUrl("/img/bulkManagementTemplatesTabSVG.svg")
const srcBulkManagementCampaignsTabSVG = getRuntimeUrl("/img/bulkManagementCampaignsTabSVG.svg")

export default function BtBulkMangementTabs() {
    const dispatch = useAppDispatch()

    const activeTab = useAppSelector((state) => state.CampaignReducer.activeTab);


    const analyticsService: AnalyticsService = useAnalyticsService();


    const tabsData = [
        {
            label: "Manage Audiences",
            value: "manageAudiences",
            iconSrc: srcBulkManagementAudiencesTabSVG,
            alt: "Manage Audiences"
        },
        {
            label: "Manage Templates",
            value: "manageTemplates",
            iconSrc: srcBulkManagementTemplatesTabSVG,
            alt: "Manage Templates"
        },
        {
            label: "Manage Campaigns",
            value: "manageCampaigns",
            iconSrc: srcBulkManagementCampaignsTabSVG,
            alt: "Manage Campaigns"
        },
    ];

    const handleBulkCampaignTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        analyticsService.event('bulk_dashboard_tab_change', {
            category: 'bulk',
            action: newValue
        });

        dispatch(setActiveTab(newValue as BulkDashboardTab));
    }


    return (
        <Stack direction='row' justifyContent="center">
            <Tabs sx={{
                ".MuiTabs-indicator": {
                    display: 'none',
                },
            }}
                value={activeTab} onChange={handleBulkCampaignTabChange}>
                {tabsData.map((tab) => (
                    <Tab
                        key={tab.value}
                        sx={{
                            '&.Mui-selected': {
                                fontWeight: 'bold !important',
                                opacity: '1 !important',
                            },
                        }}
                        style={{
                            opacity: 0.65,
                            fontWeight: 'normal',
                            color: "var(--campaign-tabs-string)",
                            textTransform: 'none',
                            marginRight: '30px',
                            marginLeft: '30px',
                        }}
                        icon={<img src={tab.iconSrc} alt={tab.alt} />}
                        label={tab.label}
                        value={tab.value}
                    />
                ))}
            </Tabs>
        </Stack>
    )
}