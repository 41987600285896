import { Close } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { accessFormPopupActions, AccessFormPopupStatus } from "@reducers/AccessFormPopupReducer";
import { selectUser } from "@reducers/UserSelectors";
import { useAppSelector } from "@store/hooks";
import React, { Fragment } from "react";
import { AiOutlineLogin } from "react-icons/ai";
import { FaRegBell } from "react-icons/fa";
import { useAppDispatch} from "@store/hooks";
import { User } from "../../models/user";
import { FormState } from "../access-form/access-form";
import classes from "./complete-details-bar.module.scss";


export interface CompleteDetailsBarProps { }

export interface CompleteDetailsBarProps { }

export interface CompleteDetailsBarState {
  showAccessDialog: boolean;
  user?: User | null | undefined;
  completeDetailsClosed: boolean;
  initialAccessFormState?: FormState;
}


export const CompleteDetailsBar = () => {
  const dispatch = useAppDispatch()

  const user = useAppSelector(selectUser);
  const completeDetailsClosed = useAppSelector((state) => state.WhatsAppReducer.completeDetailsClosed);


  const showAccessForm = (accessFormPopupState: AccessFormPopupStatus) => {
    dispatch(accessFormPopupActions.setStatus(accessFormPopupState))
  }

  const forceClose = () => {
    dispatch(accessFormPopupActions.setStatus('Closed'))
  };

  return (
    <>
      {user?.email?.indexOf("@") === -1 &&
        !completeDetailsClosed ? (
        <Fragment>
          <div dir="ltr" className={classes.completeDetailsBar}>
            <IconButton className={classes.BellIconButton}>
              <FaRegBell />
            </IconButton>
            <span>You are currently logged in as a guest</span>
            <div>
              <Button
                className={classes.authButton}
                variant="contained"
                color="primary"
                onClick={() => showAccessForm('Signup')}
              >
                Signup
              </Button>
              <span>to prevent loosing your data</span>
              <Button
                className={classes.authButton}
                variant="outlined"
                color="primary"
                startIcon={<AiOutlineLogin />}
                onClick={() => showAccessForm('Login')}
              >
                Login
              </Button>
              <span>to switch account</span>
            </div>

            <IconButton
              className={classes.closeIcon}
              children={<Close />}
              onClick={forceClose}
            />
          </div>
          <div style={{ margin: "auto" }}></div>
        </Fragment>
      ) : (
        <></>
      )}
    </>
  );
}

export default CompleteDetailsBar;
