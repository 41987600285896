import { ViewFilter } from "@components/tasks-panel/filter-button/advanced_filters";
import { ExtraDataFile, ExtraDataItem } from "@models/ExtraDataItem";
import { Automation, AutomationActions, AutomationConditions, AutomationTrigger } from "@models/automation";
import { Board } from "@models/board";
import { BlueticksBoardUser } from "@models/board-user";
import { BoardView } from "@models/board-views";
import { ContactLabel } from "@models/contact-label";
import { EntitySubscriber } from "@models/entity-subscriber";
import { Feature } from "@models/feature";
import { BlueticksForm } from "@models/form";
import { Gantt } from "@models/gantt";
import { Invoice } from "@models/invoice";
import { Kanban } from "@models/kanban";
import { _Label } from "@models/label";
import { Log } from "@models/logs";
import { MessageLog } from "@models/message-log";
import { MyWork } from "@models/mywork";
import { NewTabFocus } from "@models/new-tab-focus";
import { Notification } from "@models/notification";
import { QuickLaunch } from "@models/quick-launch";
import { RecycledItem } from "@models/recycle-items";
import { Subscription } from "@models/subscription";
import { Task } from "@models/task";
import { TaskColumn } from "@models/task-column";
import { TaskColumnOption } from "@models/task-column-option";
import { TasksGroup } from "@models/task-group";
import { TimeTrackingSession } from "@models/time-tracking-session";
import { UserInterface } from "@models/user";
import { UserMessage } from "@models/user-message";
import { WASession } from "@models/wasession";
import { Webhook } from "@models/webhook";
import { Workload } from "@models/workload";
import { Workspace } from "@models/workspace";
import { BlueticksWorkspaceUser } from "@models/workspace-user";
import {
  ActionReducerMapBuilder,
  AsyncThunk,
  Dictionary,
  EntityId,
  PayloadAction,
  Selector,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { Chat } from "@services/whatsapp";
import { RootState } from "@store/index";
import { WritableDraft } from "immer/dist/internal";
import randomstring from "randomstring";
import sift from "sift";
import { sort } from "sift-sort";
import { BulkList } from "../models/bulk-list";
import { Campaign } from "../models/campaign-model";
import { ContactInfo } from "../models/contact-info";
import { Template } from "../models/template";
import { ThunkAppDispatch } from "../store/hooks";
import {
  NullableId,
  Paginated,
  createEntity,
  createManyEntities,
  deleteEntity,
  findEntity,
  patchEntity,
  updateManyEntities
} from "./backend-api/backend-api";
import { SnippetHooks } from "./hooks/snippet.hook";
export interface DBThunks<T extends BaseEntity> {
  create: AsyncThunk<T, T, any>;
  createMany: AsyncThunk<any[], T[], any>;
  patch: AsyncThunk<T, DBThunkInput<Partial<T>, PatchOptions>, any>;
  patchMany: AsyncThunk<void, Update<T>[], any>;
  delete: AsyncThunk<T, DBThunkInput<Partial<T>, DeleteOptions>, any>;
  find: AsyncThunk<any, any, any>;
  createOptimisticUpdate: AsyncThunk<T, T, any>;
  syncOptimisticUpdate: AsyncThunk<T, T, any>;
  undoOptimisticUpdate: AsyncThunk<string, string, any>;
}

export interface Asset {
  name: string;
  type: string;
  url: string;
}

export interface Update<T extends BaseEntity> {
  id: string;
  changes: Partial<T>;
}

export interface BaseEntity extends Obj {
  _id?: string;
  tempId?: string;
  version?: number;
  dirtySessionId?: string;
  dirty?: boolean;
}

export interface GenericState<T extends BaseEntity> {
  ids: EntityId[];
  entities: Dictionary<T>;
  itemsLoaded: boolean;
  itemsLoading: boolean;
  total: number;
  skip: number;
  limit: number;
}

type Obj = Record<string, any>;

export interface DeleteOptions {
  inCaseOfErrorRollbackOptimisticUpdateDisabled: boolean;
}

export interface PatchOptions {
  disableOptimisticUpdates?: boolean;
}

export interface DBThunkInput<T, OptionsInterface> { entity: T, options?: OptionsInterface }

export const DBserviceAdapter = createEntityAdapter({
  selectId: (item: any) => item?._id ?? item?.tempId ?? null,
});

export interface HookOptions {
  dispatch: ThunkAppDispatch;
}
export interface Hooks {
  create?: (entity: any, options: HookOptions) => Promise<any> | any;
  patch?: (entity: any, options: HookOptions) => Promise<any> | any;
  delete?: (entity: any, options: HookOptions) => Promise<any> | any;
  find?: (entityList: any[], options: HookOptions) => Promise<any[]> | any;
}

export interface DBThunkHooks {
  before?: Hooks;
  after?: Hooks;
}

export type GetStateFn<T> = (state) => GenericState<T>;

export const _DBReducerMap: Map<string, any> = new Map();
export const createDBServiceSlice = <T extends BaseEntity>({
  service = "",
  initialState,
  hooks,
  customReducers,
  getReducerState,
  customThunks = {},
}: {
  service: string;
  initialState: GenericState<T>;
  hooks?: DBThunkHooks;
  getReducerState: GetStateFn<T>;
  customReducers?:
  | ((builder: ActionReducerMapBuilder<GenericState<T>>) => void)
  | undefined;
  customThunks?: { [key: string]: AsyncThunk<any, any, any> };
}) => {
  const selectors = DBserviceAdapter.getSelectors(getReducerState);
  const rootStateSelectors = DBserviceAdapter.getSelectors();

  const shouldEntityUpdate = (
    state: WritableDraft<GenericState<T>>,
    entity: T
  ): boolean => {
    let existingEntity;
    if (entity._id) {
      existingEntity = state.entities[entity._id];
      if (
        !existingEntity ||
        (entity.version ?? 0) > (existingEntity?.version ?? 0)
      ) {
        return true;
      }
    }
    return false;
  };

  const createSliceFactory = <
    Reducers extends SliceCaseReducers<GenericState<T>>
  >(
    reducers: Reducers &
      ValidateSliceCaseReducers<GenericState<T>, Reducers> = {} as Reducers &
      ValidateSliceCaseReducers<GenericState<T>, Reducers>
  ) => {
    return createSlice({
      name: service,
      initialState,
      reducers: {
        addOne: DBserviceAdapter.addOne,
        addMany: DBserviceAdapter.addMany,
        setAll: DBserviceAdapter.setAll,
        removeOne: DBserviceAdapter.removeOne,
        removeMany: DBserviceAdapter.removeMany,
        removeAll: DBserviceAdapter.removeAll,
        updateOne: DBserviceAdapter.updateOne,
        updateMany: DBserviceAdapter.updateMany,
        upsertOne: DBserviceAdapter.upsertOne,
        upsertMany: DBserviceAdapter.upsertMany,
        clearState: (state) => {
          DBserviceAdapter.removeAll(state);
          state.itemsLoaded = false;
        },

        conditionalUpdateOne: (state, action: PayloadAction<T>) => {
          const entity = action.payload;
          if (shouldEntityUpdate(state, entity)) {
            DBserviceAdapter.upsertOne(state, entity);
          }
        },
        conditionalUpdateMany: (state, action: PayloadAction<T[]>) => {
          DBserviceAdapter.updateMany(
            state,
            action.payload
              .filter((entity) => shouldEntityUpdate(state, entity))
              .map((entity) => ({
                id: entity._id!,
                changes: entity,
              }))
          );
        },
        setItemsLoaded: (state, action: PayloadAction<boolean>) => {
          console.log(`[${service}] setItemsLoaded`);
          state.itemsLoaded = action.payload;
        },
        setItemsLoading: (state, action: PayloadAction<boolean>) => {
          state.itemsLoading = action.payload;
        },
        setItemsTotalSkipAndLimit: (state, action: PayloadAction<{ total: number, skip: number, limit: number }>) => {

          const { total, skip, limit } = action.payload;
          state.total = total;
          state.skip = skip;
          state.limit = limit;

        },
        ...customReducers,
      },
      extraReducers: customReducers,
    });
  };
  const slice = createSliceFactory();

  const createDBThunks = <T extends BaseEntity>(
    service: string
  ): DBThunks<T> => {
    return {
      find: createAsyncThunk(
        `${service}/find`,
        async (query: any, thunkAPI) => {
          console.log(`[${service}] find`);
          const dispatch: ThunkAppDispatch =
            thunkAPI.dispatch as ThunkAppDispatch;

          dispatch(slice.actions.setItemsLoading(true));

          const getEntityPromise = dispatch(
            findEntity.initiate({
              service,
              query: { ...query, breakCache: randomstring.generate(16) },
            }))
            .unwrap()
            .then(async (response: Paginated<T> | T[] | undefined) => {
              const data = (response && "data" in response) ? response.data : response;
              const entityList =
                (await hooks?.after?.find?.apply(null, [
                  data ?? [],
                  {
                    dispatch,
                  },
                ])) ?? data ?? [];
              dispatch(slice.actions.addMany(entityList));
              dispatch(slice.actions.updateMany(entityList.map(entity => ({
                id: entity._id,
                changes: entity
              }))));
              dispatch(slice.actions.setItemsLoaded(true));

              if (response && "skip" in response && "limit" in response && "total" in response) {
                const { skip, limit, total } = response;
                dispatch(slice.actions.setItemsTotalSkipAndLimit({ skip, limit, total }));
              }

              dispatch(slice.actions.setItemsLoading(false));

              return entityList;
            })
            .catch((e) => {
              console.error(e);
              dispatch(slice.actions.setItemsLoading(false));
            });
          return getEntityPromise;
        }
      ),
      create: createAsyncThunk(
        `${service}/create2`,
        async (entity: T, thunkAPI) => {
          console.log(`[${service}] Create2`);
          entity.version = 1;
          const dirtySessionId = randomstring.generate(16);
          entity = { ...entity, dirtySessionId, dirty: true };

          const dispatch: ThunkAppDispatch =
            thunkAPI.dispatch as ThunkAppDispatch;

          if (hooks?.before?.create) {
            entity = await hooks?.before?.create(entity, { dispatch });
          }

          dispatch(
            slice.actions.addOne({
              ...entity,
              _id: dirtySessionId,
              tempId: dirtySessionId,
              deleted: false,
            })
          );

          let response;
          try {
            let newEntity = await dispatch(
              createEntity.initiate({
                service,
                entity,
              })
            ).unwrap();

            newEntity = {
              ...newEntity,
              dirtySessionId: undefined,
              dirty: false,
            };

            if (hooks?.after?.create) {
              newEntity = await hooks?.after?.create(newEntity, { dispatch });
            }

            dispatch(
              slice.actions.updateOne({
                id: dirtySessionId,
                changes: newEntity,
              })
            );

            response = newEntity;
          } catch (e) {
            console.error(e);
            dispatch(slice.actions.removeOne(dirtySessionId));

            response = e;
          }
          return response;
        }
      ),
      patch: createAsyncThunk(
        `${service}/patch2`,
        async ({ entity, options }: DBThunkInput<T, PatchOptions>, thunkAPI) => {
          console.log(`[${service}] Patch`);

          const dispatch: ThunkAppDispatch =
            thunkAPI.dispatch as ThunkAppDispatch;

          //const localState = getReducerState(getState());
          const originalEntity = selectors.selectById(
            thunkAPI.getState(),
            entity._id
          );
          if (!originalEntity) {
            console.log(`patch didnt found the entity - entity._id : ${entity._id}  not exists`)
            return;
          }
          if (!originalEntity._id) {
            console.log(`patch originalEntity didnt have _id. originalEntity:`)
            console.log(originalEntity)
            return;
          }

          const dirtySessionId = randomstring.generate(16);
          entity = {
            ...entity,
            dirtySessionId,
            dirty: true,
            version: originalEntity?.version + 1 ?? 0,
          };

          if (hooks?.before?.patch) {
            entity = await hooks?.before?.patch(entity, { dispatch });
          }

          if (!options?.disableOptimisticUpdates) {
            console.log(`Update entity dark mode: ${JSON.stringify(entity?.isDarkMode)}`)
            dispatch(
              slice.actions.updateOne({
                id: entity._id,
                changes: entity,
              })
            );
          }

          let newEntity
          try {
            newEntity = await dispatch(
              patchEntity.initiate({
                service,
                entity,
              })
            ).unwrap();

            newEntity = {
              ...newEntity,
              dirtySessionId: undefined,
              dirty: false,
            };
            if (hooks?.after) {
              newEntity = await hooks?.after?.patch(newEntity, { dispatch });
            }
            if (options?.disableOptimisticUpdates) {
              dispatch(slice.actions.updateOne({
                id: newEntity._id,
                changes: newEntity
              }));
            } else {
              dispatch(slice.actions.conditionalUpdateOne(newEntity));
            }
          } catch (e) {
            dispatch(
              slice.actions.updateOne({
                id: originalEntity._id,
                changes: originalEntity,
              })
            );
            thunkAPI.rejectWithValue(e.data);
            return e.data;
          }
          return newEntity;
        }
      ),
      patchMany: createAsyncThunk(
        `${service} / patchMany`,
        async (updates: Update<T>[], thunkAPI) => {
          console.log(`[${service}] patchMany`);


          if (updates.length === 0) {
            return;
          }

          const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;
          const localState = getReducerState(thunkAPI.getState());

          const ids = updates.map(update => update.id)
          const projection = Object.keys(updates[0]?.changes);

          const originalEntities = selectByQuery(localState,
            {
              _id: { $in: ids }
            },
            projection
          );

          if (originalEntities.length !== ids.length) {
            console.log(`patchMany didnt found the entities - some of them may not exists`)
            return;
          }

          dispatch(slice.actions.updateMany(updates));

          const deletedEntitiesPromise = dispatch(updateManyEntities.initiate(
            { service, entities: updates })).unwrap()
            .then(async (response: any) => {

              const entities = response.map(entity => {
                return {
                  ...entity,
                  dirtySessionId: undefined,
                  dirty: false,
                }
              })
              dispatch(slice.actions.conditionalUpdateMany(entities));
              return entities;
            }).catch((e) => {
              console.error(e);
              const beforeChanges = originalEntities.map(entity => {
                return {
                  id: entity._id,
                  changes: entity
                }
              })
              dispatch(slice.actions.updateMany(beforeChanges));
            });

          return deletedEntitiesPromise;
        }
      ),
      createMany: createAsyncThunk(
        `${service}/createMany`,
        async (entities: T[], thunkAPI) => {
          console.log(`[${service}] CreateMany`);

          const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;

          // Initialize responses array
          let responses = [];

          // Prepare entities with initial modifications and optimistic IDs
          const preparedEntities = entities.map(entity => {
            entity.version = 1;
            const dirtySessionId = randomstring.generate(16);
            return { ...entity, dirtySessionId, dirty: true, tempId: dirtySessionId };
          });

          // Run hooks before create if any
          const entitiesAfterBeforeHook = hooks?.before?.create
            ? await Promise.all(preparedEntities.map(entity => hooks.before.create(entity, { dispatch })))
            : preparedEntities;

          // Optimistically add entities to the store
          entitiesAfterBeforeHook.forEach(entity => {
            dispatch(
              slice.actions.addOne({
                ...entity,
                _id: entity.dirtySessionId,
              })
            );
          });

          try {
            // Attempt to create multiple entities
            const newEntities = await dispatch(
              createManyEntities.initiate({
                service,
                entities: entitiesAfterBeforeHook,
              })
            ).unwrap();

            // Post creation updates or hooks
            const entitiesAfterAfterHook = hooks?.after?.create
              ? await Promise.all(newEntities.map(entity => hooks.after.create(entity, { dispatch })))
              : newEntities;

            // Update store with the new entities data, removing dirty flags
            entitiesAfterAfterHook.forEach((newEntity, index) => {
              dispatch(
                slice.actions.updateOne({
                  id: entitiesAfterBeforeHook[index].dirtySessionId,
                  changes: {
                    ...newEntity,
                    dirtySessionId: undefined,
                    dirty: false,
                  },
                })
              );
              responses.push(newEntity);
            });
          } catch (e) {
            console.error(e);

            // Rollback optimistic updates by removing all entities added
            entitiesAfterBeforeHook.forEach(entity => {
              dispatch(slice.actions.removeOne(entity.dirtySessionId));
            });

            // Push error to responses
            responses.push(e);
          }

          return responses;
        }
      ),


      delete: createAsyncThunk(
        `${service}/delete2`,
        async ({ entity, options }: DBThunkInput<T, DeleteOptions>, thunkAPI) => {
          console.log(`[${service}] delete`);

          const dispatch: ThunkAppDispatch =
            thunkAPI.dispatch as ThunkAppDispatch;

          const originalEntity = selectors.selectById(
            thunkAPI.getState(),
            entity._id
          );
          const dirtySessionId = randomstring.generate(16);
          entity = {
            _id: entity._id,
            dirtySessionId,
            dirty: true,
            deleted: true,
            version: originalEntity?.version + 1 ?? 0,
          } as any;

          if (hooks?.before?.delete) {
            entity = await hooks?.before?.delete(entity, { dispatch });
          }

          dispatch(slice.actions.removeOne(entity._id));

          const deletedEntityPromise = dispatch(
            deleteEntity.initiate({
              service,
              entity,
            })
          ).unwrap();
          deletedEntityPromise
            .then(async (newEntity: T) => {
              newEntity = {
                ...newEntity,
                dirtySessionId: undefined,
                dirty: false,
              };
              if (hooks?.after?.delete) {
                newEntity = await hooks?.after?.delete(newEntity, { dispatch });
              }
              dispatch(slice.actions.removeOne(newEntity._id));
            })
            .catch((e) => {
              // When an entity is not found, it means it was already removed so don't return it to the entity map

              console.log(entity);
              if (!(e?.data?.name === "NotFound" || options?.inCaseOfErrorRollbackOptimisticUpdateDisabled)) {
                dispatch(slice.actions.addOne(originalEntity));
              }
              console.error(e);
            });

          return deletedEntityPromise;
        }
      ),
      createOptimisticUpdate: createAsyncThunk(
        `${service}/createOptimisticUpdate`,
        async (entity: T, thunkAPI) => {
          console.log(`[${service}] makeOptimisticUpdate`);

          const dirtySessionId = randomstring.generate(16);
          entity = { ...entity, dirtySessionId, dirty: true };

          const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;
          const dirtyEntity = {
            ...entity,
            _id: dirtySessionId,
            tempId: dirtySessionId,
          };

          if (entity._id) {
            dispatch(slice.actions.updateOne({
              id: entity._id,
              changes: dirtyEntity
            }));
          } else {
            dispatch(slice.actions.addOne({ dirtyEntity }));
          }
          return entity;
        }
      ),
      syncOptimisticUpdate: createAsyncThunk(
        `${service}/syncOptimisticUpdate`,
        async (newEntity: T, thunkAPI) => {
          console.log(`[${service}] syncOptimisticUpdate`);
          const dirtySessionId = newEntity.dirtySessionId
          newEntity = {
            ...newEntity,
            dirtySessionId: undefined,
            dirty: false,
          };

          const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;

          // Remove the dirty entity using its dirtySessionId
          dispatch(slice.actions.removeOne(dirtySessionId));

          // Insert the newEntity using its actual _id
          dispatch(slice.actions.addOne(newEntity));
          return newEntity;
        }
      ),
      undoOptimisticUpdate: createAsyncThunk(
        `${service}/undoOptimisticUpdate`,
        async (dirtySessionId: string, thunkAPI) => {
          console.log(`[${service}] undoOptimisticUpdate`);
          const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;

          dispatch(slice.actions.removeOne(dirtySessionId));
          return dirtySessionId;
        }
      ),

      ...customThunks,
    };
  };

  const dbThunks: DBThunks<T> = createDBThunks<T>(service);

  type DBReducerState = GenericState<T>;

  const stateSelector: Selector<DBReducerState, DBReducerState> = (
    state: DBReducerState
  ) => state;



  const getFilterObj: Selector = (_: DBReducerState, filterObj: string) => {
    return filterObj
  };

  const getProjection: Selector = (_: DBReducerState, __: string, projectionArray: string[]) => {
    return projectionArray
  };

  const getProjectionField: Selector = (_: DBReducerState, __: string, projectionField: string) => {
    return projectionField
  };

  const getSortObj: Selector = (_: DBReducerState, __: string, ___: string, sortObj: any) => {
    return sortObj
  };

  const selectByQuery = createSelector<
    [Selector<DBReducerState, DBReducerState>, typeof getFilterObj, typeof getProjection, typeof getSortObj],
    T[]
  >([stateSelector, getFilterObj, getProjection, getSortObj], (state: DBReducerState, filterObj: any, projectionArray: string[], sortObj: any = {}): T[] => {
    if (!filterObj || Object.keys(filterObj).length === 0) {
      //console.log(`filterObj param ${service} is empty`);
      return rootStateSelectors.selectAll(state);
    }
    if (!state) {
      //console.log(`state param ${service} is undefined`);
      return [];
    }
    if (!state.ids || state.ids.length === 0) {
      //console.log(`state.ids ${service} is empty`);
      return [];
    }

    let resultsArray = [];

    const siftFunction = sift(filterObj);
    resultsArray = (state.ids ?? [])
      .filter(id => siftFunction(state.entities[id]))
      .map(id => state.entities[id]) as T[];

    if (Object.keys(sortObj).length !== 0) {
      resultsArray = sort(resultsArray, sortObj);
    }

    if (Array.isArray(projectionArray) && projectionArray.length > 0) {
      resultsArray = resultsArray.map((entity: T | {}) => {
        entity = projectionArray.reduce((a, e) => { a[e] = entity[e]; return a; }, {})
        return entity;
      }) as T[];
    }

    return resultsArray;

  });

  const selectByQueryOnlyLength = createSelector<
    [Selector<DBReducerState, DBReducerState>, typeof getFilterObj],
    number
  >([stateSelector, getFilterObj], (state: DBReducerState, filterObj: any): number => {

    return selectByQuery(state, filterObj).length

  });

  const selectOneObjectById = createSelector<[Selector<DBReducerState, DBReducerState>, typeof getFilterObj], T>
    ([stateSelector, getFilterObj], (state: DBReducerState, id: string): T => {

      const object = rootStateSelectors.selectById(state, id);
      return !object?.deleted ? object : null;

    });

  const selectOneFieldById = createSelector<[Selector<DBReducerState, DBReducerState>, typeof getFilterObj, typeof getProjectionField], any>
    ([stateSelector, getFilterObj, getProjectionField], (state: DBReducerState, id: string, projectionField: string): any => {

      if (!projectionField) {
        return null;
      }

      const onlyOne = selectOneObjectById(state, id);

      if (!onlyOne) {
        return null;
      }

      if (onlyOne.hasOwnProperty(projectionField)) {
        return onlyOne[projectionField];
      }

      return null;

    });


  const selectOneObjectByQuery = createSelector<[Selector<DBReducerState, DBReducerState>, typeof getFilterObj, typeof getProjection, typeof getSortObj], T>
    ([stateSelector, getFilterObj, getProjection, getSortObj], (state: DBReducerState, filterObj: any, projectionArray: string[], sortObj: any = {}): T => {

      let queryResult = selectByQuery(state, filterObj, projectionArray, sortObj);

      if (queryResult.length >= 1) {
        const onlyOne: T = queryResult[0];
        return onlyOne;

      }

      return null;

    });


  const selectOneFieldByQuery = createSelector<[Selector<DBReducerState, DBReducerState>, typeof getFilterObj, typeof getProjectionField, typeof getSortObj], any>
    ([stateSelector, getFilterObj, getProjectionField, getSortObj], (state: DBReducerState, filterObj: any, projectionField: string, sortObj: any = {}): any => {

      if (!projectionField) {
        return null;
      }

      const onlyOne = selectOneObjectByQuery(state, filterObj, [projectionField], sortObj);

      if (!onlyOne) {
        return null;
      }

      if (onlyOne.hasOwnProperty(projectionField)) {
        return onlyOne[projectionField];
      }

      return null;

    });


  const getField: Selector = (_: DBReducerState, __: string, projection: string) => {
    return projection
  };

  const getOper: Selector = (_: DBReducerState, __: string, ___: string, sortObj: string) => {
    return sortObj
  };

  const selectByQueryOnlyMaxMin =
    createSelector<[Selector<DBReducerState, DBReducerState>, typeof getFilterObj, typeof getField, typeof getOper], number>
      ([stateSelector, getFilterObj, getField, getOper],
        (state: DBReducerState, filterObj: any, field: string, oper: string): number => {

          field = "order";
          oper = "max";

          const array = selectByQuery(state, filterObj)

          if (array.length === 0)
            return null;

          const result = window.Math[oper](...array.map(o => o[field]))

          return result

        });

  // create selectPaginationStatus selector that returns the pagination status of the current slice
  const selectPaginationStatus = createSelector<
    [Selector<DBReducerState, DBReducerState>],
    { itemsLoaded: boolean, itemsLoading: boolean, total: number, skip: number, limit: number }
  >([stateSelector], (state: DBReducerState): { itemsLoaded: boolean, itemsLoading: boolean, total: number, skip: number, limit: number } => {
    return {
      itemsLoaded: state.itemsLoaded,
      itemsLoading: state.itemsLoading,
      total: state.total,
      skip: state.skip,
      limit: state.limit
    }
  }
  );



  const dbReducer = {
    slice,
    dbThunks,
    selectByQuery,
    selectors,
    selectByQueryOnlyLength,
    selectByQueryOnlyMaxMin,
    selectOneObjectByQuery,
    selectOneFieldByQuery,
    selectPaginationStatus,
    selectOneObjectById,
    selectOneFieldById,
    getReducerState
  };
  _DBReducerMap.set(service, dbReducer);

  return dbReducer;
};

// DB Reducers

// Lists Reducer
export const {
  slice: BulkListSlice,
  dbThunks: _bulkListThunks,
  selectByQuery: _bulkListSelectByQuery,
  selectOneObjectByQuery: _bulkListSelectOneObjectByQuery,
  selectPaginationStatus: _bulkListSelectPaginationStatus,
} = createDBServiceSlice<BulkList>({
  service: "lists",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBBulkListReducer,
});
export const DBBulkListReducer = BulkListSlice.reducer;
export const bulkListReducerActions = BulkListSlice.actions;
export type BulkListReducerActions = keyof typeof bulkListReducerActions;
export const DBBulkListThunks = _bulkListThunks;
export const bulkListSelectByQuery = _bulkListSelectByQuery;
export const BulkListSelectPaginationStatus = _bulkListSelectPaginationStatus;

// Contact Info Reducer
export const {
  slice: ContactSlice,
  dbThunks: _contactThunks,
  selectByQuery: _contactSelectByQuery,
  selectOneObjectByQuery: _contactSelectOneObjectByQuery,
} = createDBServiceSlice<ContactInfo>({
  service: "contacts",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBContactReducer,
});
export const DBContactReducer = ContactSlice.reducer;
export const contactReducerActions = ContactSlice.actions;
export type ContactReducerActions = keyof typeof contactReducerActions;
export const DBContactThunks = _contactThunks;
export const contactSelectByQuery = _contactSelectByQuery;

// Template Reducer
export const {
  slice: TemplateSlice,
  dbThunks: _templateThunks,
  selectByQuery: _templateSelectByQuery,
  selectOneObjectByQuery: _templateSelectOneObjectByQuery,
  selectors: _templatesSelectors,
} = createDBServiceSlice<Template>({
  service: "templates",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTemplateReducer,
  hooks: SnippetHooks
});
export const DBTemplateReducer = TemplateSlice.reducer;
export const templateReducerActions = TemplateSlice.actions;
export type TemplateReducerActions = keyof typeof templateReducerActions;
export const DBTemplateThunks = _templateThunks;
export const templateSelectByQuery = _templateSelectByQuery;
export const templateSelectors = _templatesSelectors;
export const templateSelectOneObjectByQuery = _templateSelectOneObjectByQuery;


// Campaigns Reducer
export const {
  slice: CampaignSlice,
  dbThunks: _campaignThunks,
  selectByQuery: _campaignsSelectByQuery,
  selectOneObjectByQuery: _campaignsSelectOneObjectByQuery,
  selectors: _campaignSelectors,
} = createDBServiceSlice<Campaign>({
  service: "campaigns",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBCampaignReducer,
  // customReducers: (builder) => {
  //   builder.addMatcher(
  //     backendApi.endpoints.startCampaign.matchFulfilled,
  //     (state, { payload }) => {
  //       if (payload.campaignMessages?.length > 0) {
  //       }
  //     }
  //   );
  // },
});
export const DBCampaignReducer = CampaignSlice.reducer;
export const campaignReducerActions = CampaignSlice.actions;
export type CampaignReducerActions = keyof typeof campaignReducerActions;
export const DBCampaignThunks = _campaignThunks;
export const campaignSelectors = _campaignSelectors;
export const campaignsSelectByQuery = _campaignsSelectByQuery;
export const campaignsSelectOneObjectByQuery = _campaignsSelectOneObjectByQuery;


// Message Log Reducer
export const {
  slice: MessageLogSlice,
  dbThunks: _messageLogThunks,
  selectByQuery: _messageLogSelectByQuery,
  selectOneObjectByQuery: _messageLogSelectOneObjectByQuery,
} =
  createDBServiceSlice<MessageLog>({
    service: "message-logs",
    initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
    getReducerState: (state) => state.DBMessageLogReducer,
    //hooks: UserMessageHooks,
  });
export const DBMessageLogReducer = MessageLogSlice.reducer;
export const messageLogReducerActions = MessageLogSlice.actions;
export type MessageLogReducerActions = keyof typeof messageLogReducerActions;
export const DBMessageLogThunks = _messageLogThunks;
export const messageLogSelectByQuery = _messageLogSelectByQuery;
export const messageLogSelectOneObjectByQuery = _messageLogSelectOneObjectByQuery;


// Taskgroups Reducer
export const {
  slice: TaskgroupsSlice,
  dbThunks: _taskgroupsThunks,
  selectByQuery: _taskGroupsSelectByQuery,
  selectOneObjectByQuery: _taskGroupsSelectOneObjectByQuery,
  selectOneFieldByQuery: _taskGroupsSelectOneFieldByQuery,
  selectByQueryOnlyLength: _taskGroupsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _taskGroupsSelectByQueryOnlyMaxMin,
  selectOneObjectById: _taskGroupsSelectOneObjectById,
  selectOneFieldById: _taskGroupsSelectOneFieldById,
  selectors: _taskgroupsSelectors,
} = createDBServiceSlice<TasksGroup>({
  service: "task-groups",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTaskgroupsReducer,
});
export const DBTaskgroupsReducer = TaskgroupsSlice.reducer;

export const taskgroupsReducerActions = TaskgroupsSlice.actions;
export type TaskgroupsReducerActions = keyof typeof taskgroupsReducerActions;
export const DBTaskgroupsThunks = _taskgroupsThunks;
export const taskgroupsSelectors = _taskgroupsSelectors;
export const taskGroupsSelectByQuery = _taskGroupsSelectByQuery;
export const taskGroupsSelectOneFieldByQuery = _taskGroupsSelectOneFieldByQuery;
export const taskGroupsSelectOneObjectByQuery = _taskGroupsSelectOneObjectByQuery;
export const taskGroupsSelectByQueryOnlyLength = _taskGroupsSelectByQueryOnlyLength;
export const taskGroupsSelectByQueryOnlyMaxMin = _taskGroupsSelectByQueryOnlyMaxMin;
export const taskGroupsSelectOneObjectById = _taskGroupsSelectOneObjectById;
export const taskGroupsSelectOneFieldById = _taskGroupsSelectOneFieldById;


// Boards Reducer
export const {
  slice: BoardsSlice,
  dbThunks: _BoardsThunks,
  selectByQuery: _boardsSelectByQuery,
  selectOneObjectByQuery: _boardsSelectOneObjectByQuery,
  selectOneFieldByQuery: _boardsSelectOneFieldByQuery,
  selectByQueryOnlyLength: _boardsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _boardsSelectByQueryOnlyMaxMin,
  selectPaginationStatus: _boardsSelectPaginationStatus,
  selectOneObjectById: _boardsSelectOneObjectById,
  selectOneFieldById: _boardsSelectOneFieldById,
  selectors: _boardSelectors,
} = createDBServiceSlice<Board>({
  service: "boards",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBBoardsReducer,
});
export const DBBoardsReducer = BoardsSlice.reducer;
export const boardsReducerActions = BoardsSlice.actions;
export type BoardsReducerActions = keyof typeof boardsReducerActions;
export const DBBoardsThunks = _BoardsThunks;
export const boardsSelectors = _boardSelectors;
export const boardsSelectByQuery = _boardsSelectByQuery;
export const boardsSelectOneObjectByQuery = _boardsSelectOneObjectByQuery;
export const boardsSelectOneFieldByQuery = _boardsSelectOneFieldByQuery;
export const boardsSelectByQueryOnlyLength = _boardsSelectByQueryOnlyLength;
export const boardsSelectByQueryOnlyMaxMin = _boardsSelectByQueryOnlyMaxMin;
export const BoardsSelectPaginationStatus = _boardsSelectPaginationStatus;
export const boardsSelectOneObjectById = _boardsSelectOneObjectById;
export const boardsSelectOneFieldById = _boardsSelectOneFieldById;


// Tasks Reducer
export const {
  slice: TasksSlice,
  dbThunks: _TasksThunks,
  selectByQuery: _tasksSelectByQuery,
  selectOneObjectByQuery: _tasksSelectOneObjectByQuery,
  selectOneFieldByQuery: _tasksSelectOneFieldByQuery,
  selectByQueryOnlyLength: _tasksSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _tasksSelectByQueryOnlyMaxMin,
  selectOneObjectById: _tasksSelectOneObjectById,
  selectOneFieldById: _tasksSelectOneFieldById,
  selectors: _tasksSelectors,
} = createDBServiceSlice<Task>({
  service: "tasks",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTasksReducer,
});
export const DBTasksReducer = TasksSlice.reducer;
export const tasksReducerActions = TasksSlice.actions;
export type TasksReducerActions = keyof typeof tasksReducerActions;
export const DBTasksThunks = _TasksThunks;
export const tasksSelectors = _tasksSelectors;
export const tasksSelectByQuery = _tasksSelectByQuery;
export const tasksSelectOneFieldByQuery = _tasksSelectOneFieldByQuery;
export const tasksSelectOneObjectByQuery = _tasksSelectOneObjectByQuery;
export const tasksSelectByQueryOnlyLength = _tasksSelectByQueryOnlyLength;
export const tasksSelectByQueryOnlyMaxMin = _tasksSelectByQueryOnlyMaxMin;
export const tasksSelectOneObjectById = _tasksSelectOneObjectById;
export const tasksSelectOneFieldById = _tasksSelectOneFieldById;


// ExtraData Reducer
export const {
  slice: TasksExtraDataSlice,
  dbThunks: _TasksExtraDataThunks,
  selectByQuery: _tasksExtraDataSelectByQuery,
  selectOneObjectByQuery: _tasksExtraDataSelectOneObjectByQuery,
  selectOneFieldByQuery: _tasksExtraDataSelectOneFieldByQuery,
  selectors: _tasksExtraDataSelectors,
} = createDBServiceSlice<ExtraDataItem>({
  service: "tasks-extra-data",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTasksExtraDataReducer,
});
export const DBTasksExtraDataReducer = TasksExtraDataSlice.reducer;
export const TasksExtraDataReducerActions = TasksExtraDataSlice.actions;
export type tasksExtraDataReducerActions = keyof typeof TasksExtraDataReducerActions;
export const DBTasksExtraDataThunks = _TasksExtraDataThunks;
export const tasksExtraDataSelectors = _tasksExtraDataSelectors;
export const tasksExtraDataSelectByQuery = _tasksExtraDataSelectByQuery;


// TasksExtraDataFiles Reducer
export const {
  slice: TasksExtraDataFilesSlice,
  dbThunks: _TasksExtraDataFilesThunks,
  selectByQuery: _tasksExtraDataFilesSelectByQuery,
  selectOneObjectByQuery: _tasksExtraDataFilesSelectOneObjectByQuery,
  selectOneFieldByQuery: _tasksExtraDataFilesSelectOneFieldByQuery,
  selectors: _tasksExtraDataFilesSelectors,
} = createDBServiceSlice<ExtraDataFile>({
  service: "tasks-extra-data-files",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTasksExtraDataFilesReducer,
});

export const DBTasksExtraDataFilesReducer = TasksExtraDataFilesSlice.reducer;
export const TasksExtraDataFilesReducerActions = TasksExtraDataFilesSlice.actions;
export type tasksExtraDataFilesReducerActions = keyof typeof TasksExtraDataFilesReducerActions;
export const DBTasksExtraDataFilesThunks = _TasksExtraDataFilesThunks;
export const tasksExtraDataFilesSelectors = _tasksExtraDataFilesSelectors;
export const tasksExtraDataFilesSelectByQuery = _tasksExtraDataFilesSelectByQuery;

// Label Reducer
export const {
  slice: LabelSlice,
  dbThunks: _LabelThunks,
  selectByQuery: _labelSelectByQuery,
  selectOneObjectByQuery: _labelSelectOneObjectByQuery,
  selectOneFieldByQuery: _labelSelectSelectOneFieldByQuery,
  selectors: _labelSelectors,
  selectByQueryOnlyMaxMin: _selectByQueryOnlyMaxMin
} = createDBServiceSlice<_Label>({
  service: "labels",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBLabelReducer,
});

export const DBLabelReducer = LabelSlice.reducer;
export const LabelReducerActions = LabelSlice.actions;
export type labelReducerActions = keyof typeof LabelReducerActions;
export const DBLabelThunks = _LabelThunks;
export const labelSelectors = _labelSelectors;
export const labelSelectByQuery = _labelSelectByQuery;
export const labelSelectByQueryOnlyMaxMin = _selectByQueryOnlyMaxMin;
export const labelSelectOneObjectByQuery = _labelSelectOneObjectByQuery;


// conatct Label Reducer
// ContactsLabels Reducer
export const {
  slice: ContactsLabelsSlice,
  dbThunks: _ContactsLabelsThunks,
  selectByQuery: _contactsLabelsSelectByQuery,
  selectOneObjectByQuery: _contactsLabelsSelectOneObjectByQuery,
  selectOneFieldByQuery: _contactsLabelsSelectOneFieldByQuery,
  selectors: _contactsLabelsSelectors,
  selectByQueryOnlyLength: _contactsLabelssSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _contactsLabelssSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<ContactLabel>({
  service: "contacts-labels",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBContactsLabelsReducer,
});
export const DBContactsLabelsReducer = ContactsLabelsSlice.reducer;
export const ContactsLabelsReducerActions = ContactsLabelsSlice.actions;
export type contactsLabelsReducerActions = keyof typeof ContactsLabelsReducerActions;
export const DBContactsLabelsThunks = _ContactsLabelsThunks;
export const contactsLabelsSelectors = _contactsLabelsSelectors;
export const contactsLabelsSelectByQuery = _contactsLabelsSelectByQuery;
export const contactsLabelsSelectOneObjectByQuery = _contactsLabelsSelectOneObjectByQuery;
export const contactsLabelsSelectOneFieldByQuery = _contactsLabelsSelectOneFieldByQuery;
export const contactsLabelssSelectByQueryOnlyLength = _contactsLabelssSelectByQueryOnlyLength;
export const contactsLabelssSelectByQueryOnlyMaxMin = _contactsLabelssSelectByQueryOnlyMaxMin;


// Invoice Reducer
export const {
  slice: InvoiceSlice,
  dbThunks: _InvoiceThunks,
  selectByQuery: _invoiceSelectByQuery,
  selectOneObjectByQuery: _invoiceSelectOneObjectByQuery,
  selectOneFieldByQuery: _invoiceSelectOneFieldByQuery,
  selectors: _invoiceSelectors,
} = createDBServiceSlice<Invoice>({
  service: "invoices",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBInvoiceReducer,
});
export const DBInvoiceReducer = InvoiceSlice.reducer;
export const InvoiceReducerActions = InvoiceSlice.actions;
export type invoiceReducerActions = keyof typeof InvoiceReducerActions;
export const DBInvoiceThunks = _InvoiceThunks;
export const invoiceSelectors = _invoiceSelectors;
export const invoiceSelectByQuery = _invoiceSelectByQuery;

// TaskColumn Reducer
export const {
  slice: TaskColumnSlice,
  dbThunks: _TaskColumnThunks,
  selectByQuery: _taskColumnSelectByQuery,
  selectOneObjectByQuery: _taskColumnSelectOneObjectByQuery,
  selectOneFieldByQuery: _taskColumnSelectOneFieldByQuery,
  selectors: _taskColumnSelectors,
  selectByQueryOnlyLength: _taskColumnsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _taskColumnsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<TaskColumn>({
  service: "task-columns",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTaskColumnReducer,
});
export const DBTaskColumnReducer = TaskColumnSlice.reducer;
export const TaskColumnReducerActions = TaskColumnSlice.actions;
export type taskColumnReducerActions = keyof typeof TaskColumnReducerActions;
export const DBTaskColumnThunks = _TaskColumnThunks;
export const taskColumnSelectors = _taskColumnSelectors;
export const taskColumnSelectByQuery = _taskColumnSelectByQuery;
export const taskColumnSelectOneObjectByQuery = _taskColumnSelectOneObjectByQuery;
export const taskColumnSelectOneFieldByQuery = _taskColumnSelectOneFieldByQuery;
export const taskColumnsSelectByQueryOnlyLength = _taskColumnsSelectByQueryOnlyLength;
export const taskColumnsSelectByQueryOnlyMaxMin = _taskColumnsSelectByQueryOnlyMaxMin;

// Workspace Reducer
export const {
  slice: WorkspaceSlice,
  dbThunks: _WorkspaceThunks,
  selectByQuery: _workspaceSelectByQuery,
  selectOneObjectByQuery: _workspaceSelectOneObjectByQuery,
  selectOneFieldByQuery: _workspaceSelectOneFieldByQuery,
  selectors: _workspaceSelectors,
  selectByQueryOnlyLength: _workspacesSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _workspacesSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Workspace>({
  service: "workspaces",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBWorkspaceReducer,
});
export const DBWorkspaceReducer = WorkspaceSlice.reducer;
export const WorkspaceReducerActions = WorkspaceSlice.actions;
export type workspaceReducerActions = keyof typeof WorkspaceReducerActions;
export const DBWorkspaceThunks = _WorkspaceThunks;
export const workspaceSelectors = _workspaceSelectors;
export const workspaceSelectByQuery = _workspaceSelectByQuery;
export const workspaceSelectOneObjectByQuery = _workspaceSelectOneObjectByQuery
export const workspacesSelectByQueryOnlyLength = _workspacesSelectByQueryOnlyLength;
export const workspacesSelectByQueryOnlyMaxMin = _workspacesSelectByQueryOnlyMaxMin;

export function isTempId(id: NullableId) {
  if (id?.toString().length === 16) return true;
}

// TaskColumnOption Reducer
export const {
  slice: TaskColumnOptionSlice,
  dbThunks: _TaskColumnOptionThunks,
  selectByQuery: _taskColumnOptionSelectByQuery,
  selectOneObjectByQuery: _taskColumnOptionSelectOneObjectByQuery,
  selectors: _taskColumnOptionSelectors,
  selectByQueryOnlyLength: _taskColumnOptionSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _taskColumnOptionSelectByQueryOnlyMaxMin,
  selectOneObjectById: _taskColumnOptionSelectOneObjectById,
  selectOneFieldById: _taskColumnOptionSelectOneFieldById,
} = createDBServiceSlice<TaskColumnOption>({
  service: "task-column-options",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTaskColumnOptionReducer,
});
export const DBTaskColumnOptionReducer = TaskColumnOptionSlice.reducer;
export const TaskColumnOptionReducerActions = TaskColumnOptionSlice.actions;
export type taskColumnOptionReducerActions = keyof typeof TaskColumnOptionReducerActions;
export const DBTaskColumnOptionThunks = _TaskColumnOptionThunks;
export const taskColumnOptionSelectors = _taskColumnOptionSelectors;
export const taskColumnOptionSelectByQuery = _taskColumnOptionSelectByQuery;
export const taskColumnOptionSelectByQueryOnlyLength = _taskColumnOptionSelectByQueryOnlyLength;
export const taskColumnOptionSelectByQueryOnlyMaxMin = _taskColumnOptionSelectByQueryOnlyMaxMin;
export const taskColumnOptionSelectOneObjectById = _taskColumnOptionSelectOneObjectById;
export const taskColumnOptionSelectOneFieldById = _taskColumnOptionSelectOneFieldById;

// Users Reducer
export const {
  slice: UserSlice,
  dbThunks: _UserThunks,
  selectByQuery: _userSelectByQuery,
  selectOneObjectByQuery: _userSelectOneObjectByQuery,
  selectors: _userSelectors,
  selectByQueryOnlyLength: _userSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _userSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<UserInterface>({
  service: "users",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBUserReducer,
});
export const DBUserReducer = UserSlice.reducer;
export const UserReducerActions = UserSlice.actions;
export type userReducerActions = keyof typeof UserReducerActions;
export const DBUserThunks = _UserThunks;
export const userSelectors = _userSelectors;
export const userSelectByQuery = _userSelectByQuery;
export const userSelectByQueryOnlyLength = _userSelectByQueryOnlyLength;
export const userSelectByQueryOnlyMaxMin = _userSelectByQueryOnlyMaxMin;
export const userSelectOneObjectByQuery = _userSelectOneObjectByQuery;

// Kanbans Reducer
export const {
  slice: KanbanSlice,
  dbThunks: _KanbanThunks,
  selectByQuery: _kanbanSelectByQuery,
  selectOneObjectByQuery: _kanbanSelectOneObjectByQuery,
  selectOneFieldByQuery: _kanbanSelectOneFieldByQuery,
  selectors: _kanbanSelectors,
  selectByQueryOnlyLength: _kanbanSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _kanbanSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Kanban>({
  service: "kanbans",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBKanbanReducer,
});
export const DBKanbanReducer = KanbanSlice.reducer;
export const KanbanReducerActions = KanbanSlice.actions;
export type kanbanReducerActions = keyof typeof KanbanReducerActions;
export const DBKanbanThunks = _KanbanThunks;
export const kanbanSelectors = _kanbanSelectors;
export const kanbanSelectByQuery = _kanbanSelectByQuery;
export const kanbanSelectByQueryOnlyLength = _kanbanSelectByQueryOnlyLength;
export const kanbanSelectByQueryOnlyMaxMin = _kanbanSelectByQueryOnlyMaxMin;
export const kanbanSelectOneObjectByQuery = _kanbanSelectOneObjectByQuery;
export const kanbanSelectOneFieldByQuery = _kanbanSelectOneFieldByQuery;


// Workloads Reducer
export const {
  slice: WorkloadSlice,
  dbThunks: _WorkloadThunks,
  selectByQuery: _workloadSelectByQuery,
  selectOneObjectByQuery: _workloadSelectOneObjectByQuery,
  selectOneFieldByQuery: _workloadSelectOneFieldByQuery,
  selectors: _workloadSelectors,
  selectByQueryOnlyLength: _workloadSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _workloadSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Workload>({
  service: "workloads",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBWorkloadReducer,
});
export const DBWorkloadReducer = WorkloadSlice.reducer;
export const WorkloadReducerActions = WorkloadSlice.actions;
export type workloadReducerActions = keyof typeof WorkloadReducerActions;
export const DBWorkloadThunks = _WorkloadThunks;
export const workloadSelectors = _workloadSelectors;
export const workloadSelectByQuery = _workloadSelectByQuery;
export const workloadSelectByQueryOnlyLength = _workloadSelectByQueryOnlyLength;
export const workloadSelectByQueryOnlyMaxMin = _workloadSelectByQueryOnlyMaxMin;
export const workloadSelectOneObjectByQuery = _workloadSelectOneObjectByQuery;
export const workloadSelectOneFieldByQuery = _workloadSelectOneFieldByQuery;



// Forms Reducer
export const {
  slice: FormSlice,
  dbThunks: _FormThunks,
  selectByQuery: _formSelectByQuery,
  selectOneObjectByQuery: _formSelectOneObjectByQuery,
  selectors: _formSelectors,
  selectByQueryOnlyLength: _formSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _formSelectByQueryOnlyMaxMin,
  selectOneFieldByQuery: _formSelectOneFieldByQuery,
} = createDBServiceSlice<BlueticksForm>({
  service: "forms",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBFormReducer,
});
export const DBFormReducer = FormSlice.reducer;
export const FormReducerActions = FormSlice.actions;
export type formReducerActions = keyof typeof FormReducerActions;
export const DBFormThunks = _FormThunks;
export const formSelectors = _formSelectors;
export const formSelectByQuery = _formSelectByQuery;
export const formSelectByQueryOnlyLength = _formSelectByQueryOnlyLength;
export const formSelectByQueryOnlyMaxMin = _formSelectByQueryOnlyMaxMin;
export const formSelectOneObjectByQuery = _formSelectOneObjectByQuery;
export const formSelectOneFieldByQuery = _formSelectOneFieldByQuery;

// Gantts Reducer
export const {
  slice: GanttSlice,
  dbThunks: _GanttThunks,
  selectByQuery: _ganttSelectByQuery,
  selectOneObjectByQuery: _ganttSelectOneObjectByQuery,
  selectOneFieldByQuery: _ganttSelectOneFieldByQuery,
  selectors: _ganttSelectors,
  selectByQueryOnlyLength: _ganttSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _ganttSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Gantt>({
  service: "gantts",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBGanttReducer,
});
export const DBGanttReducer = GanttSlice.reducer;
export const GanttReducerActions = GanttSlice.actions;
export type ganttReducerActions = keyof typeof GanttReducerActions;
export const DBGanttThunks = _GanttThunks;
export const ganttSelectors = _ganttSelectors;
export const ganttSelectByQuery = _ganttSelectByQuery;
export const ganttSelectByQueryOnlyLength = _ganttSelectByQueryOnlyLength;
export const ganttSelectByQueryOnlyMaxMin = _ganttSelectByQueryOnlyMaxMin;
export const ganttSelectOneObjectByQuery = _ganttSelectOneObjectByQuery;
export const ganttSelectOneFieldByQuery = _ganttSelectOneFieldByQuery;


// WorkspaceUsers Reducer
export const {
  slice: WorkspaceUserSlice,
  dbThunks: _WorkspaceUserThunks,
  selectByQuery: _workspaceUserSelectByQuery,
  selectOneObjectByQuery: _workspaceUserSelectOneObjectByQuery,
  selectors: _workspaceUserSelectors,
  selectByQueryOnlyLength: _workspaceUserSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _workspaceUserSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<BlueticksWorkspaceUser>({
  service: "workspace-users",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBWorkspaceUserReducer,
});
export const DBWorkspaceUserReducer = WorkspaceUserSlice.reducer;
export const WorkspaceUserReducerActions = WorkspaceUserSlice.actions;
export type workspaceUserReducerActions = keyof typeof WorkspaceUserReducerActions;
export const DBWorkspaceUserThunks = _WorkspaceUserThunks;
export const workspaceUserSelectors = _workspaceUserSelectors;
export const workspaceUserSelectByQuery = _workspaceUserSelectByQuery;
export const workspaceUserSelectByQueryOnlyLength = _workspaceUserSelectByQueryOnlyLength;
export const workspaceUserSelectByQueryOnlyMaxMin = _workspaceUserSelectByQueryOnlyMaxMin;
export const workspaceUserSelectOneObjectByQuery = _workspaceUserSelectOneObjectByQuery;

// BoardUsers Reducer
export const {
  slice: BoardUserSlice,
  dbThunks: _BoardUserThunks,
  selectByQuery: _boardUserSelectByQuery,
  selectOneObjectByQuery: _boardUserSelectOneObjectByQuery,
  selectors: _boardUserSelectors,
  selectByQueryOnlyLength: _boardUserSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _boardUserSelectByQueryOnlyMaxMin,
  selectOneObjectById: _boardUserSelectOneObjectById,
  selectOneFieldById: _boardUserSelectOneFieldById,
} = createDBServiceSlice<BlueticksBoardUser>({
  service: "board-users",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBBoardUserReducer,
});
export const DBBoardUserReducer = BoardUserSlice.reducer;
export const BoardUserReducerActions = BoardUserSlice.actions;
export type boardUserReducerActions = keyof typeof BoardUserReducerActions;
export const DBBoardUserThunks = _BoardUserThunks;
export const boardUserSelectors = _boardUserSelectors;
export const boardUserSelectByQuery = _boardUserSelectByQuery;
export const boardUserSelectByQueryOnlyLength = _boardUserSelectByQueryOnlyLength;
export const boardUserSelectByQueryOnlyMaxMin = _boardUserSelectByQueryOnlyMaxMin;
export const boardUserSelectOneObjectByQuery = _boardUserSelectOneObjectByQuery;
export const boardUserSelectOneObjectById = _boardUserSelectOneObjectById;
export const boardUserSelectOneFieldById = _boardUserSelectOneFieldById;

// Subscriptions Reducer
export const {
  slice: SubscriptionSlice,
  dbThunks: _SubscriptionThunks,
  selectByQuery: _subscriptionSelectByQuery,
  selectOneObjectByQuery: _subscriptionSelectOneObjectByQuery,
  selectors: _subscriptionSelectors,
  selectByQueryOnlyLength: _subscriptionSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _subscriptionSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Subscription>({
  service: "subscriptions",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBSubscriptionReducer,
});
export const DBSubscriptionReducer = SubscriptionSlice.reducer;
export const SubscriptionReducerActions = SubscriptionSlice.actions;
export type subscriptionReducerActions = keyof typeof SubscriptionReducerActions;
export const DBSubscriptionThunks = _SubscriptionThunks;
export const subscriptionSelectors = _subscriptionSelectors;
export const subscriptionSelectByQuery = _subscriptionSelectByQuery;
export const subscriptionSelectByQueryOnlyLength = _subscriptionSelectByQueryOnlyLength;
export const subscriptionSelectByQueryOnlyMaxMin = _subscriptionSelectByQueryOnlyMaxMin;
export const subscriptionSelectOneObjectByQuery = _subscriptionSelectOneObjectByQuery;

// RecycledItemss Reducer
export const {
  slice: RecycledItemsSlice,
  dbThunks: _RecycledItemsThunks,
  selectByQuery: _recycledItemsSelectByQuery,
  selectOneObjectByQuery: _recycledItemsSelectOneObjectByQuery,
  selectors: _recycledItemsSelectors,
  selectByQueryOnlyLength: _recycledItemsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _recycledItemsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<RecycledItem>({
  service: "recycled-items",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBRecycledItemsReducer,
});
export const DBRecycledItemsReducer = RecycledItemsSlice.reducer;
export const RecycledItemsReducerActions = RecycledItemsSlice.actions;
export type recycledItemsReducerActions = keyof typeof RecycledItemsReducerActions;
export const DBRecycledItemsThunks = _RecycledItemsThunks;
export const recycledItemsSelectors = _recycledItemsSelectors;
export const recycledItemsSelectByQuery = _recycledItemsSelectByQuery;
export const recycledItemsSelectByQueryOnlyLength = _recycledItemsSelectByQueryOnlyLength;
export const recycledItemsSelectByQueryOnlyMaxMin = _recycledItemsSelectByQueryOnlyMaxMin;
export const recycledItemsSelectOneObjectByQuery = _recycledItemsSelectOneObjectByQuery;

// Webhooks Reducer
export const {
  slice: WebhooksSlice,
  dbThunks: _WebhooksThunks,
  selectByQuery: _webhooksSelectByQuery,
  selectOneObjectByQuery: _webhooksSelectOneObjectByQuery,
  selectors: _webhooksSelectors,
  selectByQueryOnlyLength: _webhooksSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _webhooksSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Webhook>({
  service: "webhooks",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBWebhooksReducer,
});
export const DBWebhooksReducer = WebhooksSlice.reducer;
export const WebhooksReducerActions = WebhooksSlice.actions;
export type webhooksReducerActions = keyof typeof WebhooksReducerActions;
export const DBWebhooksThunks = _WebhooksThunks;
export const webhooksSelectors = _webhooksSelectors;
export const webhooksSelectByQuery = _webhooksSelectByQuery;
export const webhooksSelectByQueryOnlyLength = _webhooksSelectByQueryOnlyLength;
export const webhooksSelectByQueryOnlyMaxMin = _webhooksSelectByQueryOnlyMaxMin;
export const webhooksSelectOneObjectByQuery = _webhooksSelectOneObjectByQuery;

// User Message Reducer
export const {
  slice: UserMessageSlice,
  dbThunks: _userMessageThunks,
  selectByQuery: _userMessagesSelectByQuery,
  selectByQueryOnlyLength: _userMessagesSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _userMessagesSelectByQueryOnlyMaxMin,
  selectOneFieldByQuery: _userMessagesSelectOneFieldByQuery,
  selectOneObjectByQuery: _userMessagesSelectOneObjectByQuery,
  selectors: _userMessagesSelectors,
} = createDBServiceSlice<UserMessage>({
  service: "user-messages",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBUserMessageReducer,
});
export const DBUserMessageReducer = UserMessageSlice.reducer;
export const userMesssageReducerActions = UserMessageSlice.actions;
export type UserMessageReducerActions = keyof typeof userMesssageReducerActions;
export const DBUserMessageThunks = _userMessageThunks;
export const userMessagesSelectByQuery = _userMessagesSelectByQuery;
export const userMessagesSelectByQueryOnlyLength = _userMessagesSelectByQueryOnlyLength;
export const userMessagesSelectByQueryOnlyMaxMin = _userMessagesSelectByQueryOnlyMaxMin;
export const userMessagesSelectOneObjectByQuery = _userMessagesSelectOneObjectByQuery;

export const currentChatMessageListSelector = createSelector<
  [Selector<RootState, RootState>, Selector<RootState, Chat>],
  UserMessage[]
>(
  [(state) => state, (state) => state.WhatsAppReducer.currentChat],
  (state, currentChat) =>
    state.DBUserMessageReducer.ids
      .map((id: EntityId) => {
        return state.DBUserMessageReducer.entities[id];
      })
      .filter(
        sift({
          $or: [{ deleted: { $exists: false } }, { deleted: false }],
          contactList: { $elemMatch: { id: currentChat?.id?._serialized } },
        })
      )
);

//quick launch reducer 
export const {
  slice: quickLaunchSlice,
  dbThunks: DBQuickLaunchThunks,
  selectByQuery: quickLaunchSelectByQuery,
  selectOneObjectByQuery: quickLaunchSelectOneObjectByQuery,
  selectOneFieldByQuery: quickLaunchSelectOneFieldByQuery,
  selectors: quickLaunchSelectors,
  selectByQueryOnlyLength: quickLaunchSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: quickLaunchSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<QuickLaunch>({
  service: 'quick-launch',
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBQuickLaunchReducer
})

export const DBQuickLaunchReducer = quickLaunchSlice.reducer
export const QuickLaunchReducerActions = quickLaunchSlice.actions


// Time tracking cell Reducer
export const {
  slice: TimeTrackingSessionsSlice,
  dbThunks: _TimeTrackingSessionsThunks,
  selectByQuery: _TimeTrackingSessionsSelectByQuery,
  selectOneObjectByQuery: _TimeTrackingSessionsSelectOneObjectByQuery,
  selectors: _TimeTrackingSessionsSelectors,
  selectByQueryOnlyLength: _TimeTrackingSessionsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _TimeTrackingSessionsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<TimeTrackingSession>({
  service: "time-tracking-sessions",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBTimeTrackingSessionReducer,
});



export const DBTimeTrackingSessionReducer = TimeTrackingSessionsSlice.reducer;
export const timeTrackingSessionsActions = TimeTrackingSessionsSlice.actions;
export type timeTrackingSessionsReducerActions = keyof typeof timeTrackingSessionsActions;
export const DBTimeTrackingSessionsThunks = _TimeTrackingSessionsThunks;
export const timeTrackingSessionsSelectors = _TimeTrackingSessionsSelectors;
export const timeTrackingSessionsSelectByQuery = _TimeTrackingSessionsSelectByQuery;
export const timeTrackingSessionsSelectByQueryOnlyLength = _TimeTrackingSessionsSelectByQueryOnlyLength;
export const timeTrackingSessionsSelectByQueryOnlyMaxMin = _TimeTrackingSessionsSelectByQueryOnlyMaxMin;
export const timeTrackingSessionsSelectOneObjectByQuery = _TimeTrackingSessionsSelectOneObjectByQuery;

// Log Reducer
export const {
  slice: LogsSlice,
  dbThunks: _LogsThunks,
  selectByQuery: _LogsSelectByQuery,
  selectOneObjectByQuery: _LogsSelectOneObjectByQuery,
  selectors: _LogsSelectors,
  selectByQueryOnlyLength: _LogsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _LogsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Log>({
  service: "logs",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBLogReducer,
});
export const DBLogReducer = LogsSlice.reducer;
export const logsActions = LogsSlice.actions;
export type logsReducerActions = keyof typeof logsActions;
export const DBLogsThunks = _LogsThunks;
export const logsSelectors = _LogsSelectors;
export const logsSelectByQuery = _LogsSelectByQuery;
export const logsSelectByQueryOnlyLength = _LogsSelectByQueryOnlyLength;
export const logsSelectByQueryOnlyMaxMin = _LogsSelectByQueryOnlyMaxMin;
export const logsSelectOneObjectByQuery = _LogsSelectOneObjectByQuery;

// Automation Reducer
export const {
  slice: AutomationsSlice,
  dbThunks: _AutomationsThunks,
  selectByQuery: _AutomationsSelectByQuery,
  selectOneObjectByQuery: _AutomationsSelectOneObjectByQuery,
  selectors: _AutomationsSelectors,
  selectByQueryOnlyLength: _AutomationsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _AutomationsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Automation>({
  service: "automations",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBAutomationReducer,
});
export const DBAutomationReducer = AutomationsSlice.reducer;
export const automationsActions = AutomationsSlice.actions;
export type automationsReducerActions = keyof typeof automationsActions;
export const DBAutomationsThunks = _AutomationsThunks;
export const automationsSelectors = _AutomationsSelectors;
export const automationsSelectByQuery = _AutomationsSelectByQuery;
export const automationsSelectByQueryOnlyLength = _AutomationsSelectByQueryOnlyLength;
export const automationsSelectByQueryOnlyMaxMin = _AutomationsSelectByQueryOnlyMaxMin;
export const automationsSelectOneObjectByQuery = _AutomationsSelectOneObjectByQuery;


// AutomationTrigger Reducer
export const {
  slice: AutomationTriggerSlice,
  dbThunks: _AutomationTriggerThunks,
  selectByQuery: _AutomationTriggerSelectByQuery,
  selectOneObjectByQuery: _AutomationTriggerSelectOneObjectByQuery,
  selectors: _AutomationTriggerSelectors,
  selectByQueryOnlyLength: _AutomationTriggerSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _AutomationTriggerSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<AutomationTrigger>({
  service: "automations-triggers",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBAutomationTriggerReducer,
});
export const DBAutomationTriggerReducer = AutomationTriggerSlice.reducer;
export const automationTriggerActions = AutomationTriggerSlice.actions;
export type automationTriggerReducerActions = keyof typeof automationTriggerActions;
export const DBAutomationTriggerThunks = _AutomationTriggerThunks;
export const automationTriggerSelectors = _AutomationTriggerSelectors;
export const automationTriggerSelectByQuery = _AutomationTriggerSelectByQuery;
export const automationTriggerSelectByQueryOnlyLength = _AutomationTriggerSelectByQueryOnlyLength;
export const automationTriggerSelectByQueryOnlyMaxMin = _AutomationTriggerSelectByQueryOnlyMaxMin;
export const automationTriggerSelectOneObjectByQuery = _AutomationTriggerSelectOneObjectByQuery;

// AutomationConditions Reducer
export const {
  slice: AutomationConditionsSlice,
  dbThunks: _AutomationConditionsThunks,
  selectByQuery: _AutomationConditionsSelectByQuery,
  selectOneObjectByQuery: _AutomationConditionsSelectOneObjectByQuery,
  selectors: _AutomationConditionsSelectors,
  selectByQueryOnlyLength: _AutomationConditionsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _AutomationConditionsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<AutomationConditions>({
  service: "automations-conditions",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBAutomationConditionsReducer,
});
export const DBAutomationConditionsReducer = AutomationConditionsSlice.reducer;
export const automationConditionsActions = AutomationConditionsSlice.actions;
export type automationConditionsReducerActions = keyof typeof automationConditionsActions;
export const DBAutomationConditionsThunks = _AutomationConditionsThunks;
export const automationConditionsSelectors = _AutomationConditionsSelectors;
export const automationConditionsSelectByQuery = _AutomationConditionsSelectByQuery;
export const automationConditionsSelectByQueryOnlyLength = _AutomationConditionsSelectByQueryOnlyLength;
export const automationConditionsSelectByQueryOnlyMaxMin = _AutomationConditionsSelectByQueryOnlyMaxMin;
export const automationConditionsSelectOneObjectByQuery = _AutomationConditionsSelectOneObjectByQuery;


// AutomationActions Reducer
export const {
  slice: AutomationActionsSlice,
  dbThunks: _AutomationActionsThunks,
  selectByQuery: _AutomationActionsSelectByQuery,
  selectOneObjectByQuery: _AutomationActionsSelectOneObjectByQuery,
  selectors: _AutomationActionsSelectors,
  selectByQueryOnlyLength: _AutomationActionsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _AutomationActionsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<AutomationActions>({
  service: "automations-actions",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBAutomationActionsReducer,
});
export const DBAutomationActionsReducer = AutomationActionsSlice.reducer;
export const automationActionsActions = AutomationActionsSlice.actions;
export type automationActionsReducerActions = keyof typeof automationActionsActions;
export const DBAutomationActionsThunks = _AutomationActionsThunks;
export const automationActionsSelectors = _AutomationActionsSelectors;
export const automationActionsSelectByQuery = _AutomationActionsSelectByQuery;
export const automationActionsSelectByQueryOnlyLength = _AutomationActionsSelectByQueryOnlyLength;
export const automationActionsSelectByQueryOnlyMaxMin = _AutomationActionsSelectByQueryOnlyMaxMin;
export const automationActionsSelectOneObjectByQuery = _AutomationActionsSelectOneObjectByQuery;



// View filters Reducer
export const {
  slice: ViewFiltersSlice,
  dbThunks: _ViewFiltersThunks,
  selectByQuery: _ViewFiltersSelectByQuery,
  selectOneObjectByQuery: _ViewFiltersSelectOneObjectByQuery,
  selectors: _ViewFiltersSelectors,
  selectByQueryOnlyLength: _ViewFiltersSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _ViewFiltersSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<ViewFilter>({
  service: "view-filters",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBViewFiltersReducer,
});
export const DBViewFiltersReducer = ViewFiltersSlice.reducer;
export const viewFiltersActions = ViewFiltersSlice.actions;
export type viewFiltersReducerActions = keyof typeof viewFiltersActions;
export const DBViewFiltersThunks = _ViewFiltersThunks;
export const viewFiltersSelectors = _ViewFiltersSelectors;
export const viewFiltersSelectByQuery = _ViewFiltersSelectByQuery;
export const viewFiltersSelectByQueryOnlyLength = _ViewFiltersSelectByQueryOnlyLength;
export const viewFiltersSelectByQueryOnlyMaxMin = _ViewFiltersSelectByQueryOnlyMaxMin;
export const viewFiltersSelectOneObjectByQuery = _ViewFiltersSelectOneObjectByQuery;





// boardView Reducer
export const {
  slice: BoardViewSlice,
  dbThunks: _BoardViewThunks,
  selectByQuery: _boardViewSelectByQuery,
  selectOneObjectByQuery: _boardViewSelectOneObjectByQuery,
  selectOneFieldByQuery: _boardViewSelectOneFieldByQuery,
  selectors: _boardViewSelectors,
  selectByQueryOnlyLength: _boardViewSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _boardViewSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<BoardView>({
  service: "board-views",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBBoardViewReducer,
});
export const DBBoardViewReducer = BoardViewSlice.reducer;
export const BoardViewReducerActions = BoardViewSlice.actions;
export type boardViewReducerActions = keyof typeof BoardViewReducerActions;
export const DBBoardViewThunks = _BoardViewThunks;
export const boardViewSelectors = _boardViewSelectors;
export const boardViewSelectByQuery = _boardViewSelectByQuery;
export const boardViewSelectByQueryOnlyLength = _boardViewSelectByQueryOnlyLength;
export const boardViewSelectByQueryOnlyMaxMin = _boardViewSelectByQueryOnlyMaxMin;
export const boardViewSelectOneObjectByQuery = _boardViewSelectOneObjectByQuery;
export const boardViewSelectOneFieldByQuery = _boardViewSelectOneFieldByQuery;


// Feature Reducer
export const {
  slice: FeatureSlice,
  dbThunks: _FeatureThunks,
  selectByQuery: _featureSelectByQuery,
  selectOneObjectByQuery: _featureSelectOneObjectByQuery,
  selectors: _featureSelectors,
  selectByQueryOnlyLength: _featureSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _featureSelectByQueryOnlyMaxMin,
  selectOneFieldByQuery: _featureSelectOneFieldByQuery,
} = createDBServiceSlice<Feature>({
  service: "features",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBFeatureReducer,
});
export const DBFeatureReducer = FeatureSlice.reducer;
export const FeatureReducerActions = FeatureSlice.actions;
export type featureReducerActions = keyof typeof FeatureReducerActions;
export const DBFeatureThunks = _FeatureThunks;
export const featureSelectors = _featureSelectors;
export const featureSelectByQuery = _featureSelectByQuery;
export const featureSelectByQueryOnlyLength = _featureSelectByQueryOnlyLength;
export const featureSelectByQueryOnlyMaxMin = _featureSelectByQueryOnlyMaxMin;
export const featureSelectOneObjectByQuery = _featureSelectOneObjectByQuery;
export const featureSelectOneFieldByQuery = _featureSelectOneFieldByQuery;

// MyWorkss Reducer
export const {
  slice: MyWorksSlice,
  dbThunks: _MyWorksThunks,
  selectByQuery: _myWorksSelectByQuery,
  selectOneObjectByQuery: _myWorksSelectOneObjectByQuery,
  selectOneFieldByQuery: _myWorksSelectOneFieldByQuery,
  selectors: _myWorksSelectors,
  selectByQueryOnlyLength: _myWorksSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _myWorksSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<MyWork>({
  service: "my-works",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBMyWorksReducer,
});
export const DBMyWorksReducer = MyWorksSlice.reducer;
export const MyWorksReducerActions = MyWorksSlice.actions;
export type myWorksReducerActions = keyof typeof MyWorksReducerActions;
export const DBMyWorksThunks = _MyWorksThunks;
export const myWorksSelectors = _myWorksSelectors;
export const myWorksSelectByQuery = _myWorksSelectByQuery;
export const myWorksSelectByQueryOnlyLength = _myWorksSelectByQueryOnlyLength;
export const myWorksSelectByQueryOnlyMaxMin = _myWorksSelectByQueryOnlyMaxMin;
export const myWorksSelectOneObjectByQuery = _myWorksSelectOneObjectByQuery;
export const myWorksSelectOneFieldByQuery = _myWorksSelectOneFieldByQuery;



// Notification Reducer
export const {
  slice: NotificationsSlice,
  dbThunks: _NotificationsThunks,
  selectByQuery: _notificationsSelectByQuery,
  selectOneObjectByQuery: _notificationsSelectOneObjectByQuery,
  selectors: _notificationsSelectors,
  selectByQueryOnlyLength: _notificationsSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _notificationsSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<Notification>({
  service: "notifications",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBNotificationsReducer,
});
export const DBNotificationsReducer = NotificationsSlice.reducer;
export const NotificationsReducerActions = NotificationsSlice.actions;
export type notificationsReducerActions = keyof typeof NotificationsReducerActions;
export const DBNotificationsThunks = _NotificationsThunks;
export const notificationsSelectors = _notificationsSelectors;
export const notificationsSelectByQuery = _notificationsSelectByQuery;
export const notificationsSelectByQueryOnlyLength = _notificationsSelectByQueryOnlyLength;
export const notificationsSelectByQueryOnlyMaxMin = _notificationsSelectByQueryOnlyMaxMin;
export const notificationsSelectOneObjectByQuery = _notificationsSelectOneObjectByQuery;


// EntitySubscribers Reducer
export const {
  slice: EntitySubscribersSlice,
  dbThunks: _EntitySubscribersThunks,
  selectByQuery: _entitySubscribersSelectByQuery,
  selectOneObjectByQuery: _entitySubscribersSelectOneObjectByQuery,
  selectors: _entitySubscribersSelectors,
  selectByQueryOnlyLength: _entitySubscribersSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _entitySubscribersSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<EntitySubscriber>({
  service: "entity-subscribers",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBEntitySubscribersReducer,
});
export const DBEntitySubscribersReducer = EntitySubscribersSlice.reducer;
export const EntitySubscribersReducerActions = EntitySubscribersSlice.actions;
export type entitySubscribersReducerActions = keyof typeof EntitySubscribersReducerActions;
export const DBEntitySubscribersThunks = _EntitySubscribersThunks;
export const entitySubscribersSelectors = _entitySubscribersSelectors;
export const entitySubscribersSelectByQuery = _entitySubscribersSelectByQuery;
export const entitySubscribersSelectByQueryOnlyLength = _entitySubscribersSelectByQueryOnlyLength;
export const entitySubscribersSelectByQueryOnlyMaxMin = _entitySubscribersSelectByQueryOnlyMaxMin;
export const entitySubscribersSelectOneObjectByQuery = _entitySubscribersSelectOneObjectByQuery;


// Sessions Reducer
export const {
  slice: SessionSlice,
  dbThunks: _SessionThunks,
  selectByQuery: _sessionSelectByQuery,
  selectOneObjectByQuery: _sessionSelectOneObjectByQuery,
  selectors: _sessionSelectors,
  selectByQueryOnlyLength: _sessionSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _sessionSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<WASession>({
  service: "sessions",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBSessionReducer,
});
export const DBSessionReducer = SessionSlice.reducer;
export const SessionReducerActions = SessionSlice.actions;
export type sessionReducerActions = keyof typeof SessionReducerActions;
export const DBSessionThunks = _SessionThunks;
export const sessionSelectors = _sessionSelectors;
export const sessionSelectByQuery = _sessionSelectByQuery;
export const sessionSelectByQueryOnlyLength = _sessionSelectByQueryOnlyLength;
export const sessionSelectByQueryOnlyMaxMin = _sessionSelectByQueryOnlyMaxMin;
export const sessionSelectOneObjectByQuery = _sessionSelectOneObjectByQuery;


//create reducer for NewTabFocus
export const {
  slice: NewTabFocusSlice,
  dbThunks: _NewTabFocusThunks,
  selectByQuery: _newTabFocusSelectByQuery,
  selectOneObjectByQuery: _newTabFocusSelectOneObjectByQuery,
  selectors: _newTabFocusSelectors,
  selectByQueryOnlyLength: _newTabFocusSelectByQueryOnlyLength,
  selectByQueryOnlyMaxMin: _newTabFocusSelectByQueryOnlyMaxMin,
} = createDBServiceSlice<NewTabFocus>({
  service: "new-tab-focus",
  initialState: { ids: [], entities: {}, itemsLoaded: false, itemsLoading: false, total: 0, skip: 0, limit: 0 },
  getReducerState: (state) => state.DBNewTabFocusReducer,
});
export const DBNewTabFocusReducer = NewTabFocusSlice.reducer;
export const NewTabFocusReducerActions = NewTabFocusSlice.actions;
export type newTabFocusReducerActions = keyof typeof NewTabFocusReducerActions;
export const DBNewTabFocusThunks = _NewTabFocusThunks;
export const newTabFocusSelectors = _newTabFocusSelectors;
export const newTabFocusSelectByQuery = _newTabFocusSelectByQuery;
export const newTabFocusSelectByQueryOnlyLength =
  _newTabFocusSelectByQueryOnlyLength;
export const newTabFocusSelectByQueryOnlyMaxMin =
  _newTabFocusSelectByQueryOnlyMaxMin;
export const newTabFocusSelectOneObjectByQuery =
  _newTabFocusSelectOneObjectByQuery;


// Must be declared after all the reducers!
export type DBSlice = ReturnType<typeof createDBServiceSlice>;
export const DBReducerMap: Map<string, DBSlice> = new Map(_DBReducerMap);
