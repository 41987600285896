import { TaskColumn } from "@models/task-column";
import { UserInterface } from "@models/user";
import { createSelector } from "@reduxjs/toolkit";
import { boardsSelectByQuery, campaignsSelectOneObjectByQuery, messageLogSelectByQuery, messageLogSelectOneObjectByQuery, taskColumnSelectByQuery } from "./DBServiceReducers";
import { activeSubscriptionSelector, selectUser } from "./UserSelectors";
import { selectMergedContactMap } from "./WhatsAppReducer";
import { isScheduleValid } from "@services/utils";
import { RootState } from "@store/index";
import { $in } from "sift";

export const getUserIdIfExistForBulkDashboard = createSelector(
    [
        (state) => state
    ],
    (state) => {
        const user = selectUser as UserInterface

        return user._id;
    }
);


export const getIfSubscriptionHasBulk = createSelector(
    [
        (state) => state
    ],
    (state) => {
        const activeSubscription = activeSubscriptionSelector(state)

        return activeSubscription?.hasBulk;
    }
);


export const getSelectedListId = createSelector(
    [
        (state) => state
    ],
    (state) => {
        const { contactListPanelState: contactListPanel } = state.CampaignReducer;

        return contactListPanel?.getSelectedListId;
    }
);


export const getSelectedTemplateId = createSelector(
    [
        (state) => state
    ],
    (state) => {
        const { templatePanelState } = state.CampaignReducer;

        return templatePanelState?.selectedTemplateId;
    }
);


export const getTemplatePlaceholderListForCampaign = createSelector(
    [
        (state, boardIDArray) => state,
        (state, boardIDArray) => boardIDArray,
    ],
    (state, boardIDArray) => {
        let columnList = []
        for (let boardId of boardIDArray ?? []) {
            const currentBoardIdColumnList = taskColumnSelectByQuery(
                state.DBTaskColumnReducer,
                {
                    $or: [{ deleted: { $exists: false } }, { deleted: false }],
                    boardId,
                },
                ["type", "title"],
                {
                    order: 1,
                }
            )
                .filter(column => ['number', 'link', 'text-cell', 'phone-cell', 'option-picker', 'complexity-picker',
                    'status-option-picker', 'datepicker',].includes(column.type)) // in case not all column types are supported
            columnList = [...columnList, ...currentBoardIdColumnList]
        }
        const seen = new Set();
        let returnList: string[] = columnList
            .map((column: TaskColumn) => column.title ?? column.type)
            .filter(str => {
                const normalizedStr = str.replace(/\s+/g, '').toLowerCase();
                if (seen.has(normalizedStr)) {
                    return false;
                }
                seen.add(normalizedStr);
                return true;
            })

        returnList.unshift('Whatsapp name')

        return returnList
    }
);



export const getTaskSumByBoardIdRecord = createSelector(
    [
        (state) => state,
    ],
    (state) => {
        const boards = boardsSelectByQuery(
            state.DBBoardsReducer,
            {
                $or: [{ deleted: { $exists: false } }, { deleted: false }],
                type: 'audience',
            },
            ["_id", "tasksCount"],
            {
                order: 1,
            }
        )

        let tasksRecord = {}

        for (let board of boards) {
            tasksRecord[board._id] = board.tasksCount
        }

        return tasksRecord
    }
);


export const getBoardNameByBoardIdRecord = createSelector(
    [
        (state) => state,
    ],
    (state) => {
        const boards = boardsSelectByQuery(
            state.DBBoardsReducer,
            {
                $or: [{ deleted: { $exists: false } }, { deleted: false }],
                type: 'audience',
            },
            ["_id", "name"],
            {
                order: 1,
            }
        )

        let namesRecord = {}

        for (let board of boards) {
            namesRecord = {
                ...namesRecord,
                [board._id]: board.name
            }
        }

        return namesRecord
    }
);


export const getCampaignSelectedAudenicesAmount = createSelector([(state) => state],
    (state) => {
        const newCampaign = state.CampaignReducer.newCampaign

        return (newCampaign?.boardIDArray?.length ?? 0)
    });


export const getCampaignDueDate = createSelector([(state) => state], (state) => {
    const newCampaign = state.CampaignReducer.newCampaign
    return newCampaign?.schedule?.dueDate
});


export const getIsCampaginMessageExist = createSelector([(state) => state], (state) => {
    const currentTemplate = state.CampaignReducer.newCampaign?.template
    const isCampaginMessageExist = currentTemplate?.message ||
        currentTemplate?.linkPreview?.url ||
        currentTemplate?.linkPreview?.title ||
        currentTemplate?.linkPreview?.description

    return isCampaginMessageExist
}
);



export const getIsAudienceRowChecked = createSelector(
    [
        (state, itemId) => state,
        (state, itemId) => itemId,
    ],
    (state, itemId) => {
        // const checked = newCampaign?.boardIDArray?.includes(itemId)
        const newCampaign = state.CampaignReducer.newCampaign

        return (newCampaign?.boardIDArray ?? []).includes(itemId)
    }
);


export const getIsCampaignWithoutContacts = createSelector(
    [
        (state) => state,
    ],
    (state) => {
        const taskSumByBoardIdRecord = getTaskSumByBoardIdRecord(state)
        const newCampaign = state.CampaignReducer.newCampaign

        let counter = 0
        for (let boardId of (newCampaign?.boardIDArray ?? [])) {
            if (boardId) {
                counter += taskSumByBoardIdRecord[boardId]
            }
        }

        return counter === 0
    }
);


export const getFilteredIdArray = createSelector(
    [
        (state, searchTerm) => state,
        (state, searchTerm) => searchTerm,
    ],
    (state, searchTerm) => {
        const mergedContactMap = selectMergedContactMap(state);
        const campaignIdForLogsView = state.CampaignReducer.campaignIdForLogsView
        const filteredKeys = Object.keys(mergedContactMap)
            .filter(key => mergedContactMap[key]?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()))

        const messageLogs = messageLogSelectByQuery(
            state.DBMessageLogReducer,
            {
                campaignId: campaignIdForLogsView,
            },
            [],
            {
                order: 1,
            }
        )

        const filteredMessageLogs = messageLogs.filter(log => log?.message.contactList?.[0]?.id &&
            filteredKeys.includes(log?.message.contactList?.[0]?.id))
            .map(log => log._id)

        return filteredMessageLogs
    }
);


export const getIsCampaignCompleteAndAllLogsAreComplete = createSelector(
    [
        (state, campaignId) => state,
        (state, campaignId) => campaignId,
    ],
    (state, campaignId) => {
        const campaignLogs = messageLogSelectByQuery(state.DBMessageLogReducer, {
            $or: [
                { deleted: { $exists: false } },
                { deleted: false }
            ],
            campaignId,
        })
        const campaign = campaignsSelectOneObjectByQuery(state.DBCampaignReducer, {
            _id: campaignId,
        })

        const numberOfLogs = campaignLogs?.length
        const numberOfSentLogs = campaignLogs?.filter(log => log.status === 'sent')?.length
        const isCampaignComplete = campaign.campaignStatus === "completed"

        return isCampaignComplete && (numberOfLogs === numberOfSentLogs)
    }
);

export const selectNumberOfAudienceSelected = createSelector(
    [
        (state) => state.CampaignReducer.newCampaign
    ],
    (newCampaign) => {
        return newCampaign?.boardIDArray?.length ?? 0;
    }
);

export const selectCanContinueToNextTab = createSelector(
    [
        selectNumberOfAudienceSelected,
        (state) => state.CampaignReducer.activeTab,
        (state) => state.CampaignReducer.newCampaign
    ],
    (isAudienceSelected, activeTab, newCampaign) => {
        const currentTemplate = newCampaign?.template
        const isCampaignMessageExist = currentTemplate?.message || currentTemplate?.linkPreview?.url || currentTemplate?.linkPreview?.title || currentTemplate?.linkPreview?.description;
        const isCampaignDueDateExist = !!newCampaign?.schedule?.dueDate

        let canContinueToNextTab = false;

        switch (activeTab) {
            case "audiences":
                canContinueToNextTab = !!isAudienceSelected;
                break;
            case "messageAndFiles":
                canContinueToNextTab = isCampaignMessageExist;
                break;
            case "timeAndDate":
                canContinueToNextTab = isCampaignDueDateExist;
                break;
            default:
                break;
        }

        return canContinueToNextTab;
    }
);

export const selectIsTimingValid = createSelector(
    [
        (state) => state.CampaignReducer.newCampaign
    ],
    (newCampaign) => {
        const isNow = newCampaign?.schedule?.isNow;

        return isNow || isScheduleValid(newCampaign.schedule?.dueDate);
    }
);

export const selectRunningCampaign = createSelector(
    [
        (state: RootState) => state.DBCampaignReducer
    ],
    (DBCampaignReducer) => {
        const runningCampaign = campaignsSelectOneObjectByQuery(DBCampaignReducer, {
            campaignStatus: "running"
        }, [], { createdAt: -1 })
        return runningCampaign;
    }
);

export const selectRunningCampaignName = createSelector(
    [
        selectRunningCampaign
    ],
    (runningCampaign) => {
        return runningCampaign?.name;
    }
);

export const selectCampaignNumberOfContacts = createSelector(
    [
        (state: RootState) => state.DBBoardsReducer,
        selectRunningCampaign
    ],
    (DBBoardsReducer, runningCampaign) => {
        const taskSumByBoardIdRecord = getTaskSumByBoardIdRecord({ DBBoardsReducer })
        let counter = 0
        for (let boardId of (runningCampaign?.boardIDArray ?? [])) {
            if (boardId) {
                counter += taskSumByBoardIdRecord[boardId] ?? 0
            }
        }

        return counter
    }
);

export const selectIsCampaignRunning = createSelector(
    [
        selectRunningCampaign,
        selectCampaignNumberOfContacts
    ],
    (runningCampaign, campaignNumberOfContacts) => {
        return (!!runningCampaign) && (campaignNumberOfContacts > 0);
    }
);

export const selectRunningCampaignSentMessageCount = createSelector(
    [
        (state: RootState) => state.DBMessageLogReducer,
        selectRunningCampaign
    ],
    (DBMessageLogReducer, runningCampaign): number => {
        const messageLogs = messageLogSelectByQuery(DBMessageLogReducer, {
            campaignId: runningCampaign?._id,
            status: {
                $in: ['sent', 'delivered']
            }
        })

        // find number of unique messages by field messageId
        const messageLogsByMessageId = messageLogs.reduce((acc, log) => {
            if (!acc[log.messageId] || acc[log.messageId].createdAt < log.createdAt) {
                acc[log.messageId] = log
            }
            return acc
        }, {})

        return Object.keys(messageLogsByMessageId).length
    }
);

// Selector for campaign logs, aggregated by latest log per messageId
export const selectCampaignLogs = createSelector(
    [
        (state: RootState) => state.DBMessageLogReducer,
        (state: RootState, campaignId) => campaignId
    ],
    (DBMessageLogReducer, campaignId) => {
        const messageLogs = messageLogSelectByQuery(DBMessageLogReducer, {
            campaignId
        })
        const messageLogsByMessageId = messageLogs.reduce((acc, log) => {
            if (!acc[log.messageId] || acc[log.messageId].createdAt < log.createdAt) {
                acc[log.messageId] = log
            }
            return acc
        }, {})
        return Object.values(messageLogsByMessageId)
    }
);
