import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import moment from "moment";
// import { AnalyticsService } from "../services/analytics-service";
import { MessageState } from "../types/interface";
import { User } from "@models/user";
import { Chat } from "@services/whatsapp";
import { AnalyticsService } from "@services/analytics-service";
import { UserMessage } from "@models/user-message";
import { selectIsCurrentUserSessionConnected } from "./UserSelectors";

export interface AddScheduledMessageButtonState {
  showErrorDialog: boolean;
  open: boolean;
  message: UserMessage;
  isEmojiPicker: boolean;
  user?: User | null | undefined;
  currentChat?: Chat;
  showConfirmationDialog?: boolean;
  originalMessage?: UserMessage;
  isWaSocketsLimitReached: boolean;
}

const initialState: AddScheduledMessageButtonState = {
  showErrorDialog: false,
  open: false,
  message: {
    dueDate: undefined,
    message: "",
    isRecurring: false,
    state: MessageState.pending,
    cancelIfReceived: false,
  },
  isEmojiPicker: false,
  showConfirmationDialog: false,
  originalMessage: undefined,
  isWaSocketsLimitReached: false,
};

const analyticsService = new AnalyticsService();

const AddScheduledMessageButtonSlice = createSlice({
  name: "AddScheduledMessageButtonSlice",
  initialState,
  reducers: {
    editMessage: (state, action: PayloadAction<UserMessage>) => {
      const message = { ...action.payload };
      if (!state.open) {
        message.dueDate = moment(message.dueDate).toDate().toISOString();
        message.state = MessageState.editing;
        state.open = true;
        state.message = message;
      }
    },
    updateMessage: (state, action: PayloadAction<UserMessage>) => {
      state.message = action.payload;
    },
    closeDialog: (state, action: PayloadAction<void>) => {
      return initialState;
    },
    setIsEmojiPicker: (state, action: PayloadAction<boolean>) => {
      state.isEmojiPicker = action.payload;
    },
    editCalendarMessage: (state, action: PayloadAction<UserMessage>) => {
      const message = { ...action.payload };
      message.state = MessageState.editing;
      state.open = true;
      state.message = message;
      state.originalMessage = { ...message }
    },
    setConfirmationDialog: (state, action: PayloadAction<boolean>) => {
      state.showConfirmationDialog = action.payload;
    },
    setIsSocketsLimitReached: (state, action: PayloadAction<boolean>) => {
      state.isWaSocketsLimitReached = action.payload;
    },
  },
});

const selectState = (state: RootState) => state.AddScheduledMessageButtonReducer
export const selectIsOpen = createSelector(selectState, (s: AddScheduledMessageButtonState) => s.open);


export const selectIsWaSocketsLimitReached = createSelector([
  (state) => state.AddScheduledMessageButtonReducer.isWaSocketsLimitReached,
  selectIsCurrentUserSessionConnected
],
  (isWaSocketsLimitReached, isCurrentUserSessionConnected) => !isCurrentUserSessionConnected && isWaSocketsLimitReached
);

export const addScheduledMessageButtonActions =
  AddScheduledMessageButtonSlice.actions;
export default AddScheduledMessageButtonSlice.reducer;
