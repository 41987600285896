import React, { useState } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { UserInterface } from "@models/user";
import { selectUserObject, workspaceSubscriptionListSelector } from "@reducers/UserSelectors";
import { useGetEmailWorkspacesMutation, useUpdateSubscriptionWorkspaceMutation } from "@reducers/backend-api/backend-api";
import { modalActions } from "@reducers/ModalReducer";
import { BackIcon } from '@views/qr-view/qr-icons';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
interface Workspace {
  id: string;
  name: string;
}

export const UpdateSubscriptionWorkSpace = () => {
  const dispatch = useAppDispatch();
  const user: UserInterface = useAppSelector(selectUserObject);
  const [getWorkspacesByEmail, { isLoading: isLoadingWorkspaces }] = useGetEmailWorkspacesMutation();
  const [changeWorkspaceSubscription, { isLoading: isChangingSubscription }] = useUpdateSubscriptionWorkspaceMutation();
  const [workspaceSubscription] = useAppSelector(workspaceSubscriptionListSelector) ?? null;

  const [email, setEmail] = useState('');
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<string>('');
  const [phase, setPhase] = useState(1);
  const [message, setMessage] = useState<string>('');

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleGetUserWorkspaces = async () => {
    try {
      const response: any = await getWorkspacesByEmail({ email }).unwrap();
      setWorkspaces(response);
      setPhase(2); 
    } catch (error) {
      console.error("Failed to fetch workspaces:", error);
    }
  };

  const handleWorkspaceChange = async (event: SelectChangeEvent<string>) => {
    const workspaceId = event.target.value as string;
    setSelectedWorkspace(workspaceId);
    try {
      const response = await changeWorkspaceSubscription({ workspaceId, subscriptionId: workspaceSubscription?._id }).unwrap() as any;
      if (response.status === "ok") {
        setMessage(`Subscription successfully moved to workspace ${workspaceId}`);
        setPhase(3);
      } else {
        setMessage(`Failed to move subscription: ${response.error}`);
        setPhase(3);
      }
    } catch (error) {
      console.error("Failed to change workspace subscription:", error);
      setMessage("An error occurred while changing the subscription.");
      setPhase(3);
    }
  };

  const handleBack = () => {
    setPhase(1);
    setSelectedWorkspace('');
  };

  return (
    <>
      <DialogTitle>Update Subscription Workspace</DialogTitle>
      <DialogContent>
        {phase === 1 && (
          <>
            <DialogContentText>
              <Box>Enter your email address to fetch workspaces.</Box>
            </DialogContentText>
            <TextField
              label="Email Address"
              type="email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              margin="normal"
            />
            <Button
              onClick={handleGetUserWorkspaces}
              color="primary"
              variant="contained"
              fullWidth
              disabled={isLoadingWorkspaces}
            >
              Get User Workspaces
            </Button>
          </>
        )}
        {phase === 2 && (
          <>
            <Button startIcon={<KeyboardBackspaceIcon/>} onClick={handleBack} color="secondary">
           Go back

            </Button>

            <DialogContentText>
              <Box>Select a workspace you want to assign the subscription.</Box>
            </DialogContentText>

            <Select
              fullWidth
              value={selectedWorkspace}
              onChange={handleWorkspaceChange}
              displayEmpty
         
            >
              <MenuItem value="" disabled>
                Select Workspace
              </MenuItem>
              {workspaces.map((workspace) => (
                <MenuItem key={workspace.id} value={workspace.id}>
                  {workspace.name} - {workspace.id}
                </MenuItem>
              ))}
            </Select>
          </>
        )}

{phase === 3 && (
          <DialogContentText>
            <Box>{message}</Box>
          </DialogContentText>
        )}
      </DialogContent>

    </>
  );
};
