import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkAppDispatch } from "@store/hooks";
import { RootState } from "@store/index";
import { DBFormThunks, formSelectOneObjectByQuery } from "./DBServiceReducers";
import { getSortedVisibleColumnList } from "./FormReducers";




export const updateFormColumnDataThunk = createAsyncThunk<
    void,
    { formId: string, },
    { state: RootState }
>("boardViews/addNewBoardViewThunk", async (data, thunkAPI) => {
    const { formId } = data;
    const dispatch: ThunkAppDispatch = thunkAPI.dispatch as ThunkAppDispatch;
    const state = thunkAPI.getState();

    /// get form from state by the formId. get sortedVisibleColumnList with getSortedVisibleColumnList selector. for each column in sortedVisibleColumnList that the form doesnt has its sortedVisibleColumn._id as a field, create a new field in the form's columnData with the sortedVisibleColumn._id as the field name and an empty object inside


    const form = formSelectOneObjectByQuery(state.DBFormReducer, {
        _id: formId,
    });
    const sortedVisibleColumnList = getSortedVisibleColumnList(state, form?.columnData, form?.boardId, formId);
    const sortedVisibleColumnIdList = sortedVisibleColumnList.map((column, index) => column._id);
    const newColumnData = { ...form?.columnData };
    sortedVisibleColumnIdList.forEach((columnId) => {
        if (newColumnData[columnId] === undefined) {
            newColumnData[columnId] = {};
        }
    });
    const updatedForm = { ...form, columnData: newColumnData };
    await dispatch(DBFormThunks.patch({ entity: { ...updatedForm } }));
});