import BTButton from '@components/bt-button/bt-button';
import BTDrawer from '@components/bt-drawer/bt-drawer';
import InvoiceTable from '@components/invoice-table/invoice-table';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import { useGetWorkspaceInvoicesMutation } from "@reducers/backend-api/backend-api";
import { getPortalSessionUrlThunk } from '@reducers/PaymentFormThunks';
import { useAppDispatch } from '@store/hooks';
import { namespace } from 'config/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
const ChargebeeAdminPortal = () => {
    const dispatch = useAppDispatch();
    const [portalUrl, setPortalUrl] = useState('');
    const [getWorkspaceInvoices, { data: workspaceInvoices }] = useGetWorkspaceInvoicesMutation();
    const [drawerStatus, setDrawerStatus] = useState(false);
    const { t } = useTranslation(namespace);

    useEffect(() => {
        const fetchPortalUrl = async () => {
            try {
                const result = await dispatch(getPortalSessionUrlThunk());
                if (getPortalSessionUrlThunk.fulfilled.match(result)) {
                    setPortalUrl(result.payload);
                } else {
                    console.error('Failed to fetch portal URL');
                }
            } catch (error) {
                console.error('Error fetching portal URL:', error);
            }
        };

        fetchPortalUrl();
    }, [dispatch]);

    useEffect(() => {
        getWorkspaceInvoices();
    }, [getWorkspaceInvoices]);

    const toggleDrawer = () => {
        setDrawerStatus(!drawerStatus);
    };

    return (
        <Box sx={{ width: '100%', height: '100%', border: 'none', position: 'relative' }}>
            {!portalUrl ? (
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%', border: 'none' }}>
                    <CircularProgress />
                </Stack>
            ) : (
                <>
                    <iframe
                        title="Chargebee Admin Portal"
                        src={portalUrl}
                        style={{ width: '100%', height: '95%', border: 'none' }}
                    ></iframe>
            {workspaceInvoices && workspaceInvoices.invoiceList.length > 0 && (
                    <Box sx={{ position: 'absolute', bottom: 20, width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <BTButton btvariant="text" onClick={toggleDrawer}>
                        {t('adminBillingView.viewOldInvoices')}
                    </BTButton>
                </Box>
            )
            }
                

                </>
            )}
            <BTDrawer
                anchor="right"
                open={drawerStatus}
                onClose={toggleDrawer}
                sx={{
                    ".MuiPaper-root": {
                        overflow: "hidden"
                    },
                }}
            >
                <Box sx={{
                    width: "750px",
                    overflow: "auto",
                    height: "100%",
                    padding: "24px",
                    backgroundColor: "var(--secondary-background-color)"
                }}>
              
              
                        <IconButton onClick={toggleDrawer}>
                            <CloseIcon />
                        </IconButton>
                    <h3>Invoices</h3>
                    <InvoiceTable />
                </Box>
            </BTDrawer>
        </Box>
    );
};

export default ChargebeeAdminPortal;
