import { BoardRowType, Task } from '@models/task'
import { TasksGroup } from '@models/task-group'
import { Box, styled } from '@mui/material'
import { getSelectedBoardId } from '@reducers/BoardsSelectors'
import { getBulkTaskList } from '@reducers/BulkActionsReducer'
import { DBTasksThunks, taskgroupsSelectors, tasksSelectByQuery } from '@reducers/DBServiceReducers'
import { taskActions } from '@reducers/TasksReducer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { namespace } from 'config/constants'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsSearch } from 'react-icons/bs'
import classes from './select-parent-task.module.scss'

const Container = styled(Box)(() => ({
    maxWidth: 'auto',
    height: 'auto',
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
    boxShadow: '0px 8px 16px 0px var(--shadow-default-color)'
}))
const HeaderText = styled('p')(() => ({
    fontWeight: 500,
    fontSize: 16,
    margin: 0
}))
const MenuItemButton = styled('button')(() => ({
    width: 198,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
        backgroundColor: 'var(--button-hovered-color)'
    }
}))


type Props = {
    taskId: string
}

export default function SelectParentTask({ taskId }: Props) {
    const { t } = useTranslation(namespace);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const [taskFilterText, setTaskFilterText] = useState<string>('')
    const bulkTasksList = useAppSelector(getBulkTaskList)

    let tasksFilter: any = {
        boardId,
        text: new RegExp(taskFilterText, "i"),
        $or: [{ deleted: { $exists: false } }, { deleted: false }]
    }



    const groups: TasksGroup[] = useAppSelector(taskgroupsSelectors.selectAll)
    const activeTaskid = useAppSelector((state) => state.TasksReducer.menuActiveTaskId)

    const tasksList: Task[] = useAppSelector((state) => tasksSelectByQuery(state.DBTasksReducer,
        tasksFilter,
        [
            "_id",
            "groupId",
            "order",
            "text",
            "showSubTasks",
            "showAllSubTasks",
            "parentTaskId",
            "priority",
            "priorityId",
            "status",
            "statusId",
            "owner",
            "person",
            "taskType",
            "createdAt",
            "customData",
            "updatedAt",
        ],
    ))

    const dispatch = useAppDispatch()
    const move_to = useAppSelector((state) => state.BulkActionsReducer.move_to)

    // convert task subTask 
    function convertTaskToSub(parentTask: Task) {

        const task = tasksList.find((task) => task._id === activeTaskid);
        let taskType: string = BoardRowType.subTask;
        let tasksToPatch = bulkTasksList;
        if (tasksToPatch.length === 0){
            tasksToPatch = [{_id:activeTaskid}]
            
        }
  
        const changes = tasksToPatch.map((task) => {
            let change = {
                id: task._id,
                changes: {
                    groupId: parentTask.groupId,
                    parentTaskId: parentTask._id,
                    taskType
                },
            };

            return change;
        });

        dispatch(DBTasksThunks.patchMany(changes));
    
      
        dispatch(taskActions.toggleTaskMenu(''))
    }

    function parentTasksList(groupId) {
        const filtered = tasksList.filter((task) => task.groupId === groupId && task.taskType !== 'subTask')
        return filtered
    }



    return (

        <Container >
            <HeaderText>{t('selectParentTask.chooseParentTask')}</HeaderText>
            <Box className={classes.filter_container}>
                <input type="text" onChange={({ target }: any) => setTaskFilterText(target.value)} placeholder={`${t('selectParentTask.searchTaskPlaceholder')} ${move_to}`} />
                <button>
                    <BsSearch />
                </button>
            </Box>

            <Box className={classes.mapped_items}>

                <>
                    {groups.map((group, index) => (
                        <Box key={index} sx={{mb:2}}>
                            <Box key={index} className={classes.group_item_wrap}>
                                <Box className={classes.accent_icon} style={{
                                    backgroundColor: group.colorAccent
                                        ?? '#00c875'
                                }}></Box>
                                <MenuItemButton sx={{
                                    flex: 1,
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}>
                                    {group.name}
                                </MenuItemButton>

                            </Box>
                            {parentTasksList(group._id).map((taskItem) => (
                                <Box key={`${index}-${taskItem._id}`}>
                                    {taskItem._id === taskId ? <></> : (
                                        <MenuItemButton onClick={() => convertTaskToSub(taskItem)}>
                                            {taskItem.text}
                                        </MenuItemButton>
                                    )}
                                </Box>

                            ))}
                        </Box>))}
                </>

            </Box>
        </Container >

    )
}