import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { CSSProperties } from 'react';
import { NotificationStack } from './notification-row';

type Props = {
    notificationId: string;
}

export default function DateColumnNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))
    const cellWrapperStyle: CSSProperties = { pointerEvents: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingInline: '2px', paddingBlockStart: '2px' }

    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === notification.notificationUpdateType
    );

    let status
    if (notification.oldValue && notification.newValue) { status = 'change data' }
    if (!notification.oldValue && notification.newValue) { status = 'add data' }
    if (notification.oldValue && !notification.newValue) { status = 'remove data' }
    return (
        <NotificationStack direction='row'>
            {status === 'change data' &&
                <>
                    <span>{notification.actionType}</span>
                    <span style={cellWrapperStyle}><CellWrapper
                        sx={{
                            display: 'contents',
                        }}
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={notification.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></span>
                    <span> to </span>
                    <span style={cellWrapperStyle}><CellWrapper
                        sx={{
                            display: 'contents',
                        }}
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={notification.newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></span>
                </>
            }
            {status === 'add data' &&
                <>
                    <span>{notification.actionType}&nbsp;</span>
                    <span> to </span>
                    <span style={cellWrapperStyle}><CellWrapper
                        sx={{
                            display: 'contents',
                        }}
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={notification.newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></span>
                </>
            }
            {status === 'remove data' &&
                <>
                    <span>removed&nbsp;</span>
                    <span style={cellWrapperStyle}><CellWrapper
                        sx={{
                            display: 'contents',
                        }}
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={notification.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    /></span>
                </>
            }
            <span> in&nbsp;</span>
            <span style={{ fontWeight: 'bold' }}>{notification.taskName}-</span>
            <span style={{ fontWeight: 'bold' }}>{notification.actionOnEntityData}</span>
        </NotificationStack>
    )
}