
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import React from 'react';

interface ExtentionMenuTitleProps {
    text: string;
}

export const ExtentionMenuTitle: React.FC<ExtentionMenuTitleProps> = ({ text }) => {
    return (
        <Typography style={{
            fontWeight: 600,
            backgroundColor: 'var(--manage-extension-menu-headers-bg)',
            fontSize: '11px',
            color: 'var(--primary)',
            lineHeight: '23px',
            paddingInlineStart: '19px',
        }}>{text}</Typography>
    );
};



export const ExtentionMenuIconButton = (
    { svg, label, onClick, filter = 'grayscale(0%)', width = '45px', height = '45px' }:
        { svg: string; label: string; onClick: () => void; filter?: string, width?: string, height?: string }) => {
    return (
        <Button
            onClick={onClick}
            sx={{
                maxWidth: "130px",
                padding: "20px",
                '&:hover': {
                    backgroundColor: 'rgba(13, 122, 169, 0.07)', // Correct syntax for RGBA in JSX
                },
            }} >
            <Stack
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <img style={{ width, height, filter: filter }} src={svg} alt={`${svg}`} />
                <p style={{
                    marginTop: '5px',
                    marginBottom: '0px',
                    fontSize: '13px',
                    lineHeight: '16px',
                    textTransform: 'none',
                    color: 'var(--primary)',
                }}>{label}</p>
            </Stack>
        </Button>
    )
}