import { useDispatch, useSelector } from 'react-redux';
import BTInputField from "@components/bt-input-field/bt-input-field";
import { Board } from "@models/board";
import { Paper, Typography, Button } from "@mui/material";
import { Stack, Box } from "@mui/system";
import { getAllBoards } from "@reducers/BoardsSelectors";
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { recycleActions } from '@reducers/RecycleBinReducer';
import { fetchArchivedOrDeletedData } from '@reducers/TaskThunks';

type FilterOption = string | { name: string; id: string };

export const ArchivedOrDeletedFilterMenu = () => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(state => state.RecycleBinReducer.filters);
    const prevFiltersRef = useRef(filters);

    const boards: Board[] = useAppSelector(getAllBoards);
    const searchText = useAppSelector((state) => state.RecycleBinReducer.searchText);
    const currentTab = useAppSelector((state) => state.RecycleBinReducer.currentTab);
    const [boardSearchTerm, setBoardSearchTerm] = useState("");
    const filteredBoards = boards.filter(board =>
        board.name.toLowerCase().includes(boardSearchTerm.toLowerCase())
    );
    const boardOptions = filteredBoards.map(board => ({ name: board.name, id: board._id}));

    const handleFilterToggle = (field: keyof typeof filters, value: string) => {
        let updatedFilters = { ...filters };

        if (field === 'date') {
            updatedFilters.date = filters.date === value ? '' : value;
        } else {
            const isActive = filters[field].includes(value);
            updatedFilters[field] = isActive
                ? filters[field].filter(item => item !== value)
                : [...filters[field], value];
        }

        dispatch(recycleActions.setFilters(updatedFilters));
    }


    const renderFilterButtons = (field: keyof typeof filters, options: FilterOption[]) => {
        return options.map((option, index) => {
            const displayName = typeof option === 'string' ? option : option.name;
            const value = typeof option === 'string' ? option : option.id;
            const isOutlined = !filters[field].includes(value);

            return (
                <Button
                    key={index}
                    variant={isOutlined ? 'outlined' : 'contained'}
                    sx={{
                        width: field !== "board" ? "150px" : "100%",
                        textTransform: "capitalize",
                        ...(isOutlined ? {
                            borderColor: '#f7f7f7',
                            background: "#f7f7f7",
                            color: "#000",
                            '&:hover': {
                                borderColor: '#ebebeb',
                                background: "#ebebeb",
                            }
                        } : {})
                    }}
                    onClick={() => handleFilterToggle(field, value)}
                >
                    {displayName}
                </Button>
            );
        });
    }



    const executeFilter = () => {
        dispatch(recycleActions.setArchivedOrDeletedData([]));
        dispatch(recycleActions.setArchivedOrDeletedBulkItems([]));

        dispatch(fetchArchivedOrDeletedData({
            mode: currentTab,
            itemId: "",
            itemUpdatedAt: "",
            searchQuery: searchText,
            ...filters,
            showLoader: true

        }));
    };

    useEffect(() => {
        if (JSON.stringify(prevFiltersRef.current) !== JSON.stringify(filters)) {
          executeFilter();
          prevFiltersRef.current = filters;
        }
      }, [filters]);

    return (
        <Paper sx={{ padding: 4, justifyContent: "space-between", width: "650px", height: "400px" }}>
            <Stack direction="row" justifyContent={"space-between"} pb={2}>
                <Box>Filters</Box>
                <Button
                    onClick={() => {
                        dispatch(recycleActions.setFilters({
                            type: [],
                            date: '',
                            board: [],
                        }));
                        setBoardSearchTerm("");
                    }}
                    disabled={filters.board.length === 0 && filters.type.length === 0 && filters.date === ""}
                    sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "grey"
                    }}
                >
                    Clear Filters
                </Button>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack spacing={2} alignItems="flex-start">
                    <Typography>Type:</Typography>
                    {renderFilterButtons('type', ['Board', 'Group', 'Task','Sub Task', 'Column'])}
                </Stack>
                <Stack spacing={2} alignItems="flex-start">
                    <Typography>Date:</Typography>
                    {renderFilterButtons('date', ['Today', 'Yesterday', 'This Week', 'Last Week', 'This Month'])}
                </Stack>
                <Stack spacing={2} alignItems="flex-start">
                    <Typography>Board:</Typography>
                    <BTInputField
                        sx={{ marginInlineStart: '10px' }} type="text"
                        value={boardSearchTerm}
                        onChange={e => setBoardSearchTerm(e.target.value)}
                        placeholder="Search boards..."
                    />
                    <Stack height={"250px"} width="100%" spacing={2} sx={{
                        overflowY: "scroll",
                        scrollBehavior: "smooth",
                        pr: 1,
                        '&::-webkit-scrollbar': {
                            width: '3px',
                        },
                        '&::-webkit-scrollbar-track': {
                            borderRadius: '3px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'darkgrey',
                            borderRadius: '10px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: 'grey',
                        },
                    }}>
                        {renderFilterButtons('board', boardOptions)}
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
}

