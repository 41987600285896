import { Box, Stack } from "@mui/system";
import { automation } from "@reducers/AutomationReducer";
import { getColumnsByType, selectAllColumns } from "@reducers/AutomationsSelectors";
import { getSelectedBoardId } from "@reducers/BoardsSelectors";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddAndRemove from "../add-and-remove";
import AutomationButton from "../automation-button";
import PickerColumn from "../dropdowns/column-dropdown";
import { setCurrentAction } from "../automations-helper";

const SetDateToTodayAction = ({ index }) => {
    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = useState(null);
    const prefix = index === 0 ? 'Then' : 'and then';
    const [columnName, setColumnName] = useState<string>('Date');
    const actions = useAppSelector((state) => state.AutomationReducer.actions);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const filteredColumns = useAppSelector((state) => selectAllColumns(state, boardId));
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    const typesToFilter = ['datepicker'];

    const columnSelector = getColumnsByType(boardId, typesToFilter);

    const columns = useSelector(columnSelector);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleColumnChange = (id, name) => {
        dispatch(automation.setActions(setCurrentAction(actions, index, { actionType: actions[index].actionType, columnId: id})));
        setColumnName(name);
        handleClose()
    };


    useEffect(() => {
        if (actions) {
            const selectedColumnId = actions[index].columnId;
            const column = filteredColumns.find(item => item.id === selectedColumnId);
            setColumnName(column.name ?? "Date")
            dispatch(automation.fieldValidityChanged({ component: 'SetDateToTodayAction', index, field: 'columnId', isValid: !!actions[index].columnId }));
        }
    }, [actions, dispatch, filteredColumns, index]);

    return (
        <Stack sx={{ fontSize: '24px' }} direction="row" justifyContent={"space-between"}>
            <Box>
                {prefix} set
                <AutomationButton
                    componentName='SetDateToTodayAction'
                    index={index}
                    option='columnId'
                    label={columnName}
                    handleClick={handleClick}
                    validationStates={validationStates}
                    attemptedSubmission={attemptedSubmission}
                /> date to today
            </Box>
            <AddAndRemove elements={actions} index={index} type={"actions"} componentName="SetDateToTodayAction" />
            <PickerColumn
                anchorEl={anchorEl}
                handleClose={handleClose}
                filteredColumns={columns}
                handleColumnChange={handleColumnChange}
            />

        </Stack>
    );
};

export default SetDateToTodayAction;
