import React from 'react'

type Props = {
  titleString: string;
}

export default function CampaignFinishTabTitle
  ({
    titleString,
  }: Props) {
  return (
    <span style={{
      borderRadius: '8px',
      backgroundColor: 'var(--campaign-finish-tab-title-bg)',
      color: 'var(--campaign-finish-tab-title-text)',
      fontWeight: 600,
      fontSize: '24px',
      padding: '5px 10px 7px 10px',
    }}>
      {titleString}
    </span>
  )
}