import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NullableId } from "./backend-api/backend-api";
import { actions, conditions, AutomationTrigger } from "@models/automation";

interface State {
  selectedAutomationId:NullableId;
  selectedAutomationOwner?: any;
  isShown:boolean;
  trigger: AutomationTrigger;
  conditions: conditions[];
  actions: actions[];
  loader:Boolean;
  activeView:string;
  deletedConditions:string[];
  deletedActions:string[];
  validationStates:any;
  attemptedSubmission: boolean,
}

const initialState: State = {
  selectedAutomationId:"",
  selectedAutomationOwner:"",
  isShown:false,
  trigger: {triggerType:""},
  conditions: [],
  actions: [{ actionType: "" }],
  loader:false,
  activeView:"automationsList",
  deletedConditions:[],
  deletedActions:[],
  validationStates:{},
  attemptedSubmission: false,

};
const automationSlice = createSlice({
  name: "automationSlice",
  initialState,
  reducers: {
    setSelectedAutomationId(state, { payload }: PayloadAction<any>) {
      state.selectedAutomationId = payload;
    },
    setSelectedAutomationOwner
    (state, { payload }: PayloadAction<any>) {
      state.selectedAutomationOwner = payload;
    },

    setIsShown(state, { payload }: PayloadAction<any>) {
      state.isShown = payload;
    },
    setActions(state, { payload }: PayloadAction<any>) {
      state.actions = payload;
    },
    setTrigger(state, { payload }: PayloadAction<any>) {
      state.trigger = payload;
    },
    setConditions(state, { payload }: PayloadAction<any>) {
      state.conditions = payload;
    },
    setLoader(state, { payload }: PayloadAction<any>) {
      state.loader = payload;
    },
    setActiveView(state, { payload }: PayloadAction<any>) {
      state.activeView = payload;
    },
    setDeletedConditions(state, { payload }: PayloadAction<any>) {
      state.deletedConditions.push(payload);
    },
    setDeletedActions(state, { payload }: PayloadAction<any>) {
      state.deletedActions.push(payload);
    },
    setAttemptedSumbission(state, { payload }: PayloadAction<any>) {
      state.attemptedSubmission = payload;
    },
    resetValidationStates(state, { payload }: PayloadAction<any>) {
      state.validationStates = payload;
    },
    resetAutomationState(state) {
      state.selectedAutomationId = null;
      state.trigger = {triggerType:""};
      state.actions = [{ actionType: "" }];
      state.conditions = [];
      state.activeView = "automationsList"
      state.deletedActions = [];
      state.deletedConditions = [];
      state.validationStates = {};
      state.selectedAutomationOwner = {};
      state.attemptedSubmission = false;
    },
    fieldValidityChanged(state, action)  {
      const { component, index, field, isValid } = action.payload;
      state.validationStates[`${component}_${index}`] = {
          ...(state.validationStates[`${component}_${index}`] || {}),
          [field]: isValid,
      };
  },
  removeFieldValidity: (state, action: PayloadAction<{component: string, index: number}>) => {
    const { component, index } = action.payload;
    const key = `${component}_${index}`;
    delete state.validationStates[key];
},
  },
});

export const automation = automationSlice.actions;
export default automationSlice.reducer;
