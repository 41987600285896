import { Box, Stack } from "@mui/system";
import { automation } from "@reducers/AutomationReducer";
import { selectOptionPickersColumns } from "@reducers/AutomationsSelectors";
import { getSelectedBoardId } from "@reducers/BoardsSelectors";
import { taskColumnOptionSelectByQuery } from "@reducers/DBServiceReducers";
import { NullableId } from "@reducers/backend-api/backend-api";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { RootState } from '@store/index';
import React, { useEffect } from "react";
import AddAndRemove from '../add-and-remove';
import AutomationButton from "../automation-button";
import PickerColumn from "../dropdowns/column-dropdown";
import ColumnOptionsSelector from "../dropdowns/options-dropdown";
import { setCurrentTrigger } from "../automations-helper";

const StatusChangesTrigger = () => {
    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [columnId, setColumnId] = React.useState<NullableId>(null);
    const [columnName, setColumnName] = React.useState<string>('status');
    const [optionName, setOptionName] = React.useState<string>('something');
    const [dropdown, setDropdown] = React.useState<string>('pickType');
    const validationStates = useAppSelector((state) => state.AutomationReducer.validationStates);
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);
    const triggerObj = useAppSelector((state) => state.AutomationReducer.trigger);
    const conditions = useAppSelector((state) => state.AutomationReducer.conditions);
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const filteredColumns = useAppSelector((state) => selectOptionPickersColumns(state, boardId));



    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, dropdownOption) => {
        setAnchorEl(event.currentTarget);
        setDropdown(dropdownOption);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const columnOptions = useAppSelector((state: RootState) => taskColumnOptionSelectByQuery(state.DBTaskColumnOptionReducer, {
        boardId,
        columnId,
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }))

    const handleColumnChange = (id, name, type) => {
   
        dispatch(automation.setTrigger(setCurrentTrigger(triggerObj, { columnId: id})));

        setColumnName(name);
        setOptionName('something')
        setColumnId(id);
        handleClose();
    };

    const clickedOption = (option) => {
        dispatch(automation.setTrigger(setCurrentTrigger(triggerObj, {optionId: option._id})));
        setOptionName(option.label)
        handleClose();
    };

    useEffect(() => {
        if (triggerObj) {
            const selectedColumnId = triggerObj.columnId;
            const column = filteredColumns.find(item => item.id === selectedColumnId);
            setColumnId(column?.id);
            setColumnName(column?.name ?? "status")
            dispatch(automation.fieldValidityChanged({ component: 'StatusChangesTrigger', index: 0, field: 'columnId', isValid: !!column?.name }));

            const selectedOptionSelectedId = triggerObj.optionId;
            const optionSelected = columnOptions.find(item => item._id === selectedOptionSelectedId);
            setOptionName(optionSelected?.label ?? "something")
            dispatch(automation.fieldValidityChanged({ component: 'StatusChangesTrigger', index: 0, field: 'optionId', isValid: !!optionSelected?._id }));
        }
    }, [columnOptions, triggerObj, filteredColumns, dispatch]);

    return (
        <Stack>
            <Stack sx={{ fontSize: "24px" }} direction="row" alignItems={"flex-start"} justifyContent={"space-between"}>
                <Box>
                    When
                    <AutomationButton
                        componentName='StatusChangesTrigger'
                        index={0}
                        option='columnId'
                        label={columnName}
                        handleClick={(e) => handleClick(e, 'pickerColumn')}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    /> changes to
                    <AutomationButton
                        componentName='StatusChangesTrigger'
                        index={0}
                        option='optionId'
                        label={optionName}
                        handleClick={(e) => handleClick(e, 'columnOptions')}
                        validationStates={validationStates}
                        attemptedSubmission={attemptedSubmission}
                    />
                </Box>
                <AddAndRemove elements={conditions} index={0} type="trigger" componentName="StatusChangesTrigger" />
            </Stack>

            {dropdown === 'pickerColumn' && (
                <PickerColumn
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    filteredColumns={filteredColumns}
                    handleColumnChange={handleColumnChange}
                />)}

            {dropdown === 'columnOptions' && (
                <ColumnOptionsSelector boardId={boardId} columnId={columnId} onSelectOption={clickedOption} anchorEl={anchorEl}
                    handleClose={handleClose}
                />)}

        </Stack>


    )
}
export default StatusChangesTrigger