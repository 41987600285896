import { Dialog, DialogContent, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { automation } from '@reducers/AutomationReducer';
import { useAppDispatch, useAppSelector } from "@store/hooks";

import Automation from './automation-create-or-update';
import AutomationsList from './automations-list';

const AutomationPanel = () => {
    const dispatch = useAppDispatch();
    const activeView =  useAppSelector((state) => state.AutomationReducer.activeView);
  
    const closeDialog = () => {
        dispatch(automation.setIsShown(false));
        dispatch(automation.resetAutomationState());
    }
    return (
        <Dialog sx={{ "& .MuiPaper-root": { height: "100%" } }} onClose={closeDialog} fullWidth maxWidth="xl" open={true}>
            <DialogContent sx={{ p: 4, height: '100%' }}>
                <Box sx={{ width: '100%' }}>

                    {activeView === "automationsList" &&
                    <AutomationsList/>
                    }

                    {activeView === "currentAutomation" &&
                        <Stack spacing={2}>
                        
                            <Automation />
                        </Stack>
                    }

                </Box>
            </DialogContent>
        </Dialog >
    );
};

export default AutomationPanel;
