import { ThemeProvider, createTheme } from '@mui/material'
import { useAppSelector } from '@store/hooks'
import React, { PropsWithChildren, useMemo } from 'react'
import { lightTheme, darkTheme } from 'theme';

type Props = {}


// This function will be responsible for merging the datePicker styles with the existing themes
const getThemeWithDatePickerStyles = (baseTheme, mode) => {
  return createTheme({
    ...baseTheme, // spread the existing theme
    palette: {
      ...baseTheme.palette,
      mode: mode, // set the mode based on the themeMode
    },
    components: {
      ...baseTheme.components, // spread the existing component overrides
      // Add your DatePicker style overrides here
      MuiPickersDay: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#53bdeb!important'
            },
          },
      
        },
      },
    },
  });
};

export default function BTThemeProvider({ children }: PropsWithChildren<Props>) {
  const themeMode = useAppSelector(state => state.WhatsAppReducer.theme);

  // Use useMemo to avoid unnecessary recalculations
  const theme = useMemo(() => {
    const baseTheme = themeMode === 'light' ? lightTheme : darkTheme;
    return getThemeWithDatePickerStyles(baseTheme, themeMode);
  }, [themeMode]);

  return (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  )
}