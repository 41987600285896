import { Box } from '@mui/system'
import { btQuickPanelActions } from '@reducers/BtQuickPanelReducer'
import { useAppDispatch } from '@store/hooks'
import { useEffect } from 'react'


function QuickMessage() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(btQuickPanelActions.setTargetComponent(""))
    }
  }, [dispatch])



  return (
    <Box>QuickMessage</Box>
  )
}

export default QuickMessage