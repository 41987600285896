import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { NotificationStack } from './notification-row';

type Props = {
    notificationId: string;
}

export default function RatingColumnNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))
    
    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === notification.notificationUpdateType
    );

    return (
        <NotificationStack direction='row'>
            <>
                <span>{notification.actionType}</span>
                <span style={{
                    display: 'inline-flex',
                    height: '20px',
                    marginTop: '-13px',
                }}>
                    <CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={notification.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                </span>
                <span>&nbsp;to</span>
                <span style={{
                    display: 'inline-flex',
                    height: '20px',
                    marginTop: '-13px',
                }}>
                    <CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={notification.newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                </span>
            </>
            <span>in</span>
            <span style={{ fontWeight: 'bold' }}>{notification.taskName}-</span>
            <span style={{ fontWeight: 'bold' }}>{notification.actionOnEntityData}</span>
        </NotificationStack>
    )
}