import { DBNewTabFocusThunks } from "@reducers/DBServiceReducers";
import { selectNewTabFocusByOwnerId } from "@reducers/NewTabFocusThunks";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useEffect, useState } from "react";
import { ClockUI } from "./focus-timer-or-stopwatch";

export function Stopwatch() {
    const dispatch = useAppDispatch();
    const tabData = useAppSelector(selectNewTabFocusByOwnerId);
    const [time, setTime] = useState({ seconds: 0, minutes: 0, hours: 0 });
    const [running, setRunning] = useState(tabData?.stopwatchRunning || false);
    const [startTime, setStartTime] = useState(tabData?.stopwatchStartTime || null);
    const [pausedTime, setPausedTime] = useState(tabData?.stopwatchPausedTime || 0);
    const [intervalId, setIntervalId] = useState(null);
  
    const calculateElapsedTime = () => {
      const now = new Date().getTime();
      const elapsedMilliseconds = running ? now - startTime + pausedTime : pausedTime;
  
      return {
        hours: Math.floor(elapsedMilliseconds / 3600000),
        minutes: Math.floor((elapsedMilliseconds % 3600000) / 60000),
        seconds: Math.floor((elapsedMilliseconds % 60000) / 1000)
      };
    };
  
    // Function to start or resume the stopwatch
    const handleStart = () => {
      if (!running) {
        setStartTime(new Date().getTime());
        setRunning(true);
        saveStopwatchStateToDB(new Date().getTime(), true, pausedTime);
      }
    };
  
    // Function to pause the stopwatch
    const handlePause = () => {
      if (running) {
        const now = new Date().getTime();
        const newPausedTime = now - startTime + pausedTime;
        setPausedTime(newPausedTime);
        setRunning(false);
        clearInterval(intervalId);
        saveStopwatchStateToDB(startTime, false, newPausedTime);
      }
    };
  
    // Function to save stopwatch state to Redux and DB
    const saveStopwatchStateToDB = (startTime, running, pausedTime) => {
      dispatch(DBNewTabFocusThunks.patch({
        entity: {
          _id: tabData._id,
          stopwatchStartTime: startTime,
          stopwatchRunning: running,
          stopwatchPausedTime: pausedTime
        }
      }));
    };
  
  
    // Function to handle reset
    const handleReset = () => {
      setTime({ seconds: 0, minutes: 0, hours: 0 });
      setRunning(false);
      setStartTime(null);
      setPausedTime(0);
      clearInterval(intervalId);
      saveStopwatchStateToDB(null, false, 0);
    };
  
    // Function to handle time edit (if needed)
    const handleTimeEdit = (editedTime) => {
      // Assuming editedTime is an object { hours, minutes, seconds }
      const totalEditedMilliseconds = (editedTime.hours * 3600000)
        + (editedTime.minutes * 60000)
        + (editedTime.seconds * 1000);
  
      setPausedTime(totalEditedMilliseconds);
  
      // If the stopwatch is running, reset the startTime to now
      if (running) {
        const currentTime = new Date().getTime();
        setStartTime(currentTime);
      } else {
        // Update the displayed time immediately if not running
        setTime({
          hours: editedTime.hours,
          minutes: editedTime.minutes,
          seconds: editedTime.seconds
        });
      }
  
      // Save the updated state to the database
      saveStopwatchStateToDB(startTime, running, totalEditedMilliseconds);
    };


    useEffect(() => {
        if (running) {
          const interval = setInterval(() => {
            setTime(calculateElapsedTime());
          }, 1000);
          setIntervalId(interval);
        } else if (intervalId) {
          clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
      }, [running, startTime, pausedTime, intervalId, calculateElapsedTime]);
    
      useEffect(() => {
        if (tabData && tabData) {
          setStartTime(tabData.stopwatchStartTime);
          setRunning(tabData.stopwatchRunning);
          setPausedTime(tabData.stopwatchPausedTime || 0);
          if (tabData.stopwatchRunning) {
            setTime(calculateElapsedTime());
          } else {
            setTime({
              seconds: Math.floor(pausedTime / 1000) % 60,
              minutes: Math.floor((pausedTime / (1000 * 60)) % 60),
              hours: Math.floor(pausedTime / (3600000))
            });
          }
        }
      }, [tabData]);
    return (
      <ClockUI
        days={0}
        hours={time.hours}
        minutes={time.minutes}
        seconds={time.seconds}
        isRunning={running}
        onStart={handleStart}
        onPause={handlePause}
        onReset={handleReset}
        onTimeEdit={handleTimeEdit}
      />
    );
  }