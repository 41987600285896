import { CellProps } from '@components/tasks/columns-cells/cell-interface';
import { Stack } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect, useState } from 'react';
import { RiCloseCircleFill } from 'react-icons/ri';
import classes from "./timeline-form-cell.module.scss";
import { namespace } from 'config/constants';
import { useTranslation } from 'react-i18next';

export default function TimelineFormCell({ cellValue, onUpdate, isFormInEditMode }: CellProps) {
    const [rangeDate, setRangeDate] = useState<{
        startDate: Date | null;
        endDate: Date | null;
    }[]>([
        {
            startDate: null,
            endDate: null,
        }
    ]);

    const handleNewDate = (newValue, typeOfDate) => {
        setRangeDate(prevRangeDate => [
            {
                ...prevRangeDate[0],
                [typeOfDate]: newValue, // newValue is a Moment object if you are using AdapterMoment
            }
        ]);
    };

    function handleClearValue(e, typeOfDate) {
        setRangeDate(prevRangeDate => [
            {
                ...prevRangeDate[0],
                [typeOfDate]: null,
            }
        ]);
        e.stopPropagation();
    }

    useEffect(() => {
        let newValue

        newValue = {
            startDate: rangeDate[0].startDate?.toISOString(),
            endDate: rangeDate[0].endDate?.toISOString(),
        }

        if (rangeDate[0].startDate && rangeDate[0].endDate) {
            if (rangeDate[0].startDate.toISOString() > rangeDate[0].endDate.toISOString()) {
                newValue = {
                    startDate: rangeDate[0].endDate,
                    endDate: rangeDate[0].startDate,
                }
            }
        }

        onUpdate(newValue)
    }, [rangeDate])

    return (
        <>
            <Stack sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Stack direction='row'>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Stack spacing={3} sx={{ width: '260px', marginRight: '5px' }}>
                            <DesktopDatePicker
                                disabled={isFormInEditMode}
                                label="Start Date"
                                format="DD/MM/YYYY"
                                value={rangeDate[0].startDate} // Expecting a Date object or a moment object
                                onChange={(newValue) => { handleNewDate(newValue, "startDate") }}
                            />
                        </Stack>
                    </LocalizationProvider>
                    {!isFormInEditMode && rangeDate[0].startDate && (
                        <RiCloseCircleFill
                            onClick={(e) => handleClearValue(e, "startDate")}
                            className={classes.clearIcon}
                        />
                    )}
                </Stack>
                <Stack direction='row' sx={{ marginTop: '15px', }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Stack spacing={3} sx={{ width: '260px', marginRight: '5px' }}>
                            <DesktopDatePicker
                                disabled={isFormInEditMode}
                                label="End Date"
                                format="DD/MM/YYYY"
                                value={rangeDate[0].endDate} // Expecting a Date object or a moment object
                                onChange={(newValue) => handleNewDate(newValue, "endDate")}
                            />
                        </Stack>
                    </LocalizationProvider>
                    {!isFormInEditMode && rangeDate[0].endDate && (
                        <RiCloseCircleFill
                            onClick={(e) => handleClearValue(e, "endDate")}
                            className={classes.clearIcon}
                        />
                    )}
                </Stack>
            </Stack>
        </>
    )
}

