import { useAppDispatch } from '@store/hooks';
import classes from "../../task-row/task_row.module.scss";
import { CellProps } from '../cell-interface';

export default function RowSuffixCell({
    taskId
}: CellProps) {
    const dispatch = useAppDispatch();

    const deleteTask = () => {
        // dispatch(modalActions.setComponentToRender(MODAL.CONFIRM_DELETE_DIALOG))
    }

    return (
        <div
            className={classes.actions_wrapper}
            style={{
                right: 0,
                width: '100%'
            }}
        >
            {/* <IconButton
                className={classes.DeleteButton}
                size="small"
                aria-label="delete row"
                component="span"
                onClick={deleteTask}
            >
                <RiDeleteBinLine />
            </IconButton> */}
        </div>
    )
}

