import PersonAvatar from '@components/tasks/person-avatar/person-avatar';
import { Stack, keyframes } from '@mui/system';
import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { setDisplayedNewNotifications } from '@reducers/MainReducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import CheckboxColumnNotification from './checkbox-column-notification';
import DateColumnNotification from './date-column-notification';
import DropdownColumnNotification from './dropdown-column-notification';
import ExtraDataNotification from './extra-data-notification';
import FilesColumnNotification from './files-column-notification';
import InviteAcceptRemoveRestoreBoardUserNotification from './invite-accept-remove-restore-board-user-notification';
import NewDeleteRestoreTaskNotification from './new-delete-task-notification';
import NotificationCreation from './notification-creation';
import classes from "./notifications-drawer.module.scss";
import NumberLinkTextPhoneColumnNotification from './number-link-text-phone-column-notification';
import OptionStatusComplexityColumnNotification from './option-status-complexity-column-notification';
import PersonColumnNotification from './person-column-notification';
import RatingColumnNotification from './rating-column-notification';
import RenameNotification from './rename-notification';
import TaskDragToNewGroupNotification from './task-drag-to-new-group-notification';
import TimelineColumnNotification from './timeline-column-notification';


type Props = {
    itemId: string;
    index: number;
}

export default function NotificationRow({
    itemId,
    index,
}: Props) {
    const dispatch = useAppDispatch()

    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: itemId,
        deleted: false
    }))

    const [seen, setSeen] = useState<boolean>(notification.seen ?? false)

    const pulsate = keyframes`
    0% {
        box-shadow: inset 0 0 0 0 rgba(0, 123, 255, 0.5);
      }
      50% {
        box-shadow: inset 0 0 10px 2px rgba(0, 123, 255, 0.7);
      }
      100% {
        box-shadow: inset 0 0 0 0 rgba(0, 123, 255, 0.5);
      }`;
    const isExtraDataNotification = ['new extra data', 'updated extra data', 'deleted extra data'].includes(notification?.notificationUpdateType)

    const NotificationData = () => {
        if (['new extra data', 'updated extra data', 'deleted extra data'].includes(notification?.notificationUpdateType) === true) {
            return (<ExtraDataNotification notificationId={notification._id} />)
        }
        if (
            [
                'task group change'
            ]
                .includes(notification?.notificationUpdateType) === true) {
            return (<TaskDragToNewGroupNotification notificationId={notification._id} />)
        }
        if (
            [
                'new task', 'delete task', 'restore task',
                'new board', 'restore board', 'delete board',
                'new group', 'restore group', 'delete group',
                'new column', 'restore column', 'delete column'
            ]
                .includes(notification?.notificationUpdateType) === true) {
            return (<NewDeleteRestoreTaskNotification notificationId={notification._id} />)
        }
        if (
            [
                'board invite', 'accept board user invite', 'remove board user', 'restore board user'
            ]
                .includes(notification?.notificationUpdateType) === true) {
            return (<InviteAcceptRemoveRestoreBoardUserNotification notificationId={notification._id} />)
        }
        if (
            ['task name', 'board name', 'group name', 'column name']
                .includes(notification?.notificationUpdateType) === true) {
            return (<RenameNotification notificationId={notification._id} />)
        }
        if (
            ['option-picker', 'status-option-picker', 'complexity-picker']
                .includes(notification?.notificationUpdateType) === true) {
            return (<OptionStatusComplexityColumnNotification notificationId={notification._id} />)
        }
        if (
            ['person-picker']
                .includes(notification?.notificationUpdateType) === true) {
            return (<PersonColumnNotification notificationId={notification._id} />)
        }
        if (
            ['datepicker']
                .includes(notification?.notificationUpdateType) === true) {
            return (<DateColumnNotification notificationId={notification._id} />)
        }
        if (
            ['number', 'link', 'text-cell', 'phone-cell']
                .includes(notification?.notificationUpdateType) === true) {
            return (<NumberLinkTextPhoneColumnNotification notificationId={notification._id} />)
        }
        if (
            ['files']
                .includes(notification?.notificationUpdateType) === true) {
            return (<FilesColumnNotification notificationId={notification._id} />)
        }
        if (
            ['checkbox']
                .includes(notification?.notificationUpdateType) === true) {
            return (<CheckboxColumnNotification notificationId={notification._id} />)
        }
        if (
            ['rating']
                .includes(notification?.notificationUpdateType) === true) {
            return (<RatingColumnNotification notificationId={notification._id} />)
        }
        if (
            ['drop-down']
                .includes(notification?.notificationUpdateType) === true) {
            return (<DropdownColumnNotification notificationId={notification._id} />)
        }
        if (
            ['timeline-picker']
                .includes(notification?.notificationUpdateType) === true) {
            return (<TimelineColumnNotification notificationId={notification._id} />)
        }
    }

    useEffect(() => {
        if (!notification.seen) {
            dispatch(setDisplayedNewNotifications(notification._id))
            setSeen(false)
        }
    }, [])


    return (
        <Stack sx={{
            background: 'var(--background-default)',
            animation: !seen && `${pulsate} 2s ease-in-out infinite`,
            margin: '10px 5px 10px 3px',
            boxShadow: '-1px 2px 0px 2px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
        }}>
            <p className={classes.board_name_label}>{notification?.boardName}:</p>
            <Stack direction='row' key={`${index}_notifications_${itemId}`} gap={1} className={classes.notification_row_wrapper} sx={{
                position: 'relative',
            }}>
                <PersonAvatar
                    style={{
                        alignSelf: isExtraDataNotification && 'flex-start',
                        marginTop: isExtraDataNotification && '25px',
                    }}
                    size={24}
                    email={notification?.triggerUser?.email}
                    profileImg={notification?.triggerUser?.profileImg}
                    lastName={notification?.triggerUser?.lastName}
                    firstName={notification?.triggerUser?.firstName}
                    toolTip={false} />
                {NotificationData()}
                <div style={{ marginLeft: 'auto' }}><NotificationCreation notificationId={notification._id} /></div>
            </Stack>
        </Stack>
    )
}



export const NotificationStack = ({ children, ...rest }) => {
    return (
        <Stack sx={{ backgroundColor: 'transparent !important' }} direction="row" flexWrap="wrap" alignItems={"center"}>{children}</Stack>
    );
};