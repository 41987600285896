import { TaskColumn } from "@models/task-column";

export const staticColumnList: TaskColumn[] = [{
  _id: 'context-menu',
  type: 'context-menu',
  title: 'context-menu',
  static: true,
  width: 46,
  outerCell: true,
  order: -30,
}, {
  _id: 'row-prefix',
  type: 'row-prefix',
  title: 'row-prefix',
  static: true,
  width: 400,
  order: -20,
}, {
  _id: 'chat',
  type: 'chat',
  title: 'Chat',
  static: true,
  order: -10,
  width: 80,
}, {
  _id: 'row-suffix',
  type: 'row-suffix',
  title: 'row-suffix',
  static: true,
  outerCell: true,
  order: 1000,
},
];

export const myWorkStaticColumnList: TaskColumn[] = [ {
  _id: 'row-prefix',
  type: 'row-prefix',
  title: 'row-prefix',
  static: true,
  width: 500,
  order: -20,
},
{
  _id: 'board-cell',
  type: 'board-cell',
  title: 'Board',
  static: true,
  outerCell: true,
  "width": 250,

  order: 1000,
},
{
  _id: 'group-cell',
  type: 'group-cell',
  title: 'Group',
  static: true,
  outerCell: true,
  "width": 250,

  order: 1000,
}
];