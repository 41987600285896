import { Board } from "@models/board";
import { MyWork } from "@models/mywork";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface State {
  myWorkPreferences:MyWork;
  myWorkIsLoading:boolean;
  myWorkViewMode:string;
  myWorkTasksFilter:any;
  myWorkBoardsIds:string[]
}

const initialState: State = {
  myWorkPreferences:{},
  myWorkIsLoading:false,
  myWorkViewMode:'board',
  myWorkTasksFilter:'',
  myWorkBoardsIds:[]
};

const myWorkSlice = createSlice({
  name: "myWorkSlice",
  initialState,
  reducers: {
    setMyWorkPrefernces(state, action: PayloadAction<any>) {
      state.myWorkPreferences = action.payload;
    },
    setMyWorkLoading(state, action: PayloadAction<boolean>) {
      state.myWorkIsLoading = action.payload;
    },
    setMyWorkViewMode(state, action: PayloadAction<string>) {
      state.myWorkViewMode = action.payload;
    },
    setMyWorkBoardsIds(state, action: PayloadAction<string[]>) {
      state.myWorkBoardsIds = action.payload;
    },
    setTasksFilterName(state, action: PayloadAction<string>) {
      state.myWorkTasksFilter = action.payload
        ? new RegExp(action.payload, "i")
        : "";
      if (state.myWorkTasksFilter === "") {
        delete state.myWorkTasksFilter;
      }
    },
  },
});

export const myWorkActions = myWorkSlice.actions;
export default myWorkSlice.reducer;

