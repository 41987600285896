import { ContactInfo } from '@models/contact-info';
import { Box, Checkbox, Stack, SxProps, Theme } from '@mui/material';
import { getIsContactChecked } from '@reducers/WhatsAppSelectors';
import { handleContactSelectionChangedThunk, toggleContactSelectionThunk } from '@reducers/WhatsappThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import React from 'react';
import ContactAvatar from './contact-avatar';
import { selectDoesContactExistInBoard } from '@reducers/TaskSelectors';

const tableCellStyle: SxProps<Theme> = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'var(--text-color)',
    fontWeight: 'bold',
}


export default function ContactTableRow(rowData: any) {
    console.log("ContactTableRow")
    const dispatch = useAppDispatch()

    const isContactChecked = useAppSelector((state) => getIsContactChecked(state, rowData.contact?.whatsappId))
    const contactExistsInBoard = useAppSelector((state) => selectDoesContactExistInBoard(state, rowData.contact?.whatsappId))

    const handleContactSelectionChanged = (isSelected: boolean, contact: ContactInfo) => {
        if (!contactExistsInBoard) {
            dispatch(handleContactSelectionChangedThunk({ contact, isSelected }))
        }
    }


    const calculatedName = (contact: ContactInfo) => {
        const _calcName = [contact?.firstName, contact?.lastName].filter(x => x).join(' ') || contact?.mobile;
        return contact?.name ?? contact?.displayName ?? contact?.shortName ?? _calcName;
    }

    const columnList = [
        { type: 'checkbox', width: '80px', dataKey: 'checkbox' },
        { type: 'avatar', width: '10%', label: '', placeholder: 'No Photo Available', path: 'avatar', dataKey: 'avatar' },
        { type: 'field', width: '80%', label: 'Display Name', placeholder: 'Set name', path: 'name', dataKey: 'name', valueFn: calculatedName },
    ];

    return (
        <Stack direction="row" key={rowData?.index}
            onClick={() => {
                if (!contactExistsInBoard) {
                    dispatch(toggleContactSelectionThunk({ contact: rowData.contact }))
                }
            }}
            sx={{
                position: 'relative',
                width: '100%',
                alignContent: 'center',
                justifyContent: 'space-between',
                height: '80px',
                cursor: contactExistsInBoard ? 'not-allowed' : 'pointer',
                '&:hover': {
                    backgroundColor: 'var(--contact-table-row-hover)',
                },
            }}>
            {contactExistsInBoard && <Box sx={{
                position: 'absolute',
                width: 1,
                height: 1,
                backgroundColor: 'rgba(255,255,255,0.7)',
                zIndex: 10,
            }} />
            }
            {columnList.map((column, i) => {
                let cellContent: React.ReactNode;
                const _value = column.valueFn ? column.valueFn(rowData?.contact) : rowData?.contact[column.path ?? ''] || undefined;
                switch (column.type) {
                    case 'field': {
                        cellContent =
                            <Box key={`displayName-${i}`} sx={{
                                ...tableCellStyle,
                                width: column.width,
                                borderRight: '1px solid var(--import-border)',
                                lineHeight: '80px',
                                paddingLeft: '15px',
                            }}>
                                {_value}
                            </Box>;
                        break;
                    }
                    case 'avatar': {
                        cellContent =
                            <Box key={`avatar-${i}`} sx={{
                                ...tableCellStyle,
                                width: column.width,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <ContactAvatar
                                    contactId={rowData?.contact?.whatsappId}
                                />
                            </Box>

                        break;
                    }
                    case 'checkbox': {
                        cellContent = <Box sx={{
                            ...tableCellStyle,
                            width: column.width,
                            maxWidth: column.width,
                            height: '100%',
                            borderLeft: column.type === 'checkbox' && '1px solid var(--import-border)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }} key={`checkbox-${i}`}>
                            <Checkbox
                                onChange={(event) => handleContactSelectionChanged(event.target.checked, rowData?.contact)}
                                checked={isContactChecked || contactExistsInBoard}
                                sx={{
                                    color: (isContactChecked || contactExistsInBoard) ? '#42d871!important' : 'grey',
                                }}
                            />
                        </Box>;
                        break;
                    }
                }
                return cellContent
            })}
        </Stack>
    )
}