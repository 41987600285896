import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { NotificationStack } from './notification-row';
import classes from "./notifications-drawer.module.scss";

type Props = {
    notificationId: string;
}

export default function CheckboxColumnNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))
    
    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === notification.notificationUpdateType
    );

    return (
        <NotificationStack direction='row'>
            <>
                <span className={classes.notification_text_element}>{notification.actionType} &nbsp; </span>
                <div style={{ maxWidth: '20px', paddingTop: '3px' }}>
                    <CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={notification.oldValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                </div>
                <span>&nbsp; to &nbsp;</span>
                <div style={{ maxWidth: '20px', paddingTop: '3px' }}>
                    <CellWrapper
                        columnId={""}
                        taskId={""}
                        groupId={""}
                        boardId={''}
                        mockValue={notification.newValue}
                        readOnly={true}
                        cellComponent={cell.cellComponent}
                    />
                </div>
            </>
            <span className={classes.notification_text_element}>&nbsp;&nbsp;in&nbsp;</span>
            <span style={{ fontWeight: 'bold' }}>{notification.taskName}-</span>
            <span className={classes.notification_text_element} style={{ fontWeight: 'bold' }}>{notification.actionOnEntityData}</span>
        </NotificationStack>
    )
}