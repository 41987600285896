import { ClickAwayListener, Popper } from "@mui/material";
import { pickerDialogActions } from "@reducers/PickerDialogReducer";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useRef } from "react";
import { CirclePicker } from "react-color";
import { groupcolors } from "theme";
import classes from "./bt-color-picker.module.scss";

type Props = {
  handleOnChange: ({ hex }) => void,
}

export default function BTColorPicker({
  handleOnChange,
}: Props) {
  const dispatch = useAppDispatch()
  const activeGroupId = useAppSelector((state) => state.PickerDialogReducer.activeGroupId)
  const colorPickerRef = useRef<HTMLDivElement>()

  const id = 'group-color-picker-popper';

  return (
    <>
      <div id='form-color-picker' ref={colorPickerRef}>
        {activeGroupId && (
          <ClickAwayListener onClickAway={() => dispatch(pickerDialogActions.setActiveGroupId(''))}>
            <div style={{ zIndex: 9999 }}>
              {colorPickerRef.current &&
                <Popper
                  id={id}
                  open={true}
                  anchorEl={colorPickerRef.current}
                  style={{ zIndex: 9999, left: 0 }}
                >
                  <div
                    className={classes.pickerContainer}
                  // style={{ borderRight: `3px solid ${currentColor || '#53bdeb'}` }}
                  // id="group_header"
                  >
                    <CirclePicker
                      onChange={handleOnChange}
                      width="540px"
                      circleSize={20}
                      circleSpacing={10}
                      colors={groupcolors}
                    />
                  </div>
                </Popper>}
            </div>
          </ClickAwayListener>
        )}
      </div>
    </>

  );
}
