import SlateEditor from '@components/tasks/extraData/editor/SlateEditor'
import NewTaskRowInput from '@components/tasks/new-task-row/new-task-row-input'
import styled from '@emotion/styled'
import { ExtraDataItem } from '@models/ExtraDataItem'
import { CustomColumnData, Task } from '@models/task'
import { CircularProgress, IconButton } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { DBTasksExtraDataThunks } from '@reducers/DBServiceReducers'
import { btQuickPanelActions } from '@reducers/BtQuickPanelReducer'
import { addNewTaskThunk } from '@reducers/TaskThunks'
import { taskActions } from '@reducers/TasksReducer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { IconMessage, IconPaperclip } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { BsCheck2All } from 'react-icons/bs'
import classes from './new-task-and-extradata.module.scss'

const ButtonIcon = styled(IconButton)({
    width: 35,
    height: 35,
    transition: '0.3s all',
    '&:hover': {
        backgroundColor: "#53BDEB",
        color: '#fff'
    }
})
const AddTask = styled(IconButton)({
    width: 35,
    height: 35,
    transition: '0.3s all',
})

const NewTaskRowInputContainer = styled(Box)(() => ({
    height: '30px',
    margin: 4,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 1
}))

const ExtradataContainer = styled(Stack)(() => ({
    position: 'relative',
    marginTop: 4,
    borderStyle: 'solid',
    borderWidth: 1
}))

const LoadinSpinnerWrap = styled(Box)(() => ({
    position: 'absolute',
    bottom: 25,
    right: 105
}))

type Props = {
    boardId: string
    groupId: string
    colorAccent: string
    customData?: Record<string, CustomColumnData>
}

export default function NewTaskAndExtradata({ boardId, groupId, colorAccent, customData }: Props) {
    const dispatch = useAppDispatch()
    const [writeUpdate, writeAnUpdate] = useState<boolean>(false)
    const [isTaskAndExtraDataLoading, setIsTaskAndExtraDataLoading] = useState<boolean>(false)
    const isNewQuickTask = useAppSelector((state) => state.BtQuickPanelReducer.isNewQuickTask)
    const isErrorInput = useAppSelector((state) => state.BtQuickPanelReducer.isErrorInput)
    const [extraDataError, setExtradataError] = useState<boolean>(false)
    const [newTaskText, setnewTaskText] = useState<string>('')

    useEffect(() => {
        return () => {
            dispatch(btQuickPanelActions.setAlertPopper({
                type: '',
                message: '',
                popperTargetId: ''
            }))
        }
    }, [dispatch])


    function onChangeText(text: string) {
        setnewTaskText(text)
    }




    function handleWriteTaskUpdate() {
        if (newTaskText) {
            writeAnUpdate(!writeUpdate)
        } else {
            dispatch(btQuickPanelActions.setIsErrorInput(true))
        }
    }


    // handler for quick task creation
    async function handleQuickTaskCreation() {
        if (!newTaskText) return
        let newTask: Task = {
            text: newTaskText,
            boardId,
            groupId,
        }

        if (customData) {
            newTask = {
                ...newTask,
                customData
            }
        }
        dispatch(btQuickPanelActions.setIsNewQuicktask(true))
        const { payload }: any = await dispatch(addNewTaskThunk({ ...newTask, isQuickTask: true }));
        if (payload._id) {
            dispatch(btQuickPanelActions.setIsNewQuicktask(false))
            dispatch(taskActions.setnewTaskText(''))
            dispatch(btQuickPanelActions.setPopperId(''))
            dispatch(btQuickPanelActions.setIsErrorInput(false))
            return payload
        }
    }


    async function createTaskWithExtradata(editorValue) {
        if (editorValue.length === 1 && !editorValue[0].children[0].text) {
            dispatch(btQuickPanelActions.setIsErrorInput(true))
            setExtradataError(true)
            setTimeout(() => {
                setExtradataError(false)
            }, 2000)
        } else {
            setIsTaskAndExtraDataLoading(true)
            const task = await handleQuickTaskCreation()
            if (task._id) {
                // create extradata
                const extraDataItem: ExtraDataItem = {
                    taskId: task._id,
                    groupId: groupId,
                    boardId: boardId,
                    author: "",
                    extraData: editorValue,
                    isUpdating: false,
                    isPinedToTop: false,
                };
                setIsTaskAndExtraDataLoading(false)
                dispatch(DBTasksExtraDataThunks.create(extraDataItem));
                writeAnUpdate(false)
                dispatch(btQuickPanelActions.setPopperId(''))
                dispatch(btQuickPanelActions.setIsErrorInput(false))
            }

        }
    }


    return (
        <Box className={classes.InputContainer}
            sx={{
                width: 414,
                height: writeUpdate ? 'auto' : 40,
                borderColor: colorAccent ?? '#00c875',
                transition: '0.3s all',
                paddingBottom: extraDataError ? '2px' : 0
            }}>
            <Box className={classes.NewTaskContainer}>
                <NewTaskRowInputContainer
                    sx={{ borderColor: isErrorInput && !newTaskText ? 'red' : 'transparent' }}>
                    <NewTaskRowInput
                        onChangeText={onChangeText}
                        textValue={newTaskText}
                        onEnter={handleQuickTaskCreation}
                        customData={customData}
                        disabled={!boardId || !groupId || isNewQuickTask}
                        outsideStyles={{
                            flex: 1,
                            height: "20px",
                            resize: "none",
                            backgroundColor: "transparent",
                            width: '100%!important',
                            marginInlineStart: 0,
                            border: '0px solid red'
                        }} />
                </NewTaskRowInputContainer>

                <Box className={classes.PanelActionsCol}>

                    <AddTask
                        onClick={handleQuickTaskCreation}
                        sx={{
                            visibility: !newTaskText || writeUpdate ? 'hidden' : 'visible',
                            color: '#53BDEB',
                        }}>
                        {isNewQuickTask ? <CircularProgress size={18} />
                            : <BsCheck2All />
                        }
                    </AddTask>

                    <ButtonIcon sx={{ mx: 1 }} >
                        <IconPaperclip size={16} />
                    </ButtonIcon>


                    <ButtonIcon id="alert-extradata-update" onClick={handleWriteTaskUpdate}
                        sx={{
                            backgroundColor: writeUpdate ? '#53BDEB' : '',
                            color: writeUpdate ? '#fff' : ''
                        }}>
                        <IconMessage size={16} />
                    </ButtonIcon>
                </Box>
            </Box>
            {writeUpdate && (
                <ExtradataContainer
                    sx={{
                        borderRadius: extraDataError ? 1 : 0,
                        transition: '0.3s all',
                        m: extraDataError ? 1 : 0,
                        borderTopColor: extraDataError ? 'red' : '#ddd',
                        borderColor: extraDataError ? 'red' : 'transparent'
                    }}>
                    <LoadinSpinnerWrap>
                        {isTaskAndExtraDataLoading && <CircularProgress color='primary' size={25} />}
                    </LoadinSpinnerWrap>
                    <SlateEditor
                        value={null}
                        onValueUpdate={createTaskWithExtradata}
                        addBorder={false}
                    />
                </ExtradataContainer>
            )}
        </Box>
    )
}

/* <FilesCell
    cellValue={[]}
    boardId=""
    groupId=""
    taskId=''
    columnId=''
    onUpdate={() => { }}
    togglePicker={() => { }}
    closePicker={()=>{}}
    openPicker={()=>{}}
    isFormInEditMode={false}
           /> */