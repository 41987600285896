import { Tab, Tabs } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { boardUserSelectOneObjectByQuery, boardsSelectors, taskgroupsSelectors, tasksSelectors } from '@reducers/DBServiceReducers'
import { setExtraDataOfTaskId } from '@reducers/ExtraDataReducer'
import { taskActions } from '@reducers/TasksReducer'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useEffect, useState } from 'react'
import { CiViewList } from "react-icons/ci"
import { IoChatbubblesOutline, IoFileTrayFullOutline } from "react-icons/io5"
import EditTaskInput from '../edit-task-input/edit-task-input'
import MessageThread from './MessageThread'
import NewExtraData from './NewExtraData'
import TaskSubscribersDialog from './task-subscribers-dialog'
import classes from "./extraData.module.scss"
import { ExtraDataFiles } from './extraDataFiles'
import ExtraDataPanelLog from './logs/ExtraDataPanelLog'
import PersonAvatar from '../person-avatar/person-avatar'
import { getSelectedBoardId } from '@reducers/BoardsSelectors'
import { Board } from '@models/board'
import RowStack from '@components/common/row-stack/RowStack'


type Props = {}

export default function ExtraDataPanelChat({ }: Props) {
    const dispatch = useAppDispatch();
    const displayExtraDataOfTaskById = useAppSelector(
        (state) => state.ExtraDataReducer.displayExtraDataOfTaskById
    );
    const [currentTab, setCurrentTab] = useState(0);
    const isTaskModalOpen = useAppSelector((state) => state.TasksReducer.isTaskModalOpen);
    const task = useAppSelector((state) =>
        tasksSelectors.selectById(state, displayExtraDataOfTaskById)
    );
    const group = useAppSelector((state) =>
        taskgroupsSelectors.selectById(state, task?.groupId)
    );

    const user = useAppSelector((state) => boardUserSelectOneObjectByQuery(
        state.DBBoardUserReducer,
        { userId: task.createdBy }
    ))

    const currentBoardView = useAppSelector((state) => state.TasksReducer.currentBoardView)

    const selectedBoardId = useAppSelector(getSelectedBoardId);
    const board: Board = useAppSelector((state) =>
        boardsSelectors.selectById(state, selectedBoardId)
    );

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };


    useEffect(() => {
        if (!task) {
            dispatch(taskActions.setTaskViewSideDrawerOpenWith(""));
            dispatch(setExtraDataOfTaskId(null))
        }

    }, [task])

    return (
        <>

            {
                !isTaskModalOpen &&
                <Stack spacing={1} className={classes.task_name} sx={{ overflow: "hidden", padding: "0 30px 15px", background: "var(--default-background)" }} >
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Box sx={{ color: group?.colorAccent, fontSize: "15px", fontWeight: "600", fontFamily: "Poppins", padding: "0 5px" }}>
                            {group?.name}
                        </Box>
                        {user &&
                            <RowStack>
                                {board?.displayBetaFeatures && currentBoardView.type === 'board' &&
                                    <TaskSubscribersDialog />
                                }

                                <PersonAvatar textSize={10} size={24} email={user.email}

                                    profileImg={user?.profile.profileImg}
                                    lastName={user?.profile.lastName}
                                    firstName={user?.profile.firstName} />
                            </RowStack>

                        }

                    </Stack>

                    <EditTaskInput taskId={task?._id}
                        isMultiline={true}

                        rowsMax={6}
                        boxSx={{
                            fontSize: '24px',
                            fontFamily: 'Poppins',
                            wordWrap: "break-word",
                            whiteSpace: "normal"
                        }} inputSx={{
                            fontSize: '24px',
                            fontFamily: 'Poppins',
                        }}
                    />
                </Stack>
            }


            <Tabs
                TabIndicatorProps={{ style: { backgroundColor: "#FDAB3D" } }}
                sx={{
                    padding: "0 30px",
                    borderBottom: "1px solid lightgrey",
                    background: "var(--default-background)",
                    "& .MuiTabs-flexContainer": { background: "var(--default-background)" },
                    "& .MuiTab-labelIcon.Mui-selected": { fontWeight: "600" },
                    "& .MuiTabs-root": { minHeight: "45px" },
                    "& .MuiTab-root.Mui-selected": { color: "var(--primary)", fontWeight: 600 },
                    "& .MuiTab-root": { fontFamily: "Poppins", textTransform: "capitalize", color: "#809BA8", minHeight: "50px" }
                }} value={currentTab} onChange={handleTabChange} aria-label="extra data tabs">
                <Tab icon={<IoChatbubblesOutline />} iconPosition="start" label="Updates" />
                <Tab icon={<IoFileTrayFullOutline />
                } iconPosition="start" label="Files" />

                {board?.displayBetaFeatures &&
                    <Tab icon={<CiViewList />} iconPosition="start" label="Logs" />
                }

            </Tabs>

            <div style={{ padding: "0 30px", background: "var(--extradata-background-color)", height: `calc(100vh - ${isTaskModalOpen ? "270px" : "220px"})`, overflow: "auto" }} className={classes.body}>
                {currentTab === 0 && (<>
                    <NewExtraData />
                    <div className={classes.render_threads_comp}>
                        <MessageThread />
                    </div>
                </>)}

                {currentTab === 1 && <ExtraDataFiles />}
                {currentTab === 2 && <ExtraDataPanelLog />}
            </div>
        </>
    )
}