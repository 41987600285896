import unavailable from "@assets/unavailable.png"
import BTButton from '@components/bt-button/bt-button'
import { Stack, Typography } from '@mui/material'
import { modalActions } from '@reducers/ModalReducer'
import { switchWorkspace } from '@reducers/UserReducer'
import { selectCurrentUserWorkspace } from '@reducers/UserSelectors'
import { useRouter } from '@reducers/hooks/use-router'
import { useAppDispatch, useAppSelector } from '@store/hooks'

function FeatureUnavailableDialog() {
    const dispatch = useAppDispatch()
    const workspaces = useAppSelector(selectCurrentUserWorkspace)
    const router = useRouter();

    const switchToCurrentUserWorkspace = async () => {

        await dispatch(switchWorkspace({ workspaceId:workspaces._id }))

        router.navigate({
            pathname: 'tasks',
            search: ''
        })

        dispatch(modalActions.setComponentToRender(null))
        
    }


    return (
        <Stack px={6} py={4} spacing={2} alignItems={"center"}>
            <Typography variant="h6" fontWeight={"700"} textAlign={"center"}>This Feature is available only to workspace users.</Typography>
            <Typography variant="body1">You are a guest on this workspace.</Typography>

            <img style={{marginLeft:"45px"}} src={unavailable} width="350" height="300" alt="unavailable" />
             
               <BTButton sx={{width:"70%",boxShadow:"none"}} onClick={switchToCurrentUserWorkspace} variant="contained" color="primary"  >
                Switch to your workspace
            </BTButton>
        </Stack>
    )
}

export default FeatureUnavailableDialog