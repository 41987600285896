import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import { Button } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { handleActionOptionSelected, removeEmptyActions } from '@reducers/AutomationThunks';
import { selectActionsLength } from '@reducers/AutomationsSelectors';
import { useAppDispatch, useAppSelector } from "@store/hooks";
import React, { useEffect, useState } from "react";
import { AiOutlineNotification, AiOutlineOrderedList } from 'react-icons/ai';
import { BiDuplicate } from "react-icons/bi";
import { BsTrash3 } from "react-icons/bs";
import { HiOutlineArchiveBoxArrowDown } from "react-icons/hi2";
import { MdOutlineLowPriority, MdOutlineToday } from 'react-icons/md';
import CustomDropdown from "./automation-dropdown";
import { errorStyle, buttonStyle } from './automations-helper';


const AutomationActions = ({ index }) => {
    const dispatch = useAppDispatch();
    const [text, setActionText] = useState(index === 0 ? 'Then do this' : 'and then do this');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOptionClicked, setOptionClicked] = useState<boolean>(false);
    const [isHover, setHoverState] = useState<boolean>(false);

    const actionsLength = useAppSelector(selectActionsLength);
    const trigger = useAppSelector((state) => state.AutomationReducer.trigger);
    const attemptedSubmission = useAppSelector((state) => state.AutomationReducer.attemptedSubmission);

    const hoverStyle = isHover ? { color: "#0072ff" } : {}
    const ref = React.useRef();

    const actionsOptions = trigger.triggerType !== 'every-time-period' ?
    [
        { label: 'Move task to group', name: 'move-task-to-group', icon: <MoveUpIcon fontSize="small" /> },
        { label: 'Notify person', name: 'notify-person', icon: <AiOutlineNotification fontSize="small" /> },
        { label: 'Assign person', name: 'assign-person', icon: <AssignmentIndIcon fontSize="small" /> },
        { label: 'Change status', name: 'change-status', icon: <MdOutlineLowPriority fontSize="small" /> },
        { label: 'Create task update', name: 'create-task-update', icon: <MdOutlineLowPriority fontSize="small" /> },
        { label: 'Create task', name: 'create-task', icon: <MdOutlineLowPriority fontSize="small" /> },
        { label: 'Create sub task', name: 'create-sub-task', icon: <MdOutlineLowPriority fontSize="small" /> },
        { label: 'Create group', name: 'create-group', icon: <MdOutlineLowPriority fontSize="small" /> },
        { label: 'Clear column', name: 'clear-column', icon: <MdOutlineLowPriority fontSize="small" /> },
        { label: 'Duplicate task', name: 'duplicate-task', icon: <MdOutlineLowPriority fontSize="small" /> },
        { label: 'Archive task', name: 'archive-task', icon: <HiOutlineArchiveBoxArrowDown fontSize="small" /> },
        { label: 'Delete task', name: 'delete-task', icon: <BsTrash3 fontSize="small" /> },
        { label: 'Duplicate group', name: 'duplicate-group', icon: <BiDuplicate fontSize="small" /> },
        { label: 'Set date to today', name: 'set-date-to-today', icon: <MdOutlineToday fontSize="small" /> },
        { label: 'Increase or decrease number value', name: 'increase-or-decrease', icon: <AiOutlineOrderedList fontSize="small" /> },
        { label: 'Clear assignees', name: 'clear-assignees', icon: <AiOutlineOrderedList fontSize="small" /> },
        { label: 'Create board', name: 'create-board', icon: <AiOutlineOrderedList fontSize="small" /> },
        { label: 'Create task in board', name: 'create-task-in-board', icon: <AiOutlineOrderedList fontSize="small" /> },
    ] : [
        { label: 'Create task', name: 'create-task', icon: <MdOutlineLowPriority fontSize="small" /> },
        { label: 'Create group', name: 'create-group', icon: <MdOutlineLowPriority fontSize="small" /> },
        { label: 'Duplicate group', name: 'duplicate-group', icon: <BiDuplicate fontSize="small" /> },
    ];

    const handleClose = () => {
        setAnchorEl(null);
        if (isOptionClicked) {
            setOptionClicked(true);
        } else {
            dispatch(removeEmptyActions());
            setOptionClicked(false);
        }
    };

    const openDropdownMenu = () => {
        setAnchorEl(ref.current);
    };

    const handleOptionSelect = (option) => {
        dispatch(handleActionOptionSelected({ name: option.name }));
        setOptionClicked(true);
    };

    const onHover = (option, show) => {
        if (show) {
            const prefix = index === 0 ? 'Then' : 'and then'
            setActionText(prefix + " " + option.label);
            setHoverState(true);
        } else {
            setActionText(index === 0 ? 'Then do this' : 'and then do this')
            setHoverState(false);

        }
    };

    useEffect(() => {
        if (actionsLength > 1) {
            openDropdownMenu();
        }
    }, [actionsLength]);


    return (
        <Stack key={0} direction="row" justifyContent={"space-between"}>
            <Box ref={ref}>
                <Button disabled={!trigger.triggerType} sx={{
                    ...buttonStyle,
                    ...hoverStyle,
                    ...(attemptedSubmission ? errorStyle : {})
                }} onClick={openDropdownMenu} >{text}</Button>
            </Box>
            <CustomDropdown
                options={actionsOptions}
                anchorEl={anchorEl}
                onClose={handleClose}
                onOptionSelect={handleOptionSelect}
                onHoverOption={onHover}
            />
        </Stack>
    );
};

export default AutomationActions;



