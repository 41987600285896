import { Log } from '@models/logs';
import { logsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import React from 'react'
import { BsFillGearFill } from 'react-icons/bs';
import { getUserForLogs } from '@reducers/TaskSelectors';
import { PersonAvatar } from '../person-avatar/person-avatar';

type Props = {
    itemId: string;
    size: number;
    isUser: boolean;
    ifBoth?: 'old' | 'new';
}

export default function AvatarGenerator({
    itemId,
    size,
    isUser,
    ifBoth,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: itemId }))

    let value = {
        _id: undefined,
        firstName: undefined,
        lastName: undefined,
    }
    switch (log?.actionType) {
        case ('changed'):
            value = ifBoth === 'new' ? log?.newValue : log?.oldValue
            break
        case ('assigned'):
            value = log?.newValue
            break
        case ('removed'):
            value = log?.oldValue
            break
    }
    value = isUser ? { _id: log?.triggerById, firstName: undefined, lastName: undefined } : value
    const user = useAppSelector((state) => getUserForLogs(state, value))

    return (
        <>
            {value?._id &&
                <PersonAvatar
                    size={size}
                    email={user?.email}
                    profileImg={user?.profileImg}
                    lastName={user?.lastName}
                    firstName={user?.firstName}
                />
            }
            {!value?._id &&
                <BsFillGearFill />

            }
        </>
    )
}