import React, { useEffect, useState } from "react";
import classes from "./webbook-guide-view.module.scss";
import { Box, Button, Link, Paper, Stack, Typography } from "@mui/material";
import { appConfig } from "config/app.config";

export default function WebHookGuideMake() {
  return (
    <Stack sx={{ padding: 5, maxWidth: "1200px", margin: "0 auto" }}>
      <div
        style={{
          textAlign: "center",
          marginBottom: "24px",
        }}
      >
        <Stack direction="row" alignItems={"center"} justifyContent={"center"}>
          <img alt="logo" style={{ width: "250px", height: "80px" }} src="img/logo.png"></img>
          <Box fontSize={"80px"} padding={"0 16px"}>+</Box>
          <img alt="logo" style={{ width: "250px", height: "100px" }} src="img/make.svg"></img>

        </Stack>

      </div>

      <Box component="main" sx={{ p: 1 }}>
        <Typography variant="h4" gutterBottom textAlign={"center"}>
          Creating and Integrating Webhooks with Make<br /> Your Complete Guide
        </Typography>

        <Typography variant="h6">Introduction:</Typography>
        <Typography paragraph>
          This guide will take you through the process of creating and integrating a custom webhook using Make. By the end of this walkthrough, you will be able to send data over HTTP to trigger scenarios in Make from Blueticks.
        </Typography>

        {/* Phase 1: Initializing Your Webhook */}
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Stack spacing={4}>
            <Box>
              <Typography variant="h6">Phase 1: Initializing Your Webhook</Typography>
              <Typography paragraph>- Navigate to the 'Webhooks' section found under the 'Tools' menu in your Make dashboard.</Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screenshot1.png" alt="Initializing Webhook" />
              </div>
            </Box>

            <Box>
              <Typography variant="body1" gutterBottom>2. Creating a New Webhook:</Typography>
              <Typography paragraph>- Select the purple button to add a new scenario.</Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screenshot2.png" alt="Open scenario builder" />
              </div>
              <Typography paragraph>- From the modules that appear, choose 'Webhooks' or search for it in the search bar.</Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screenshot3.png" alt="Select module" />
              </div>
            </Box>
          </Stack>
        </Paper>

        {/* Phase 2: Setting Up Your Webhook */}
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>


          <Stack spacing={1}>
            <Box>
              <Typography variant="h6">Phase 2: Setting Up Your Webhook</Typography>
              <Typography variant="body1" gutterBottom>1. Selecting Your Webhook Type:</Typography>
              <Typography paragraph>- Within the 'Webhooks' module, you'll have options for different types of webhooks.</Typography>
              <Typography paragraph>- Choose custom webhook.</Typography>

              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screensho4.png" alt="Setting Up Webhook" />
              </div>
            </Box>

            <Box>
              <Typography variant="body1" gutterBottom>2. Defining Webhook Details:</Typography>
              <Typography paragraph>- In the 'Create a webhook' dialog, enter a name for your webhook; for instance, "Blueticks webhook."</Typography>
              <Typography paragraph>- Specify any IP restrictions or leave it empty if not required.</Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screenshot7.png" alt="Setting Up Webhook" />
              </div>
            </Box>
          </Stack>
        </Paper>

        {/* Phase 3: Configuring and Using Your Webhook */}
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Stack spacing={1}>
            <Box>
              <Typography variant="h6" gutterBottom>Phase 3: Configuring and Using Your Webhook</Typography>


              <Typography variant="body1" gutterBottom>1. Configuring the Webhook:</Typography>
              <Typography paragraph> - Once saved, you will be provided with a unique URL endpoint for your webhook.</Typography>
              <Typography paragraph>- This URL is what you will use to send data to Make.
              </Typography>
              <Typography variant="body1" gutterBottom>2. Testing and Using Your Webhook:</Typography>
              <Typography paragraph>- Use the 'Copy address to clipboard' function to capture the webhook URL.</Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screenshot9.png" alt="Configuring Webhook" />



              </div>
            </Box>

            <Box>
              <Typography paragraph>- Navigate to the API Page from the user settings on or by clicking &nbsp;
                <Link target="_blank" href={appConfig.APP_ENDPOINT + "/admin-api"}>here</Link>
              </Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/make/screenshot10.png" alt="Configuring Webhook" />
              </div>
              <Typography paragraph>- Switch to the webhooks tab
              </Typography>
              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screenshot11.png" alt="Configuring Webhook" />
              </div>
              <Typography paragraph>- Paste the webhook url from Make and click on Add webhook
              </Typography>

              <div style={{ textAlign: "center" }}>
                <img src="img/make/Screenshot12.png" alt="Configuring Webhook" />
              </div>
            </Box>

            <Box>
              <Typography variant="h6" gutterBottom>Conclusion and Next Steps:</Typography>
              <Typography paragraph>
                With your webhook now set up and tested, you can integrate it with scenarios to automate workflows. If you encounter any issues, refer to Make's online help or community forums for troubleshooting tips and additional support.
              </Typography>

              
        <Typography variant="h6" gutterBottom>Additional Resources:</Typography>
        <Typography paragraph>
          - Make sure to check the <Link href="https://www.make.com/en/help/tools/webhooks">Learn more about using Webhooks</Link> link provided in the Webhooks section for detailed documentation.

        </Typography>
            </Box>
          </Stack>
        </Paper>

     

      </Box>

      <div
        style={{
          textAlign: "center",
          backgroundImage: `url(img/api-bg.svg)`,
          backgroundSize: "contain",
          marginBottom: "24px",
          minHeight: "150px",
        }}
      ></div>
    </Stack>
  );
}
