import { BTCheckbox } from "@components/atoms/bt-checkbox/bt-checkbox";
import { Label } from "@models/label";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

type LabelItemsListProps = {
  initialLabelItems: Array<LabelListItem>;
  onLabelItemClick: (labelItem: Label) => void;
};

export interface LabelListItem {
  selected: boolean;
  label: Label;
}

export const LabelItemsList = (props: LabelItemsListProps) => {

  const onClick = (labelItem: Label) => () => {
    props.onLabelItemClick(labelItem);
  };

  return (
    <List
      style={{
        maxHeight: "220px",
        overflow: "auto",
      }}
    >
      {props.initialLabelItems.map(
        (labelItem: LabelListItem, index: number) => {
          // const labelId = `checkbox-list-label-${value}`;
          const labelId: string = `list-items-label-${index}`;
          return (
            <ListItem
              key={labelId}
              role={undefined}
              dense
              button
              onClick={onClick(labelItem.label)}
            >
              <ListItemIcon>
                <BTCheckbox
                  edge="start"
                  checked={labelItem.selected}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={labelItem.label.displayName} />
            </ListItem>
          );
        }
      )}
    </List>
  );
}
