import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import WSButton from '@components/ws-button/ws-button';
import { CheckCircle, Error } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, debounce } from '@mui/material';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/system';
import { PaymentMethod, paymentFormActions } from '@reducers/PaymentFormReducer';
import { selectBillingInfo, showLoaderSelector } from '@reducers/PaymentFormSelectors';

import { closePaymentDialogThunk, createPaymentIntentThunk, getShopperInfoThunk, setAppliedCouponCodeThunk, setCurrencyThunk, validateVATNumberThunk, validateZipCodeThunk } from '@reducers/PaymentFormThunks';
import { selectCurrentUserEmail } from '@reducers/UserSelectors';
import { backendApiEndpoints } from '@reducers/backend-api/backend-api';
import { useAnalyticsService } from "@reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@services/analytics-service";
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { namespace } from 'config/constants';
import { createRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from "react-icons/ai";
import Generic from './assets/generic.png';
import PaypalLogo from './assets/paypal.png';
import { countryList } from './country-list';
import { ReactComponent as CreditCard } from './creditcard.svg';
import { CurrencyList } from './currency-codes';
import PaymentPricingBox from './payment-pricing-box/payment-pricing-box';
import classes from "./payment_form.module.scss";
import { ReactComponent as Paypal } from './paypal.svg';

export type CardType = 'AMEX' | 'VISA' | 'MASTERCARD' | 'DISCOVER' | 'DINERS' | 'JCB' | 'UNKNOWN'

export interface BluesnapToken {
    payload: {
        commonJwtPayload: {
            dateCreated: string;
        }
    }
}

const cardLogEndpoint = 'https://pay.bluesnap.com/web-sdk/5/assets/cards';

export const getCreditCardLogo = (cardType: string) => {
    console.log(`Card type: ${cardType}`)
    let creditCardLogo = Generic;
    switch (cardType) {
        case 'AMEX':
        case 'American Express':
        case 'amex': {
            creditCardLogo = `${cardLogEndpoint}/amex.png`;
            break;
        }
        case 'VISA':
        case 'Visa':
        case 'visa': {
            creditCardLogo = `${cardLogEndpoint}/visa.png`;
            break;
        }
        case 'MASTERCARD':
        case 'Mastercard':
        case 'mastercard': {
            creditCardLogo = `${cardLogEndpoint}/mastercard.png`;
            break;
        }
        case 'DISCOVER':
        case 'Discover':
        case 'discover': {
            creditCardLogo = `${cardLogEndpoint}/discover.png`;
            break;
        }
        case 'DINERS':
        case 'Diners':
        case 'diners': {
            creditCardLogo = `${cardLogEndpoint}/diners.png`;
            break;
        }
        case 'JCB':
        case 'jcb': {
            creditCardLogo = `${cardLogEndpoint}/jcb.png`;
            break;
        }
        case 'PayPal':
        case 'Paypal':
        case 'paypal': {
            creditCardLogo = PaypalLogo;
            break;
        }
    }
    return creditCardLogo;
}

export default function PaymentForm() {
    const { t } = useTranslation(namespace);
    const analyticsService: AnalyticsService = useAnalyticsService();

    const dispatch = useAppDispatch()

    const cardRef = createRef<ChargebeeComponents>()

    const dialogOpen = useAppSelector((state) => state.PaymentFormReducer.open);
    const paymentMethod = useAppSelector((state) => state.PaymentFormReducer.paymentMethod ?? 'CreditCard');
    const appliedCouponCode = useAppSelector((state) => state.PaymentFormReducer.appliedCouponCode);
    const firstName = useAppSelector((state) => state.PaymentFormReducer.firstName);
    const lastName = useAppSelector((state) => state.PaymentFormReducer.lastName);
    const companyName = useAppSelector((state) => state.PaymentFormReducer.companyName);
    const zip = useAppSelector((state) => state.PaymentFormReducer.zip);
    const vatId = useAppSelector((state) => state.PaymentFormReducer.vatId);
    const address = useAppSelector((state) => state.PaymentFormReducer.address);
    const countryCode = useAppSelector((state) => state.PaymentFormReducer.country?.code);

    const errorMessage = useAppSelector((state) => state.PaymentFormReducer.errorMessage);
    const cartErrorMessage = useAppSelector((state) => state.PaymentFormReducer.cartErrorMessage);
    const currency = useAppSelector((state) => state.PaymentFormReducer.currency);
    const planId = useAppSelector((state) => state.PaymentFormReducer.planId);
    const zipValidationInProgress = useAppSelector((state) => state.PaymentFormReducer.zipValidationLoading);
    const zipStatus = useAppSelector((state) => state.PaymentFormReducer.zipStatus);
    const zipValidationMessage = useAppSelector((state) => state.PaymentFormReducer.zipValidationMessage);

    const paymentIntent = useAppSelector((state) => state.PaymentFormReducer.paymentIntent);
    const vatStatus = useAppSelector((state) => state.PaymentFormReducer.vatStatus);
    const vatValidationMessage = useAppSelector((state) => state.PaymentFormReducer.vatValidationMessage);
    const vatValidationInProgress = useAppSelector((state) => state.PaymentFormReducer.vatValidationLoading);
    const getShopperInfoLoading = useAppSelector((state) => state.PaymentFormReducer.getShopperInfoLoading);
    const email = useAppSelector(selectCurrentUserEmail)
    const seats = useAppSelector((state) => state.PaymentFormReducer.seats);

    const [chargebeeComponentReady, setChargebeeComponentReady] = useState(false);
    const showLoader = useAppSelector(showLoaderSelector);

    const {
        creditCard,
        paypal,
        cardType,
        creditCardLogo,
        last4digits,
    } = useAppSelector((state) => selectBillingInfo(state));

    useEffect(() => {
        dispatch(getShopperInfoThunk());
    }, [])

    useEffect(() => {
        if (!paymentIntent) {
            dispatch(createPaymentIntentThunk())
        }
    }, [dispatch, paymentIntent])

    const setProcessingPayment = useCallback((processingPaymentStatus: boolean) => {
        dispatch(paymentFormActions.setProcessingPayment(processingPaymentStatus));
    }, [dispatch])

    const setErrorMessage = useCallback((errorMessage: string) => {
        dispatch(paymentFormActions.setErrorMessage(errorMessage));
    }, [dispatch])

    const setPaymentMethod = useCallback((paymentMethod: PaymentMethod) => {
        dispatch(paymentFormActions.setPaymentMethod(paymentMethod));
    }, [dispatch])

    const closeDialog = useCallback(() => {
        dispatch(closePaymentDialogThunk());
    }, [dispatch])

    const authorizeWith3ds = useCallback(async () => {
        const res = await cardRef.current?.authorizeWith3ds(paymentIntent, {
            plan: planId,
            email,
            billingAddress: {
                firstName,
                lastName,
                addressLine1: address,
                countryCode,
                zip
            }
            // Enable mandate for india payments
            // mandate: { 
            //     requireMandate: true,
            //     description: planId,
            // }
        }, {
            change: (intent, params) => {
                console.log('Change', params)
            },
            success: async (intent) => {
                console.log('Success', intent)
                setProcessingPayment(false);
                setErrorMessage('');
                dispatch(paymentFormActions.clearPaymentIntent());

                const subscription = await dispatch(backendApiEndpoints.finalizePurchase.initiate({
                    item_price_id: planId,
                    quantity: seats,
                    coupon_ids: appliedCouponCode ? [appliedCouponCode] : [],
                    payment_intent_id: intent.id,
                    customerDetails: {
                        firstName,
                        lastName,
                        email,
                        company: companyName,
                        vatNumber: vatId,
                        address,
                        city: '',
                        state: '',
                        zip,
                        country: countryCode
                    }
                })).unwrap();
                console.log(subscription)

                dispatch(paymentFormActions.setPaymentSuccess(true));
            },
            error: (intent, error) => {
                console.error('Error occured', error)
            }
        })
        console.log(res);
    }, [address, appliedCouponCode, cardRef, companyName, countryCode, dispatch, email, firstName, lastName, paymentIntent, planId, seats, setErrorMessage, setProcessingPayment, vatId, zip]);

    const finalizeWithExistingPaymentMethod = useCallback(async () => {
        const subscription = await dispatch(backendApiEndpoints.finalizePurchase.initiate({
            item_price_id: planId,
            quantity: seats,
            coupon_ids: appliedCouponCode ? [appliedCouponCode] : [],
            customerDetails: {
                firstName,
                lastName,
                email,
                company: companyName,
                vatNumber: vatId,
                address,
                city: '',
                state: '',
                zip,
                country: countryCode
            }
        })).unwrap();
        console.log(subscription)

        dispatch(paymentFormActions.setPaymentSuccess(true));
    }, [address, appliedCouponCode, companyName, countryCode, dispatch, email, firstName, lastName, planId, seats, vatId, zip]);

    const makePayment = useCallback(async () => {
        setProcessingPayment(true);
        if (creditCard) {
            await finalizeWithExistingPaymentMethod();
        } else {
            await authorizeWith3ds();
        }
    }, [authorizeWith3ds, creditCard, finalizeWithExistingPaymentMethod, setProcessingPayment])

    const [couponCode, setCouponCode] = useState<string>('');
    const inputTheme = {
        width: '100%',
        ".MuiFormControl-root": {
            width: '100%'
        },
        ".MuiInputBase-root": {
            background: 'var(--default-background)',
            border: '1px solid #DEEEF2',
            borderRadius: '8px',
            '& .MuiInputBase-input': {
                padding: '6.5px'
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                boxShadow: '2px 6px 12px rgba(80, 187, 238, 0.0625)'
            }
        }
    }
    const selectTheme = {
        width: '100%', background: 'var(--default-background)', border: '1px solid #DEEEF2', borderRadius: '8px',
        ".MuiSelect-select": {
            padding: '6.5px',
        },
        '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
            boxShadow: '2px 6px 12px rgba(80, 187, 238, 0.0625)'
        }
    }
    const couponButton = {
        background: 'var(--default-background)', color: 'var(--pricing-button-color)', textTransform: 'capitalize', borderRadius: '8px', boxShadow: '2px 6px 12px rgb(80 187 238 / 6%)', border: '1px solid #DEEEF2', maxWidth: '95px',
        "&:hover": {
            background: 'var(--default-background)',
        }
    }

    const validateVAT = useCallback(debounce((vatId) => {
        dispatch(validateVATNumberThunk(vatId))
    }, 500), [dispatch]);

    const validateZip = useCallback(debounce(() => {
        dispatch(validateZipCodeThunk());
    }, 500), [dispatch]);

    useEffect(() => {
        if (vatId?.length > 0) {
            validateVAT(vatId);
        }
    }, [vatId, validateVAT])

    useEffect(() => {
        // Function to fetch the user's country based on IP
        const fetchCountryCode = async () => {
            try {
                // Replace with your own API call
                const response = await fetch('https://ipapi.co/json');
                const data = await response.json();
                return data.country_code; // Or the appropriate field for the country code
            } catch (error) {
                console.error("An error occurred while fetching the user's country:", error);
                return ''; // Return a default value or handle error appropriately
            }
        };

        // Set the country code once it has been retrieved
        fetchCountryCode().then((code) => {
            if (code) {
                dispatch(paymentFormActions.setCountry(countryList.find(county => county.code === code)))
            }
        });
    }, []);

    const applyCoupon = useCallback(() => {
        dispatch(setAppliedCouponCodeThunk(couponCode));
    }, [couponCode, dispatch])

    const buildCreditCardFields = () => <Stack spacing={2}>
        <Box style={{ display: "none" }}>
            <FormControl fullWidth>
                <label className={classes.label}>Currency</label>
                <Select
                    data-testid='credit-card-currency-select'
                    id="currency-select"
                    value={currency?.code ?? ''}
                    label="Currency"
                    sx={selectTheme}
                    onChange={(event: SelectChangeEvent<string>) => {
                        dispatch(setCurrencyThunk(event.target.value));
                    }}
                    MenuProps={{
                        PopoverClasses: {
                            root: `currency-select-option`
                        }
                    }}
                >
                    {CurrencyList.map((currency, index) => <MenuItem key={index} value={currency.code}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {/* <CurrencyFlag currency={currency.code} /> */}
                            <Box data-testid={`currency-select-option-${currency.title}`}>{currency.title}</Box>
                        </Stack>
                    </MenuItem>)}
                </Select>
            </FormControl>
        </Box>
        <Stack >
            <label className={classes.label}>* Card Details</label>
            {getShopperInfoLoading && <CircularProgress />}
            {!getShopperInfoLoading && !creditCard && !paypal && paymentMethod === 'CreditCard' && <Box sx={{
                background: 'var(--default-background)',
                border: '1px solid #DEEEF2',
                borderRadius: '8px',
                padding: '6.5px',
                height: 23
            }}>

                <CardComponent
                    ref={cardRef}
                    styles={
                        {
                            empty: {

                            },
                            base: {
                                color: '#00C875',
                                fontSize: '16px',
                            },
                            invalid: {
                                color: '#E2445C',
                            },
                        }
                    }
                    onChange={(event: any) => {
                        const status: CardStatus = event;
                        if (status.complete) {
                            dispatch(paymentFormActions.setPaymentsFieldsReady(true))
                        } else {
                            dispatch(paymentFormActions.setPaymentsFieldsReady(false))
                        }
                    }}
                    onReady={() => {
                        console.log('Chargebee component ready')
                        setChargebeeComponentReady(true)
                    }}
                />
            </Box>}
            {!getShopperInfoLoading && (creditCard || paypal) && <Stack direction="row" alignItems="center" spacing={1}>
                {!getShopperInfoLoading && creditCard && <Stack direction="row" alignItems="center" spacing={1}>
                    <img style={{
                        height: 16
                    }} src={creditCardLogo ?? getCreditCardLogo(cardType)} alt={cardType} />
                    <span>{cardType} ending in {last4digits}</span>
                </Stack>}
                {!getShopperInfoLoading && paypal && <Stack direction="row" alignItems="center" spacing={1}>
                    <img style={{
                        height: 24
                    }} src={creditCardLogo ?? getCreditCardLogo('Paypal')} alt='Paypal' />
                    <span>PayPal {paypal.email}</span>
                </Stack>}
                <Button onClick={() => dispatch(paymentFormActions.setIsUpdatePaymentMethodPopupOpen(true))}>
                    Manage payment methods
                </Button>
            </Stack>}
        </Stack>
        {errorMessage && <Alert severity="error" sx={{ width: '100%' }}>
            {errorMessage}
        </Alert>}
    </Stack>

    const buildForm = () => {
        return <Box sx={{ position: 'relative', width: '100%', margin: 'auto' }}>
            <Box>
                <Stack sx={{ gap: '80px', minHeight: "737px" }} justifyContent={'center'} direction={'row'} flexWrap={'wrap'} >

                    <Stack spacing={2} sx={{ maxWidth: '450px', flexGrow: '1' }}>
                        {(!creditCard && !paypal) && <Stack direction="column" className={classes.paymenMethodContainer}>
                            <DialogTitle
                                sx={{
                                    color: 'var(--secondary-text-color)',
                                    fontWeight: 700,
                                    fontSize: '32px',
                                    padding: 0,
                                    marginBottom: '10px'
                                }}>
                                {planId ? 'Checkout' : 'Update payment details'}
                            </DialogTitle>
                            <span className={classes.label}>Payment method</span>
                            <Stack justifyContent='flex-start' direction='row' className={classes.actionsContainer}>
                                <Button data-testid='credit-card-payment-option-button' onClick={() => {
                                    analyticsService.event("select_credit_card", {
                                        category: "paymentForm",
                                        action: "select credit card",
                                    });
                                    setPaymentMethod('CreditCard')
                                }} className={`${classes.creditCardButton} ${paymentMethod === 'CreditCard' ? classes.selected : ''}`} startIcon={<CreditCard />} variant="outlined">Credit Card</Button>
                                {planId && <Button className={`${classes.creditCardButton} ${paymentMethod === 'Paypal' ? classes.selected : ''}`} onClick={() => {
                                    analyticsService.event("select_paypal", {
                                        category: "paymentForm",
                                        action: "select paypal",
                                    });
                                    // setPaymentMethod('Paypal')
                                    dispatch(paymentFormActions.setIsUpdatePaymentMethodPopupOpen(true))
                                }} variant="outlined"><Paypal /></Button>}
                            </Stack>
                        </Stack>}
                        {paymentMethod === 'CreditCard' && buildCreditCardFields()}
                        <Stack direction='row' spacing={2}>
                            <Box sx={{ width: '100%' }}>
                                <label className={classes.label}>* First name </label>
                                <TextField
                                    data-testid='credit-card-first-name-input'
                                    fullWidth
                                    sx={inputTheme}
                                    value={firstName ?? ''}
                                    onChange={(event) => dispatch(paymentFormActions.setFirstName(event.target.value))}
                                />
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <label className={classes.label}>* Last name </label>
                                <TextField
                                    data-testid='credit-card-last-name-input'
                                    fullWidth
                                    sx={inputTheme}
                                    value={lastName ?? ''}
                                    onChange={(event) => dispatch(paymentFormActions.setLastName(event.target.value))}
                                />
                            </Box>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Box sx={{ width: '100%' }}>
                                <label className={classes.label}>* Address</label>
                                <TextField
                                    data-testid='credit-card-address-input'
                                    fullWidth
                                    sx={inputTheme}
                                    value={address ?? ''}
                                    onChange={(event) => dispatch(paymentFormActions.setAddress(event.target.value))}
                                />
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <label className={classes.label}>* Country</label>
                                <Select
                                    data-testid='credit-card-country-select'
                                    IconComponent={ExpandMoreIcon}
                                    value={countryCode ?? ''}
                                    sx={selectTheme}
                                    onChange={(event) => dispatch(paymentFormActions.setCountry(countryList.find(county => county.code === event.target.value)))}
                                    MenuProps={{
                                        PopoverClasses: {
                                            root: `cc-country-select-option`
                                        }
                                    }}
                                >

                                    {countryList.map((country, index) => <MenuItem key={index} value={country.code} data-testid={`credit-card-country-select-option-${country.name}`}>
                                        {country.name}
                                    </MenuItem>)}
                                </Select>

                            </Box>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Box sx={{ width: '100%' }}>
                                <label className={classes.label}>Zip Code</label>
                                <TextField
                                    data-testid='credit-card-zipcode-input'
                                    fullWidth
                                    sx={inputTheme}
                                    type="number"
                                    value={zip ?? ''}
                                    onChange={(event) => {
                                        dispatch(paymentFormActions.setZip(event.target.value));
                                        validateZip();
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            zipValidationInProgress ? <CircularProgress size={24} /> :
                                                zip === "" ? <></> :
                                                    zipStatus === 'VALID' ? <CheckCircle style={{ color: 'green' }} /> :
                                                        zipStatus === 'INVALID' ? <Error style={{ color: 'red' }} /> :
                                                            <></>
                                        )
                                    }}
                                />
                                {!!zipValidationMessage && zip !== "" &&
                                    <span style={{ color: 'red', fontSize: '12px' }}>{zipValidationMessage}</span>
                                }
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <label className={classes.label}>VAT ID
                                    <Tooltip title={'VAT ID is an 8-15 digit number'} arrow placement="top" disableInteractive>
                                        <Stack> <AiOutlineInfoCircle /></Stack>
                                    </Tooltip>
                                </label>
                                <TextField
                                    data-testid='credit-card-VAT-input'
                                    fullWidth
                                    sx={inputTheme}
                                    type="text"
                                    value={vatId ?? ''}
                                    onChange={(event) => {
                                        dispatch(paymentFormActions.setVatId(event.target.value))
                                        validateVAT(vatId);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            vatValidationInProgress ? <CircularProgress size={24} /> :
                                                vatId === "" ? <></> : // Show neutral icon when vatId is empty
                                                    vatStatus === 'VALID' ? <CheckCircle style={{ color: 'green' }} /> :
                                                        vatStatus === 'INVALID' ? <Error style={{ color: 'red' }} /> :
                                                            <></>
                                        )
                                    }}
                                />
                                {!!vatValidationMessage && vatId !== "" && // Only show error message when vatId is not empty
                                    <span style={{ color: 'red', fontSize: '12px', }}>{vatValidationMessage}</span>
                                }
                            </Box>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Box sx={{ width: '100%' }}>
                                <label className={classes.label}>Company name </label>
                                <TextField
                                    data-testid='credit-card-company-name-input'
                                    fullWidth
                                    sx={inputTheme}
                                    value={companyName ?? ''}
                                    onChange={(event) => dispatch(paymentFormActions.setCompanyName(event.target.value))}
                                />
                            </Box>
                        </Stack>
                        <Stack>
                            {planId &&
                                <Stack direction="row" alignItems='end' spacing={2}>
                                    <Box>
                                        <label className={classes.label}>Coupon</label>
                                        <TextField
                                            data-testid='credit-card-coupon-input'
                                            sx={inputTheme}
                                            value={couponCode ?? ''}
                                            onChange={(event) => {
                                                setCouponCode(event.target.value);
                                                dispatch(paymentFormActions.setCartErrorMessage(''))
                                            }}
                                            error={!!cartErrorMessage}
                                        />

                                    </Box>
                                    {appliedCouponCode !== couponCode &&
                                        <Box>
                                            <WSButton sx={couponButton} disabled={showLoader} variant="contained" onClick={() => {
                                                analyticsService.event("click_redeem", {
                                                    category: "paymentForm",
                                                    action: "click redeem",
                                                });
                                                applyCoupon()
                                            }} data-testid='coupon-redeem-button'>
                                                Redeem
                                            </WSButton>
                                        </Box>}
                                </Stack>}


                            <Box sx={{ color: 'red' }}>
                                {cartErrorMessage}
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack alignSelf="flex-end"
                        sx={{
                            maxWidth: '350px',
                            marginTop: planId && { xs: '100px', lg: '60px' },
                            margin: !planId && "auto 0",
                            flexGrow: "1"
                        }}>
                        <PaymentPricingBox
                            makePayment={() => {
                                makePayment();
                            }}
                        />
                    </Stack>
                </Stack>
            </Box>
        </Box>
    }

    return (
        <>
            <Dialog fullScreen sx={{
                p: 3
            }} open={dialogOpen} onClose={closeDialog}>
                <DialogContent sx={{ background: 'linear-gradient(180deg, var(--default-background) 0%, #E8F9FD 100%)', display: 'flex' }}>
                    <IconButton sx={{ position: 'absolute', right: '25px', top: '10px', zIndex: 1 }} onClick={closeDialog} color="primary" aria-label="close manager" component="label">
                        <CancelIcon color="action" />
                    </IconButton>
                    {buildForm()}
                    {getShopperInfoLoading &&
                        <Stack sx={{
                            width: 1,
                            height: 1,
                            position: 'absolute',
                            background: 'linear-gradient(180deg, var(--default-background) 0%, #E8F9FD 100%)',
                            zIndex: 10
                        }} alignItems="center" justifyContent="center">
                            <CircularProgress />
                        </Stack>
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}
