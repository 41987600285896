import { useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import classes from "./rating-form-cell.module.scss";
import Rating from '@mui/material/Rating';
import { CellProps } from "@components/tasks/columns-cells/cell-interface";


export default function RatingFormCell({ cellValue, onUpdate }: CellProps) {
  const [value, setValue] = useState<number | undefined>();
  function updateRating(newValue) {
    setValue(Number(newValue.currentTarget.value));
    onUpdate(Number(newValue.currentTarget.value));
  }

  function handleClearValue() {
    setValue(undefined);
    onUpdate(undefined);
  }
  return (
    <div className={classes.ratingCell}>
      <Rating size="small"
        value={(value || cellValue) ?? 0}
        onChange={(newValue) => {
          updateRating(newValue);
        }}
      />
       {cellValue && (
          <RiCloseCircleFill
            onClick={handleClearValue}
            className={classes.clearIcon}
          />
        )}
    </div>
  );
}
