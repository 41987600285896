import { Stack } from '@mui/system';
import { logsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { LogStack } from '../../ExtraDataPanel';

type Props = {
    logId: string;
}

export default function NewDeleteRestoreTaskLog({
    logId,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))

    return (
        <LogStack>
            <span>{log.actionType}</span>&nbsp;
            <span style={{ fontWeight: 'bold' }}>{log.actionOnEntityData}</span>
        </LogStack>
    )
}