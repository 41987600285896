import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { DraggableProvided } from 'react-beautiful-dnd';

interface StickyHeaderState {
    stickyGroupId?: string;
    provided?: DraggableProvided;
}

const initialState: StickyHeaderState = {
};

const stickyHeaderSlice = createSlice({
    name: 'stickyHeader',
    initialState,
    reducers: {
        setStickyGroupId: (state, action: PayloadAction<string>) => {
            state.stickyGroupId = action.payload;
        },
        setProvided: (state, action: PayloadAction<DraggableProvided>) => {
            state.provided = action.payload;
        }
    },
});

// Create selector for stickyGroupId
export const selectStickyGroupId = (state: RootState) => state.StickyHeaderReducer.stickyGroupId;

export const {
    setStickyGroupId,
    setProvided
} = stickyHeaderSlice.actions;
export default stickyHeaderSlice.reducer;