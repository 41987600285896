
import { AccountPage } from "@components/account-page/account-page";
import BTThemeProvider from "@components/bt-theme-provider";
import { Stack } from '@mui/system';
import { SideBarItem } from "../../../components/sidebar/sidebar";
import classes from "../account-view/account-view.module.scss";
import shieldIcon from "../../../assets/shield2.png"
import { Card, CardContent, Typography, IconButton, Menu, MenuItem, Divider, Box, Grid } from "@mui/material";
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAvatar from "@components/tasks/person-avatar/person-avatar";
import { selectUserObject } from "@reducers/UserSelectors";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { identifySocketThunk, notifierLogoutThunk } from "@reducers/NotifierThunks";
const AccountDevices = () => {
  const dispatch = useAppDispatch();
  const [activeDeviceId, setActiveDeviceId] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const user = useAppSelector(selectUserObject);
  const devices = useAppSelector((state) => state.MainReducer.devices)

  const handleMenuClick = (event, deviceId) => {
    setAnchorEl(event.currentTarget);
    setActiveDeviceId(deviceId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveDeviceId(null);
  };

  const signOut = (socketId) => {
    dispatch(notifierLogoutThunk(socketId));
    handleMenuClose();
  }

  const identifySocket = (socketId) => {
    dispatch(identifySocketThunk(socketId))
    handleMenuClose();
  }

  const accountDevicesyView = () => {

    return (
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <h4 className={classes.pageTitle}>Account devices</h4>
        </div>
        <Stack p={2} justifyContent={"center"} alignItems={"center"}>
          <img width="110" src={shieldIcon} alt="devices shield" />
          <h3 style={{ textAlign: "center", margin: "4px" }}>These signed-in devices have recently been active on this account.<br />
            {/* You can sign out 
             */}
            {/* or change main sender  */}
            </h3>


        </Stack>
        <Grid container spacing={3} padding={10} rowGap={4}>
          {devices.filter(device => device.domain === "https://web.whatsapp.com/")
            .map((device, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card sx={{ maxWidth: 350 }}>
                  <CardContent>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography fontSize={14} component="div">
                        {device.device} - {device.browserType} 
                        {/* - id: {device.id} */}
                      </Typography>
                      <IconButton
                        aria-label="more"
                        aria-controls={`long-menu-${device.id}`}
                        aria-haspopup="true"
                        onClick={(event) => handleMenuClick(event, device.id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Stack>
                    <Divider sx={{ my: 1 }} />

                    <Stack spacing={2} mt={2}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <PersonAvatar
                          size={24}
                          email={user.email}
                          profileImg={user.profileImg}
                          lastName={user.lastName}
                          firstName={user.firstName}
                          isName={true}
                        />

                        <Box sx={{ textAlign: "center", backgroundColor: "#CCE6FF", borderRadius: "4px", padding: 0.5, fontSize: "10px", width: "80px" }}>
                          {/* {device.status} */}
                          Connected
                        </Box>
                      </Stack>

                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack spacing={1} direction="row" alignItems={"center"}>
                          <AccessTimeIcon />
                          <Typography variant="body2">
                            {device.connectionTime} 
                             {/* {device.connectedFrom}  */}
                         
                             {/* {device.domain} */}
                          </Typography>
                        </Stack>


                        {device.isMainSender && (
                          <Box sx={{ textAlign: "center", backgroundColor: "#9AFFB6", borderRadius: "4px", padding: 0.5, fontSize: "11px", width: "80px" }}>
                            Main Sender
                          </Box>
                        )}
                      </Stack>
                    </Stack>

                    <Menu
                      id={`device-card-menu-${activeDeviceId}`}
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl) && activeDeviceId === device.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => signOut(activeDeviceId)}>Sign out</MenuItem>
                      <MenuItem onClick={() => identifySocket(activeDeviceId)}>Identify</MenuItem>
                      {/* <MenuItem onClick={handleMenuClose}>Set as main sender</MenuItem> */}
                    </Menu>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </div>
    );
  };

  return (
    <BTThemeProvider>
      <AccountPage
        page={accountDevicesyView()}
        active={SideBarItem.Devices}
      ></AccountPage>
    </BTThemeProvider>
  );
};

export default AccountDevices;
