import { Box, Stack, Tooltip } from '@mui/material';
import { getCurrentBoard } from '@reducers/BoardsSelectors';
import { useAppSelector } from '@store/hooks';
import { namespace } from 'config/constants';
import { useTranslation } from 'react-i18next';
import { CellProps } from '../cell-interface';

export default function ColumnHeaderBoardView({
    columnId,
    boardId,
    openPicker,
    mockValue
}: CellProps) {
    const { t } = useTranslation(namespace);
    const board = useAppSelector(getCurrentBoard)


    const textTheme = {
        cursor: "default",
        textAlign: "center",
        width: "100%",
        color: "var(--cell-name-color)",
        whiteSpace: "nowrap",
        textOverflow: 'ellipsis',
        outline: "none",
        backgroundColor: "transparent",
        fontSize: '12px',
        border: '1px solid transparent',
        borderRadius: '4px',
        overflow: "hidden",
        fontFamily: "'Inter', sans-serif",
        padding: "3px",

    }

    return (
        <Stack direction="row" justifyContent='center'
            sx={{
                padding: "8px 0",
                overflow: "hidden",

                '&:hover button': {
                    visibility: 'visible'
                },
                width: "100%"
            }}
        >


            <Tooltip placement="top" arrow title={t('columnHeaderBoardView.titleCannotBeEdited')}>
                <Box sx={textTheme}>
                    {mockValue.column?.title}
                </Box>
            </Tooltip>



        </Stack>
    )
}
