import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { Template } from "../models/template";
import { RootState } from "../store";
import { templateSelectByQuery } from "./DBServiceReducers";

export interface SnippetsState {
  toggledOn: boolean;
  fetchStatus?: "loading" | "idle" | "rejected";
  items: Template[];
  filterVal: string;
  messageboxVal?: string;
  filteredItems?: Template[];
  isSnippetDialogOpen: boolean;
  showLeanList: boolean;
}

const initialState: SnippetsState = {
  toggledOn: false,
  items: [],
  filterVal: "",
  isSnippetDialogOpen: false,
  showLeanList: false,
};

const SnippetsReducer = createSlice({
  name: "snippets",
  initialState,
  reducers: {
    toggle(state) {
      state.toggledOn = !state.toggledOn;
    },
    filterChange(state, { payload }: PayloadAction<string>) {
      state.filterVal = payload;
    },
    messageboxChange(state, { payload }: PayloadAction<string>) {
      state.messageboxVal = payload;
    },
    toggleSnippetDialog(state) {
      state.isSnippetDialogOpen = !state.isSnippetDialogOpen;
    },
    closeSnippetDialog(state) {
      state.isSnippetDialogOpen = false;
    },
    showList(state, { payload }: PayloadAction<boolean>) {
      state.toggledOn = payload;
    },
  },
});

//selectors
const selectItemList = (state) =>
  templateSelectByQuery(state.DBTemplateReducer, {
    $or: [{ deleted: { $exists: false } }, { deleted: false }],
  }).sort((a: Template, b: Template) =>
    moment(b.updatedAt).diff(moment(a.updatedAt))
  );
const selectTemplates = (state: RootState) => state.SnippetsReducer;

export const selectAllItems = createSelector(selectItemList, (items) => items);

export const selectIsTemplateDialogOpen = createSelector(
  selectTemplates,
  (t) => t.isSnippetDialogOpen
);
export const selectIsToggleOn = createSelector(
  selectTemplates,
  (t) => t.toggledOn
);
export const selectFilterVal = createSelector(
  selectTemplates,
  (t) => t.filterVal
);

export const selectMessageboxVal = createSelector(
  selectTemplates,
  (t) => t.messageboxVal
);

export const selectItemListView = createSelector(
  selectAllItems,
  selectFilterVal,
  (items: Template[], filterStr) => {
    if (!filterStr || filterStr === "") return items;

    const lStr = filterStr?.toLowerCase();

    return items.filter(
      (item) =>
        item.name?.toLowerCase().includes(lStr) ||
        item.message?.toLowerCase().includes(lStr)
    );
  }
);

export const selectLeanListItems = createSelector(
  selectAllItems,
  selectMessageboxVal,
  (items, messageboxVal) => {
    if (!messageboxVal || messageboxVal === "") return items;

    const lStr = messageboxVal?.toLowerCase();

    return items.filter(
      (item) =>
        item.name?.toLowerCase().includes(lStr) ||
        item.message?.toLowerCase().includes(lStr)
    );
  }
);

//actions
export const {
  toggle,
  filterChange,
  messageboxChange,
  toggleSnippetDialog,
  closeSnippetDialog,
  showList,
} = SnippetsReducer.actions;

export default SnippetsReducer.reducer;
