import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Stack, Tooltip, Typography } from "@mui/material";
import { DBMyWorksThunks } from '@reducers/DBServiceReducers';
import { fetchMyWorkThunk, getMyWorkBoards } from '@reducers/MyWorkThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { TbLayoutBoardSplit } from 'react-icons/tb';

export const BoardsAccordion = () => {
  const dispatch = useAppDispatch();
  const  myWorkPreferences  = useAppSelector(state => state.MyWorkReducer.myWorkPreferences)
  const [hiddenBoards, setHiddenBoards] = useState(myWorkPreferences.hiddenBoards || []);
  const boards = useAppSelector(state => getMyWorkBoards(state))

  const handleBoardVisibilityChange = async (boardId, isChecked) => {
    let updatedHiddenBoards;
    if (isChecked) {
      updatedHiddenBoards = hiddenBoards.filter(id => id !== boardId);
    } else {
      updatedHiddenBoards = [...hiddenBoards, boardId];
    }

    setHiddenBoards(updatedHiddenBoards);

    await dispatch(DBMyWorksThunks.patch({
      entity: {
        _id: myWorkPreferences._id,
        hiddenBoards: updatedHiddenBoards
      }
    }));
    dispatch(fetchMyWorkThunk())
  }

  const handleLabelClick = (boardId) => {
    const isChecked = !hiddenBoards.includes(boardId);
    handleBoardVisibilityChange(boardId, !isChecked);
  };
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} >
        <Stack direction="row" alignItems="center" spacing={1}>
          <TbLayoutBoardSplit />
          <Typography>Boards</Typography>
          <Tooltip title="Select which boards you wanna see" placement='top' arrow>
            <div>
              <AiOutlineInfoCircle />
            </div>
          </Tooltip>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>

        {boards.map(board => (
          <Stack spacing={1} direction="row" alignItems={"center"} key={board._id}>
            <Checkbox

              sx={{ px: 0 }}
              checked={!hiddenBoards.includes(board._id)}
              onChange={(e) => handleBoardVisibilityChange(board._id, e.target.checked)}
            />
            <Typography sx={{cursor:"pointer"}} onClick={() => handleLabelClick(board._id)}>
              {board.name}
            </Typography>
          </Stack>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};