import RowStack from '@components/common/row-stack/RowStack'
import { IconButton } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { setActiveTab } from '@reducers/CampaignReducer'
import { campaignsSelectOneObjectByQuery } from '@reducers/DBServiceReducers'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import dayjs from 'dayjs'
import { BsPencil } from 'react-icons/bs'
import CampaignFinishTabTitle from './campaign-finish-tab-title'

type Props = {
    campaignId?: string;
}

export default function CampaignSummaryTimingDialog({ campaignId }: Props) {
    const dispatch = useAppDispatch()
    let campaign
    const _campaign = useAppSelector((state) => campaignsSelectOneObjectByQuery(state.DBCampaignReducer, { _id: campaignId }))
    const newCampaign = useAppSelector((state) => state.CampaignReducer.newCampaign)

    if (campaignId) campaign = _campaign
    else campaign = newCampaign

    // const patternString = !!campaign?.schedule?.rruleset ? RRule.fromString(campaign?.schedule?.rruleset).toText() : 'No pattern specified'
    const dueDateDayjs = dayjs(campaign?.schedule?.dueDate)
    const startingAtString = (!campaignId && campaign?.schedule?.isNow) ? 'Now' : `Starting ${dueDateDayjs.format('MMMM D, YYYY')} at ${dueDateDayjs.format('HH:mm')}`

    return (
        <Box sx={{
            width: '100%',
        }}>
            <Stack direction='row' sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
            }}>
                <RowStack sx={{ marginBottom: "15px" }}>



                    <CampaignFinishTabTitle titleString={`Due date: ${startingAtString}`} />
                    {!campaignId && <IconButton onClick={() => dispatch(setActiveTab('timeAndDate'))}>
                        <BsPencil />
                    </IconButton>}
                </RowStack>


                {/* <Stack direction='row'>
                    <Icon fontSize='small'><Loop /></Icon>
                    <p style={{ margin: 0, textTransform: 'capitalize' }}>
                        &nbsp; {patternString}
                    </p>
                </Stack> */}

            </Stack>
        </Box>
    )
}