import React from 'react'
import BtQuickPanel from '@components/quick-panel/bt-quick-panel'



export default function BtQuickPanelView() {

    return <div>
        <h1>Quick panel view</h1>
        <BtQuickPanel />
    </div>
}