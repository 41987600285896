import FilesIcons from "@components/files-icons/filesIcons";
import { Tooltip } from "@mui/material";
import { pickerDialogActions } from "@reducers/PickerDialogReducer";
import { mediaManager } from "@reducers/MediaManagerReducer";
import { handleUpload } from "@services/upload";
import { useCallback, useRef, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { useAppDispatch } from "@store/hooks";
import { ReactComponent as AddIcon } from "@assets/add.svg";
import Dropzone from "./dropzone";
import classes from "./files_cell.module.scss";
import { ReactComponent as FilesIcon } from "./small-file.svg";
import { CellProps } from "../cell-interface";
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import { namespace } from "config/constants";
import { useTranslation } from "react-i18next";

export default function FilesCell({
  cellValue: currentFiles,
  taskId,
  columnId,
  readOnly,
  onUpdate,
  togglePicker,
  mockValue,
}: CellProps) {
  const { t } = useTranslation(namespace);
  const fileRef: any = useRef();
  const dispatch = useAppDispatch()
  const anchorId = `files_${taskId}`;
  const [loading, showLoader] = useState(false);
  function addFiles(e) {
    if (readOnly) return;
    e.stopPropagation();
    fileRef.current.files = null;
    fileRef.current.click();
  }

  function clearFile(e) {
    e.stopPropagation();
    onUpdate(undefined);
  }
  const [delayHandler, setDelayHandler] = useState(null);

  function hoverFile() {
    if (currentFiles.length === 1) return;
    setDelayHandler(
      setTimeout(() => {
        togglePicker({
          anchorId: anchorId,
          pickerType: 'files'
        })
      }, 300)
    );
  }

  const handleMouseLeave = () => {
    clearTimeout(delayHandler);
  };

  function openMediaManager(e, i) {
    e.stopPropagation();
    const currentFilesAndIndex = {}
    currentFilesAndIndex["index"] = i;
    currentFilesAndIndex["shown"] = true;
    currentFilesAndIndex["taskId"] = taskId;
    currentFilesAndIndex["columnId"] = columnId;
    currentFilesAndIndex["isViewOnly"] = false;
    if (readOnly) {
      currentFilesAndIndex["filesTotal"] = currentFiles
      currentFilesAndIndex["isViewOnly"] = true;
    }
    dispatch(
      mediaManager.setSelectedCellFilesAndCurrentindex(currentFilesAndIndex)
    );
    dispatch(pickerDialogActions.closePickerDialog());
  }

  const getUniqeAndDuplicateFiles = useCallback((filesToUpload: any) => {
    let isFileExists = false
    let filesToDecideNewVersionOrNewFile = []
    let uniqueFilesToUpload = [];
    for (let i = 0; i < filesToUpload.length; i++) {
      const fileName: string = filesToUpload[i].filename;
      isFileExists = currentFiles?.find(({ filename }) => filename === fileName);
      if (isFileExists) {
        filesToDecideNewVersionOrNewFile = [...filesToDecideNewVersionOrNewFile, filesToUpload[i]]
      } else {
        uniqueFilesToUpload = [...uniqueFilesToUpload, filesToUpload[i]];
      }
    }
    return { uniqueFilesToUpload, filesToDecideNewVersionOrNewFile };
  }, [currentFiles])

  const updateUniqueFiles = useCallback((filesToUpload) => {
    const files = currentFiles ? currentFiles.concat(filesToUpload) : filesToUpload;
    onUpdate(files);
  }, [currentFiles, onUpdate])

  const uploadFiles = useCallback(async (files: any) => {
    if (files) {
      const filesArray = [...files];
      const promises = filesArray.map(async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          const mimeType: string = file.type;
          const filename: string = file.name;
          const fileDetails = {};
          reader.addEventListener("load", async () => {
            const base64file: any = reader.result;
            const urlLink = await uploadFile(base64file, mimeType);
            fileDetails["url"] = urlLink;
            fileDetails["type"] = mimeType;
            fileDetails["filename"] = filename;
            fileDetails["updatedAt"] = moment().format('DD/MM/YYYY HH:mm:ss');
            fileDetails["version"] = 1;
            fileDetails["versions"] = [{ url: urlLink, type: mimeType, filename, updatedAt: moment().format('DD/MM/YYYY HH:mm:ss'), version: 1 }]
            resolve(fileDetails);
          });
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });
      let currentFilesUploaded: any = await Promise.all(promises);
      return currentFilesUploaded;
    }
  }, [])

  const updateState = useCallback((filesToDecideNewVersionOrNewFile) => {
    dispatch(
      mediaManager.setFilesToDecideNewVersionOrNewFile({ taskId, columnId, filesToDecideNewVersionOrNewFile })
    );
  }, [columnId, dispatch, taskId])

  const onNewFilesAdded = useCallback(async (newFiles) => {
    showLoader(true);
    const fileListUploaded = await uploadFiles(newFiles);
    const { uniqueFilesToUpload, filesToDecideNewVersionOrNewFile } = getUniqeAndDuplicateFiles(fileListUploaded);
    if (uniqueFilesToUpload.length > 0) {
      updateUniqueFiles(uniqueFilesToUpload);
    }
    if (filesToDecideNewVersionOrNewFile.length > 0) {
      updateState(filesToDecideNewVersionOrNewFile);
    }
    showLoader(false);
    if (fileRef?.current) {
      fileRef.current.value = "";
    }
  }, [getUniqeAndDuplicateFiles, uploadFiles, updateUniqueFiles, updateState])

  const onDrop = useCallback(
    (droppedFiles) => {
      onNewFilesAdded(droppedFiles);
    },
    [onNewFilesAdded]);

  const uploadFile = async (base64file: string, mimeType: string) => {
    try {
      const response: any = await handleUpload(base64file, mimeType);
      if (response.uploadResponse.url) {
        return response.image_url;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className={classes.filesContainer}
      style={{ width: mockValue && 'auto' }}
      onClick={(e) => {
        addFiles(e);
      }}
      id={`files_${taskId}`}
    >
      <Dropzone onDrop={onDrop} />
      {(currentFiles && !readOnly) && (
        <Tooltip title={t('filesCell.addFiles')} arrow placement="top" disableInteractive>
          <AddIcon
            className={[classes.addIcon, classes.addWithFiles].join(" ")}
          />
        </Tooltip>
      )}
      <div className={classes.filesCell}>
        {(!currentFiles && !loading && !readOnly) && (
          <>
            <AddIcon className={classes.addIcon} />
            <FilesIcon className={classes.filesIcon} />
          </>
        )}
        <input
          onChange={(e) => {
            onNewFilesAdded(e.target.files);
          }}
          ref={fileRef}
          type="file"
          multiple={true}
          hidden
          readOnly={readOnly}
        />
        {loading && (
          <CircularProgress style={{ color: "#259ae9" }} size={18} />
        )}
        {!loading && currentFiles?.slice(0, 3).map((file, i) => {
          return (
            <span key={i} className={classes.fileIcon}
              onClick={(e) => {
                openMediaManager(e, i);
              }}
              onMouseEnter={hoverFile}
              onMouseLeave={handleMouseLeave}
            >
              <FilesIcons file={file} />
            </span>
          );
        })}
      </div>
      {currentFiles?.length > 3 && (
        <div
          onMouseEnter={hoverFile}
          onMouseLeave={handleMouseLeave}
          className={classes.extraFiles}>
          +{currentFiles.length - 3}
        </div>
      )}
      {(currentFiles?.length === 1 && !readOnly) && (
        <RiCloseCircleFill
          onClick={(e) => {
            clearFile(e);
          }}
          className={classes.clearIcon}
        />
      )}
    </div>
  );
}
