import { Person } from "@models/person";

export type elementType = {
  _id: string;
  text: string;
  status: string;
  priority: string;
  person_id?: string;
  createdAt?: string;
  extraData?: any;
};

// export interface CustomElement {
//   taskId?: string;
//   chatId?: string;
//   boardId?: string;
//   owner?: string;
//   taskMapIndex?: number;
//   groupIndex?: number;
//   taskListIndex?: number;
//   personList?: Person[];
//   value?: string;
//   colorAccent?: string;
//   isActionsVisible?: boolean;
//   type?: BoardRowType;
//   element?: Task;
//   groupId?: string;
//   order?: number;
//   isEditting?: boolean;
//   isDialogOpen?: boolean;
//   taskType?: string
//   parentTaskId?: string;
//   showSubTasks?: boolean,
//   subTasksCount?: number,
//   isFirstTaskInGroup?: boolean
//   isLastTaskInGroup?: boolean
// }
export interface GroupHeaderElement {
  type?: string;
  isEditing?: boolean;
  chatId?: string;
  colorAccent?: string;
  groupIndex?: number;
  isAutoFocus?: boolean;
  isMenuOpen?: boolean;
  isColorPickerOpen?: boolean;
  isEditingGroupName?: boolean;
  personList?: Person[];
  value?: string;
}
export interface FooterElement {
  type?: string;
  chatId?: string;
  groupId?: string;
  isEditing?: boolean;
  isAutoFocus?: boolean;
  colorAccent?: string;
  groupIndex?: number;
  taskListIndex?: number;
  headerIndex?: number;
}

export interface ConfirmDeleteDialogPayload {
  deleteTaskGroupDialogOpen: boolean;
  taskGroupIdPendingDelete: {
    chatId: string;
    groupIndex: number | any;
  };
}

export interface ContactListPayload {
  contactList: any;
  me: any;
}

export type QuickLink = {
  _id: string
  boardId: string
  groupId: string
}

export enum QUICK_PANEL {
  TASK = 'TASK',
  MESSAGE = 'MESSAGE',
  CHAT = 'CHAT',
  SETTINGS = 'SETTINGS',
  MAIN_POPPER_ID = 'quickPannel-mainPopperId',
  QUICK_LINK_PANEL_ID = 'quick-link-id'
}



