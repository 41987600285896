import BTButton from '@components/bt-button/bt-button';
import BtEditableBox from '@components/tasks/bt-editable-box/bt-editable-box';
import { SendOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/system';
import { DBFormThunks, formSelectOneFieldByQuery, isTempId } from '@reducers/DBServiceReducers';
import { formColumnList } from '@reducers/FormReducers';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { namespace } from 'config/constants';
import { DragDropContext, DragStart, Draggable, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { RiCloseCircleFill } from 'react-icons/ri';
import BTUploadLogo from './bt-upload-logo';
import classes from "./form.module.scss";
import FormsRow from './forms-row';

type Props = {
    formId: string,
    boardId: string,
}

export default function BlueticksForm({ formId, boardId }: Props) {
    const { t } = useTranslation(namespace);
    const columnData = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'columnData')) ?? {}
    const sortedColumnList = useAppSelector((state) => formColumnList(state, columnData, boardId))
    const dispatch = useAppDispatch()
    const logo = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'logo'))
    const title = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'title'))
    const name = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'name'))
    const description = useAppSelector(state => formSelectOneFieldByQuery(state.DBFormReducer, {
        _id: formId
    }, 'description'))

    const inputTheme = {
        color: "var(--primary)",
        width: "100%",
        fontSize: "15px",
        borderRadius: "8px",
        border: "1px solid #53bdeb",
        fontFamily: "inherit",
        "& .MuiInputBase-input": {
            padding: "0",
        },
        display: 'flex',
        alignItems: 'center',
        padding: "3px 6px",
    };

    const textTheme = {
        color: "var(--primary)",
        fontSize: "15px",
        border: "1px solid transparent",
        borderRadius: "8px",
        "&:hover": {
            border: "1px dashed var(--text-color)",
        },
        display: 'flex',
        alignItems: 'center',
        padding: "4px 6px",
        width: "100%",
        fontFamily: "inherit",
    };

    const titleUpdate = (value) => {
        if (!isTempId(formId)) {
            dispatch(DBFormThunks.patch({
                entity: {
                    _id: formId,
                    title: value,
                }
            }))
        }

    }

    const FormTitle = () => {
        const titleCss = {
            fontSize: 24,
            fontWeight: 800,
            marginTop: "24px",
            marginBottom: "6px",
            marginInline: 'calc(16px + 24px + 16px)', // 16px padding + 24px icon + 16px gap
            fontFamily: "'Poppins','Assistant',sans-serif",
        };
        return (
            <BtEditableBox
                inputSx={{ ...inputTheme, ...titleCss }}
                boxSx={{ ...textTheme, ...titleCss }}
                text={(title === undefined || title === '') ? name : title}
                onValueUpdate={(value) => {
                    titleUpdate(value);
                }}
            />
        )
    }

    const descriptionUpdate = (value) => {
        if (!isTempId(formId)) {
            dispatch(DBFormThunks.patch({
                entity: {
                    _id: formId,
                    description: value,
                }
            }))
        }

    }

    const FormDescription = () => {
        const emptyText = t('blueticksForm.addDescription');
        const descriptionCss = {
            fontSize: 16,
            marginInline: 'calc(16px + 24px + 16px)', // 16px padding + 24px icon + 16px gap
            marginBottom: "12px",
            "&:hover": {
                opacity: 1,
            },
        };
        const descriptionCssOnlyBox = {
            opacity: !description || description === emptyText ? 0.5 : 1,
        }
        return (
            <BtEditableBox
                inputSx={{ ...inputTheme, ...descriptionCss }}
                boxSx={{ ...textTheme, ...descriptionCss, ...descriptionCssOnlyBox }}
                text={(description === '' || description === undefined) ? emptyText : description}
                onValueUpdate={(value) => {
                    descriptionUpdate(value);
                }}
            />
        )
    }



    const clearFile = () => {
        if (!isTempId(formId)) {
            dispatch(DBFormThunks.patch({
                entity: {
                    _id: formId,
                    logo: '',
                }
            }))
        }

    }


    return (
        <Stack direction='column' sx={{
            display: 'flex',
            alignItems: 'center',
        }}>
            <div style={{
                width: '700px',
                display: 'flex',
                justifyContent: 'flex-start',
                marginBottom: '10px',
            }}>
            </div>
            <div className={classes.form_wrapper} >
                <Stack direction='column'>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                        <Box sx={{
                            maxHeight: '150px',
                            maxWidth: '200px',
                        }}>
                            {logo &&

                                <Stack direction='row' sx={{
                                    height: '100px',
                                    width: 'auto',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '25px',
                                }}>
                                    <img src={logo} alt={t('blueticksForm.formLogoAlt')} />
                                    <Box >
                                        <RiCloseCircleFill
                                            onClick={() => { clearFile() }}
                                            style={{
                                                cursor: 'pointer',
                                                fontSize: '15px',
                                            }}
                                        />
                                    </Box>
                                </Stack>}
                            {!logo &&
                                <BTUploadLogo formId={formId} />
                            }
                        </Box>
                    </div>
                    <div className={classes.form_body}>
                        <FormTitle />
                        <FormDescription />
                        <DragDropContext
                            onBeforeDragStart={(initial: DragStart) => {
                            }}
                            onDragEnd={(result: DropResult, provided: ResponderProvided) => {

                                if (!result.destination) {
                                    return;
                                }
                                const [_, columnId] = result.draggableId.split("formCell_")
                                const column = sortedColumnList.find((column) => {
                                    return column._id === columnId
                                })

                                const finalOrder = [...sortedColumnList
                                    .filter(column => {
                                        return column._id !== columnId
                                    })
                                ]

                                finalOrder.splice(result.destination.index, 0, column)

                                //let newColumnData = {...form.columnData}

                                let newColumnData = JSON.parse(JSON.stringify(columnData));

                                for (let i = 0; i < finalOrder.length; i++) {
                                    const column = finalOrder[i]
                                    newColumnData[column._id] = {
                                        ...(newColumnData[column._id] ?? {}),
                                        order: i
                                    }
                                }

                                dispatch(DBFormThunks.patch({
                                    entity: {
                                        _id: formId,
                                        columnData: newColumnData
                                    }
                                }))

                            }}>
                            <Droppable droppableId='formCell' direction="vertical">
                                {(provided) => (
                                    <Box {...provided.droppableProps} ref={provided.innerRef} sx={{ width: '100%' }}>
                                        {sortedColumnList.map((column, index) => {
                                            return (
                                                <Draggable
                                                    key={`formCell_${column._id}`}
                                                    draggableId={`formCell_${column._id}`}
                                                    index={index}>
                                                    {(draggableProvided) => (
                                                        <FormsRow
                                                            index={index}
                                                            formId={formId}
                                                            column={column}
                                                            draggableProvided={draggableProvided}
                                                        />
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <BTButton
                            disabled={true}
                            sx={{
                                display: 'flex',
                                alignSelf: 'center',
                                fontWeight: 600,
                                fontSize: '14px',
                                marginBlock: '16px'
                            }}
                            // onClick={handleFormSubmit}
                            endIcon={<SendOutlined />}>
                            {t('blueticksForm.submit')}
                        </BTButton>
                        <small style={{
                            alignSelf: 'center',
                            textAlign: 'center',
                            marginBottom: '35px',
                        }}>
                            {t('blueticksForm.unsupportedColumnsInfoFirstLine')}<br />
                            {t('blueticksForm.unsupportedColumnsInfoSecondLine')}
                        </small>
                    </div >
                </Stack >
            </div >
        </Stack >

    )
}