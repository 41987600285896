import * as icons from "@mui/icons-material";
import React, { ReactElement } from "react";

export type IconNames = keyof typeof icons;

export interface NamedIconProps {
  name: IconNames | string | undefined;
}

export default function NamedIcon(props: NamedIconProps): ReactElement {
  if (
    props.name &&
    props.name !== "" &&
    icons[props.name as IconNames] !== undefined
  ) {
    const CustomIcon = icons[props.name as IconNames];
    return <CustomIcon />;
  } else {
    return <></>;
  }
}
