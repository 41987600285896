import { Modal } from '@mui/material'
import { DBFeatureThunks, featureSelectOneObjectByQuery } from '@reducers/DBServiceReducers'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { useEffect, useState } from 'react'
import MaintenancePopupDialog from './maintenance-popup-dialog'




function MaintenancePopup() {
    const dispatch = useAppDispatch()
    const maintenancePopupFeature = useAppSelector((state) => featureSelectOneObjectByQuery(state.DBFeatureReducer, {
        featureName: 'maintenancePopup',
    }))

    const [showMaintenancePopup, setShowMaintenancePopup] = useState(false);


    const LOCALSTORAGE_KEY = "lastMaintenancePopupVersionShown";

    function parseStringToNumber(str) {
        const parsedNumber = parseInt(str, 10);
        return isNaN(parsedNumber) ? 0 : parsedNumber;
    }

    useEffect(() => {
        dispatch(
            DBFeatureThunks.find({
                featureName: 'maintenancePopup',
            })
        )
    }, [])


    useEffect(() => {
        const lastMaintenancePopupVersionShown = localStorage.getItem(LOCALSTORAGE_KEY);
        if (maintenancePopupFeature?.isActive) {
            if (!lastMaintenancePopupVersionShown) {
                localStorage.setItem(LOCALSTORAGE_KEY, maintenancePopupFeature?.version.toString());
                setShowMaintenancePopup(true);
            }
            else if (parseStringToNumber(lastMaintenancePopupVersionShown) < maintenancePopupFeature?.version) {
                localStorage.setItem(LOCALSTORAGE_KEY, maintenancePopupFeature?.version.toString());
                setShowMaintenancePopup(true);
            }
        }
    }, [maintenancePopupFeature]);

    if (!showMaintenancePopup) {
        return null;
    }
    if (showMaintenancePopup) {
        return <MaintenancePopupModal openMaintenancePopup={showMaintenancePopup} handlMaintenancePopupClose={() => setShowMaintenancePopup(false)} />;
    }
}


type Props = {
    openMaintenancePopup: boolean;
    handlMaintenancePopupClose: () => void;
}

export function MaintenancePopupModal({
    openMaintenancePopup,
    handlMaintenancePopupClose,
}: Props) {

    return (
        <Modal
            open={openMaintenancePopup}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handlMaintenancePopupClose();
                }
            }}
            closeAfterTransition
        >
            <MaintenancePopupDialog handlMaintenancePopupClose={handlMaintenancePopupClose} />
        </Modal>
    )
}

export default MaintenancePopup;