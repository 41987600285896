
import { memo } from 'react';
import { Toaster } from 'react-hot-toast';

export default memo(function LabelToast() {
    return (
        <Toaster
            position='top-center'
            toastOptions={{ duration: 1800 }}
            containerStyle={{ marginTop: 100 }}
        />
    )
}) 