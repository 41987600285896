import CellWrapper from "@components/tasks/columns-cells/cell-wrapper";
import { columnTypeList } from "@components/tasks/columns-cells/column-cell-defs";
import { Box, Stack } from "@mui/system";
import { getSelectedBoardId } from "@reducers/BoardsSelectors";
import { tasksSelectOneFieldById } from "@reducers/DBServiceReducers";
import { dynamicColumnsListOfIdsTypesAndTitles } from "@reducers/TaskSelectors";
import { getIconByType } from "@services/utils";
import { useAppSelector } from "@store/hooks";

export default function MediaMangerEdit() {
    const boardId = useAppSelector((state) => getSelectedBoardId(state))
    const columnsListOfIds = useAppSelector((state) => dynamicColumnsListOfIdsTypesAndTitles(state, boardId))
    const taskId = useAppSelector((state) => state.MediaManagerReducer.taskId);
    const groupId = useAppSelector((state) => tasksSelectOneFieldById(state.DBTasksReducer, taskId, "groupId"))

    const ColumnRow = (column, index) => {
        const [cell] = columnTypeList.filter(
            (columnType) =>
                columnType.type === column[1]
        );
        let backgroundIndicator = true;
        if (['timeline-picker', 'option-picker', 'status-option-picker', 'complexity-picker'].indexOf(column[1]) !== -1) {
            backgroundIndicator = false
        }

        return (
            <Stack  key={`${index}_${column[0]}`} sx={{
                backgroundColor: "white",
                margin: "10px",
                padding: "15px",
                borderRadius: "8px"
            }}>
   
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{textAlign:"center",paddingBottom:"5px",fontSize:"13px" }}>
                    <Box sx={{marginRight:"5px"}}>{getIconByType(column?.type)}</Box> {column[2] ?? column[1]}</Stack>
                <Box sx={{
        
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  //  border:'1px dashed  #d0d4e4',
                    backgroundColor: backgroundIndicator ? 'var(--background-default))' : 'transparent',
                }}>
                    <CellWrapper
                        columnId={column[0]}
                        taskId={taskId}
                        groupId={groupId}
                        boardId={boardId}
                        cellComponent={cell.cellComponent}
                        isOnKanban
                    />
                </Box>
            </Stack>
        )
    }
    return (

        <Box sx={{maxHeight:"70vh",overflow:'auto'}}>
                  {columnsListOfIds
                            .map((column, index) => (
                                ColumnRow(column, index)
                            ))
                        }
        </Box>

    )
}
