import { styled } from '@mui/material';
import { BiArrowBack } from 'react-icons/bi';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { ButtonBase, IconButton } from '@mui/material';
import { MdClose } from 'react-icons/md';
import { useState } from 'react';
import { Box } from '@mui/system';
import { DBQuickLaunchThunks } from '@reducers/DBServiceReducers';
import { QUICK_PANEL } from 'reusable/interfaces';
import PersonAvatar from '@components/tasks/person-avatar/person-avatar';
import { btQuickPanelActions } from '@reducers/BtQuickPanelReducer';
import { QuickLaunch } from '@models/quick-launch';



const BtQuickPanelButton = styled(ButtonBase)({
    position: 'relative',
    fontSize: 18,
    width: 50,
    height: 50,
    margin: '5px 0',
    padding: 0,
    background: '#fff',
    color: '#111',
    borderRadius: 29,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    textTransform: 'none',
    '&:hover': {
        background: '#fff'
    }
})

const ButtonIcon = styled(IconButton)({
    marginTop: 4,
    background: '#fff',
    color: '#111',
    marginLeft: 5,
    '&:hover': {
        background: '#fff'
    }
})




type Props = {
    quickLink: QuickLaunch
}

export default function QuickLinkOverlayButton({ quickLink }: Props) {
    const dispatch = useAppDispatch()
    const [isHovered, setHovered] = useState<boolean>(false)
    const isDragging = useAppSelector((state) => state.BtQuickPanelReducer.isDragging)
    const clientX = useAppSelector((state) => state.BtQuickPanelReducer.clientX)
    const popperId = useAppSelector((state) => state.BtQuickPanelReducer.popperId)
    function deleteQuickLink() {
        dispatch(DBQuickLaunchThunks.delete({ entity: { _id: quickLink._id } }))
    }

    const _popperId = `${quickLink._id}-${QUICK_PANEL.QUICK_LINK_PANEL_ID}`

    return (


        <Box sx={{ width: 100 }} onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}>
            <BtQuickPanelButton
                disabled={isDragging}
                sx={{
                    borderStyle: 'solid',
                    borderRadius: clientX > 15 ? '50%' : '',
                    borderColor: quickLink.colorAccent,
                    borderWidth: clientX > 15 ? 4 : 0,
                    borderLeftWidth: clientX > 15 ? 4 : 6,
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'

                }}
                id={_popperId}
                onClick={() => {
                    dispatch(btQuickPanelActions.setPopperId(_popperId))
                    dispatch(btQuickPanelActions.setActiveLink(quickLink))
                }}
                onMouseLeave={() => setHovered(false)}
            >
                {popperId === _popperId ?
                    <BiArrowBack size={22} /> : quickLink?.personPhotoURL ?
                        <PersonAvatar
                            email={quickLink.email}
                            firstName={quickLink.firstName}
                            lastName={quickLink.lastName}
                            profileImg={quickLink.personPhotoURL}
                            size={24}
                            textSize={13}

                        /> :
                        quickLink.boardTitleIcon 
                }
            </BtQuickPanelButton>

            {
                isHovered && !popperId && (
                    <ButtonIcon onClick={deleteQuickLink} size='small'>
                        <MdClose />
                    </ButtonIcon>
                )
            }
        </Box >
    );
}


