import { Stack } from '@mui/system';
import { logsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { LogStack } from '../../ExtraDataPanel';

type Props = {
    logId: string;
}

export default function RenameLog({
    logId,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))








    return (
        <LogStack direction='row'>
            {/* <div> */}
                {log.actionType}&nbsp;
                <b>{log.oldValue}</b>{` `}
                &nbsp;to&nbsp;
                <b>{log.newValue}</b>{` `}
            {/* </div> */}
        </LogStack>
    )
}