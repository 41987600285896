import { TaskColumn } from '@models/task-column';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { Stack } from '@mui/system';
import { getIconByType } from "@services/utils";
import { useEffect, useState } from 'react';
import SearchInput from './search-input';
import { useTranslation } from 'react-i18next';
import { namespace } from 'config/constants';

type Props = {
  columnList: TaskColumn[],
  handleCheckBoxChange: (value: any, column: any) => void,
  handleAllCheckBoxChange: (value: any) => void,
  isCheckedRecord: Record<string, boolean>,
}

export default function BTCheckboxList({
  columnList,
  handleCheckBoxChange,
  handleAllCheckBoxChange,
  isCheckedRecord,
}: Props) {
  const { t } = useTranslation(namespace);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredColumns, setFilteredColumns] = useState(columnList);

  useEffect(() => {
    setFilteredColumns(columnList);
  }, [columnList]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query !== '') {
      setFilteredColumns(columnList.filter((column) => {
        if (column.title === '') {
          return column.type?.toLowerCase().includes(query.toLowerCase())
        }
        else {
          return column.title?.toLowerCase().includes(query.toLowerCase())
        }
      }));
    } else {
      setFilteredColumns(columnList);
    }
  };

  const isChecked = (column) => (isCheckedRecord[column._id])

  const allChecked = () => (
    Object.entries(isCheckedRecord)
      .map(([key, value]) => (value))
      .every((value) => (value), [])
  )

  const checkBoxList = () => {
    
    return (
      <>
        <FormControlLabel
          control={<Checkbox
            onChange={(event) => {
              handleAllCheckBoxChange(event.target.checked)
            }}
            checked={allChecked()}
          />}
          label={<span style={{
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: "'Inter', 'Assistant', sans-serif",
          }}>
            {t('btCheckboxList.allColumns')}
          </span>}
          style={{
            padding: '0 2px',
            height: 32
          }}
        />
        {filteredColumns
          .map((column, columnIndex) => {
            return (
              <FormControlLabel
                key={columnIndex}
                control={
                  <Checkbox
                    onChange={(event) => {
                      handleCheckBoxChange(event.target.checked, column)
                    }}
                    checked={isChecked(column)}
                  />
                }
                label={<Stack direction="row" alignItems="center" style={{
                  fontSize: '14px',
                  fontFamily: "'Inter', 'Assistant', sans-serif",
                }}>
                  <span style={{ marginInlineEnd: 10 }}>{getIconByType(column?.type)}</span>
                  {column.title ?? column.type}
                </Stack>}
                style={{
                  padding: '0 2px',
                  height: 32
                }}
              />
            )
          })
        }
      </>
    )
  }

  return (
    <Stack p={2}>
      <Box sx={{marginBottom:"8px"}}>
        <SearchInput onSearch={handleSearch} />
      </Box>
      {checkBoxList()}
    </Stack>
  )
}
