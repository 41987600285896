import FilesIcons from "@components/files-icons/filesIcons";
import { Box, Stack, Tooltip } from "@mui/material";
import { mediaManager } from "@reducers/MediaManagerReducer";
import { pickerDialogActions } from "@reducers/PickerDialogReducer";
import { NullableId } from "@reducers/backend-api/backend-api";
import { useAppDispatch } from "@store/hooks";
import { memo, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";

interface Props {
    values: any;
    columnId: NullableId;
    groupId:NullableId;

}
function FilesSumCell({
    values:files,
    columnId,
    groupId
}: Props) {
    const dispatch = useAppDispatch()
    const [delayHandler, setDelayHandler] = useState(null);
    const anchorId = `filesSum_${columnId}`
    function hoverFile(i) {
        if (files.length === 1) return;
        setDelayHandler(
          setTimeout(() => {
            dispatch(
              pickerDialogActions.setPickerTypeAndId({
                pickerAnchorId: anchorId,
                pickerProps: {
                  cellValue:files,
                  columnId,
                  taskId:files[i]?._id,
                  groupId,
                  type: 'files'
                }
              })
            );
          }, 300)
        );
      }
    

    const handleMouseLeave = () => {
        clearTimeout(delayHandler);
    };

    function openMediaManager(e, i, files) {
        e.stopPropagation();
        const currentFilesAndIndex = {}
        currentFilesAndIndex["index"] = i;
        currentFilesAndIndex["shown"] = true;
        currentFilesAndIndex["taskId"] = files[i]._id;
        currentFilesAndIndex["columnId"] = columnId;
        currentFilesAndIndex["filesTotal"] = files
        currentFilesAndIndex["isViewOnly"] = true;
        dispatch(
            mediaManager.setSelectedCellFilesAndCurrentindex(currentFilesAndIndex)
        );
        dispatch(pickerDialogActions.closePickerDialog());
    }
    return (
        <Stack id={`filesSum_${columnId}`} direction="row" alignItems={"center"} flexGrow={1} justifyContent={"center"}
            sx={{ background: 'var(--task-background-color)', height: "80%", padding: "0 10px",position:"relative"}} >
            {files?.slice(0, 3).map((file, i) => {
                return (
                    <Stack sx={{padding:"0 2px;"}} key={i}
                    onMouseEnter={()=>hoverFile(i)}
                    onMouseLeave={handleMouseLeave}
                        onClick={(e) => {
                            openMediaManager(e, i, files);
                        }}
                    >
                        <FilesIcons file={file} />
                    </Stack>
                );
            })}

            {files?.length > 3 && (
                <Box
                sx={{height:'20px',width:'20px',background: "var(--primary)",display:'flex',color:"#fff",fontSize:"10px",alignItems:"center",justifyContent:"center",borderRadius:"50%",marginTop:"2px",position: "absolute",right:"2px",top:"1px"}}
                onMouseEnter={()=>hoverFile(0)}

                    onMouseLeave={handleMouseLeave}
                   >
                    +{files.length - 3}
                </Box>
            )}
        </Stack>
    );
}

export default memo(FilesSumCell)