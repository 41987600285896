import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import classes from './filter_styles.module.scss'
import PersonAvatar from "@components/tasks/person-avatar/person-avatar";
import { userDisplayName } from "@models/user";


type MultiselectFilterValueProps = {
    options: any[];
    onSelectionChange?: (newValue: any[]) => void;
    valueField?: [];
    isPerson?: boolean;
    isDisableTempId?: any;
};



export const MultiselectFilterValue = ({ options, onSelectionChange, valueField, isPerson, isDisableTempId }: MultiselectFilterValueProps) => {
    const initialValueField = Array.isArray(valueField) ? valueField : [];
    const [value, setValue] = useState<any[]>(initialValueField);
    const [transformedOptions, setTransformedOptions] = useState([]);
    const scrollBoxRef = useRef(null);

    function getOptionColorById(id) {
        const option = options.find(option => option.hasOwnProperty(id));
        return option ? option.optionColor : null;
    }
    function getOptionIsBlankById(optionId) {
        const option = options.some(option => {
            const keys = Object.keys(option).filter(key => key !== 'optionColor');
            const labelKey = keys[0];
            return (option?.optionColor === "#f0f0f0" || option?.optionColor === `var(--empty-picker-cell)`) && option[labelKey] === '' && optionId === labelKey;
        });
        return option
    }

    useEffect(() => {
        const transformed = options.map(option => {
            if (typeof option === 'object' && !isPerson && !option?.isGroup) {
                const id = Object.keys(option)[0];
                const label = option[id];
                return { id, label };
            } else if (option?.isGroup) {
                const id = option.id
                const label = option.value
                const profile = isPerson ? option?.profile : undefined
                return { id, label, profile };
            }
                else if (isPerson){
                    const id = option.id
                    const label = userDisplayName(option)
                    const profile = option?.profile 
                    return { id, label, profile };
                }
             else {
                return option
            }
        });
        if (isPerson) {
            transformed.unshift('Blank');
        }
        setTransformedOptions(transformed);
    }, [isPerson, options]);

    useEffect(() => {
        const handleWheel = (e) => {
            if (scrollBoxRef.current) {
                scrollBoxRef.current.scrollLeft += e.deltaY;
                e.preventDefault();
            }
        };

        const element = scrollBoxRef.current;
        if (element) {
            element.addEventListener("wheel", handleWheel);
        }

        return () => {
            if (element) {
                element.removeEventListener("wheel", handleWheel);
            }
        };
    }, []);


    const handleChange = (event, newSelection) => {
        const updatedValue = []
        let selectedIndex;
        newSelection.filter((selectFilterValue) => {
            const sameItem = updatedValue.find((val, i) => {
                selectedIndex = i
                if (getOptionIsBlankById(selectFilterValue.id)) {
                    return val === 'Blank'
                }
                if (typeof selectFilterValue === 'object') {
                    return val.id === selectFilterValue.id
                } else { return val === selectFilterValue }
            })
            if (!sameItem) {
                if (getOptionIsBlankById(selectFilterValue.id)) {
                    updatedValue.push('Blank')
                } else {
                    updatedValue.push(selectFilterValue)
                }
            } else {
                updatedValue.splice(selectedIndex, 1);
            }
        })
        setValue(updatedValue);
        onSelectionChange([...updatedValue]);
    };

    const ColorEl = ({ optionColor }) => {
        if (!optionColor) return <></>;

        return (
            <span style={{
                backgroundColor: optionColor,
                borderRadius: '50%',
                width: '5px',
                height: '5px',
                marginInlineEnd: '6px',
                display: 'block',
            }}></span>
        );
    };

    const PersonLabel = ({ option, style }) => {
        let name;
        if (option?.profile) {
            name = option?.profile.firstName
                ? userDisplayName(option.profile)
                : option?.profile.email;
        } else {
            name = option.label;
        }

        return (
            <>
                <PersonAvatar size={20} 
                firstName={option?.profile?.firstName}
                lastName={option?.profile?.lastName}
                email={option?.profile?.email || option?.label} profileImg={option?.profile?.profileImg}  />
                <span style={style}>{name}</span>
            </>
        );
    };

    const getLabel = (option, _fontSize?: string) => {
        const optionColor = getOptionColorById(option?.id);
        const optionLabelContainerStyle = { display: 'flex', alignItems: 'center', gap: 10 };
        const optionLabelTextContainerStyle = { fontSize: _fontSize ?? '14px' };

        const labelText = (typeof option === 'object') ? (option?.label || (isPerson && option?.profile ? '' : 'Blank')) : (option || 'Blank');

        return (
            <span style={optionLabelContainerStyle}>
                <ColorEl optionColor={optionColor} />
                {isPerson && typeof option === 'object'
                    ? <PersonLabel option={option} style={optionLabelTextContainerStyle} />
                    : <span style={optionLabelTextContainerStyle}>{labelText}</span>}
            </span>
        );
    };


    return (
        <Autocomplete
            disabled={isDisableTempId}
            multiple
            id="select-chip"
            clearIcon={false}
            options={transformedOptions}
            value={initialValueField ?? value}
            onChange={handleChange}
            getOptionLabel={(option) => {
                if (!option) return
                if (typeof option === 'object') {
                    if (option?.label) {
                        return option.label.toString();
                    } else {
                        return option?.id;
                    }
                } else {
                    return option.toString();
                }
            }}
            sx={{
                height: '47px',
                width: '100%',
                overflowX: 'auto',
                ".MuiOutlinedInput-notchedOutline": {
                    borderColor: 'rgba(0, 0, 0, 0.23) !important',
                },
                ".MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: 'rgba(0, 0, 0, 0.87) !important',
                },
                ".MuiOutlinedInput-root": {
                    paddingRight: '40px !important',
                },
                ".MuiInputBase-root": {
                    height: '47px',
                    
                },
                ".MuiInputLabel-root ": {
                    fontSize: '14px',
                    top: '-3px !important',
                },
                ".MuiInputBase-input": {
                    display: value?.length > 0 ? 'none' : '',
                    width: '100px !important'
                },
            }}

            renderOption={(props, option, { selected }) => {
                let existsInArray = value.some(e => e.id === option.id);
                if (typeof option === 'object') {
                    if (existsInArray) {
                        selected = true
                    }
                    if (getOptionIsBlankById(option.id) && value.includes('Blank')) {
                        selected = true
                    }

                } else {
                    if (existsInArray) {
                        const selectedDate = value.find(val => val === option)
                        selected = selectedDate
                    }
                }

                return (
                    <li {...props} style={{ background: selected ? 'var(--billing-btn-bg)' : '' }}>
                        {getLabel(option)}
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField {...params} sx={{
                    height: '47px',
                    ".MuiInputLabel-root ": {
                        fontSize: '14px',
                        top: '-3px !important',
                    },
                }} variant="outlined" />
            )
            }
            renderTags={(value, getTagProps) =>
                <Box
                    ref={scrollBoxRef}
                    component="div"
                    className={classes.scrollContainerX}
                    sx={{ display: 'flex', overflowX: 'auto', flexWrap: 'nowrap' }}>
                    {
                        value.map((option, index) => (
                            <Chip
                                color="primary"
                                size="small"
                                variant="outlined"
                                label={getLabel(option, '12px')} {...getTagProps({ index })} />
                        ))
                    }
                </Box>
            }
        />
    );
};
