import BTButton from '@components/bt-button/bt-button';
import { BtAutoComplete } from '@components/bt-country-select/bt-autocomplete';
import BTtextFieldWithLabel from '@components/bt-input-field-with-label/bt-input-field-with-label';
import { ShopperInfo } from '@models/shopper-info';
import { Box, FilterOptionsState, MenuItem, Stack, debounce } from '@mui/material';
import TextField from '@mui/material/TextField';
import { validateVATNumberThunk } from '@reducers/PaymentFormThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Country } from 'country-state-city';
import { ICountry } from 'country-state-city/lib/interface';
import * as React from 'react';
import { useCallback, useState } from 'react';
export interface ExtendedICountry extends ICountry {
  index?: number;
}
export const countryList: ExtendedICountry[] = [{ name: '', phonecode: '', isoCode: '', flag: '', currency: '', latitude: '', longitude: '' }, ...Country.getAllCountries()].map<ExtendedICountry>((country: ICountry, index) => ({ ...country, index }));


interface Props {
  initialValue: ShopperInfo;
  onSubmit?: (formValues: ShopperInfo) => void;
}

const EditContactDetailsForm: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { onSubmit, initialValue } = props;
  const [internalValues, setInternalValues] = React.useState<ShopperInfo>({});
  const vatId = useAppSelector((state) => internalValues.vatId ?? state.PaymentFormReducer.vatId);
  const vatValidationMessage = useAppSelector((state) => state.PaymentFormReducer.vatValidationMessage);

  const formValues = React.useMemo(() => ({
    ...initialValue,
    ...internalValues,
  }), [initialValue, internalValues])

  const country = countryList.find(country => country.isoCode.toLowerCase() === formValues?.country?.toLowerCase()) ?? null;

  const validateVAT = useCallback(debounce((vatId) => {
    dispatch(validateVATNumberThunk(vatId))
  }, 500), [dispatch]);

  React.useEffect(() => {
    if (vatId?.length > 0) {
      validateVAT(vatId);
    }
  }, [vatId, validateVAT])

  const vatInvalid = !!vatValidationMessage && vatId !== "" 

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "vatId") {
      validateVAT(vatId);
    }

    setInternalValues({ ...formValues, [name]: value });
  };

  const handleCountryChange = (value: ICountry) => {
    setInternalValues({ ...formValues, country: value?.isoCode });
  };


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formValues);
  };

  const countryTheme = {
    '&.MuiTextField-root': {
      ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid",
        borderColor: "var(--search-input-border)"
      },
    },
    "& input::placeholder": {
      color: "var(--input-placeholder-color)",
      opacity: '1',
      fontWeight: '400',
    },
    "&.Mui-focused": {
      ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--search-input-border)",
        boxShadow: ' 0 5px 12px rgb(0 0 0 / 15%)',
      },
    },
    "&: hover": {
      ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--search-input-border-hover)",
        boxShadow: ' 0 5px 12px rgb(0 0 0 / 15%)',
      },
    },
    fontWeight: '500',
    borderRadius: '8px',
    background: '#fff',
    fontSize: '14px',
    height: '36px',
    padding: '0',
    margin: '0',
    color: '#706b6b',
    outline: 'none',
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>

        <Stack spacing={2} direction={"row"}>
          <BTtextFieldWithLabel
            sx={{ margin: "0" }}
            label="First Name"
            name={"firstName"}

            value={formValues.firstName ?? ''}
            onChange={handleChange}
          />
          <BTtextFieldWithLabel
            sx={{ margin: "0" }}
            label="Last Name"
            value={formValues.lastName ?? ''}
            onChange={handleChange}
            name={"lastName"}
          /></Stack>
        <BTtextFieldWithLabel
          sx={{ margin: "0" }}
          label="Company name"
          name={"companyName"}
          value={formValues.companyName ?? ''}
          onChange={handleChange}
        />
        <BTtextFieldWithLabel
          sx={{ margin: "0" }}
          label="Address 1"
          value={formValues.address1 ?? ''}
          onChange={handleChange}
          name={"address1"}
        />
        <BTtextFieldWithLabel
          sx={{ margin: "0" }}
          label="Address 2"
          value={formValues.address2 ?? ''}
          onChange={handleChange}
          name={"address2"}
        />
        <BTtextFieldWithLabel
          sx={{ margin: "0" }}
          label="Zip Code"
          type="text"
          value={formValues.zip ?? ''}
          name={"zip"}
          onChange={handleChange}
        />

        <BTtextFieldWithLabel
          sx={{ margin: "0" }}
          label="VAT ID"
          name={"vatId"}
          type="text"
          value={formValues.vatId ?? ''}
          onChange={handleChange}
        />
        {vatInvalid && // Only show error message when vatId is not empty
          <span style={{ color: 'red', fontSize: '12px', }}>{vatValidationMessage}</span>
        }
        <Box
          style={{
            color: "var(--primary)",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "27px",

          }}
        >
          Country
        </Box>
        <BtAutoComplete<ICountry, false, false, false> autoHighlight
          renderInput={(params) => <TextField
            onChange={handleChange} name={"country"} sx={countryTheme}  {...params} />}
          filterOptions={(options: ICountry[], state: FilterOptionsState<ExtendedICountry>) => {
            return options.filter((country) => country.name.toLowerCase().indexOf(state.inputValue.toLowerCase()) > -1).slice(0, 500);
          }}
          renderOption={(props, option: ExtendedICountry) => (
            <MenuItem
              key={option.index}
              {...props}
            >
              {option.name}
            </MenuItem>
          )}
          getOptionLabel={(country: ICountry) => country.name}
          value={country}
          onChange={(event, value) => handleCountryChange(value)}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          sx={{ margin: "0" }}

          options={countryList} />

        <BTButton disabled={vatInvalid} sx={{ maxWidth: '200px', alignSelf: "end" }} type="submit" >Save changes</BTButton>
      </Stack>
    </form>
  );
};

export default EditContactDetailsForm;
