export const HTMLInput = "input:text";
export const DIVContentEditable = "div:content-editable";

export const INCREMENT: string = "INCREMENT";

// Group Types
export const SET_TASK_DEFAULTS: string = "SET_TASK_DEFAULTS";
export const SET_ORIGINAL_TASKS_MAP: string = "SET_ORIGINAL_TASKS_MAP";
export const NEW_GROUP: string = "NEW_GROUP";

export const TOGGLE_PICKER: string = "TOGGLE_PICKER";
export const SET_PICKER_POSITION: string = "SET_PICKER_POSITION";
export const CLOSE_ALL_OPENED_PICKERS: string = "CLOSE_ALL_OPENED_PICKERS";
export const SET_PICKER_UPDATE: string = "SET_PICKER_UPDATE";
export const SET_ELEMENT_LIST: string = "SET_ELEMENT_LIST";
export const SET_BOARD_PANEL_ELEMENT_LIST: string = "SET_BOARD_PANEL_ELEMENT_LIST";
export const SET_TASKS: string = "SET_TASKS";
export const NEW_TASK_BOARD: string = "NEW_TASK_BOARD";
export const IS_SHOWALL = "IS_SHOWALL";
export const SHOW_ALL_ELEMENTS_LIST = "SHOW_ALL_ELEMENTS_LIST";
export const TOGGLE_SHOW_ALL_ELEMENTS = "TOGGLE_SHOW_ALL_ELEMENTS";
export const SET_IS_TASKS_PANEL_OPEN: string = "SET_IS_TASKS_PANEL_OPEN";
export const SET_IS_TASKS_PANEL_OPEN_FULFILLED: string = "SET_IS_TASKS_PANEL_OPEN/fulfilled";
export const SET_TASK_EXTRA_DATA: string = "SET_TASK_EXTRA_DATA";
export const SET_CONTACT_LIST: string = "SET_CONTACT_LIST";

export const HANDLE_SHARE_DIALOG: string = "HANDLE_SHARE_DIALOG";
export const TOGGLE_DELETE_CONFIRM_DIALOG: string = "TOGGLE_DELETE_CONFIRM_DIALOG";

export const TOGGLE_EDITTING: string = "TOGGLE_EDITTING";

export const SET_INPUT_VALUE_CHANGE: string = "SET_INPUT_VALUE_CHANGE";
export const UPDATE_TASK_MAP_DEPENDECIES: string =
  "UPDATE_TASK_MAP_DEPENDECIES";
export const REBUILD_ELEMENTS: string = "REBUILD_ELEMENTS";

export const TOGGLE_CONVERSATION_COMPONENT: string =
  "TOGGLE_CONVERSATION_COMPONENT";
export const TOGGLE_EXTRADATA: string = "TOGGLE_EXTRADATA";
export const DISABLE_DRAG: string = "DISABLE_DRAG";
export const UPDATE_CHAT_ID: string = "UPDATE_CHAT_ID";
export const UPDATE_CHAT_ID_FULFILLED: string = "UPDATE_CHAT_ID/fulfilled";
export const HANDLE_TOGGLEABLE_NAV: string = "HANDLE_TOGGLEABLE_NAV";
export const SEND_SCHEDULED_TASK: string = "SEND_SCHEDULED_TASK"
export const SEND_SCHEDULED_TASK_FULFILLED: string = "SEND_SCHEDULED_TASK/fulfilled"


export const SET_BOARDS_LIST: string = "SET_BOARDS_LIST";
export const PREVIEW_BOARD_DATA: string = "PREVIEW_BOARD_DATA";
export const SET_ANCHOR_ELEMENT: string = "SET_ANCHOR_ELEMENT";


export const WPURL = 'https://web.whatsapp.com/';

//quick panel constants





