export const appConfigLocal = {
    API_ENDPOINT: "http://localhost:3310",
    GATEWAY_ENDPOINT: "http://localhost:3310",
    NOTIFIER_ENDPOINT: "http://localhost:3310",

    // API_ENDPOINT: "https://stg-api.blueticks.co",
    // GATEWAY_ENDPOINT: "https://stg-gateway.blueticks.co",
    // NOTIFIER_ENDPOINT: "https://stg-notify.blueticks.co",

    APP_ENDPOINT: "http://localhost:3000",
    WS_ENDPOINT: "wss://localhost:3310",
    
    CLARITY_ID: '',
    // GA_ID: 'G-2YQ649PE93',
    // GTM_ID: 'GTM-P5TZZS2V',

    /** comment out this 3 for tudoboard */
    // asset_key: 'tudoboard',
    // pricingOptions: 'only-tasks',
    // google_client_id: '171213536785-40uq3jeidmqcuabjsous4deaj8t99870.apps.googleusercontent.com',
    /** ---------------- */


    // API_ENDPOINT: "https://api.blueticks.co",
    // APP_ENDPOINT: "https://app.blueticks.co",

    /** comment out this 3 for blueticks */
    // asset_key: 'blueticks',
    // pricingOptions: 'extension',
};
export default appConfigLocal;