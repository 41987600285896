import CellWrapper from '@components/tasks/columns-cells/cell-wrapper';
import { columnTypeList } from '@components/tasks/columns-cells/column-cell-defs';
import { logsSelectOneObjectByQuery, tasksSelectOneFieldById, tasksSelectOneFieldByQuery } from '@reducers/DBServiceReducers';
import { getMockUserForLogs } from '@reducers/TaskThunks';
import { useAppSelector } from '@store/hooks';
import { LogStack } from '../../ExtraDataPanel';

type Props = {
    logId: string;
    isBoardLog?: boolean;
}

export default function PersonColumnLog({
    logId,
    isBoardLog = false,
}: Props) {
    const log = useAppSelector((state) => logsSelectOneObjectByQuery(state.DBLogReducer, { _id: logId }))

    const newMockUser = useAppSelector((state) => getMockUserForLogs(log?.newValue?._id, state))
    const oldMockUser = useAppSelector((state) => getMockUserForLogs(log?.oldValue?._id, state))
    const taskName = useAppSelector(state => tasksSelectOneFieldById(state.DBTasksReducer, log?.taskId, 'text')) ?? 'deleted task'


    let cell
    cell = columnTypeList.find(
        (columnType) =>
            columnType.type === log.logUpdateType
    );




    return (
        <>
            {log.actionType === 'changed' &&
                <LogStack direction='row'>
                    <span style={{ verticalAlign: 'middle' }}>{log.actionType}</span>&nbsp;
                    <div style={{ maxWidth: '30px' }}>
                        <CellWrapper
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={[oldMockUser]}
                            readOnly={true}
                            cellComponent={cell.cellComponent}
                        />
                    </div>
                    &nbsp;
                    <span style={{ verticalAlign: 'middle' }}>to</span>&nbsp;
                    <div style={{ maxWidth: '30px' }}>
                        <CellWrapper
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={[newMockUser]}
                            readOnly={true}
                            cellComponent={cell.cellComponent}
                        />
                    </div>
                    &nbsp;
                    <span style={{ verticalAlign: 'middle' }}>in</span>&nbsp;
                    <span style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>{log.actionOnEntityData}</span>&nbsp;
                </LogStack>
            }
            {log.actionType !== 'changed' &&
                <LogStack direction='row'>
                    <span style={{ verticalAlign: 'middle' }}>{log.actionType}</span>&nbsp;
                    <div style={{ maxWidth: '30px' }}>
                        <CellWrapper
                            columnId={""}
                            taskId={""}
                            groupId={""}
                            boardId={''}
                            mockValue={[oldMockUser ?? newMockUser]}
                            readOnly={true}
                            cellComponent={cell.cellComponent}
                        />
                    </div>
                    &nbsp;
                    <span style={{ verticalAlign: 'middle' }}>in</span>&nbsp;
                    {isBoardLog && <span style={{ fontWeight: 'bold' }}>{taskName}-</span>}
                    <span style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>{log.actionOnEntityData}</span>&nbsp;
                </LogStack>
            }
        </>
    )
}