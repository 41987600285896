import { notificationsSelectOneObjectByQuery } from '@reducers/DBServiceReducers';
import { useAppSelector } from '@store/hooks';
import { NotificationStack } from './notification-row';

type Props = {
    notificationId: string;
}

export default function RenameNotification({
    notificationId,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: notificationId,
        deleted: false
    }))


    return (
        <NotificationStack direction='row'>
            {/* <div> */}
                {notification.actionType}&nbsp;
                <b>{notification.oldValue}</b>{` `}
                &nbsp;to&nbsp;
                <b>{notification.newValue}</b>{` `}
            {/* </div> */}
        </NotificationStack>
    )
}