import { Clear } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { useState } from "react";

const FocusSearchInput = () => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = () => {
        const bingSearchURL = `https://www.bing.com/search?q=${encodeURIComponent(searchQuery)}`;
        window.location.href = bingSearchURL;
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };
    const handleClear = () => {
        setSearchQuery('');
    };
    return (<span style={{ marginInline: 'auto', fontFamily: 'Poppins' }}>

        <TextField
           onChange={(e) => setSearchQuery(e.target.value)}
           value={searchQuery}
           autoComplete="off"

            label={<Stack direction={"row"} gap={"16px"} alignItems={"center"} >
              
                <Box sx={{fontFamily:"Inter"}} >Search the web</Box>
            </Stack>}
            InputProps={{
                onKeyPress: handleKeyPress,
                startAdornment:(
                    <svg style={{ position: 'relative', top: '-1px',paddingLeft:"10px" }} width="20" height="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z" />
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448" />
                </svg>
                ),
                endAdornment: searchQuery && (
                    <InputAdornment position="end">
                        <IconButton onClick={handleClear}>
                            <Clear />
                        </IconButton>
                    </InputAdornment>
                ),

            }}
            sx={{
                width: '333px',

                '& .MuiInputBase-root': {
                    height: '41px',
                    padding: 0,
                    backgroundColor: 'white',
                    fontFamily: 'Poppins',
                    borderRadius: '8px',
                    boxShadow: '0 0 9px rgba(0, 0, 0, 0.2)',
                    '& .MuiInputBase-input': {
                        paddingInline: '16px',
                        fontFamily: 'Poppins',
                    },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: '0 !important',
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0 !important',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: '0 !important',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0 !important',
                },
                '& .MuiInputLabel-root': {
                    height: '100%',
                    color: '#013750 !important',
                    opacity: '0.6 !important',
                    fontFamily: "Inter",
                    top: '-6px !important',
                },
                '& .MuiInputLabel-shrink': {
                    display: 'none',
                },
            }} />

    </span>)
}

export default FocusSearchInput