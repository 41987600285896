import { UserMessage } from "@models/user-message";
import { nextCampaignMessageThunk } from "@reducers/CampaignReducer";
import {
  DBUserMessageReducer,
  UserReducerActions,
  campaignReducerActions,
  isTempId,
  messageLogReducerActions,
  userMessagesSelectOneObjectByQuery
} from "@reducers/DBServiceReducers";
import { hasActiveSocketSession } from "@reducers/WhatsAppSelectors";
import { sendMessageThunk } from "@reducers/WhatsappThunks";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { Campaign } from "../models/campaign-model";
import { MessageSentResponse, backendApi } from "../reducers/backend-api/backend-api";
import store, { AppDispatch, RootState } from "../store";
import { isExtensionContextOrWhatsappView } from "./detect-context";
import { isValidChromeRuntime } from "./storage";
import { selectUser } from "@reducers/UserSelectors";

export const MessageOvedueLimit = 30;


export const initMessageSentConfirmation = createAsyncThunk<void, void, { state: RootState }
>("initMessageSentConfirmation", async (_, thunkApi) => {
  const dispatch: AppDispatch = thunkApi.dispatch;


  window.addEventListener("message", (event: MessageEvent) => {
    if (event.data) {
      switch (event.data.cmd) {
        case "messageSentConfirmation": {
          console.log(event.data);

          let message = event.data.message;

          if (!message) {
            const state = store.getState();
            message = userMessagesSelectOneObjectByQuery(state.DBUserMessageReducer, {
              'waMessageKey.id': event.data.messageKey.id,
            });
          }

          dispatch(
            backendApi.endpoints.messageSent.initiate({
              sentMessage: event.data.message,
              status: event.data.status ?? 'sent'
            })
          ).then((res) => {
            if ("data" in res) {
              const messageSentResponse: MessageSentResponse = res.data;
              if (messageSentResponse.campaign) {
                dispatch(
                  campaignReducerActions.updateOne({
                    id: messageSentResponse.campaign._id,
                    changes: messageSentResponse.campaign,
                  })
                );
              }
              if (messageSentResponse.messageLog) {
                dispatch(
                  messageLogReducerActions.addOne(
                    messageSentResponse.messageLog
                  )
                );
              }
            }
          });
          break;
        }
      }
    }
  });
});
let myInterval;
export const initSendMessagesTimer = createAsyncThunk<void, void, { state: RootState }
>("initSendMessagesTimer", async (_, thunkApi) => {
  const dispatch: AppDispatch = thunkApi.dispatch;

  if (myInterval) {
    console.log("An interval already exists. Clearing it before creating a new one.");
    clearInterval(myInterval);
  }

  const minMessageInterval = (24 * 60 * 60) / 10000;
  myInterval = setInterval(async () => {
    //console.log('interval', moment().format('hh:mm'));

    // console.log(isValidChromeRuntime);
    // console.log(isExtensionContextOrWhatsappView);


    const state: RootState = store.getState();
    const user = selectUser(state);

    if (user && !isValidChromeRuntime() && isExtensionContextOrWhatsappView()) {
      return;
    }

    const hasActiveSocket = hasActiveSocketSession(state);
    if (hasActiveSocket) {
      return;
    }

    let cycleMessages = 0;

    const lowerLimit = moment().subtract(MessageOvedueLimit, "minutes");
    const upperLimit = moment();

    // Handle scheduled messages
    let userMessageList = Object.keys(state.DBUserMessageReducer.entities)
      .filter(id => !isTempId(id))
      .map((id) => state.DBUserMessageReducer.entities[id]);

    userMessageList
      .filter((_message) => !_message.deleted)
      .forEach((message: UserMessage) => {
        const messageDueDate = moment.utc(message.dueDate).local();

        // let campaign;
        // if (message.campaignId) {
        //   campaign = campaignsSelectByQuery(state.DBCampaignReducer, {
        //     _id: message.campaignId,
        //   });
        // }

        // if (
        //   (message.state === MessageState.pending ||
        //     message.state === undefined) &&
        //   message.dueDate &&
        //   ((message.campaignId && campaign) || !message.campaignId) &&
        //   (message.campaignId ||
        //     messageDueDate.isBetween(lowerLimit, upperLimit)) &&
        //   cycleMessages < 3
        // )
        if (
          (message.status === 'pending' || message.status === undefined) &&
          message.dueDate &&
          messageDueDate.isBetween(lowerLimit, upperLimit) &&
          cycleMessages < 3
        ) {
          console.log(`Sending scheduled message [${cycleMessages}]`);
          cycleMessages++;
          console.log(message);


          // analyticsService.event(`${message.isRecurring ? "recurring" : "regular"}-message-sent`, {
          //   category: "messages",
          //   action: "sent",
          //   label: message.isRecurring ? "recurring" : "regular",
          //   messageId: message.id,
          //   campaignId: message.campaignId,
          // });
          dispatch(sendMessageThunk(message));
        }
      });

    // Campaigns are handled in the client regardless if there is an active session or not.
    dispatch(handleCampaigns());
  }, minMessageInterval * 1000);

});



// function getNextOccurrence(rruleString: string) {
//   const rule = RRule.fromString(rruleString);

//   // The next occurrence from now
//   const nextOccurrence = rule.after(new Date()) ?? new Date();

//   return nextOccurrence.toISOString();
// }

export const handleCampaigns = createAsyncThunk<void, void, { state: RootState }
>("handleCampaigns", async (_, thunkApi) => {
  const dispatch: AppDispatch = thunkApi.dispatch;

  // dispatch(
  //   DBCampaignThunks.find({})
  // );

  const appState = store.getState();

  if (!appState.DBCampaignReducer.itemsLoaded) {
    return;
  }

  const campaignList = Object.keys(appState.DBCampaignReducer.entities).map(
    (id) => appState.DBCampaignReducer.entities[id]
  );


  // console.log(campaignList[0]?.schedule?.dueDate)
  // console.log(moment(campaignList[0]?.schedule?.dueDate))
  // console.log(moment(campaignList[0]?.schedule?.dueDate).local().utc())

  // console.log(moment())

  // console.log(moment.utc())

  // console.log(moment(campaignList[0]?.schedule?.dueDate).isBefore(moment.utc()))
  // console.log(moment(campaignList[0]?.schedule?.dueDate).local().isBefore(moment.utc()))
  // console.log(moment(campaignList[0]?.schedule?.dueDate).local().utc().isBefore(moment.utc()))



  const pendingOrActiveCampaignList: Campaign[] = campaignList.filter(
    (campaign: Campaign) => {
      const now = moment.utc();

      return (campaign?.campaignStatus === "running" ||
        (campaign?.campaignStatus === "pending" &&
          (!campaign?.schedule?.dueDate || moment.utc(campaign?.schedule?.dueDate).isBefore(now))))
    }
  );


  for (const campaign of pendingOrActiveCampaignList) {
    if (campaign._id && !isTempId(campaign._id)) {

      dispatch(nextCampaignMessageThunk(campaign._id!));
    }
  }

  // const completedCampaignList: Campaign[] = campaignList.filter((campaign: Campaign) =>
  //   campaign?.campaignStatus === "completed" && !campaign?.isCloned
  //   )


  // for (const campaign of completedCampaignList) {
  //   if (campaign?.schedule?.isRecurring && !campaign.isCloned) {
  //     dispatch(
  //       DBCampaignThunks.patch({
  //         entity: {
  //           _id: campaign._id,
  //           isCloned: true,
  //         }
  //       })
  //     )

  //     const nextCampaignIntervalDueDate = getNextOccurrence(campaign?.schedule?.rruleset ?? '')

  //     dispatch(
  //       DBCampaignThunks.create({
  //         campaignStatus: 'pending',
  //         schedule: {
  //           ...campaign.schedule,
  //           dueDate: nextCampaignIntervalDueDate,
  //         },
  //         timing: campaign.timing,
  //         name: campaign.name,
  //         template: campaign.template,
  //         labelIDArray: [...(campaign.labelIDArray ?? [])],
  //       })
  //     )
  //   }
  // }
});




