//import { googleLogout } from '@react-oauth/google';
import {
  authenticate,
  backendApi,
  cancelSubscription,
  changePlan,
  updateChargeDate,
} from "@reducers/backend-api/backend-api";
import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit";
import { AnalyticsService } from "@services/analytics-service";
import {
  getContextName,
  isExtensionContextOrWhatsappView,
  isWebPage,
} from "@services/detect-context";
import { messageService } from "@services/messageService";
import { sleep } from "@services/utils/sleep";
import { RootState } from "@store/index";
import moment from "moment";
import { BLUETICKS_TOKEN_KEY } from "reusable/common";
import { User, UserInterface } from "../models/user";
import {
  DBBoardsThunks,
  DBReducerMap,
  DBSubscriptionThunks,
  DBUserThunks,
  SubscriptionReducerActions,
  boardsReducerActions,
  userSelectByQuery,
} from "./DBServiceReducers";
import { mainSliceActions } from "./MainReducer";
import { modalActions } from "./ModalReducer";
import { notifierDisconnectThunk } from "./NotifierThunks";
import { taskActions } from "./TasksReducer";
import { selectCurrentWorkspace } from "./UserSelectors";

const analyticsService = new AnalyticsService();

export interface SignupFormState {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  acceptedTerms?: boolean;
}

// Define a type for the slice state
export interface UserState {
  authenticating: boolean;
  accessToken?: string | null;
  userId?: string;
  authError?: string;
  socketId?: string;
  socketModalOpen: boolean;
  hideSocketModal: boolean;
  signup: SignupFormState;
  direction: string;
}

// Define the initial state using that type
const initialState: UserState = {
  authenticating: true,
  signup: { email: "", password: "", firstName: "", lastName: "" },
  socketModalOpen: false,
  hideSocketModal: false,
  accessToken:
    typeof window !== "undefined"
      ? window.localStorage.getItem(BLUETICKS_TOKEN_KEY)
      : undefined,
  direction: "ltr",
};

export const initAuth = createAsyncThunk<void, void, { state: RootState }>(
  "initAuth",
  async (_, thunkApi) => {
    let accessToken;
    let params: any;

    const contextName = getContextName();
    console.log(`initAuth called in context: ${contextName}`);

    if (isWebPage()) {
      params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop as string),
      });

      accessToken =
        localStorage.getItem(BLUETICKS_TOKEN_KEY) ?? params.accessToken;
    }
    // Proceed in extension or web page and only if no acess token was provided in the URL query
    const extensionContext = isExtensionContextOrWhatsappView();
    const webPage = isWebPage();
    if (extensionContext || webPage) {
      console.log("Getting token from background");
      try {
        const res: { accessToken: string } = await messageService.sendMessage<{
          accessToken: string;
        }>("get-token", {});
        if (res && res.accessToken !== null) {
          accessToken = res.accessToken;
        }
        console.log(`Received access token: ${res?.accessToken}`);
      } catch (e) {
        console.log("No extension found!");
      }
    }

    console.log(accessToken);

    if (accessToken) {
      window.postMessage(
        {
          cmd: "initialize-notifier",
        },
        "*"
      );

      await thunkApi.dispatch(
        authenticate.initiate({
          strategy: "jwt",
          accessToken,
        })
      );
    }
  }
);



export const externalAccessToken = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("externalAccessToken", (accessToken, thunkApi) => {
  const state = thunkApi.getState();
  if (state.UserReducer.accessToken !== accessToken) {
    thunkApi.dispatch(
      authenticate.initiate({
        strategy: "jwt",
        accessToken,
      })
    );
  }
});

export const toggleDarkMode = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("toggleDarkMode", (_, thunkApi) => {
  const state = thunkApi.getState();
  const { dispatch } = thunkApi;
  const [user] = userSelectByQuery(state.DBUserReducer, {
    _id: state.UserReducer.userId,
  });
  const isDarkMode = !user?.isDarkMode;

  console.log(`Dark mode: ${isDarkMode}`);

  dispatch(
    DBUserThunks.patch({
      entity: {
        ...user,
        isDarkMode,
      },
    })
  );

  document.querySelector("body").className = isDarkMode ? "dark" : "";
});

export const toggleDomDirection = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("toggleDomDirection", (_, thunkApi) => {
  const state = thunkApi.getState();
  const { dispatch } = thunkApi;
  const [user] = userSelectByQuery(state.DBUserReducer, {
    _id: state.UserReducer.userId,
  });
  const direction = user?.direction === "rtl" ? "ltr" : "rtl";
  if (!window.location.href.includes("web.whatsapp.com")) {
    document.body.setAttribute("dir", direction);
  }

  dispatch(userSlice.actions.setUserDirection(direction));

  dispatch(
    DBUserThunks.patch({
      entity: {
        ...user,
        direction,
      },
    })
  );
});

export const changeDefaultChatCountryCode = createAsyncThunk<
  void,
  { defaultChatCountryCode: string },
  { state: RootState }
>(
  "changeDefaultChatCountryCode",
  async ({ defaultChatCountryCode }, thunkApi) => {
    const state = thunkApi.getState();
    const { dispatch } = thunkApi;

    const [user] = userSelectByQuery(state.DBUserReducer, {
      _id: state.UserReducer.userId,
    });

    if (user.defaultChatCountryCode !== defaultChatCountryCode) {

      dispatch(DBUserThunks.patch({
        entity: {
          _id: state.UserReducer.userId,
          defaultChatCountryCode,
        },
      })
      );
    }
  }
);

export const changePaymentPlan = createAsyncThunk<
  { changeSuccessful: boolean },
  { productId: string },
  { state: RootState }
>("changePaymentPlan", async ({ productId }, thunkApi) => {
  const { dispatch } = thunkApi;
  let res;
  try {
    res = await dispatch(
      changePlan.initiate({
        contractId: productId,
      })
    ).unwrap();
    const { changeSuccessful } = res;
    if (changeSuccessful) {
      await dispatch(refreshUser());
    }
  } catch (e) {
    res = e;
  }
  return res;
});

export const googleLoginThunk = createAsyncThunk<
  void,
  { accessToken?: string },
  { state: RootState }
>("googleLoginThunk", async ({ accessToken }, thunkApi) => {
  console.log("googleLoginThunk called")
  let result;
  if (!accessToken) {
    const res = await messageService.sendMessage<{
      accessToken: string;
    }>("launchWebAuthFlow", {});

    accessToken = res.accessToken;
    result = await thunkApi.dispatch(
      authenticate.initiate({
        strategy: "google",
        accessToken,
      })
    );
  } else {
    result = await thunkApi.dispatch(
      authenticate.initiate({
        strategy: "google-token",
        accessToken,
      })
    );
  }
  return result
});

export const clearStateThunk = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("clearStateThunk", async (_, thunkApi) => {
  const { dispatch } = thunkApi;

  // Clear all reducer states
  for (const [name, reducer] of DBReducerMap.entries()) {
    console.log(`Clearing state for ${name} reducer`);
    dispatch(reducer.slice.actions.clearState());
  }
});

export const logoutThunk = createAsyncThunk<void, void, { state: RootState }>(
  "logoutThunk",
  (_, thunkApi) => {
    const { dispatch } = thunkApi;
    try {
      dispatch(logout());
      dispatch(backendApi.util.resetApiState());
      messageService.sendMessage("logout", {});
      messageService.sendPageMessage("logout", {});
      localStorage.removeItem(BLUETICKS_TOKEN_KEY);

      dispatch(taskActions.setCurrentBoardView({
        type: 'board',
        id: undefined
      }))

      dispatch(clearStateThunk());

      // Close UI elements
      dispatch(mainSliceActions.closeScheduledContactList());
      dispatch(taskActions.setIsTasksPanelOpen(false));
      dispatch(notifierDisconnectThunk);

      //googleLogout();
    } catch (e) {
      console.error(e);
    }
  }
);

export interface PollRequestOptions {
  user: User;
  timeout?: number;
}

export const refreshUser = createAsyncThunk<void, void, { state: RootState }>(
  "refreshUser",
  async (_, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState();
    const accessToken = state.UserReducer.accessToken;
    await thunkApi
      .dispatch(
        authenticate.initiate({
          strategy: "jwt",
          accessToken,
        })
      )
      .unwrap();
  }
);

export const switchWorkspace = createAsyncThunk<
  void,
  { workspaceId: string },
  { state: RootState }
>("switchWorkspace", async ({ workspaceId }, thunkApi) => {
  const { dispatch, getState } = thunkApi;
  const state: RootState = getState();
  const userId = state.UserReducer.userId;
  const currentWorkspace = selectCurrentWorkspace(state);

  if (currentWorkspace?._id === workspaceId) {
    return;
  }

  dispatch(modalActions.cleanComponentToRender());
  await dispatch(boardsReducerActions.clearState());
  await dispatch(SubscriptionReducerActions.clearState());



  await dispatch(
    DBUserThunks.patch({
      entity: {
        _id: userId,
        currentWorkspace: workspaceId,
      },
    })
  );

  await dispatch(refreshUser());
  await dispatch(DBSubscriptionThunks.find({}));

  await dispatch(DBBoardsThunks.find({ deleted: false }));
});

export const cancelSubscriptionThunk = createAsyncThunk<
  void,
  { subscriptionID: string; reason: string },
  { state: RootState }
>("cancelSubscriptionThunk", async ({ subscriptionID, reason }, thunkApi) => {
  const { dispatch } = thunkApi;

  const res = await dispatch(
    cancelSubscription.initiate({
      subscriptionID,
      reason,
    })
  );

  if ("data" in res) {
    dispatch(refreshUser());
  }
});

export const updateChargeDateThunk = createAsyncThunk<
  void,
  { subscriptionId: string; nextChargeDate: string },
  { state: RootState }
>("updateChargeDate", async ({ subscriptionId, nextChargeDate }, thunkApi) => {
  const { dispatch } = thunkApi;

  await dispatch(
    updateChargeDate.initiate({
      subscriptionId,
      nextChargeDate,
    })
  );


});

export const pollUserChanges = createAsyncThunk<
  boolean,
  PollRequestOptions,
  { state: RootState }
>("pollUserChanges", async (options: PollRequestOptions, thunkApi) => {
  const start = moment();
  const state = thunkApi.getState();
  const accessToken = state.UserReducer.accessToken;
  let user: User;
  do {
    const res = await thunkApi
      .dispatch(
        authenticate.initiate({
          strategy: "jwt",
          accessToken,
        })
      )
      .unwrap();

    user = new User(res.user);
    if (!user.compareTo(options.user)) {
      break;
    }

    console.log("No change detected... continuing");
    await sleep(1000);
  } while (moment().diff(start, "second") < options.timeout);

  return !user.compareTo(options.user);
});

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCredentials: (
      state,
      action: PayloadAction<{ user?: UserInterface; accessToken?: string }>
    ) => {
      state.userId = action.payload.user._id;
      state.accessToken = action.payload.accessToken;
    },
    logout: (state) => {
      delete state.userId;
      delete state.accessToken;
      // clear analytics user identity
      analyticsService.clearUserIdentity();
    },
    clearAuthError: (state) => {
      delete state.authError;
    },
    setSocketId: (state, action: PayloadAction<string | undefined>) => {
      state.socketId = action.payload;
    },
    setAuthenticating: (state, action: PayloadAction<boolean>) => {
      state.authenticating = action.payload;
    },
    setSignupState: (state, action: PayloadAction<SignupFormState>) => {
      state.signup = action.payload;
    },
    setHideSocketModal: (state, action: PayloadAction<boolean>) => {
      state.hideSocketModal = action.payload;
    },
    setUserDirection(state, { payload }: PayloadAction<string>) {
      state.direction = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isFulfilled(initAuth), (state) => {
      state.authenticating = false;
    });
    builder.addMatcher(isRejected(initAuth), (state) => {
      state.authenticating = false;
    });
    builder.addMatcher(
      backendApi.endpoints.authenticate.matchPending,
      (state, payload) => {
        console.log(`Starting authentication`);
        if (!state.userId) {
          state.authenticating = true;
        }
        delete state.authError;
      }
    );
    builder.addMatcher(
      backendApi.endpoints.register.matchPending,
      (state, payload) => {
        state.authenticating = true;
        delete state.authError;
      }
    );
    builder.addMatcher(
      backendApi.endpoints.authenticate.matchRejected,
      (state, payload) => {
        console.log(`Authentication error!`);
        console.log(payload);
        state.authenticating = false;

        if (payload.meta.arg.originalArgs.strategy === "local") {
          const message = (payload?.payload?.data as any)?.message;
          if (message === "Invalid login") {
            state.authError = `Invalid password. Please retry or reset by "Forgot your password?"`;
          } else {
            state.authError = message || "An error occurred";
          }

          analyticsService.event("login_failed", {
            category: "access",
            action: "login-failed",
          });
        }
      }
    );
    builder.addMatcher(
      backendApi.endpoints.register.matchRejected,
      (state, payload) => {
        state.authenticating = false;

        const payloadErrorData: any = payload?.payload?.data;

        switch (payloadErrorData?.name) {
          case "Conflict":
            state.authError = "User already exists";
            break;
          default:
            state.authError = payloadErrorData?.message || "An error occurred";
            break;
        }

        analyticsService.event("login_failed", {
          category: "access",
          action: "signup-failed",
        });
      }
    );
    builder.addMatcher(
      backendApi.endpoints.authenticate.matchFulfilled,
      (state, payload) => {
        console.log(`Authentication success!`);

        state.userId = payload.payload.user._id;
        state.accessToken = payload.payload.accessToken;

        analyticsService.identify(payload.payload.user);

        if (typeof window !== "undefined") {
          window.localStorage.setItem(BLUETICKS_TOKEN_KEY, state.accessToken);
        }
        messageService.sendMessage<string>("set-token", {
          accessToken: state.accessToken,
        });
        messageService.sendPageMessage("set-token", {
          accessToken: state.accessToken,
        });

        if (payload.meta.arg.originalArgs.strategy === "local") {
          analyticsService.event("login_success", {
            category: "access",
            action: "login-success",
          });
        }
      }
    );
    builder.addMatcher(
      backendApi.endpoints.register.matchFulfilled,
      (state, payload) => {
        state.authenticating = false;

        analyticsService.event("login_success", {
          category: "access",
          action: "signup-success",
        });
      }
    );
  },
});

export const {
  setCredentials,
  clearAuthError,
  logout,
  setSocketId,
  setAuthenticating,
  setSignupState,
  setHideSocketModal,
} = userSlice.actions;

export default userSlice.reducer;
