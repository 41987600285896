import RowStack from '@components/common/row-stack/RowStack'
import { IconButton } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { setActiveTab } from '@reducers/CampaignReducer'
import { boardsSelectByQuery, campaignsSelectOneObjectByQuery } from '@reducers/DBServiceReducers'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { BsPencil } from 'react-icons/bs'
import CampaignFinishTabTitle from './campaign-finish-tab-title'

type Props = {
    campaignId?: string;
}

export default function CampaignSummaryAudienceDialog({ campaignId }: Props) {
    const dispatch = useAppDispatch()

    let campaign
    const _campaign = useAppSelector((state) => campaignsSelectOneObjectByQuery(state.DBCampaignReducer, { _id: campaignId }))
    const newCampaign = useAppSelector((state) => state.CampaignReducer.newCampaign)

    if (campaignId) campaign = _campaign
    else campaign = newCampaign


    let filteredboardArray = useAppSelector((state) => boardsSelectByQuery(state.DBBoardsReducer, {
        type: 'audience',
        $or: [
            { deleted: { $exists: false } },
            { deleted: false }
        ],
        _id: { $in: (campaign?.boardIDArray ?? []) }
    }))

    let selectedString = 'No audiences selected'

    if (campaign?.boardIDArray?.length === 1) {
        selectedString = 'Target audience:'
    }
    else if (campaign?.boardIDArray?.length > 1) {
        selectedString = `${campaign?.boardIDArray?.length} target audiences:`
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stack sx={{
                alignItems: 'flex-start',
                justifyContent: 'center',
                width: '100%',
                marginBottom: '10px',
            }}>

                <RowStack sx={{ marginBottom: "15px" }}>
                    <CampaignFinishTabTitle titleString={selectedString} />
                    {!campaignId && <IconButton onClick={() => dispatch(setActiveTab('audiences'))}>
                        <BsPencil />
                    </IconButton>}
                </RowStack>

                <RowStack >
                    {filteredboardArray && filteredboardArray.length !== 0 &&
                        filteredboardArray
                            .filter((label, index) => index < 3)
                            .map((audience, index) => {
                                return (
                                    <span
                                        key={index}
                                        style={{
                                            borderRadius: '16px',
                                            backgroundColor: 'ver(--campaign-finish-view-audience-label-bg)',
                                            border: '1px solid var(--campaign-audience-label-border)',
                                            padding: '5px 10px 7px 10px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {audience.name}
                                    </span>
                                )
                            })}
                    {filteredboardArray.length > 3 && <span>
                        {` and ${filteredboardArray.length - 3} more...`}
                    </span>}

                </RowStack>
            </Stack>
        </Box>
    )
}