import { DEFAULT_COLUMN_WIDTH } from '@models/task-column';
import { Box, Tooltip } from '@mui/material';
import { BoxProps } from '@mui/system';
import { boardsActions } from '@reducers/BoardsReducer';
import { getBlueticksThemeSelector, getCurrentBoard } from '@reducers/BoardsSelectors';
import { onResizeStopThunk } from '@reducers/BoardsThunks';
import { taskColumnSelectOneFieldByQuery } from '@reducers/DBServiceReducers';
import { NullableId } from '@reducers/backend-api/backend-api';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { PropsWithChildren, RefObject, SyntheticEvent, memo, useEffect, useState } from 'react';
import { Resizable, ResizeCallbackData, ResizeHandleAxis } from 'react-resizable';

type Props = PropsWithChildren<{
    columnId: NullableId;
    disabled?: boolean;
} & BoxProps>;

const HANDLE_WIDTH = 20;
const TARGET_WIDTH = 2;


const ColumnCellResizable = memo(({ children, columnId, disabled, ...boxProps }: Props) => {
    const dispatch = useAppDispatch();
    const columnWidth = useAppSelector(state => taskColumnSelectOneFieldByQuery(state.DBTaskColumnReducer, {
        _id: columnId
    }, 'width'));
    const board = useAppSelector(getCurrentBoard);
    const theme = useAppSelector((state) => getBlueticksThemeSelector(state))

    const initWidth = columnId === "row-prefix" ? board.textColumnWidth ? board.textColumnWidth : 400 : columnWidth ?? DEFAULT_COLUMN_WIDTH

    const [initialColumnWidth, setColumnWidth] = useState(initWidth)
    const [resizing, setResizing] = useState(false);
    const [initialMouseX, setInitialMouseX] = useState(0);
    const [initialWidth, setInitialWidth] = useState(0);
    const [finalWidth, setFinalWidth] = useState(0);


    const onResizeStart = () => {
        dispatch(boardsActions.setResizing(true));
    }

    const onResize = (clientX: number) => {
        if (!resizing) return;

        const widthDiff = clientX - initialMouseX;
        const newWidth = initialWidth + widthDiff;
        setFinalWidth(newWidth);


        const className = `column_${columnId}`;
        const styleId = 'dynamic-style';
        if (columnId === 'row-prefix') {
            const minWidth = Math.max(newWidth, 420);
            updateDynamicWidth(minWidth, className, styleId);
        } else {
            const minWidth = Math.max(newWidth, 70);
            updateDynamicWidth(minWidth, className, styleId);
        }


        if (columnId === "row-prefix") {
            updateDynamicFooterInLineStart(newWidth, "column-row-prefix-footer", "dynamic-style-footer");
        }


    };

    const onResizeStop = () => {
        const width = finalWidth;
        const styleId = 'dynamic-style';
        const styleTag = document.getElementById(styleId);
        if (styleTag) {
            styleTag.parentNode.removeChild(styleTag);
        }
        const styleFooterId = 'dynamic-style-footer';
        const styleTagFooter = document.getElementById(styleFooterId);
        if (styleTagFooter) {
            styleTagFooter.parentNode.removeChild(styleTagFooter);
        }
        dispatch(onResizeStopThunk({ columnId, width, boardId: board._id }));
        setResizing(false)
    };



    const updateDynamicWidth = (width: number, className: string, styleId: string) => {
        let styleTag = document.getElementById(styleId) as HTMLStyleElement;

        if (!styleTag) {
            styleTag = document.createElement('style');
            styleTag.id = styleId;
            document.head.appendChild(styleTag);
        }

        styleTag.innerHTML = `.${className} { width: ${width}px !important; transition: width 0.05s;border-right:2px solid #4a9af4  }`;

    }

    const updateDynamicFooterInLineStart = (width: number, className: string, styleId: string) => {
        let styleTag = document.getElementById(styleId) as HTMLStyleElement;

        if (!styleTag) {
            styleTag = document.createElement('style');
            styleTag.id = styleId;
            document.head.appendChild(styleTag);
        }
        const totalWidth = Math.round(width + 125);
        let minWidth = Math.max(totalWidth, 545);

        styleTag.innerHTML = `.${className} { inset-inline-start: ${minWidth}px!important; }`;
    }

    const handleMouseDown = (event: React.MouseEvent) => {
        setResizing(true);
        setInitialMouseX(event.clientX);

        setInitialWidth(initialColumnWidth);
    };

    useEffect(() => {
        if (columnWidth && columnId) {
            setColumnWidth(columnId === "row-prefix" ? board.textColumnWidth ? board.textColumnWidth : 400 : columnWidth ?? DEFAULT_COLUMN_WIDTH);
        }
    }, [columnWidth, columnId, board.textColumnWidth]);


    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            if (resizing) {
                onResize(event.clientX);
            }
        };

        const handleMouseUp = () => {
            setResizing(false);
        };

        if (resizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [resizing, initialMouseX, initialWidth]);
    return (
        <Box sx={{
            position: "relative",
            transition: 'width 0.05s',
            backgroundColor: theme.boards.tasksPanel.tasksHeader.backgorund,
        }} >
            {renderContent(children, boxProps)}
            {!disabled && (
                <Resizable
                    width={initialColumnWidth ?? columnWidth}
                    height={40}
                    onResizeStart={onResizeStart}
                    onResize={onResize}
                    onResizeStop={onResizeStop}
                    onMouseDown={handleMouseDown}
                    resizeHandles={['e']}
                    handle={(resizeHandle: ResizeHandleAxis, ref: RefObject<HTMLElement>) => (
                        <Tooltip title="Resize column" placement="top" arrow>
                            <Box
                                ref={ref}
                                sx={{
                                    width: HANDLE_WIDTH,
                                    height: '100%',
                                    position: 'absolute',
                                    cursor: 'default',
                                    top: 0,
                                    left: -1 * (HANDLE_WIDTH / 2),
                                    backgroundColor: 'transparent',
                                    zIndex: 100,
                                    '&:hover': {
                                        cursor: 'ew-resize'
                                    },
                                }}
                            />
                        </Tooltip>
                    )}
                >

                    <Box
                        sx={{
                            width: '1px',
                            height: '100%',
                            position: 'absolute',
                            cursor: 'ew-resize',
                            top: 0,
                            right: 1,
                            backgroundColor: 'transparent',
                            '&:hover': {
                                width: TARGET_WIDTH,
                                background: '#4a9af4',
                            },
                            ...(resizing && {
                                width: TARGET_WIDTH,
                                background: '#4a9af4',
                            }),
                        }}
                    />

                </Resizable>
            )}
        </Box>
    );
});

function renderContent(children: React.ReactNode, boxProps: BoxProps) {
    return <Box {...boxProps}>{children}</Box>;
}

export default ColumnCellResizable;