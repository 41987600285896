import { Stack } from "@mui/material";
import { useRef, useState } from "react";
import { ReactComponent as AddIcon } from "@assets/add.svg";
import { RiCloseCircleFill } from "react-icons/ri";
import { TbNumbers } from "react-icons/tb";
import { NumericFormat } from "react-number-format";
import { CellProps } from "../cell-interface";
import classes from "./number_cell.module.scss";
import { useAppSelector } from "@store/hooks";
import { RootState } from "@store/index";
import { taskColumnSelectByQuery } from "@reducers/DBServiceReducers";

export default function NumberCell({ cellValue, onUpdate, columnId, readOnly, sx }: CellProps) {
  const inputRef: any = useRef();
  const [value, setValue] = useState<string | undefined>();
  const [isInputShown, toggleDisplayToInput] = useState<boolean>(true);

  function updateNumber(e) {
    setValue(undefined)
    if (value) {
      onUpdate(Number(value?.replaceAll(',', '')));
    }
    e?.target?.blur();
    toggleDisplayToInput(true)
  }
  function handleNumberChange({ target }: any) {
    setValue(target.value ? target.value : undefined);
  }
  function handleClearValue() {
    setValue(undefined);
    onUpdate(undefined);
  }
  const handleClick = () => {
    if (readOnly) return;
    toggleDisplayToInput(false)
  }
  const [column] = useAppSelector((state: RootState) => taskColumnSelectByQuery(state.DBTaskColumnReducer, { _id: columnId }))
  const symbol = column?.customData?.symbol ? column.customData.symbol : "";
  const direction = column?.customData?.direction ? column.customData?.direction : undefined
  return (
    <Stack sx={{
      "& span":
        { color: "#819ba8", textAlign: "center", width: "100%" },
      ...sx
    }}
      direction="row"
      alignItems={"center"}
      className={classes.numberCell}>
      {isInputShown && cellValue ?
        (<NumericFormat onClick={handleClick}
          displayType="text"
          allowNegative
          decimalSeparator={"."}
          allowLeadingZeros
          thousandSeparator=","
          readOnly={readOnly}
          value={(value || cellValue) ?? ""}
          prefix={(isInputShown && (direction === 'left' || direction === undefined)) ? symbol ?? "" : ""}
          suffix={isInputShown && direction === 'right' ? symbol ?? "" : ""}
        />) :
        <NumericFormat
          value={(value || cellValue) ?? ""}
          onBlur={updateNumber}
          onChange={handleNumberChange}
          onKeyPress={(e) => e.key === "Enter" && updateNumber(e)}
          getInputRef={inputRef}
          allowNegative
          readOnly={readOnly}
          decimalSeparator={"."}
          allowLeadingZeros
          autoFocus={!isInputShown}
          thousandSeparator=","
        />


      }




      <div className={classes.numbersIcon}>
        {isNaN(cellValue) && (
          <>
            <AddIcon className={classes.addIcon} />
            <TbNumbers className={classes.smNumbersIcon} />
          </>
        )}
        {(!isNaN(cellValue) && !readOnly) && (
          <RiCloseCircleFill
            onClick={handleClearValue}
            className={classes.clearIcon}
          />
        )}
      </div>
    </Stack>
  );
}
